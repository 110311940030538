import React, { useState } from "react";
import { Box, Grid, IconButton, Typography, makeStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import PinnedDatasets from "./PinnedDatasets";
import ViewDataData from "src/pages/ViewData/ViewDataData/ViewDataData";
import { DatasetCustomColumnsProps, Entity, EntityFeaturesResponse } from "src/types";
import { RecordSelector } from "pages/Projects/common/RecordSelector";
import AutoSelectDataset from "./AutoSelectDataset";
import ChatBox from "src/pages/Projects/AIGuide/common/ChatBox";
import { EntityIconV3 } from "src/icons/NewUX/EntityIconV3";

const useStyles = makeStyles({
  rightAligned: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    flexWrap: "nowrap",
    flex: 0
  },
  leftAligned: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px"
  },
  datasetName: {
    color: "#4646B5",
    fontWeight: 400
  },
  inputDatasetsRow: {
    padding: "0px 0px 0px 16px",
    width: "inherit",
    gap: "12px",
    flexWrap: "nowrap"
  },
  outputIconWrap: {
    height: "32px",
    width: "24px !important",
    marginRight: "12px"
  },
  span: {
    fontSize: "14px",
    color: "#515151"
  },
  boxWrap: {
    width: "100%",
    maxWidth: "100%",
    boxShadow: "0px 4px 4px 0px #82828240"
  },
  titleBox: {
    color: "#4646B5",
    cursor: "pointer",
    padding: "6px 12px",
    borderTopRightRadius: "4px",
    gap: "8px"
  },
  dataContainer: {
    background: "#fff",
    borderRadius: "4px",
    maxWidth: "100%"
  },
  collapsedBox: {
    cursor: "pointer",
    padding: "0px",
    gap: "16px"
  },
  collapsedTitle: {
    color: "#4646B5",
    fontWeight: 400
  },
  dataset: {
    margin: "5px 12px 0px 12px",
    border: "1px solid #D1D1D1",
    overflowY: "auto",
    borderRadius: "2px"
  }
});

const AskAIInputDataset = ({
  inputDataset,
  entityFeatures,
  entityData,
  responses,
  isAutoGenerateInProgress,
  getDatasetCustomColumnsData
}: {
  inputDataset: Entity;
  entityFeatures: EntityFeaturesResponse | undefined;
  entityData: $TSFixMe;
  isAutoGenerateInProgress: boolean;
  responses: $TSFixMe[];
  getDatasetCustomColumnsData?: ({ columnNames, entityId }: DatasetCustomColumnsProps) => void;
}) => {
  const [value, setValue] = useState(100);
  const [isExpanded, setIsExpanded] = useState(responses?.length === 0);
  const classes = useStyles({ isExpanded });

  React.useEffect(() => {
    // collapse all responses when auto generate is running or when code history responses exist.
    if (responses?.length !== 0) {
      setIsExpanded(false);
    } else if (isAutoGenerateInProgress) {
      setIsExpanded(false);
    }
  }, [responses?.length, isAutoGenerateInProgress]);

  const handleCountChange = (event: $TSFixMe) => {
    event.stopPropagation();
    setValue(event.target.value);
  };

  return (
    <Grid container direction="row" className={classes.inputDatasetsRow}>
      <Grid
        test-id="ask-ai-modal-entity-icon"
        container
        item
        direction="column"
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
        className={classes.outputIconWrap}>
        <EntityIconV3 />
      </Grid>
      {isExpanded ? (
        <ChatBox
          noPadding
          color={"#F2F2FF"}
          border="#4646B5"
          width="calc(100% - 194px)"
          childWidth="100%">
          <Grid container direction="column" className={classes.boxWrap}>
            <Grid
              container
              direction="row"
              className={classes.titleBox}
              wrap="nowrap"
              alignItems="center"
              onClick={() => setIsExpanded(false)}>
              <Grid container item className={classes.leftAligned}>
                <Typography variant="subtitle2" className={classes.datasetName}>
                  {inputDataset.name}
                </Typography>
              </Grid>
              <Grid container item className={classes.rightAligned} alignItems="center">
                <PinnedDatasets name={inputDataset.displayName || inputDataset.name} />
                <AutoSelectDataset name={inputDataset.displayName || inputDataset.name} />
                <IconButton size="small">
                  <ExpandLess fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid className={classes.dataContainer}>
              <Box className={classes.dataset}>
                <MemoizedInputDataset
                  entityData={entityData}
                  entityFeatures={entityFeatures}
                  getDatasetCustomColumnsData={getDatasetCustomColumnsData}
                  value={value}
                />
              </Box>
              <Box
                p="0px 12px 12px 12px"
                style={{ display: "flex", gap: "16px", alignItems: "center" }}>
                <RecordSelector value={value} handleCountChange={handleCountChange} />
                <span className={classes.span}>{`Size: ${
                  entityData?.numRows ?? entityData?.rows?.length ?? 0
                } Rows * ${entityData?.numCols ?? entityData?.columns?.length ?? 0} Columns`}</span>
              </Box>
            </Grid>
          </Grid>
        </ChatBox>
      ) : (
        <ChatBox border={"#FFF"} color="#FFF" width="auto">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={() => setIsExpanded(true)}
            className={classes.collapsedBox}>
            <Typography variant="subtitle2" className={classes.collapsedTitle}>
              {inputDataset.name}
            </Typography>
            <IconButton size="small" style={{ padding: "0px" }}>
              <ExpandMore fontSize="small" />
            </IconButton>
          </Grid>
        </ChatBox>
      )}
    </Grid>
  );
};

const InputDataset = ({
  entityFeatures,
  value,
  entityData,
  getDatasetCustomColumnsData
}: {
  entityData: any;
  value: number;
  entityFeatures: EntityFeaturesResponse | undefined;
  getDatasetCustomColumnsData?: ({ columnNames, entityId }: DatasetCustomColumnsProps) => void;
}) => {
  const slicedRows = entityData?.rows?.slice(0, value) || [];
  return (
    <ViewDataData
      isLoadingData={false}
      responseData={entityData ? { ...entityData, rows: slicedRows } : { columns: [], rows: [] }}
      entityFeatures={entityFeatures}
      maxHeight="236px"
      {...(!!entityData?.entityId
        ? {
            onColumnChange: (columnNames: string[]) =>
              getDatasetCustomColumnsData?.({
                columnNames,
                entityId: entityData?.entityId
              })
          }
        : {})}
    />
  );
};

const MemoizedInputDataset = React.memo(InputDataset);

export default React.memo(AskAIInputDataset);
