import React, { useEffect, useMemo, useState } from "react";
import { find, get, isEmpty } from "lodash";

import { processFilesWithRethrow, applyConfigWithRethrow } from "src/api/projects";

import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";

import {
  Criteria,
  schemaOptionsSupportedFileTypes,
  FileMetaDataKeys,
  FileUploadStatuses,
  DatasetKeys,
  OntologyConfig,
  OntologyDatasetStatuses,
  separators,
  encodings
} from "../../utils/Dataset.constants";

import { useGetEntityData } from "src/hooks/api";
import useStoreSelectors from "../../hooks/useStoreSelectors";
import useDatasetsSession from "../../hooks/useDatasetsSession";
import useFilesSession from "../../hooks/useFilesSession";
import useHelpers from "../../hooks/useHelpers";
import ViewUploadedContainer from "./ViewUploadedContainer";
import ConfigContainer from "./ConfigContainer";
import OntologyContainer from "./OntologyContainer/OntologyContainer";
import useAuthStore from "src/stores/auth.store";
import shallow from "zustand/shallow";
import { useProjectsStore } from "src/store/store";
import { useUploadContext } from "../../contexts/Upload/useUploadContext";
import { useParams } from "react-router-dom";
import { useSourceContext } from "../../contexts/Source/useSourceContext";
import WebWorker from "src/workerSetup";
import worker from "../../edaWorker";
import useEntities from "src/hooks/api/entities/useEntities";
import { useGetSchemaData, useGetSchemaOptions } from "src/hooks/api/projects/useEntityFeatures";

const OntologySection = () => {
  const { projectId, scenarioId } = useParams();

  const edaWorker = new WebWorker(worker);

  const { sources } = useSourceContext();
  const {
    cancelApiTokenRef,
    createDataset,
    failFilesInSession,
    ontologyDatasetIndex,
    envRelaunchToastIdRef
  } = useUploadContext();

  const [setModifiedDatasetId] = useProjectsStore((state) => [state.setModifiedDatasetId]);

  // Stores - STARTS >>
  const {
    datasetDefaultDatasetStore,
    datasetSourceStore,
    datasetCriterionStore,
    datasetFilesStore,
    datasetDatasetsStore,
    setDatasetIsFetchingOntologyDataStore,
    datasetWatchOntologySchemaSetStore
  } = useStoreSelectors();

  const [authToken] = useAuthStore((state) => [state.token], shallow);
  // << ENDS - Stores

  const { getDatasetDatasetsSession } = useDatasetsSession();

  // Files in session - STARTS >>
  const [filesTimer, setFilesTimer] = useState<$TSFixMe>(new Date().getTime());

  const { getDatasetFilesSession, setDatasetFilesSession } = useFilesSession({
    timer: filesTimer
  });
  // << ENDS - Files in session

  const {
    isDataSourcesFilesUpload,
    updateFilesSession,
    updateDatasetsSession,
    removeDatasetOntologySchemaSession
  } = useHelpers();

  // States - STARTS >>
  const [isConfigContainerExpanded, setIsConfigContainerExpanded] = useState<$TSFixMe>(false);
  const [isProcessingFiles, setIsProcessingFiles] = useState<$TSFixMe>(false);
  // << ENDS - States

  const dataset = useMemo(
    () => datasetDatasetsStore[ontologyDatasetIndex],
    [ontologyDatasetIndex, datasetDatasetsStore]
  );

  const getDatasetSession = () => getDatasetDatasetsSession()?.[ontologyDatasetIndex] || {};

  const isSchemaOptionsSupportedFileType = useMemo(
    () =>
      schemaOptionsSupportedFileTypes.includes(
        datasetFilesStore[ontologyDatasetIndex]?.type?.toLowerCase()
      ),
    [datasetFilesStore, ontologyDatasetIndex]
  );

  // Query hooks - STARTS >>
  const {
    isSuccess: isDatasetFetched,
    data: datasetData,
    refetch: refetchDataset
  } = useEntities({
    id: dataset?.id || getDatasetSession()?.id,
    projectId,
    cacheTime: Infinity,
    enabled: isSchemaOptionsSupportedFileType && (!!dataset?.id || !!getDatasetSession()?.id)
  });

  const {
    isLoading: isFetchingOntologySchemaOptions,
    data: ontologySchemaOptionsData,
    refetch: refetchOntologySchemaOptions
  } = useGetSchemaOptions({
    entityId: dataset?.id || getDatasetSession()?.id,
    cacheTime: Infinity,
    enabled: false
  });

  const {
    isFetching: isFetchingOntologySchema,
    isSuccess: isOntologySchemaFetched,
    data: ontologySchemaData,
    refetch: refetchOntologySchema
  } = useGetSchemaData({
    entityId: dataset?.id || getDatasetSession()?.id,
    onError: () => {
      failDatasetsInSession();
    },
    cacheTime: Infinity,
    enabled: false
  });

  const {
    isLoading: isFetchingOntologySampleData,
    isSuccess: isOntologySampleDataFetched,
    data: ontologySampleData,
    refetch: refetchOntologySampleData
  } = useGetEntityData({
    entityId: dataset?.id || getDatasetSession()?.id,
    limit: OntologyConfig.RowsLimit,
    onSuccess: (data: $TSFixMe) => (Object.keys(data || {})?.length > 0 ? data : {}),
    cacheTime: Infinity,
    enabled: false
  });

  useEffect(() => {
    let isFetching = false;

    if (!datasetDefaultDatasetStore?.id) {
      isFetching = isFetching || isFetchingOntologySchemaOptions;
      isFetching = isFetching || isFetchingOntologySchema;
    }

    isFetching = isFetching || isFetchingOntologySampleData;

    setDatasetIsFetchingOntologyDataStore(isFetching);
  }, [
    datasetDefaultDatasetStore,
    isFetchingOntologySchemaOptions,
    isFetchingOntologySchema,
    isFetchingOntologySampleData
  ]);

  useEffect(() => {
    if (isDatasetFetched) {
      if (datasetData?.id) {
        // Update datasets in session >>
        const fields: $TSFixMe = {};
        fields[DatasetKeys.OntologyConfig] = {};
        fields[DatasetKeys.OntologyConfig][DatasetKeys.Separator] =
          datasetData?.entityMeta?.separator || separators[0].value;
        fields[DatasetKeys.OntologyConfig][DatasetKeys.Encoding] =
          datasetData?.entityMeta?.encoding || encodings[0].value;

        updateDatasetsSession({ index: ontologyDatasetIndex, fields: fields });
        // << Update datasets in session
      }
    }
  }, [isDatasetFetched, datasetData]);
  // << ENDS - Query hooks

  const failDatasetsInSession = () => {
    // Update datasets in session >>
    const fields: $TSFixMe = {};
    fields[DatasetKeys.OntologyConfig] = {};
    fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Failed;

    updateDatasetsSession({ index: ontologyDatasetIndex, fields });
    // << Update datasets in session
  };

  // Process files - STARTS >>
  const processFiles = async () => {
    let thisDatasetFilesSession = getDatasetFilesSession();

    const targetIndices: number[] = [];
    if (datasetCriterionStore?.value === Criteria.Segregate) {
      targetIndices.push(ontologyDatasetIndex);
    } else {
      (thisDatasetFilesSession || [])?.forEach((eachFile: $TSFixMe, index: number) => {
        if (eachFile?.status === FileUploadStatuses.SignedUrl) {
          targetIndices.push(index);
        }
      });
    }

    const fileNamesToProcess = (thisDatasetFilesSession || [])
      ?.filter(
        (eachFile: $TSFixMe, index: number) =>
          targetIndices.includes(index) && eachFile?.status === FileUploadStatuses.SignedUrl
      )
      ?.map((eachFile: $TSFixMe) => eachFile?.name);

    if (fileNamesToProcess?.length === 0) {
      return;
    }

    // Update files in session >>
    thisDatasetFilesSession = (thisDatasetFilesSession || [])?.map(
      (eachFile: $TSFixMe, index: number) => {
        const fields: $TSFixMe = {};

        if (targetIndices.includes(index) && eachFile?.status === FileUploadStatuses.SignedUrl) {
          fields[FileMetaDataKeys.Status] = FileUploadStatuses.Processing;
        }

        return {
          ...eachFile,
          ...fields
        };
      }
    );

    setDatasetFilesSession(thisDatasetFilesSession);
    setFilesTimer(() => new Date().getTime());
    // << Update files in session

    try {
      setIsProcessingFiles(() => true);

      await processFilesWithRethrow({
        params: { entityId: dataset?.id || getDatasetSession()?.id },
        options: {
          params: {
            fileName: `${fileNamesToProcess}`,
            isAppend: datasetCriterionStore?.value === Criteria.Append
          },
          cancelToken: cancelApiTokenRef.current
        }
      });

      if (datasetCriterionStore?.value === Criteria.Append) {
        setModifiedDatasetId(dataset?.id || getDatasetSession()?.id);
      }

      // Update files in session >>
      thisDatasetFilesSession = getDatasetFilesSession();

      thisDatasetFilesSession = (thisDatasetFilesSession || [])?.map(
        (eachFile: $TSFixMe, index: number) => {
          const fields: $TSFixMe = {};

          if (targetIndices.includes(index) && eachFile?.status === FileUploadStatuses.Processing) {
            fields[FileMetaDataKeys.Status] = FileUploadStatuses.Success;
            fields[FileMetaDataKeys.UploadProgress] = 100;
          }

          return {
            ...eachFile,
            ...fields
          };
        }
      );

      setDatasetFilesSession(thisDatasetFilesSession);
      setFilesTimer(() => new Date().getTime());
      // << Update files in session

      // setReloadTrigger();
    } catch (error: $TSFixMe) {
      // Update files in session >>
      thisDatasetFilesSession = getDatasetFilesSession();

      thisDatasetFilesSession = (thisDatasetFilesSession || [])?.map(
        (eachFile: $TSFixMe, index: number) => {
          const fields: $TSFixMe = {};

          if (targetIndices.includes(index) && eachFile?.status === FileUploadStatuses.Processing) {
            fields[FileMetaDataKeys.Status] = FileUploadStatuses.SignedUrl;
          }

          return {
            ...eachFile,
            ...fields
          };
        }
      );

      setDatasetFilesSession(thisDatasetFilesSession);
      setFilesTimer(() => new Date().getTime());
      // << Update files in session

      throw error;
    } finally {
      setIsProcessingFiles(() => false);
    }
  };
  // << ENDS - Process files

  const applyConfig = async ({ isAppliedConfig = true } = {}) => {
    // Update datasets in session >>
    const fields: $TSFixMe = {};
    fields[DatasetKeys.OntologyConfig] = {};
    fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Active;

    updateDatasetsSession({ index: ontologyDatasetIndex, fields });
    // << Update datasets in session

    setDatasetIsFetchingOntologyDataStore(true);

    // Resetting ontology schema
    removeDatasetOntologySchemaSession();

    let isCreateDataset = false;
    // Keeping nested ifs for a better readability.
    if (isDataSourcesFilesUpload) {
      if (!(dataset?.id || getDatasetSession()?.id)) {
        isCreateDataset = true;
      }
    } else {
      if (!(dataset?.id || getDatasetSession()?.id)) {
        setDatasetIsFetchingOntologyDataStore(false);
        return;
      }
    }

    try {
      const separator = dataset?.ontologyConfig?.separator;
      const encoding = dataset?.ontologyConfig?.encoding;

      if (isSchemaOptionsSupportedFileType && (!separator || !encoding)) {
        setDatasetIsFetchingOntologyDataStore(false);
        return;
      }

      const requestJson = {
        id: dataset?.id || getDatasetSession()?.id || "",
        ...(!!isAppliedConfig
          ? { entityMeta: { separator, encoding }, autodetectionDisable: true }
          : {})
      };

      if (!envRelaunchToastIdRef.current) {
        envRelaunchToastIdRef.current = checkEnvRelaunch(projectId);
      }

      let canApplyConfig = isAppliedConfig;

      if (isCreateDataset) {
        try {
          setIsProcessingFiles(() => true);

          const isFiveTran =
            get(find(sources, { id: datasetSourceStore?.value }), "isFivetran") ?? false;

          const filePath = datasetFilesStore[ontologyDatasetIndex];
          const file = isEmpty(filePath?.payloadPath) ? filePath?.file : filePath?.payloadPath;
          const thisRequestJson = {
            ...requestJson,
            name: datasetDatasetsStore[ontologyDatasetIndex]?.name,
            dataSourceId: datasetSourceStore?.value,
            dataSourceOptions: { [isFiveTran ? "probableDataset" : "filePath"]: file }
          };

          const entityResponse = await createDataset(thisRequestJson);

          if (entityResponse?.id) {
            canApplyConfig = false;

            // Update files in session >>
            const fileFields: $TSFixMe = {};
            fileFields[FileMetaDataKeys.Status] = FileUploadStatuses.Success;
            fileFields[FileMetaDataKeys.UploadProgress] = 100;
            fileFields[FileMetaDataKeys.EntityId] = entityResponse?.id;

            updateFilesSession({ index: ontologyDatasetIndex, fields: fileFields });
            // << Update files in session

            // Update datasets in session >>
            const datasetFields: $TSFixMe = {};
            datasetFields[DatasetKeys.Id] = entityResponse?.id;
            datasetFields[DatasetKeys.IsDirty] = false;

            if (isSchemaOptionsSupportedFileType) {
              datasetFields[DatasetKeys.OntologyConfig] = {};
              datasetFields[DatasetKeys.OntologyConfig][DatasetKeys.Separator] =
                entityResponse?.entityMeta?.separator || separator || separators[0].value;
              datasetFields[DatasetKeys.OntologyConfig][DatasetKeys.Encoding] =
                entityResponse?.entityMeta?.encoding || encoding || encodings[0].value;
            }

            updateDatasetsSession({ index: ontologyDatasetIndex, fields: datasetFields });
            // << Update datasets in session
          } else {
            failFilesInSession({ index: ontologyDatasetIndex });
            failDatasetsInSession();

            setDatasetIsFetchingOntologyDataStore(false);
            setIsProcessingFiles(() => false);

            return;
          }
        } catch {
          failFilesInSession({ index: ontologyDatasetIndex });
          failDatasetsInSession();

          return;
        } finally {
          setDatasetIsFetchingOntologyDataStore(false);
          setIsProcessingFiles(() => false);
        }
      }

      if (canApplyConfig) {
        const entityResponse = await applyConfigWithRethrow(requestJson);

        if (entityResponse?.id) {
          // Update datasets in session >>
          fields[DatasetKeys.IsDirty] = false;

          if (isSchemaOptionsSupportedFileType) {
            fields[DatasetKeys.OntologyConfig][DatasetKeys.Separator] =
              entityResponse?.entityMeta?.separator || separator || separators[0].value;
            fields[DatasetKeys.OntologyConfig][DatasetKeys.Encoding] =
              entityResponse?.entityMeta?.encoding || encoding || encodings[0].value;
          }

          updateDatasetsSession({ index: ontologyDatasetIndex, fields: fields });
          // << Update datasets in session
        }
      }

      await processFiles();

      if (!canApplyConfig) {
        if (isSchemaOptionsSupportedFileType && (!!dataset?.id || !!getDatasetSession()?.id)) {
          refetchDataset();
        }
      }

      const domain = window.location.origin;
      const datasetFilesSession = getDatasetFilesSession();
      datasetFilesSession.map((file: any) => {
        const entityId = file?.entityId;
        entityId &&
          scenarioId &&
          // @ts-ignore
          edaWorker?.postMessage({
            entityId,
            scenarioId,
            authToken,
            domain
          });
      });
    } catch {
      failDatasetsInSession();
      setDatasetIsFetchingOntologyDataStore(false);

      return;
    }

    setDatasetIsFetchingOntologyDataStore(false);

    if (datasetDefaultDatasetStore?.id) {
      await refetchOntologySampleData();
    } else {
      await refetchOntologySchemaOptions();
      await refetchOntologySchema();
      await refetchOntologySampleData();
    }
  };

  useEffect(() => {
    const _ = async () => {
      await applyConfig({ isAppliedConfig: false });
    };

    _();
  }, [ontologyDatasetIndex]);

  useEffect(() => {
    const _ = async () => {
      await Promise.all([refetchOntologySchema(), refetchOntologySampleData()]);
    };

    !!isOntologySchemaFetched && !!isOntologySampleDataFetched && _();
  }, [isOntologySchemaFetched, isOntologySampleDataFetched, datasetWatchOntologySchemaSetStore]);

  return (
    <>
      <ViewUploadedContainer
        ontologyDatasetIndex={ontologyDatasetIndex}
        isConfigContainerExpanded={isConfigContainerExpanded}
        setIsConfigContainerExpanded={setIsConfigContainerExpanded}
        isSchemaOptionsSupportedFileType={isSchemaOptionsSupportedFileType}>
        {isSchemaOptionsSupportedFileType && isConfigContainerExpanded && (
          <ConfigContainer
            datasetDefaultDatasetStore={datasetDefaultDatasetStore}
            ontologyDatasetIndex={ontologyDatasetIndex}
            applyConfig={applyConfig}
          />
        )}
      </ViewUploadedContainer>

      <OntologyContainer
        isDataSourcesFilesUpload={isDataSourcesFilesUpload}
        ontologyDatasetIndex={ontologyDatasetIndex}
        dataset={dataset}
        isConfigContainerExpanded={isConfigContainerExpanded}
        ontologySampleData={ontologySampleData}
        isProcessingFiles={isProcessingFiles}
        isFetchingOntologySchema={isFetchingOntologySchema}
        isFetchingOntologySampleData={isFetchingOntologySampleData}
        ontologySchemaOptionsData={ontologySchemaOptionsData}
        ontologySchemaData={ontologySchemaData}
      />
    </>
  );
};

export default OntologySection;
