import React from "react";
import { IVideo } from "./VideoList";
import NewModal from "src/components/Modal/NewModal";

interface IProps {
  video: IVideo;
  open: boolean;
  onClose: () => void;
}

const VideoModal: React.FC<IProps> = (props) => {
  const { video, open, onClose } = props;

  return (
    <NewModal
      header={video.title}
      width={700}
      background="#fff"
      modelBg="linear-gradient(36.71deg, #E5E5FF -1.63%, #FFE2D2 116.16%)"
      hideFooter
      open={open}
      onClose={onClose}>
      <iframe
        style={{ border: 0 }}
        width="100%"
        height="415"
        src={video.link}
        title={video.title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </NewModal>
  );
};

export default VideoModal;
