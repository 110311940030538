/* eslint-disable no-unused-vars */
import React from "react";
import groupBy from "lodash/groupBy";

import { DataAppType } from "../../DataApps.type";
import { FilterMenu } from "src/components/Menu/FilterMenu";
import { isEqual, size } from "lodash";

type Props = {
  dataApps: DataAppType[];
  filteredDataApps: DataAppType[] | undefined;
  selectedProjects: Array<string>;
  setSelectedProjects: (values: Array<string>) => void;
  projects?: Array<$TSFixMe>;
};

export const DataAppsFilter = ({
  dataApps,
  selectedProjects,
  filteredDataApps,
  projects,
  setSelectedProjects
}: Props) => {
  const projectDataAppsMap = groupBy(dataApps, "projectId");
  const selectedProjectDataAppsMap = groupBy(filteredDataApps, "projectId");
  const projectIdNameMap = projects?.reduce(
    (acc, project: $TSFixMe) => ({ ...acc, [project.id]: project.name }),
    {}
  );
  const options = Object.keys(projectDataAppsMap).map((project) => {
    const projectName = (projectIdNameMap as $TSFixMe)?.[project];
    const count = selectedProjectDataAppsMap?.[project]?.length || 0;
    return {
      label: projectName || project,
      value: project,
      selected: selectedProjects.includes(project),
      disabled: count === 0,
      tag: count?.toString() || "0"
    };
  });

  return (
    <FilterMenu
      isFiltered={!isEqual(size(selectedProjects), size(options))}
      values={options}
      allLabel="All Projects"
      onFilter={setSelectedProjects}
      testId="dataAppsFilterBtn"
    />
  );
};
