import React, { useMemo, lazy, Suspense } from "react";
import _ from "lodash";
import shallow from "zustand/shallow";
import { Routes, useLocation, Route } from "react-router-dom";

import AnalyticsWrapper from "../AnalyticsWrapper";
import RedirectRoute from "./RedirectRoute";
import useAuthStore from "src/stores/auth.store";
import { ResetPasswordPathStatuses } from "src/constants";
import { Spinner } from "src/components";

const ComponentNotFound = lazy(() => import("components/Errors/ComponentNotFound"));
const SignIn = lazy(() => import("src/pages/public/SignIn/SignIn"));
const SignUp = lazy(() => import("src/pages/public/SignUp/SignUp"));
const ResetPasswordLink = lazy(() => import("src/pages/public/ResetPassword/ResetPasswordLink"));
const ResetPassword = lazy(() => import("src/pages/public/ResetPassword/ResetPassword"));

import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { WebPaths, loginPath } from "../routes";

const publicConfig = [
  {
    key: "sign-in",
    path: WebPaths.SignIn,
    Component: (
      <NewThemeWrapper>
        <SignIn />
      </NewThemeWrapper>
    )
  },
  {
    key: "sign-up",
    path: WebPaths.SignUp,
    Component: (
      <NewThemeWrapper>
        <SignUp />
      </NewThemeWrapper>
    )
  },
  {
    key: "reset-password-link-send",
    path: WebPaths.SendResetPassword,
    Component: (
      <NewThemeWrapper>
        <ResetPasswordLink status={ResetPasswordPathStatuses.Send} />
      </NewThemeWrapper>
    )
  },
  {
    key: "reset-password-link-sent",
    path: WebPaths.ResetPasswordLinkSent,
    Component: (
      <NewThemeWrapper>
        <ResetPasswordLink status={ResetPasswordPathStatuses.Sent} />
      </NewThemeWrapper>
    )
  },
  {
    key: "reset-password",
    path: WebPaths.ResetLink,
    Component: (
      <NewThemeWrapper>
        <ResetPassword />
      </NewThemeWrapper>
    )
  }
];

const PublicRoute = () => {
  const location = useLocation();
  const [isUserLoggedIn, token] = useAuthStore(
    (state) => [state.isUserLoggedIn, state.token],
    shallow
  );

  const routes = useMemo(
    () =>
      _.map(publicConfig, ({ key, path, Component }) => (
        <Route
          key={key}
          path={path}
          element={<AnalyticsWrapper path={path}>{Component}</AnalyticsWrapper>}
        />
      )),
    []
  );
  // Redirect to Dashboard screen if user is already logged in
  if (isUserLoggedIn && token && location.pathname === loginPath) {
    return <RedirectRoute token={token} />;
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        {routes}
        <Route path="*" element={<ComponentNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default PublicRoute;
