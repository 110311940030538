import React from "react";

export const AIGuideMiniIcon = ({
  width = 21,
  height = 21,
  viewBox = "0 0 21 21"
}: { width?: number; height?: number; viewBox?: string } = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_17348_5244)">
        <path
          d="M10.5 20.119C10.5 20.751 11.1544 21.1769 11.7422 20.9278C17.166 18.629 20.8831 14.427 20.9378 8.9268C20.9385 8.88612 20.9385 8.8449 20.9385 8.80368C20.9385 8.76245 20.9385 8.72119 20.9378 8.68055V8.92685C20.9385 8.88616 20.9385 8.84494 20.9385 8.80372C20.9385 8.7625 20.9385 8.72123 20.9378 8.68059C20.8592 3.87528 16.2161 0 10.5 0C4.73505 0 0.0615234 3.94156 0.0615234 8.80363C0.0615234 13.6657 4.73505 17.6073 10.5 17.6073V20.119Z"
          fill="url(#paint0_linear_17348_5244)"
        />
        <path
          d="M20.2849 5.73193C17.974 11.8754 12.043 16.2467 5.0906 16.2467C5.03412 16.2467 4.97776 16.2462 4.92145 16.2456C6.53521 17.1079 8.44834 17.6075 10.5 17.6075V20.1192C10.5 20.7512 11.1545 21.1771 11.7423 20.928C17.166 18.6292 20.8831 14.4271 20.9378 8.92697C20.9385 8.88628 20.9385 8.84506 20.9385 8.80384C20.9385 8.76262 20.9385 8.72136 20.9378 8.68071C20.9209 7.64466 20.6913 6.652 20.2849 5.73193Z"
          fill="url(#paint1_linear_17348_5244)"
        />
        <path
          d="M10.5 12.1406V5.57812"
          stroke="white"
          strokeWidth="1.23047"
          strokeLinecap="round"
        />
        <path
          d="M7.21875 10.5V7.21875"
          stroke="white"
          strokeWidth="1.23047"
          strokeLinecap="round"
        />
        <path
          d="M13.7812 10.5V7.21875"
          stroke="white"
          strokeWidth="1.23047"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_17348_5244"
          x1="-1.76523"
          y1="5.25"
          x2="-4.95367"
          y2="19.3297"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF653D" />
          <stop offset="1" stopColor="#8585FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17348_5244"
          x1="3.51994"
          y1="9.549"
          x2="1.3121"
          y2="19.837"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF653D" />
          <stop offset="1" stopColor="#8585FF" />
        </linearGradient>
        <clipPath id="clip0_17348_5244">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
