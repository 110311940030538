import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { Badge, Checkbox, Grid, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import { filter, get, includes, isEmpty, isEqual, map, size } from "lodash";
import { toast } from "react-toastify";
import { generatePath, useNavigate } from "react-router-dom";

import { Spinner } from "../../../components";
import { deleteEnvironment, relaunchEnvironment, stopEnvironment } from "../../../api";
import { useEnvironmentsStore } from "../../../store/store";
import { Search, ToggleView } from "src/components/custom";
import {
  environmentsGetter,
  environmentsSetter,
  shouldRefreshEnvironmentsGetter,
  shouldRefreshEnvironmentsToggler,
  watchingEnvsSetter,
  watchingEnvsGetter,
  watchingEnvsIntervalIdSetter,
  watchingEnvsIntervalIdGetter,
  isEnvironmentsLoadingGetter,
  setIsEnvironmentLoadingSetter
} from "../../../store/store.selectors";

import { createString } from "src/helpers/helpers";
import EnvironmentConfig from "./components/EnvironmentConfig/EnvironmentConfigDrawer";
import EnvironmentLogsDrawer from "./components/EnvironmentLogs/EnvironmentLogsDrawer";
import envRelaunchNotification from "../../../utils/envRelaunchNotification";
import { CreateEnvironment } from "./components/CreateEnvironment/CreateEnvironment";
import { EnvAction } from "./components/Environment";
import { EnvironmentCards } from "./components/EnvironmentCards/EnvironmentCards";
import { EnvironmentStatuses } from "src/constants/environments.constants";
import { EnvironmentTable } from "./components/EnvironmentTable/EnvironmentTable";
import { getEnvironmentUsageDetails } from "./utils/environments.helpers";
import { getEnvironmentById } from "../../../api";
import { toastWrapper } from "src/utils/toastWrapper";
import EnvironmentsHeader from "./components/EnvironmentsHeader";
import SplashSectionWrapper from "./components/SplashSectionWrapper";
import { EnvironmentDeleteMessage, EnvironmentsConfig } from "./utils/Environments.constants";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";

import backgroundTopImg from "src/assets/images/background-top.svg";
import backgroundBottomImg from "src/assets/images/background-bottom.svg";

import useStyles from "./Environments.styles";
import { WebPaths } from "src/routing/routes";
import { useGetEnvironments, useGetEnvironmentTypes } from "src/hooks/api";
import { listViewPages, PagesViewConfiguration } from "src/constants";
import { FilterIcon } from "src/icons/FilterIcon";
import { DataSourcesHelperText } from "src/pages/DataSources/utils/DataSources.constants";

interface IProjectData {
  id: string;
  envId: string;
  name: string;
}

export type Environment = {
  id: string;
  name: string;
  defaultFlag: boolean;
  description: string;
  createdAt: number;
  updated: number;
  updater: string;
  creator: string;
  cores: number;
  memInMbs: number;
  requirements: string;
  diskInGbs: number;
  launchStatus: string;
  envType: string;
  projects: string[];
  projectDtos: IProjectData[];
  shutdownStrategy: { type: "INACTIVITY_TIME_PERIOD" | "EVERGREEN"; inactivityInHours: number };
};

const Environments = () => {
  const navigate = useNavigate();

  const classes = useStyles();
  const pagesViewConfiguration = JSON.parse(localStorage.getItem(PagesViewConfiguration) || "{}");
  const isTilesView = get(pagesViewConfiguration, listViewPages.ENVIRONMENT, true);

  const [envTypeFilterAnchorEl, setEnvTypeFilterAnchorEl] = useState(null);
  const [environmentState, setEnvironmentState] = useState<$TSFixMe>({});
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [confirmEnvAction, setConfirmEnvAction] = useState<EnvAction | null>(null);
  const [openModalEnvironment, setOpenModalEnvironment] = useState(false);
  const [logsOpen, setLogsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [tilesView, setTilesView] = useState(isTilesView);
  const [isDeleting, setIsDeleting] = useState(false);
  const [stoppingEnvironments, setStoppingEnvironments] = useState<string[]>([]);
  const [envCheckedList, setEnvCheckedList] = useState<string[]>([]);

  const setEnvironments = useEnvironmentsStore(environmentsSetter);
  const environments = useEnvironmentsStore(environmentsGetter);
  const isEnvironmentsLoading = useEnvironmentsStore(isEnvironmentsLoadingGetter);

  const setIsEnvironmentLoading = useEnvironmentsStore(setIsEnvironmentLoadingSetter);
  const toggleShouldEnvironmentsRefresh = useEnvironmentsStore(shouldRefreshEnvironmentsToggler);
  const watchingEnvsStore = useEnvironmentsStore(watchingEnvsGetter);
  const shouldRefresh = useEnvironmentsStore(shouldRefreshEnvironmentsGetter);
  const setWatchingEnvsStore = useEnvironmentsStore(watchingEnvsSetter);
  const watchingEnvsIntervalIdStore = useEnvironmentsStore(watchingEnvsIntervalIdGetter);
  const setWatchingEnvsIntervalIdStore = useEnvironmentsStore(watchingEnvsIntervalIdSetter);

  const [isEnvUsageOpen, setIsEnvUsageOpen] = useState<$TSFixMe>(false);

  const { data: environmentsTypes, isLoading: isEnvironmentsTypesFetching } =
    useGetEnvironmentTypes();

  const envNames = useMemo(() => {
    if (environmentsTypes) {
      const names = map(environmentsTypes, "name");
      setEnvCheckedList(names);
      return names;
    }
    return [];
  }, [environmentsTypes]);

  const isFiltered = useMemo(
    () => !isEqual(size(envNames), size(envCheckedList)),
    [envNames, envCheckedList]
  );

  const { isFetching, refetch } = useGetEnvironments({
    refetchOnMount: true,
    onSuccess: (environmentsData) => {
      setEnvironments(environmentsData);
    }
  });

  useEffect(() => {
    if (Boolean(shouldRefresh)) {
      refetch();
    }
  }, [shouldRefresh]);

  const toggleView = (bool: boolean) => {
    setTilesView(bool);
    localStorage.setItem(
      PagesViewConfiguration,
      JSON.stringify({ ...pagesViewConfiguration, [listViewPages.ENVIRONMENT]: bool })
    );
  };

  const toggleEnvTypeFilter = (event: $TSFixMe) => {
    setEnvTypeFilterAnchorEl(event.currentTarget);
  };

  const closeEnvTypesFilter = () => {
    setEnvTypeFilterAnchorEl(null);
  };

  const handleSelectAllEnv = (event: any) => {
    if (event.target.checked) {
      setEnvCheckedList(envNames);
    } else {
      setEnvCheckedList([]);
    }
  };

  const handleEnvTypeCheck = (event: any, name: string) => {
    if (event.target.checked) {
      setEnvCheckedList((prev) => [...prev, name]);
    } else {
      setEnvCheckedList((prev) => {
        return filter(prev, (item) => item !== name);
      });
    }
  };

  React.useEffect(() => {
    if (isEnvironmentsLoading) {
      toggleShouldEnvironmentsRefresh();
      setIsEnvironmentLoading(false);
    }
  }, [isEnvironmentsLoading, setIsEnvironmentLoading, toggleShouldEnvironmentsRefresh]);

  const watchEnvironment = (environmentParam: $TSFixMe) => {
    if (environmentParam?.id) {
      setWatchingEnvsStore([...watchingEnvsStore, environmentParam]);
    }
  };

  const handleDeleteV2 = async () => {
    setIsDeleting(true);
    try {
      await deleteEnvironment(environmentState?.id);

      toastWrapper({
        type: "success",
        content: "Environment deleted successfully!"
      });

      toggleShouldEnvironmentsRefresh();
    } catch (error) {
      console.error({ error });
    }
    setIsDeleting(false);
  };

  const handleEdit = (envId: string) => {
    navigate(generatePath(WebPaths.EnvironmentConfig, { envId }));
  };

  const handleOpenModalEnv = () => {
    setOpenModalEnvironment(true);
  };

  const handleClose = () => {
    setOpenModalEnvironment(false);
  };

  const handleCloseModal = async () => {
    if (environmentState?.projects?.length > 0) {
      toastWrapper({
        type: "error",
        content: `Cannot delete this environment because it is currently used in other project(s) [${environmentState?.projects?.join(
          ", "
        )}]`
      });

      setShowConfirmScreen(false);

      return;
    }

    await handleDeleteV2();
    setShowConfirmScreen(false);
  };

  const handleCancelClose = () => {
    setEnvironmentState({});
    setShowConfirmScreen(false);
  };

  const handleDeleteProcessV2 = (env: $TSFixMe) => {
    setEnvironmentState(env);
    setShowConfirmScreen(true);
  };

  const onSearch = ({ target: { value } }: $TSFixMe) => {
    setSearchValue(value);
  };

  const handleRelaunch = async (body: Environment) => {
    const toastId = envRelaunchNotification(body?.shutdownStrategy?.inactivityInHours);

    const prevLaunchStatus = body?.launchStatus;

    setEnvironments(
      environments?.map((env: $TSFixMe) => {
        let thisEnv = env;

        if (thisEnv?.id === body?.id) {
          thisEnv.launchStatus = EnvironmentStatuses.Launching;
        }

        return thisEnv;
      })
    );

    try {
      const thisEnvironment = await relaunchEnvironment(body?.id, true, body);
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!thisEnvironment) {
        if (thisEnvironment?.id) {
          watchEnvironment(thisEnvironment);
        }
      }
    } catch (error) {
      toast.dismiss(toastId);

      console.error({ error });

      setEnvironments(
        environments?.map((env: $TSFixMe) => {
          let thisEnv = env;

          if (thisEnv?.id === body?.id) {
            thisEnv.launchStatus = prevLaunchStatus;
          }

          return thisEnv;
        })
      );
    }
  };

  const stopEnv = async (body: Environment) => {
    try {
      await stopEnvironment(body?.id);

      toggleShouldEnvironmentsRefresh();
      setEnvironmentState(() => ({ ...body, launchStatus: EnvironmentStatuses.Inactive }));
    } catch (error) {
      console.error({ error });
    } finally {
      setStoppingEnvironments((prevState: string[]) =>
        prevState?.filter((envId: string) => envId !== body?.id)
      );
    }
  };

  const resetWatchingEnvsStore = () => {
    clearInterval(watchingEnvsIntervalIdStore);
    setWatchingEnvsStore([]);
  };

  const getFilteredWatchingEnvsLaunching = (environments: $TSFixMe) =>
    environments?.filter(
      (env: $TSFixMe) =>
        env?.launchStatus?.trim()?.toLowerCase() === EnvironmentStatuses.Launching.toLowerCase()
    );

  const getEnvironment = async () => {
    Promise.all(
      watchingEnvsStore.map(async (env: $TSFixMe) => {
        const thisEnvironments = await getEnvironmentById(env.id);

        if (thisEnvironments?.length > 0) {
          return thisEnvironments[0];
        } else {
          return [];
        }
      })
    )
      .then(async (thisEnvironments) => {
        const hasStatusChanged = thisEnvironments.reduce((acc, env) => {
          const previousEnv = environments.find((item: $TSFixMe) => item?.id === env?.id);
          return (
            previousEnv?.launchStatus?.trim()?.toLowerCase() !==
              env?.launchStatus?.trim()?.toLowerCase() || acc
          );
        }, false);
        if (hasStatusChanged) {
          const filteredWatchingEnvsLaunching =
            getFilteredWatchingEnvsLaunching(thisEnvironments) || [];

          setWatchingEnvsStore(filteredWatchingEnvsLaunching);

          const thisEnvironmentIds = thisEnvironments?.map((thisEnv: $TSFixMe) => thisEnv?.id);

          const filteredEnvironments = environments?.filter(
            (thisEnv: $TSFixMe) => !thisEnvironmentIds?.includes(thisEnv?.id)
          );

          setEnvironments([...filteredEnvironments, ...thisEnvironments]);
          setEnvironmentState(thisEnvironments[0]);

          if (filteredWatchingEnvsLaunching?.length === 0) {
            resetWatchingEnvsStore();
          }
        }
      })
      .catch((error: $TSFixMe) => {
        console.error(error);

        resetWatchingEnvsStore();
        toggleShouldEnvironmentsRefresh();
      });
  };

  useEffect(() => {
    let intervalId: $TSFixMe = null;
    if (watchingEnvsIntervalIdStore !== null || isDeleting) {
      clearInterval(watchingEnvsIntervalIdStore);
    }

    if (watchingEnvsStore?.length > 0 && !isDeleting) {
      const filteredWatchingEnvsLaunching = getFilteredWatchingEnvsLaunching(watchingEnvsStore);

      if (filteredWatchingEnvsLaunching?.length === 0) {
        resetWatchingEnvsStore();
      } else {
        // $FixMe: Below API can be refactored with better polling approach.
        // Watching for environment changes
        intervalId = setInterval(() => {
          getEnvironment();
        }, 2000);

        setWatchingEnvsIntervalIdStore(intervalId);
      }
    }

    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
      }

      if (watchingEnvsIntervalIdStore !== null) {
        clearInterval(watchingEnvsIntervalIdStore);
      }
    };
  }, [watchingEnvsStore, isDeleting]);

  useEffect(() => {
    if (environments?.length > 0) {
      const filteredWatchingEnvsLaunching = getFilteredWatchingEnvsLaunching(environments);

      if (filteredWatchingEnvsLaunching?.length === 0) {
        resetWatchingEnvsStore();
      } else {
        setWatchingEnvsStore(filteredWatchingEnvsLaunching);
      }
    }
  }, [environments]);

  const parsedList = useMemo(() => {
    return filter(environments, (eachEnvironment: Environment) => {
      const matchesSearch = searchValue
        ? eachEnvironment?.name?.toLowerCase().includes(searchValue?.toLowerCase())
        : true;

      const matchesChecked = envCheckedList.includes(eachEnvironment.envType);

      return matchesSearch && matchesChecked;
    });
  }, [searchValue, environments, envCheckedList]);

  const viewLogs = (env: $TSFixMe) => {
    setEnvironmentState(env);
    setLogsOpen(true);
  };

  const viewUsage = (env: $TSFixMe) => {
    setEnvironmentState(env);
    setIsEnvUsageOpen(true);
  };

  const onConfirmStopEnvClose = () => {
    setStoppingEnvironments((prevState: string[]) =>
      prevState?.filter((envId: string) => envId !== environmentState?.id)
    );

    setEnvironmentState(() => ({}));
    setConfirmEnvAction(null);
  };

  const onConfirmStopEnv = async () => {
    setConfirmEnvAction(null);

    await stopEnv(environmentState);

    setEnvironmentState(() => ({}));
  };

  const onStopEnv = async (body: Environment) => {
    setStoppingEnvironments((prevState: string[]) => [...prevState, body?.id]);
    setEnvironmentState(body);
    const types = await getEnvironmentUsageDetails(body?.id);
    if (isEmpty(types)) {
      await stopEnv(body);
    } else {
      setConfirmEnvAction({ types, action: "stopping" });
    }
  };

  const availableWindowSize = window.innerWidth * (1 - EnvironmentsConfig.ExemptingWidth);
  const numberOfCards = Math.floor(
    (availableWindowSize + 24) / (EnvironmentsConfig.CardWidth + 24)
  );

  const startLoc = tilesView
    ? (availableWindowSize + 24 - numberOfCards * (EnvironmentsConfig.CardWidth + 24)) / 2
    : 0;

  return (
    <>
      <EnvironmentConfig
        isOpen={isEnvUsageOpen}
        environment={environmentState}
        stoppingEnvironments={stoppingEnvironments}
        onUpdate={() => toggleShouldEnvironmentsRefresh()}
        onClose={() => setIsEnvUsageOpen(() => false)}
      />
      <Menu
        id="dataSourcesTypesFilter"
        anchorEl={envTypeFilterAnchorEl}
        open={Boolean(envTypeFilterAnchorEl)}
        onClose={closeEnvTypesFilter}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}>
        <MenuItem key="data_sources_types_select_all">
          <Checkbox
            data-testid="filterSelectAll"
            color="default"
            checked={isEqual(size(envCheckedList), size(envNames))}
            onClick={handleSelectAllEnv}
          />
          {DataSourcesHelperText.SelectAll}
        </MenuItem>
        {environmentsTypes?.map((item, index: number) => (
          <MenuItem key={`data_source_type_${index}`}>
            <Checkbox
              data-testid={`dataConnector${index + 1}`}
              checked={includes(envCheckedList, item.name)}
              color="default"
              onClick={(event) => {
                handleEnvTypeCheck(event, item.name);
              }}
            />
            <div>{item.name}</div>
          </MenuItem>
        ))}
      </Menu>

      <EnvironmentLogsDrawer
        open={logsOpen}
        environment={environmentState}
        onClose={() => setLogsOpen(false)}
      />

      {showConfirmScreen && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title="Delete Environment"
          content={[EnvironmentDeleteMessage.messageLine1, EnvironmentDeleteMessage.messageLine2]}
          isSubmitting={isDeleting}
          onClose={handleCancelClose}
          onSubmit={handleCloseModal}
        />
      )}

      {confirmEnvAction && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title="Are you sure?"
          content={[
            `Please note that there are active processes within this environment, such as ${
              !!confirmEnvAction ? createString(confirmEnvAction.types) : ""
            }. Continuing with ${
              !!confirmEnvAction ? confirmEnvAction?.action : ""
            } the environment may affect these ongoing operations`
          ]}
          onClose={onConfirmStopEnvClose}
          onSubmit={onConfirmStopEnv}
        />
      )}

      <EnvironmentsHeader isAddActionDisabled={isFetching} onAddAction={handleOpenModalEnv} />

      {openModalEnvironment && (
        <CreateEnvironment
          open={true}
          isEnvironmentsTypesFetching={isEnvironmentsTypesFetching}
          environmentsTypes={environmentsTypes || []}
          onClose={handleClose}
          watchEnvironment={watchEnvironment}
          refetch={refetch}
        />
      )}

      {isFetching ? (
        <Spinner />
      ) : (
        <Grid
          item
          container
          direction="column"
          wrap="nowrap"
          xs={12}
          className={classes.root}
          style={{
            backgroundImage: `url(${backgroundTopImg}), url(${backgroundBottomImg})`,
            backgroundSize: "100% auto, 100% auto",
            backgroundRepeat: "no-repeat, no-repeat",
            backgroundPosition: "right top, right bottom"
          }}>
          <Grid container style={{ padding: `0 ${startLoc}px`, width: availableWindowSize }}>
            {!isEmpty(parsedList) && (
              <Grid item>
                <Typography variant="h5" className={classes.title}>
                  Environments
                </Typography>
                <Typography variant="subtitle1">
                  Showing {parsedList.length || 0} Environments
                </Typography>
              </Grid>
            )}

            <Grid className={classes.topActions}>
              {environments?.length > 0 && (
                <>
                  <Search onSearch={onSearch} placeholder="Search environments" />
                  <IconButton
                    data-testid="dataConnectorFilterIcon"
                    onClick={toggleEnvTypeFilter}
                    disabled={environmentsTypes?.length === 0}>
                    <Badge color="error" variant="dot" invisible={!isFiltered}>
                      <FilterIcon />
                    </Badge>
                  </IconButton>

                  <ToggleView isPrimaryView={tilesView} setIsPrimaryView={toggleView} />
                </>
              )}
            </Grid>
          </Grid>
          {isEmpty(parsedList) ? (
            <SplashSectionWrapper onClick={handleOpenModalEnv} />
          ) : (
            <Grid item style={{ padding: `16px ${startLoc}px`, width: availableWindowSize }}>
              {tilesView ? (
                <EnvironmentCards
                  environmentsTypes={environmentsTypes || []}
                  list={parsedList}
                  onDeleteEnv={handleDeleteProcessV2}
                  onEditEnv={handleEdit}
                  onRelaunchEnv={handleRelaunch}
                  stoppingEnvironments={stoppingEnvironments}
                  onStopEnv={onStopEnv}
                  logs={viewLogs}
                  usage={viewUsage}
                />
              ) : (
                <EnvironmentTable
                  environmentsTypes={environmentsTypes || []}
                  handleSearch={onSearch}
                  dataEnvironments={parsedList}
                  onDeleteEnv={handleDeleteProcessV2}
                  onEditEnv={handleEdit}
                  onRelaunch={handleRelaunch}
                  stoppingEnvironments={stoppingEnvironments}
                  onStopEnv={onStopEnv}
                  logs={viewLogs}
                  usage={viewUsage}
                />
              )}
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

Environments.propTypes = {
  environments: PropTypes.array,
  isLoading: PropTypes.bool
};

export default Environments;
