/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { DFSRunConfigGroupDto } from './dfsrun-config-group-dto';
// May contain unused imports in some cases
// @ts-ignore
import { OutEntityInfo } from './out-entity-info';
// May contain unused imports in some cases
// @ts-ignore
import { RecipeRunResult } from './recipe-run-result';

/**
 * 
 * @export
 * @interface ProjectRunEntryDto
 */
export interface ProjectRunEntryDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectRunEntryDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRunEntryDto
     */
    'created'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRunEntryDto
     */
    'updated'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunEntryDto
     */
    'status'?: ProjectRunEntryDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunEntryDto
     */
    'trigger'?: ProjectRunEntryDtoTriggerEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunEntryDto
     */
    'error'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProjectRunEntryDto
     */
    'variables'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ProjectRunEntryDto
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunEntryDto
     */
    'updater'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunEntryDto
     */
    'runId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRunEntryDto
     */
    'endTime'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectRunEntryDto
     */
    'outEntityNames'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunEntryDto
     */
    'scenarioId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunEntryDto
     */
    'path'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProjectRunEntryDto
     */
    'computedVariables'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: RecipeRunResult; }}
     * @memberof ProjectRunEntryDto
     */
    'recipeRunInfo'?: { [key: string]: RecipeRunResult; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProjectRunEntryDto
     */
    'recipeNameToId'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: DFSRunConfigGroupDto; }}
     * @memberof ProjectRunEntryDto
     */
    'allRecipeDetails'?: { [key: string]: DFSRunConfigGroupDto; };
    /**
     * 
     * @type {Array<OutEntityInfo>}
     * @memberof ProjectRunEntryDto
     */
    'outEntityInfo'?: Array<OutEntityInfo>;
}

export const ProjectRunEntryDtoStatusEnum = {
    Created: 'CREATED',
    Started: 'STARTED',
    ComputeVariables: 'COMPUTE_VARIABLES',
    EntityLoading: 'ENTITY_LOADING',
    RecipeRunning: 'RECIPE_RUNNING',
    Success: 'SUCCESS',
    Failure: 'FAILURE',
    SuccessWithWarn: 'SUCCESS_WITH_WARN',
    TimedOut: 'TIMED_OUT',
    RecipeTimedOut: 'RECIPE_TIMED_OUT'
} as const;

export type ProjectRunEntryDtoStatusEnum = typeof ProjectRunEntryDtoStatusEnum[keyof typeof ProjectRunEntryDtoStatusEnum];
export const ProjectRunEntryDtoTriggerEnum = {
    Cron: 'CRON',
    Manual: 'MANUAL'
} as const;

export type ProjectRunEntryDtoTriggerEnum = typeof ProjectRunEntryDtoTriggerEnum[keyof typeof ProjectRunEntryDtoTriggerEnum];


