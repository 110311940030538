/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Config
 */
export interface Config {
    /**
     * 
     * @type {string}
     * @memberof Config
     */
    'llmModel'?: ConfigLlmModelEnum;
    /**
     * 
     * @type {string}
     * @memberof Config
     */
    'inputType'?: ConfigInputTypeEnum;
}

export const ConfigLlmModelEnum = {
    OpenaiGpt: 'OPENAI_GPT'
} as const;

export type ConfigLlmModelEnum = typeof ConfigLlmModelEnum[keyof typeof ConfigLlmModelEnum];
export const ConfigInputTypeEnum = {
    ProjectCanvas: 'PROJECT_CANVAS',
    Dataset: 'DATASET',
    SqlDb: 'SQL_DB'
} as const;

export type ConfigInputTypeEnum = typeof ConfigInputTypeEnum[keyof typeof ConfigInputTypeEnum];


