/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { BaseTransform } from './base-transform';
// May contain unused imports in some cases
// @ts-ignore
import { DFSInput } from './dfsinput';
// May contain unused imports in some cases
// @ts-ignore
import { PipelineEntityFeature } from './pipeline-entity-feature';

/**
 * 
 * @export
 * @interface DFSTemplateDto
 */
export interface DFSTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'type'?: DFSTemplateDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'conditionExpression'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'markdown'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'source'?: DFSTemplateDtoSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'category'?: DFSTemplateDtoCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'requirements'?: string;
    /**
     * 
     * @type {number}
     * @memberof DFSTemplateDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'status'?: DFSTemplateDtoStatusEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DFSTemplateDto
     */
    'variableExprMap'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: Array<PipelineEntityFeature>; }}
     * @memberof DFSTemplateDto
     */
    'fieldsTemplate'?: { [key: string]: Array<PipelineEntityFeature>; };
    /**
     * 
     * @type {Array<BaseTransform>}
     * @memberof DFSTemplateDto
     */
    'baseTransforms'?: Array<BaseTransform>;
    /**
     * 
     * @type {Array<DFSInput>}
     * @memberof DFSTemplateDto
     */
    'inputs'?: Array<DFSInput>;
    /**
     * 
     * @type {Set<string>}
     * @memberof DFSTemplateDto
     */
    'tags'?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof DFSTemplateDto
     */
    'secondaryTags'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'templateLevel'?: DFSTemplateDtoTemplateLevelEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DFSTemplateDto
     */
    'shouldUploadNotebook'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'descriptionHtml'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSTemplateDto
     */
    'display_name'?: string;
}

export const DFSTemplateDtoTypeEnum = {
    Notebook: 'NOTEBOOK',
    PythonFunc: 'PYTHON_FUNC',
    Code: 'CODE'
} as const;

export type DFSTemplateDtoTypeEnum = typeof DFSTemplateDtoTypeEnum[keyof typeof DFSTemplateDtoTypeEnum];
export const DFSTemplateDtoSourceEnum = {
    System: 'SYSTEM',
    Project: 'PROJECT',
    Tenant: 'TENANT',
    Custom: 'CUSTOM'
} as const;

export type DFSTemplateDtoSourceEnum = typeof DFSTemplateDtoSourceEnum[keyof typeof DFSTemplateDtoSourceEnum];
export const DFSTemplateDtoCategoryEnum = {
    Basic: 'BASIC',
    Advanced: 'ADVANCED'
} as const;

export type DFSTemplateDtoCategoryEnum = typeof DFSTemplateDtoCategoryEnum[keyof typeof DFSTemplateDtoCategoryEnum];
export const DFSTemplateDtoStatusEnum = {
    Active: 'ACTIVE',
    Disabled: 'DISABLED'
} as const;

export type DFSTemplateDtoStatusEnum = typeof DFSTemplateDtoStatusEnum[keyof typeof DFSTemplateDtoStatusEnum];
export const DFSTemplateDtoTemplateLevelEnum = {
    Feature: 'FEATURE',
    Entity: 'ENTITY',
    Project: 'PROJECT'
} as const;

export type DFSTemplateDtoTemplateLevelEnum = typeof DFSTemplateDtoTemplateLevelEnum[keyof typeof DFSTemplateDtoTemplateLevelEnum];


