/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { QueryInputDto } from './query-input-dto';

/**
 * 
 * @export
 * @interface AIGuideChatRequestDto
 */
export interface AIGuideChatRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AIGuideChatRequestDto
     */
    'targetId': string;
    /**
     * 
     * @type {string}
     * @memberof AIGuideChatRequestDto
     */
    'targetType': AIGuideChatRequestDtoTargetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AIGuideChatRequestDto
     */
    'useCase'?: AIGuideChatRequestDtoUseCaseEnum;
    /**
     * 
     * @type {string}
     * @memberof AIGuideChatRequestDto
     */
    'outputType'?: AIGuideChatRequestDtoOutputTypeEnum;
    /**
     * 
     * @type {QueryInputDto}
     * @memberof AIGuideChatRequestDto
     */
    'queryInput': QueryInputDto;
    /**
     * 
     * @type {string}
     * @memberof AIGuideChatRequestDto
     */
    'query': string;
}

export const AIGuideChatRequestDtoTargetTypeEnum = {
    CanvasNode: 'CANVAS_NODE',
    RapidModelRecipe: 'RAPID_MODEL_RECIPE'
} as const;

export type AIGuideChatRequestDtoTargetTypeEnum = typeof AIGuideChatRequestDtoTargetTypeEnum[keyof typeof AIGuideChatRequestDtoTargetTypeEnum];
export const AIGuideChatRequestDtoUseCaseEnum = {
    ConversationUseCase: 'CONVERSATION_USE_CASE',
    ProblemTypeUseCase: 'PROBLEM_TYPE_USE_CASE',
    TargetColumnUseCase: 'TARGET_COLUMN_USE_CASE',
    OneShotUseCase: 'ONE_SHOT_USE_CASE',
    PromptSuggestionsUseCase: 'PROMPT_SUGGESTIONS_USE_CASE'
} as const;

export type AIGuideChatRequestDtoUseCaseEnum = typeof AIGuideChatRequestDtoUseCaseEnum[keyof typeof AIGuideChatRequestDtoUseCaseEnum];
export const AIGuideChatRequestDtoOutputTypeEnum = {
    Text: 'TEXT',
    Chart: 'CHART',
    Image: 'IMAGE',
    Dataset: 'DATASET',
    TextNoCode: 'TEXT_NO_CODE',
    Function: 'FUNCTION',
    Unrecognized: 'UNRECOGNIZED',
    AutoInfer: 'AUTO_INFER',
    Model: 'MODEL'
} as const;

export type AIGuideChatRequestDtoOutputTypeEnum = typeof AIGuideChatRequestDtoOutputTypeEnum[keyof typeof AIGuideChatRequestDtoOutputTypeEnum];


