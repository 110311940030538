import React, { useEffect, useState } from "react";
import { Grid, MenuItem, Typography, makeStyles } from "@material-ui/core";

import Text from "src/components/Widget/Text";
import { Field } from "../../../../../components";

import {
  getCoreSet,
  getDiskSpaceSet,
  getMemorySet
} from "src/pages/Projects/helpers/projects.helpers";

import {
  EnvironmentType,
  EnvironmentsTypes
} from "../../../../../constants/environments.constants";
import styles from "../../Environments.module.scss";
import EnvironmentTypeConfig from "../EnvironmentTypeConfig";

import {
  getEnvironmentConfigSession,
  setEnvironmentConfigSession
} from "../../utils/environments.helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 142px)",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: 12,
    rowGap: theme.spacing(2.5)
  },
  dropdown: {
    maxHeight: 200
  }
}));

type Props = {
  isEnvironmentsTypesFetching: boolean;
  environmentsTypes: $TSFixMe[];
  nameError: string;
  setNameError: Function;
  readonly?: boolean;
  setTimer?: $TSFixMe;
};

export const EnvironmentInputs = ({
  isEnvironmentsTypesFetching,
  environmentsTypes,
  setTimer
}: Props) => {
  const classes = useStyles();

  const [typeOption, setTypeOption] = useState();
  const [values, setValues] = useState(getEnvironmentConfigSession() || {});

  useEffect(() => setEnvironmentConfigSession({ ...values }), [values]);

  const handleChange = (event: $TSFixMe, id?: string) => {
    const { name, value } = event?.target;

    const thisValues = { ...(getEnvironmentConfigSession() || {}) };

    if (id === "envType" && event?.target?.value !== EnvironmentsTypes.Custom) {
      thisValues["envType"] = value;
      thisValues["cores"] = "";
      thisValues["memory"] = "";
      thisValues["diskSpace"] = "";
    } else {
      setValues({
        ...values,
        [name]: value
      });

      thisValues[name] = value;
    }

    setValues(() => ({ ...thisValues }));

    setEnvironmentConfigSession({ ...thisValues });

    setTimer(() => new Date().getTime());
  };

  useEffect(() => {
    if (!isEnvironmentsTypesFetching && values) {
      setTypeOption(
        (environmentsTypes || [])?.find(
          (env: EnvironmentType) => env.name.toLowerCase() === values.envType
        )
      );
    }
  }, [isEnvironmentsTypesFetching, environmentsTypes]);

  return (
    <Grid container alignContent="flex-start" className={classes.root}>
      <Grid item xs={12}>
        <Field
          id="description"
          label="Environment Description"
          variant="outlined"
          size="small"
          fullWidth
          onChange={handleChange}
          multiline
          minRows={4}
          maxRows={4}
          value={getEnvironmentConfigSession().description}
        />
        <Typography variant="caption" className={styles.formTypographyBody2}>
          Provide additional information about this environment.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Field
          id="envType"
          label="Environment Type"
          variant="outlined"
          size="small"
          fullWidth
          required
          select
          onChange={(event: $TSFixMe) => {
            handleChange(event, "envType");
          }}
          minRows={4}
          maxRows={4}
          value={getEnvironmentConfigSession().envType}
          SelectProps={{ MenuProps: { classes: { paper: `${classes.dropdown} ` } } }}>
          {(environmentsTypes || [])
            // ?.filter((thisEnvType: $TSFixMe) => thisEnvType?.name !== EnvironmentsTypes.Custom)
            ?.map((typeOption: EnvironmentType) => {
              return (
                <MenuItem
                  key={typeOption.name}
                  value={typeOption.name}
                  //@ts-expect-error
                  onClick={() => setTypeOption(typeOption)}>
                  <Text value={typeOption.name} />
                </MenuItem>
              );
            })}
        </Field>
        {getEnvironmentConfigSession().envType !== EnvironmentsTypes.Custom && typeOption ? (
          <Typography variant="caption" className={styles.formTypographyBody2}>
            <EnvironmentTypeConfig
              cores={(typeOption as EnvironmentType).cores}
              memInMbs={(typeOption as EnvironmentType).memInMbs}
              diskInGbs={(typeOption as EnvironmentType).diskInGbs}
            />
          </Typography>
        ) : null}
      </Grid>
      {getEnvironmentConfigSession().envType === EnvironmentsTypes.Custom && (
        <>
          <Grid container justifyContent="space-between">
            <Grid item style={{ width: "47.5%" }}>
              <Field
                id="cores"
                label="Cores"
                variant="outlined"
                size="small"
                fullWidth
                required
                select
                onChange={(event: $TSFixMe) => {
                  handleChange(event);
                }}
                minRows={4}
                maxRows={4}
                value={getEnvironmentConfigSession().cores}
                SelectProps={{ MenuProps: { classes: { paper: `${classes.dropdown} ` } } }}>
                {getCoreSet().map((eachCore: $TSFixMe) => {
                  return (
                    <MenuItem key={`core_${eachCore}`} value={eachCore}>
                      {eachCore}
                    </MenuItem>
                  );
                })}
              </Field>
            </Grid>
            <Grid item style={{ width: "47.5%" }}>
              <Field
                id="memory"
                label="Memory"
                variant="outlined"
                size="small"
                fullWidth
                required
                select
                onChange={(event: $TSFixMe) => {
                  handleChange(event);
                }}
                minRows={4}
                maxRows={4}
                value={getEnvironmentConfigSession().memory}
                SelectProps={{ MenuProps: { classes: { paper: `${classes.dropdown} ` } } }}>
                {getMemorySet().map((eachMemory: $TSFixMe) => {
                  return (
                    <MenuItem key={`memory_${eachMemory}`} value={eachMemory}>
                      {`${eachMemory} GB`}
                    </MenuItem>
                  );
                })}
              </Field>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Field
              id="diskSpace"
              label="Disk Space"
              variant="outlined"
              size="small"
              fullWidth
              required
              select
              onChange={handleChange}
              minRows={4}
              maxRows={4}
              value={getEnvironmentConfigSession().diskSpace}
              SelectProps={{ MenuProps: { classes: { paper: `${classes.dropdown} ` } } }}>
              {getDiskSpaceSet().map((eachDiskSpace: $TSFixMe) => {
                return (
                  <MenuItem key={`diskSpace_${eachDiskSpace}`} value={eachDiskSpace}>
                    {`${eachDiskSpace} GB`}
                  </MenuItem>
                );
              })}
            </Field>
          </Grid>
        </>
      )}
    </Grid>
  );
};
