import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  Typography,
  Button,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import _, { isEmpty } from "lodash";

import Text from "src/components/Widget/Text";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import { Field, ConfirmScreen } from "../../../components";
import { useDrawerStore, useProjectsStore } from "../../../store/store";
import { shouldRefreshProjectsToggler, sideComponentSetter } from "../../../store/store.selectors";
import useRelaunchEnvironment from "src/hooks/api/environments/useRelaunchEnvironment";
import {
  IProjectData,
  useGetEnvironmentTypes,
  useGetEnvironments,
  useGetProject,
  useGetVariables
} from "src/hooks/api";
import { useForm } from "../../../utils/useForm";
import {
  deleteAPIWithRethrow,
  postAPIWithRethrow,
  putAPIWithRethrow
} from "../../../utils/apiService";
import EnvironmentTypeConfig from "src/pages/private/Environments/components/EnvironmentTypeConfig";
import clsx from "clsx";
import { envMetadataFind } from "../helpers/projects.helpers";
import { validateNameField } from "src/utils/formFieldUtils";
import { toastWrapper } from "src/utils/toastWrapper";
import { Delete } from "src/icons/Delete";
import { PlusIcon } from "src/icons/PlusIcon";
import { useStyles } from "./styling";
import { statusValues } from "src/pages/private/Environments/components/StatusBar/StatusBar";
import styles from "src/pages/private/Environments/Environments.module.scss";
import { Skeleton } from "@material-ui/lab";
import { useGetRole } from "src/hooks/useGetRole";

import NewThemeWrapper from "src/styles/NewThemeWrapper";
import useDrawerStyles from "src/components/custom/Drawer/Drawer.styles";
import { EnvAction } from "pages/private/Environments/components/Environment";
import default_image from "src/assets/images/dashaboardCards/default_project_thumbnail1.svg";
import PreviewImageSelectorNew from "./PreviewImageSelectorNew";
import { ITypes } from "src/pages/private/Environments/utils/environments.helpers";
import { createString } from "src/helpers/helpers";

const ID_PREFIX = "project-settings";

type Variable = {
  name: string;
  value: string;
  id?: string;
};
type DataProps = {
  projectId: string;
  projects: $TSFixMe[];
};
type Props = {
  data: DataProps;
};

interface IThisProject extends IProjectData {
  useCase?: any[];
  industry?: any[];
  variables?: { name: string; value: string }[];
}

const ProjectSettings = ({ data }: Props) => {
  const classes = useStyles();

  const drawerClasses = useDrawerStyles();

  const relaunch = useRelaunchEnvironment();
  const setSideComponent = useDrawerStore(sideComponentSetter);

  const [isReadingProject, setIsReadingProject] = useState<$TSFixMe>(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [confirmEnvAction, setConfirmEnvAction] = useState<EnvAction | null>(null);
  const [selectingEnvId, setSelectingEnvId] = useState("");
  const [project, setProject] = useState<$TSFixMe>({});
  const [showProjectNameError, setShowProjectNameError] = useState<[boolean, string]>([false, ""]);
  const [variableNameErrors, setVariableNameErrors] = useState<{ [index: number]: string }>({});
  const [variableValueErrors, setVariableValueErrors] = useState<{ [index: number]: string }>({});

  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [selectedEnv, setSelectedEnv] = useState();
  const [imageBase64, setImageBase64] = useState<string | undefined>();
  const [globalVariables, setGlobalVariables] = useState<$TSFixMe>([]);

  const isDirty = useProjectsStore((state) => state.isDirty);
  const [toggleDirty, setReloadTrigger] = useProjectsStore((state) => [
    state.toggleDirty,
    state.setReloadTrigger
  ]);

  // Query hooks - STARTS >>
  const { isLoading: isFetchingEnvironmentTypes, data: environmentsTypes } =
    useGetEnvironmentTypes();

  const { isLoading: isFetchingEnvironments, data: environments } = useGetEnvironments({
    cacheTime: Infinity,
    refetchOnMount: true
  });
  // ToDo: Use Access Control Provider post drawer cleanup
  const { isBusinessUser } = useGetRole();

  const {
    isLoading: isFetchingProject,
    data: projectData,
    isSuccess: isProjectFetched,
    refetch: refetchProject
  } = useGetProject({
    projectId: data?.projectId,
    includeJobCount: true,
    cacheTime: Infinity,
    refetchOnMount: true
  });

  const {
    isLoading: isFetchingVariables,
    data: variablesData,
    isSuccess: isVariablesFetched
  } = useGetVariables({
    projectId: data?.projectId,
    cacheTime: Infinity,
    refetchOnMount: true
  });
  // << ENDS - Query hooks

  useEffect(() => {
    setIsReadingProject(() => true);
    if (!!data?.projectId) {
      const thisProject = data?.projects?.find(
        (eachProject: $TSFixMe) => eachProject?.id === data?.projectId
      );

      const img = thisProject?.image?.split(";base64,")?.pop() || thisProject?.image;

      img && setImageBase64(`data:image/jpeg;base64,${img}`);
      setProject(thisProject);
      setValues({ ...values, ...thisProject });

      setIsReadingProject(() => false);
    }
  }, [data?.projectId, data?.projects]);

  const { values, setValues, handleInputChange } = useForm({
    ...project
  });

  useEffect(() => {
    if ((environments || [])?.length > 0 && !!project?.envId) {
      setSelectedEnv(() =>
        environments?.find((eachEnv: $TSFixMe) => eachEnv?.id === project?.envId)
      );
    }
  }, [environments, project]);

  useEffect(() => {
    if (isProjectFetched && isVariablesFetched) {
      if (typeof projectData === "object") {
        const thisProject: IThisProject = _.cloneDeep(projectData);
        const img = thisProject?.image?.split(";base64,")?.pop() || thisProject?.image;
        img && setImageBase64(`data:image/jpeg;base64,${img}`);

        setGlobalVariables(() => variablesData || []);

        if ((variablesData || [])?.length > 0) {
          thisProject.variables = [...variablesData, { name: "", value: "" }];
        } else {
          thisProject.variables = [{ name: "", value: "" }];
        }

        setProject(thisProject);
        setValues({ ...values, ...thisProject });
      }
    }
  }, [isProjectFetched, isVariablesFetched, projectData, variablesData]);

  const validFields = () => {
    let isValid = true;
    const { isValid: isNameValid, error } = validateNameField({
      fieldName: (values as $TSFixMe)?.name,
      fieldNameLabel: `project name`
    });
    if (!isNameValid && error) {
      setShowProjectNameError([true, error]);
      isValid = false;
    } else if (nameExist()) {
      setShowProjectNameError([true, "This project name exists"]);
      isValid = false;
    } else {
      setShowProjectNameError([false, ""]);
    }

    const variables = (values as $TSFixMe)?.variables;
    const variableNames = variables?.map((variable: Variable) => variable?.name);
    const nameErrors: { [index: number]: string } = {};
    const valueErrors: { [index: number]: string } = {};

    variables?.forEach((variable: Variable, index: number) => {
      if (variable?.name?.length === 0 && variable?.value?.length === 0) {
        return;
      }
      if (variable?.value?.length === 0) {
        valueErrors[index] = "Key Value cannot be blank";
      }
      const { error: nameError } = validateNameField({
        fieldName: variable?.name,
        fieldNameLabel: `global variable name`
      });
      if (nameError) {
        nameErrors[index] = nameError;
      }
      const firstIndexOfName = variableNames?.indexOf(variable?.name);
      if (firstIndexOfName !== index) {
        nameErrors[firstIndexOfName] = "This Variable name has a duplicate name";
        nameErrors[index] = "This Variable name has a duplicate name";
      }
    });
    if (!!Object.keys(nameErrors).length || !!Object.keys(valueErrors).length) {
      setVariableNameErrors(nameErrors);
      setVariableValueErrors(valueErrors);
      isValid = false;
    }

    return isValid;
  };

  const toggleShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsToggler);

  useEffect(() => {
    showProjectNameError[0] && setShowProjectNameError([false, ""]);
  }, [open]); // eslint-disable-line

  const nameExist = () => {
    return data?.projects?.some((project: $TSFixMe) => {
      return (
        project.name.toLowerCase() === (values as $TSFixMe).name.toLowerCase().trim() &&
        project.id !== data?.projectId
      );
    });
  };

  const onDelete = (index: number) => {
    const newVariables = [...(values as $TSFixMe).variables];
    newVariables.splice(index, 1);
    toggleDirty(true);
    setValues({ ...values, variables: newVariables });
  };

  const onAdd = () => {
    setValues({
      ...values,
      variables: [...(values as $TSFixMe).variables, { name: "", value: "" }]
    });
  };

  const handleSubmit = async (e: $TSFixMe) => {
    setIsUpdating(true);

    e.preventDefault();
    if (validFields()) {
      const { name, description, variables, askAIContext } = values as $TSFixMe;
      const valuesToSend = {
        ...values,
        name: name.trim(),
        description: description?.trim(),
        envId: "",
        image: imageBase64,
        display_name: name.trim(),
        askAIContext: askAIContext?.trim()
      };

      if (selectedEnv) {
        (valuesToSend as $TSFixMe).envId = (selectedEnv as $TSFixMe).id;
      }
      (valuesToSend as $TSFixMe).industries = (values as $TSFixMe)?.industry?.map(
        (industryItem: $TSFixMe) => industryItem?.value?.toUpperCase()
      );
      (valuesToSend as $TSFixMe).useCases = (values as $TSFixMe)?.useCase?.map(
        (useCaseItem: $TSFixMe) => useCaseItem?.value?.toUpperCase()
      );
      delete (valuesToSend as $TSFixMe).useCase;
      delete (valuesToSend as $TSFixMe).industry;
      delete (valuesToSend as $TSFixMe).variables;

      const variablesToCreate: Variable[] = variables ? [...variables] : [];
      const variablesToUpdate: Variable[] = [];
      const variablesToDelete: string[] = [];
      try {
        const variableNames = variables?.map((variable: Variable) => variable?.name);
        globalVariables?.forEach((oldVariable: Variable) => {
          if (variableNames?.includes(oldVariable?.name)) {
            const newVariableIndex = variablesToCreate?.findIndex(
              (variable: Variable) => variable?.name === oldVariable?.name
            );
            if (variablesToCreate[newVariableIndex]?.value !== oldVariable.value) {
              variablesToUpdate.push({
                ...oldVariable,
                value: variablesToCreate[newVariableIndex]?.value
              });
            }
            variablesToCreate.splice(newVariableIndex, 1);
          } else {
            oldVariable?.id && variablesToDelete.push(oldVariable.id);
          }
        });
      } catch (e: $TSFixMe) {
        toastWrapper({
          type: "error",
          content: e?.message || "There's an error in global variables"
        });
        setIsUpdating(false);
        return;
      }

      try {
        await putAPIWithRethrow("/v2/projects", valuesToSend);
        try {
          await Promise.all(
            variablesToUpdate.map((variable: Variable) => {
              return putAPIWithRethrow(
                `/v2/variables/${variable.id}`,
                {
                  name: variable.name,
                  type: "STRING",
                  value: variable.value,
                  projectId: data?.projectId
                },
                {}
              );
            })
          );
          await Promise.all(
            variablesToDelete.map((id: string) => {
              // @ts-expect-error
              return deleteAPIWithRethrow(`/v2/variables/${id}`);
            })
          );
          await Promise.all(
            variablesToCreate
              .filter((variable: Variable) => variable?.name?.length > 0)
              .map((variable: Variable) => {
                return postAPIWithRethrow(
                  "/v2/variables",
                  {
                    name: variable?.name,
                    type: "STRING",
                    value: variable?.value,
                    projectId: data?.projectId
                  },
                  {}
                );
              })
          );

          refetchProject();
          setReloadTrigger();
        } catch (error) {
          console.error("error", error);

          setIsUpdating(false);
          return;
        }
        toggleShouldProjectsRefresh();
        setSideComponent({
          sideComponent: null,
          sidecomponentProps: null
        });
        toastWrapper({
          type: "success",
          content: "Settings saved"
        });
        relaunch.mutate({ envId: (selectedEnv as any).id });
      } catch (error) {
        console.error("error", error);
        setIsUpdating(false);
      }
    }

    setIsUpdating(false);
  };

  const handleChange = (event: $TSFixMe, id?: string) => {
    if (id === "name") {
      setShowProjectNameError([false, ""]);
    }
    handleInputChange(event);
    toggleDirty(true);
  };

  const handleVariableChange = (event: $TSFixMe, index: number, type: "name" | "value") => {
    setVariableNameErrors({});
    setVariableValueErrors({});
    const newVariables = [...(values as $TSFixMe).variables];
    newVariables[index] = { ...newVariables[index], [type]: event?.target?.value };
    toggleDirty(true);
    setValues({ ...values, variables: newVariables });
  };

  const environmentChange = (envId = "") => {
    const env = envId
      ? environments?.find((item: $TSFixMe) => item?.id === envId) || {}
      : environments?.find((item: $TSFixMe) => item?.id === selectingEnvId) || {};

    toggleDirty(true);
    setSelectedEnv(env);
    setSelectingEnvId(() => "");
  };

  const checkForRunningJobs = async (envId: $TSFixMe) => {
    const types: ITypes = [];

    if (project?.runningJobCount > 0) {
      types.push("running job(s)");
    }

    if (project?.runningPredictionJobCount > 0) {
      types.push("prediction job(s)");
    }

    if (project?.runningRecipeCount > 0) {
      types.push("active recipe(s)");
    }

    if (isEmpty(types)) {
      environmentChange(envId);
    } else {
      setConfirmEnvAction({ types, action: "changing" });
    }
  };

  const onEnvironmentChange = (e: $TSFixMe) => {
    setSelectingEnvId(() => e?.target?.value);
    checkForRunningJobs(e?.target?.value);
  };

  const onConfirmUpdateClose = () => {
    setSelectingEnvId(() => "");
    setConfirmEnvAction(null);
  };

  const onConfirmUpdate = () => {
    environmentChange();
    setSelectingEnvId(() => "");
    setConfirmEnvAction(null);
  };

  const handleImageChange = (img: $TSFixMe) => {
    toggleDirty(true);
    setImageBase64(img);
  };
  const handleCloseModal = () => {
    toggleDirty(false);
    setShowConfirmScreen(false);
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  const handleCloseAttempt = () => {
    if (isDirty) {
      return setShowConfirmScreen(true);
    } else {
      setSideComponent({
        sideComponent: null,
        sideComponentProps: null
      });
    }
  };

  const handleCancelClose = () => {
    setShowConfirmScreen(false);
  };

  // $FixMe: RC-1305: The isDirty is not getting updated immediately, to show confirm navigate form.
  // Applied a work-around, will come back to improve it.
  useEffect(() => () => toggleDirty(false), []);

  return (
    <>
      {confirmEnvAction && (
        <NewThemeWrapper>
          <Modal
            open={true}
            variant={ModalVariants.Delete}
            title="Are you sure?"
            content={[
              `Please note that there are active processes within this environment, such as ${
                !!confirmEnvAction ? createString(confirmEnvAction.types) : ""
              }. Continuing with ${
                !!confirmEnvAction ? confirmEnvAction?.action : ""
              } the environment may affect these ongoing operations`
            ]}
            onClose={onConfirmUpdateClose}
            onSubmit={onConfirmUpdate}
          />
        </NewThemeWrapper>
      )}

      {showConfirmScreen && isDirty && (
        <ConfirmScreen
          onCancel={handleCancelClose}
          onConfirm={handleCloseModal}
          confirmLabel="Close"
          title="Do you really want to close this window?"
          subtitleLines={["If you leave you will lose any unsaved changes."]}
        />
      )}

      <NewThemeWrapper>
        <Box className={classes.drawerContent}>
          <div className={classes.settingsContainer}>
            {isReadingProject ? (
              <Skeleton variant="rect" height={200} />
            ) : (
              <Box className={classes.topInputs}>
                <PreviewImageSelectorNew
                  isNewTheme
                  noText
                  previewType="mini-project"
                  onChange={handleImageChange}
                  defaultImage={imageBase64 || default_image}
                />
                <Box className={classes.inputsContainer}>
                  <Field
                    id="name"
                    label="Project Name"
                    className={classes.nameInput}
                    value={(values as $TSFixMe).name || ""}
                    onChange={(event: $TSFixMe) => {
                      handleChange(event, "name");
                    }}
                    error={showProjectNameError[0]}
                    helperText={showProjectNameError[0] && showProjectNameError[1]}
                    required
                    variant="outlined"
                    size="small"
                  />
                  <Tooltip title={(values as $TSFixMe).description} arrow>
                    <div
                      className={clsx(classes.descriptionInput, {
                        [classes.descriptionMargin]: !showProjectNameError[0]
                      })}>
                      <Field
                        id="description"
                        label="Project description"
                        multiline
                        minRows={2}
                        maxRows={2}
                        onChange={(event: $TSFixMe) => {
                          handleChange(event);
                        }}
                        value={(values as $TSFixMe).description}
                        variant="outlined"
                      />
                    </div>
                  </Tooltip>
                </Box>
              </Box>
            )}
            <Box className={clsx(classes.additionalInputs, classes.inputsContainer)}>
              <Typography variant="body1" className={classes.inputTitle}>
                Additional
              </Typography>
              {isFetchingEnvironmentTypes || isFetchingEnvironments ? (
                <Skeleton variant="rect" height={80} />
              ) : (
                <>
                  <FormControl variant="outlined">
                    <InputLabel shrink htmlFor="environmentId">
                      Environment Type
                    </InputLabel>
                    <Select
                      label="Environment Type"
                      required
                      id="environmentId"
                      name="environmentId"
                      defaultValue={(selectedEnv as $TSFixMe)?.id || ""}
                      value={(selectedEnv as $TSFixMe)?.id || ""}
                      onChange={onEnvironmentChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                      input={<OutlinedInput notched label="Environment Type" />}
                      displayEmpty>
                      {environments?.map((env: $TSFixMe) => {
                        const envMetaData = envMetadataFind(environmentsTypes, env);
                        const envType = envMetaData?.name;
                        return (
                          <MenuItem key={env?.name} value={env?.id}>
                            <Box className={classes.envMenuItem}>
                              <Grid container direction="column">
                                <Text value={env.name} />
                                <span className={classes.envTypeSpan}>
                                  (
                                  <EnvironmentTypeConfig
                                    envType={envType}
                                    cores={env?.cores}
                                    memInMbs={env?.memInMbs}
                                    diskInGbs={env?.diskInGbs}
                                  />
                                  )
                                </span>
                              </Grid>
                              <div
                                className={clsx(
                                  classes.roundEnvStatus,
                                  styles.envStatus,
                                  styles[env?.launchStatus?.trim()?.toLowerCase()]
                                )}>
                                {statusValues[env?.launchStatus?.trim()?.toLowerCase()]?.text ||
                                  env?.launchStatus ||
                                  "Unknown"}
                              </div>
                            </Box>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>
            {!isBusinessUser && (
              <Box className={clsx(classes.variablesInputs, classes.inputsContainer)}>
                <Typography variant="body1" className={classes.inputTitle}>
                  Global Variables
                </Typography>
                {isFetchingProject || isFetchingVariables ? (
                  <Box pb="16px">
                    <Skeleton variant="rect" height={65} />
                  </Box>
                ) : (
                  (values as $TSFixMe)?.variables?.map((variable: $TSFixMe, index: number) => {
                    const isLastVariable = index === (values as $TSFixMe)?.variables?.length - 1;
                    return (
                      <Box key={`variable-inputs-${index}`} display="flex" gridGap="24px">
                        <Field
                          id={`variable-name-${index}`}
                          label="Variable"
                          className={clsx(classes.nameInput, {
                            [classes.variableMargin]: !variableNameErrors?.[index]
                          })}
                          value={variable?.name || ""}
                          onChange={(event: $TSFixMe) => {
                            handleVariableChange(event, index, "name");
                          }}
                          required
                          variant="outlined"
                          size="small"
                          error={!!variableNameErrors?.[index]}
                          helperText={variableNameErrors?.[index]}
                        />
                        <Field
                          id={`variable-key-value-${index}`}
                          label="Key Value"
                          className={clsx(classes.nameInput, {
                            [classes.variableMargin]: !variableNameErrors?.[index]
                          })}
                          value={variable?.value || ""}
                          onChange={(event: $TSFixMe) => {
                            handleVariableChange(event, index, "value");
                          }}
                          required
                          variant="outlined"
                          size="small"
                          error={!!variableValueErrors?.[index]}
                          helperText={variableValueErrors?.[index]}
                        />
                        {isLastVariable ? (
                          <Button
                            id={`${ID_PREFIX}-add-variable-button`}
                            className={classes.variableButton}
                            color="primary"
                            size="small"
                            onClick={onAdd}
                            disabled={variable?.value === "" && variable?.name === ""}>
                            <PlusIcon />
                          </Button>
                        ) : (
                          <Button
                            id={`${ID_PREFIX}-delete-variable-button`}
                            className={classes.variableButton}
                            color="primary"
                            size="small"
                            onClick={() => onDelete(index)}>
                            <Delete />
                          </Button>
                        )}
                      </Box>
                    );
                  })
                )}
              </Box>
            )}
            <Box className={classes.inputsContainer}>
              <Field
                id="askAIContext"
                label="AskAI System Message"
                onChange={(event: $TSFixMe) => {
                  handleChange(event);
                }}
                placeholder="System message updated here will be used by all AskAI queries across this project"
                multiline
                minRows={3}
                value={(values as $TSFixMe).askAIContext || ""}
                variant="outlined"
              />
            </Box>
          </div>
          <div className={drawerClasses.footerContainer}>
            <Button
              id={`${ID_PREFIX}-cancel-button`}
              size="small"
              onClick={handleCloseAttempt}
              disabled={isUpdating}>
              Cancel
            </Button>
            <Button
              id={`${ID_PREFIX}-save-button`}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSubmit}
              disabled={isUpdating || !isDirty}
              disableElevation>
              {isUpdating ? <CircularProgress size={16} style={{ color: "#fff" }} /> : "Save"}
            </Button>
          </div>
        </Box>
      </NewThemeWrapper>
    </>
  );
};

export default ProjectSettings;
