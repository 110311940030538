import React, { useEffect, useState } from "react";

import {
  Grid,
  Paper,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ButtonGroup,
  TextField,
  Tooltip,
  Divider
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { useJobContext } from "../Job/context/useJobContext";

import { jobFrequency, jobWeekDays, JobsHelperText } from "../../utils/Jobs.constants";

import { useStyles } from "./JobRunConfig.styles";

const JobRunConfig = (props: $TSFixMe) => {
  const { isDisabledScenarioSelection = false, children } = props || {};

  const classes = useStyles();

  // Job context
  const {
    jobRunConfigContextState,
    setJobRunConfigContextState,

    jobData,

    scenariosData,
    defaultScenario,

    currentJobId: jobId,

    setIsSaved
  } = useJobContext() || {};

  // Case #1: [Default values + No default scenario] - STARTS >>
  const defaultValues = {
    scenario: "",
    frequency: "daily",
    days: [],
    hr: "00",
    min: "00",
    cron: "0 0 * * *"
  };

  const [values, setValues] = useState<$TSFixMe>(defaultValues || {});
  // << ENDS - Case #1: [Default values + No default scenario]

  // Case #2: [Default values + Default scenario] - STARTS >>
  useEffect(() => {
    if (Object.keys(jobData || {})?.length === 0) {
      setValues(() => ({
        ...values,
        scenario: defaultScenario?.id || ""
      }));
    }
  }, [defaultScenario?.id]);
  // << ENDS - Case #2: [Default values + Default scenario]

  // Case #3: Job - STARTS >>
  useEffect(() => {
    if (Object.keys(jobData || {})?.length > 0) {
      let thisValues: $TSFixMe = {
        scenario: jobData?.scenarioId,
        cron: jobData?.schedule
      };

      if (Object.keys(jobData?.scheduleInfo || {})?.length === 0) {
        thisValues = {
          ...thisValues,
          frequency: "cron",
          days: [],
          hr: "00",
          min: "00"
        };
      } else {
        thisValues = {
          ...thisValues,
          frequency: jobData?.scheduleInfo?.cronConfig?.frequency,
          days: jobData?.scheduleInfo?.cronConfig?.days,
          hr: jobData?.scheduleInfo?.cronConfig?.hr,
          min: jobData?.scheduleInfo?.cronConfig?.min
        };
      }

      setValues(() => ({
        ...values,
        ...thisValues
      }));
    }
  }, [jobData]);
  // << ENDS - Case #3: Job

  // Sync jobRunConfigContextState - STARTS >>
  useEffect(() => {
    // $FixMe: Below code should be replaced by jobRunConfigContextState.
    // Due to unknown reasons latest cron value is not being available while reading at save-job,
    // though store gets updated with latest cron value. Hence, took support of session-storage work-around for time-being.
    sessionStorage.setItem("cronValueSession", values?.cron);

    const thisJobRunConfigContextState = jobRunConfigContextState;
    thisJobRunConfigContextState.values = values;

    let isValid: boolean = true;

    isValid = isValid && values?.scenario && values?.frequency;
    isValid = isValid && (values?.frequency === "daily" ? !!values?.hr && !!values?.min : true);
    isValid = isValid && (values?.frequency === "weekly" ? (values?.days || [])?.length > 0 : true);
    isValid = isValid && (values?.frequency === "cron" ? !!values?.cron : true);

    thisJobRunConfigContextState.isValid = isValid;

    setJobRunConfigContextState(() => ({ ...thisJobRunConfigContextState }));
  }, [values]);
  // << ENDS - Sync jobRunConfigContextState

  const onFieldChange = (field: $TSFixMe, e: $TSFixMe) => {
    setIsSaved(() => false);

    const thisValues = values;

    if (field === "frequency") {
      thisValues["days"] = defaultValues?.days;
      thisValues["hr"] = defaultValues?.hr;
      thisValues["min"] = defaultValues?.min;
      thisValues["cron"] = defaultValues?.cron;
    }

    if (field === "days") {
      const { value, checked } = e?.target;

      if (checked) {
        thisValues[field] = [...thisValues[field], value];
      } else {
        thisValues[field] = thisValues[field]?.filter((eachDay: $TSFixMe) => eachDay !== value);
      }
    } else {
      thisValues[field] = e?.target?.value;
    }

    setValues(() => ({
      ...thisValues
    }));
  };

  return (
    <Paper style={{ width: "auto", margin: "20px 16px" }}>
      <Grid container className={classes.container}>
        <Grid item>
          <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
            Run
          </Typography>
        </Grid>
        {!!jobId && (
          <Grid item>
            <Tooltip title={JobsHelperText.ScenarioUpdateInfo} placement="right">
              <InfoOutlinedIcon fontSize="small" style={{ cursor: "help", opacity: 0.5 }} />
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={2}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel id="scenarioInputLabel">Scenario</InputLabel>
            <Select
              labelId="scenarioInputLabel"
              id="scenario"
              label="Scenario"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                classes: { paper: classes.selectDropdownContainer }
              }}
              value={values?.scenario}
              onChange={(e: $TSFixMe) => onFieldChange("scenario", e)}
              error={!values?.scenario}
              disabled={isDisabledScenarioSelection}>
              {scenariosData?.map((scenario: $TSFixMe) => (
                <MenuItem key={scenario?.id} value={scenario?.id}>
                  {scenario?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            @
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel id="frequencyInputLabel">Frequency</InputLabel>
            <Select
              labelId="frequencyInputLabel"
              id="frequency"
              label="Frequency"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                classes: { paper: classes.selectDropdownContainer }
              }}
              value={values?.frequency}
              onChange={(e: $TSFixMe) => onFieldChange("frequency", e)}
              error={!values?.frequency}>
              {jobFrequency?.map((eachInstance: $TSFixMe, index: number) => (
                <MenuItem key={`frequency_${index}`} value={eachInstance?.id}>
                  {eachInstance?.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {values?.frequency === "weekly" && (
          <Grid item>
            <FormControl variant="outlined" style={{ width: "100%", flexDirection: "row" }}>
              <ButtonGroup color="primary">
                {jobWeekDays.map((weekDay: $TSFixMe, index: number) => (
                  <Button
                    key={`day_${index}`}
                    style={{
                      textTransform: "none",
                      padding: 0,
                      borderColor:
                        (Array.isArray(values?.days) ? values?.days : [])?.length === 0
                          ? "red"
                          : "initial"
                    }}
                    color={
                      (Array.isArray(values?.days) ? values?.days : [])?.includes(
                        // @ts-ignore
                        weekDay?.id
                      )
                        ? "primary"
                        : "default"
                    }
                    variant={
                      (Array.isArray(values?.days) ? values?.days : [])?.includes(
                        // @ts-ignore
                        weekDay?.id
                      )
                        ? "contained"
                        : "outlined"
                    }>
                    <label style={{ marginBottom: 0, padding: "5px 15px", cursor: "pointer" }}>
                      <input
                        type="checkbox"
                        id={`day_${index}`}
                        name="days"
                        value={weekDay?.id}
                        style={{ display: "none" }}
                        onChange={(e: $TSFixMe) => onFieldChange("days", e)}
                      />
                      {weekDay?.displayName}
                    </label>
                  </Button>
                ))}
              </ButtonGroup>
            </FormControl>
          </Grid>
        )}
        {values?.frequency !== "cron" && (
          <>
            <Grid item>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="hrInputLabel">Hr.</InputLabel>
                <Select
                  labelId="hrInputLabel"
                  id="hr"
                  label="Hr."
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.selectDropdownContainer }
                  }}
                  value={values?.hr}
                  onChange={(e: $TSFixMe) => onFieldChange("hr", e)}
                  error={!values?.hr}>
                  {[...Array(24).keys()]
                    .map((eachHr: number) => String(eachHr).padStart(2, "0"))
                    .map((eachHr: string, index: number) => (
                      <MenuItem key={`hr_${index}`} value={eachHr}>
                        {eachHr}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="minInputLabel">Min.</InputLabel>
                <Select
                  labelId="minInputLabel"
                  id="min"
                  label="Min."
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.selectDropdownContainer }
                  }}
                  value={values?.min}
                  onChange={(e: $TSFixMe) => onFieldChange("min", e)}
                  error={!values?.min}>
                  {[...Array(60).keys()]
                    .map((eachMin: number) => String(eachMin).padStart(2, "0"))
                    .map((eachMin: string, index: number) => (
                      <MenuItem key={`min_${index}`} value={eachMin}>
                        {eachMin}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                UTC
              </FormControl>
            </Grid>
          </>
        )}
        {values?.frequency === "cron" && (
          <>
            <Grid item xs={2}>
              <TextField
                id="cron"
                label="Format"
                variant="outlined"
                className={classes.textField}
                value={values?.cron}
                onChange={(e: $TSFixMe) => onFieldChange("cron", e)}
                error={!values?.cron}
              />
            </Grid>
            <Grid item>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                UTC
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InfoOutlinedIcon
                  fontSize="small"
                  style={{ cursor: "pointer", opacity: 0.5 }}
                  onClick={() => window.open(JobsHelperText.CronRefLink, "_blank")}
                />
              </FormControl>
            </Grid>
          </>
        )}
        <Divider orientation="vertical" flexItem style={{ marginLeft: "auto" }} />
        <Grid item style={{ marginLeft: "auto" }}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default JobRunConfig;
