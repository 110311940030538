import React, { useMemo } from "react";

// Packages
import { ColumnDefResolved } from "@tanstack/react-table";
import { isEqual, map, size, sortBy } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import InfoOutlined from "@material-ui/icons/InfoOutlined";

// Types
import { TData } from "../../Table.types";

// Constants
import { TableSettingsColumnsDirection, TableConfig } from "../../utils/Table.constants";

type Props = {
  maxColumnsCount?: number;
  inputColumns: ColumnDefResolved<TData, any>[];
  columns: ColumnDefResolved<TData, any>[];
  selectedColumns: ColumnDefResolved<TData, any>[];
  sort: (direction?: TableSettingsColumnsDirection) => void;
  onApply: () => void;
  disabledApplyActionMessage?: string;
};

const useStyles = makeStyles((theme) => ({
  info: {
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.25),
    fontSize: "small",
    cursor: "help"
  }
}));

const FooterActions = (props: Props) => {
  const {
    maxColumnsCount,
    inputColumns,
    columns,
    selectedColumns,
    sort,
    onApply,
    disabledApplyActionMessage = ""
  } = props || {};

  const classes = useStyles();

  const isDefaultOrder = useMemo(() => {
    return isEqual(map(inputColumns, "header"), map(columns, "header"));
  }, [inputColumns, columns]);

  const isAscOrder = useMemo(() => {
    const sortedColumns = sortBy(columns, (column) => column?.header?.toLowerCase());
    return isEqual(map(sortedColumns, "header"), map(columns, "header"));
  }, [columns]);

  const isDescOrder = useMemo(() => {
    const sortedColumns = sortBy(columns, (column) => column?.header?.toLowerCase())?.reverse();
    return isEqual(map(sortedColumns, "header"), map(columns, "header"));
  }, [columns]);

  return (
    <Grid container style={{ padding: "6px 0", rowGap: 8 }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ margin: "0 16px" }}>
        <Typography variant="subtitle2">Headers</Typography>
        <Grid container style={{ width: "auto", columnGap: 8 }}>
          <Button
            color="primary"
            size="small"
            disabled={!!isDefaultOrder}
            onClick={() => sort(TableSettingsColumnsDirection.Default)}>
            Default
          </Button>
          <Button
            color="primary"
            size="small"
            disabled={!!isAscOrder}
            onClick={() => sort(TableSettingsColumnsDirection.Asc)}>
            A to Z
          </Button>
          <Button
            color="primary"
            size="small"
            disabled={!!isDescOrder}
            onClick={() => sort(TableSettingsColumnsDirection.Desc)}>
            Z to A
          </Button>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} />
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ margin: "0 16px" }}>
        <Typography color="textSecondary" variant="caption">
          {`Selected ${size(selectedColumns)} of ${columns?.length || 0}`}
          {size(columns) > (maxColumnsCount ?? TableConfig.MaxColumnsCount) && (
            <Tooltip
              title={`Sample data view can only show a maximum of ${maxColumnsCount ?? TableConfig.MaxColumnsCount} columns at once`}>
              <InfoOutlined className={classes.info} fontSize="small" />
            </Tooltip>
          )}
        </Typography>
        <Tooltip title={disabledApplyActionMessage}>
          <span>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              disabled={!!disabledApplyActionMessage}
              onClick={onApply}>
              Apply
            </Button>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default FooterActions;
