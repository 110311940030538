import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { getProjectById } from "src/api";

type ProjectProps = {
  projectId?: string;
  includeJobCount?: $TSFixMe;

  // useQuery options
  cacheTime?: number;
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
};

export interface IProjectData {
  id: string;
  markdown: string;
  name: string;
  artifactsCount: number;
  canvasEdgeStyle: string;
  createAt: number;
  created: number;
  creator: string;
  dataSetCount: number;
  description: string;
  display_name: string;
  envId: string;
  hasMarkdown: boolean;
  image: any;
  modelsCount: number;
  recipeCount: number;
  scenarioCount: number;
  updated: number;
  updatedAt: number;
  updater: string;
  viewCount: number;
  useCases: string[];
  industries: string[];
}

const QUERY_KEY_PROJECTS = "project";

export const useGetProject = (props: ProjectProps): UseQueryResult<IProjectData> => {
  const {
    projectId,
    includeJobCount = false,

    // useQuery options
    cacheTime = 0,
    refetchOnMount = false,
    refetchOnWindowFocus = false
  } = props;

  return useQuery({
    queryKey: [QUERY_KEY_PROJECTS, projectId],
    queryFn: async () => {
      return await getProjectById(projectId, includeJobCount);
    },
    select: (data: $TSFixMe) =>
      Array.isArray(data) ? (typeof data?.[0] === "object" ? data?.[0] : {}) : {},
    cacheTime,
    refetchOnMount,
    refetchOnWindowFocus,
    enabled: !!projectId
  });
};

export { QUERY_KEY_PROJECTS };
