import React from "react";
import { Grid, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import _ from "lodash";

import { capitalize } from "src/utils/capitalize";
import { Spinner } from "src/components";
import ChatBox from "src/pages/Projects/AIGuide/common/ChatBox";
import clsx from "clsx";

type StyleProps = { isAddedToRecipe: boolean; isOutputError: boolean; hasErrorMsg: boolean };
const useStyles = makeStyles({
  arrow: {
    content: "''",
    position: "absolute",
    width: 0,
    height: 0,
    left: "0px",
    top: "12px",
    transform: "translateY(-50%)",
    border: "12px solid",
    borderColor: ({ isAddedToRecipe, isOutputError }: StyleProps) =>
      `${isAddedToRecipe ? "#E9FFDF" : isOutputError ? "#FFDBDD" : "#fff"} transparent transparent`
  },
  collapsedResponseWrap: {
    maxWidth: "750px",
    flexWrap: "nowrap",
    position: "relative",
    padding: "0px 0px 0px 12px"
  },
  collapsedResponse: {
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 8px",
    maxHeight: "132px",
    overflow: "auto",
    minWidth: ({ hasErrorMsg }) => (hasErrorMsg ? "auto" : "150px"),
    cursor: ({ isOutputError }: StyleProps) => (isOutputError ? "auto" : "pointer"),
    borderRadius: "4px",
    gap: "32px",
    minHeight: ({ hasErrorMsg }) => (hasErrorMsg ? "Opx" : "30px")
  },
  text: {
    color: "#4646B5",
    fontWeight: 400
  },
  noOutputText: {
    padding: "4px 0px"
  },
  errorBtn: {
    flex: 0
  }
});

type Props = {
  toggleExpanded: () => void;
  queryOutputs: { name: string }[];
  outputType: string;
  isFetchingResponse: boolean;
  isAddedToRecipe: boolean;
  isOutputError: boolean;
  errorMsg: string | undefined;
  hasErrorCode: boolean;
  errDetails?: any;
  isOutputModel: boolean;
};
export const AskAIResponseCollapsedView = ({
  toggleExpanded,
  queryOutputs,
  outputType,
  isFetchingResponse,
  isAddedToRecipe,
  isOutputError,
  errorMsg,
  hasErrorCode,
  errDetails,
  isOutputModel
}: Props) => {
  const classes = useStyles({ isAddedToRecipe, isOutputError, hasErrorMsg: !!errorMsg });

  return (
    <ChatBox
      noPadding
      color={isAddedToRecipe ? "#E9FFDF" : isOutputError ? "#FFDBDD" : "#FFF"}
      border={isAddedToRecipe ? "#E9FFDF" : isOutputError ? "#FFDBDD" : "#FFF"}
      width="auto">
      {isOutputError ? (
        <Grid
          container
          direction="row"
          className={classes.collapsedResponse}
          onClick={() => hasErrorCode && toggleExpanded()}>
          <Typography variant="subtitle2" className={clsx(classes.text, classes.noOutputText)}>
            {errDetails?.errorExplanation ||
              `${hasErrorCode ? "Could not execute code. " : ""}${errorMsg}` ||
              "No Output Generated"}
          </Typography>
          {hasErrorCode && (
            <Grid
              container
              direction="row"
              className={clsx(classes.collapsedResponse, classes.errorBtn)}>
              <IconButton size="small" disabled={isFetchingResponse}>
                <ExpandMore fontSize="small" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ) : (
        <Tooltip title={isAddedToRecipe ? "Output added to Recipe" : ""} arrow placement="right">
          <Grid
            container
            direction="row"
            className={classes.collapsedResponse}
            onClick={() => !isFetchingResponse && toggleExpanded()}>
            <Typography variant="subtitle2" className={classes.text}>
              {isOutputModel
                ? _.get(_.find(queryOutputs, ["modelVersion", "default"]), "name", "")
                : queryOutputs?.length >= 1
                  ? `${queryOutputs[0].name}${
                      queryOutputs?.length > 1 ? ` (+${queryOutputs?.length - 1})` : " "
                    }`
                  : capitalize(outputType?.toLowerCase())}
            </Typography>
            <IconButton size="small" disabled={isFetchingResponse}>
              {isFetchingResponse ? (
                <Spinner size={20} noPadding />
              ) : (
                <ExpandMore fontSize="small" />
              )}
            </IconButton>
          </Grid>
        </Tooltip>
      )}
    </ChatBox>
  );
};
