import React from "react";

// Packages
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";

type Props = {
  project: $TSFixMe;
  isJobPath: boolean;
  jobData: $TSFixMe;
  jobRunId?: string;
  jobRunName?: string;
  artifactName?: string;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, isJobPath, jobData, jobRunId, jobRunName, artifactName } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id && (
      <Link key="projects" to={`/projects/${project?.id}/canvas`}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    )
  );

  if (isJobPath && !!project?.id) {
    breadcrumbs.push(
      <Link key="jobs" to={`/projects/${project?.id}/jobs`}>
        <RenderText>Jobs</RenderText>
      </Link>
    );

    breadcrumbs.push(
      !!project?.id && !!jobData?.id ? (
        <Link key="job-name" to={`/projects/${project?.id}/jobs/${jobData?.id}`}>
          <RenderText>{jobData?.name || "Job"}</RenderText>
        </Link>
      ) : (
        <RenderText key="job-name" color="textSecondary">
          {jobData?.name || "Job"}
        </RenderText>
      )
    );

    breadcrumbs.push(
      project?.id && !!jobData?.id && !!jobRunId ? (
        <Link
          key="job-run-name"
          to={`/projects/${project?.id}/jobs/${jobData?.id}/job-runs/${jobRunId}/job-canvas`}>
          <RenderText>{`Run Name ${jobRunName || jobRunId || "Unknown"}`}</RenderText>
        </Link>
      ) : (
        <RenderText key="job-run-name" color="textSecondary">{`Run Name ${
          jobRunName || jobRunId || "Unknown"
        }`}</RenderText>
      )
    );
  }

  breadcrumbs.push(
    <RenderText key="artifacts" color="textSecondary">
      {artifactName}
    </RenderText>
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
