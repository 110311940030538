/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";

// MUI
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import { RunIcon } from "src/icons/NewUX/RunIcon";
import { TimeoutIcon } from "src/icons/NewUX/TimeoutIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { AddRunIcon } from "src/icons/NewUX";

import { useDrawerStore, useCanvasStore } from "src/store/store";
import { sideComponentSetter } from "src/store/store.selectors";
import { handleResponse } from "src/utils/apiService";
import { isRecipeRunInQueue as isRecipeRunInQueueHelper } from "src/pages/private/ProjectsModule/utils";
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import { RecipeConditions } from "src/pages/Projects/common/RecipeConditions/RecipeConditions";

import { StandardRecipeHeaderProps } from "./StandardRecipeHeader.type";
import { TimeoutTextField } from "../../common/TimeoutTextField/TimeoutTextField";
import { useValidateRecipeName } from "../../common/useValidateRecipeName";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { DISABLED_RECIPE_LOGS, READONLY_ENTITY } from "src/constants";
import RecipeLogsDrawer from "../../common/ShowLogsModal/RecipeLogsDrawer";
import { useJobsContext } from "src/pages/private/ProjectsModule/pages/Jobs/context/useJobsContext";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { openLogsModal } from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";

export const StandardRecipeHeader = ({
  recipeName,
  recipeDisplayName,
  handleSaveName,
  onRunRecipe,
  scenarioData,
  isDefaultScenario,
  recipe,
  isEmptyRecipe,
  isRunDisabled,
  isRunInProgress,
  conditions,
  setConditions,
  invalidTransformInputsHelpText,
  recipeRunInProgressHelpText,
  emptyRecipeHelperText,
  handleUpdateRecipeRunTimeout,
  jobData,
  jobRunName,
  isAddingRecipeToQueue
}: StandardRecipeHeaderProps) => {
  const navigate = useNavigate();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  // Project context
  const { project } = useProjectContext() || {};

  // Jobs context
  const { jobPaths, jobPathsSetter } = useJobsContext() || {};

  const { projectId, scenarioId, jobId, jobRunId } = useParams<$TSFixMe>();
  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRecipeUpdateInProgress, setIsRecipeUpdateInProgress] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openLogs, setOpenLogs] = useState(false);
  const isOpenLogs = searchParams.get(openLogsModal);

  const { validateName } = useValidateRecipeName();
  const { canAccessRecipeTimeout, canViewStandardRecipeConditions } = useAccessControlContext();

  const setSideComponent = useDrawerStore(sideComponentSetter);
  const isRecipesRunningAcrossScenariosStore = useCanvasStore(
    (state) => state.isRecipesRunningAcrossScenarios
  );
  const isPendingRecipeRunsInQueueStore = useCanvasStore(
    (state) => state.isPendingRecipeRunsInQueue
  );
  const pendingRecipeRunsInQueueStore = useCanvasStore((state) => state.pendingRecipeRunsInQueue);

  const handleLogsClick = () => {
    setOpenLogs(true);
  };

  useEffect(() => {
    if (isOpenLogs) {
      handleLogsClick();
    }
  }, [isOpenLogs]);

  const openConditionsSideBar = () => {
    const onClose = () => {
      setSideComponent({
        sideComponent: null,
        sideComponentProps: null
      });
    };
    setSideComponent({
      sideComponent: RecipeConditions,
      sideComponentProps: {
        title: "Conditions",
        projectId,
        scenarioId,
        recipe,
        isJobPath,
        jobId,
        jobRunId,
        conditions,
        setConditions,
        onClose,
        isDefaultScenario
      }
    });
  };

  const updateTransformName = React.useCallback(
    async (name: string) => {
      const cleanedName = name?.trim();
      const { error, nameExist } = validateName(cleanedName);

      if (error) {
        handleResponse({ errorMessage: error });
        setShouldResetName(Date.now());
      } else if (nameExist) {
        handleResponse({ errorMessage: "Recipe name already exists" });
        setShouldResetName(Date.now());
      } else {
        await handleSaveName({
          updatedName: cleanedName,
          onError: () => setShouldResetName(Date.now())
        });
      }
    },
    [handleSaveName, validateName]
  );

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [projectId, scenarioId]);

  const onUpdateRecipeRunTimeout = (timeout: number) => {
    handleUpdateRecipeRunTimeout({
      timeout,
      onError: () => setIsRecipeUpdateInProgress(false),
      onSuccess: () => setIsRecipeUpdateInProgress(false)
    });
  };

  const isRecipeRunInQueue = useMemo(
    () =>
      isRecipeRunInQueueHelper({
        pendingRecipeRunsInQueue: pendingRecipeRunsInQueueStore,
        recipeId: recipe?.id,
        scenarioId: scenarioId
      }),
    [pendingRecipeRunsInQueueStore, recipe?.id, scenarioId]
  );

  const isRunActionDisabled = useMemo(
    () =>
      (isEmptyRecipe && !recipe?.id) ||
      !recipe?.id ||
      isRunInProgress ||
      isRunDisabled ||
      isRecipeRunInQueue ||
      isAddingRecipeToQueue,
    [
      isEmptyRecipe,
      recipe?.id,
      isRunInProgress,
      isRunDisabled,
      isRecipeRunInQueue,
      isAddingRecipeToQueue
    ]
  );

  const isConditionsAction = useMemo(() => {
    if (!canViewStandardRecipeConditions) {
      return false;
    }

    if (!isJobPath && isEmptyRecipe && !recipe?.id) {
      return false;
    }

    return true;
  }, [isJobPath, canViewStandardRecipeConditions, isEmptyRecipe, recipe?.id]);

  const isRunAction = useMemo(() => {
    if (!!isJobPath) {
      return false;
    } else {
      if (isEmptyRecipe && !recipe?.id) {
        return false;
      }
    }

    return true;
  }, [isJobPath, isEmptyRecipe, recipe?.id]);

  const isLogsAction = useMemo(() => {
    if (!isJobPath && isEmptyRecipe && !recipe?.id) {
      return false;
    }

    return true;
  }, [isJobPath, isEmptyRecipe, recipe?.id]);

  const handleCloseLogs = () => {
    setOpenLogs(false);
    setSearchParams({});
  };

  const isAddRecipeToQueue = useMemo(
    () =>
      !isRunInProgress &&
      (!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore),
    [isRunInProgress, isRecipesRunningAcrossScenariosStore, isPendingRecipeRunsInQueueStore]
  );

  return (
    <>
      <DeleteNodeModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteSuccess={handleBack}
        nodeId={recipe?.id}
        nodeName={recipe?.displayName}
        nodeType="group"
        deleteNote={
          <div>
            <span>Note: </span>
            {psMessage}
          </div>
        }
      />
      {openLogs && (
        <RecipeLogsDrawer
          name={recipe?.displayName ?? ""}
          open={openLogs}
          projectId={projectId}
          scenarioId={scenarioId}
          jobRunId={jobRunId}
          groupId={recipe?.id}
          isJobPath={isJobPath}
          onClose={handleCloseLogs}
        />
      )}
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          ...(isJobPath
            ? {
                backNavAction: () => {
                  jobPathsSetter(jobPaths.slice(0, -1));
                }
              }
            : {
                backNavPath: `/projects/${projectId}/scenario/${scenarioId}/canvas`
              }),
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              isJobPath={isJobPath}
              jobData={jobData}
              jobRunId={jobRunId}
              jobRunName={jobRunName}
              isDefaultScenario={isDefaultScenario}
              scenarioName={scenarioData?.name}
              recipeName={recipeDisplayName || recipeName}
              updateRecipeName={updateTransformName}
              shouldResetRecipeName={shouldResetName}
            />
          )
        }}
        subTopNavBarRightSection={{
          ...(!isJobPath && !!recipe?.id
            ? {
                moreOptions: [
                  {
                    label: "Delete",
                    icon: <TrashIcon viewBox="0 0 20 20" />,
                    action: () => (recipe?.id ? handleOpenDeleteModal() : handleBack()),
                    isDisabled: !isDefaultScenario,
                    ...(!isDefaultScenario && {
                      tooltip: READONLY_ENTITY
                    })
                  },
                  ...(canAccessRecipeTimeout
                    ? [
                        {
                          label: `Timeout ${recipe?.timeout || 0}hr`,
                          icon: <TimeoutIcon viewBox="0 0 20 22" />,
                          nestedComponent: (
                            <TimeoutTextField
                              value={recipe?.timeout || ""}
                              minValue={1}
                              onChange={onUpdateRecipeRunTimeout}
                              disabled={isRecipeUpdateInProgress}
                            />
                          )
                        }
                      ]
                    : [])
                ]
              }
            : {}),
          component: (
            <>
              {!!isConditionsAction && (
                <Button
                  size="small"
                  color="primary"
                  onClick={openConditionsSideBar}
                  disabled={isEmptyRecipe && !recipe?.id}>
                  Conditions
                </Button>
              )}

              {!!isRunAction && (
                <Tooltip
                  title={
                    emptyRecipeHelperText ||
                    invalidTransformInputsHelpText ||
                    recipeRunInProgressHelpText ||
                    (isRecipeRunInQueue
                      ? "Recipe is already in queue!"
                      : isAddingRecipeToQueue
                        ? "Recipe is being added to queue"
                        : isAddRecipeToQueue
                          ? "Add to Queue"
                          : "Run Recipe")
                  }>
                  <span>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={onRunRecipe}
                      disabled={isRunActionDisabled}
                      style={{ textTransform: "none" }}
                      startIcon={
                        isAddingRecipeToQueue || isRunInProgress ? (
                          <CircularProgress size={18} />
                        ) : isAddRecipeToQueue ? (
                          <AddRunIcon />
                        ) : (
                          <RunIcon />
                        )
                      }>
                      {isAddRecipeToQueue ? "Add to Queue" : "Run"}
                    </Button>
                  </span>
                </Tooltip>
              )}

              {!!isLogsAction && (
                <Tooltip title={!recipe?.id ? DISABLED_RECIPE_LOGS : ""}>
                  <span>
                    <Button
                      size="small"
                      color="primary"
                      onClick={handleLogsClick}
                      disabled={!recipe?.id}>
                      <LogsNewIconV2
                        width="22"
                        height="22"
                        // New UX change
                        // The static value will be replaced with new theme at platform-level.
                        color={!recipe?.id ? "#4646b550" : "#4646b5"}
                      />
                    </Button>
                  </span>
                </Tooltip>
              )}
            </>
          )
        }}
      />
    </>
  );
};
