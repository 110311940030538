import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    display: "flex",
    margin: "auto",
    alignItems: "center",
    paddingTop: "125px",
    justifyContent: "center",
    gap: "10px",

    "& button": {
      background: "#1976d2",
      color: "white",

      "&:hover": {
        background: "#00649f"
      }
    }
  }
});

export interface ResultProps {
  icon: JSX.Element;
  heading: string | JSX.Element;
  subtitle1?: string;
  subtitle2?: string;
  extra?: JSX.Element;
}

const Result: React.FC<ResultProps> = (props) => {
  const { icon, heading, subtitle1, subtitle2, extra } = props;

  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.container}>
      {icon}
      <Typography variant="h5">{heading}</Typography>
      <Typography variant="subtitle1">{subtitle1}</Typography>
      <Typography variant="subtitle1">{subtitle2}</Typography>
      {extra}
    </Grid>
  );
};

export default Result;
