/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AddChartToDataAppRequest } from '../Models';
// @ts-ignore
import { CreateDataAppRequestDto } from '../Models';
// @ts-ignore
import { DataAppDto } from '../Models';
// @ts-ignore
import { DataAppLogsResponseDto } from '../Models';
// @ts-ignore
import { DataappRecordUsageResponse } from '../Models';
// @ts-ignore
import { ErrorMessage } from '../Models';
// @ts-ignore
import { GetDataAppConfigDto } from '../Models';
// @ts-ignore
import { LaunchDevAppResponse } from '../Models';
// @ts-ignore
import { PipelineTestResult } from '../Models';
// @ts-ignore
import { PodStatusDto } from '../Models';
// @ts-ignore
import { ResourceUsageDto } from '../Models';
// @ts-ignore
import { UpdateDataAppEnvRequestDto } from '../Models';
// @ts-ignore
import { UpdateDataAppRequestDto } from '../Models';
/**
 * DataAppControllerApi - axios parameter creator
 * @export
 */
export const DataAppControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} dataappId 
         * @param {AddChartToDataAppRequest} addChartToDataAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChart: async (dataappId: string, addChartToDataAppRequest: AddChartToDataAppRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataappId' is not null or undefined
            assertParamExists('addChart', 'dataappId', dataappId)
            // verify required parameter 'addChartToDataAppRequest' is not null or undefined
            assertParamExists('addChart', 'addChartToDataAppRequest', addChartToDataAppRequest)
            const localVarPath = `/dataapps/{dataappId}/add-chart`
                .replace(`{${"dataappId"}}`, encodeURIComponent(String(dataappId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addChartToDataAppRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDataAppRequestDto} createDataAppRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataApp: async (createDataAppRequestDto: CreateDataAppRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDataAppRequestDto' is not null or undefined
            assertParamExists('createDataApp', 'createDataAppRequestDto', createDataAppRequestDto)
            const localVarPath = `/dataapps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDataAppRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete8: async (dataappId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataappId' is not null or undefined
            assertParamExists('delete8', 'dataappId', dataappId)
            const localVarPath = `/dataapps/{dataappId}`
                .replace(`{${"dataappId"}}`, encodeURIComponent(String(dataappId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {string} chartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateChart: async (dataappId: string, chartId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataappId' is not null or undefined
            assertParamExists('generateChart', 'dataappId', dataappId)
            // verify required parameter 'chartId' is not null or undefined
            assertParamExists('generateChart', 'chartId', chartId)
            const localVarPath = `/dataapps/{dataappId}/generate-chart/{chartId}`
                .replace(`{${"dataappId"}}`, encodeURIComponent(String(dataappId)))
                .replace(`{${"chartId"}}`, encodeURIComponent(String(chartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataApp: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getDataApp', 'name', name)
            const localVarPath = `/dataapps/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAppConfig: async (dlId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dlId' is not null or undefined
            assertParamExists('getDataAppConfig', 'dlId', dlId)
            const localVarPath = `/dataapps/dataapp-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dlId !== undefined) {
                localVarQueryParameter['dlId'] = dlId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataApps: async (name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dataapps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAppsAcrossTenants: async (name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dataapps/across-tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAppsByGroup: async (groupId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getDataAppsByGroup', 'groupId', groupId)
            const localVarPath = `/dataapps/by-group/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAppsById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDataAppsById', 'id', id)
            const localVarPath = `/dataapps/by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAppsBySlug: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getDataAppsBySlug', 'slug', slug)
            const localVarPath = `/dataapps/by-slug/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodStatus: async (dataappId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataappId' is not null or undefined
            assertParamExists('getPodStatus', 'dataappId', dataappId)
            const localVarPath = `/dataapps/{dataappId}/pod-status`
                .replace(`{${"dataappId"}}`, encodeURIComponent(String(dataappId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceUsage1: async (dataappId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataappId' is not null or undefined
            assertParamExists('getResourceUsage1', 'dataappId', dataappId)
            const localVarPath = `/dataapps/{dataappId}/resource-usage`
                .replace(`{${"dataappId"}}`, encodeURIComponent(String(dataappId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launch: async (dataappId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataappId' is not null or undefined
            assertParamExists('launch', 'dataappId', dataappId)
            const localVarPath = `/dataapps/{dataappId}/launch`
                .replace(`{${"dataappId"}}`, encodeURIComponent(String(dataappId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchDevApp: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dataapps/launch-dev-app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logs: async (dataappId: string, mode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataappId' is not null or undefined
            assertParamExists('logs', 'dataappId', dataappId)
            const localVarPath = `/dataapps/{dataappId}/logs`
                .replace(`{${"dataappId"}}`, encodeURIComponent(String(dataappId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUsage: async (dataappId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataappId' is not null or undefined
            assertParamExists('recordUsage', 'dataappId', dataappId)
            const localVarPath = `/dataapps/{dataappId}/record-usage`
                .replace(`{${"dataappId"}}`, encodeURIComponent(String(dataappId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {string} chartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeChart: async (dataappId: string, chartId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataappId' is not null or undefined
            assertParamExists('removeChart', 'dataappId', dataappId)
            // verify required parameter 'chartId' is not null or undefined
            assertParamExists('removeChart', 'chartId', chartId)
            const localVarPath = `/dataapps/{dataappId}/remove-chart/{chartId}`
                .replace(`{${"dataappId"}}`, encodeURIComponent(String(dataappId)))
                .replace(`{${"chartId"}}`, encodeURIComponent(String(chartId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stop: async (dataappId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataappId' is not null or undefined
            assertParamExists('stop', 'dataappId', dataappId)
            const localVarPath = `/dataapps/{dataappId}/stop`
                .replace(`{${"dataappId"}}`, encodeURIComponent(String(dataappId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {UpdateDataAppRequestDto} updateDataAppRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataApp: async (dataappId: string, updateDataAppRequestDto: UpdateDataAppRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataappId' is not null or undefined
            assertParamExists('updateDataApp', 'dataappId', dataappId)
            // verify required parameter 'updateDataAppRequestDto' is not null or undefined
            assertParamExists('updateDataApp', 'updateDataAppRequestDto', updateDataAppRequestDto)
            const localVarPath = `/dataapps/{dataappId}`
                .replace(`{${"dataappId"}}`, encodeURIComponent(String(dataappId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataAppRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {UpdateDataAppEnvRequestDto} updateDataAppEnvRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataAppEnv: async (dataappId: string, updateDataAppEnvRequestDto: UpdateDataAppEnvRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataappId' is not null or undefined
            assertParamExists('updateDataAppEnv', 'dataappId', dataappId)
            // verify required parameter 'updateDataAppEnvRequestDto' is not null or undefined
            assertParamExists('updateDataAppEnv', 'updateDataAppEnvRequestDto', updateDataAppEnvRequestDto)
            const localVarPath = `/dataapps/{dataappId}/env`
                .replace(`{${"dataappId"}}`, encodeURIComponent(String(dataappId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataAppEnvRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataAppControllerApi - functional programming interface
 * @export
 */
export const DataAppControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataAppControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} dataappId 
         * @param {AddChartToDataAppRequest} addChartToDataAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addChart(dataappId: string, addChartToDataAppRequest: AddChartToDataAppRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAppDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addChart(dataappId, addChartToDataAppRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.addChart']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CreateDataAppRequestDto} createDataAppRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataApp(createDataAppRequestDto: CreateDataAppRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAppDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDataApp(createDataAppRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.createDataApp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete8(dataappId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete8(dataappId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.delete8']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {string} chartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateChart(dataappId: string, chartId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineTestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateChart(dataappId, chartId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.generateChart']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataApp(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAppDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataApp(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.getDataApp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} dlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAppConfig(dlId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDataAppConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAppConfig(dlId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.getDataAppConfig']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataApps(name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataAppDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataApps(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.getDataApps']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAppsAcrossTenants(name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataAppDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAppsAcrossTenants(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.getDataAppsAcrossTenants']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAppsByGroup(groupId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataAppDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAppsByGroup(groupId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.getDataAppsByGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAppsById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAppDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAppsById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.getDataAppsById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataAppsBySlug(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAppDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataAppsBySlug(slug, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.getDataAppsBySlug']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPodStatus(dataappId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PodStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPodStatus(dataappId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.getPodStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourceUsage1(dataappId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceUsageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourceUsage1(dataappId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.getResourceUsage1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async launch(dataappId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAppDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.launch(dataappId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.launch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async launchDevApp(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LaunchDevAppResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.launchDevApp(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.launchDevApp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logs(dataappId: string, mode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAppLogsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logs(dataappId, mode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.logs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordUsage(dataappId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataappRecordUsageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordUsage(dataappId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.recordUsage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {string} chartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeChart(dataappId: string, chartId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAppDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeChart(dataappId, chartId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.removeChart']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stop(dataappId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAppDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stop(dataappId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.stop']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {UpdateDataAppRequestDto} updateDataAppRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDataApp(dataappId: string, updateDataAppRequestDto: UpdateDataAppRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAppDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataApp(dataappId, updateDataAppRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.updateDataApp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {UpdateDataAppEnvRequestDto} updateDataAppEnvRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDataAppEnv(dataappId: string, updateDataAppEnvRequestDto: UpdateDataAppEnvRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAppDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataAppEnv(dataappId, updateDataAppEnvRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataAppControllerApi.updateDataAppEnv']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DataAppControllerApi - factory interface
 * @export
 */
export const DataAppControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataAppControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} dataappId 
         * @param {AddChartToDataAppRequest} addChartToDataAppRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChart(dataappId: string, addChartToDataAppRequest: AddChartToDataAppRequest, options?: any): AxiosPromise<DataAppDto> {
            return localVarFp.addChart(dataappId, addChartToDataAppRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDataAppRequestDto} createDataAppRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataApp(createDataAppRequestDto: CreateDataAppRequestDto, options?: any): AxiosPromise<DataAppDto> {
            return localVarFp.createDataApp(createDataAppRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete8(dataappId: string, options?: any): AxiosPromise<void> {
            return localVarFp.delete8(dataappId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {string} chartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateChart(dataappId: string, chartId: string, options?: any): AxiosPromise<PipelineTestResult> {
            return localVarFp.generateChart(dataappId, chartId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataApp(name: string, options?: any): AxiosPromise<DataAppDto> {
            return localVarFp.getDataApp(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAppConfig(dlId: string, options?: any): AxiosPromise<GetDataAppConfigDto> {
            return localVarFp.getDataAppConfig(dlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataApps(name?: string, options?: any): AxiosPromise<Array<DataAppDto>> {
            return localVarFp.getDataApps(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAppsAcrossTenants(name?: string, options?: any): AxiosPromise<Array<DataAppDto>> {
            return localVarFp.getDataAppsAcrossTenants(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAppsByGroup(groupId: string, options?: any): AxiosPromise<Array<DataAppDto>> {
            return localVarFp.getDataAppsByGroup(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAppsById(id: string, options?: any): AxiosPromise<DataAppDto> {
            return localVarFp.getDataAppsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataAppsBySlug(slug: string, options?: any): AxiosPromise<DataAppDto> {
            return localVarFp.getDataAppsBySlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPodStatus(dataappId: string, options?: any): AxiosPromise<PodStatusDto> {
            return localVarFp.getPodStatus(dataappId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceUsage1(dataappId: string, options?: any): AxiosPromise<ResourceUsageDto> {
            return localVarFp.getResourceUsage1(dataappId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launch(dataappId: string, options?: any): AxiosPromise<DataAppDto> {
            return localVarFp.launch(dataappId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchDevApp(options?: any): AxiosPromise<LaunchDevAppResponse> {
            return localVarFp.launchDevApp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logs(dataappId: string, mode?: string, options?: any): AxiosPromise<DataAppLogsResponseDto> {
            return localVarFp.logs(dataappId, mode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordUsage(dataappId: string, options?: any): AxiosPromise<DataappRecordUsageResponse> {
            return localVarFp.recordUsage(dataappId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {string} chartId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeChart(dataappId: string, chartId: string, options?: any): AxiosPromise<DataAppDto> {
            return localVarFp.removeChart(dataappId, chartId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stop(dataappId: string, options?: any): AxiosPromise<DataAppDto> {
            return localVarFp.stop(dataappId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {UpdateDataAppRequestDto} updateDataAppRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataApp(dataappId: string, updateDataAppRequestDto: UpdateDataAppRequestDto, options?: any): AxiosPromise<DataAppDto> {
            return localVarFp.updateDataApp(dataappId, updateDataAppRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataappId 
         * @param {UpdateDataAppEnvRequestDto} updateDataAppEnvRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataAppEnv(dataappId: string, updateDataAppEnvRequestDto: UpdateDataAppEnvRequestDto, options?: any): AxiosPromise<DataAppDto> {
            return localVarFp.updateDataAppEnv(dataappId, updateDataAppEnvRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataAppControllerApi - interface
 * @export
 * @interface DataAppControllerApi
 */
export interface DataAppControllerApiInterface {
    /**
     * 
     * @param {string} dataappId 
     * @param {AddChartToDataAppRequest} addChartToDataAppRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    addChart(dataappId: string, addChartToDataAppRequest: AddChartToDataAppRequest, options?: RawAxiosRequestConfig): AxiosPromise<DataAppDto>;

    /**
     * 
     * @param {CreateDataAppRequestDto} createDataAppRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    createDataApp(createDataAppRequestDto: CreateDataAppRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<DataAppDto>;

    /**
     * 
     * @param {string} dataappId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    delete8(dataappId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} dataappId 
     * @param {string} chartId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    generateChart(dataappId: string, chartId: string, options?: RawAxiosRequestConfig): AxiosPromise<PipelineTestResult>;

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    getDataApp(name: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAppDto>;

    /**
     * 
     * @param {string} dlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    getDataAppConfig(dlId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetDataAppConfigDto>;

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    getDataApps(name?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataAppDto>>;

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    getDataAppsAcrossTenants(name?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataAppDto>>;

    /**
     * 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    getDataAppsByGroup(groupId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataAppDto>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    getDataAppsById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAppDto>;

    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    getDataAppsBySlug(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAppDto>;

    /**
     * 
     * @param {string} dataappId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    getPodStatus(dataappId: string, options?: RawAxiosRequestConfig): AxiosPromise<PodStatusDto>;

    /**
     * 
     * @param {string} dataappId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    getResourceUsage1(dataappId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResourceUsageDto>;

    /**
     * 
     * @param {string} dataappId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    launch(dataappId: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAppDto>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    launchDevApp(options?: RawAxiosRequestConfig): AxiosPromise<LaunchDevAppResponse>;

    /**
     * 
     * @param {string} dataappId 
     * @param {string} [mode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    logs(dataappId: string, mode?: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAppLogsResponseDto>;

    /**
     * 
     * @param {string} dataappId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    recordUsage(dataappId: string, options?: RawAxiosRequestConfig): AxiosPromise<DataappRecordUsageResponse>;

    /**
     * 
     * @param {string} dataappId 
     * @param {string} chartId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    removeChart(dataappId: string, chartId: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAppDto>;

    /**
     * 
     * @param {string} dataappId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    stop(dataappId: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAppDto>;

    /**
     * 
     * @param {string} dataappId 
     * @param {UpdateDataAppRequestDto} updateDataAppRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    updateDataApp(dataappId: string, updateDataAppRequestDto: UpdateDataAppRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<DataAppDto>;

    /**
     * 
     * @param {string} dataappId 
     * @param {UpdateDataAppEnvRequestDto} updateDataAppEnvRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApiInterface
     */
    updateDataAppEnv(dataappId: string, updateDataAppEnvRequestDto: UpdateDataAppEnvRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<DataAppDto>;

}

/**
 * DataAppControllerApi - object-oriented interface
 * @export
 * @class DataAppControllerApi
 * @extends {BaseAPI}
 */
export class DataAppControllerApi extends BaseAPI implements DataAppControllerApiInterface {
    /**
     * 
     * @param {string} dataappId 
     * @param {AddChartToDataAppRequest} addChartToDataAppRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public addChart(dataappId: string, addChartToDataAppRequest: AddChartToDataAppRequest, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).addChart(dataappId, addChartToDataAppRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDataAppRequestDto} createDataAppRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public createDataApp(createDataAppRequestDto: CreateDataAppRequestDto, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).createDataApp(createDataAppRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataappId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public delete8(dataappId: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).delete8(dataappId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataappId 
     * @param {string} chartId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public generateChart(dataappId: string, chartId: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).generateChart(dataappId, chartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public getDataApp(name: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).getDataApp(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public getDataAppConfig(dlId: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).getDataAppConfig(dlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public getDataApps(name?: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).getDataApps(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public getDataAppsAcrossTenants(name?: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).getDataAppsAcrossTenants(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public getDataAppsByGroup(groupId: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).getDataAppsByGroup(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public getDataAppsById(id: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).getDataAppsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public getDataAppsBySlug(slug: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).getDataAppsBySlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataappId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public getPodStatus(dataappId: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).getPodStatus(dataappId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataappId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public getResourceUsage1(dataappId: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).getResourceUsage1(dataappId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataappId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public launch(dataappId: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).launch(dataappId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public launchDevApp(options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).launchDevApp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataappId 
     * @param {string} [mode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public logs(dataappId: string, mode?: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).logs(dataappId, mode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataappId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public recordUsage(dataappId: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).recordUsage(dataappId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataappId 
     * @param {string} chartId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public removeChart(dataappId: string, chartId: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).removeChart(dataappId, chartId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataappId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public stop(dataappId: string, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).stop(dataappId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataappId 
     * @param {UpdateDataAppRequestDto} updateDataAppRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public updateDataApp(dataappId: string, updateDataAppRequestDto: UpdateDataAppRequestDto, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).updateDataApp(dataappId, updateDataAppRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataappId 
     * @param {UpdateDataAppEnvRequestDto} updateDataAppEnvRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAppControllerApi
     */
    public updateDataAppEnv(dataappId: string, updateDataAppEnvRequestDto: UpdateDataAppEnvRequestDto, options?: RawAxiosRequestConfig) {
        return DataAppControllerApiFp(this.configuration).updateDataAppEnv(dataappId, updateDataAppEnvRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}

