/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { DataConversationDto } from '../Models';
// @ts-ignore
import { DeleteDataConversationsRequest } from '../Models';
// @ts-ignore
import { ErrorMessage } from '../Models';
// @ts-ignore
import { GenerateCodeRequest } from '../Models';
// @ts-ignore
import { GenerateCodeResponse } from '../Models';
// @ts-ignore
import { GenerateOutputRequest } from '../Models';
// @ts-ignore
import { GenerateOutputResponse } from '../Models';
// @ts-ignore
import { LikeGeneratedOutputRequest } from '../Models';
// @ts-ignore
import { ProcessFileDto } from '../Models';
// @ts-ignore
import { ProcessFileRequest } from '../Models';
// @ts-ignore
import { UpdateDataConversationRequest } from '../Models';
/**
 * DataConversationControllerApi - axios parameter creator
 * @export
 */
export const DataConversationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ProcessFileRequest} processFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataConversation: async (processFileRequest: ProcessFileRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processFileRequest' is not null or undefined
            assertParamExists('createDataConversation', 'processFileRequest', processFileRequest)
            const localVarPath = `/data-conversation/process-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataConversations1: async (conversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('dataConversations1', 'conversationId', conversationId)
            const localVarPath = `/data-conversation/by-conversation/{conversationId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {DeleteDataConversationsRequest} deleteDataConversationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataConversations: async (conversationId: string, deleteDataConversationsRequest: DeleteDataConversationsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('deleteDataConversations', 'conversationId', conversationId)
            // verify required parameter 'deleteDataConversationsRequest' is not null or undefined
            assertParamExists('deleteDataConversations', 'deleteDataConversationsRequest', deleteDataConversationsRequest)
            const localVarPath = `/data-conversation/by-conversation-id/{conversationId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteDataConversationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateCodeRequest} generateCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCode1: async (generateCodeRequest: GenerateCodeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateCodeRequest' is not null or undefined
            assertParamExists('generateCode1', 'generateCodeRequest', generateCodeRequest)
            const localVarPath = `/data-conversation/generate-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateOutputRequest} generateOutputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOutput1: async (generateOutputRequest: GenerateOutputRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateOutputRequest' is not null or undefined
            assertParamExists('generateOutput1', 'generateOutputRequest', generateOutputRequest)
            const localVarPath = `/data-conversation/generate-output`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateOutputRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataConversation1: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDataConversation1', 'id', id)
            const localVarPath = `/data-conversation/by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} historyId 
         * @param {LikeGeneratedOutputRequest} likeGeneratedOutputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeGeneratedOutput1: async (historyId: string, likeGeneratedOutputRequest: LikeGeneratedOutputRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'historyId' is not null or undefined
            assertParamExists('likeGeneratedOutput1', 'historyId', historyId)
            // verify required parameter 'likeGeneratedOutputRequest' is not null or undefined
            assertParamExists('likeGeneratedOutput1', 'likeGeneratedOutputRequest', likeGeneratedOutputRequest)
            const localVarPath = `/data-conversation/generated-output/like`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (historyId !== undefined) {
                localVarQueryParameter['historyId'] = historyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(likeGeneratedOutputRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {UpdateDataConversationRequest} updateDataConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataConversation1: async (conversationId: string, updateDataConversationRequest: UpdateDataConversationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('updateDataConversation1', 'conversationId', conversationId)
            // verify required parameter 'updateDataConversationRequest' is not null or undefined
            assertParamExists('updateDataConversation1', 'updateDataConversationRequest', updateDataConversationRequest)
            const localVarPath = `/data-conversation/by-conversation-id/{conversationId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataConversationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataConversationControllerApi - functional programming interface
 * @export
 */
export const DataConversationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataConversationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ProcessFileRequest} processFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataConversation(processFileRequest: ProcessFileRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDataConversation(processFileRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataConversationControllerApi.createDataConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataConversations1(conversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataConversationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataConversations1(conversationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataConversationControllerApi.dataConversations1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {DeleteDataConversationsRequest} deleteDataConversationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDataConversations(conversationId: string, deleteDataConversationsRequest: DeleteDataConversationsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDataConversations(conversationId, deleteDataConversationsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataConversationControllerApi.deleteDataConversations']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GenerateCodeRequest} generateCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCode1(generateCodeRequest: GenerateCodeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCode1(generateCodeRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataConversationControllerApi.generateCode1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GenerateOutputRequest} generateOutputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateOutput1(generateOutputRequest: GenerateOutputRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateOutput1(generateOutputRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataConversationControllerApi.generateOutput1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataConversation1(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataConversationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataConversation1(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataConversationControllerApi.getDataConversation1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} historyId 
         * @param {LikeGeneratedOutputRequest} likeGeneratedOutputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async likeGeneratedOutput1(historyId: string, likeGeneratedOutputRequest: LikeGeneratedOutputRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataConversationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.likeGeneratedOutput1(historyId, likeGeneratedOutputRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataConversationControllerApi.likeGeneratedOutput1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {UpdateDataConversationRequest} updateDataConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDataConversation1(conversationId: string, updateDataConversationRequest: UpdateDataConversationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataConversationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataConversation1(conversationId, updateDataConversationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DataConversationControllerApi.updateDataConversation1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DataConversationControllerApi - factory interface
 * @export
 */
export const DataConversationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataConversationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ProcessFileRequest} processFileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataConversation(processFileRequest: ProcessFileRequest, options?: any): AxiosPromise<ProcessFileDto> {
            return localVarFp.createDataConversation(processFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataConversations1(conversationId: string, options?: any): AxiosPromise<Array<DataConversationDto>> {
            return localVarFp.dataConversations1(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {DeleteDataConversationsRequest} deleteDataConversationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataConversations(conversationId: string, deleteDataConversationsRequest: DeleteDataConversationsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDataConversations(conversationId, deleteDataConversationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenerateCodeRequest} generateCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCode1(generateCodeRequest: GenerateCodeRequest, options?: any): AxiosPromise<GenerateCodeResponse> {
            return localVarFp.generateCode1(generateCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenerateOutputRequest} generateOutputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOutput1(generateOutputRequest: GenerateOutputRequest, options?: any): AxiosPromise<GenerateOutputResponse> {
            return localVarFp.generateOutput1(generateOutputRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataConversation1(id: string, options?: any): AxiosPromise<DataConversationDto> {
            return localVarFp.getDataConversation1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} historyId 
         * @param {LikeGeneratedOutputRequest} likeGeneratedOutputRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeGeneratedOutput1(historyId: string, likeGeneratedOutputRequest: LikeGeneratedOutputRequest, options?: any): AxiosPromise<DataConversationDto> {
            return localVarFp.likeGeneratedOutput1(historyId, likeGeneratedOutputRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} conversationId 
         * @param {UpdateDataConversationRequest} updateDataConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataConversation1(conversationId: string, updateDataConversationRequest: UpdateDataConversationRequest, options?: any): AxiosPromise<DataConversationDto> {
            return localVarFp.updateDataConversation1(conversationId, updateDataConversationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataConversationControllerApi - interface
 * @export
 * @interface DataConversationControllerApi
 */
export interface DataConversationControllerApiInterface {
    /**
     * 
     * @param {ProcessFileRequest} processFileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApiInterface
     */
    createDataConversation(processFileRequest: ProcessFileRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProcessFileDto>;

    /**
     * 
     * @param {string} conversationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApiInterface
     */
    dataConversations1(conversationId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataConversationDto>>;

    /**
     * 
     * @param {string} conversationId 
     * @param {DeleteDataConversationsRequest} deleteDataConversationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApiInterface
     */
    deleteDataConversations(conversationId: string, deleteDataConversationsRequest: DeleteDataConversationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {GenerateCodeRequest} generateCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApiInterface
     */
    generateCode1(generateCodeRequest: GenerateCodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenerateCodeResponse>;

    /**
     * 
     * @param {GenerateOutputRequest} generateOutputRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApiInterface
     */
    generateOutput1(generateOutputRequest: GenerateOutputRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenerateOutputResponse>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApiInterface
     */
    getDataConversation1(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DataConversationDto>;

    /**
     * 
     * @param {string} historyId 
     * @param {LikeGeneratedOutputRequest} likeGeneratedOutputRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApiInterface
     */
    likeGeneratedOutput1(historyId: string, likeGeneratedOutputRequest: LikeGeneratedOutputRequest, options?: RawAxiosRequestConfig): AxiosPromise<DataConversationDto>;

    /**
     * 
     * @param {string} conversationId 
     * @param {UpdateDataConversationRequest} updateDataConversationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApiInterface
     */
    updateDataConversation1(conversationId: string, updateDataConversationRequest: UpdateDataConversationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DataConversationDto>;

}

/**
 * DataConversationControllerApi - object-oriented interface
 * @export
 * @class DataConversationControllerApi
 * @extends {BaseAPI}
 */
export class DataConversationControllerApi extends BaseAPI implements DataConversationControllerApiInterface {
    /**
     * 
     * @param {ProcessFileRequest} processFileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApi
     */
    public createDataConversation(processFileRequest: ProcessFileRequest, options?: RawAxiosRequestConfig) {
        return DataConversationControllerApiFp(this.configuration).createDataConversation(processFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} conversationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApi
     */
    public dataConversations1(conversationId: string, options?: RawAxiosRequestConfig) {
        return DataConversationControllerApiFp(this.configuration).dataConversations1(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} conversationId 
     * @param {DeleteDataConversationsRequest} deleteDataConversationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApi
     */
    public deleteDataConversations(conversationId: string, deleteDataConversationsRequest: DeleteDataConversationsRequest, options?: RawAxiosRequestConfig) {
        return DataConversationControllerApiFp(this.configuration).deleteDataConversations(conversationId, deleteDataConversationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenerateCodeRequest} generateCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApi
     */
    public generateCode1(generateCodeRequest: GenerateCodeRequest, options?: RawAxiosRequestConfig) {
        return DataConversationControllerApiFp(this.configuration).generateCode1(generateCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenerateOutputRequest} generateOutputRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApi
     */
    public generateOutput1(generateOutputRequest: GenerateOutputRequest, options?: RawAxiosRequestConfig) {
        return DataConversationControllerApiFp(this.configuration).generateOutput1(generateOutputRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApi
     */
    public getDataConversation1(id: string, options?: RawAxiosRequestConfig) {
        return DataConversationControllerApiFp(this.configuration).getDataConversation1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} historyId 
     * @param {LikeGeneratedOutputRequest} likeGeneratedOutputRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApi
     */
    public likeGeneratedOutput1(historyId: string, likeGeneratedOutputRequest: LikeGeneratedOutputRequest, options?: RawAxiosRequestConfig) {
        return DataConversationControllerApiFp(this.configuration).likeGeneratedOutput1(historyId, likeGeneratedOutputRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} conversationId 
     * @param {UpdateDataConversationRequest} updateDataConversationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataConversationControllerApi
     */
    public updateDataConversation1(conversationId: string, updateDataConversationRequest: UpdateDataConversationRequest, options?: RawAxiosRequestConfig) {
        return DataConversationControllerApiFp(this.configuration).updateDataConversation1(conversationId, updateDataConversationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

