import React, { useEffect, useState } from "react";

// Packages
import Typography from "@material-ui/core/Typography";

import clsx from "clsx";
import { find, first, isEqual } from "lodash";
import ReactFlow, { Background, BackgroundVariant, Controls, MiniMap } from "react-flow-renderer";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import { AutoLayoutIcon, SaveIcon } from "src/icons/NewUX";

// Utils
import { handleResponse } from "src/utils/apiService";
import { resetNodesSelectionStore } from "src/pages/private/ProjectsModule/utils/Dag.helpers";

// Hooks
import useAutoLayout from "../DagFlow/hooks/useAutoLayout";
import useConnect from "../DagFlow/hooks/useConnect";
import useControlNodeDrag from "../DagFlow/hooks/useControlNodeDrag";
import useDagNodeAndEdgeTypes from "../DagFlow/hooks/useDagNodeAndEdgeTypes";
import useHighlightPath from "../DagFlow/hooks/useHighlightPath";
import useManageDagFlow from "../DagFlow/hooks/useManageDagFlow";
import useSaveNodes from "../DagFlow/hooks/useSaveNodes";

// Stores
import { useAIGuideStore, useViewPortStore } from "src/store/store";

// Components
import { Spinner } from "src/components";
import ExpandAllCollapseAllAction from "../ExpandAllCollapseAllAction";
import Dataset from "../Nodes/Dataset/Dataset";
import Recipe from "../Nodes/Recipe/Recipe";
import Chart from "../Nodes/Chart/Chart";
import Artifact from "../Nodes/Artifact/Artifact";
import Model from "../Nodes/Model/Model";
import AIGuideBtn from "src/pages/Projects/AIGuide/AIGuideBtn";
import SnippetGenerator from "../SnippetGenerator";
import ToggleLineViewButton from "../ToggleLineViewButton";

// Contexts
import { useDagFlowContext } from "../DagFlow/context/useDagFlowContext";

// Styles
import useStyles from "./DagFlowContainer.styles";
import { useParams } from "react-router";
import { useGetAIGuideThreads } from "src/hooks/api";
import AIGuideDialog from "src/pages/Projects/AIGuide/common/AIGuideDialog";
import { AIGuideProps } from "src/pages/Projects/AIGuide/common/useAIGuideContext";
import { DagHelperText } from "../../utils/Dag.constants";
import { ThreadResponseDtoTargetTypeEnum } from "openapi/Models/thread-response-dto";

type Props = {
  projectId?: string;
  project: $TSFixMe;
  jobProps: $TSFixMe;
  nodesDraggable?: boolean;
};

const proOptions = {
  account: "paid-pro",
  hideAttribution: true
};

const nodeTypes = {
  entity: Dataset,
  dfsgroup: Recipe,
  chart: Chart,
  artifact: Artifact,
  model: Model
};

const DagFlowContainer = (props: Props) => {
  const { scenarioId } = useParams<any>();
  const { projectId, project, jobProps, nodesDraggable } = props || {};

  const classes = useStyles();

  // States - STARTS >>
  const [isSaveNodesInProgress, setIsSaveNodesInProgress] = useState(false);

  // Using react-state here to check if focus is lost.
  // Ideal fix is to reset the react-router location's state object, which is a limitation from react-router.
  const [isNodeLostFocus, setIsNodeLostFocus] = useState(false);
  // << ENDS - States

  const [aiGuideParams, setAIGuideParams] = useState<AIGuideProps | null>(null);
  const [viewPortInfo, setViewPortInfo] = useViewPortStore((state) => [
    state.viewPortInfo,
    state.setViewPortInfo
  ]);
  const {
    data: threads,
    isLoading,
    refetch,
    isRefetching
  } = useGetAIGuideThreads({ projectId: projectId!, shouldSortAndFilter: true });

  const [getLatestGeneratingThreadId] = useAIGuideStore((state) => [
    state.getLatestGeneratingThreadId
  ]);
  const latestGeneratingThreadId = getLatestGeneratingThreadId();

  const savedViewPort = projectId && (viewPortInfo as any)?.[projectId];
  const viewPort = savedViewPort || { zoom: 1, x: 500, y: 250 };

  const lastActiveThread = first(threads);

  const {
    isFromDatasetPage,
    nodeActions,
    nodesStore,
    setNodeToFocusStore,
    edgeType,
    changeEdgeType,
    isRecipesRunning,
    resetNodesAndEdgesTimer,
    isDefaultScenario
  } = useDagFlowContext();

  const { edgeTypes } = useDagNodeAndEdgeTypes();

  const {
    nodes,
    setNodes,
    onNodesChange,

    edges,
    setEdges,
    onEdgesChange,

    resetNodesAndEdges,

    formatNodesToCanvas,

    minimap,
    isMinimap,
    nodeColorSelection
  } = useManageDagFlow();

  const { highlightPath } = useHighlightPath({ setNodes, setEdges });
  const { saveNodes } = useSaveNodes({ projectId });

  const { autoLayout, autoLayoutLoading } = useAutoLayout({
    projectId,
    nodesStore,
    nodes,
    edges,
    formatNodesToCanvas,
    saveNodes
  });
  const [showAIGuideDialog, setShowAIGuideDialog] = useState(false);

  const { onNodeDragStart, onNodeDragStop } = useControlNodeDrag({
    projectId,
    nodes,
    setNodes,
    edges
  });

  const { onConnect } = useConnect({ nodes });

  const minimapStyles = clsx([classes.minimap, isMinimap ? classes.fadeIn : classes.fadeOut]);

  const isJobCanvasOld = () =>
    !!jobProps?.jobId &&
    !!jobProps?.canvasData &&
    !isEqual(
      {
        nodes: jobProps?.canvasData?.nodes || [],
        edges: jobProps?.canvasData?.edges || []
      },
      { nodes: nodes || [], edges: edges || [] }
    );

  const clearDatasetFocus = () => {
    if (!!isFromDatasetPage && !isNodeLostFocus) {
      setNodeToFocusStore("");

      // Using react-state here to check if focus is lost.
      // Ideal fix is to reset the react-router location's state object, which is a limitation from react-router.
      setIsNodeLostFocus(() => true);
    }
  };

  useEffect(() => {
    !!resetNodesAndEdgesTimer && resetNodesAndEdges();
  }, [resetNodesAndEdgesTimer]);

  const handlePaneMove = (_: any, data: any) => {
    projectId && setViewPortInfo(projectId, data);
  };

  const navigateToAIGuide = () => {
    refetch().then((response) => {
      if (response.isFetched) {
        const recentThread = latestGeneratingThreadId
          ? find(response.data, { threadId: latestGeneratingThreadId })
          : first(response.data);

        if (!recentThread) {
          handleResponse({ errorMessage: "No active chats found!" });
          return;
        }

        const queryParams = {
          projectId: projectId!,
          scenarioId: scenarioId!,
          datasetId:
            recentThread.targetType === ThreadResponseDtoTargetTypeEnum.Dataset
              ? recentThread.entityId!
              : undefined,
          chartId:
            recentThread.targetType === ThreadResponseDtoTargetTypeEnum.Chart
              ? recentThread?.targetInputs?.[0]?.chartId
              : undefined,
          modelId:
            recentThread.targetType === ThreadResponseDtoTargetTypeEnum.Model
              ? recentThread.entityId!
              : undefined,
          diamondId:
            recentThread.targetType === ThreadResponseDtoTargetTypeEnum.Chart
              ? recentThread.entityId!
              : undefined,
          chartName: recentThread?.targetInputs?.[0]?.chartName,
          targetType: recentThread.targetType!,
          datasetContext: recentThread.datasetContext
        };

        setAIGuideParams(queryParams);
        setShowAIGuideDialog(true);
      }
    });
  };

  const isAIGuideDisabled = !lastActiveThread;

  return (
    <Grid container className={classes.canvasContainer}>
      <Grid item xs={12}>
        <ReactFlow
          // Metadata
          id="dag"
          className={classes.flowContainer}
          proOptions={proOptions}
          deleteKeyCode={null}
          // Config
          defaultZoom={viewPort.zoom}
          defaultPosition={[viewPort.x, viewPort.y]}
          snapToGrid
          {...(!savedViewPort ? { fitView: true } : {})}
          snapGrid={[180, 180]}
          fitViewOptions={{ minZoom: 0.5, maxZoom: 1.25 }}
          nodesDraggable={nodesDraggable && !nodeActions?.autoLayout}
          // Nodes & Edges
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          // Actions
          // > Pane
          onPaneClick={() => {
            resetNodesAndEdges();
            resetNodesSelectionStore();
            clearDatasetFocus();
          }}
          // > Node
          onNodesChange={onNodesChange}
          onNodeClick={clearDatasetFocus}
          onNodeDoubleClick={clearDatasetFocus}
          onNodeMouseEnter={(_event, node) => highlightPath(node, nodes, edges)}
          onNodeMouseLeave={resetNodesAndEdges}
          onNodeDragStart={(_event, node) => {
            clearDatasetFocus();
            onNodeDragStart(node);
          }}
          onNodeDragStop={onNodeDragStop}
          onNodeContextMenu={clearDatasetFocus}
          // > Edge
          onEdgesChange={onEdgesChange}
          onEdgeClick={clearDatasetFocus}
          onEdgeDoubleClick={clearDatasetFocus}
          onEdgeContextMenu={clearDatasetFocus}
          // > Others
          onConnect={onConnect}
          onMoveStart={minimap}
          onMove={handlePaneMove}
          // Styles
          style={{
            ...(isJobCanvasOld() && !!jobProps?.canvasBackgroundColor
              ? { background: jobProps?.canvasBackgroundColor }
              : {})
          }}>
          {jobProps?.canvasInfo}
          <div className={classes.actionsContainer}>
            {isJobCanvasOld() && jobProps?.renderContent}

            <ExpandAllCollapseAllAction setEdges={setEdges} setNodes={setNodes} />
            <ToggleLineViewButton
              isCurvedLine={edgeType !== "smoothstep"}
              onClick={changeEdgeType}
            />

            {!jobProps && (
              <>
                <Tooltip
                  title={
                    !!nodeActions?.nodeDrag
                      ? DagHelperText.NodeDragInProgressText
                      : autoLayoutLoading
                        ? "Auto arrange action is in Progress..."
                        : `Auto arrange canvas nodes${
                            isRecipesRunning
                              ? ". This action is disabled currently as there is a running recipe in canvas."
                              : ""
                          }`
                  }>
                  <span>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={autoLayout}
                      disabled={isRecipesRunning || !!nodeActions?.nodeDrag || autoLayoutLoading}>
                      {autoLayoutLoading ? (
                        <Spinner size={12} noPadding />
                      ) : (
                        <Typography variant="body2" color="textPrimary">
                          <AutoLayoutIcon
                            width={20}
                            height={20}
                            viewBox="0 0 16 16"
                            {...(isRecipesRunning ? { opacity: 0.5 } : {})}
                          />
                        </Typography>
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={`Save canvas nodes orientation${
                    isRecipesRunning
                      ? ". This action is disabled currently as there is a running recipe in canvas."
                      : ""
                  }`}>
                  <span>
                    <IconButton
                      size="small"
                      color="primary"
                      disabled={isSaveNodesInProgress || isRecipesRunning}
                      onClick={() => {
                        setIsSaveNodesInProgress(true);
                        saveNodes({
                          payloadNodes: nodes,
                          onSuccess: () => {
                            handleResponse({
                              successMessage: `Canvas nodes saved successfully.`
                            });
                          },
                          onSettled: () => {
                            setIsSaveNodesInProgress(false);
                          }
                        });
                      }}>
                      {isSaveNodesInProgress ? (
                        <Spinner size={12} noPadding />
                      ) : (
                        <Typography variant="body2" color="textPrimary">
                          <SaveIcon width={21} height={21} viewBox="-2 -2 16 16" />
                        </Typography>
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
                <SnippetGenerator projectId={projectId} projectName={project?.name} />
              </>
            )}
          </div>
          {!jobProps && !isLoading && !isAIGuideDisabled && isDefaultScenario && (
            <div className={classes.footerContainer}>
              <AIGuideBtn onClick={navigateToAIGuide} isLoading={isRefetching} />
            </div>
          )}
          {aiGuideParams && (
            <AIGuideDialog
              open={showAIGuideDialog && !!aiGuideParams}
              onClose={() => {
                setAIGuideParams(null);
                setShowAIGuideDialog(false);
              }}
              {...aiGuideParams}
              projectId={projectId!}
              scenarioId={scenarioId!}
            />
          )}
          <Background variant={BackgroundVariant.Dots} gap={5} color="#e5e5e6" />
          <Controls className={classes.controlsFlow} showInteractive={false} />
          <MiniMap
            nodeColor={nodeColorSelection}
            className={minimapStyles}
            nodeStrokeColor={nodeColorSelection}
            maskColor="#3232327a"
          />
        </ReactFlow>
      </Grid>
    </Grid>
  );
};

export default DagFlowContainer;
