import React from "react";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";

import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { Spinner } from "src/components";
import { getJobRunStatusTemplate } from "../../../Jobs/utils/Jobs.helpers";
import { useGetJob, useGetJobRun, useGetJobRunLogs } from "src/hooks/api";
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";

export const useStyles = makeStyles({
  logsContainer: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    padding: 16
  }
});

const PredictionJobLogsPage: React.FC = () => {
  const { projectId, predictionRunId, predictionRunEntryId } = useParams() || {};

  const classes = useStyles();

  // Project context
  const { project } = useProjectContext() || {};

  // Query hooks - STARTS >>
  // Queries
  const { data: jobData } = useGetJob({ projectId, jobId: predictionRunId });
  const { data: jobRunData } = useGetJobRun({
    jobRunId: predictionRunEntryId,
    isApiWithRethrow: false
  });
  const { isFetching: isFetchingJobRunLogs, data: jobRunLogs } = useGetJobRunLogs({
    jobRunId: predictionRunEntryId!
  });
  // << ENDS - Query hooks

  return (
    <>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          backNavPath: predictionRunId
            ? `/projects/${projectId}/prediction-job/${predictionRunId}`
            : `/projects/${projectId}/prediction-job`,
          component: <SubTopNavBarBreadcrumbs project={project} jobData={jobData} />
        }}
        subTopNavBarRightSection={{
          component: getJobRunStatusTemplate(jobRunData?.entryDto?.status)
        }}
      />

      <Grid container className={classes.logsContainer}>
        <Grid item xs={12}>
          {isFetchingJobRunLogs ? (
            <Spinner />
          ) : (jobRunLogs || [])?.length === 0 ? (
            <>No logs found!</>
          ) : (
            <Grid container item xs={12}>
              <Paper
                style={{
                  width: "100%",
                  padding: "20px 25px",
                  backgroundColor: "#1e1e1e",
                  fontFamily: `Menlo, Monaco, "Courier New", monospace`,
                  color: "#d4d4d4",
                  fontSize: 12,
                  overflow: "auto"
                }}>
                <ul style={{ listStyle: "none", wordBreak: "break-word" }}>
                  {(jobRunLogs || [])?.map((log: $TSFixMe, index: $TSFixMe) => {
                    return <li key={`log_${index}`}>{log}</li>;
                  })}
                </ul>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PredictionJobLogsPage;
