import React, { useMemo } from "react";
import { useParams } from "react-router";

import StandardRecipeContainer from "./StandardRecipeContainer";
import { useGetRecipe } from "src/hooks/api";
import { Spinner } from "src/components";
import { EntityTypeEnum } from "src/types";

export const StandardRecipeDataContainer = () => {
  const { groupId, scenarioId, jobRunId } = useParams<$TSFixMe>();
  const { isFetching, data } = useGetRecipe({ recipeId: groupId, scenarioId, jobRunId });

  const { entityIds, artifactNames, modelNames } = useMemo(() => {
    return {
      entityIds: data?.recipe?.parents
        ?.filter((parent) => parent.type === EntityTypeEnum.ENTITY)
        ?.map((parent) => parent.id),
      artifactNames: data?.recipe?.parents
        ?.filter((parent) => parent.type === EntityTypeEnum.ARTIFACT)
        ?.map((parent) => parent.name),
      modelNames: data?.recipe?.parents
        ?.filter((parent) => parent.type === EntityTypeEnum.MODEL)
        ?.map((parent) => parent.name)
    };
  }, [data?.recipe?.parents]);

  if (!groupId) {
    return <StandardRecipeContainer />;
  }
  return isFetching ? (
    <Spinner />
  ) : (
    <StandardRecipeContainer
      entityIds={entityIds}
      artifactNames={artifactNames}
      modelNames={modelNames}
      recipeId={data?.recipe.id}
      recipe={data?.recipe}
    />
  );
};

export default StandardRecipeDataContainer;
