/* eslint-disable no-unused-vars */
import React from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  Grid,
  Box,
  CircularProgress,
  Button,
  IconButton,
  Typography,
  TypographyProps,
  Tooltip
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { DeleteNew } from "src/icons/DeleteNew";

interface BaseColumn {
  id: string;
  accessor: $TSFixMe;
  Header: React.ReactNode;
  isSortable?: boolean;
  isEditable?: boolean;
  Cell?: $TSFixMe;
  color?: TypographyProps["color"];
  fontWeight?: string | number;
  width?: number | string;
  sortDescFirst?: boolean;
  sortType?: any;
  filter?: $TSFixMe;
  defaultCanFilter?: boolean;
}
interface NumberColumn extends BaseColumn {
  type?: "number";
}

interface StringColumn extends BaseColumn {
  type?: "string";
}

interface SelectColumn extends BaseColumn {
  type: "select";
  values: { value: string; label: string | number }[];
  hideSelectInputLabel: boolean;
  selectVariant: string;
}

interface DateTimeColumn extends BaseColumn {
  type: "datetime";
  format?: string;
}

interface DateColumn extends BaseColumn {
  type: "date";
  format?: string;
}

export type InputColumn = StringColumn | NumberColumn | SelectColumn | DateTimeColumn | DateColumn;

type EditProps = {
  isEditable?: boolean;
  // Check if particular row can be edited or not
  canEdit?: $TSFixMeFunction;
  isInviteExpired: (cell: any) => boolean;
  handleEditClick?: $TSFixMeFunction;
  handleEditSave?: $TSFixMeFunction;
  handleEditCancel?: $TSFixMeFunction;
  isRowInEditMode?: $TSFixMeFunction;
  isSaveDisabled?: $TSFixMeFunction;
  isSaveInProgress?: $TSFixMeFunction;
};
type DeleteProps = {
  isDeletable?: boolean;
  // Check if particular row can be deleted or not
  canDelete?: $TSFixMeFunction;
  handleDeleteClick?: $TSFixMeFunction;
};
type FormTableCellProps = {
  inputColumns: InputColumn[];
  size: string;
  editProps?: EditProps;
  deleteProps?: DeleteProps;
  renderExtraActions?: ({ cell }: { cell: any }) => React.ReactNode;
  isFilterable?: boolean;
};
const useStyles = makeStyles({
  subHeading: {
    fontSize: "14px"
  },
  gridGap: {
    gap: "8px"
  }
});

const BaseCell = ({
  column,
  size = "medium",
  children
}: {
  size?: string;
  column: InputColumn;
  children: React.ReactNode;
}) => {
  const styles = useStyles();
  const { fontWeight, color } = column;
  const typographyProps = {
    ...(fontWeight ? { style: { fontWeight } } : {}),
    ...(color ? { color } : {})
  };
  return (
    <Typography
      className={clsx({ [styles.subHeading]: size === "small" ? true : false })}
      {...typographyProps}>
      {children}
    </Typography>
  );
};

export const formTableCells = ({
  inputColumns,
  size = "medium",
  editProps,
  deleteProps,
  isFilterable = false,
  renderExtraActions
}: FormTableCellProps) => {
  const columns = inputColumns.map((inputColumn) =>
    formTableCell({ inputColumn, size, isFilterable })
  );
  const actionsColumn = formActionColumn({
    editProps,
    deleteProps,
    renderExtraActions
  });
  return actionsColumn ? [...columns, actionsColumn] : columns;
};

const formActionColumn = ({
  editProps,
  deleteProps,
  renderExtraActions
}: {
  editProps?: EditProps;
  deleteProps?: DeleteProps;
  renderExtraActions?: ({ cell }: { cell: any }) => React.ReactNode;
}) => {
  if (!editProps?.isEditable && !deleteProps?.isDeletable && !renderExtraActions) {
    return null;
  }
  const baseActionColumnProps = {
    id: "Actions",
    accessor: "id",
    Header: "Actions",
    isSortable: false
  };
  const actionCell = formActionCell({ editProps, deleteProps, renderExtraActions });
  return { ...baseActionColumnProps, Cell: actionCell };
};

const formActionCell = ({
  editProps,
  deleteProps,
  renderExtraActions
}: {
  editProps?: EditProps;
  deleteProps?: DeleteProps;
  renderExtraActions?: ({ cell }: { cell: any }) => React.ReactNode;
}) => {
  const styles = useStyles();
  return function Cell({ cell }: any) {
    const isEditing = editProps?.isRowInEditMode?.(cell);
    if (isEditing) {
      const isSaveDisabled = editProps?.isSaveDisabled?.(cell);
      const isSaveInProgress = editProps?.isSaveInProgress?.(cell);
      return (
        <Grid container className={styles.gridGap}>
          {isSaveInProgress ? (
            <Box display="flex" px="22px" alignItems="center">
              <CircularProgress size="20px" />
            </Box>
          ) : (
            <>
              <Button
                size="small"
                onClick={() => editProps?.handleEditSave?.(cell)}
                color="primary"
                disabled={isSaveDisabled}>
                Save
              </Button>
            </>
          )}
          <Button size="small" onClick={() => editProps?.handleEditCancel?.(cell)} color="primary">
            Cancel
          </Button>
          {renderExtraActions?.({ cell })}
        </Grid>
      );
    }
    const canEdit = editProps?.canEdit?.(cell);
    const canDelete = deleteProps?.canDelete?.(cell);
    const inviteExpired = editProps?.isInviteExpired(cell);
    return (
      <Grid container className={styles.gridGap}>
        <Tooltip
          title={
            inviteExpired
              ? "You cannot change the role of a user whose invitation link has expired"
              : ""
          }>
          <span>
            <IconButton
              aria-label="edit"
              component="div"
              size="small"
              disabled={!canEdit}
              onClick={(event) => editProps?.handleEditClick?.(event, cell)}
              color="primary">
              <Edit fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <IconButton
          aria-label="delete"
          component="div"
          size="small"
          disabled={!canDelete}
          color="primary"
          onClick={(event) => deleteProps?.handleDeleteClick?.(event, cell)}>
          <DeleteNew disabled={!canDelete} size="small" />
        </IconButton>
        {renderExtraActions?.({ cell })}
      </Grid>
    );
  };
};
const formTableCell = ({
  inputColumn,
  size = "medium",
  isFilterable
}: {
  inputColumn: InputColumn;
  size?: string;
  isFilterable: boolean;
}) => {
  const { accessor, Header, isSortable, ...restProps } = inputColumn;
  const cell = formCellProp(inputColumn, size);
  const updatedAccessor = isFilterable ? formCustomAccessor(inputColumn) : accessor;
  return {
    accessor: updatedAccessor,
    Header,
    isSortable,
    // Incase of custom accessor function, id is updated as Header. To fix, Update Id manually
    ...(isFilterable && { id: accessor }),
    ...restProps,
    ...(cell && { Cell: cell })
  };
};

const formCellProp = (inputColumn: InputColumn, size: string) => {
  if (inputColumn.Cell) {
    return inputColumn.Cell;
  }
  if (inputColumn.type === "datetime") {
    return function Cell(row: any) {
      const dateVal = row.value;
      const date = dateVal && dateVal !== 0 ? new Date(dateVal) : null;
      const dateTimeFormat = inputColumn.format || "YYYY-MM-DD HH:mm:ss";
      return (
        <BaseCell column={inputColumn} size={size}>
          {date ? dayjs(date).format(dateTimeFormat) : "N/A"}
        </BaseCell>
      );
    };
  }

  if (inputColumn.type === "date") {
    return function Cell(row: any) {
      const dateVal = row.value;
      const dateFormat = inputColumn.format || "YYYY-MM-DD";
      return (
        <BaseCell column={inputColumn} size={size}>
          {dateVal && dateVal !== 0 ? dayjs(dateVal).format(dateFormat) : ""}
        </BaseCell>
      );
    };
  }

  if (inputColumn.color || inputColumn.fontWeight) {
    return function Cell(row: any) {
      return (
        <BaseCell column={inputColumn} size={size}>
          {row.value}
        </BaseCell>
      );
    };
  }
};

/* To add global filter for custom Cells */
const formCustomAccessor = (inputColumn: InputColumn) => {
  const { accessor } = inputColumn;
  if (inputColumn.type === "date") {
    return (row: $TSFixMe) =>
      row[accessor] ? dayjs(row[accessor]).format("YYYY-MM-DD") : row[accessor];
  }
  if (inputColumn.type === "datetime") {
    return (row: $TSFixMe) =>
      row[accessor] ? dayjs(row[accessor]).format("YYYY-MM-DD hh:mm:ss") : row[accessor];
  }
  return accessor;
};
