import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { Spinner, Tabs, Tab, TabPanel } from "src/components";
import { useDfsStore, useScenariosStore } from "../../store/store";
import { dfsListSetter, getDfsList } from "../../store/store.selectors";

import AddScenariosSegments from "./AddScenariosSegments";
import { extractScenarioName, getVariablesDTO } from "./helpers/scenarios.helpers";
import ScenariosHeader from "./ScenariosHeader/ScenariosHeader";
import AddScenarioVariables from "./AddScenarioVariables";
import { handleResponse } from "src/utils/apiService";
import { validateNameField } from "src/utils/formFieldUtils";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { WebPaths } from "src/routing/routes";
import { useGetDfsTemplates, useGetScenarios, useGetTransformGroups } from "src/hooks/api";
import { Recipe } from "src/types";

const useStyles = makeStyles((theme) => ({
  root: {
    rowGap: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

const AddScenarios = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { id } = useParams<$TSFixMe>();

  const [value, setValue] = useState(1);

  const [projectRecipes, setProjectRecipes] = useState<Recipe[]>([]);
  const [scenarioVariables, setScenarioVariables] = useState<any[]>([]);
  const [entityGroupFormStates, setEntityGroupFormStates] = useState([]);
  const [sharedVariables, setSharedVariables] = useState([]);
  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);

  const dfsList = useDfsStore(getDfsList);
  const setDfsList = useDfsStore(dfsListSetter);
  const [scenarios, setScenarios] = useScenariosStore((state) => [
    state.scenarios,
    state.setScenarios
  ]);
  const [currentScenario, setCurrentScenario] = useState<any>(() => ({
    name: extractScenarioName(scenarios)
  }));

  const {
    isFetching: isLoadingDfs,
    isFetched: areTemplatesFetched,
    data: dfsTemplates
  } = useGetDfsTemplates({ projectId: id!, enabled: !dfsList?.length });

  const { isFetching: isScenariosLoading } = useGetScenarios({
    projectId: id!,
    cacheTime: 5 * (60 * 1000),
    onSuccess: (response) => {
      setScenarios(response);
      const scenarioName = extractScenarioName(response);
      setCurrentScenario({ name: scenarioName });
    }
  });

  const { isFetching: isFetchingRecipes } = useGetTransformGroups({
    projectId: id!,
    onSuccess: (allProjectRecipes) => {
      setProjectRecipes(allProjectRecipes.filter((recipe) => recipe.runConfigs.length));
    }
  });

  useEffect(() => {
    if (areTemplatesFetched && dfsTemplates) {
      setDfsList(dfsTemplates);
    }
  }, [areTemplatesFetched, dfsTemplates]);

  useEffect(() => {
    if (projectRecipes && dfsList) {
      projectRecipes?.map(({ name, runConfigs }) => {
        return runConfigs.map((runConfig) => {
          const appliedTransform = dfsList.find(
            (template: any) => template.id === runConfig?.templateId
          );

          const values = appliedTransform?.inputs?.reduce((acc: $TSFixMe, input: $TSFixMe) => {
            acc[input.name] =
              runConfig?.variables?.[input.name] ||
              input?.display?.default_value ||
              input?.metadata?.default_value;
            return acc;
          }, {});

          setScenarioVariables((previousScenarioVariables: $TSFixMe) => [
            ...previousScenarioVariables,
            ...getVariablesDTO({
              variables: values || {},
              recipeName: name,
              runConfigName: runConfig?.name
            })
          ]);
        });
      });
    }
  }, [projectRecipes, dfsList]);

  const updateScenarioName = async (name: $TSFixMe) => {
    const { error } = validateNameField({
      fieldName: name,
      fieldNameLabel: `scenario name`
    });
    if (error) {
      setShouldResetName(Date.now());
      handleResponse({ errorMessage: error });
      return;
    }
    setCurrentScenario((previousCurrentScenario: any) => ({
      ...previousCurrentScenario,
      name
    }));
  };

  const onAfterSubmit = useCallback(() => {
    if (id) {
      navigate(generatePath(WebPaths.Scenarios, { id }));
    }
  }, [id]);

  const handleTabChange = useCallback(
    (newValue: $TSFixMe) => {
      if (value === newValue) return;
      setValue(newValue);
    },
    [value]
  );

  const segmentIds = useMemo(() => {
    return entityGroupFormStates
      .map((formState) => {
        if ((formState as $TSFixMe).segmentVariant === "custom") {
          return (formState as $TSFixMe).segments
            .filter((segment: $TSFixMe) => segment.checked)
            .map((segment: $TSFixMe) => segment.id);
        } else {
          return [];
        }
      })
      .flat();
  }, [entityGroupFormStates]);

  return isScenariosLoading ? (
    <Spinner isAbsolute />
  ) : (
    <>
      <ScenariosHeader
        handleUpdateName={updateScenarioName}
        scenarioData={currentScenario}
        shouldReset={shouldResetName}
      />

      <Grid container direction="column" className={classes.root}>
        <Tabs value={value} onChange={handleTabChange}>
          <Tab value={1} label="Segments" />
          <Tab value={2} label="Variables" />
        </Tabs>
        {isLoadingDfs || isFetchingRecipes ? (
          <Spinner isAbsolute />
        ) : (
          <>
            <TabPanel value={value} index={1}>
              <AddScenariosSegments
                projectId={id!}
                scenarioName={(currentScenario as $TSFixMe)?.name}
                scenarioVariables={scenarioVariables}
                onAfterSubmit={onAfterSubmit}
                entityGroupFormStates={entityGroupFormStates}
                setEntityGroupFormStates={setEntityGroupFormStates}
                sharedVariables={sharedVariables}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AddScenarioVariables
                onAfterSubmit={onAfterSubmit}
                projectId={id!}
                scenarioName={(currentScenario as $TSFixMe)?.name}
                scenarioVariables={scenarioVariables}
                segmentIds={segmentIds}
                sharedVariables={sharedVariables}
                setSharedVariables={setSharedVariables}
              />
            </TabPanel>
          </>
        )}
      </Grid>
    </>
  );
};

export default AddScenarios;
