import { forEach } from "lodash";

export const hideRootNode = (nodes: $TSFixMe[]) => {
  forEach(nodes, (node: $TSFixMe) => {
    if (!node?.data?.id) {
      node.hidden = true;
    }
  });

  return nodes;
};
