/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AppTemplateInput } from './app-template-input';

/**
 * 
 * @export
 * @interface PublishAppTemplateRequestDto
 */
export interface PublishAppTemplateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PublishAppTemplateRequestDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PublishAppTemplateRequestDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublishAppTemplateRequestDto
     */
    'appType'?: PublishAppTemplateRequestDtoAppTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublishAppTemplateRequestDto
     */
    'source'?: PublishAppTemplateRequestDtoSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof PublishAppTemplateRequestDto
     */
    'status'?: PublishAppTemplateRequestDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PublishAppTemplateRequestDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublishAppTemplateRequestDto
     */
    'iconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublishAppTemplateRequestDto
     */
    'requirements'?: string;
    /**
     * 
     * @type {Array<AppTemplateInput>}
     * @memberof PublishAppTemplateRequestDto
     */
    'params'?: Array<AppTemplateInput>;
    /**
     * 
     * @type {string}
     * @memberof PublishAppTemplateRequestDto
     */
    'autoMlProblemType'?: PublishAppTemplateRequestDtoAutoMlProblemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublishAppTemplateRequestDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublishAppTemplateRequestDto
     */
    'systemAppTemplate'?: boolean;
}

export const PublishAppTemplateRequestDtoAppTypeEnum = {
    Streamlit: 'STREAMLIT',
    Reactjs: 'REACTJS'
} as const;

export type PublishAppTemplateRequestDtoAppTypeEnum = typeof PublishAppTemplateRequestDtoAppTypeEnum[keyof typeof PublishAppTemplateRequestDtoAppTypeEnum];
export const PublishAppTemplateRequestDtoSourceEnum = {
    Tenant: 'TENANT',
    System: 'SYSTEM'
} as const;

export type PublishAppTemplateRequestDtoSourceEnum = typeof PublishAppTemplateRequestDtoSourceEnum[keyof typeof PublishAppTemplateRequestDtoSourceEnum];
export const PublishAppTemplateRequestDtoStatusEnum = {
    Active: 'ACTIVE',
    Disabled: 'DISABLED'
} as const;

export type PublishAppTemplateRequestDtoStatusEnum = typeof PublishAppTemplateRequestDtoStatusEnum[keyof typeof PublishAppTemplateRequestDtoStatusEnum];
export const PublishAppTemplateRequestDtoAutoMlProblemTypeEnum = {
    BinaryClassification: 'BINARY_CLASSIFICATION',
    BinaryClassificationGpt: 'BINARY_CLASSIFICATION_GPT',
    Regression: 'REGRESSION',
    MulticlassClassification: 'MULTICLASS_CLASSIFICATION',
    TimeseriesForecasting: 'TIMESERIES_FORECASTING',
    AnomalyDetection: 'ANOMALY_DETECTION',
    Clustering: 'CLUSTERING'
} as const;

export type PublishAppTemplateRequestDtoAutoMlProblemTypeEnum = typeof PublishAppTemplateRequestDtoAutoMlProblemTypeEnum[keyof typeof PublishAppTemplateRequestDtoAutoMlProblemTypeEnum];


