import React from "react";
import { Link } from "react-router-dom";
import { Typography, TypographyVariant, TypographyProps } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";
import { OverflowTooltip } from "src/components";

type TypographyColors = Exclude<TypographyProps["color"], undefined>;

type Props = {
  variant?: TypographyVariant;
  color?: TypographyColors;
  isOverflowTooltip?: boolean;
  children: React.ReactNode;
  linkTo?: string;
};

const useStyles = makeStyles((theme) => ({
  link: {
    color: "#515151",
    fontSize: "14px",

    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "none"
    }
  },
  tooltip: {
    maxWidth: 200,
    whiteSpace: "nowrap"
  }
}));

export const RenderText = ({
  variant = "body2",
  color = "textPrimary",
  isOverflowTooltip = false,
  children,
  linkTo,
  ...rest
}: Props) => {
  const classes = useStyles();
  const val = isOverflowTooltip ? (
    <OverflowTooltip className={classes.tooltip} value={children} />
  ) : (
    children
  );

  if (linkTo) {
    return (
      <Link to={linkTo} className={classes.link}>
        {val}
      </Link>
    );
  } else {
    return (
      <Typography variant={variant} color={color} {...rest}>
        {val}
      </Typography>
    );
  }
};

export const PatternRexExps = {
  Email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  Password: new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{9,}$")
};
