import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";

import { getDashboardProjects } from "src/api";

export const enum UseGetProjectsQueryKeys {
  Projects = "projects"
}

export interface IProject {
  id: string;
  name: string;
  description: string;
  industries: string[];
  useCases: string[];
  recipeCount: number;
  dataSetCount: number;
  scenarioCount: number;
  viewCount: number;
  artifactsCount: number;
  modelsCount: number;
  envId: string;
  image: string;
  canvasEdgeStyle: string;
  created: number;
  updated: number;
  creator: string;
  updater: string;
  hasMarkdown: true;
}

export const useGetProjects = (options?: UseQueryOptions): UseQueryResult<IProject[] | any> =>
  useQuery({
    queryKey: [UseGetProjectsQueryKeys.Projects],
    queryFn: async () => await getDashboardProjects(),
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    ...options
  });
