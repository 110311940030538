import React, { useMemo } from "react";
import { useParams } from "react-router";

import { Spinner } from "src/components";
import { useGetRecipe } from "src/hooks/api";
import CodeRecipeContainer from "./CodeRecipeContainer";
import { CodeRecipeContextProvider } from "./CodeRecipeContext/CodeRecipeContextProvider";

const CodeRecipeDataContainer = () => {
  const { groupId, scenarioId, jobRunId } = useParams<$TSFixMe>();
  const { isInitialLoading, isLoading, data } = useGetRecipe({
    recipeId: groupId,
    scenarioId,
    jobRunId
  });

  const { entityIds, outputs, charts } = useMemo(() => {
    return {
      entityIds: data?.recipe?.parents
        ?.filter((parent) => parent.type === "ENTITY")
        ?.map((parent) => parent.id),
      outputs: data?.recipe?.children
        ?.filter((child) => child.type === "ENTITY")
        ?.map((output) => output.name),
      charts: data?.recipe?.children
        ?.filter((child) => child.type === "CHART")
        ?.map((output) => output.name)
    };
  }, [data?.recipe]);

  if (!groupId) {
    return (
      <CodeRecipeContextProvider>
        <CodeRecipeContainer />
      </CodeRecipeContextProvider>
    );
  }

  return isInitialLoading ? (
    <Spinner />
  ) : (
    <CodeRecipeContextProvider
      templates={data?.templates}
      recipe={data?.recipe}
      outputs={outputs}
      isLoading={isLoading}>
      <CodeRecipeContainer entityIds={entityIds} outputs={outputs} charts={charts} />
    </CodeRecipeContextProvider>
  );
};

export default CodeRecipeDataContainer;
