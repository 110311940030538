import { toastWrapper } from "src/utils/toastWrapper";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";

const QUERY_KEY_SEGMENTS = "query-key-segments";

interface IReturn {
  condition: Record<string, any>;
  created: number;
  description: string;
  entityDisplayName: string;
  entityId: string;
  id: string;
  name: string;
  rowLimit: number;
}

const useEntitySegments = (
  entityId?: string,
  scenarioId?: string,
  jobRunId?: string,
  options?: UseQueryOptions<IReturn>
): UseQueryResult<IReturn> => {
  return useQuery({
    queryKey: [QUERY_KEY_SEGMENTS, entityId, scenarioId, jobRunId],
    queryFn: async () => {
      return await getAPIWithRethrow(
        `/v2/segments?entityId=${entityId}${!!scenarioId ? `&scenarioId=${scenarioId}` : ``}${
          !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
        }`
      );
    },
    ...options,
    refetchOnMount: true,
    onError: () => {
      toastWrapper({
        type: "error",
        content: "Segments not available"
      });
    }
  });
};

export default useEntitySegments;
export { QUERY_KEY_SEGMENTS };
