import React, { useMemo, useState } from "react";
import { useFetchChartData } from "../../Charts/useFetchChartData";
import PlotlyCharts from "src/components/PlotlyCharts";
import { Grid, IconButton, makeStyles, Tooltip, useMediaQuery, useTheme } from "@material-ui/core";
import { Spinner } from "src/components";
import ArrowCollapseIcon from "src/icons/NewUX/ArrowCollapseIcon";
import ArrowExpandIcon from "src/icons/NewUX/ArrowExpandIcon";
import NewModal from "src/components/Modal/NewModal";

const useStyles = makeStyles({
  chart: {
    minWidth: "calc(60vw)",
    width: "auto",
    overflow: "auto",
    borderRadius: "0px 0px 8px 8px",
    background: "#fff"
  },
  chartOutput: {
    background: "#fff",
    borderRadius: "2px",
    border: "1px solid #D1D1D1",
    overflowX: "auto",
    overflowY: "hidden",
    width: "100%"
  },
  chartName: {
    width: "100%",
    padding: "4px 12px 0px !important",
    color: "#133553",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #D1D1D1"
  }
});

const Chart = ({
  name,
  dataFilePath,
  chartType,
  shouldFetchData,
  plotlyJson = null
}: {
  name: string;
  dataFilePath?: string;
  chartType?: string;
  shouldFetchData?: boolean;
  plotlyJson?: null | string;
}) => {
  const classes = useStyles();
  const { chartData: initialChartData, isFetching } = useFetchChartData({
    name,
    dataFilePath,
    chartType: chartType || "",
    shouldFetchData: plotlyJson ? false : shouldFetchData
  });
  const [open, setOpen] = useState(false);
  const chartData = plotlyJson || initialChartData;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isXl = useMediaQuery(theme.breakpoints.down("xl"));

  const zoomFactor = isXs ? 1.1 : isSm ? 1.2 : isMd ? 1.3 : isLg ? 1.4 : isXl ? 1.5 : 1;

  const { chartJson, cleanedChartJson } = useMemo(() => {
    const cleanedJson = chartData
      ? {
          ...chartData,
          layout: {
            ...chartData.layout,
            title: null
          }
        }
      : {};

    const expandedJson = chartData
      ? {
          ...chartData,
          layout: {
            ...chartData.layout,
            title: null,
            width: chartData.layout.width * zoomFactor,
            height: chartData.layout.height * zoomFactor
          }
        }
      : {};

    return { chartJson: chartData, cleanedChartJson: cleanedJson, expandedJson };
  }, [chartData]);

  const expandedJson = useMemo(() => {
    if (!open) {
      return null;
    }
    return chartJson
      ? {
          ...chartJson,
          layout: {
            ...chartJson.layout,
            title: null,
            width: chartJson.layout.width * zoomFactor,
            height: chartJson.layout.height * zoomFactor
          }
        }
      : {};
  }, [chartJson, zoomFactor, open]);

  const handleExpand = () => {
    setOpen(!open);
  };

  const children = (
    <Grid container direction="column" className={classes.chartOutput}>
      {isFetching || !chartJson ? (
        <Spinner />
      ) : (
        <Grid container direction="column" style={{ width: "100%", gap: "4px" }}>
          <Grid item className={classes.chartName}>
            <span>{open && name}</span>
            <Tooltip title={open ? "Collapse" : "Expand"}>
              <IconButton size="small" onClick={handleExpand}>
                {open ? <ArrowCollapseIcon /> : <ArrowExpandIcon />}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid container className={classes.chart}>
            <PlotlyCharts {...(open ? expandedJson : cleanedChartJson)} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {children}
      <NewModal
        width="auto"
        contentWidth="auto"
        open={open}
        hideCloseButton
        hideFooter
        background="#fff"
        keepMounted={false}
        onClose={handleClose}>
        {children}
      </NewModal>
    </>
  );
};

export default React.memo(Chart);
