import React from "react";

// Icons
import IconButton from "@material-ui/core/IconButton";
import { PlusIcon } from "src/icons/NewUX/PlusIcon";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";

type Props = {
  isAddActionDisabled: boolean;
  onAddAction: $TSFixMeFunction;
};

const EnvironmentsHeader = (props: Props) => {
  const { isAddActionDisabled = false, onAddAction } = props || {};

  return (
    <SubTopNavBarWrapper
      subTopNavBarRightSection={{
        component: (
          <>
            <IconButton
              color="primary"
              size="small"
              disabled={isAddActionDisabled}
              onClick={onAddAction}>
              <PlusIcon width={28} height={28} />
            </IconButton>
          </>
        )
      }}
    />
  );
};

export default EnvironmentsHeader;
