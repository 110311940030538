import React, { useMemo, useState } from "react";
import { Grid, Typography, IconButton, Button, TextField, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import cloneDeep from "lodash/cloneDeep";
import { useForm } from "../../../../utils/useForm";
import { Spinner } from "../../../../components";
import { createSegment, updateSegment, getEntityFeatures, trySegment } from "../../../../api";
import { useFetchWithZustand } from "../../../../utils/useFetchWithZustand";
import { useInputEntitiesStore } from "../../../../store/store";
import {
  getEntityFeatures as entityFeaturesGetter,
  entityFeaturesSetter
} from "../../../../store/store.selectors";
import CriteriaBuilder from "./CriteriaBuilder";
import ModalSegmentTest from "./ModalSegmentTest";
import styles from "./NewSegment.module.scss";
import { validateNameField } from "src/utils/formFieldUtils";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import { toastWrapper } from "src/utils/toastWrapper";

export const getRulesFromExpression = (expression: $TSFixMe) => {
  let rules: $TSFixMe = [];
  const checkIfNested = (expressionGroup: $TSFixMe) => {
    const { groupElements = [] } = expressionGroup;
    rules.push({
      ...expressionGroup,
      groupElements: groupElements.filter((el: $TSFixMe) => el.type === "item")
    });
    if (expressionGroup.groupElements) {
      expressionGroup.groupElements?.forEach((item: $TSFixMe) => {
        if (item.type === "group") {
          checkIfNested(item);
        }
      });
    }
  };
  checkIfNested(expression);
  return rules;
};

const getInitialFormValues = (selectedSegment: $TSFixMe) => {
  if (selectedSegment) {
    return {
      segmentName: selectedSegment.name,
      segmentDescription: selectedSegment.description,
      rowLimit: selectedSegment.rowLimit,
      rules: getRulesFromExpression(selectedSegment?.condition?.expression)
    };
  } else {
    return {
      segmentName: "",
      segmentDescription: "",
      rowLimit: null,
      rules: []
    };
  }
};

type NewSegmentProps = {
  projectId?: string;
  isReadOnly?: boolean;
  open?: boolean;
  onClose?: $TSFixMeFunction;
  inputEntityId?: number;
  entityId?: $TSFixMe;
  selectedSegment: $TSFixMe;
};

const useStyles = makeStyles((theme) => ({
  segmentFieldsContainer: {
    "& .descriptionField": {
      "& label": {
        width: "75% !important"
      }
    },
    "&:not(last-child)": {
      marginBottom: theme.spacing(2)
    },
    "& [class^=MuiInputBase-input]": {
      backgroundColor: theme.palette.common.white
    }
  }
}));

const NewSegment = ({
  projectId,
  isReadOnly,
  onClose = () => null,
  entityId,
  selectedSegment
}: NewSegmentProps) => {
  const classes = useStyles();

  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const validate = (input: $TSFixMe) => {
    // // @ts-expect-error TS(2550) FIXME: Property 'values' does not exist on type 'ObjectCo... Remove this comment to see the full error message
    const inputValue = Object.values(input)[0];
    const inputKey = Object.keys(input)[0];

    if (inputKey === "segmentName") {
      const { error } = validateNameField({
        fieldName: inputValue as string,
        fieldNameLabel: `segment name`
      });
      setErrors((previousErrors) => ({ ...previousErrors, [inputKey]: error }));
    } else if (inputKey !== "rowLimit") {
      setErrors((previousErrors) => ({ ...previousErrors, [inputKey]: inputValue === "" }));
    }
  };

  const { values, setValues, handleInputChange, resetForm, setErrors, errors } = useForm(
    getInitialFormValues(selectedSegment),
    true,
    // @ts-expect-error TS(2345) FIXME: Argument of type '(input: $TSFixMe) => void' is no... Remove this comment to see the full error message
    validate
  );

  const [segmentData, setSegmentData] = useState(null);
  const [openModalSegmentTest, setOpenModalSegmentTest] = useState(false);

  const entityFeatures = useInputEntitiesStore(entityFeaturesGetter) || [];
  const setEntityFeatures = useInputEntitiesStore(entityFeaturesSetter);

  const { isLoading } = useFetchWithZustand({
    fetchCallback: getEntityFeatures,
    fetchingParams: entityId,
    setApiData: setEntityFeatures,
    shouldFetchData: !entityFeatures?.length || entityFeatures?.[0]?.entityId !== entityId
  });

  const rulesFormat = useMemo(() => {
    let expression: $TSFixMe;
    // @ts-expect-error TS(2339) FIXME: Property 'rules' does not exist on type '{}'.
    const rules = cloneDeep(values.rules);
    rules.reverse()?.forEach((ruleGroup: $TSFixMe, index: $TSFixMe) => {
      if (index > 0) {
        const nestedGroup = expression;
        expression = ruleGroup;
        expression.groupElements.push(nestedGroup);
      } else {
        expression = ruleGroup;
      }
    });
    return expression;
  }, [(values as $TSFixMe).rules]);

  const handleSubmit = async (e: $TSFixMe) => {
    e?.preventDefault();
    e?.stopPropagation();
    setOpenWarningModal(false);
    const segmentName = (values as $TSFixMe).segmentName.trim();
    const { error } = validateNameField({
      fieldName: segmentName,
      fieldNameLabel: `segment name`
    });

    // @ts-expect-error
    const emptyValues = values?.rules.reduce((acc: $TSFixMe, rule: $TSFixMe) => {
      return (
        acc ||
        rule.groupElements.reduce(
          (acc: $TSFixMe, element: $TSFixMe) =>
            acc || element?.value?.value?.trim() == "" || element?.field?.isEmpty,
          false
        )
      );
    }, false);
    const currentErrors = {
      rules: Boolean(!rulesFormat) || emptyValues,
      segmentName: error
    };

    setErrors((previousErrors) => ({
      ...previousErrors,
      ...currentErrors
    }));

    const hasErrors =
      // // @ts-expect-error TS(2550) FIXME: Property 'values' does not exist on type 'ObjectCo... Remove this comment to see the full error message
      Object.values({ ...errors, ...currentErrors }).some((error: $TSFixMe) => Boolean(error)) ||
      !rulesFormat;

    if (hasErrors) return;

    const payload = {
      ...(selectedSegment && { id: selectedSegment.id }),
      name: segmentName,
      description: (values as $TSFixMe).segmentDescription.trim(),
      rowLimit: (values as $TSFixMe).rowLimit,
      entityId,
      condition: {
        expression: rulesFormat
      }
    };

    const res = await (selectedSegment ? updateSegment(payload) : createSegment(payload));
    if (res && !res.msg) {
      toastWrapper({
        type: "success",
        content: selectedSegment ? "Segment updated" : "Segment created"
      });
    }
    resetForm();
    onClose(true);
  };

  const onChangeRules = (rules: $TSFixMe) => {
    setErrors((previousErrors) => ({ ...previousErrors, rules: false }));
    setValues({
      ...values,
      rules
    });
  };

  const handleModal = (bool: $TSFixMe) => {
    if (!bool) setSegmentData(null);
    setOpenModalSegmentTest(bool);
  };

  const handleTry = async () => {
    projectId && checkEnvRelaunch(projectId);

    try {
      if (!rulesFormat) return;
      const payload = {
        entityId,
        rowLimit: (values as $TSFixMe).rowLimit,
        condition: {
          expression: rulesFormat
        }
      };
      handleModal(true);
      const response = await trySegment(payload);
      setSegmentData({ ...response });
    } catch (error) {
      setSegmentData({
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ columns: never[]; rows: never[... Remove this comment to see the full error message
        columns: [],
        rows: []
      });
    }
  };

  const onRowLimitChange = (event: $TSFixMe) => {
    const updatedRowLimit = event.target.value;
    if (!updatedRowLimit || /^\d+$/.test(updatedRowLimit)) {
      handleInputChange(event);
    }
  };

  return (
    <>
      <ModalSegmentTest
        segmentData={segmentData}
        visible={openModalSegmentTest}
        onClose={handleModal}
      />

      {openWarningModal && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title="Update Segment"
          content={[
            "If you make any changes to the segment conditions associated with custom scenario(s), it will invalidate the existing canvas connections. To ensure the changes take effect, you will need to rerun the associated custom scenario(s).",
            "Are you sure you want to update?"
          ]}
          onClose={() => setOpenWarningModal(false)}
          onSubmit={handleSubmit}
          cancelLabel="Cancel"
          submitLabel="Yes, Update"
        />
      )}

      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <Grid container flexDirection="column" className={styles.newSegmentContainer}>
        <Grid item container justifyContent="space-between">
          <Grid item xs>
            <Typography color="primary" variant="h6">
              New Segment
            </Typography>
          </Grid>
          <Grid item xs container justifyContent="flex-end">
            <IconButton color="primary" onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container className={classes.segmentFieldsContainer} style={{ columnGap: 16 }}>
          <Grid item xs={4}>
            <TextField
              id="segmentName"
              name="segmentName"
              variant="outlined"
              size="small"
              fullWidth
              label="Segment Name *"
              disabled={isReadOnly}
              defaultValue={(values as $TSFixMe).segmentName}
              onChange={handleInputChange}
              {...(!!(errors as $TSFixMe)?.segmentName
                ? {
                    error: true,
                    helperText: (errors as $TSFixMe)?.segmentName
                  }
                : {})}
            />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField
              id="segmentDescription"
              name="segmentDescription"
              variant="outlined"
              size="small"
              fullWidth
              label="Description"
              className="descriptionField"
              disabled={isReadOnly}
              defaultValue={(values as $TSFixMe).segmentDescription}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.segmentFieldsContainer}>
          <Grid item xs={2}>
            <TextField
              id="rowLimit"
              name="rowLimit"
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              label="Row Limit"
              disabled={isReadOnly}
              defaultValue={(values as $TSFixMe).rowLimit}
              inputProps={{
                min: 1
              }}
              error={!!(errors as $TSFixMe)?.rowLimit}
              onChange={onRowLimitChange}
            />
          </Grid>
        </Grid>

        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <CriteriaBuilder
              isReadOnly={isReadOnly}
              // @ts-expect-error TS(2322) FIXME: Type '{ isLoading: boolean; entityFeatures: any; o... Remove this comment to see the full error message
              isLoading={isLoading}
              entityFeatures={entityFeatures}
              onChangeRules={onChangeRules}
              initialRules={(values as $TSFixMe).rules}
              // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              error={errors["rules"]}
            />
            {entityFeatures?.length !== 0 && (
              <Grid container className={styles.actionButtonContainer} justifyContent="flex-end">
                <Button color="primary" onClick={onClose} className={styles.actionButton}>
                  Cancel
                </Button>
                {!isReadOnly && (
                  <>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleTry}
                      className={styles.actionButton}>
                      Test
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(event: $TSFixMe) =>
                        selectedSegment ? setOpenWarningModal(true) : handleSubmit(event)
                      }
                      className={styles.actionButton}
                      disableElevation>
                      {selectedSegment ? "Update" : "Create"}
                    </Button>
                  </>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default NewSegment;
