import React from "react";

// Packages
import { BezierEdge, getSmoothStepPath, useNodes } from "react-flow-renderer";
import { getSmartEdge } from "@tisoap/react-flow-smart-edge";

const foreignObjectSize = 100;

const smartEdgeOptions = {
  nodePadding: 40,
  gridRatio: 10
};

const useDagNodeAndEdgeTypes = () => {
  const FixedSmoothStepEdge = (props: $TSFixMe) => {
    const edgePath = getSmoothStepPath({
      ...props,
      centerX: props?.sourceX + 65,
      borderRadius: 12
    });
    return (
      <path
        id={props?.id}
        style={props?.style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={props?.markerEnd}
      />
    );
  };

  const SmartEdgeWithButtonLabel = (props: $TSFixMe) => {
    const {
      sourcePosition,
      targetPosition,
      sourceX,
      sourceY,
      targetX,
      targetY,
      style,
      markerStart,
      markerEnd
    } = props;

    const nodes = useNodes();

    const getSmartEdgeResponse = getSmartEdge({
      sourcePosition,
      targetPosition,
      sourceX,
      sourceY,
      targetX,
      targetY,
      nodes,
      options: smartEdgeOptions
    });

    // If the value returned is null, it means "getSmartEdge" was unable to find
    // a valid path, and you should do something else instead
    if (getSmartEdgeResponse === null) {
      return <BezierEdge {...props} />;
    }

    const { edgeCenterX, edgeCenterY, svgPathString } = getSmartEdgeResponse;

    return (
      <>
        <path
          style={style}
          className="react-flow__edge-path"
          d={svgPathString}
          markerEnd={markerEnd}
          markerStart={markerStart}
        />
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={edgeCenterX - foreignObjectSize / 2}
          y={edgeCenterY - foreignObjectSize / 2}
          requiredExtensions="http://www.w3.org/1999/xhtml"></foreignObject>
      </>
    );
  };

  const edgeTypes = {
    smoothstep: FixedSmoothStepEdge,
    smart: SmartEdgeWithButtonLabel
  };

  return { edgeTypes };
};

export default useDagNodeAndEdgeTypes;
