import React, { useMemo, useState } from "react";
import _ from "lodash";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Typography
} from "@material-ui/core";

import OverflowTooltip from "components/OverflowTooltip";
import SelectSearch from "components/Inputs/SelectSearch";
import { IDestination } from "../JobDestinations/JobDestinations";
import { useStyles } from "./JobDestination.styles";

interface IConnector {
  id: string;
  name: string;
  image: JSX.Element;
  dataSourceType: string;
}

interface IProps extends SelectProps {
  connectors: IConnector[];
  destination: IDestination;
  onConnectorChange: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    dataSourceType: string
  ) => void;
}

const SelectJobConnectorType: React.FC<IProps> = (props) => {
  const { destination, connectors, onConnectorChange, ...restProps } = props;
  const classes = useStyles();

  const [value, setValue] = useState("");

  const filtered = useMemo(
    () => _.filter(connectors, ({ name }) => _.includes(_.lowerCase(name), _.lowerCase(value))),
    [value, connectors]
  );

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const dataSourceType = _.find(connectors, { id: e.target.value as string })?.dataSourceType;
    if (dataSourceType) {
      onConnectorChange(e, dataSourceType);
    }
  };

  return (
    <FormControl
      variant="outlined"
      style={{ marginBottom: 15 }}
      error={destination?.isSubmitted && !destination?.enteredDataSourceId}>
      <InputLabel style={{ top: "-5px" }} id="destinationLabel">
        * Destination
      </InputLabel>
      <Select
        id="destination"
        label="* Destination"
        style={{ height: "40px" }}
        labelId="destinationLabel"
        value={destination?.enteredDataSourceId}
        onChange={handleChange}
        onClose={() => setValue("")}
        {...restProps}>
        <SelectSearch value={value} onChange={setValue} />
        {_.map(filtered, (dataSource) => (
          <MenuItem key={dataSource.id} value={dataSource.id}>
            <Typography component="div" color="primary" className={classes.ellipsis}>
              <span
                style={{
                  display: "inline-block",
                  width: 20,
                  height: 20,
                  marginRight: 10
                }}>
                {dataSource?.image}
              </span>
              <OverflowTooltip value={dataSource?.name} />
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectJobConnectorType;
