import React, { useMemo, useState } from "react";

// Packages
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { DraggableProvided } from "react-beautiful-dnd";
import { get, has, replace, size, toUpper } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { LogsIcon } from "src/icons/NewUX";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Utils
import { dateFormat } from "src/utils/dateFormat";
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { RecipeTypesPathMapping } from "src/pages/private/ProjectsModule/utils";
import { WebPaths } from "src/routing/routes";
import useRemoveRecipe from "./useRemoveRecipe";

// Open API
import { RecipeRunData, RecipeRunDataStatusEnum } from "openapi/Models/recipe-run-data";

// Types
import { Scenario } from "src/types";

// Constants
import {
  QueuedRecipeTheme,
  RecipeRunsHelperText,
  statusNameMap
} from "./RecipeRunsPopover.constants";

// Components
import { OverflowTooltip } from "src/components";
import RecipeLogsDrawer from "src/pages/Projects/common/ShowLogsModal/RecipeLogsDrawer";

const useStyles = makeStyles((theme) => ({
  root: {
    columnGap: theme.spacing(2)
  },
  chip: {
    width: 85,
    backgroundColor: theme.palette.success.light,
    color: theme.palette.text.secondary
  }
}));

type Props = {
  scenariosMap: { [key: string]: Scenario };
  queuedRecipes: RecipeRunData[];
  data: RecipeRunData;
  isReorderingRecipeInQueue: boolean;
  provided: DraggableProvided;
};

const RecipeRow = (props: Props) => {
  const { scenariosMap, queuedRecipes, data, isReorderingRecipeInQueue, provided } = props || {};

  const { projectId, scenarioId } = useParams();
  const navigate = useNavigate();

  const classes = useStyles();

  const [openLogs, setOpenLogs] = useState(false);

  const { removeRecipeFromQueue, isRemovingRecipeFromQueue } = useRemoveRecipe({ data });

  const onRecipeClick = () => {
    if (!projectId || !areAllKeysPresentAndNotNil(data, ["scenarioId", "groupId", "recipeType"])) {
      return;
    }

    // @ts-ignore
    const recipeType = get(RecipeTypesPathMapping, data?.recipeType);

    if (!recipeType) {
      return;
    }

    const url = replace(
      WebPaths.StandardRecipeDataContainer,
      RecipeTypesPathMapping.STANDARD,
      recipeType
    );

    const path = generatePath(url, {
      projectId,
      scenarioId: data?.scenarioId,
      groupId: data?.groupId
    });

    navigate(path);
  };

  const onScenarioClick = () => {
    if (!projectId || !data?.scenarioId) {
      return;
    }

    const path = generatePath(`${WebPaths.Dag}${WebPaths.Canvas}`, {
      projectId,
      scenarioId: data?.scenarioId
    });

    navigate(path);
  };

  const recipeName = useMemo(
    () => data?.recipeDisplayName || data?.recipeName || RecipeRunsHelperText.Unknown,
    [data?.recipeDisplayName, data?.recipeName]
  );

  const disableActions = useMemo(
    () => isReorderingRecipeInQueue || isRemovingRecipeFromQueue,
    [isReorderingRecipeInQueue, isRemovingRecipeFromQueue]
  );

  const dragIconComponent = useMemo(() => {
    if (data?.status !== RecipeRunDataStatusEnum.InQueue || size(queuedRecipes) <= 1) {
      return <ListItemText style={{ width: 20 }} />;
    }

    return (
      <ListItemText
        style={{ width: 20 }}
        primaryTypographyProps={{ align: "center" }}
        primary={
          <Tooltip
            title={`Reorder Queue (applicable only for recipes with ${statusNameMap[RecipeRunDataStatusEnum.InQueue]} Status)`}>
            <DragIndicatorIcon fontSize="small" color="disabled" />
          </Tooltip>
        }
        {...(disableActions ? {} : provided?.dragHandleProps)}
      />
    );
  }, [data?.status, disableActions, provided]);

  const actionComponents = useMemo(() => {
    const isRecipeInQueue = toUpper(data?.status) === toUpper(RecipeRunDataStatusEnum.InQueue);
    const items: React.ReactNode[] = [
      <Tooltip
        key={`recipeRowActionLogs_${data?.groupId}_${data?.index}`}
        title={
          isRecipeInQueue
            ? "Logs are available once recipe starts running."
            : disableActions
              ? "Please wait"
              : "View logs"
        }>
        <span>
          <IconButton
            size="small"
            disabled={disableActions || isRecipeInQueue}
            onClick={() => setOpenLogs(() => true)}>
            <LogsIcon width={16} height={16} viewBox="0 0 22 22" />
          </IconButton>
        </span>
      </Tooltip>
    ];

    const isRecipeRunning = toUpper(data?.status) === toUpper(RecipeRunDataStatusEnum.Running);

    if (isRemovingRecipeFromQueue) {
      items.push(
        <CircularProgress key={`recipeRowActionRemove_${data?.groupId}_${data?.index}`} size={12} />
      );
    } else {
      items.push(
        <Tooltip
          key={`recipeRowActionRemove_${data?.groupId}_${data?.index}`}
          title={
            isRecipeRunning
              ? "Running recipe cannot be removed from the queue!"
              : disableActions
                ? "Please wait"
                : "Remove recipe from Queue"
          }>
          <span>
            <IconButton
              size="small"
              disabled={disableActions || isRecipeRunning}
              onClick={removeRecipeFromQueue}>
              <TrashIcon width={16} height={16} viewBox="0 0 22 22" />
            </IconButton>
          </span>
        </Tooltip>
      );
    }

    return items;
  }, [data, isRemovingRecipeFromQueue, disableActions]);

  return (
    <>
      {!!data?.groupId && openLogs && (
        <RecipeLogsDrawer
          name={recipeName}
          open={openLogs}
          projectId={projectId}
          scenarioId={data?.scenarioId}
          groupId={data?.groupId}
          isJobPath={false}
          onClose={() => setOpenLogs(() => false)}
        />
      )}

      <ListItem
        key={`headerSelection_${data?.groupId}_${data?.index}`}
        dense
        disabled={isReorderingRecipeInQueue}
        className={classes.root}
        style={{
          width: "30%",
          backgroundColor: data?.status === RecipeRunDataStatusEnum.InQueue ? "#f3f3ff" : "initial"
        }}
        ref={provided?.innerRef}
        {...provided?.draggableProps}
        {...provided?.dragHandleProps}>
        {dragIconComponent}
        <ListItemText
          style={{ width: "30%" }}
          primary={
            <Box style={{ cursor: "pointer" }} onClick={onRecipeClick}>
              <OverflowTooltip style={{ whiteSpace: "nowrap" }} value={recipeName} />
            </Box>
          }
        />
        <ListItemText
          style={{ width: "20%" }}
          primary={
            data?.scenarioId !== scenarioId ? (
              <Box style={{ cursor: "pointer" }} onClick={onScenarioClick}>
                <OverflowTooltip
                  style={{ whiteSpace: "nowrap" }}
                  value={
                    !!data?.scenarioId
                      ? get(scenariosMap, data?.scenarioId)?.name || RecipeRunsHelperText.Unknown
                      : RecipeRunsHelperText.Unknown
                  }
                />
              </Box>
            ) : (
              <OverflowTooltip
                style={{ whiteSpace: "nowrap" }}
                value={
                  !!data?.scenarioId
                    ? get(scenariosMap, data?.scenarioId)?.name || RecipeRunsHelperText.Unknown
                    : RecipeRunsHelperText.Unknown
                }
              />
            )
          }
        />
        <ListItemText
          style={{ width: "15%" }}
          primaryTypographyProps={{ align: "center" }}
          primary={
            <Chip
              size="small"
              label={
                !!data?.status
                  ? statusNameMap[data?.status] ?? RecipeRunsHelperText.Unknown
                  : RecipeRunsHelperText.Unknown
              }
              className={classes.chip}
              style={{
                backgroundColor: `${
                  !!data?.status
                    ? has(QueuedRecipeTheme, data?.status)
                      ? get(QueuedRecipeTheme, [data?.status, "backgroundColor"])
                      : QueuedRecipeTheme.backgroundColor
                    : QueuedRecipeTheme.backgroundColor
                }`
              }}
            />
          }
        />
        <ListItemText
          style={{ width: "25%" }}
          primary={<span>{dateFormat(data?.lastRunTimestamp) ?? "-"}</span>}
        />
        <ListItemText
          style={{ width: "10%" }}
          primary={
            <Grid container style={{ columnGap: 8 }}>
              {actionComponents}
            </Grid>
          }
        />
      </ListItem>
    </>
  );
};

export default RecipeRow;
