/* eslint-disable no-unused-vars */
import React from "react";
import {
  AutocompleteProps as MuiAutocompleteProps,
  Autocomplete as MuiAutocomplete,
  AutocompleteRenderInputParams
} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";

interface Option {
  label: string;
  value: string;
}

//@ts-expect-error
interface AutocompleteProps extends MuiAutocompleteProps<Option, false, false, false> {
  options: Option[];
  value: string | null;
  label: string;
  required?: boolean;
  onChange: (event: any, selectedOption: string | null) => void;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

const useStyles = makeStyles({
  selectOption: {
    whiteSpace: "normal"
  }
});

export const Autocomplete: React.FC<AutocompleteProps> = ({
  options,
  label,
  value,
  onChange,
  renderInput,
  required,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <MuiAutocomplete
      classes={{
        option: classes.selectOption
      }}
      options={options}
      value={options.find((option) => option.value === value) || null}
      getOptionLabel={(option: Option) => option.label}
      getOptionSelected={(option: Option, value: Option) => option.value === value.value}
      onChange={(event, newValue) => {
        onChange(event, newValue ? newValue.value : null);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          variant="outlined"
          label={label}
          placeholder=""
        />
      )}
      {...restProps}
    />
  );
};
