/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ChartInputRequestDto } from './chart-input-request-dto';
// May contain unused imports in some cases
// @ts-ignore
import { DataFileInputRequestDto } from './data-file-input-request-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ExternalDataSourceInputRequestDto } from './external-data-source-input-request-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ModelInputRequestDto } from './model-input-request-dto';

/**
 * 
 * @export
 * @interface TargetInputRequestDto
 */
export interface TargetInputRequestDto {
    /**
     * 
     * @type {ExternalDataSourceInputRequestDto}
     * @memberof TargetInputRequestDto
     */
    'externalDataSourceInputRequestDto'?: ExternalDataSourceInputRequestDto;
    /**
     * 
     * @type {DataFileInputRequestDto}
     * @memberof TargetInputRequestDto
     */
    'dataFileInput'?: DataFileInputRequestDto;
    /**
     * 
     * @type {ChartInputRequestDto}
     * @memberof TargetInputRequestDto
     */
    'chartInput'?: ChartInputRequestDto;
    /**
     * 
     * @type {ModelInputRequestDto}
     * @memberof TargetInputRequestDto
     */
    'modelInput'?: ModelInputRequestDto;
    /**
     * 
     * @type {string}
     * @memberof TargetInputRequestDto
     */
    'sourceInputId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TargetInputRequestDto
     */
    'inputName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TargetInputRequestDto
     */
    'inputType'?: TargetInputRequestDtoInputTypeEnum;
}

export const TargetInputRequestDtoInputTypeEnum = {
    ExternalDatasource: 'EXTERNAL_DATASOURCE',
    DataFile: 'DATA_FILE',
    Chart: 'CHART',
    Model: 'MODEL'
} as const;

export type TargetInputRequestDtoInputTypeEnum = typeof TargetInputRequestDtoInputTypeEnum[keyof typeof TargetInputRequestDtoInputTypeEnum];


