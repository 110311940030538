type SetItemProps = {
  key: string;
  data: $TSFixMe;
};

type GetItemProps = {
  key: string;
  shouldJsonParse?: boolean;
};

const setSessionStorageItem = ({ key, data }: SetItemProps) => {
  try {
    const stringifiedData = JSON.stringify(data);
    sessionStorage.setItem(key, stringifiedData);
  } catch (error: $TSFixMe) {
    console.error(error);
  }
};

const getSessionStorageItem = ({ key, shouldJsonParse = true }: GetItemProps) => {
  try {
    const data: $TSFixMe = sessionStorage.getItem(key);

    if (!data) {
      return;
    }

    return shouldJsonParse ? JSON.parse(data) : data;
  } catch (error: $TSFixMe) {
    console.error(error);
  }
};

const removeSessionStorageItem = (key: string) => sessionStorage.removeItem(key);

// Warning!
// Clears everything from session-storage.
const clearSessionStorage = () => sessionStorage.clear();

export {
  setSessionStorageItem,
  getSessionStorageItem,
  removeSessionStorageItem,
  clearSessionStorage
};
