import React, { useMemo } from "react";

import { dateFormat } from "src/utils/dateFormat";
import { Table } from "src/components";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { PencilUnderlined } from "src/icons/PencilUnderlined";
import { Delete } from "src/icons/Delete";
import { Link } from "@material-ui/core";

const ViewDataSegmentsTable = ({ data, onDelete, onEdit, isReadOnly }: $TSFixMe) => {
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        id: "Name",
        accessor: "name",
        isTooltip: false,
        Cell: ({ row, value }: $TSFixMe) =>
          isReadOnly ? (
            <Link onClick={() => onEdit(row?.original)}>{value}</Link>
          ) : (
            <LinkCellWithMenu
              linkText={value}
              onClick={() => onEdit(row?.original)}
              menuButtons={[
                {
                  icon: <PencilUnderlined />,
                  label: "Edit",
                  action: () => onEdit(row?.original)
                },
                {
                  icon: <Delete />,
                  label: "Delete",
                  action: () => onDelete(row?.original?.id)
                }
              ]}
            />
          )
      },
      {
        Header: "Description",
        id: "Description",
        accessor: "description"
      },
      {
        Header: "Created",
        id: "Created",
        accessor: "created",
        Cell: ({ cell }: $TSFixMe) => <span>{dateFormat(cell.value)}</span>
      },
      {
        Header: "Rows",
        id: "Rows",
        accessor: "rowLimit"
      }
    ],
    [isReadOnly]
  );

  return (
    <Table
      orderByDefault="Created"
      data={data}
      columns={columns}
      emptyTableMessage="No records"
      size="small"
      maxHeight="calc(100vh - 179px)"
    />
  );
};

export default ViewDataSegmentsTable;
