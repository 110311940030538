import React, { useEffect, useMemo } from "react";
import _, { isEmpty, find, lowerCase, trim } from "lodash";
import shallow from "zustand/shallow";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
  Typography
} from "@material-ui/core";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  FilterNone as FilterNoneIcon,
  LibraryAddCheckOutlined as LibraryAddCheckOutlinedIcon
} from "@material-ui/icons";

import useAuthStore from "src/stores/auth.store";
import useCopyClipboard from "src/hooks/useCopyClipboard";
import useTenantsStore from "src/stores/tenant-management.store";
import { DeleteNew } from "src/icons/DeleteNew";
import { Modal } from "src/components/custom";
import { RadioButtons } from "src/components";
import { PlusIcon } from "src/icons/PlusIcon";
import { Roles } from "src/types";
import { handleResponse } from "src/utils/apiService";
import { useInviteUserMutation } from "src/hooks/api";

type FormValues = {
  role: string;
  inviteType: string;
  emails: {
    email: string | undefined;
  }[];
  urls: {
    email: string;
    authLink?: string;
  }[];
};

const useStyles = makeStyles({
  loaderContainer: { height: 275 },
  email: {
    width: "380px"
  },
  authLink: {
    width: "264px"
  },
  arrayField: {
    gap: "8px",
    paddingBottom: "8px"
  }
});

const emailPattern =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const InviteUsersModal = ({ onClose }: any) => {
  const styles = useStyles();

  const [tenantName, askAiEnabled] = useAuthStore(
    (state) => [state.tenantName, state.askAiEnabled],
    shallow
  );
  const [roles, toggleTenantsRefresh] = useTenantsStore(
    (state) => [state.roles, state.toggleTenantsRefresh],
    shallow
  );

  const inviteTypes = useMemo(() => {
    const types = [
      {
        label: "Using Link (URL)",
        value: "url"
      }
    ];

    if (!!askAiEnabled) {
      types.unshift({
        label: "Email Invite",
        value: "email"
      });
    }

    return types;
  }, [askAiEnabled]);

  const { menuItems, roleAdminId } = useMemo(() => {
    return {
      menuItems: _.map(_.sortBy(roles, "name"), ({ name, id }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      )),
      roleAdminId: _.get(_.find(roles, { name: Roles.Admin.name }), "id")
    };
  }, [roles]);

  const { control, trigger, watch, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: {
      role: roleAdminId,
      inviteType: !!askAiEnabled ? "email" : "url",
      emails: [{ email: "" }],
      urls: [{ email: "", authLink: "" }]
    }
  });

  useEffect(() => {
    if (roleAdminId) {
      setValue("role", roleAdminId);
    }
  }, [roleAdminId]);

  const { fields, prepend, remove } = useFieldArray<FormValues, "emails", "email">({
    control,
    name: "emails"
  });

  const {
    fields: urlFields,
    prepend: prependURL,
    remove: removeURL,
    replace: replaceURLs
  } = useFieldArray<FormValues, "urls", "url">({
    control,
    name: "urls"
  });

  const inviteType = watch("inviteType");
  // These watch() are required to avoid only one item in array displaying error instead of all of them.
  watch("emails");
  watch("urls");

  const isEmailInvite = inviteType === "email";

  const inviteUserMutation = useInviteUserMutation();

  const onSubmit = async (data: FormValues) => {
    const result = await trigger();
    if (!result) {
      return;
    }
    isEmailInvite
      ? handleSendEmail({ emails: data.emails, roleId: data.role })
      : handleGetInvitations({ urls: data.urls, roleId: data.role });
  };

  const handleGetInvitations = ({ urls, roleId }: any) => {
    const requests = urls.map((url: any) => ({
      roleId,
      recipientEmail: url.email
    }));
    inviteUserMutation.mutate(
      { requests, isEmail: false },
      {
        onSuccess: (responses: any) => {
          const errorMessages = urls.reduce((acc: Array<string>, __: any, index: number) => {
            const [{ errorMessage }] = responses[index];
            return errorMessage ? [...acc, errorMessage] : acc;
          }, []);
          if (errorMessages?.length !== 0) {
            handleResponse({
              errorMessage: errorMessages
                .map((msg: string) => (msg.endsWith(".") ? msg : `${msg}.`))
                .join(" ")
            });
          }

          const updatedURLs = urls.map((url: any, index: number) => {
            const [{ link }] = responses[index];
            return { email: url.email, authLink: link };
          });
          replaceURLs(updatedURLs);
        }
      }
    );
  };

  const handleSendEmail = ({ emails, roleId }: any) => {
    const requests = emails.map((email: any) => ({
      roleId,
      recipientEmail: email.email
    }));
    inviteUserMutation.mutate(
      { requests },
      {
        onSuccess: (responses: any) => {
          const errorMessages = responses.reduce(
            (acc: Array<string>, response: any) =>
              response.errorMessage ? [...acc, response.errorMessage] : acc,
            []
          );
          if (errorMessages?.length !== 0) {
            handleResponse({
              errorMessage: errorMessages
                .map((msg: string) => (msg.endsWith(".") ? msg : `${msg}.`))
                .join(" ")
            });
            onModalClose();
            return;
          }
          const successMessage = `${
            emails.length === 1 ? "Email Invitation" : emails.length + " Email Invitations"
          } sent successfully`;
          handleResponse({
            successMessage
          });
          onModalClose();
        },
        onError: () => {
          onClose();
        }
      }
    );
  };

  const submitLabel = React.useMemo(
    () =>
      inviteType === "email"
        ? `Send ${fields.length === 1 ? "Invite" : fields.length + " Invites"}`
        : `Generate Auth ${urlFields.length === 1 ? "Link" : "Links"}`,
    [fields.length, inviteType, urlFields.length]
  );

  const onModalClose = () => {
    toggleTenantsRefresh();
    onClose();
  };

  const getDefaultUserRole = useMemo(
    () =>
      find(
        roles,
        (eachMenu: $TSFixMe) =>
          lowerCase(trim(eachMenu?.name)) === lowerCase(trim(Roles.Admin.name))
      ),
    [roles]
  );

  const isEmailUnique = (value: string, index: number) => {
    const currentEmails = watch("emails");
    const otherEmails = currentEmails.filter((_, i) => i !== index);
    return !otherEmails.some((email) => email.email === value);
  };

  const isUrlEmailUnique = (value: string, index: number) => {
    const currentEmails = watch("urls");
    const otherEmails = currentEmails.filter((_, i) => i !== index);
    return !otherEmails.some((email) => email.email === value);
  };

  return (
    <Modal
      open={true}
      size="md"
      title={`Invite users to ${tenantName}`}
      submitLabel={submitLabel}
      isSubmitting={inviteUserMutation.isLoading}
      isSubmitDisabled={isEmpty(menuItems)}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onModalClose}>
      {isEmpty(menuItems) ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          className={styles.loaderContainer}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Box py="8px">
            <Grid>
              <Controller
                name="role"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    variant="outlined"
                    style={{ width: "42%", marginBottom: "15px" }}
                    component="fieldset">
                    <FormLabel component="legend">
                      <Typography style={{ fontWeight: 500 }}>User Role</Typography>
                    </FormLabel>
                    <InputLabel id="demo-simple-select-label" />
                    <Select
                      labelId="demo-simple-select-label"
                      id="role-id"
                      defaultValue={getDefaultUserRole?.id}
                      value={value}
                      onChange={onChange}>
                      {menuItems}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid>
              <Controller
                name="inviteType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioButtons
                    row
                    size="small"
                    label="Invite Type"
                    labelStyleProps={{
                      color: "#515151"
                    }}
                    values={inviteTypes}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid>
              <Typography style={{ fontWeight: 500, marginBottom: "8px", color: "#515151" }}>
                User Email
              </Typography>
              {inviteType === "email"
                ? fields.map((field: any, index: number) => {
                    return (
                      <Grid
                        key={field.id}
                        container
                        alignItems="center"
                        className={styles.arrayField}>
                        <Controller
                          name={`emails.${index}.email` as const}
                          control={control}
                          rules={{
                            required: "This field is required.",
                            validate: {
                              isValidPassword: (value) => {
                                if (value && !emailPattern.test(value)) {
                                  return "Email format invalid";
                                }

                                return true;
                              },
                              isUnique: (value?: string) => {
                                if (!value || isEmailUnique(value, index)) {
                                  return true;
                                }

                                return "Email must be unique";
                              }
                            }
                          }}
                          render={({ field, fieldState }) => {
                            const { error } = fieldState;
                            return (
                              <TextField
                                {...field}
                                label="User Email"
                                variant="outlined"
                                size="small"
                                required
                                error={!!error}
                                helperText={error?.message}
                                className={styles.email}
                                onBlur={() => trigger()}
                              />
                            );
                          }}
                        />
                        <IconButton
                          id={`delete-row-button-${index}`}
                          color="primary"
                          onClick={() => {
                            remove(index);
                            trigger();
                          }}
                          disabled={fields.length === 1}>
                          <DeleteNew disabled={fields.length === 1} />
                        </IconButton>
                        {index == 0 && (
                          <IconButton
                            id={`add-row-button-${index}`}
                            color="primary"
                            onClick={() =>
                              prepend({
                                email: ""
                              })
                            }>
                            <PlusIcon />
                          </IconButton>
                        )}
                      </Grid>
                    );
                  })
                : urlFields.map((field: any, index: number) => {
                    return (
                      <Grid
                        key={field.id}
                        container
                        alignItems="flex-start"
                        className={styles.arrayField}>
                        <Controller
                          name={`urls.${index}.email` as const}
                          control={control}
                          rules={{
                            required: "This field is required.",
                            validate: {
                              isValidPassword: (value) => {
                                if (value && !emailPattern.test(value)) {
                                  return "Email format invalid";
                                }

                                return true;
                              },
                              isUnique: (value?: string) => {
                                if (!value || isUrlEmailUnique(value, index)) {
                                  return true;
                                }

                                return "Email must be unique";
                              }
                            }
                          }}
                          render={({ field, fieldState }) => {
                            const { error } = fieldState;
                            return (
                              <TextField
                                {...field}
                                style={{ paddingRight: "16px" }}
                                label="Email"
                                variant="outlined"
                                size="small"
                                required
                                error={!!error}
                                helperText={error?.message}
                                onBlur={() => trigger()}
                              />
                            );
                          }}
                        />
                        <Controller
                          name={`urls.${index}.authLink` as const}
                          control={control}
                          render={({ field: { onChange, value = "" }, fieldState }) => {
                            const { error } = fieldState;
                            const { handleCopyClick, isCopied } = useCopyClipboard(value);
                            return (
                              <TextField
                                label="Auth Link"
                                variant="outlined"
                                size="small"
                                onChange={onChange}
                                value={value}
                                disabled={!value}
                                error={!!error}
                                helperText={error?.message}
                                className={styles.authLink}
                                // @ts-expect-error
                                readOnly={value !== ""}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton
                                        id={`copy-invite-link-button-${index}`}
                                        aria-label="copy invite link"
                                        onClick={handleCopyClick}
                                        onMouseDown={handleCopyClick}
                                        edge="end">
                                        {isCopied ? (
                                          <LibraryAddCheckOutlinedIcon fontSize="small" />
                                        ) : (
                                          <FilterNoneIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            );
                          }}
                        />
                        <Box mt="2px">
                          <IconButton
                            id={`delete-row-button-${index}`}
                            color="primary"
                            onClick={() => removeURL(index)}
                            disabled={urlFields.length === 1}>
                            <DeleteNew disabled={urlFields.length === 1} />
                          </IconButton>
                        </Box>
                        {index == 0 && (
                          <IconButton
                            id={`add-row-button-${index}`}
                            color="primary"
                            onClick={() =>
                              prependURL({
                                email: ""
                              })
                            }>
                            <PlusIcon />
                          </IconButton>
                        )}
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
        </>
      )}
    </Modal>
  );
};

export default InviteUsersModal;
