import React, { useEffect, useMemo, useState } from "react";

import {
  Drawer,
  Grid,
  Paper,
  Toolbar,
  Button,
  Tooltip,
  CircularProgress,
  Typography
} from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { toastWrapper } from "src/utils/toastWrapper";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { RenderText } from "src/utils";

import { useManualJobRun } from "src/hooks/api";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { Field } from "src/components";
import { useStyles as useSubtopBarStyles } from "src/components/SubtopBar/styling";

import { JobsHelperText } from "../../utils/Jobs.constants";

import { useStyles } from "./ManualJobRun.styles";

const ManualJobRun = (props: $TSFixMe) => {
  const { close, projectId, jobId, jobParametersData, navigateToJobRunsPage } = props || {};

  const { barMainButton, actionButton } = useSubtopBarStyles();
  const classes = useStyles();

  // States - STARTS >>
  const [jobRunName, setJobRunName] = useState<$TSFixMe>("");
  const [jobParameters, setJobParameters] = useState<$TSFixMe>([]);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isRunning,
    mutateAsync: manualJobRunMutation,
    reset: resetManualJobRunMutation
  } = useManualJobRun();
  // << ENDS - Query hooks

  useEffect(() => {
    const thisJobParameters =
      Object.keys(jobParametersData || {})?.map((key: $TSFixMe) => ({
        key: key,
        value: jobParametersData[key]
      })) || [];

    setJobParameters(() => [...(thisJobParameters || [])]);
  }, [jobParametersData]);

  const onJobRunNameChange = (event: $TSFixMe) => {
    setJobRunName(() => event?.target?.value);
  };

  const onValueChange = (e: $TSFixMe, index: number, type: string) => {
    const thisJobParameters: $TSFixMe = [...jobParameters];
    thisJobParameters[index][type] = e?.target?.value;
    setJobParameters(() => [...thisJobParameters]);
  };

  const isValidJobParameters = () =>
    (jobParameters || [])?.every(
      (eachParameter: $TSFixMe) => !!eachParameter?.key && !!eachParameter?.value
    );

  const runJob = async () => {
    if (!isValidJobParameters()) {
      return;
    }

    resetManualJobRunMutation();

    projectId && checkEnvRelaunch(projectId);

    const thisJobParameters: $TSFixMe = {};
    (jobParameters || [])?.forEach((eachParameter: $TSFixMe) => {
      if (!!eachParameter?.key && !!eachParameter?.value) {
        thisJobParameters[eachParameter.key] = eachParameter.value;
      }
    });

    const payload = {
      jobId,
      deleteOld: false,
      variables: thisJobParameters,
      useOldMetadata: true,
      ...(!!jobRunName ? { runId: jobRunName?.trim() } : {})
    };

    await manualJobRunMutation(payload, {
      onSuccess: (data: $TSFixMe) => {
        let toastDetails: $TSFixMe = {
          message: JobsHelperText.ManualJobRunStartedMessage,
          type: "success"
        };

        let runDetailsResponseStatus: $TSFixMe = "";
        if (Object.keys(data || {})?.length > 0) {
          runDetailsResponseStatus = data?.status;
          if (runDetailsResponseStatus === "FAILURE") {
            toastDetails = {
              message: data?.error || "Job run failed!",
              type: "error"
            };
          }
        }

        toastWrapper({ type: toastDetails.type, content: toastDetails.message });

        if (runDetailsResponseStatus !== "FAILURE") {
          navigateToJobRunsPage();
        }
      }
    });
  };

  const disabledRunActionMessage = useMemo(() => {
    if (isRunning) {
      return "The run action is in progress. Please wait.";
    }

    if (!isValidJobParameters()) {
      return "Invalid global variables.";
    }

    return "";
  }, [isRunning, jobParameters]);

  return (
    <Drawer
      open
      anchor="right"
      variant="temporary"
      onClose={close}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper
      }}
      disableEscapeKeyDown={isRunning}>
      <SubTopNavBarWrapper
        variant="drawer"
        onDrawerClose={close}
        subTopNavBarLeftSection={{
          component: (
            <RenderText color="textSecondary" isOverflowTooltip>
              Manual Run Configuration
            </RenderText>
          )
        }}
      />

      <Toolbar />
      <Grid container direction="column" wrap="nowrap" className={classes.container}>
        <Grid container>
          <Field
            id="jobRunName"
            label="Run Name (Optional)"
            // @ts-ignore
            value={jobRunName}
            onChange={onJobRunNameChange}
            variant="outlined"
            size="small"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <Tooltip title="System will generates a name, if this is left blank.">
                  <InfoOutlinedIcon
                    fontSize="small"
                    style={{ marginLeft: 15, cursor: "help", opacity: 0.5 }}
                  />
                </Tooltip>
              )
            }}
          />
        </Grid>
        {(jobParameters || [])?.length > 0 && (
          <Grid container>
            <Paper variant="outlined" square className={classes.subContainer}>
              <Typography variant="body1" style={{ padding: "12px 14px " }}>
                Global Variables
              </Typography>
              {(jobParameters || [])?.map((eachJobParameter: $TSFixMe, index: number) => {
                return (
                  <Grid
                    container
                    alignItems="center"
                    key={index}
                    wrap="nowrap"
                    className={classes.inputRow}>
                    <Grid item xs={6}>
                      <Field
                        id={`parameterKeyLabel_${index}`}
                        label="Key"
                        // @ts-ignore
                        value={eachJobParameter?.key}
                        variant="outlined"
                        size="small"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        id={`parameterValue_${index}`}
                        label="Value"
                        // @ts-ignore
                        value={eachJobParameter?.value}
                        onChange={(e: $TSFixMe) => onValueChange(e, index, "value")}
                        error={!eachJobParameter?.value}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Paper>
          </Grid>
        )}
      </Grid>
      <footer className={classes.footerContainer}>
        <Tooltip title={disabledRunActionMessage}>
          <div>
            <Button
              variant="outlined"
              color="primary"
              onClick={close}
              disabled={!!disabledRunActionMessage}
              className={`${barMainButton} ${actionButton}`}>
              Cancel
            </Button>
          </div>
        </Tooltip>
        <Tooltip title={disabledRunActionMessage}>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => runJob()}
              disabled={!!disabledRunActionMessage}>
              {isRunning ? <CircularProgress color="secondary" size={20} /> : "Run"}
            </Button>
          </div>
        </Tooltip>
      </footer>
    </Drawer>
  );
};

export default ManualJobRun;
