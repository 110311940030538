import axios from "axios";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import client from "src/utils/AxiosClient";
import useAuthStore from "src/stores/auth.store";
import { AuthSessionConfig, PathRexExps } from "src/constants/common.constants";
import { getLocalStorageItem } from "src/services/LocalStorage/LocalStorage.service";
import { getSessionStorageItem } from "src/services/SessionStorage/SessionStorage.service";
import { logNotebooksOut } from "src/utils/operateNotebookService";
import { loginPath } from "src/routing/routes";

export const QUERY_KEY_TOKEN_VALIDATION = "query-key-token-validation";

interface IAuthResponse {
  cpuGuarantee: number;
  dataappsTtlInHrs: number;
  eulaAccepted: boolean;
  expiresAt: number;
  ghEmail: string;
  ghFullName: string;
  memGuarantee: number;
  notebookId: string;
  notebookType: string;
  roleId: string;
  roleName: string;
  tenantId: string;
  tenantName: string;
  token: string;
  userEmail: string;
  userId: string;
}
const getTokenFromStorage = () => {
  const tokenFromLocalStorage = getLocalStorageItem({
    key: AuthSessionConfig.TokenSessionKey,
    shouldJsonParse: false
  });

  const tokenFromSessionStorage = getSessionStorageItem({
    key: AuthSessionConfig.TokenSessionKey,
    shouldJsonParse: false
  });

  return tokenFromLocalStorage || tokenFromSessionStorage;
};

const useCheckTokenValidation = (
  options?: UseQueryOptions<IAuthResponse>
): UseQueryResult<IAuthResponse> => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = useMemo(() => location.pathname, [location.pathname]);

  const [logout, logoutTimer] = useAuthStore((state) => [state.logout, state.logoutTimer]);

  const navigateToLogin = () => {
    navigate(
      {
        pathname: PathRexExps.auth.test(currentPath) ? currentPath : loginPath,
        search: location.search
      },
      { state: { from: location } }
    );
  };

  return useQuery({
    queryKey: [QUERY_KEY_TOKEN_VALIDATION],
    queryFn: async () => {
      const token = searchParams.get(AuthSessionConfig.TokenSessionKey);
      const tokenFromStorage = token ?? getTokenFromStorage();

      if (!tokenFromStorage) {
        navigateToLogin();
        logNotebooksOut();
        throw "Token is absent";
      }

      try {
        const { data } = await axios.post("/api/token/validation", { token: tokenFromStorage });
        client.init(tokenFromStorage);
        return data;
      } catch {
        logout(logoutTimer);
        navigateToLogin();
        client.logout();
        throw "token validation failed";
      }
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    ...options
  });
};

export default useCheckTokenValidation;
