import React, { useMemo } from "react";

import { Button, Avatar, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import useHelpers from "../../hooks/useHelpers";
import useFilesObserver from "../../hooks/useFilesObserver";
import useStoreSelectors from "../../hooks/useStoreSelectors";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { Criteria, DatasetHelperText, criteria } from "../../utils/Dataset.constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import OntologySectionStepper from "./OntologySectionStepper";
import { isEmpty, size } from "lodash";
import OntologyActions from "./OntologyActions";
import SqlInterfaceActions from "./SqlInterfaceActions";
import ConnectorFilesActions from "./ConnectorFilesActions";
import LocalFilesActions from "./LocalFilesActions";
import { useUploadContext } from "../../contexts/Upload/useUploadContext";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import AppendFileName from "./AppendFileName";
import { WebPaths } from "src/routing/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: theme.spacing(5)
  },
  buttonMarginLeft: {
    extend: "button",
    marginLeft: theme.spacing(1)
  },
  filesCountContainer: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    fontSize: "small",
    color: theme.palette.common.black
  }
}));

const Header = () => {
  const classes = useStyles();

  const { ontologyDatasetIndex, ontologyNavigation } = useUploadContext();

  const { source, isDataSourcesFilesUpload } = useHelpers();
  const { projectId, scenarioId, datasetId } = useParams();
  const navigate = useNavigate();

  const { isFilesUploadDone } = useFilesObserver();

  // Stores - STARTS >>
  const {
    datasetCriterionStore,
    datasetSelectedDataSourceFilesStore,
    datasetFilesStore,
    datasetDatasetsStore,
    datasetIsOntologyStore,
    datasetFooterActionsStore
  } = useStoreSelectors();
  // << ENDS - Stores

  const selectedCriterion = useMemo(
    () =>
      criteria?.find(
        (eachCriterion: $TSFixMe) => eachCriterion?.id === datasetCriterionStore?.value
      ),
    [datasetCriterionStore?.value]
  );

  const isStepper = useMemo(
    () =>
      !source?.isSql &&
      datasetIsOntologyStore &&
      datasetCriterionStore?.value === Criteria.Segregate &&
      !isEmpty(datasetDatasetsStore),
    [datasetCriterionStore?.value, datasetDatasetsStore, datasetIsOntologyStore]
  );

  const openDataset = () => {
    if (projectId && datasetDatasetsStore?.[0]?.id && scenarioId) {
      const path = generatePath(WebPaths.ViewData, {
        projectId,
        scenarioId,
        entityId: datasetDatasetsStore?.[0]?.id,
        section: "data"
      });
      navigate(path, { replace: true });
    }
  };
  const ontologyClose = useMemo(() => {
    if (datasetFooterActionsStore.ontologyClose.isHidden) {
      return <></>;
    }

    return (
      <>
        {(datasetCriterionStore.value === Criteria.SingleFileUpload ||
          datasetCriterionStore.value === Criteria.Merge) &&
          !!isFilesUploadDone && (
            <Button
              id="datasetActionOpenDataset"
              variant="contained"
              color="primary"
              className={classes.buttonMarginLeft}
              disabled={
                datasetFooterActionsStore.ontologyClose.isDisabled || !datasetDatasetsStore?.[0]?.id
              }
              onClick={() => openDataset()}>
              {DatasetHelperText.OpenDataset}
            </Button>
          )}
        <Button
          id="datasetActionDone"
          variant="contained"
          color="primary"
          className={classes.buttonMarginLeft}
          disabled={datasetFooterActionsStore.ontologyClose.isDisabled}
          onClick={() => ontologyNavigation()}>
          {DatasetHelperText.Done}
        </Button>
      </>
    );
  }, [
    datasetFooterActionsStore,
    datasetDatasetsStore,
    ontologyDatasetIndex,
    datasetCriterionStore
  ]);

  const noOfFilesTemplate = useMemo(() => {
    if (!!source?.isSql || !!datasetIsOntologyStore) {
      return;
    }

    let noOfFiles = 0;

    if (size(datasetFilesStore) === 0) {
      if (isDataSourcesFilesUpload) {
        if (size(datasetSelectedDataSourceFilesStore) > 0) {
          noOfFiles = size(datasetSelectedDataSourceFilesStore);
        }
      }
    } else {
      noOfFiles = size(datasetFilesStore);
    }

    return noOfFiles > 0 && <Avatar className={classes.filesCountContainer}>{noOfFiles}</Avatar>;
  }, [
    source?.isSql,
    datasetIsOntologyStore,
    datasetFilesStore,
    isDataSourcesFilesUpload,
    datasetSelectedDataSourceFilesStore,
    datasetFilesStore
  ]);

  return (
    <Grid container alignItems="center" justifyContent="space-between" className={classes.root}>
      <Grid xs={3}>
        {source?.isSql ? (
          source?.name || DatasetHelperText.Unknown
        ) : (
          <Grid container style={{ columnGap: 8 }}>
            <Typography id="selectedCriterionName">
              {selectedCriterion?.name || DatasetHelperText.Unknown}
            </Typography>
            <Tooltip id="selectedCriterionDescription" title={selectedCriterion?.description || ""}>
              <InfoOutlinedIcon className="info" fontSize="small" />
            </Tooltip>
            {noOfFilesTemplate}
          </Grid>
        )}
      </Grid>

      {isStepper && <OntologySectionStepper ontologyDatasetIndex={ontologyDatasetIndex} />}
      {selectedCriterion?.id === "APPEND" && <AppendFileName datasetId={datasetId} />}
      <Grid xs={3} style={{ textAlign: "end" }}>
        {datasetIsOntologyStore ? (
          <OntologyActions ontologyClose={ontologyClose} />
        ) : source?.isSql ? (
          <SqlInterfaceActions ontologyClose={ontologyClose} />
        ) : isDataSourcesFilesUpload ? (
          <ConnectorFilesActions />
        ) : (
          <LocalFilesActions />
        )}
      </Grid>
    </Grid>
  );
};

export default Header;
