import { ConfigInputTypeEnum } from "openapi/Models/config";

export enum DataAppsConfig {
  ExemptingWidth = 0.15, // 15%
  CardWidth = 575,
  CardHeight = 305
}

export enum DataAppsSplashSection {
  title = "Get Started with a New DataApp",
  subTitleLine1 = "Welcome to the DataApp page.",
  subTitleLine2 = "Start a new one by clicking on the button below.",
  actionLabel = "+ New DataApp"
}

export const STOPPING_DATAAPP_MSG = "Please wait while the DataApp stops";
export const LAUNCHING_DATAAPP_MSG = "Please wait while the dataapp is launching";
export const DELETING_DATAAPP_MSG = "Dataapp deletion is already in progress";

export const askAITypesInfoList = [
  {
    title: "Project Canvas Datasets",
    tooltip:
      "This option supports using AskAI on all the datasets available on the project canvas along with user uploaded datasets",
    label: "Project",
    value: ConfigInputTypeEnum.ProjectCanvas,
    disabledTooltip:
      "Each project can only be associated with one project AskAI DataApp. If necessary, please delete the existing AskAI DataApp associated with this project and create a new one"
  },
  {
    title: "Uploaded Datasets",
    tooltip: "This option supports using AskAI on User uploaded datasets",
    label: "Datasets",
    value: ConfigInputTypeEnum.Dataset,
    disabledTooltip:
      "Each project can only be associated with one datset AskAI DataApp. If necessary, please delete the existing AskAI DataApp associated with this project and create a new one"
  }
  // {
  //   title: "SQL DB as Inputs",
  //   tooltip:
  //     "This option supports using AskAI on MySQL based database. The connection needs to be created under Connectors menu under the main navigation",
  //   label: "SQL Sources",
  //   value: ConfigInputTypeEnum.SqlDb,
  //   disabledTooltip: ""
  // }
];
