/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EntityMetaDto } from './entity-meta-dto';
// May contain unused imports in some cases
// @ts-ignore
import { SegmentDto } from './segment-dto';

/**
 * 
 * @export
 * @interface EntityDto
 */
export interface EntityDto {
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'markdown'?: string;
    /**
     * 
     * @type {EntityMetaDto}
     * @memberof EntityDto
     */
    'entityMeta'?: EntityMetaDto;
    /**
     * 
     * @type {number}
     * @memberof EntityDto
     */
    'created'?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityDto
     */
    'updated'?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityDto
     */
    'built'?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'status'?: EntityDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'scenarioStepId'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityDto
     */
    'positionX'?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityDto
     */
    'positionY'?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'creator'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityDto
     */
    'numberOfSegments'?: number;
    /**
     * 
     * @type {Array<SegmentDto>}
     * @memberof EntityDto
     */
    'segments'?: Array<SegmentDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EntityDto
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'dataSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'dataSourceName'?: string;
    /**
     * 
     * @type {object}
     * @memberof EntityDto
     */
    'dataSourceOptions'?: object;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'dataSourceType'?: EntityDtoDataSourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'syncDataSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'syncDataSourceName'?: string;
    /**
     * 
     * @type {object}
     * @memberof EntityDto
     */
    'syncDataSourceOptions'?: object;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'syncDataSourceType'?: EntityDtoSyncDataSourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityDto
     */
    'image'?: string;
}

export const EntityDtoStatusEnum = {
    Pending: 'PENDING',
    Built: 'BUILT',
    Empty: 'EMPTY',
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Success: 'SUCCESS',
    Error: 'ERROR',
    Unbuilt: 'UNBUILT',
    Skipped: 'SKIPPED',
    Building: 'BUILDING'
} as const;

export type EntityDtoStatusEnum = typeof EntityDtoStatusEnum[keyof typeof EntityDtoStatusEnum];
export const EntityDtoDataSourceTypeEnum = {
    GcpStorage: 'GCP_STORAGE',
    S3Storage: 'S3_STORAGE',
    AzureBlob: 'AZURE_BLOB',
    Mysql: 'MYSQL',
    Mongo: 'MONGO',
    Snowflake: 'SNOWFLAKE',
    Redshift: 'REDSHIFT',
    RedisStorage: 'REDIS_STORAGE',
    ThirdParty: 'THIRD_PARTY'
} as const;

export type EntityDtoDataSourceTypeEnum = typeof EntityDtoDataSourceTypeEnum[keyof typeof EntityDtoDataSourceTypeEnum];
export const EntityDtoSyncDataSourceTypeEnum = {
    GcpStorage: 'GCP_STORAGE',
    S3Storage: 'S3_STORAGE',
    AzureBlob: 'AZURE_BLOB',
    Mysql: 'MYSQL',
    Mongo: 'MONGO',
    Snowflake: 'SNOWFLAKE',
    Redshift: 'REDSHIFT',
    RedisStorage: 'REDIS_STORAGE',
    ThirdParty: 'THIRD_PARTY'
} as const;

export type EntityDtoSyncDataSourceTypeEnum = typeof EntityDtoSyncDataSourceTypeEnum[keyof typeof EntityDtoSyncDataSourceTypeEnum];


