/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { AppTemplateInputMetadata } from './app-template-input-metadata';

/**
 * 
 * @export
 * @interface AppTemplateInput
 */
export interface AppTemplateInput {
    /**
     * 
     * @type {string}
     * @memberof AppTemplateInput
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppTemplateInput
     */
    'inputType'?: AppTemplateInputInputTypeEnum;
    /**
     * 
     * @type {AppTemplateInputMetadata}
     * @memberof AppTemplateInput
     */
    'metadata'?: AppTemplateInputMetadata;
}

export const AppTemplateInputInputTypeEnum = {
    Entity: 'ENTITY',
    Artifact: 'ARTIFACT',
    Model: 'MODEL',
    Scenario: 'SCENARIO'
} as const;

export type AppTemplateInputInputTypeEnum = typeof AppTemplateInputInputTypeEnum[keyof typeof AppTemplateInputInputTypeEnum];


