import React from "react";

// Packages
import clsx from "clsx";

// MUI
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";

// Constants
import { NodeTypeNames } from "src/pages/private/ProjectsModule/utils";

// Types
import { NodeData } from "src/types";

// Styles
import useStyles from "./ModelReadonly.styles";

type Props = {
  data: NodeData;
};

const ModelReadonly = (props: Props) => {
  const { data } = props || {};

  const classes: $TSFixMe = useStyles({ status: data?.status });
  // << ENDS - Hooks

  return (
    <Tooltip title={NodeTypeNames.Model}>
      <div className={clsx(classes.root, classes[data?.status])}>
        <div className={classes.container}>
          <div className={classes.icon}>
            <EmojiEventsIcon />
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default ModelReadonly;
