import React from "react";
import { makeStyles } from "@material-ui/core";

import JsonEditor from "components/editor/JsonEditor";

interface IProps {
  jsonString: string;
}

const useStyles = makeStyles({
  editor: {
    width: "100% !important",
    height: "400px !important",
    background: "black",
    color: "white",

    "& .ace_cursor": {
      color: "white !important"
    },

    "& .ace_gutter": {
      background: "black",
      color: "white"
    },

    "& .ace_print-margin": {
      visibility: "hidden !important"
    },

    "& .ace_indent-guide": {
      background: "none !important"
    }
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    margin: "16px 0"
  }
});

const PredictionResults: React.FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div>PredictionResults</div>
      <JsonEditor className={classes.editor} readOnly jsonString={props.jsonString} />
    </div>
  );
};

export default PredictionResults;
