import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#f5f5f5",
    display: "flex",
    flexDirection: "column",
    padding: "64px 10% 32px 10%",
    width: "100%",
    // New UX change
    // The value 96px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 46px).
    height: "calc(100vh - 96px)",
    overflowY: "auto",
    overflowX: "hidden"
  },
  title: {
    color: "#003656",
    fontSize: "32px",
    lineHeight: "37.5px",
    paddingBottom: "4px"
  },
  topActions: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    marginLeft: "auto"
  }
});

export default useStyles;
