import React, { useState } from "react";

import { Column } from "@tanstack/react-table";
import { debounce } from "lodash";

import TextField from "@material-ui/core/TextField";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { TData } from "../../Table.types";

type Props = {
  column: Column<TData, unknown>;
};

const useStyles = makeStyles((theme) => ({
  search: {
    alignItems: "center",
    columnGap: theme.spacing(1),
    fontSize: "small",
    "&:before, &:hover:before": {
      borderBottom: "none !important"
    }
  }
}));

function SearchField(props: Props) {
  const { column } = props || {};

  const classes = useStyles();

  const columnFilterValue = column?.getFilterValue();

  const [value, setValue] = useState(columnFilterValue ?? "");

  const debouncedHandleChange = debounce((newValue) => {
    column?.setFilterValue(newValue);
  }, 500);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event?.target?.value;
    setValue(newValue);
    debouncedHandleChange(newValue);
  };

  return (
    <TextField
      id={`searchColumn-${column?.getIndex()}`}
      size="small"
      fullWidth
      placeholder="Search column..."
      InputProps={{
        className: classes.search,
        startAdornment: <SearchRoundedIcon fontSize="small" />
      }}
      value={value}
      onChange={onChange}
    />
  );
}

export default SearchField;
