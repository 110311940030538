/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CloudFile } from './cloud-file';
// May contain unused imports in some cases
// @ts-ignore
import { PipelineTestResultPerTransformMsgsInnerValueInner } from './pipeline-test-result-per-transform-msgs-inner-value-inner';

/**
 * 
 * @export
 * @interface ListFilesResult
 */
export interface ListFilesResult {
    /**
     * 
     * @type {string}
     * @memberof ListFilesResult
     */
    'status'?: ListFilesResultStatusEnum;
    /**
     * 
     * @type {Array<PipelineTestResultPerTransformMsgsInnerValueInner>}
     * @memberof ListFilesResult
     */
    'messages'?: Array<PipelineTestResultPerTransformMsgsInnerValueInner>;
    /**
     * 
     * @type {string}
     * @memberof ListFilesResult
     */
    'responseEntity'?: string;
    /**
     * 
     * @type {Array<CloudFile>}
     * @memberof ListFilesResult
     */
    'files'?: Array<CloudFile>;
}

export const ListFilesResultStatusEnum = {
    Skipped: 'SKIPPED',
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type ListFilesResultStatusEnum = typeof ListFilesResultStatusEnum[keyof typeof ListFilesResultStatusEnum];


