import { addTransformGroupWithRethrow } from "src/api/transforms";
import { RecipeTypes } from "pages/private/ProjectsModule/utils/Projects.constants";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { Entities, Recipe } from "src/types";
import { getAPIWithRethrow } from "src/utils/apiService";

interface Output {
  group: Recipe;
}

interface IProps {
  projectId?: string;
  inputDatasets: Entities;
}

const useCreateApiConnectorRecipe = (): UseMutationResult<Output, unknown, IProps, unknown> => {
  return useMutation({
    mutationFn: async ({ projectId, inputDatasets }) => {
      if (!projectId) {
        throw "Project id is absent";
      }
      const groups: Array<Recipe> = await getAPIWithRethrow(
        `/v2/dfs-run-config-groups?projectId=${projectId}`
      );
      const allRecipeNames = groups.reduce((acc: Array<string>, item) => {
        return [...acc, item.name?.toLowerCase(), item.displayName?.toLowerCase()];
      }, []);

      let updatedRecipeName = `Recipe`;
      if (allRecipeNames?.length) {
        let i = 2;
        while (allRecipeNames?.includes(updatedRecipeName?.toLowerCase())) {
          updatedRecipeName = `Recipe ${i}`;
          i = i + 1;
        }
      }

      const transformGroupPayload = {
        name: updatedRecipeName,
        displayName: updatedRecipeName,
        parents: inputDatasets?.map((entity) => ({ id: entity.id, type: "ENTITY" })),
        recipeType: RecipeTypes.ApiConnector
      };
      const createdGroup = await addTransformGroupWithRethrow(transformGroupPayload, projectId);
      const group = Array.isArray(createdGroup) ? createdGroup[0] : createdGroup;

      return { group };
    }
  });
};

export default useCreateApiConnectorRecipe;
