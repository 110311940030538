import React, { useEffect, useMemo, useState } from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
  Divider,
  Typography
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";

import styles from "./ColumnFilterMenu.module.scss";

const ColumnFilterMenu = ({
  data,
  onFormControlChange,
  onSearch,
  visibleColumns,
  size,
  disableSelectAll,
  classNameContainer,
  classNameList
}: $TSFixMe) => {
  const [filterValue, setFilterValue] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const sizeSmall = useMemo(() => size === "small", [size]);
  const checkboxSize = useMemo(() => (sizeSmall ? "small" : "large"), [sizeSmall]);
  const fontSize = useMemo(() => (sizeSmall ? "caption" : "body1"), [sizeSmall]);

  const filteredData = useMemo(() => {
    const regex = new RegExp(filterValue, "gi");
    return data?.filter((dataItem: $TSFixMe) => dataItem.match(regex)) || [];
  }, [filterValue, data]);

  useEffect(() => {
    if (filteredData?.length === visibleColumns?.length) setSelectAllChecked(true);
    else setSelectAllChecked(false);
  }, [visibleColumns, filteredData]);

  const handleFormChange = (e: $TSFixMe) => {
    const checked = e.target.checked;
    const targetValue = e.currentTarget.value;

    if (targetValue === "selectAll") setSelectAllChecked(checked);
    onFormControlChange(e);
  };

  const handleSearch = (value: $TSFixMe) => {
    onSearch(value);
    setFilterValue(value);
  };
  const inputStyle = {
    fontSize: sizeSmall ? "12px" : "14px"
  };

  return (
    <div>
      <FormControl className={`${styles.formControl} ${classNameContainer}`} component="fieldset">
        <div className={styles.header}>
          <Typography variant={fontSize} className={styles.legend} component="legend">
            Visible columns
          </Typography>
          <TextField
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                <InputAdornment style={inputStyle} variant={fontSize} position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            placeholder="Search column..."
            className={styles.search}
            id="outlined-basic"
            variant="outlined"
          />
        </div>
        <FormGroup>
          {!disableSelectAll && (
            <>
              <div className={styles.selectAllContainer}>
                <FormControlLabel
                  value="selectAll"
                  onChange={handleFormChange}
                  className={classNameList}
                  control={
                    // @ts-expect-error TS(2322) FIXME: Type '"small" | "large"' is not assignable to type... Remove this comment to see the full error message
                    <Checkbox size={checkboxSize} checked={selectAllChecked} color="primary" />
                  }
                  label={<Typography variant={fontSize}>Select All</Typography>}
                  labelPlacement="end"
                />
                <Typography color="textSecondary" variant={fontSize}>
                  {`(${visibleColumns?.length || 0} of 
                    ${filteredData?.length || 0} selected)`}
                </Typography>
              </div>
              <Divider />
            </>
          )}
          {filteredData.map((column: $TSFixMe) => {
            return (
              <div key={column}>
                <FormControlLabel
                  value={column}
                  onChange={handleFormChange}
                  className={classNameList}
                  control={
                    <Checkbox
                      // @ts-expect-error TS(2322) FIXME: Type '"small" | "large"' is not assignable to type... Remove this comment to see the full error message
                      size={checkboxSize}
                      checked={visibleColumns?.includes(column)}
                      color="primary"
                    />
                  }
                  label={<Typography variant={fontSize}>{column}</Typography>}
                  labelPlacement="end"
                />
              </div>
            );
          })}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default ColumnFilterMenu;
