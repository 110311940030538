import { useCallback, useEffect, useRef, useState } from "react";

// Packages
import { find, isEmpty } from "lodash";
import shallow from "zustand/shallow";
import { useEdgesState, useNodesState, useReactFlow } from "react-flow-renderer";

// Stores
import { useCanvasStore } from "src/store/store";

const useManageDagFlow = () => {
  const { setCenter } = useReactFlow();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [nodesStore, nodeToFocusStore] = useCanvasStore(
    (state: $TSFixMe) => [state.nodes, state.nodeToFocus],
    shallow
  );

  const timeoutMap = useRef(null);
  const [isMinimap, setIsMiniMap] = useState(true);

  // Node search & focus on it.
  useEffect(() => {
    if (!isEmpty(nodeToFocusStore)) {
      const focusNode: $TSFixMe = find(
        nodes || [],
        (node: $TSFixMe) => node?.data?.displayName === nodeToFocusStore
      );

      if (!!focusNode) {
        setCenter(focusNode?.position?.x, focusNode?.position?.y, {
          zoom: 1,
          duration: 500
        });
      }
    }
  }, [nodeToFocusStore, nodes]);

  // $FixMe, $ToBeRemoved
  const formatNodesToCanvas = (thisNodes: $TSFixMe) => {
    const nodeTypes = {
      entity: "entity",
      dfsgroup: "dfsgroup",
      chart: "chart",
      artifact: "artifact",
      model: "model"
    };
    const canvasNodeIdNodeMap = nodesStore.reduce(
      (acc: $TSFixMe, node: $TSFixMe) => ({ ...acc, [node.id]: node }),
      {}
    );
    return thisNodes
      ?.filter((node: $TSFixMe) => !!node?.data?.id)
      ?.map((node: $TSFixMe) => ({
        ...canvasNodeIdNodeMap[node.id],
        ...node.data,
        id: node.data.id,
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        type: nodeTypes[node.data.type],
        column: node.position.x / 180,
        row: node.position.y / 180,
        displayName: node.data.displayName,
        name: node.data.name,
        status: node.data.status,
        projectId: node.data.projectId
      }));
  };

  const resetNodesAndEdges = useCallback(() => {
    setNodes((prevElements: $TSFixMe) => {
      return prevElements?.map((elem: $TSFixMe) => {
        elem.style = {
          ...elem.style,
          opacity: 1
        };
        return elem;
      });
    });

    setEdges((prevElements: $TSFixMe) => {
      return prevElements?.map((elem: $TSFixMe) => {
        elem.animated = false;
        elem.selected = false;

        elem.style = {
          ...elem.style,
          stroke: "#c7c7c7",
          opacity: 1
        };

        elem.markerEnd = {
          ...elem.markerEnd,
          color: "#c7c7c7"
        };

        return elem;
      });
    });
  }, []);

  const minimap = useCallback(() => {
    if (timeoutMap.current) clearTimeout(timeoutMap.current);
    setIsMiniMap(true);
    // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'null'.
    timeoutMap.current = setTimeout(() => {
      setIsMiniMap(false);
      timeoutMap.current = null;
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number[]' is not assignable to p... Remove this comment to see the full error message
    }, [2500]);
  }, [timeoutMap.current]);

  const nodeColorSelection = (node: $TSFixMe) => {
    const { data } = node;
    const colorStatus = {
      UNBUILT: "#00000042",
      EMPTY: "#00000042",
      RUNNING: "#2196f3",
      PENDING: "#ff9800",
      SUCCESS: "#4caf50",
      BUILT: "#4caf50",
      ERROR: "#f44336"
    };
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return colorStatus[data.status];
  };

  return {
    nodes,
    setNodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    resetNodesAndEdges,
    formatNodesToCanvas,
    minimap,
    isMinimap,
    nodeColorSelection
  };
};

export default useManageDagFlow;
