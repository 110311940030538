import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  Typography,
  InputLabel,
  OutlinedInput,
  Box
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import Text from "src/components/Widget/Text";
import { useForm } from "src/utils/useForm";
import useRelaunchEnvironment from "hooks/api/environments/useRelaunchEnvironment";
import { validateNameField } from "src/utils/formFieldUtils";
import { toastWrapper } from "src/utils/toastWrapper";
import { Field, Modal, ConfirmScreen } from "src/components";
import { useEnvironmentsStore, useProjectsStore } from "src/store/store";
import {
  environmentsGetter,
  environmentsSetter,
  shouldRefreshProjectsToggler,
  projectsSetter
} from "src/store/store.selectors";
import { getEnvironmentsTypes, createProjectWithRethrow, getEnvironments } from "src/api";
import { envMetadataFind, imageToBase64 } from "./helpers/projects.helpers";
import { DEFAULT_NAME } from "src/constants/environments.constants";
import { statusValues } from "src/pages/private/Environments/components/StatusBar/StatusBar";
import EnvironmentTypeConfig from "src/pages/private/Environments/components/EnvironmentTypeConfig";
import { useStyles as useEnvStyles } from "src/pages/Projects/ProjectSettings/styling";
import styles from "src/pages/private/Environments/Environments.module.scss";
import default_image from "src/assets/images/dashaboardCards/default_project_thumbnail1.svg";
import PreviewImageSelectorNew from "./ProjectSettings/PreviewImageSelectorNew";
import { defaultImages } from "src/pages/Projects/ProjectSettings/PreviewImageModal";
import { Environment } from "../private/Environments/Environments";
import { WebPaths } from "src/routing/routes";

const initialFormValues = {
  description: "",
  name: ""
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    gap: 24,
    "& > .MuiGrid-item": {
      paddingTop: 8
    }
  },
  helperText: {
    color: "#828282",
    marginBottom: 24,
    display: "inline-block"
  },
  envTypeSpan: {
    fontSize: 12,
    color: "#838383"
  },
  inputsContainer: {
    width: "calc(50% + 30px)",
    "& div[class^='MuiFormControl-root']": {
      width: "100%"
    },
    "& div[class^='MuiSelect-root']": {
      paddingTop: 10.5,
      paddingBottom: 10.5
    },
    "& label[class*='MuiInputLabel-outlined']:not(.MuiInputLabel-shrink)": {
      transform: "translate(14px, 12px) scale(1)"
    }
  },
  submitBtn: {
    height: "36px !important"
  },
  imageSelectionGrid: {
    width: "calc(50% - 30px)"
  }
}));

type Props = {
  open: boolean;
  onClose?: () => void;
};

const CreateProject = ({ open, onClose }: Props) => {
  const classes = useStyles();
  const { envMenuItem, roundEnvStatus } = useEnvStyles();

  const navigate = useNavigate();
  const relaunch = useRelaunchEnvironment();
  const [environmentsTypes, setEnvironmentsTypes] = useState<$TSFixMe>([]);

  const { values, handleInputChange, resetForm } = useForm({ ...initialFormValues });
  const [showProjectNameError, setShowProjectNameError] = useState<[boolean, string]>([false, ""]);
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [selectedEnv, setSelectedEnv] = useState<Environment | null>(null);
  const [imageBase64, setImageBase64] = useState<string | undefined>();
  const [isCreatingProject, setIsCreatingProject] = useState(false);

  const projectList = useProjectsStore((state) => state.projectList);
  const setProjects = useProjectsStore(projectsSetter);

  const toggleShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsToggler);
  const isDirty = useProjectsStore((state) => state.isDirty);
  const toggleDirty = useProjectsStore((state) => state.toggleDirty);

  const setEnvironments = useEnvironmentsStore(environmentsSetter);
  const environments = useEnvironmentsStore(environmentsGetter);
  const isEnvironmentsLoading = useEnvironmentsStore((state) => state.isEnvironmentsLoading);
  const setIsEnvironmentLoading = useEnvironmentsStore((state) => state.setIsEnvironmentLoading);

  useEffect(() => {
    const _ = async () => {
      const environmentsTypesResponse = await getEnvironmentsTypes();
      setEnvironmentsTypes(() =>
        Array.isArray(environmentsTypesResponse) ? environmentsTypesResponse : []
      );
    };

    _();
  }, []);

  const validFields = () => {
    let isValid = true;
    const { isValid: isNameValid, error } = validateNameField({
      fieldName: (values as $TSFixMe)?.name,
      fieldNameLabel: `project name`
    });
    if (!isNameValid && error) {
      setShowProjectNameError([true, error]);
      isValid = false;
    } else if (nameExist()) {
      setShowProjectNameError([true, "This project name exists"]);
      isValid = false;
    } else {
      setShowProjectNameError([false, ""]);
    }

    return isValid;
  };

  useEffect(() => {
    showProjectNameError[0] && setShowProjectNameError([false, ""]);
  }, [open]);

  useEffect(() => {
    const fetchEnvs = async () => {
      setIsEnvironmentLoading(true);

      const response = await getEnvironments();
      setIsEnvironmentLoading(false);
      if (response) {
        const defaultEnv = response?.find(
          (env: Environment) => env?.name === DEFAULT_NAME && env?.defaultFlag
        );
        setEnvironments(response);
        defaultEnv && setSelectedEnv(defaultEnv);
      }
    };
    fetchEnvs();
  }, []);

  const nameExist = () => {
    return Boolean(
      projectList?.find(
        (project) =>
          (project as $TSFixMe).name.toLowerCase() ===
          (values as $TSFixMe).name.toLowerCase().trim()
      )
    );
  };

  const handleOnClose = () => {
    resetForm();
    onClose?.();
  };

  const handleSubmit = async (e: $TSFixMe) => {
    setIsCreatingProject(true);

    e.preventDefault();
    if (validFields()) {
      if (!imageBase64) {
        const randomImageIndex = Math.floor(Math.random() * defaultImages.length);
        imageToBase64(
          defaultImages[randomImageIndex],
          (base64String: string) => {
            handlePostImageSubmit(base64String);
          },
          543,
          1084
        );
      } else {
        handlePostImageSubmit();
      }
    } else {
      setIsCreatingProject(false);
    }
  };

  const handlePostImageSubmit = async (updatedImage?: string) => {
    if (selectedEnv) {
      const valuesToSend = {
        ...values,
        envId: selectedEnv.id,
        image: updatedImage ? updatedImage : imageBase64?.split(";base64,")?.pop()
      };

      try {
        const response = await createProjectWithRethrow(valuesToSend);

        toastWrapper({
          type: "success",
          content: "Project created successfully!"
        });

        relaunch.mutate({ envId: selectedEnv.id });

        if (response) {
          Object.keys(response)?.length > 0 && setProjects([...projectList, response]);
        }

        toggleShouldProjectsRefresh();
        response?.id ? navigate(`/projects/${response?.id}/canvas`) : navigate(WebPaths.Projects);
      } catch (error) {
        console.error("error", error);
      }

      setIsCreatingProject(false);
      onClose?.();
      toggleDirty(false);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    id?: string
  ) => {
    if (id === "name") {
      setShowProjectNameError([false, ""]);
    }

    handleInputChange(event);
    toggleDirty(true);
  };

  const handleCloseModal = () => {
    toggleDirty(false);
    setShowConfirmScreen(false);

    handleOnClose();
  };

  const handleCloseAttempt = () => {
    if (isDirty) {
      return setShowConfirmScreen(true);
    }

    handleOnClose();
  };

  const handleCancelClose = () => {
    setShowConfirmScreen(false);
  };

  const handleEnvironmentChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const env = environments.find((item: Environment) => item.id === e.target.value) || {};
    setSelectedEnv(env);
  };

  return (
    <Modal
      isNewTheme
      title="Create a new project"
      open={open}
      height={596}
      onClose={handleCloseAttempt}
      onCancel={handleCloseAttempt}
      onSubmit={handleSubmit}
      cancelButtonLabel="Cancel"
      submitButtonId="createProject"
      tooltipTitle={
        isEnvironmentsLoading
          ? "Please wait until the environment is loaded for this project"
          : undefined
      }
      submitButtonLabel="Create Project"
      submitDataTestId="submitProjectButton"
      cancelDataTestId="cancelProjectButton"
      submitButtonClassName={classes.submitBtn}
      isSubmitLoading={isCreatingProject}
      submitDisabled={
        (values as $TSFixMe)?.name?.trim() === "" ||
        isCreatingProject ||
        isEnvironmentsLoading ||
        !selectedEnv?.id
      }>
      {showConfirmScreen && isDirty && (
        <ConfirmScreen
          onCancel={handleCancelClose}
          onConfirm={handleCloseModal}
          confirmLabel="Close"
          title="Do you really want to close this window?"
          subtitleLines={["If you leave you will lose any unsaved changes."]}
        />
      )}
      <div className={classes.container}>
        <Grid
          container
          item
          style={{ width: "calc(50% + 30px)" }}
          className={classes.inputsContainer}>
          <Grid item xs={12}>
            <Field
              id="name"
              data-testid="projectName"
              label="Project Name"
              onChange={(event) => {
                handleChange(event, "name");
              }}
              error={showProjectNameError[0]}
              helperText={showProjectNameError[0] && showProjectNameError[1]}
              required
              variant="outlined"
              size="small"
            />
            <Typography variant="caption" className={classes.helperText}>
              This name will be the project identifier.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              id="description"
              label="Project description"
              data-testid="projectDescription"
              onChange={handleChange}
              multiline
              minRows={3}
              maxRows={3}
              value={(values as $TSFixMe).description}
              variant="outlined"
            />
            <Typography variant="caption" className={classes.helperText}>
              A short description is useful to add context to your project.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined">
              <InputLabel shrink htmlFor="environmentId">
                Environment Type
              </InputLabel>
              <Select
                label="Environment Type"
                required
                data-testid="projectEnvironment"
                id="environmentId"
                name="environmentId"
                defaultValue={(selectedEnv as $TSFixMe)?.id || ""}
                value={(selectedEnv as $TSFixMe)?.id || ""}
                onChange={handleEnvironmentChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                input={<OutlinedInput notched label="Environment Type" />}
                displayEmpty>
                {environments?.map((env: $TSFixMe) => {
                  const { name: type } = envMetadataFind(environmentsTypes, env) || {};
                  return (
                    <MenuItem key={env.name} value={env.id}>
                      <Box className={envMenuItem}>
                        <Grid container direction="column">
                          <Text value={env.name} />
                          <span className={classes.envTypeSpan}>
                            <EnvironmentTypeConfig
                              envType={type}
                              cores={env?.cores}
                              memInMbs={env?.memInMbs}
                              diskInGbs={env?.diskInGbs}
                            />
                          </span>
                        </Grid>
                        <div
                          className={clsx(
                            roundEnvStatus,
                            styles.envStatus,
                            styles[env?.launchStatus?.trim()?.toLowerCase()]
                          )}>
                          {statusValues[env?.launchStatus?.trim()?.toLowerCase()]?.text ||
                            env?.launchStatus ||
                            "Unknown"}
                        </div>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Typography
              variant="caption"
              className={classes.helperText}
              style={{ marginBottom: 0 }}>
              Optionally choose the environment this project will use.
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.imageSelectionGrid}>
          <PreviewImageSelectorNew
            isNewTheme
            previewType="project"
            onChange={setImageBase64}
            defaultImage={default_image}
          />
        </Grid>
      </div>
    </Modal>
  );
};

export default CreateProject;
