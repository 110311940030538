import { getAPI } from "../../../utils/apiService";
import { Scenario } from "src/types";

export enum ScenarioDeletePromptDetails {
  title = "Do you really want to delete this scenario?",
  message = "If you confirm you will delete the scenario completely",
  confirmLabel = "Delete"
}

export const getScenarioName = async (id: $TSFixMe) => {
  const getScenarios = async () => {
    try {
      const scenarios = await getAPI(`/v2/scenarios?projectId=${id}`);
      return scenarios;
    } catch ({ error }: any) {
      console.error({ error });
    }
  };
  const scenarios = await getScenarios();
  return extractScenarioName(scenarios);
};

export const extractScenarioName = (scenarios: Scenario[]) => {
  const scenarioNames = scenarios.reduce((acc: $TSFixMe, item: $TSFixMe) => {
    if (item.name.includes("Untitled Scenario")) {
      acc.push(item.name);
    }
    return acc;
  }, []);

  let name = "Untitled Scenario";
  if (scenarioNames.length) {
    let i = 2;
    while (scenarioNames.includes(name)) {
      name = `Untitled Scenario ${i}`;
      i++;
    }
  }
  return name;
};

export const parseAppliedTransform = (appliedTransform: $TSFixMe) => {
  const input_datasets: $TSFixMe = [];
  const parameters: $TSFixMe = [];
  const output_datasets: $TSFixMe = [];
  const output_charts: $TSFixMe = [];

  appliedTransform?.inputs?.forEach((input: $TSFixMe) => {
    if (input.display?.input_dataset || input.metadata?.input_dataset) {
      input_datasets.push(input);
    } else if (input.display?.output_dataset || input.metadata?.output_dataset) {
      output_datasets.push(input);
    } else if (input.display?.output_chart || input.metadata?.output_chart) {
      output_charts.push(input);
    } else {
      parameters.push(input);
    }
  });

  return { input_datasets, parameters, output_charts, output_datasets };
};

export const getVariablesDTO = ({ variables, recipeName, runConfigName }: $TSFixMe) => {
  return Object.keys(variables).map((variableKey) => ({
    groupName: recipeName,
    runConfigName,
    variableName: variableKey,
    variableValue: variables[variableKey]
  }));
};

export const checkIfDefaultScenario = (
  scenarioData: Scenario | null,
  scenarios: any[] = [],
  scenarioId?: string
) => {
  if (scenarioData && Object.keys(scenarioData)?.length > 0) {
    return (scenarioData as $TSFixMe)?.name === "DEFAULT";
  } else if (scenarios?.length > 0) {
    const scenarioSelected = scenarios?.find(
      (scenario: $TSFixMe) => (scenario as $TSFixMe)?.id === scenarioId
    );
    return (scenarioSelected as $TSFixMe)?.name === "DEFAULT";
  }
  // If there's no way to know if scenario is default, then make it readonly
  return false;
};
