/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ArtifactDto } from './artifact-dto';

/**
 * 
 * @export
 * @interface ArtifactListDto
 */
export interface ArtifactListDto {
    /**
     * 
     * @type {string}
     * @memberof ArtifactListDto
     */
    'status'?: ArtifactListDtoStatusEnum;
    /**
     * 
     * @type {Array<ArtifactDto>}
     * @memberof ArtifactListDto
     */
    'artifacts'?: Array<ArtifactDto>;
}

export const ArtifactListDtoStatusEnum = {
    Skipped: 'SKIPPED',
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type ArtifactListDtoStatusEnum = typeof ArtifactListDtoStatusEnum[keyof typeof ArtifactListDtoStatusEnum];


