import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { isEmpty } from "lodash";

import NewSegment from "./NewSegment/NewSegment";
import ViewDataSegmentsTable from "./ViewDataSegmentsTable/ViewDataSegmentsTable";
import useDeleteSegment from "hooks/api/projects/useDeleteSegment";
import useEntitySegments from "hooks/api/projects/useEntitySegments";
import { Spinner } from "../../../components";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";

export enum SegmentDeletePromptDetails {
  title = "Do you really want to delete this segment?",
  message = "If you confirm you will delete the segment completely",
  confirmLabel = "Delete"
}

const ViewDataSegments = ({
  projectId,
  isAddSegmentOpen,
  handleModalOpen,
  isReadOnly,
  setIsAddSegmentAction
}: $TSFixMe) => {
  const { entityId, scenarioId, jobRunId } = useParams<$TSFixMe>();

  const [selectedSegment, setSelectedSegment] = useState<any>(null);
  const deleteSegment = useDeleteSegment();
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [deletingSegmentId, setDeletingSegmentId] = useState("");

  const { data, isLoading, refetch } = useEntitySegments(entityId, scenarioId, jobRunId, {
    onSuccess: (data) => setIsAddSegmentAction(() => isEmpty(data))
  });

  const promptConfirmDeleteSegment = (id: $TSFixMe) => {
    setDeletingSegmentId(id);
    setShowConfirmScreen(true);
  };

  const cancelDeleteSegment = () => {
    setDeletingSegmentId("");
    setShowConfirmScreen(false);
  };

  const confirmDeleteSegment = () => {
    handleDeleteSegment();
    setDeletingSegmentId("");
    setShowConfirmScreen(false);
  };

  const handleDeleteSegment = () => {
    deleteSegment.mutate({ deletingSegmentId }, { onSuccess: () => refetch() });
  };

  const handleOnClose = (refresh: $TSFixMe) => {
    if (refresh) {
      refetch();
    }
    setSelectedSegment(null);
    handleModalOpen();
  };

  const handleEditSegment = (selectedSegment: $TSFixMe) => {
    setSelectedSegment(selectedSegment);
    handleModalOpen();
  };

  return (
    <>
      {showConfirmScreen && (
        <Modal
          open={showConfirmScreen}
          variant={ModalVariants.Delete}
          title="Delete Segment"
          content={[SegmentDeletePromptDetails.message, SegmentDeletePromptDetails.title]}
          onClose={cancelDeleteSegment}
          onSubmit={confirmDeleteSegment}
        />
      )}

      <section style={{ overflow: "auto" }}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Grid container>
            {isAddSegmentOpen && (
              <Grid item xs={12}>
                <NewSegment
                  projectId={projectId}
                  isReadOnly={isReadOnly}
                  onClose={handleOnClose}
                  entityId={entityId}
                  selectedSegment={selectedSegment}
                />
              </Grid>
            )}
            {!isAddSegmentOpen && (
              <Grid item xs={12}>
                <ViewDataSegmentsTable
                  data={data}
                  onDelete={promptConfirmDeleteSegment}
                  onEdit={handleEditSegment}
                  isReadOnly={isReadOnly}
                />
              </Grid>
            )}
          </Grid>
        )}
      </section>
    </>
  );
};

export default ViewDataSegments;
