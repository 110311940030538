/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface EnvAlertDto
 */
export interface EnvAlertDto {
    /**
     * 
     * @type {string}
     * @memberof EnvAlertDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvAlertDto
     */
    'created'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvAlertDto
     */
    'updated'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvAlertDto
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvAlertDto
     */
    'updater'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvAlertDto
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvAlertDto
     */
    'parentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvAlertDto
     */
    'parentType'?: EnvAlertDtoParentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvAlertDto
     */
    'type'?: EnvAlertDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvAlertDto
     */
    'status'?: EnvAlertDtoStatusEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EnvAlertDto
     */
    'extraInfo'?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof EnvAlertDto
     */
    'read'?: boolean;
    /**
     * 
     * @type {Array<EnvAlertDto>}
     * @memberof EnvAlertDto
     */
    'matching'?: Array<EnvAlertDto>;
    /**
     * 
     * @type {string}
     * @memberof EnvAlertDto
     */
    'message'?: string;
}

export const EnvAlertDtoParentTypeEnum = {
    Byos: 'BYOS',
    CustomEnv: 'CUSTOM_ENV',
    Notebook: 'NOTEBOOK',
    Project: 'PROJECT',
    Eda: 'EDA',
    Dataapp: 'DATAAPP',
    Recipe: 'RECIPE',
    Byor: 'BYOR',
    Job: 'JOB',
    PredictionJob: 'PREDICTION_JOB',
    DataSource: 'DATA_SOURCE'
} as const;

export type EnvAlertDtoParentTypeEnum = typeof EnvAlertDtoParentTypeEnum[keyof typeof EnvAlertDtoParentTypeEnum];
export const EnvAlertDtoTypeEnum = {
    Oom: 'OOM',
    HighCpu: 'HIGH_CPU',
    LowDisk: 'LOW_DISK',
    ProjectMarkdown: 'PROJECT_MARKDOWN',
    EdaInfo: 'EDA_INFO',
    EdaCharts: 'EDA_CHARTS',
    EdaAdditionalCharts: 'EDA_ADDITIONAL_CHARTS',
    DataappLaunch: 'DATAAPP_LAUNCH',
    RecipeRun: 'RECIPE_RUN',
    JobRun: 'JOB_RUN',
    PredictionJobRun: 'PREDICTION_JOB_RUN',
    CustomEnvLaunch: 'CUSTOM_ENV_LAUNCH',
    ProjectSnippet: 'PROJECT_SNIPPET',
    SyncDataSource: 'SYNC_DATA_SOURCE',
    CopyProject: 'COPY_PROJECT',
    QueueRecipe: 'QUEUE_RECIPE'
} as const;

export type EnvAlertDtoTypeEnum = typeof EnvAlertDtoTypeEnum[keyof typeof EnvAlertDtoTypeEnum];
export const EnvAlertDtoStatusEnum = {
    Triggered: 'TRIGGERED',
    Handled: 'HANDLED',
    Error: 'ERROR',
    Success: 'SUCCESS',
    TimedOut: 'TIMED_OUT',
    RecipeTimedOut: 'RECIPE_TIMED_OUT',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    SyncIssues: 'SYNC_ISSUES',
    SyncPaused: 'SYNC_PAUSED',
    SyncNotStarted: 'SYNC_NOT_STARTED',
    Syncing: 'SYNCING',
    InProgress: 'IN_PROGRESS'
} as const;

export type EnvAlertDtoStatusEnum = typeof EnvAlertDtoStatusEnum[keyof typeof EnvAlertDtoStatusEnum];


