import MagicGenerateIcon from "src/icons/MagicGenerateIcon";
import { Button, Grid, makeStyles, Tooltip } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  btn: {
    border: "1px solid #C7C7C7",
    backgroundColor: "#fff",
    color: "#003656",
    borderRadius: "20px",
    height: "24px",
    fontSize: "12px",
    fontWeight: 400
  },
  prompts: {
    gap: "16px",
    overflow: "auto",
    minHeight: "40px",
    justifyContent: "center",
    alignItems: "center"
  }
});
export const SuggestionPrompts = ({
  suggestions,
  onClick,
  isDisabled,
  disabledTitle
}: {
  disabledTitle: string;
  isDisabled: boolean;
  suggestions: string[];
  onClick: (suggestion: string) => void;
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" wrap="nowrap" className={classes.prompts}>
      {suggestions.map((suggestion, index) => (
        <Tooltip title={disabledTitle} key={index}>
          <div>
            <Button
              disabled={isDisabled}
              className={classes.btn}
              startIcon={<MagicGenerateIcon fill="#003656" opacity={!!isDisabled ? 0.5 : 1} />}
              onClick={() => onClick(suggestion)}>
              {suggestion}
            </Button>
          </div>
        </Tooltip>
      ))}
    </Grid>
  );
};
