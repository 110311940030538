import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import markdownToTxt from "markdown-to-txt";

import { AIGuideTextRow } from "./AIGuideTextRow";
import { DeleteQueryModal } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/DeleteQueryModal";
import { handleResponse } from "src/utils/apiService";
import AIGuideTextResponse from "./AIGuideTextResponse";
import AIGuideDatasetResponse from "./AIGuideDatasetResponse";
import AIGuideChartResponse from "./AIGuideChartResponse";
import {
  AI_GUIDE_MESSAGES_KEY,
  useDeleteAIGuideMessages,
  useRetryAIGuideChatMutation
} from "src/hooks/api";
import { isEmpty } from "lodash";

import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";
import {
  AIChatRequestDtoOutputTypeEnum,
  AIChatRequestDtoUseCaseEnum
} from "openapi/Models/aichat-request-dto";
import { MessageFeedbackRequestDto } from "openapi/Models/message-feedback-request-dto";

import AIGuideFeedback from "./AIGuideFeedback";
import { InputIdDto } from "openapi/Models/input-id-dto";
import { Spinner } from "src/components";
import AskAIHeaderActions from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponses/AskAIHeaderActions";
import { PromptSuggestions } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIPromptSuggestions/PromptSuggestions";
import { DeleteQueryErrorModal } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/DeleteQueryErrorModal";

export const STOPPED_QUERY_ID = "stopped_query";

const useStyles = makeStyles((theme) => ({
  message: {
    paddingBottom: "16px"
  },
  answer: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: 400
  },
  dataset: {
    padding: "0px 32px 0px 16px",
    width: "calc(100% - 28px)"
  },
  btn: {
    padding: "2px 8px !important"
  }
}));

const AIGuideResponse = ({
  message,
  isGenerating,
  canRetry,
  inputIds,
  showActions = false,
  targetId,
  hasMarkDown = false,
  existingOutputNames = [],
  setIsRetryInProgress,
  canDelete = true,
  hideInputs,
  descendants,
  hideFeedback = false
}: {
  message: AIChatResponseDto;
  isGenerating: boolean;
  canRetry: boolean;
  inputIds: InputIdDto[];
  showActions?: boolean;
  targetId?: string;
  hasMarkDown?: boolean;
  existingOutputNames?: string[];
  canDelete?: boolean;
  setIsRetryInProgress?: (isRetryInProgress: boolean) => void;
  hideInputs?: boolean;
  descendants?: string[];
  hideFeedback?: boolean;
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [shouldShowCode, setShouldShowCode] = useState<boolean>(false);

  const {
    askAIMessageId: messageId,
    query,
    outputType,
    threadId,
    outputEntityResponseList,
    feedback,
    useCase,
    targetInputs,
    error,
    code
  } = message;
  const deleteQueriesMutation = useDeleteAIGuideMessages();
  const retryAIGuideChatMutation = useRetryAIGuideChatMutation(threadId!);
  const outputEntityResponse = outputEntityResponseList?.[0];
  const isOutputDataset = outputType === AIChatRequestDtoOutputTypeEnum.Dataset;
  const isOutputChart = outputType === AIChatRequestDtoOutputTypeEnum.Chart;
  const isOutputModel = outputType === AIChatRequestDtoOutputTypeEnum.Model;
  const isPromptSuggestionsUseCase =
    useCase === AIChatRequestDtoUseCaseEnum.PromptSuggestionsUseCase;
  const isOutputError = !isEmpty(error);

  const [isExpanded, setIsExpanded] = useState<boolean>(
    outputType === AIChatRequestDtoOutputTypeEnum.Dataset ||
      outputType === AIChatRequestDtoOutputTypeEnum.Chart
      ? !isEmpty(outputEntityResponseList)
      : !!outputEntityResponse?.textAnswer || (message as any)?.isExpanded || false
  );

  const onDeleteQuery = (messageId: string) => {
    deleteQueriesMutation.mutate(
      {
        messageIds: [messageId, ...(descendants || [])],
        threadId: message.threadId!
      },
      {
        onSuccess: () => {
          setShowDeleteModal(false);
          handleResponse({ successMessage: "Query deleted successfully" });
        }
      }
    );
  };

  const handleOnFeedbackSuccess = ({ feedback, comment }: MessageFeedbackRequestDto) => {
    queryClient.setQueryData(
      [AI_GUIDE_MESSAGES_KEY, threadId!],
      (messages: AIChatResponseDto[] | undefined) => {
        return (messages || [])?.map((message) => {
          if (message.askAIMessageId !== messageId) {
            return message;
          }
          return {
            ...message,
            feedback,
            comment
          };
        });
      }
    );
  };

  const isDeleteInProgress = deleteQueriesMutation.isLoading;

  useEffect(() => {
    isGenerating && isExpanded && setIsExpanded(false);
  }, [isGenerating]);

  const handleRetry = () => {
    setIsRetryInProgress?.(true);
    retryAIGuideChatMutation.mutate(
      {
        messageId: messageId!,
        request: {
          inputIds,
          query: query!,
          useCase: useCase!,
          outputType: outputType!
        }
      },
      {
        onSettled: () => {
          setIsRetryInProgress?.(false);
        }
      }
    );
  };

  const commonProps = React.useMemo(
    () => ({
      message,
      isExpanded,
      setIsExpanded,
      shouldShowCode
    }),
    [message, isExpanded, setIsExpanded, shouldShowCode]
  );

  const rightContainer =
    showActions && isExpanded ? (
      <AskAIHeaderActions
        message={message}
        isOutputDataset={isOutputDataset}
        shouldShowCode={shouldShowCode}
        setShouldShowCode={setShouldShowCode}
        targetId={targetId}
        existingOutputNames={existingOutputNames}
      />
    ) : (
      <React.Fragment />
    );

  const queryInputs = (targetInputs || [])?.map((targetInput) => ({
    name: targetInput.intermediateDataset?.entityName || targetInput.entityName!
  }));
  const handleToggle = () => {
    setIsExpanded((expanded) => !expanded);
  };

  const cleanedPrompts = useMemo(
    () =>
      (message.outputEntityList || message.outputEntityResponseList)?.map((response) => {
        const prompt = markdownToTxt(response.textAnswer || "");
        const [title, description] = prompt.replace(/Prompt \d+: /, "").split(". ");
        return { title, description };
      }),
    [message]
  );

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.message}>
      {isPromptSuggestionsUseCase && !isOutputError ? (
        <PromptSuggestions
          isExpanded={isExpanded}
          setShowDeleteModal={setShowDeleteModal}
          userInput={message.query}
          handleToggle={handleToggle}
          inputNames={hideInputs ? [] : queryInputs?.map(({ name }) => name)}
          promts={cleanedPrompts || []}
          tags={["Prompt Suggestions"]}
        />
      ) : (
        <>
          <AIGuideTextRow
            userInput={query!}
            width="750px"
            canDelete={messageId !== STOPPED_QUERY_ID}
            setShowDeleteModal={setShowDeleteModal}
            maxHeight="132px"
            disableDelete={isGenerating && isOutputDataset}
            queryInputs={hideInputs ? [] : queryInputs}
            color={"#fff"}
            hoverBgColor="#fff"
            deleteTooltip={
              isGenerating && isOutputDataset ? "Please wait until existing query is complete" : ""
            }
          />
          {isOutputError && code ? (
            <AIGuideTextResponse
              {...commonProps}
              hasMarkDown={hasMarkDown}
              rightContainer={rightContainer}
              setIsExpanded={(expanded: boolean) => {
                isOutputError && code && setShouldShowCode(expanded);
                setIsExpanded(expanded);
              }}
            />
          ) : isOutputDataset && !isOutputError ? (
            <AIGuideDatasetResponse {...commonProps} rightContainer={rightContainer} />
          ) : (isOutputChart || isOutputModel) && !isOutputError ? (
            <AIGuideChartResponse {...commonProps} rightContainer={rightContainer} />
          ) : (
            <AIGuideTextResponse {...commonProps} hasMarkDown={hasMarkDown} />
          )}
        </>
      )}
      {isExpanded && (
        <Box pl="56px">
          <AIGuideFeedback
            threadId={threadId!}
            messageId={messageId!}
            feedback={feedback}
            hideButtons={hideFeedback}
            onSuccess={handleOnFeedbackSuccess}
            rightComponent={
              canRetry && (
                <Button
                  className={classes.btn}
                  color="primary"
                  variant="text"
                  size="small"
                  disabled={retryAIGuideChatMutation.isLoading}
                  onClick={handleRetry}>
                  Retry
                  {retryAIGuideChatMutation.isLoading && <Spinner size={16} padding="3px 8px" />}
                </Button>
              )
            }
          />
        </Box>
      )}

      {showDeleteModal &&
        (canDelete ? (
          <DeleteQueryModal
            userInput={query}
            handleSubmit={() => onDeleteQuery?.(messageId!)}
            onClose={() => setShowDeleteModal?.(false)}
            loading={isDeleteInProgress}
          />
        ) : (
          <DeleteQueryErrorModal onClose={() => setShowDeleteModal?.(false)} />
        ))}
    </Grid>
  );
};

export default React.memo(AIGuideResponse);
