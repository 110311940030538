import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import _ from "lodash";

import AskAIChart from "./AskAIChart";
import { AskAIOutputWrapContainer } from "./AskAIOutputWrapContainer";
import { SampleRows } from "./SampleRows";

const useStyles = makeStyles({
  chartsOutput: {
    flexWrap: "nowrap",
    gap: "8px",
    padding: "8px"
  }
});

type Props = {
  toggleExpanded: () => void;
  buttons: $TSFixMe;
  queryOutputs: { name: string }[];
  isAddedToRecipe: boolean;
  sampleRows: number | undefined;
  pipelineTestResult: {
    entityViewData: $TSFixMe[];
    entityModelData: { name: string }[] | null;
  };
  isOutputModel: boolean;
};

export const AskAIChartContainer = ({
  buttons,
  toggleExpanded,
  queryOutputs,
  isAddedToRecipe,
  sampleRows,
  pipelineTestResult,
  isOutputModel
}: Props) => {
  const classes = useStyles();
  const { entityViewData } = pipelineTestResult;

  const chartNameChartDataMap = _.keyBy(entityViewData, "name");
  const filteredQueryOutputs = isOutputModel
    ? _.filter(queryOutputs, (output: any) => !output.modelVersion)
    : queryOutputs;
  const sortedEntityViewData = filteredQueryOutputs?.map(
    (queryOutput: any) => chartNameChartDataMap[queryOutput.name]
  );
  return (
    <AskAIOutputWrapContainer
      name={
        isOutputModel
          ? _.get(_.find(queryOutputs, ["modelVersion", "default"]), "name", "")
          : _.join(_.map(queryOutputs, "name"), ", ")
      }
      buttons={buttons}
      background={isAddedToRecipe ? "#E9FFDF" : "#E7E7FF"}
      onClick={() => toggleExpanded()}>
      <Grid container direction="column" className={classes.chartsOutput}>
        {sortedEntityViewData?.map((chart: any, index: number) => (
          <AskAIChart key={index} chart={chart} />
        ))}
      </Grid>
      <SampleRows sampleRows={sampleRows} />
    </AskAIOutputWrapContainer>
  );
};
