import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getVariables } from "src/api/variables";

type PrefetchingVariablesProps = { queryClient: $TSFixMe; projectId?: string };

interface VariablesProps extends UseQueryOptions<$TSFixMe> {
  projectId: string;

  // useQuery options
  cacheTime?: number;
}

export const prefetchVariables = (props: PrefetchingVariablesProps) => {
  const { queryClient, projectId } = props;

  queryClient.prefetchQuery({
    queryKey: ["variables", projectId],
    queryFn: () => getVariables(projectId)
  });
};

export const useGetVariables = (props: VariablesProps): UseQueryResult<$TSFixMe> => {
  const {
    projectId,

    // useQuery options
    cacheTime = 0,
    ...options
  } = props;

  return useQuery({
    queryKey: ["variables", projectId],
    queryFn: () => getVariables(projectId),
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    cacheTime,
    enabled: !!projectId,
    ...options
  });
};
