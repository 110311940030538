import { OUTPUT_TYPE } from "src/pages/Projects/AddCodeRecipe/CodeRecipeContext/CodeRecipeContextProvider";
export enum EntityTypeEnum {
  ENTITY = "ENTITY",
  DFSGROUP = "DFSGROUP",
  CHART = "CHART",
  MODEL = "MODEL",
  ARTIFACT = "ARTIFACT",
  NONE = "NONE"
}

export type RecipeNode = {
  id: string;
  name: string;
  displayName: string;
  type: keyof typeof EntityTypeEnum;
};
export type Recipe = {
  id: string;
  name: string;
  displayName: string;
  projectId: string;
  runConfigs: Array<{
    id: string;
    templateId: string;
    displayName: string;
    variables: $TSFixMe;
    name: string;
  }>;
  parents: Array<RecipeNode>;
  children: Array<RecipeNode>;
  condition?: {
    expression: $TSFixMe;
  };
  timeout: number | null;
  metadata?: {
    problemType?: string;
    vertical?: string;
  };
  status: "UNBUILT" | "RUNNING" | "SUCCESS" | "ERROR";
  newAskAIFlow: boolean;
};

export type DfsTemplateInputMetadataType = {
  input_name: string;
  tooltip: string | null;
  default_value: string | null;
  is_required: boolean;
  multiple: boolean;
  input_dataset: boolean;
  input_column: boolean;
};
export type DfsTemplateInputType = {
  name: string;
  metadata: DfsTemplateInputMetadataType;
};
export type DfsTemplate = {
  id: string;
  projectId: string;
  name: string;
  display_name: string;
  inputs: Array<DfsTemplateInputType>;
  inputValues: $TSFixMe;
  description: string;
  tags: Array<string>;
  secondaryTags: null | Array<string>;
};

export type AskAIResponse = {
  code: string;
  userInput: string;
  transformId?: string;
  pipelineTestResult?: $TSFixMe;
  outputType: OUTPUT_TYPE;
  queryOutputs?: $TSFixMe;
  promptSuggestions?: string[];
};

export type DatasetCustomColumnsProps = {
  columnNames: string[];
  entityId?: string;
};
