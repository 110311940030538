import React, { useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

// Icons
import IconButton from "@material-ui/core/IconButton";
import { PlusIcon } from "src/icons/NewUX/PlusIcon";

import ScenariosTable from "./ScenariosTable";

import styles from "./Scenarios.module.scss";

import { useCanvasStore, useScenariosStore } from "../../store/store";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import Search from "src/components/custom/Search/Search";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { WebPaths } from "src/routing/routes";
import { useGetProjectCanvas, useGetScenarios } from "src/hooks/api";

const Scenarios = () => {
  const navigate = useNavigate();
  const { id } = useParams<$TSFixMe>();

  // Project context
  const { project } = useProjectContext() || {};

  const [searchValue, setSearchValue] = useState("");

  const [scenarios, setScenarios, scenariosReloadTrigger] = useScenariosStore((state) => [
    state.scenarios,
    state.setScenarios,
    state.scenariosReloadTrigger
  ]);
  const nodes = useCanvasStore((state) => state.nodes);
  const setNodes = useCanvasStore((state) => state.setNodes);
  const setEdges = useCanvasStore((state) => state.setEdges);

  const { isLoading: isScenariosLoading, refetch } = useGetScenarios({
    projectId: id!,
    shouldSetScenariosLoading: true,
    cacheTime: 5 * (60 * 1000),
    onSuccess: (response) => {
      setScenarios(response);
    }
  });

  const {
    isFetching: isFetchingCanvas,
    data: canvas,
    isFetched: isCanvasFetched
  } = useGetProjectCanvas({ projectId: id!, enabled: nodes?.length === 0 });

  useEffect(() => {
    if (isCanvasFetched && canvas) {
      setNodes(canvas.nodes);
      setEdges(canvas.edges);
    }
  }, [isCanvasFetched]);

  useEffect(() => {
    scenariosReloadTrigger && refetch();
  }, [scenariosReloadTrigger]);

  const dfsGroupNodes = useMemo(() => {
    return nodes.filter((node) => (node as $TSFixMe).type === "DFSGROUP");
  }, [nodes]);

  const handleAddScenario = () => {
    if (id) {
      navigate(generatePath(WebPaths.AddScenarios, { id }));
    }
  };

  const onSearch = ({ target: { value } }: $TSFixMe) => {
    setSearchValue(value);
  };

  const isLoading = isScenariosLoading || isFetchingCanvas;

  return (
    <>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          backNavPath: `/projects/${project?.id}/canvas`,
          component: <SubTopNavBarBreadcrumbs project={project} />
        }}
        {...(!isLoading
          ? {
              subTopNavBarRightSection: {
                component: (
                  <>
                    <Search onSearch={onSearch} placeholder="Search scenario" />
                    <IconButton color="primary" size="small" onClick={handleAddScenario}>
                      <PlusIcon width={28} height={28} />
                    </IconButton>
                  </>
                )
              }
            }
          : {})}
      />

      <div className={styles.scenarioTableContainer}>
        <ScenariosTable
          searchValue={searchValue}
          isLoading={isLoading}
          data={scenarios}
          projectId={id!}
          enableRun={dfsGroupNodes?.length > 0}
        />
      </div>
    </>
  );
};

export default Scenarios;
