import { keys, pickBy } from "lodash";

import { getLocalStorageItem } from "src/services/LocalStorage/LocalStorage.service";
import { TableSessionConfig } from "src/constants/table.constants";

type ColumnsFromStorageProps = {
  userId: string | null | undefined;
  datasetId: string | null | undefined;
};

export const getColumnsFromStorage = (props: ColumnsFromStorageProps) => {
  const { userId, datasetId } = props || {};

  if (!userId || !datasetId) {
    return;
  }

  const tablePreferences = getLocalStorageItem({
    key: TableSessionConfig.TablePreferencesSessionKey
  });

  return keys(
    pickBy(
      // @ts-ignore
      tablePreferences?.[userId]?.[datasetId]?.columnVisibility,
      (value) => value === true
    )
  );
};
