import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  columnsListContainer: {
    width: "100%",
    borderBottom: "1px solid #0000001f",
    "& .columnCheckbox": {
      padding: 0
    },
    "& .columnListItem": {
      borderTop: "0.5px solid #0000001f",
      borderBottom: "0.5px solid #0000001f",
      background: "#fff"
    },
    "& .columnListItemText": {
      margin: 0,
      marginLeft: theme.spacing(0.5)
    }
  },
  fixedHeight: {
    maxHeight: 300,
    overflow: "auto"
  }
}));

export default useStyles;
