import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import { AskAIConversationGrid } from "./AskAIConversationGrid";
import { AskAIInitialView } from "./AskAIInitialView";
import { GenerateCodeBar } from "./GenerateCodeBar/GenerateCodeBar";
import { Spinner } from "src/components";
import { AskAiA11yBackdrop } from "src/components/custom";
import { useCodeRecipeContext } from "../../CodeRecipeContext/useCodeRecipeContext";

const useStyles = makeStyles({
  modalBody: {
    paddingTop: "18px",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },

  inputsWrap: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    height: "100%"
  },
  askAiA11yContainer: {
    position: "relative",
    flexGrow: 1
  }
});

export const AskAIContainer = ({
  setInsertedCode,
  readonly
}: {
  setInsertedCode: (code: string) => void;
  readonly: boolean;
}) => {
  const classes = useStyles();

  const {
    askAiEnabled,
    responses,
    setResponses,
    isAutoGenerateInProgress,
    inputDatasets,
    setUserInput,
    isFetchingEntities,
    fetchSuggestions,
    fetchSuggestionsApiInfo: { isFetchingSuggestions, fetchingSuggestionsQueryReset }
  } = useCodeRecipeContext();

  React.useEffect(() => {
    // Collapse all responses when unmounting the component
    return () => {
      setResponses((responses: any) =>
        responses?.map((response: any) => ({ ...response, isExpanded: false }))
      );
    };
  }, []);

  return (
    <Grid container direction="column" alignItems="center" className={classes.modalBody}>
      {isFetchingEntities ? (
        <Grid className={classes.modalBody}>
          <Spinner />
        </Grid>
      ) : (
        <Grid container direction="column" className={classes.inputsWrap}>
          {!askAiEnabled ? (
            <Grid item className={classes.askAiA11yContainer}>
              <AskAiA11yBackdrop styleProps={{ backgroundColor: "transparent" }} />
            </Grid>
          ) : (
            <>
              {responses?.length === 0 && !isAutoGenerateInProgress && !inputDatasets?.length && (
                <AskAIInitialView setUserInput={setUserInput} />
              )}
              <AskAIConversationGrid
                isFetchingSuggestions={isFetchingSuggestions}
                setInsertedCode={setInsertedCode}
              />
            </>
          )}
          <GenerateCodeBar
            disabled={isFetchingSuggestions || readonly}
            isFetchingSuggestions={isFetchingSuggestions}
            onFetchPromptSuggestions={fetchSuggestions}
            onReset={fetchingSuggestionsQueryReset}
          />
        </Grid>
      )}
    </Grid>
  );
};
