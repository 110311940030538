import { useEffect, useState } from "react";

// Packages
import { ColumnDefTemplate, HeaderContext, VisibilityState } from "@tanstack/react-table";
import { fromPairs, has, isEmpty, map, mapKeys, size, slice } from "lodash";

// Utils
import {
  getLocalStorageItem,
  setLocalStorageItem
} from "src/services/LocalStorage/LocalStorage.service";
import {
  getSessionStorageItem,
  setSessionStorageItem,
  removeSessionStorageItem
} from "src/services/SessionStorage/SessionStorage.service";

// Constants
import { TableSessionConfig } from "src/constants/table.constants";
import { TableConfig } from "./TableData.constants";

type Props = {
  userId?: string | null | undefined;
  datasetId?: string | null | undefined;
  inputData: $TSFixMe;
  columns: $TSFixMe[];
  data: $TSFixMe;
  togglingColumns: $TSFixMe[];
  getColumnId: (columnVal: ColumnDefTemplate<HeaderContext<any, unknown>> | undefined) => void;
  setColumnVisibility: $TSFixMeFunction;
  getPairsOfColumnVisibility: $TSFixMeFunction;
};

type StoreUserPreferences = {
  columnVisibility?: VisibilityState;
  columnOrder?: string[];
};

const useUserPreferences = (props: Props) => {
  const {
    userId,
    datasetId,
    inputData,
    columns,
    data,
    togglingColumns,
    getColumnId,
    setColumnVisibility,
    getPairsOfColumnVisibility
  } = props || {};

  const [columnOrder, setColumnOrder] = useState([]);

  useEffect(() => {
    const userPreferencesInLocalStorage =
      getLocalStorageItem({ key: TableSessionConfig.TablePreferencesSessionKey }) || {};

    setSessionStorageItem({
      key: TableSessionConfig.TablePreferencesSessionKey,
      data: userPreferencesInLocalStorage
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(data)) {
      const userPreferencesInSessionStorage =
        getSessionStorageItem({ key: TableSessionConfig.TablePreferencesSessionKey }) || {};

      const thisUserPreferences =
        !!userId && !!datasetId ? userPreferencesInSessionStorage?.[userId]?.[datasetId] : {};

      if (!isEmpty(thisUserPreferences)) {
        setColumnVisibility(
          !isEmpty(thisUserPreferences?.columnVisibility) &&
            size(columns) === size(thisUserPreferences?.columnVisibility)
            ? mapKeys(thisUserPreferences?.columnVisibility, (_, key) => getColumnId(key))
            : getPairsOfColumnVisibility(
                slice(inputData?.columns, 0, TableConfig.DefaultColumnsCount) || []
              )
        );
        setColumnOrder(() => thisUserPreferences?.columnOrder);

        removeSessionStorageItem(TableSessionConfig.TablePreferencesSessionKey);
      } else {
        setColumnVisibility(() =>
          getPairsOfColumnVisibility(
            size(togglingColumns) === 0
              ? slice(inputData?.columns, 0, TableConfig.DefaultColumnsCount) || []
              : togglingColumns
          )
        );

        setColumnOrder(() => []);
      }
    }
  }, [data]);

  const storeUserPreferences = ({ columnVisibility, columnOrder }: StoreUserPreferences) => {
    if (!!userId && !!datasetId && !isEmpty(columns) && !isEmpty(data)) {
      const userPreferencesInLocalStorage =
        getLocalStorageItem({ key: TableSessionConfig.TablePreferencesSessionKey }) || {};

      let thisUserPreferences: $TSFixMe = userPreferencesInLocalStorage || {};

      if (!has(userPreferencesInLocalStorage, userId)) {
        thisUserPreferences[userId] = {};
      }

      if (!has(thisUserPreferences[userId], datasetId)) {
        thisUserPreferences[userId][datasetId] = {};
      }

      if (!!columnVisibility) {
        const thisColumnVisibility = fromPairs(
          map(columns, (column) => [
            column?.header,
            columnVisibility[column?.accessorKey] ?? columnVisibility[column?.header]
          ])
        );

        thisUserPreferences[userId][datasetId].columnVisibility = thisColumnVisibility;
      }

      if (!!columnOrder) {
        thisUserPreferences[userId][datasetId].columnOrder = columnOrder;
      }

      setLocalStorageItem({
        key: TableSessionConfig.TablePreferencesSessionKey,
        data: thisUserPreferences
      });
    }
  };

  useEffect(
    () => () => {
      removeSessionStorageItem(TableSessionConfig.TablePreferencesSessionKey);
    },
    []
  );

  return { columnOrder, storeUserPreferences };
};

export default useUserPreferences;
