import React, { useMemo } from "react";
import clsx from "clsx";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import styles from "./SegmentChoiceAccordion.module.scss";
import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const SegmentChoiceAccordion = ({
  entity,
  open,
  toggleOpen,
  handleSegmentVariantChange,
  handleSegmentChange
}: $TSFixMe) => {
  const segments = useMemo(() => {
    return entity.segments;
  }, [entity]);

  const searchValue = useMemo(() => {
    return entity.searchValue;
  }, [entity]);

  const formState = useMemo(() => {
    return entity.formState;
  }, [entity]);

  return (
    <div className={styles.segmentChoiceAccordion}>
      {formState?.isValid ? (
        <button className={styles.accordionOpenCloseButton} onClick={toggleOpen}>
          <span className={styles.datasetName}>{entity.displayName}</span>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </button>
      ) : (
        <Alert severity="info">No segments selected</Alert>
      )}

      <div
        className={clsx(styles.segmentVariantContainer, {
          [styles.segmentVariantContainerClosed]: !open
        })}>
        <RadioGroup
          aria-label="segment variant"
          name="segment-variant"
          value={formState?.segmentVariant}
          onChange={(e) => handleSegmentVariantChange(entity.id, e.target.value)}>
          <FormControlLabel
            value="full"
            className={styles.segmentVariantLabel}
            control={
              <Radio
                color="primary"
                style={{ width: "20px", height: "20px", boxSizing: "content-box" }}
              />
            }
            label="Full dataset segment"
          />
          {Boolean(segments?.length) && (
            <div>
              <FormControlLabel
                value="custom"
                className={styles.segmentVariantLabel}
                control={
                  <Radio
                    color="primary"
                    style={{ width: "20px", height: "20px", boxSizing: "content-box" }}
                  />
                }
                label="Custom segments"
              />
              <div className={styles.segmentCheckboxGroup}>
                <FormGroup>
                  {segments.map((segment: $TSFixMe) => {
                    return (
                      <FormControlLabel
                        key={segment.id}
                        className={clsx(styles.segmentLabel, {
                          [styles.segmentLabelSearched]:
                            searchValue &&
                            segment.name.toLowerCase().includes(searchValue.toLowerCase())
                        })}
                        control={
                          <Checkbox
                            style={{ width: "15px", height: "15px", boxSizing: "content-box" }}
                            color="primary"
                            checked={
                              formState?.segments.find(
                                (formStateSegment: $TSFixMe) => formStateSegment.id === segment.id
                              ).checked
                            }
                            onChange={(e) =>
                              handleSegmentChange(entity.id, segment.id, e.target.checked)
                            }
                            name={segment.id}
                          />
                        }
                        label={segment.name}
                      />
                    );
                  })}
                </FormGroup>
              </div>
            </div>
          )}
        </RadioGroup>
      </div>
    </div>
  );
};

export default SegmentChoiceAccordion;
