import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { SearchField } from "src/components";
import { TextFieldProps } from "@material-ui/core";

interface Props {
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  disabled?: boolean;
}

const useStyles = makeStyles({
  searchField: {
    minWidth: 195,
    backgroundColor: "white",
    borderRadius: 32,
    "& button": {
      marginRight: "2px !important",
      "& svg": {
        fontSize: 12
      }
    },
    "& input": {
      padding: "9px 12px 9px 12px",
      fontSize: 12,
      maxHeight: 24
    }
  }
});

const Search = (props: Props & TextFieldProps) => {
  const { onSearch, placeholder = "Search", ...rest } = props;

  const classes = useStyles();

  return (
    <SearchField
      size="small"
      placeholder={placeholder}
      onChange={onSearch}
      className={classes.searchField}
      InputProps={{
        style: { backgroundColor: "initial", borderRadius: "32px" }
      }}
      {...rest}
    />
  );
};

export default Search;
