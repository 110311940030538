import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

// Packages
import {
  ColumnDefResolved,
  ColumnDefTemplate,
  HeaderContext,
  VisibilityState
} from "@tanstack/react-table";
import { filter, forEach, includes, indexOf, intersectionBy, map, size, sortBy } from "lodash";
import clsx from "clsx";

// MUI
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";

// Icons
import { TableCog } from "src/icons/TableCog";

// Utils
import { TableSettingsColumnsDirection } from "../../utils/Table.constants";

// Components
import Columns from "./Columns";
import FooterActions from "./FooterActions";
import SearchColumns from "./SearchColumns";

// Contexts
import { useTableContext } from "../../context/useTableContext";

// Types
import { TData } from "../../Table.types";

// Styles
import { useSettingsIconsStyles, useSettingsStyles } from "./Settings.styles";

type TableSettingsProps = {
  maxColumnsCount?: number;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

type SettingsIconProps = {
  columns: ColumnDefResolved<TData, any>[];
  columnVisibility?: VisibilityState;
  toggleColumns: (columns: (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]) => void;
  tableSettingsProps?: TableSettingsProps;
  disabledApplyActionMessage?: string;
};

type SettingsProps = {
  maxColumnsCount?: number;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  columns: ColumnDefResolved<TData, any>[];
  columnVisibility?: VisibilityState;
  toggleColumns: (columns: (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]) => void;
  disabledApplyActionMessage?: string;
};

const Settings = (props: SettingsProps) => {
  const {
    maxColumnsCount,
    anchorEl,
    setAnchorEl,
    columns: inputColumns,
    columnVisibility,
    toggleColumns,
    disabledApplyActionMessage
  } = props || {};

  const classes = useSettingsStyles();

  const { table, storeUserPreferences } = useTableContext();

  const defaultColumns = useMemo(() => {
    const columns = sortBy(inputColumns, (column: ColumnDefResolved<TData, any>) =>
      // @ts-ignore
      table?.getState()?.columnOrder?.indexOf(column?.accessorKey)
    );

    return map(columns, (column: ColumnDefResolved<TData, any>) => ({
      ...column,
      // @ts-ignore
      isSelected: !!columnVisibility?.[column?.accessorKey]
    }));
  }, [inputColumns, table?.getState()?.columnOrder, columnVisibility]);

  const [columns, setColumns] = useState(defaultColumns);
  const [filteredColumns, setFilteredColumns] = useState(defaultColumns);

  const selectedColumns = useMemo(() => filter(columns, { isSelected: true }), [columns]);

  const onClose = () => {
    setAnchorEl(() => null);
  };

  const sort = (direction?: TableSettingsColumnsDirection) => {
    switch (direction) {
      case TableSettingsColumnsDirection.Asc:
        setColumns(() =>
          columns
            ?.slice()
            // @ts-ignore
            ?.sort((a, b) => a?.header?.toLowerCase()?.localeCompare(b?.header?.toLowerCase()))
        );

        setFilteredColumns(() =>
          filteredColumns
            ?.slice()
            // @ts-ignore
            ?.sort((a, b) => a?.header?.toLowerCase()?.localeCompare(b?.header?.toLowerCase()))
        );
        break;

      case TableSettingsColumnsDirection.Desc:
        setColumns(() =>
          columns
            ?.slice()
            // @ts-ignore
            ?.sort((a, b) => b?.header?.toLowerCase()?.localeCompare(a?.header?.toLowerCase()))
        );

        setFilteredColumns(() =>
          filteredColumns
            ?.slice()
            // @ts-ignore
            ?.sort((a, b) => b?.header?.toLowerCase()?.localeCompare(a?.header?.toLowerCase()))
        );
        break;

      default:
        const thisColumnVisibility: VisibilityState = {};
        forEach(columns, (column: ColumnDefResolved<TData, any>) => {
          // @ts-ignore
          thisColumnVisibility[column?.accessorKey] = column?.isSelected;
        });

        const thisColumns = map(inputColumns, (column: ColumnDefResolved<TData, any>) => ({
          ...column,
          // @ts-ignore
          isSelected: thisColumnVisibility?.[column?.accessorKey]
        }));

        setColumns(() => thisColumns);
        setFilteredColumns(() => intersectionBy(thisColumns, filteredColumns, "accessorKey"));
        break;
    }
  };

  const deselectAll = () => {
    const filteredColumnsAccessorKeys = map(filteredColumns, "accessorKey");

    const updatedColumns = map(columns, (column) => {
      if (includes(filteredColumnsAccessorKeys, column?.accessorKey)) {
        return {
          ...column,
          isSelected: false
        };
      }
      return column;
    });

    setColumns(() => updatedColumns);
    setFilteredColumns(() => intersectionBy(updatedColumns, filteredColumns, "accessorKey"));
  };

  const handleApply = () => {
    const columnOrder = map(
      columns,
      (column: ColumnDefResolved<TData, any>) => column?.accessorKey
    );
    // @ts-ignore
    table?.setColumnOrder(columnOrder);
    storeUserPreferences?.({ columnOrder });

    toggleColumns(
      map(
        // @ts-ignore
        filter(columns, (column: ColumnDefResolved<TData, any>) => !!column?.isSelected),
        (column: ColumnDefResolved<TData, any>) => column?.header
      )
    );

    onClose();
  };

  const onColumnsReorder = (filteredReorderedColumns: ColumnDefResolved<TData, any>[]) => {
    if (size(filteredReorderedColumns) > 0) {
      const filteredColumnNames = map(
        filteredReorderedColumns,
        (column: ColumnDefResolved<TData, any>) => column?.accessorKey
      );
      const columnNames = map(
        columns,
        (column: ColumnDefResolved<TData, any>) => column?.accessorKey
      );
      const startIndex = indexOf(columnNames, filteredColumnNames?.[0]);

      if (startIndex !== -1) {
        const reorderedColumns = filter(
          columns,
          (column: ColumnDefResolved<TData, any>) =>
            !includes(filteredColumnNames, column?.accessorKey)
        );

        // @ts-ignore
        reorderedColumns?.splice(startIndex, 0, ...filteredReorderedColumns);

        setColumns(() => reorderedColumns);
      }
    }
  };

  const handleChange = (newVal: string) => {
    const regex = new RegExp(newVal, "gi");
    setFilteredColumns(
      // @ts-ignore
      () =>
        filter(columns, (column: ColumnDefResolved<TData, any>) => column?.header?.match(regex)) ||
        []
    );
  };

  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      keepMounted
      onClose={onClose}
      PopoverClasses={{ paper: classes.container }}
      MenuListProps={{
        className: classes.list
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      getContentAnchorEl={null}>
      <li className={clsx(["borderRight", "borderLeft", "borderTop", classes.padding])}>
        <SearchColumns onValueChange={handleChange} />
      </li>
      <Divider />
      <li className={clsx(["borderRight", "borderLeft", classes.padding])}>
        <Button variant="outlined" color="primary" size="small" onClick={deselectAll}>
          Deselect All
        </Button>
      </li>
      <li className={clsx(["borderRight", "borderLeft", "bgColor"])}>
        <Columns
          maxColumnsCount={maxColumnsCount}
          columns={columns}
          setColumns={setColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          selectedColumns={selectedColumns}
          onColumnsReorder={onColumnsReorder}
        />
      </li>
      <li className={clsx(["borderRight", "borderLeft", "borderBottom"])}>
        <FooterActions
          maxColumnsCount={maxColumnsCount}
          inputColumns={inputColumns}
          columns={columns}
          selectedColumns={selectedColumns}
          sort={sort}
          onApply={handleApply}
          disabledApplyActionMessage={disabledApplyActionMessage}
        />
      </li>
    </Menu>
  );
};

const SettingsIcon = (props: SettingsIconProps) => {
  const {
    columns,
    columnVisibility,
    toggleColumns,
    tableSettingsProps,
    disabledApplyActionMessage
  } = props || {};

  const classes = useSettingsIconsStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setAnchorEl(() => event?.currentTarget);

  return (
    <>
      {Boolean(tableSettingsProps?.anchorEl || anchorEl) && (
        <Settings
          maxColumnsCount={tableSettingsProps?.maxColumnsCount}
          anchorEl={tableSettingsProps?.anchorEl || anchorEl}
          setAnchorEl={tableSettingsProps?.setAnchorEl || setAnchorEl}
          columns={columns}
          columnVisibility={columnVisibility}
          toggleColumns={toggleColumns}
          disabledApplyActionMessage={disabledApplyActionMessage}
        />
      )}

      {!tableSettingsProps && (
        <IconButton className={classes.root} onClick={onClick} color="primary">
          <TableCog />
        </IconButton>
      )}
    </>
  );
};

export default SettingsIcon;
