import React from "react";
import _ from "lodash";
import RemoveMarkdown from "remove-markdown";
import { Box, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";

import { AIGuideTextRow } from "./AIGuideTextRow";
import { TextIconV2 } from "src/icons/NewUX/TextIconV2";

import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";
import { AskAICodeEditor } from "../../common/AskAICodeEditor";
import { AskAICodeContainer } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/AskAICodeContainer";
import { ExpandLess } from "@material-ui/icons";

const useStyles = makeStyles({
  textIcon: {
    height: "32px",
    width: "32px"
  },
  collapsedText: {
    "& > *": {
      marginBottom: "0px !important"
    }
  },
  title: {
    textWrap: "wrap !important",
    wordBreak: "break-word",
    fontWeight: 400,
    color: "#515151",
    fontSize: "14px"
  },
  codeRow: {
    padding: "0px 32px 0px 12px",
    width: "inherit",
    gap: "20px",
    flexWrap: "nowrap"
  }
});

const AIGuideTextResponse = ({
  message,
  isExpanded,
  setIsExpanded,
  shouldShowCode
}: {
  message: AIChatResponseDto;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  hasMarkDown?: boolean;
  rightContainer?: React.ReactNode;
  shouldShowCode: boolean;
}) => {
  const classes = useStyles();

  const { outputEntityList, outputEntityResponseList, error, sampleRows, code } = message;
  const errorMsg = (error as any)?.explanation || (error as any)?.errMessage;
  const outputText = _.join(
    _.map(outputEntityResponseList || outputEntityList, "textAnswer"),
    "<br/>"
  );
  const userInput = errorMsg || outputText || "";

  const firstResponse =
    errorMsg || (_.first(outputEntityResponseList) || _.first(outputEntityList))?.textAnswer || "";

  const firstRowText = firstResponse?.split("\n", 1)?.[0]
    ? RemoveMarkdown(firstResponse?.split("\n", 1)?.[0], {
        stripListLeaders: true,
        listUnicodeChar: "",
        gfm: true
      })
    : "Answer";

  return (
    <Grid container direction="column" wrap="nowrap">
      {!isExpanded ? (
        <Grid className={classes.collapsedText}>
          <AIGuideTextRow
            userInput={firstRowText || "Answer"}
            color={!!errorMsg ? "#FFDBDD" : "#fff"}
            hoverBgColor={!!errorMsg ? "#FFDBDD" : "#fff"}
            textColor={!!errorMsg ? "#515151" : "#4646B5"}
            isError={!!errorMsg}
            onExpand={() => setIsExpanded(true)}
            canCopy={false}
            noPadding
            disableMarkdownPadding
            isMarkDown
            icon={
              <Grid
                container
                item
                direction="column"
                alignItems="flex-start"
                justifyContent="center"
                wrap="nowrap"
                className={classes.textIcon}>
                <TextIconV2 />
              </Grid>
            }
          />
        </Grid>
      ) : shouldShowCode && code && errorMsg ? (
        <Grid container direction="row" className={classes.codeRow}>
          <Box className={classes.textIcon}>
            <TextIconV2 />
          </Box>
          <Box width="calc(100% - 160px)">
            <AskAICodeContainer
              title={errorMsg || "Python"}
              errDetails={
                error ? ({ ...error, lineOfCode: (error as any)?.lineInCode } as any) : {}
              }
              editorValue={code}
              toggleExpanded={() => setIsExpanded(false)}
              buttons={[
                {
                  component: (
                    <IconButton size="small">
                      <ExpandLess fontSize="small" />
                    </IconButton>
                  )
                }
              ]}
              background={"#FFDBDD"}
            />
          </Box>
        </Grid>
      ) : (
        <Grid container direction="column" wrap="nowrap">
          <AIGuideTextRow
            width="calc(100% - 134px)"
            color={!!errorMsg ? "#FFDBDD" : "#fff"}
            hoverBgColor="#4646b5"
            userInput={errorMsg || userInput}
            isError={!!errorMsg}
            textColor="#003656"
            copyTitle="Copy Answer"
            canCopy={!errorMsg}
            children={
              shouldShowCode && code && errorMsg ? (
                <Grid container direction="column" wrap="nowrap">
                  <Typography className={classes.title}>{errorMsg}</Typography>
                  <AskAICodeEditor
                    noMargin
                    editorValue={code}
                    errDetails={
                      error ? ({ ...error, lineOfCode: (error as any)?.lineInCode } as any) : {}
                    }
                  />
                </Grid>
              ) : undefined
            }
            isMarkDown
            noPadding
            onCollapse={() => setIsExpanded(false)}
            icon={
              <Box className={classes.textIcon}>
                <TextIconV2 />
              </Box>
            }
            sampleRows={sampleRows}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(AIGuideTextResponse);
