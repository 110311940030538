import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { debounce } from "lodash";
import { makeStyles } from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { useTableContext } from "../../context/useTableContext";

const useStyles = makeStyles({
  search: {
    position: "absolute",
    right: "45px",
    top: "-34px"
  }
});

const SearchTable = () => {
  const { table } = useTableContext();
  const classes = useStyles();
  const [value, setValue] = useState(table?.getState()?.globalFilter ?? "");

  const debouncedHandleChange = debounce((newValue) => {
    table?.setGlobalFilter(newValue);
  }, 500);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event?.target?.value;
    setValue(newValue);
    debouncedHandleChange(newValue);
  };

  return (
    <TextField
      id="searchTable"
      className={classes.search}
      variant="outlined"
      size="small"
      placeholder="Search..."
      InputProps={{
        startAdornment: <SearchRoundedIcon fontSize="small" color="action" />,
        style: { height: "28px" }
      }}
      value={value}
      onChange={onChange}
    />
  );
};

export default SearchTable;
