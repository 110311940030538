// Packages
import { generatePath, useNavigate } from "react-router-dom";

// Stores
import { useDrawerStore } from "src/store/store";
import { sideComponentSetter } from "src/store/store.selectors";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { ChartStatuses } from "src/pages/private/ProjectsModule/utils";

// Components
import { ChartModal } from "src/components/Canvas/CanvasDrawerItems/components/Charts/ChartModal";

// Contexts
import { useDagFlowContext } from "../../DagFlow/context/useDagFlowContext";

// Types
import { NodeData } from "src/types";
import { WebPaths } from "src/routing/routes";

type Props = {
  data: NodeData;
  disabledMessage: string;
};

const useActions = (props: Props) => {
  const { data, disabledMessage } = props || {};

  const navigate = useNavigate();

  // Contexts
  const { scenario } = useDagFlowContext();

  // Stores - STARTS >>
  const setSideDrawerStore = useDrawerStore(sideComponentSetter);
  // << ENDS - Stores

  const onClick = () => {
    setSideDrawerStore({
      sideComponent: null,
      sideComponentProps: null
    });

    if (!!disabledMessage) {
      return;
    }

    if (!!data?.jobProps) {
      if (!data?.isJobCanvasPath) {
        return;
      }
    }

    const drawerData = {
      ...data,
      scenarioId: data?.scenarioId,
      isCustomScenario: !scenario?.default,
      isJobCanvas: !!data?.isJobCanvas,
      jobProps: data?.jobProps
    };

    setSideDrawerStore({
      sideComponent: ChartModal,
      sideComponentProps: { noHeader: true, data: drawerData }
    });
  };

  const onDoubleClick = () => {
    setSideDrawerStore({
      sideComponent: null,
      sideComponentProps: null
    });

    if (!!disabledMessage || data?.status !== ChartStatuses.Built) {
      return;
    }

    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    let path = generatePath(WebPaths.Charts, {
      projectId: data?.projectId,
      scenarioId: data?.scenarioId,
      chartId: data?.id
    });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobChart}`, {
        projectId: data?.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        chartId: data?.id
      });

      data?.jobProps?.jobPathsSetter(
        `/projects/${data?.projectId}/jobs/${data?.jobProps?.jobId}/job-runs/${data?.jobProps?.jobRunId}/job-canvas`
      );
    }

    !!path && navigate(path);
  };

  return { onClick, onDoubleClick };
};

export default useActions;
