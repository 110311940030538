type SetItemProps = {
  key: string;
  data: $TSFixMe;
};

type GetItemProps = {
  key: string;
  shouldJsonParse?: boolean;
};

const setLocalStorageItem = ({ key, data }: SetItemProps) => {
  try {
    const stringifiedData = JSON.stringify(data);
    localStorage.setItem(key, stringifiedData);
  } catch (error: $TSFixMe) {
    console.error(error);
  }
};

const getLocalStorageItem = ({ key, shouldJsonParse = true }: GetItemProps) => {
  try {
    const data: $TSFixMe = localStorage.getItem(key);

    if (!data) {
      return;
    }

    return shouldJsonParse ? JSON.parse(data) : data;
  } catch (error: $TSFixMe) {
    console.error(error);
  }
};

const removeLocalStorageItem = (key: string) => localStorage.removeItem(key);

// Warning!
// Clears everything from local-storage.
const clearLocalStorage = () => localStorage.clear();

export { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem, clearLocalStorage };
