import shallow from "zustand/shallow";
import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";

import SegmentChoiceAccordion from "./SegmentChoiceAccordion";
import { postAPIWithRethrow, putAPIWithRethrow } from "../../utils/apiService";
import { Spinner } from "../../components";
import { useScenariosStore } from "../../store/store";
import { toastWrapper } from "src/utils/toastWrapper";
import { UpdateSegments } from "./ConfirmModals/UpdateSegments";
import { QUERY_KEY_SCENARIOS, useGetAllEntities } from "src/hooks/api";
import { useQueryClient } from "@tanstack/react-query";

type OwnProps = {
  projectId: string;
  scenarioId: string;
  scenarioName: string | null | undefined;
  scenarioVariables: $TSFixMe[];
  scenarioSegments?: $TSFixMe[];
  onAfterSubmit: $TSFixMeFunction;
  isEditing?: boolean;
  entityGroupFormStates: $TSFixMe;
  setEntityGroupFormStates: $TSFixMe;
  sharedVariables: $TSFixMe;
};

// @ts-expect-error TS(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof AddScenariosSegments.defaultProps;

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}));

// @ts-expect-error TS(7022) FIXME: 'AddScenariosSegments' implicitly has type 'any' b... Remove this comment to see the full error message
const AddScenariosSegments = ({
  projectId,
  scenarioId,
  scenarioName,
  scenarioVariables,
  scenarioSegments,
  onAfterSubmit,
  isEditing,
  entityGroupFormStates,
  setEntityGroupFormStates,
  sharedVariables
}: Props = {}) => {
  const classes = useStyles();

  const [entities, setEntities] = useState(scenarioSegments);
  const [entityGroupsSearched, setEntityGroupsSearched] = useState([]);
  const [entityGroupsClicked, setEntityGroupsClicked] = useState([]);

  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);
  const [isSegmentsChanged, setIsSegmentsChanged] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const [isScenariosLoading, setIsScenariosLoading, scenarios, setScenarios] = useScenariosStore(
    useCallback(
      (state) => [
        state.isScenariosLoading,
        state.setIsScenariosLoading,
        state.scenarios,
        state.setScenarios
      ],
      []
    ),
    shallow
  );

  const {
    isFetching,
    data: entitiesData,
    isFetched: isEntitiesDataFetched
  } = useGetAllEntities({
    projectId: projectId!,
    enabled: !!projectId
  });

  useEffect(() => {
    if (entitiesData && isEntitiesDataFetched) {
      const filteredEntities = entitiesData?.filter(
        (entity) => entity?.entityMeta?.entityViewType !== "CHART" && entity?.segments?.length > 0
      );
      setEntities(filteredEntities);
    }
  }, [isEntitiesDataFetched, entitiesData]);

  const isScenarioNameValid = useMemo(() => {
    return Boolean(scenarioName);
  }, [scenarioName]);

  useEffect(() => {
    setEntityGroupsSearched(
      entities?.map((entity: $TSFixMe) => ({
        ...entity,
        isOpen: false,
        searchValue: ""
      }))
    );
    setEntityGroupsClicked(
      entities?.map((entity: $TSFixMe) => ({
        ...entity,
        isOpen: true,
        searchValue: ""
      }))
    );
    if (entityGroupFormStates.length === 0) {
      setEntityGroupFormStates?.(
        entities?.map((entity: $TSFixMe) => {
          const entitySegments = entity?.segments?.map((segment: $TSFixMe) => ({
            ...segment,
            checked: Boolean(scenarioSegments?.find(({ id }: $TSFixMe) => id === segment?.id))
          }));
          const isAnyChecked = entitySegments?.find((segment: $TSFixMe) => segment?.checked);
          return {
            entityId: entity?.id,
            segmentVariant: isAnyChecked ? "custom" : "full",
            segments: entitySegments,
            isValid: true
          };
        })
      );
    }
  }, [entities, entityGroupFormStates.length, scenarioSegments, setEntityGroupFormStates]);

  const handleToggleOpenSegmentAccordion = useCallback(
    (entityGroupId: $TSFixMe) => {
      // @ts-expect-error TS(2345) FIXME: Argument of type '(entityGroups: never[]) => any[]... Remove this comment to see the full error message
      setEntityGroupsClicked((entityGroups) =>
        entityGroups?.map((entityGroup) => {
          if ((entityGroup as $TSFixMe)?.id === entityGroupId) {
            return {
              // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
              ...entityGroup,
              isOpen: !(entityGroup as $TSFixMe)?.isOpen
            };
          }
          // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          return { ...entityGroup };
        })
      );
    },
    [setEntityGroupsClicked]
  );

  const handleOpenAllSegmentAccordions = useCallback(() => {
    // @ts-expect-error TS(2345) FIXME: Argument of type '(entityGroups: never[]) => any[]... Remove this comment to see the full error message
    setEntityGroupsClicked((entityGroups) =>
      // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      entityGroups?.map((entityGroup) => ({ ...entityGroup, isOpen: true }))
    );
  }, [setEntityGroupsClicked]);

  const handleCloseAllSegmentAccordions = useCallback(() => {
    // @ts-expect-error TS(2345) FIXME: Argument of type '(entityGroups: never[]) => any[]... Remove this comment to see the full error message
    setEntityGroupsClicked((entityGroups) =>
      // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      entityGroups?.map((entityGroup) => ({ ...entityGroup, isOpen: false }))
    );
  }, [setEntityGroupsClicked]);

  const handleSearchSegmentChange = useCallback(
    (searchValue: $TSFixMe) => {
      setEntityGroupsSearched(() =>
        entities?.map((entityGroup: $TSFixMe) => {
          const segmentNames = (entityGroup as $TSFixMe)?.segments?.map(
            (segment: $TSFixMe) => segment?.name
          );
          if (searchValue) {
            if (
              segmentNames?.some((segmentName: $TSFixMe) =>
                segmentName.toLowerCase().includes(searchValue.toLowerCase())
              )
            ) {
              return {
                ...entityGroup,
                isOpen: true,
                searchValue
              };
            }

            return {};
          }
          return {
            ...entityGroup,
            isOpen: false,
            searchValue
          };
        })
      );
    },
    [entities]
  );

  const entityGroups = useMemo(() => {
    return entityGroupsSearched?.map((entityGroupSearched) => {
      const entityGroupClicked = entityGroupsClicked?.find(
        (entityGroupClicked) =>
          (entityGroupClicked as $TSFixMe)?.id === (entityGroupSearched as $TSFixMe)?.id
      );

      const entityGroupFormState = entityGroupFormStates?.find(
        (formState: $TSFixMe) =>
          (formState as $TSFixMe)?.entityId === (entityGroupSearched as $TSFixMe)?.id
      );

      return {
        // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        ...entityGroupSearched,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        isOpen: entityGroupClicked?.isOpen || (entityGroupSearched as $TSFixMe)?.isOpen,
        formState: entityGroupFormState
      };
    });
  }, [entityGroupsClicked, entityGroupsSearched, entityGroupFormStates]);

  const handleSegmentVariantChange = useCallback(
    (entityId: $TSFixMe, segmentVariantValue: $TSFixMe) => {
      isEditing && setIsSegmentsChanged(() => true);

      // @ts-expect-error TS(2345) FIXME: Argument of type '(formStates: never[]) => any[]' ... Remove this comment to see the full error message
      setEntityGroupFormStates?.((formStates) => {
        return formStates?.map((formState: $TSFixMe) => {
          if ((formState as $TSFixMe)?.entityId === entityId) {
            const isSegmentVariantValueCustom = segmentVariantValue === "custom";
            return {
              ...formState,
              segmentVariant: segmentVariantValue,
              segments: (formState as $TSFixMe)?.segments?.map((segment: $TSFixMe) => ({
                ...segment,
                checked: isSegmentVariantValueCustom
              })),
              isValid: true
            };
          }
          return {
            ...formState
          };
        });
      });
    },
    [setEntityGroupFormStates]
  );

  const handleSegmentChange = useCallback(
    (entityId: $TSFixMe, segmentId: $TSFixMe, segmentChecked: $TSFixMe) => {
      isEditing && setIsSegmentsChanged(() => true);

      // @ts-expect-error TS(2345) FIXME: Argument of type '(formStates: never[]) => any[]' ... Remove this comment to see the full error message
      setEntityGroupFormStates?.((formStates) => {
        const formStatesToBeReturned = formStates?.map((formState: $TSFixMe) => {
          if ((formState as $TSFixMe)?.segmentVariant === "full") {
            if ((formState as $TSFixMe)?.entityId === entityId) {
              return {
                ...formState,
                segmentVariant: "custom",
                segments: (formState as $TSFixMe)?.segments?.map((segment: $TSFixMe) => {
                  if (segment?.id === segmentId) {
                    return {
                      ...segment,
                      checked: segmentChecked
                    };
                  }
                  return {
                    ...segment
                  };
                }),
                isValid: !(
                  (formState as $TSFixMe)?.segmentVariant === "custom" &&
                  (formState as $TSFixMe)?.segments?.every((segment: $TSFixMe) => !segment?.checked)
                )
              };
            }
            return {
              ...formState
            };
          }
          if ((formState as $TSFixMe)?.segmentVariant === "custom") {
            if ((formState as $TSFixMe)?.entityId === entityId) {
              return {
                ...formState,
                segments: (formState as $TSFixMe)?.segments?.map((segment: $TSFixMe) => {
                  if (segment?.id === segmentId) {
                    return {
                      ...segment,
                      checked: segmentChecked
                    };
                  }
                  return {
                    ...segment
                  };
                }),
                isValid: !(
                  (formState as $TSFixMe)?.segmentVariant === "custom" &&
                  (formState as $TSFixMe)?.segments.every((segment: $TSFixMe) => !segment?.checked)
                )
              };
            }
            return {
              ...formState
            };
          }
          return {
            ...formState
          };
        });
        return formStatesToBeReturned?.map((formState: $TSFixMe) => ({
          ...formState,
          isValid: !(
            formState?.segmentVariant === "custom" &&
            formState?.segments?.every((segment: $TSFixMe) => !segment?.checked)
          )
        }));
      });
    },
    [setEntityGroupFormStates]
  );

  const queryClient = useQueryClient();

  const onSubmit = useCallback(async () => {
    setIsSaving(() => true);

    const url = "/v2/scenarios";
    const parsedSharedVariables = sharedVariables?.reduce((acc: $TSFixMe, variable: $TSFixMe) => {
      acc[variable.key] = variable?.value;
      return acc;
    }, {});
    const requestInformation = async (segmentIds: $TSFixMe) =>
      isEditing
        ? await putAPIWithRethrow(url, {
            name: scenarioName,
            description: "",
            id: scenarioId,
            segmentIds,
            variables: scenarioVariables,
            sharedVariables: parsedSharedVariables
          })
        : // // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
          await postAPIWithRethrow(url, {
            name: scenarioName,
            description: "",
            projectId,
            segmentIds,
            variables: scenarioVariables,
            sharedVariables: parsedSharedVariables
          });
    const isFormValid =
      isScenarioNameValid &&
      entityGroupFormStates?.every((formState: $TSFixMe) => (formState as $TSFixMe)?.isValid);

    if (isFormValid) {
      const segmentIds = entityGroupFormStates
        ?.map((formState: $TSFixMe) => {
          if ((formState as $TSFixMe)?.segmentVariant === "custom") {
            return (formState as $TSFixMe)?.segments
              .filter((segment: $TSFixMe) => segment?.checked)
              ?.map((segment: $TSFixMe) => segment?.id);
          } else {
            return [];
          }
        })
        .flat();

      setIsScenariosLoading(true);
      try {
        await requestInformation(segmentIds);
        const message = isEditing
          ? "Scenario updated successfully!"
          : "Scenario created successfully!";

        toastWrapper({
          type: "success",
          content: message
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_SCENARIOS, projectId],
          refetchType: "all"
        });
      } catch (error) {
        console.error({ error });
      } finally {
        setIsSaving(() => false);
      }

      setIsScenariosLoading(false);

      onAfterSubmit?.();
    }

    setIsSaving(() => false);
  }, [
    isScenarioNameValid,
    entityGroupFormStates,
    isEditing,
    scenarioName,
    scenarioId,
    scenarioVariables,
    projectId,
    setIsScenariosLoading,
    onAfterSubmit,
    scenarios,
    setScenarios
  ]);

  const hasEntityGroups = entityGroups?.length;

  const onConfirmUpdateClose = () => {
    setShowConfirmUpdate(false);
  };

  const onConfirmUpdateSubmit = async () => {
    await onSubmit();

    setShowConfirmUpdate(false);
  };

  const isScenariosFormValid = useMemo(
    () =>
      isScenarioNameValid &&
      entityGroupFormStates?.every((formState: $TSFixMe) => (formState as $TSFixMe)?.isValid),
    [isScenarioNameValid, entityGroupFormStates]
  );

  return (
    <>
      {showConfirmUpdate && (
        <UpdateSegments
          handleClose={onConfirmUpdateClose}
          handleSubmit={onConfirmUpdateSubmit}
          isUpdating={isScenariosLoading}
        />
      )}

      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <Grid container className={classes.root}>
            <Grid item xs={4}>
              <Grid container direction="column" style={{ rowGap: 16 }}>
                <Typography variant="h6" color="textPrimary">
                  Segments List
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Find segment"
                  style={{ backgroundColor: "white", fontSize: "small" }}
                  disabled={!hasEntityGroups}
                  onChange={(e) => handleSearchSegmentChange(e?.target?.value)}
                  InputProps={{
                    startAdornment: <SearchIcon color="action" />
                  }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      disabled={!hasEntityGroups}
                      onClick={handleOpenAllSegmentAccordions}>
                      EXPAND ALL
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={!hasEntityGroups}
                      onClick={handleCloseAllSegmentAccordions}>
                      COLLAPSE ALL
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container justifyContent="flex-end">
                <Button color="primary" disabled={isSaving} onClick={onAfterSubmit}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 16 }}
                  disabled={isSaving}
                  onClick={() =>
                    isEditing && isSegmentsChanged && isScenariosFormValid
                      ? setShowConfirmUpdate(() => true)
                      : onSubmit()
                  }>
                  {isSaving ? <CircularProgress size={20} /> : "Save"}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={4}>
              {hasEntityGroups ? (
                entityGroups?.map((entityGroup) => (
                  <SegmentChoiceAccordion
                    key={entityGroup?.id}
                    entity={entityGroup}
                    open={entityGroup?.isOpen}
                    toggleOpen={() => handleToggleOpenSegmentAccordion(entityGroup?.id)}
                    handleSegmentVariantChange={handleSegmentVariantChange}
                    handleSegmentChange={handleSegmentChange}
                  />
                ))
              ) : (
                // String format for apostrophe in the content.
                <Alert severity="info">{"You don't have any segments"}</Alert>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

AddScenariosSegments.defaultProps = {
  scenarioSegments: [],
  isEditing: false
};

export default AddScenariosSegments;
