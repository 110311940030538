import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    minHeight: theme.spacing(8)
  },
  titleText: {
    fontWeight: 400
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2)
  },
  dialogueContentBackground: {
    backgroundColor: ({ background }: any) => background || "#fff"
  },
  actions: {
    minHeight: theme.spacing(8.5)
  }
}));

export default useStyles;
