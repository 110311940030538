import React, { useState } from "react";
import { Button, Tooltip } from "@material-ui/core";

import SampleSyntaxIcon from "src/icons/NewUX/SampleSyntaxIcon";
import SampleSyntaxModal from "./SampleSyntaxModal";

const SampleSyntaxButton: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Tooltip title="Refer to this Syntax for manually adding code to the AI-assisted Recipe">
        <Button color="primary" style={{ padding: "4px" }} onClick={handleClick}>
          <SampleSyntaxIcon />
        </Button>
      </Tooltip>
      <SampleSyntaxModal open={open} onClose={handleClose} />
    </>
  );
};

export default SampleSyntaxButton;
