import { Box, Grid, makeStyles } from "@material-ui/core";
import _ from "lodash";
import React, { useMemo, useState } from "react";

import { InputDatasetTable } from "./InputDataset";
import { AIGuideOutputWrap } from "./AIGuideOutputWrap";
import { useFetchChartData } from "../../Charts/useFetchChartData";

import { AIChatResponseDto, OutputEntity, OutputEntityDto } from "openapi/Models";
import { EntityIconV3 } from "src/icons/NewUX/EntityIconV3";
import { AIGuideChatBox } from "./AIGuideChatBox";
import { AskAICodeEditor } from "../../common/AskAICodeEditor";
import { Spinner } from "src/components";
import { SampleRows } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/SampleRows";

const useStyles = makeStyles({
  dataset: {
    padding: "0px 12px 0px 12px",
    width: "100%"
  },
  root: {
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "nowrap",
    padding: "0px 32px 0px 16px"
  },
  icon: {
    height: "32px",
    width: "24px !important",
    minWidth: "24px"
  },
  datasetWrap: {
    margin: "5px 12px 5px 12px",
    border: "1px solid #D1D1D1",
    overflowY: "auto",
    borderRadius: "2px"
  }
});

const AIGuideDatasetResponse = ({
  message,
  isExpanded,
  setIsExpanded,
  rightContainer,
  shouldShowCode
}: {
  message: AIChatResponseDto;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  rightContainer: React.ReactNode;
  shouldShowCode: boolean;
}) => {
  const classes = useStyles();
  const [value, handleChange] = useState<number>(0);
  const { outputEntityList, outputEntityResponseList, transformId, code, error, sampleRows } =
    message;

  const datasetList = outputEntityList || outputEntityResponseList;
  const hasResponse = outputEntityResponseList && outputEntityResponseList?.length !== 0;
  const title = hasResponse
    ? `${outputEntityResponseList?.[0]?.name}${outputEntityResponseList?.length && outputEntityResponseList?.length !== 1 ? ` (+${outputEntityResponseList?.length - 1})` : ""}`
    : `${outputEntityList?.[0]?.name}${outputEntityList?.length && outputEntityList?.length !== 1 ? ` (+${outputEntityList?.length - 1})` : ""}`;

  return (
    <Grid container className={classes.root}>
      <AIGuideChatBox
        isCollapsed={!isExpanded}
        isExpandDisabled={false}
        onToggle={() => setIsExpanded((expanded) => !expanded)}
        rightContainer={isExpanded && rightContainer}
        isAddedToRecipe={!!transformId}
        title={title}
        width={isExpanded ? "calc(100% - 160px)" : "calc(50% - 24px)"}
        icon={
          <Grid
            container
            item
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
            wrap="nowrap"
            className={classes.icon}>
            <EntityIconV3 />
          </Grid>
        }>
        {shouldShowCode && code && (
          <AskAICodeEditor
            editorValue={code}
            errDetails={error ? ({ ...error, lineOfCode: (error as any)?.lineInCode } as any) : {}}
          />
        )}
        <Grid
          container
          direction="column"
          wrap="nowrap"
          style={{ display: shouldShowCode && code ? "none" : "block" }}>
          {datasetList?.length === 1 ? (
            <Box className={classes.datasetWrap}>
              <AIGuideDataset
                outputEntity={outputEntityList?.[0]}
                outputEntityResponse={outputEntityResponseList?.[0]}
              />
            </Box>
          ) : (
            <AIGuideOutputWrap
              tabNames={_.map(datasetList || [], (entity: any) => ({ name: entity.name }))}
              value={value}
              handleChange={handleChange}>
              {(datasetList as any)?.map((entity: any, index: number) => {
                return (
                  <Box style={{ display: index === value ? "block" : "none" }} key={entity.name}>
                    <Grid className={classes.dataset}>
                      <AIGuideDataset outputEntity={entity} outputEntityResponse={entity} />
                    </Grid>
                  </Box>
                );
              })}
            </AIGuideOutputWrap>
          )}
          <SampleRows sampleRows={sampleRows} />
        </Grid>
      </AIGuideChatBox>
    </Grid>
  );
};

const AIGuideDataset = ({
  outputEntity,
  outputEntityResponse
}: {
  outputEntity: OutputEntity | undefined;
  outputEntityResponse: OutputEntityDto | undefined;
}) => {
  const dataset = outputEntity || outputEntityResponse;
  const datasetName = dataset?.name || "";
  const { chartData: fetchedChartData, isFetching } = useFetchChartData({
    name: datasetName,
    dataFilePath: outputEntity?.sampleDatasetFilePath,
    chartType: "TABLE",
    shouldFetchData: !outputEntityResponse?.data
  });

  const chartData: any = outputEntityResponse?.data || fetchedChartData;

  const entityData = useMemo(() => {
    const countObj = {
      numRows: (dataset as any)?.datasetNumRows,
      numColumns: (dataset as any)?.datasetNumCols
    };
    if (outputEntityResponse?.data) {
      return {
        ...outputEntityResponse,
        numRows: chartData?.numRows,
        numColumns: chartData?.numCols
      };
    }
    const columns = chartData?.[0] ? Object.keys(chartData?.[0]) : [];
    return chartData
      ? {
          ...countObj,
          data: {
            columns,
            rows: _.map(chartData, (data) => ({ cells: Object.values(data) }))
          }
        }
      : {};
  }, [dataset, chartData, outputEntityResponse]);

  return <>{isFetching ? <Spinner /> : <InputDatasetTable entityData={entityData as any} />}</>;
};

export default React.memo(AIGuideDatasetResponse);
