import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import _, { includes } from "lodash";

import EventBus from "./EventBus";
import { AiControllerApi } from "openapi/Apis/ai-controller-api";
import { ArtifactsControllerApi } from "openapi/Apis/artifacts-controller-api";
import { DataAppControllerApi } from "openapi/Apis/data-app-controller-api";
import { DataConversationControllerApi } from "openapi/Apis/data-conversation-controller-api";
import { DfsRunConfigGroupControllerV2Api } from "openapi/Apis/dfs-run-config-group-controller-v2-api";
import { DfsTemplateControllerV2Api } from "openapi/Apis/dfs-template-controller-v2-api";
import { EVENTBUS_EVENTS } from "src/constants/eventbus.constants";
import { EntityControllerApi } from "openapi/Apis/entity-controller-api";
import { EnvControllerApi } from "openapi/Apis/env-controller-api";
import { DeploymentPropertyControllerApi } from "openapi/Apis/deployment-property-controller-api";
import { FeatureControllerApi } from "openapi/Apis/feature-controller-api";
import { PredictControllerApi } from "openapi/Apis/predict-controller-api";
import { PredictionServiceControllerApi } from "openapi/Apis/prediction-service-controller-api";
import { ProjectControllerApi } from "openapi/Apis/project-controller-api";
import { ProjectControllerV2Api } from "openapi/Apis/project-controller-v2-api";
import { ProjectRunControllerApi } from "openapi/Apis/project-run-controller-api";
import { ScenarioControllerApi } from "openapi/Apis/scenario-controller-api";
import { SecurityControllerApi } from "openapi/Apis/security-controller-api";
import { SignedUrlControllerApi } from "openapi/Apis/signed-url-controller-api";
import { SsoSecurityControllerApi } from "openapi/Apis/sso-security-controller-api";
import { DataSourceControllerApi } from "openapi/Apis/data-source-controller-api";

import { toastWrapper } from "./toastWrapper";

const BASE_URL = `${window.location.origin}/api`;

const config = {
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true
};

interface IError {
  msg: string;
}

const WATCH_NOTIFICATION_METHODS = ["POST", "PUT", "PATCH"];
const NO_RECORD_FOUND_METHODS = ["GET"];

class AxiosClient {
  token: string | null = null;
  client: AxiosInstance = axios.create(config);

  ArtifactsControllerApi!: ArtifactsControllerApi;
  DataAppControllerApi!: DataAppControllerApi;
  EntityControllerApi!: EntityControllerApi;
  FeatureControllerApi!: FeatureControllerApi;
  PredictControllerApi!: PredictControllerApi;
  SecurityControllerApi!: SecurityControllerApi;
  SsoSecurityControllerApi!: SsoSecurityControllerApi;
  ProjectsControllerV2Api!: ProjectControllerV2Api;
  DfsRunConfigGroupControllerV2Api!: DfsRunConfigGroupControllerV2Api;
  DfsTemplateControllerV2Api!: DfsTemplateControllerV2Api;
  SignedUrlControllerApi!: SignedUrlControllerApi;
  DataConversationControllerApi!: DataConversationControllerApi;
  ProjectControllerApi!: ProjectControllerApi;
  ProjectRunControllerApi!: ProjectRunControllerApi;
  AiControllerApi!: AiControllerApi;
  ScenarioControllerApi!: ScenarioControllerApi;
  PredictionServiceControllerApi!: PredictionServiceControllerApi;
  EnvControllerApi!: EnvControllerApi;
  DeploymentPropertyControllerApi!: DeploymentPropertyControllerApi;
  DataSourceControllerApi!: DataSourceControllerApi;

  init = (token: string): void => {
    this.token = token;
    this.client.interceptors.request.use(this.requestInterceptor, this.rejectInterceptor);
    this.client.interceptors.response.use(this.responseInterceptor, this.rejectInterceptor);

    const client = this.client;
    this.ArtifactsControllerApi = new ArtifactsControllerApi(undefined, BASE_URL, client);
    this.DataAppControllerApi = new DataAppControllerApi(undefined, BASE_URL, client);
    this.EntityControllerApi = new EntityControllerApi(undefined, BASE_URL, client);
    this.EnvControllerApi = new EnvControllerApi(undefined, BASE_URL, client);
    this.DeploymentPropertyControllerApi = new DeploymentPropertyControllerApi(
      undefined,
      BASE_URL,
      client
    );
    this.FeatureControllerApi = new FeatureControllerApi(undefined, BASE_URL, client);
    this.PredictControllerApi = new PredictControllerApi(undefined, BASE_URL, client);
    this.SecurityControllerApi = new SecurityControllerApi(undefined, BASE_URL, client);
    this.SsoSecurityControllerApi = new SsoSecurityControllerApi(undefined, BASE_URL, client);
    this.ProjectsControllerV2Api = new ProjectControllerV2Api(undefined, BASE_URL, client);
    this.ScenarioControllerApi = new ScenarioControllerApi(undefined, BASE_URL, client);
    this.DfsRunConfigGroupControllerV2Api = new DfsRunConfigGroupControllerV2Api(
      undefined,
      BASE_URL,
      client
    );
    this.DfsTemplateControllerV2Api = new DfsTemplateControllerV2Api(undefined, BASE_URL, client);
    this.SignedUrlControllerApi = new SignedUrlControllerApi(undefined, BASE_URL, client);
    this.DataConversationControllerApi = new DataConversationControllerApi(
      undefined,
      BASE_URL,
      client
    );
    this.ProjectControllerApi = new ProjectControllerApi(undefined, BASE_URL, client);
    this.ProjectRunControllerApi = new ProjectRunControllerApi(undefined, BASE_URL, client);
    this.AiControllerApi = new AiControllerApi(undefined, BASE_URL, client);
    this.PredictionServiceControllerApi = new PredictionServiceControllerApi(
      undefined,
      BASE_URL,
      client
    );
    this.DataSourceControllerApi = new DataSourceControllerApi(undefined, BASE_URL, client);
  };

  logout = () => {
    this.token = null;
  };

  responseInterceptor = (
    response: AxiosResponse<any, any>
  ): Promise<AxiosResponse<any, any>> | AxiosResponse<any, any> => {
    return response;
  };

  requestInterceptor = async (
    requestConfig: InternalAxiosRequestConfig<any>
  ): Promise<InternalAxiosRequestConfig<any>> => {
    if (_.isString(this.token)) {
      _.setWith(requestConfig, "headers.Authorization", `Bearer ${this.token}`, Object);
    }
    return requestConfig;
  };

  rejectInterceptor = ({ response, message }: AxiosError<IError>): Promise<never> => {
    if (response && response?.status > 500) {
      if (response?.data?.msg) {
        const modifiedResponse = {
          ...response,
          data: {
            ...response.data,
            msg: "Oops, something went wrong. Please try again."
          }
        };
        return Promise.reject({ response: modifiedResponse, message });
      } else {
        const modifiedMessage = "Oops, something went wrong. Please try again.";
        return Promise.reject({ response, message: modifiedMessage });
      }
    }
    return Promise.reject({ response, message });
  };

  fetchResponse = async <T>(
    apiCall: () => Promise<AxiosResponse<T>>,
    shouldDispatchEvent = true,
    shouldHideNotifications?: boolean
  ): Promise<T> => {
    try {
      const response = await apiCall();
      const method = response.config.method?.toUpperCase();
      if (method && WATCH_NOTIFICATION_METHODS.includes(method)) {
        this.watchNotifications(shouldHideNotifications);
      }

      return response.data;
    } catch (error: any) {
      const message = this.getErrorMessage(error);

      const method = (error as AxiosError<IError>).response?.config.method?.toUpperCase();

      if (axios.isCancel(error) || error?.message === "canceled") {
        console.log("Request canceled", error.message);
        throw error;
      }

      if (
        includes(
          message,
          "You do not have access to this resource. Please contact platform admin"
        ) &&
        (error as AxiosError<IError>)?.response?.status === 401
      ) {
        EventBus.publish(EVENTBUS_EVENTS.AccessDenied);
        throw error;
      }

      if (
        includes(message, "No record found for the given") &&
        shouldDispatchEvent &&
        includes(NO_RECORD_FOUND_METHODS, method)
      ) {
        EventBus.publish(EVENTBUS_EVENTS.RecordNotFound);
      } else {
        shouldDispatchEvent && this.handleErrorWithRedirectToLogs(message);
      }

      if (method && WATCH_NOTIFICATION_METHODS.includes(method)) {
        this.watchNotifications(shouldHideNotifications);
      }

      throw error;
    }
  };

  watchNotifications = (shouldHideNotifications?: boolean) => {
    // This dispatched event will be listed in TopNavBarNotifications to trigger & manage polling.
    if (!shouldHideNotifications) {
      window.dispatchEvent(new Event("notificationTimerEvent"));
    }
  };

  handleErrorWithRedirectToLogs = (
    error: string | React.ReactNode,
    action?: (event: $TSFixMe) => void,
    extraProps = {}
  ) => {
    toastWrapper({
      ...extraProps,
      type: "error",
      content: error || "Something went wrong!",
      ...(action ? { actions: [{ label: "View Log", action }] } : {})
    });
  };

  getErrorMessage = (error: any) => {
    const baseErrorMsg = error?.response?.data?.msg || error?.message;
    const errorEntries = error?.response?.data?.entries;
    let messageToReturn: string = baseErrorMsg;
    if (
      errorEntries &&
      !errorEntries?.every((entry: any) => entry?.extraInfo?.length === 0 || !entry?.extraInfo)
    ) {
      messageToReturn = errorEntries?.reduce(
        (baseError: string, errorEntry: $TSFixMe) =>
          errorEntry?.extraInfo?.length > 0
            ? `${baseErrorMsg}${errorEntry?.field ? ` - ${errorEntry.field}` : ""}\n`.concat(
                typeof errorEntry?.extraInfo === "string"
                  ? errorEntry?.extraInfo
                  : errorEntry?.extraInfo?.reduce(
                      (allEntries: string, info: $TSFixMe) =>
                        [allEntries, ...Object.keys(info)].join("\n"),
                      ""
                    ) ?? ""
              )
            : baseError,
        baseErrorMsg
      );
    }
    return messageToReturn;
  };
}

const api = new AxiosClient();

export default api;
