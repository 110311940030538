import { useMemo } from "react";

// Packages
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";

// Open API
import { RecipeRunData } from "openapi/Models/recipe-run-data";

// Hooks
import { UseGetRecipeRunsQueueQueryKeys, useRemoveRecipeFromQueue } from "src/hooks/api/recipes";

// Constants
import { RecipeRunsHelperText } from "./RecipeRunsPopover.constants";

type Props = {
  data: RecipeRunData;
};

const useRemoveRecipe = (props: Props) => {
  const { data } = props || {};

  const { projectId } = useParams();

  const queryClient = useQueryClient();

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isRemovingRecipeFromQueue,
    mutateAsync: removeRecipeFromQueueMutation,
    reset: resetRemoveRecipeFromQueueMutation
  } = useRemoveRecipeFromQueue();
  // << ENDS - Query hooks

  const recipeName = useMemo(
    () => data?.recipeDisplayName || data?.recipeName || RecipeRunsHelperText.Unknown,
    [data?.recipeDisplayName, data?.recipeName]
  );

  const removeRecipeFromQueue = async () => {
    if (!projectId || !areAllKeysPresentAndNotNil(data, ["scenarioId", "groupId"])) {
      return;
    }

    await resetRemoveRecipeFromQueueMutation();
    await removeRecipeFromQueueMutation(
      // @ts-ignore
      { projectId, scenarioId: data?.scenarioId, id: data?.groupId },
      {
        onSuccess: async () => {
          toastWrapper({
            type: ToastTypes.Success,
            content: `Recipe ${recipeName} removed from queue successfully!`
          });

          await queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue]);
        }
      }
    );
  };

  return { removeRecipeFromQueue, isRemovingRecipeFromQueue };
};

export default useRemoveRecipe;
