import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Chip, IconButton, TextField, Tooltip, makeStyles } from "@material-ui/core";
import {
  ThumbUpAltOutlined,
  ThumbDownAltOutlined,
  ThumbUpAltRounded,
  ThumbDownAltRounded
} from "@material-ui/icons";

import Button from "components/Buttons/DefaultButton";
import useLikeDislikeReaction from "hooks/api/codeRecipe/useLikeDislikeReaction";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    gap: "12px"
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "10px",

    "& button": {
      padding: 0
    }
  },
  iconButtonRoot: {
    "& svg": {
      width: 20,
      height: 20
    },

    "&:hover": {
      backgroundColor: "transparent"
    },

    "&:active": {
      backgroundColor: "transparent"
    }
  },
  feedBackSentMsg: {
    display: "flex",
    flexDirection: "column",
    fontSize: "12px"
  },
  inputRoot: {
    background: "#fff",
    paddingTop: "6.5px !important",
    paddingBottom: "6.5px !important"
  }
});

enum DISLIKED_RESPONSE_TYPES {
  InCorrectResponse = "Incorrect Response",
  Error = "Error",
  Gibberish = "Gibberish",
  Other = "Other"
}

enum REACTIONS {
  Liked = "liked",
  DisLiked = "DisLiked"
}

export interface ILikes {
  userEmail: string;
  liked: boolean;
  feedback: string;
}

interface IProps {
  historyId: string;
  likes: ILikes;
  onSuccess: ({ liked, feedback }: Pick<ILikes, "liked" | "feedback">) => void;
  rightComponent?: React.ReactNode;
}

const AskAiLikeDislike: React.FC<IProps> = (props) => {
  const { historyId, likes, onSuccess, rightComponent } = props;

  const likeDislike = useLikeDislikeReaction();
  const { root, iconButtonRoot, wrapper, feedBackSentMsg, inputRoot } = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);

  const [reaction, setReaction] = useState<REACTIONS | null>(null);
  const [disLikeResponse, setDislikeResponse] = useState<DISLIKED_RESPONSE_TYPES | null>(null);
  const [value, setValue] = React.useState("");
  const [isHistoric, setIsHistoric] = useState(false);

  useEffect(() => {
    if (likes && _.isBoolean(likes.liked)) {
      setReaction(likes.liked ? REACTIONS.Liked : REACTIONS.DisLiked);
      setIsHistoric(true);
    }
  }, [likes]);

  useEffect(() => {
    if (
      likeDislike.isSuccess ||
      reaction === REACTIONS.DisLiked ||
      disLikeResponse === DISLIKED_RESPONSE_TYPES.Other
    ) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [reaction, disLikeResponse, likeDislike.isSuccess]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleReaction = (type: REACTIONS) => {
    if (reaction !== type) {
      setReaction(type);

      setIsHistoric(false);
      likeDislike.reset();
      if (type === REACTIONS.Liked) {
        setDislikeResponse(null);
        setValue("");
        likeDislike.mutate(
          { like: true, historyId },
          {
            onError: () => setReaction(reaction),
            onSuccess: () => onSuccess({ liked: true, feedback: "" })
          }
        );
      }
    }
  };

  const handleClick = (val: DISLIKED_RESPONSE_TYPES) => {
    if (disLikeResponse !== val) {
      setDislikeResponse(val);
      setValue("");
      likeDislike.reset();
      if (val !== DISLIKED_RESPONSE_TYPES.Other) {
        likeDislike.mutate(
          { like: false, historyId, feedback: val },
          {
            onError: () => setDislikeResponse(disLikeResponse),
            onSuccess: () => {
              onSuccess({ liked: false, feedback: val });
              setValue(val);
            }
          }
        );
      }
    }
  };

  const handleSubmit = () => {
    likeDislike.mutate(
      { like: false, historyId, feedback: value },
      { onSuccess: () => onSuccess({ liked: false, feedback: value }) }
    );
  };

  return (
    <div className={wrapper} ref={ref}>
      <div className={root} style={{ marginLeft: "13px" }}>
        <Tooltip title={likeDislike.isLoading ? "" : "Good response"}>
          <span>
            <IconButton
              classes={{ root: iconButtonRoot }}
              disabled={likeDislike.isLoading}
              onClick={() => handleReaction(REACTIONS.Liked)}>
              {reaction === REACTIONS.Liked ? (
                <ThumbUpAltRounded color="primary" />
              ) : (
                <ThumbUpAltOutlined />
              )}
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={likeDislike.isLoading ? "" : "Bad response"}>
          <span>
            <IconButton
              classes={{ root: iconButtonRoot }}
              disabled={likeDislike.isLoading}
              onClick={() => handleReaction(REACTIONS.DisLiked)}>
              {reaction === REACTIONS.DisLiked ? (
                <ThumbDownAltRounded color="primary" />
              ) : (
                <ThumbDownAltOutlined />
              )}
            </IconButton>
          </span>
        </Tooltip>
        {rightComponent}
      </div>
      {reaction === REACTIONS.DisLiked && !isHistoric && (
        <div className={root}>
          {_.map(DISLIKED_RESPONSE_TYPES, (val) => (
            <Chip
              key={val}
              variant={disLikeResponse === val ? "default" : "outlined"}
              color={disLikeResponse === val ? "primary" : "default"}
              label={val}
              disabled={likeDislike.isLoading}
              onClick={() => handleClick(val)}
            />
          ))}
        </div>
      )}
      {disLikeResponse === DISLIKED_RESPONSE_TYPES.Other && !likeDislike.isSuccess && (
        <TextField
          multiline
          value={value}
          hiddenLabel
          placeholder="Please specify the issue here"
          minRows={1}
          maxRows={2}
          size="small"
          variant="outlined"
          InputProps={{
            className: inputRoot,
            endAdornment: (
              <Button
                size="small"
                variant="outlined"
                isCustomTheming={false}
                component="span"
                color="primary"
                disabled={!value || likeDislike.isLoading}
                onClick={handleSubmit}>
                Submit
              </Button>
            )
          }}
          onChange={handleChange}
        />
      )}
      {likeDislike.isSuccess && (
        <div className={feedBackSentMsg}>
          <div style={{ marginLeft: "13px" }}>{value}</div>
          <span style={{ color: "green", marginLeft: "13px" }}>Feedback sent</span>
        </div>
      )}
    </div>
  );
};

export default AskAiLikeDislike;
