import React from "react";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";

type Props = {
  isUpdating: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
};

export const UpdateSegments = ({ isUpdating, handleClose, handleSubmit }: Props) => (
  <Modal
    open={true}
    variant={ModalVariants.Delete}
    title="Update Segment"
    submitLabel="Yes, Update"
    isSubmitting={isUpdating}
    onClose={handleClose}
    onSubmit={handleSubmit}
    content={[
      "If you make any changes to the segment(s) associated with this custom scenario, it will invalidate the existing canvas connections for this scenario. Re-run this scenario for changes to take effect.",
      "Are you sure you want to update?"
    ]}
  />
);
