import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Grid, createStyles, makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";

import DeletePredictionServiceModal from "./DeletePredictionServiceModal";
import PredictionServiceDetails from "./PredictionServiceDetails";
import PredictionServiceInfo from "./PredictionServiceInfo";
import SampleCurlCommand from "./SampleCurlCommand";
import TestPredictionService from "./TestPredictionService";
import UniqueEndPoint from "./UniqueEndPoint";
import useAddPredictionService from "../hooks/useAddPredictionService";
import useDeletePredictionService from "../hooks/useDeletePredictionService";
import useDownloadSignedUrl from "../hooks/useDownloadSignedUrl";
import useEditPredictionService from "../hooks/useUpdatePredictionService";
import useRecipeById from "../hooks/useRecipeById";
import { DEFAULT_PRE_POST_PROCESS_CODE, PSFields } from "../utils/PredictionService.constants";
import { Environment } from "src/pages/private/Environments/Environments";
import { IModelReturn } from "../hooks/usePredictionServiceByModel";
import { Spinner } from "src/components";
import { getFormattedPythonCode, getUniqueEndpoint } from "../utils/PredictionService.helpers";
import { useForm } from "src/utils/useForm";
import PredictionLogsTable from "./PredictionLogsTable";

interface IProps {
  modelName?: string;
  data?: IModelReturn;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1
    },
    flex: {
      display: "flex",
      gap: "10px"
    },
    copyBtn: () => ({
      "& span": {
        color: "black"
      }
    })
  })
);

const initialValues = { [PSFields.prePostProcess.id]: DEFAULT_PRE_POST_PROCESS_CODE };

const PredictionService: React.FC<IProps> = (props) => {
  const { modelName, data } = props;
  const id = data?.predictionServiceDetails?.id;
  const recipeId = data?.producer?.recipeId;
  const { data: recipeData, isLoading: isRecipeLoading } = useRecipeById(recipeId);
  const isTimeSeriesRecipe =
    !!recipeData?.variables?.predictPeriod && !!recipeData?.variables?.predictFrequency;
  const {
    data: pythonData,
    isLoading,
    isFetching
  } = useDownloadSignedUrl(data?.predictionServiceDetails?.name, {
    onSuccess: (pythonCode) => {
      setValues({
        ...values,
        [PSFields.prePostProcess.id]: pythonCode
      });
    },
    refetchOnMount: true,
    enabled: !!id
  });

  const [open, setOpen] = useState(false);
  const [environment, setEnvironment] = useState<Environment | null>(null);

  const addPredictionService = useAddPredictionService();
  const editPredictionService = useEditPredictionService();
  const deletePredictionService = useDeletePredictionService();

  const classes = useStyles();
  const { state } = useLocation();
  const { values, handleInputChange, setValues, resetForm } = useForm(initialValues);

  const setFormValues = (predictionServiceDetails: IModelReturn["predictionServiceDetails"]) => {
    const { name, description, envId, concurrency, logCalls, timeoutInMins } =
      predictionServiceDetails;

    setValues({
      ...values,
      [PSFields.name.id]: name,
      [PSFields.description.id]: description ?? "",
      [PSFields.environment.id]: envId,
      [PSFields.endpoint.id]: getUniqueEndpoint(name),
      [PSFields.concurrency.id]: concurrency,
      [PSFields.logCalls.id]: logCalls,
      [PSFields.timeoutInMins.id]: timeoutInMins
    });
  };

  useEffect(() => {
    const predictionServiceDetails = state?.predictionServiceDetails;
    if (predictionServiceDetails) {
      setFormValues(predictionServiceDetails);
    }
  }, []);

  useEffect(() => {
    const predictionServiceDetails = data?.predictionServiceDetails;
    if (predictionServiceDetails) {
      setFormValues(predictionServiceDetails);
    } else {
      setValues(initialValues);
    }
  }, [data?.predictionServiceDetails]);

  const disabled = useMemo(() => {
    const name = _.get(values, PSFields.name.id);
    const envId = _.get(values, PSFields.environment.id);

    if (!data?.predictionServiceDetails?.id) {
      return _.isEmpty(envId) || _.isEmpty(name);
    }

    const oldValues = {
      [PSFields.name.id]: data?.predictionServiceDetails?.name,
      [PSFields.description.id]: data?.predictionServiceDetails?.description ?? "",
      [PSFields.environment.id]: data?.predictionServiceDetails?.envId,
      [PSFields.logCalls.id]: data?.predictionServiceDetails?.logCalls,
      [PSFields.timeoutInMins.id]: data?.predictionServiceDetails?.timeoutInMins,
      [PSFields.concurrency.id]: data?.predictionServiceDetails?.concurrency,
      [PSFields.prePostProcess.id]: pythonData
    };

    const newValues = _.pick(values, [
      PSFields.name.id,
      PSFields.description.id,
      PSFields.environment.id,
      PSFields.timeoutInMins.id,
      PSFields.concurrency.id,
      PSFields.logCalls.id,
      PSFields.prePostProcess.id
    ]);

    return _.isEqual(oldValues, newValues);
  }, [values, data?.predictionServiceDetails, pythonData]);

  const handleDelete = () => {
    setOpen(true);
  };

  const handleSave = () => {
    const prePostProccess = _.get(values, PSFields.prePostProcess.id);
    const name = _.get(values, PSFields.name.id);
    const envId = _.get(values, PSFields.environment.id);
    const description = _.get(values, PSFields.description.id);
    const logCalls = _.get(values, PSFields.logCalls.id);
    const timeoutInMins = _.get(values, PSFields.timeoutInMins.id);
    const concurrency = _.get(values, PSFields.concurrency.id);

    const payload = {
      modelName,
      name,
      displayName: name,
      envId,
      description,
      logCalls,
      timeoutInMins,
      concurrency
    };

    if (id) {
      const formattedPythonCode = getFormattedPythonCode(
        prePostProccess,
        name,
        data?.predictionServiceDetails?.name
      );
      const pythonFile = new File([formattedPythonCode], `${name}.py`, { type: "text/plain" });

      editPredictionService.mutate({
        id,
        ...payload,
        file: pythonFile
      });
    } else {
      const formattedPythonCode = getFormattedPythonCode(prePostProccess, name);
      const pythonFile = new File([formattedPythonCode], `${name}.py`, { type: "text/plain" });

      addPredictionService.mutate({ ...payload, file: pythonFile, shouldDispatchEvent: true });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (id) {
      deletePredictionService.mutate(
        { id },
        {
          onSuccess: () => {
            resetForm();
            handleCancel();
          }
        }
      );
    }
  };

  if ((id && (isLoading || isFetching)) || isRecipeLoading) {
    return <Spinner />;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12} sm={12}>
              <PredictionServiceDetails
                values={values}
                id={id}
                loading={addPredictionService.isLoading || editPredictionService.isLoading}
                isDeleteInProgress={deletePredictionService.isLoading}
                disabled={disabled}
                isTimeSeriesRecipe={isTimeSeriesRecipe}
                onInputChange={handleInputChange}
                onDelete={handleDelete}
                onEnvChange={setEnvironment}
                onValuesChange={setValues}
                onSave={handleSave}
              />
            </Grid>
            {id && _.get(values, PSFields.endpoint.id) && (
              <Grid item xs={12} sm={12}>
                <UniqueEndPoint
                  name={_.get(values, PSFields.endpoint.id, "")}
                  onInputChange={handleInputChange}
                />
              </Grid>
            )}
            {id && (
              <Grid item xs={12} sm={12}>
                <SampleCurlCommand name={_.get(values, PSFields.name.id, "")} />
              </Grid>
            )}
          </Grid>
        </Grid>
        {!props.open && (
          <Grid item xs={12} sm={6}>
            {id ? (
              <TestPredictionService
                isTimeSeriesRecipe={isTimeSeriesRecipe}
                file={_.get(values, ["files", 0])}
                name={_.get(values, PSFields.name.id)}
                environment={environment}
                onInputChange={handleInputChange}
              />
            ) : (
              <PredictionServiceInfo />
            )}
          </Grid>
        )}
        {props.open && (
          <Grid item xs={12} sm={6}>
            {id && <PredictionLogsTable id={id} />}
          </Grid>
        )}
      </Grid>
      {id && (
        <DeletePredictionServiceModal
          id={id}
          open={open}
          loading={deletePredictionService.isLoading}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default PredictionService;
