import { useQuery } from "@tanstack/react-query";

import { getEnvironmentsTypes } from "src/api";

export const useGetEnvironmentTypes = () => {
  return useQuery({
    queryKey: ["environmentTypes"],
    queryFn: async () => {
      return await getEnvironmentsTypes();
    },
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : [])
  });
};
