import React, { useEffect, useState, useMemo } from "react";

import { Grid, MenuItem, Paper, Typography, makeStyles } from "@material-ui/core";

import Text from "src/components/Widget/Text";
import { ConfirmScreen } from "../../../../../components";
import { useEnvironmentsStore } from "../../../../../store/store";
import { useForm } from "../../../../../utils/useForm";
import { Field, FieldName, CodeEditor } from "../../../../../components";
import {
  EnvironmentType,
  EnvironmentsTypes
} from "../../../../../constants/environments.constants";
import { createEnvironment } from "../../../../../api";
import styles from "../../Environments.module.scss";
import { Environment } from "../../Environments";
import { validateNameField } from "src/utils/formFieldUtils";
import { toastWrapper } from "src/utils/toastWrapper";
import EnvironmentTypeConfig from "../EnvironmentTypeConfig";
import {
  getCoreSet,
  getDiskSpaceSet,
  getMemorySet
} from "src/pages/Projects/helpers/projects.helpers";
import { Modal } from "src/components/custom";

const useStyles = makeStyles(() => ({
  dropdown: {
    maxHeight: 200
  }
}));

type Props = {
  open?: boolean;
  isEnvironmentsTypesFetching?: boolean;
  environmentsTypes?: $TSFixMe[];
  refetch: () => void;
  onClose?: Function;
  watchEnvironment?: $TSFixMeFunction;
};

const initialFormValues = {
  name: "",
  description: "",
  envType: "",
  code: "",
  cores: "",
  memory: "",
  diskSpace: ""
};

const errors = {
  nameExist: "The environment name already exists"
};

export const CreateEnvironment = ({
  open,
  isEnvironmentsTypesFetching,
  environmentsTypes,
  refetch,
  onClose = () => null,
  watchEnvironment
}: Props) => {
  const classes = useStyles();

  const [showConfirmScreen, setShowConfirmScreen] = useState(false);

  const { values, setValues, handleInputChange, resetForm } = useForm(initialFormValues);
  const [showNameError, setShowNameError] = useState<[boolean, string]>([false, ""]);
  const [typeOption, setTypeOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const environmentList = useEnvironmentsStore((state) => state.environmentList);

  const isDirty = useEnvironmentsStore((state) => state.isDirty);
  const toggleDirty = useEnvironmentsStore((state) => state.toggleDirty);

  const handleCloseModal = () => {
    toggleDirty(false);
    setShowConfirmScreen(false);
    onClose();
  };

  const handleCancelClose = () => {
    setShowConfirmScreen(false);
  };

  const validFields = () => {
    let isValid = true;
    const { isValid: isNameValid, error } = validateNameField({
      fieldName: (values as $TSFixMe)?.name,
      fieldNameLabel: `environment name`
    });
    if (!isNameValid && error) {
      setShowNameError([true, error]);
      isValid = false;
    } else if (nameExist()) {
      setShowNameError([true, errors.nameExist]);
      isValid = false;
    } else {
      setShowNameError([false, ""]);
    }
    return isValid;
  };

  useEffect(() => {
    showNameError[0] && setShowNameError([false, ""]);
  }, [open]); // eslint-disable-line

  const nameExist = () => {
    return environmentList.some((env) => {
      return (env as Environment).name.toLowerCase() === (values as $TSFixMe).name.toLowerCase();
    });
  };

  const handleOnClose = () => {
    if ((environmentsTypes || [])?.length === 0) {
      return;
    }

    resetForm();
    onClose();
  };

  const handleSubmit = async () => {
    try {
      let valuesToSend: $TSFixMe;
      const typeSelected = (environmentsTypes || [])?.find(
        (type: EnvironmentType) => type.name === (values as $TSFixMe).envType
      );
      if (validFields()) {
        valuesToSend = {
          name: (values as $TSFixMe).name.trim(),
          description: (values as $TSFixMe).description?.trim(),
          requirements: (values as $TSFixMe).code,
          envType: (values as $TSFixMe).envType
        };

        if ((values as $TSFixMe)?.envType === EnvironmentsTypes.Custom) {
          valuesToSend.cores = (values as $TSFixMe)?.cores;
          valuesToSend.memInMbs = (values as $TSFixMe)?.memory * 1024;
          valuesToSend.diskInGbs = (values as $TSFixMe)?.diskSpace;
        } else {
          valuesToSend.cores = typeSelected?.cores;
          valuesToSend.memInMbs = typeSelected?.memInMbs;
          valuesToSend.diskInGbs = typeSelected?.diskInGbs;
        }

        setIsLoading(true);

        const createdEnvironment = await createEnvironment(valuesToSend);

        toastWrapper({
          type: "success",
          content: "Environment created successfully!"
        });

        await refetch();

        toggleDirty(false);
        onClose();

        //@ts-ignore
        watchEnvironment(createdEnvironment);
      }
    } catch (error) {
      console.error({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event: $TSFixMe, id?: string) => {
    if (id === "name") {
      setShowNameError([false, ""]);
    }

    if (id === "envType" && event?.target?.value !== EnvironmentsTypes.Custom) {
      setTimeout(() => {
        setValues(() => ({
          ...values,
          envType: event?.target?.value,
          cores: "",
          memory: "",
          diskSpace: ""
        }));
      }, 10);
    }

    handleInputChange(event);
    toggleDirty(true);
  };

  const handleChangeCodeEditor = (name: string, value: $TSFixMe) => {
    const newValue = { target: { name, value } };
    handleInputChange(newValue);
    toggleDirty(true);
  };

  const handleCloseAttempt = () => {
    if (isDirty) {
      return setShowConfirmScreen(true);
    }

    handleOnClose();
  };

  const isSubmitDisabled = useMemo(() => {
    let isDisabled = false;

    isDisabled = isDisabled || !!isEnvironmentsTypesFetching;
    isDisabled = isDisabled || !(values as $TSFixMe)?.name?.trim();
    isDisabled = isDisabled || !typeOption;

    if ((values as $TSFixMe)?.envType === EnvironmentsTypes.Custom) {
      isDisabled = isDisabled || !(values as $TSFixMe)?.cores;
      isDisabled = isDisabled || !(values as $TSFixMe)?.memory;
      isDisabled = isDisabled || !(values as $TSFixMe)?.diskSpace;
    }

    return isDisabled;
  }, [isEnvironmentsTypesFetching, values, typeOption]);

  return (
    <>
      {showConfirmScreen && isDirty && (
        <ConfirmScreen
          onCancel={handleCancelClose}
          onConfirm={handleCloseModal}
          confirmLabel="Close"
          title="Do you really want to close this window?"
          subtitleLines={["If you leave you will lose any unsaved changes."]}
        />
      )}

      <Modal
        open={true}
        size="md"
        title="Create a new environment"
        onClose={handleCloseAttempt}
        onSubmit={handleSubmit}
        submitLabel="Create environment"
        isSubmitting={isLoading}
        isSubmitDisabled={isSubmitDisabled}>
        <Grid container>
          <Grid container item xs={6} className={styles.formSection}>
            <Grid item xs={12}>
              <Field
                id="name"
                label="Environment Name"
                value={(values as $TSFixMe).name}
                onChange={(event: $TSFixMe) => {
                  handleChange(event, "name");
                }}
                error={showNameError[0]}
                helperText={showNameError[0] && showNameError[1]}
                required
                variant="outlined"
              />
              <Typography variant="caption" style={{ color: "#828282", display: "inline-block" }}>
                The name shown in the list of environments.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                id="description"
                label="Environment Description"
                onChange={(event: $TSFixMe) => {
                  handleChange(event);
                }}
                multiline
                minRows={4}
                maxRows={4}
                value={(values as $TSFixMe).description}
                variant="outlined"
              />
              <Typography variant="caption" style={{ color: "#828282", display: "inline-block" }}>
                Provide additional information about this environment.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                id="envType"
                required
                select
                label="Environment Type"
                onChange={(event: $TSFixMe) => {
                  handleChange(event, "envType");
                }}
                minRows={4}
                maxRows={4}
                value={(values as $TSFixMe).envType}
                variant="outlined"
                SelectProps={{ MenuProps: { classes: { paper: `${classes.dropdown} ` } } }}>
                {(environmentsTypes || [])?.map((typeOption: $TSFixMe) => {
                  return (
                    <MenuItem
                      key={typeOption.name}
                      value={typeOption.name}
                      onClick={() => setTypeOption(typeOption)}>
                      <Text value={typeOption.name} />
                    </MenuItem>
                  );
                })}
              </Field>
              {(values as $TSFixMe).envType !== EnvironmentsTypes.Custom && typeOption ? (
                <Typography variant="caption" className={styles.envTypeSpan}>
                  <EnvironmentTypeConfig
                    cores={(typeOption as EnvironmentType).cores}
                    memInMbs={(typeOption as EnvironmentType).memInMbs}
                    diskInGbs={(typeOption as EnvironmentType).diskInGbs}
                  />
                </Typography>
              ) : null}
            </Grid>
            {(values as $TSFixMe).envType === EnvironmentsTypes.Custom && (
              <>
                <Grid container justifyContent="space-between">
                  <Grid item style={{ width: "47.5%" }}>
                    <Field
                      id="cores"
                      required
                      select
                      label="Cores"
                      onChange={(event: $TSFixMe) => {
                        handleChange(event);
                      }}
                      minRows={4}
                      maxRows={4}
                      value={(values as $TSFixMe).cores}
                      variant="outlined"
                      SelectProps={{ MenuProps: { classes: { paper: `${classes.dropdown} ` } } }}>
                      {getCoreSet().map((eachCore: $TSFixMe) => {
                        return (
                          <MenuItem key={`core_${eachCore}`} value={eachCore}>
                            {eachCore}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                  <Grid item style={{ width: "47.5%" }}>
                    <Field
                      id="memory"
                      required
                      select
                      label="Memory"
                      onChange={(event: $TSFixMe) => {
                        handleChange(event);
                      }}
                      minRows={4}
                      maxRows={4}
                      value={(values as $TSFixMe).memory}
                      variant="outlined"
                      SelectProps={{ MenuProps: { classes: { paper: `${classes.dropdown} ` } } }}>
                      {getMemorySet().map((eachMemory: $TSFixMe) => {
                        return (
                          <MenuItem key={`memory_${eachMemory}`} value={eachMemory}>
                            {`${eachMemory} GB`}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    id="diskSpace"
                    required
                    select
                    label="Disk Space"
                    onChange={(event: $TSFixMe) => {
                      handleChange(event);
                    }}
                    minRows={4}
                    maxRows={4}
                    value={(values as $TSFixMe).diskSpace}
                    variant="outlined"
                    SelectProps={{ MenuProps: { classes: { paper: `${classes.dropdown} ` } } }}>
                    {getDiskSpaceSet().map((eachDiskSpace: $TSFixMe) => {
                      return (
                        <MenuItem key={`diskSpace_${eachDiskSpace}`} value={eachDiskSpace}>
                          {`${eachDiskSpace} GB`}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container item xs={6} style={{ maxHeight: 280, gap: 18, paddingLeft: 20 }}>
            <FieldName name="Packages" />
            <Paper
              elevation={0}
              style={{
                border: "2px solid black",
                margin: 0,
                width: "100%",
                height: "100%"
              }}>
              <CodeEditor
                name="code"
                height="100%"
                value={(values as $TSFixMe).code}
                //@ts-expect-error
                width="100%"
                scriptType="SYSTEM"
                onChange={handleChangeCodeEditor}
                hideTestCode
              />
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
