import React, { useMemo, useState } from "react";
import Add from "@material-ui/icons/Add";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { Alert } from "@material-ui/lab";
import {
  Grid,
  ListItem,
  MenuItem,
  Select,
  FormControl,
  IconButton,
  Button,
  Typography,
  TextField,
  makeStyles
} from "@material-ui/core";
import { size, isEmpty, includes, toLower, filter } from "lodash";

import SelectSearch from "components/Inputs/SelectSearch";
import styles from "./NewSegment.module.scss";
import { OverflowTooltip } from "src/components";
import { operatorsByDatatype } from "./CriteriaBuilder";

interface IProps {
  isReadOnly?: boolean;
  condition: $TSFixMe;
  conditionIndex: number;
  ruleIndex: number;
  entityFeatures: $TSFixMe;
  error: $TSFixMe;
  rule: $TSFixMe;
  onConditionField: $TSFixMeFunction;
  onDeleteCondition: $TSFixMeFunction;
  onAddCondition: $TSFixMeFunction;
}

const MAX_FIELD_LENGTH = 255;
const MAX_LIMIT_ERROR_MESSAGE = `Maximum allowed characters: ${MAX_FIELD_LENGTH}. Your input exceeds this limit.`;

const useStyles = makeStyles((theme) => ({
  criteriaRuleFieldsContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    columnGap: theme.spacing(2),
    backgroundColor: "#e8f4fd",
    "&:first-child": {
      paddingTop: theme.spacing(1)
    },
    "&:last-child": {
      paddingBottom: theme.spacing(1)
    },
    "& [class^=MuiInputBase-root]": {
      backgroundColor: theme.palette.common.white
    }
  },
  alertContainer: {
    flexGrow: 1,
    padding: "2px 8px"
  }
}));

const CriteriaRule: React.FC<IProps> = (props) => {
  const {
    isReadOnly,
    condition,
    conditionIndex,
    ruleIndex,
    error,
    rule,
    entityFeatures,
    onConditionField,
    onDeleteCondition,
    onAddCondition
  } = props;

  const classes = useStyles();

  const [value, setValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const filtered = useMemo(() => {
    return filter(entityFeatures, ({ name }) => includes(toLower(name), toLower(value)));
  }, [value, entityFeatures]);

  const handleConditionField = (
    ruleIndex: $TSFixMe,
    conditionIndex: $TSFixMe,
    field: $TSFixMe,
    newValue: $TSFixMe
  ) => {
    onConditionField(ruleIndex, conditionIndex, field, newValue);

    if (field !== "value") {
      return;
    }

    if (size(newValue) > 255) {
      if (!errorMessage) {
        setErrorMessage(MAX_LIMIT_ERROR_MESSAGE);
      }
    } else {
      if (errorMessage) {
        setErrorMessage("");
      }
    }
  };

  return (
    <ListItem
      className={styles.listItem}
      style={{ flexDirection: "column", paddingLeft: ruleIndex * 25 + 50 }}>
      <span
        className={styles.line}
        style={{
          top: conditionIndex === 0 ? 0 : -50,
          left: (ruleIndex + 1) * 24,
          height: conditionIndex === 0 ? 45 : 80
        }}
      />
      <Grid container className={classes.criteriaRuleFieldsContainer} alignItems="center">
        <Grid item xs={3}>
          <FormControl size="small" fullWidth variant="outlined">
            <Select
              id={`condition-field-${ruleIndex}-${conditionIndex}`}
              fullWidth
              disabled={isReadOnly}
              onChange={({ target: { value } }) =>
                handleConditionField(ruleIndex, conditionIndex, "field", value)
              }
              MenuProps={{
                PaperProps: { style: { maxWidth: 340, maxHeight: 300 } },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                autoFocus: false
              }}
              labelId="search-select-label"
              defaultValue={condition?.field?.name || "Field"}
              error={error && condition?.field?.isEmpty}
              // @ts-ignore
              renderValue={(selected) =>
                selected === "Field" ? (
                  <span className={styles.placeholder}>{selected}</span>
                ) : (
                  selected
                )
              }
              onClose={() => setValue("")}>
              <SelectSearch value={value} onChange={setValue} />
              {filtered?.map((item: $TSFixMe) => (
                <MenuItem key={item.id} value={item.name}>
                  <OverflowTooltip value={item.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl size="small" fullWidth variant="outlined">
            <Select
              id={`condition-operator-${ruleIndex}-${conditionIndex}`}
              fullWidth
              disabled={isReadOnly}
              onChange={({ target: { value } }) =>
                handleConditionField(ruleIndex, conditionIndex, "operator", value)
              }
              defaultValue={
                condition?.operator ||
                // // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                operatorsByDatatype[condition?.value?.dataType]?.[0]
              }
              value={condition?.operator}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}>
              <MenuItem value="Operator" disabled>
                Operator
              </MenuItem>
              {/* // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
              {operatorsByDatatype[condition?.value?.dataType || "ANY"]?.map(
                (item: $TSFixMe, index: $TSFixMe) => (
                  <MenuItem key={index} value={item.replace(/\s/g, "")}>
                    {item}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        {["IsNull", "IsNotNull"].includes(condition.operator) ? null : (
          <Grid item xs={2}>
            <TextField
              id={`condition-value-${ruleIndex}-${conditionIndex}`}
              name="value"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              label="Value"
              placeholder="Value"
              required
              disabled={isReadOnly}
              defaultValue={condition?.value?.value}
              inputProps={{
                min: 1
              }}
              error={(!isEmpty(error) && condition?.value?.value === "") || !isEmpty(errorMessage)}
              onChange={({ target: { value } }) =>
                handleConditionField(ruleIndex, conditionIndex, "value", value)
              }
            />
          </Grid>
        )}
        {!isReadOnly && (
          <Grid item>
            <IconButton
              onClick={() => onDeleteCondition(ruleIndex, conditionIndex)}
              disabled={rule.groupElements.length === 1}>
              <DeleteOutline color="primary" />
            </IconButton>
          </Grid>
        )}
        {!!errorMessage && (
          <Alert className={classes.alertContainer} severity="error">
            {errorMessage}
          </Alert>
        )}
      </Grid>
      {conditionIndex === rule.groupElements.length - 1 && (
        <Grid container style={{ marginTop: 16 }}>
          <Grid id={`condition-${ruleIndex}-add`} item xs={12} className={styles.listButton}>
            <Button disabled={isReadOnly} onClick={(e: $TSFixMe) => onAddCondition(e, ruleIndex)}>
              <Typography variant="overline">
                <Add /> ADD RULE
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}
    </ListItem>
  );
};

export default CriteriaRule;
