/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorMessage } from '../Models';
// @ts-ignore
import { FeatureDto } from '../Models';
// @ts-ignore
import { FeatureSchemaDto } from '../Models';
/**
 * FeatureControllerApi - axios parameter creator
 * @export
 */
export const FeatureControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [entityId] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {string} [projectRunEntityName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFeatures: async (entityId?: string, scenarioId?: string, projectRunEntryId?: string, projectRunEntityName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }

            if (projectRunEntryId !== undefined) {
                localVarQueryParameter['projectRunEntryId'] = projectRunEntryId;
            }

            if (projectRunEntityName !== undefined) {
                localVarQueryParameter['projectRunEntityName'] = projectRunEntityName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureSchema: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/features/schema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureControllerApi - functional programming interface
 * @export
 */
export const FeatureControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [entityId] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {string} [projectRunEntityName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFeatures(entityId?: string, scenarioId?: string, projectRunEntryId?: string, projectRunEntityName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFeatures(entityId, scenarioId, projectRunEntryId, projectRunEntityName, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FeatureControllerApi.findFeatures']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureSchema(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureSchemaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureSchema(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FeatureControllerApi.getFeatureSchema']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FeatureControllerApi - factory interface
 * @export
 */
export const FeatureControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [entityId] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {string} [projectRunEntityName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFeatures(entityId?: string, scenarioId?: string, projectRunEntryId?: string, projectRunEntityName?: string, options?: any): AxiosPromise<Array<FeatureDto>> {
            return localVarFp.findFeatures(entityId, scenarioId, projectRunEntryId, projectRunEntityName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureSchema(options?: any): AxiosPromise<FeatureSchemaDto> {
            return localVarFp.getFeatureSchema(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureControllerApi - interface
 * @export
 * @interface FeatureControllerApi
 */
export interface FeatureControllerApiInterface {
    /**
     * 
     * @param {string} [entityId] 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunEntryId] 
     * @param {string} [projectRunEntityName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApiInterface
     */
    findFeatures(entityId?: string, scenarioId?: string, projectRunEntryId?: string, projectRunEntityName?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<FeatureDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApiInterface
     */
    getFeatureSchema(options?: RawAxiosRequestConfig): AxiosPromise<FeatureSchemaDto>;

}

/**
 * FeatureControllerApi - object-oriented interface
 * @export
 * @class FeatureControllerApi
 * @extends {BaseAPI}
 */
export class FeatureControllerApi extends BaseAPI implements FeatureControllerApiInterface {
    /**
     * 
     * @param {string} [entityId] 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunEntryId] 
     * @param {string} [projectRunEntityName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApi
     */
    public findFeatures(entityId?: string, scenarioId?: string, projectRunEntryId?: string, projectRunEntityName?: string, options?: RawAxiosRequestConfig) {
        return FeatureControllerApiFp(this.configuration).findFeatures(entityId, scenarioId, projectRunEntryId, projectRunEntityName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApi
     */
    public getFeatureSchema(options?: RawAxiosRequestConfig) {
        return FeatureControllerApiFp(this.configuration).getFeatureSchema(options).then((request) => request(this.axios, this.basePath));
    }
}

