import React from "react";

import NewThemeWrapper from "src/styles/NewThemeWrapper";

import SubTopNavBar from "./SubTopNavBar";

// $FixMe: Scope to be refactored.
// Using this wrapper only to apply NewThemeWrapper. It will be removed once new-theme is applied at platform-level.
const SubTopNavBarWrapper = ({ ...rest }) => {
  return (
    <NewThemeWrapper>
      <SubTopNavBar {...rest} />
    </NewThemeWrapper>
  );
};

export default SubTopNavBarWrapper;
