import React, { PropsWithChildren } from "react";

import AppLoadingScreen from "./components/Screens/AppLoadingScreen";
import useCheckTokenValidation from "./hooks/api/auth/useCheckTokenValidation";
import { AuthSessionConfig } from "./constants/common.constants";
import { getLocalStorageItem } from "./services/LocalStorage/LocalStorage.service";
import { useAuthSetAuthResponse } from "./hooks/useAuthSetAuthResponse";
import { useQuery } from "./hooks";

const AuthProvider: React.FC<PropsWithChildren<{}>> = (props) => {
  const queryParameters = useQuery();
  const hasInviteTenantRequestParams = queryParameters.get("invitation_details");
  const { setAuthResponse } = useAuthSetAuthResponse();

  const { isLoading } = useCheckTokenValidation({
    onSuccess: (data) => {
      if (data) {
        setAuthResponse({
          data,
          rememberMe: !!getLocalStorageItem({
            key: AuthSessionConfig.TokenSessionKey,
            shouldJsonParse: false
          })
        });
      }
    },
    enabled: !hasInviteTenantRequestParams
  });

  if (isLoading && !hasInviteTenantRequestParams) {
    return <AppLoadingScreen requestPending />;
  }

  return <>{props.children}</>;
};

export default AuthProvider;
