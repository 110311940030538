/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ChartInput } from './chart-input';
// May contain unused imports in some cases
// @ts-ignore
import { DataFileInput } from './data-file-input';
// May contain unused imports in some cases
// @ts-ignore
import { ExternalDataSourceInput } from './external-data-source-input';
// May contain unused imports in some cases
// @ts-ignore
import { ModelInput } from './model-input';

/**
 * 
 * @export
 * @interface TargetInput
 */
export interface TargetInput {
    /**
     * 
     * @type {ExternalDataSourceInput}
     * @memberof TargetInput
     */
    'externalDataSourceInput'?: ExternalDataSourceInput;
    /**
     * 
     * @type {DataFileInput}
     * @memberof TargetInput
     */
    'dataFileInput'?: DataFileInput;
    /**
     * 
     * @type {ChartInput}
     * @memberof TargetInput
     */
    'chartInput'?: ChartInput;
    /**
     * 
     * @type {ModelInput}
     * @memberof TargetInput
     */
    'modelInput'?: ModelInput;
    /**
     * 
     * @type {string}
     * @memberof TargetInput
     */
    'sourceInputId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TargetInput
     */
    'inputId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TargetInput
     */
    'inputName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TargetInput
     */
    'inputType'?: TargetInputInputTypeEnum;
}

export const TargetInputInputTypeEnum = {
    ExternalDatasource: 'EXTERNAL_DATASOURCE',
    DataFile: 'DATA_FILE',
    Chart: 'CHART',
    Model: 'MODEL'
} as const;

export type TargetInputInputTypeEnum = typeof TargetInputInputTypeEnum[keyof typeof TargetInputInputTypeEnum];


