import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  drawerContent: {
    width: 600,
    position: "relative",
    overflow: "hidden",
    height: "100%"
  },
  settingsContainer: {
    padding: "24px",
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100% - 70px)"
  },
  inputsContainer: {
    "& textarea": {
      height: "100% !important",
      overflow: "auto !important"
    },
    "& div[class^='MuiFormControl-root']": {
      width: "100%"
    }
  },
  topInputs: {
    gap: "24px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    "& > div:last-child": {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      width: "100%"
    }
  },
  additionalInputs: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "100%",
    margin: "24px 0px"
  },
  variablesInputs: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& > p": {
      marginBottom: 24
    }
  },
  inputTitle: {
    color: "#aaaaaa"
  },
  envTypeSpan: {
    fontSize: "12px",
    color: "#838383"
  },
  envMenuItem: {
    display: "flex",
    justifyContent: "space-between",
    gridGap: "16px",
    width: "100%",
    alignItems: "center",
    "& .MuiGrid-root": {
      rowGap: 5
    }
  },
  roundEnvStatus: {
    minWidth: "auto !important",
    width: "105px",
    borderRadius: "14px !important",
    padding: "0px 14px !important",
    height: "28px !important",
    justifyContent: "center !important"
  },
  actionsButtonContainer: {
    background: "#f5f5f5",
    padding: "20px",
    position: "absolute",
    width: "100%",
    bottom: 0,
    display: "flex",
    justifyContent: "flex-end",
    "& button:first-child": {
      background: "#ffffff",
      color: "#008fe4",
      border: "1px solid #008fe4"
    },
    "& button:last-child svg": {
      marginRight: 0
    }
  },
  nameInput: {
    "& p": {
      margin: "4px 0px 8px 0px"
    }
  },
  descriptionInput: {
    height: "100%",
    "& > div": {
      height: "100%"
    }
  },
  descriptionMargin: {
    marginTop: 12
  },
  variableMargin: {
    marginBottom: 24
  },
  variableButton: {
    height: 40
  }
});
