import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  footerContainer: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    justifyContent: "flex-end",
    width: "100%",
    columnGap: 5,
    background: "#f5f5f5",
    padding: "10px 20px",
    zIndex: 1,
    backgroundColor: "#d9d9d9"
  }
});

export default useStyles;
