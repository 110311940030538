import React, { useState } from "react";
import { Box, Menu, MenuItem, Typography } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { useStyles as useSubtopBarStyles } from "src/components/SubtopBar/styling";
import { BarButton } from "src/components/SubtopBar/BarButton";
import { Delete } from "src/icons/Delete";

type Props = {
  onDelete: () => void;
};

export const ProjectSettingsDrawerTitle = ({ onDelete }: Props) => {
  const { moreOptionsButton, barMenu } = useSubtopBarStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    onDelete();
    setIsDeleting(false);
  };

  const handleMenuClick = (e?: $TSFixMe) => {
    anchorEl ? setAnchorEl(null) : setAnchorEl(e?.currentTarget);
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h6">Project Settings</Typography>
      <div>
        <button
          id="project-settings-options-button"
          onClick={handleMenuClick}
          className={moreOptionsButton}>
          <MoreVert color="primary" />
        </button>
        <Menu
          id="project-settings-options-menu"
          className={barMenu}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          getContentAnchorEl={undefined}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClick}>
          <MenuItem id="delete-project-button" onClick={handleDelete} disabled={isDeleting}>
            <BarButton icon={<Delete />} label="Delete Project" loading={isDeleting} />
          </MenuItem>
        </Menu>
      </div>
    </Box>
  );
};
