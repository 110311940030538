import React from "react";

// MUI
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import { CollapseAllIcon, ExpandAllIcon } from "src/icons/NewUX";

import useExpandCollapse from "./DagFlow/hooks/useExpandCollapse";

const useStyles = makeStyles(() => ({
  button: {
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    padding: "6px 8px",
    opacity: ({ disabled }: { disabled: boolean }) => (disabled ? 0.5 : 1)
  }
}));

type Props = {
  setNodes: (nodes: any[]) => void;
  setEdges: (edges: any[]) => void;
};

const ExpandAllCollapseAllAction = (props: Props) => {
  const { setNodes, setEdges } = props;

  const { isAllNodesExpanded, isAllNodesCollapsed, expandAllNodes, collapseAllNodes } =
    useExpandCollapse({
      setNodes,
      setEdges
    });

  const disabled = isAllNodesCollapsed && isAllNodesExpanded;
  const classes = useStyles({ disabled });

  const handleClick = () => {
    if (isAllNodesCollapsed) {
      expandAllNodes();
    } else {
      collapseAllNodes();
    }
  };

  return (
    <Tooltip
      title={
        disabled
          ? "No nodes available in the DAG to expand or collapse"
          : isAllNodesCollapsed
            ? "Expand all"
            : "Collapse all"
      }>
      <span>
        <Button variant="text" disabled={disabled} className={classes.button} onClick={handleClick}>
          <Typography variant="body2" color="textPrimary">
            {isAllNodesCollapsed ? (
              <ExpandAllIcon width={16} height={16} viewBox="0 0 12 12" />
            ) : (
              <CollapseAllIcon width={16} height={16} viewBox="0 0 12 12" />
            )}
          </Typography>
        </Button>
      </span>
    </Tooltip>
  );
};

export default ExpandAllCollapseAllAction;
