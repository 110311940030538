/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EntityData } from './entity-data';

/**
 * 
 * @export
 * @interface EntityMeta
 */
export interface EntityMeta {
    /**
     * 
     * @type {string}
     * @memberof EntityMeta
     */
    'entityType'?: EntityMetaEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityMeta
     */
    'entitySubType'?: EntityMetaEntitySubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityMeta
     */
    'entityOntology'?: EntityMetaEntityOntologyEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityMeta
     */
    'entityViewType'?: EntityMetaEntityViewTypeEnum;
    /**
     * 
     * @type {EntityData}
     * @memberof EntityMeta
     */
    'entityData'?: EntityData;
    /**
     * 
     * @type {boolean}
     * @memberof EntityMeta
     */
    'customSeparatorOrEncoding'?: boolean;
}

export const EntityMetaEntityTypeEnum = {
    None: 'NONE',
    Base: 'BASE',
    Event: 'EVENT',
    Dimension: 'DIMENSION',
    Relation: 'RELATION',
    Signal: 'SIGNAL',
    Rollupevent: 'ROLLUPEVENT',
    Timetick: 'TIMETICK'
} as const;

export type EntityMetaEntityTypeEnum = typeof EntityMetaEntityTypeEnum[keyof typeof EntityMetaEntityTypeEnum];
export const EntityMetaEntitySubTypeEnum = {
    None: 'NONE',
    DimensionLow: 'DIMENSION_LOW',
    DimensionHigh: 'DIMENSION_HIGH'
} as const;

export type EntityMetaEntitySubTypeEnum = typeof EntityMetaEntitySubTypeEnum[keyof typeof EntityMetaEntitySubTypeEnum];
export const EntityMetaEntityOntologyEnum = {
    None: 'NONE',
    User: 'USER',
    Email: 'EMAIL',
    Ip: 'IP',
    Phone: 'PHONE',
    Device: 'DEVICE',
    Product: 'PRODUCT',
    DeviceEvents: 'DEVICE_EVENTS',
    UserSignup: 'USER_SIGNUP',
    UserLogin: 'USER_LOGIN',
    Transaction: 'TRANSACTION',
    Payment: 'PAYMENT',
    Order: 'ORDER',
    Cart: 'CART',
    BlsMsa: 'BLS_MSA',
    City: 'CITY',
    Department: 'DEPARTMENT'
} as const;

export type EntityMetaEntityOntologyEnum = typeof EntityMetaEntityOntologyEnum[keyof typeof EntityMetaEntityOntologyEnum];
export const EntityMetaEntityViewTypeEnum = {
    Chart: 'CHART',
    Model: 'MODEL',
    Artifact: 'ARTIFACT',
    None: 'NONE'
} as const;

export type EntityMetaEntityViewTypeEnum = typeof EntityMetaEntityViewTypeEnum[keyof typeof EntityMetaEntityViewTypeEnum];


