/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateDeploymentPropertyDto } from '../Models';
// @ts-ignore
import { DeploymentPropertyDto } from '../Models';
// @ts-ignore
import { ErrorMessage } from '../Models';
// @ts-ignore
import { UpdateDeploymentPropertyDto } from '../Models';
/**
 * DeploymentPropertyControllerApi - axios parameter creator
 * @export
 */
export const DeploymentPropertyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        all1: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deployment_property`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDeploymentPropertyDto} createDeploymentPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create6: async (createDeploymentPropertyDto: CreateDeploymentPropertyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeploymentPropertyDto' is not null or undefined
            assertParamExists('create6', 'createDeploymentPropertyDto', createDeploymentPropertyDto)
            const localVarPath = `/deployment_property`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeploymentPropertyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete7: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete7', 'id', id)
            const localVarPath = `/deployment_property/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get6: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get6', 'id', id)
            const localVarPath = `/deployment_property/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByName1: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getByName1', 'name', name)
            const localVarPath = `/deployment_property/by-name/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDeploymentPropertyDto} updateDeploymentPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update7: async (id: string, updateDeploymentPropertyDto: UpdateDeploymentPropertyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update7', 'id', id)
            // verify required parameter 'updateDeploymentPropertyDto' is not null or undefined
            assertParamExists('update7', 'updateDeploymentPropertyDto', updateDeploymentPropertyDto)
            const localVarPath = `/deployment_property/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeploymentPropertyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeploymentPropertyControllerApi - functional programming interface
 * @export
 */
export const DeploymentPropertyControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeploymentPropertyControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async all1(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentPropertyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.all1(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeploymentPropertyControllerApi.all1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CreateDeploymentPropertyDto} createDeploymentPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create6(createDeploymentPropertyDto: CreateDeploymentPropertyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentPropertyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create6(createDeploymentPropertyDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeploymentPropertyControllerApi.create6']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete7(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete7(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeploymentPropertyControllerApi.delete7']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get6(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentPropertyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get6(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeploymentPropertyControllerApi.get6']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByName1(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentPropertyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByName1(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeploymentPropertyControllerApi.getByName1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDeploymentPropertyDto} updateDeploymentPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update7(id: string, updateDeploymentPropertyDto: UpdateDeploymentPropertyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentPropertyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update7(id, updateDeploymentPropertyDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeploymentPropertyControllerApi.update7']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DeploymentPropertyControllerApi - factory interface
 * @export
 */
export const DeploymentPropertyControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeploymentPropertyControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        all1(options?: any): AxiosPromise<Array<DeploymentPropertyDto>> {
            return localVarFp.all1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDeploymentPropertyDto} createDeploymentPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create6(createDeploymentPropertyDto: CreateDeploymentPropertyDto, options?: any): AxiosPromise<DeploymentPropertyDto> {
            return localVarFp.create6(createDeploymentPropertyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete7(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.delete7(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get6(id: string, options?: any): AxiosPromise<DeploymentPropertyDto> {
            return localVarFp.get6(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByName1(name: string, options?: any): AxiosPromise<DeploymentPropertyDto> {
            return localVarFp.getByName1(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDeploymentPropertyDto} updateDeploymentPropertyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update7(id: string, updateDeploymentPropertyDto: UpdateDeploymentPropertyDto, options?: any): AxiosPromise<DeploymentPropertyDto> {
            return localVarFp.update7(id, updateDeploymentPropertyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeploymentPropertyControllerApi - interface
 * @export
 * @interface DeploymentPropertyControllerApi
 */
export interface DeploymentPropertyControllerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentPropertyControllerApiInterface
     */
    all1(options?: RawAxiosRequestConfig): AxiosPromise<Array<DeploymentPropertyDto>>;

    /**
     * 
     * @param {CreateDeploymentPropertyDto} createDeploymentPropertyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentPropertyControllerApiInterface
     */
    create6(createDeploymentPropertyDto: CreateDeploymentPropertyDto, options?: RawAxiosRequestConfig): AxiosPromise<DeploymentPropertyDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentPropertyControllerApiInterface
     */
    delete7(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentPropertyControllerApiInterface
     */
    get6(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DeploymentPropertyDto>;

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentPropertyControllerApiInterface
     */
    getByName1(name: string, options?: RawAxiosRequestConfig): AxiosPromise<DeploymentPropertyDto>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateDeploymentPropertyDto} updateDeploymentPropertyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentPropertyControllerApiInterface
     */
    update7(id: string, updateDeploymentPropertyDto: UpdateDeploymentPropertyDto, options?: RawAxiosRequestConfig): AxiosPromise<DeploymentPropertyDto>;

}

/**
 * DeploymentPropertyControllerApi - object-oriented interface
 * @export
 * @class DeploymentPropertyControllerApi
 * @extends {BaseAPI}
 */
export class DeploymentPropertyControllerApi extends BaseAPI implements DeploymentPropertyControllerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentPropertyControllerApi
     */
    public all1(options?: RawAxiosRequestConfig) {
        return DeploymentPropertyControllerApiFp(this.configuration).all1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDeploymentPropertyDto} createDeploymentPropertyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentPropertyControllerApi
     */
    public create6(createDeploymentPropertyDto: CreateDeploymentPropertyDto, options?: RawAxiosRequestConfig) {
        return DeploymentPropertyControllerApiFp(this.configuration).create6(createDeploymentPropertyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentPropertyControllerApi
     */
    public delete7(id: string, options?: RawAxiosRequestConfig) {
        return DeploymentPropertyControllerApiFp(this.configuration).delete7(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentPropertyControllerApi
     */
    public get6(id: string, options?: RawAxiosRequestConfig) {
        return DeploymentPropertyControllerApiFp(this.configuration).get6(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentPropertyControllerApi
     */
    public getByName1(name: string, options?: RawAxiosRequestConfig) {
        return DeploymentPropertyControllerApiFp(this.configuration).getByName1(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDeploymentPropertyDto} updateDeploymentPropertyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentPropertyControllerApi
     */
    public update7(id: string, updateDeploymentPropertyDto: UpdateDeploymentPropertyDto, options?: RawAxiosRequestConfig) {
        return DeploymentPropertyControllerApiFp(this.configuration).update7(id, updateDeploymentPropertyDto, options).then((request) => request(this.axios, this.basePath));
    }
}

