import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Button, Breadcrumbs, Tooltip, Grid, Box } from "@material-ui/core";

import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

import { capitalize } from "src/utils/capitalize";
import { formatBytes } from "src/utils/format-bytes";
import { dateFormat } from "src/utils/dateFormat";
import { ellipses, leadingTextInPath, getExtension } from "src/utils/formatText";

import { Table, Spinner, SearchField } from "src/components";

import {
  Criteria,
  validFileTypes,
  DataSourceFileTypes,
  DatasetHelperText,
  FivetranStatus
} from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";

import { getFivetranConnectorStatus } from "src/utils/fivetran";
import {
  DEFAULT_SYNCING_MESSAGE,
  DEFAULT_SYNC_ISSUE_MESSAGE
} from "pages/DataSources/utils/DataSources.constants";
import { useDataSourcesStore } from "src/store/store";
import useStyles from "./ConnectorFilesSelection.styles";
import { useSourceContext } from "../../contexts/Source/useSourceContext";

const ConnectorFilesSelection = () => {
  const {
    areDataSourceFilesFetching,
    sources,
    dataSourceFiles: inputDataSourcesFiles,
    getDataSourceFiles
  } = useSourceContext();

  const classes: $TSFixMe = useStyles();
  const newConnectorToSync = useDataSourcesStore((state) => state.newConnectorToSync);

  // Stores - STARTS >>
  const {
    datasetCriterionStore,
    datasetSourceStore,
    datasetSelectedDataSourceFilesStore,
    setDatasetSelectedDataSourceFilesStore
  } = useStoreSelectors();
  // << ENDS - Stores

  // States - STARTS >>
  const [breadcrumbs, setBreadcrumbs] = useState<$TSFixMe>([]);
  const [dataSourcesFiles, setDataSourcesFiles] = useState<$TSFixMe>([]);
  const [searchTerm, setSearchTerm] = useState<$TSFixMe>("");
  // << ENDS - States

  const filteredData = useMemo(() => {
    return (dataSourcesFiles || [])?.filter((eachField: $TSFixMe) => {
      return Object.values(eachField || {})
        ?.join(" ")
        ?.toLowerCase()
        ?.includes(searchTerm?.toLowerCase());
    });
  }, [searchTerm, dataSourcesFiles]);

  const { isFivetran, message } = useMemo(() => {
    const source = _.find(sources, { id: datasetSourceStore?.value });
    const isSameConnector = source?.id === newConnectorToSync?.id;
    const thirdPartyDataSourceStatus = _.get(source, "thirdPartyDataSourceStatus");
    const status = thirdPartyDataSourceStatus
      ? getFivetranConnectorStatus(
          thirdPartyDataSourceStatus,
          isSameConnector,
          newConnectorToSync?.created
        )
      : null;

    let message = null;
    if (status === FivetranStatus.syncing) {
      message = DEFAULT_SYNCING_MESSAGE;
    } else if (status === FivetranStatus.syncIssues) {
      message = DEFAULT_SYNC_ISSUE_MESSAGE;
    }

    return {
      isFivetran: _.get(source, "isFivetran") ?? false,
      message
    };
  }, [sources, datasetSourceStore?.value]);

  useEffect(() => {
    setDataSourcesFiles([]);

    (inputDataSourcesFiles || [])?.length > 0 &&
      setDataSourcesFiles(
        (inputDataSourcesFiles || []).map((inputDataSourceFile: $TSFixMe, index: number) => {
          return {
            ...inputDataSourceFile,
            id: `dataSourceFile_${index}`,
            checked: (datasetSelectedDataSourceFilesStore || []).some(
              (eachFile: $TSFixMe) =>
                (eachFile?.type === DataSourceFileTypes.File || isFivetran) &&
                eachFile?.path === inputDataSourceFile?.path
            )
          };
        })
      );
  }, [inputDataSourcesFiles, datasetSelectedDataSourceFilesStore, isFivetran]);

  const updateSelectedDataSourceFilesStore = (thisFiles: $TSFixMe) => {
    const deselectedDataSourceFiles = thisFiles
      .filter((eachFile: $TSFixMe) => !eachFile?.checked)
      ?.map((eachFile: $TSFixMe) => eachFile);

    const historyCombinedSelectedDataSourceFiles = [
      ...(datasetSelectedDataSourceFilesStore || []).filter(
        (eachFile: $TSFixMe) =>
          !deselectedDataSourceFiles.some(
            (eachDeselectedDataSourcesFile: $TSFixMe) =>
              (eachDeselectedDataSourcesFile?.type === eachFile?.type || isFivetran) &&
              eachDeselectedDataSourcesFile?.path === eachFile?.path
          )
      ),
      ...thisFiles
        .filter((eachFile: $TSFixMe) => eachFile?.checked)
        ?.map((eachFile: $TSFixMe) => eachFile)
    ]?.filter(
      (eachFile: $TSFixMe, index: number, self) =>
        index ===
        self.findIndex(
          (thisEachDataSourceFile: $TSFixMe) =>
            (thisEachDataSourceFile.type === eachFile.type || isFivetran) &&
            thisEachDataSourceFile.path === eachFile.path
        )
    );

    setDatasetSelectedDataSourceFilesStore(historyCombinedSelectedDataSourceFiles);
  };

  const getDataSourceFilesOfTypeFiles = () =>
    (filteredData || [])?.filter(
      (eachFile: $TSFixMe) => eachFile?.type === DataSourceFileTypes.File
    );

  const validatedDataSourcesFilesSelected = () => {
    const filteredDataSourcesFiles = (filteredData || [])?.filter((eachFile: $TSFixMe) => {
      if (isFivetran) {
        return true;
      }
      return eachFile?.type === DataSourceFileTypes.File
        ? validFileTypes.includes(getExtension(eachFile?.path)?.toLowerCase())
        : false;
    });

    return filteredDataSourcesFiles;
  };

  const isAllDataSourcesFilesSelected = () => {
    const filteredDataSourcesFiles = validatedDataSourcesFilesSelected();

    return (filteredDataSourcesFiles || [])?.length === 0
      ? false
      : filteredDataSourcesFiles?.every((eachFile: $TSFixMe) => eachFile?.checked);
  };

  const toggleDataSourcesFilesSelectAll = () => {
    const thisIsAllDataSourcesFilesSelected = isAllDataSourcesFilesSelected();
    const filteredDataSourcesFiles = validatedDataSourcesFilesSelected();

    const thisDataSourcesFiles = (filteredDataSourcesFiles || [])?.map((eachFile: $TSFixMe) => {
      eachFile.checked = !thisIsAllDataSourcesFilesSelected;

      return eachFile;
    });

    setDataSourcesFiles(thisDataSourcesFiles);

    updateSelectedDataSourceFilesStore(thisDataSourcesFiles);
  };

  const toggleDataSourceFileSelect = (id: string) => {
    let historyCombinedSelectedDataSourcesFiles: $TSFixMe = filteredData;
    if (datasetCriterionStore?.value === Criteria.SingleFileUpload) {
      historyCombinedSelectedDataSourcesFiles = [
        ...datasetSelectedDataSourceFilesStore,
        ...filteredData
      ];
    }

    // $FixMe: Code to be improved.
    const thisDataSourcesFiles = (historyCombinedSelectedDataSourcesFiles || []).map(
      (eachDataSourceFile: $TSFixMe) => {
        return eachDataSourceFile?.id === id
          ? {
              ...eachDataSourceFile,
              checked: !eachDataSourceFile?.checked
            }
          : {
              ...eachDataSourceFile,
              checked:
                datasetCriterionStore?.value === Criteria.SingleFileUpload
                  ? false
                  : eachDataSourceFile?.checked
            };
      }
    );

    setDataSourcesFiles(thisDataSourcesFiles);

    updateSelectedDataSourceFilesStore(thisDataSourcesFiles);
  };

  const isDisabledSelection = (extension: $TSFixMe) => {
    return !validFileTypes.includes(extension?.toLowerCase());
  };

  const getColumns = () => {
    let columns: $TSFixMe = [];

    if (getDataSourceFilesOfTypeFiles()?.length > 0 || isFivetran) {
      columns.push({
        id: "checkAll",
        accessor: "checked",
        width: datasetCriterionStore?.value === Criteria.SingleFileUpload ? "8%" : "12%",
        Header: (getDataSourceFilesOfTypeFiles()?.length > 0 || isFivetran) &&
          datasetCriterionStore?.value !== Criteria.SingleFileUpload && (
            <Checkbox
              id="dataSourceCheckAll"
              className={classes.checkboxAll}
              color="primary"
              disabled={validatedDataSourcesFilesSelected()?.length === 0}
              checked={isAllDataSourcesFilesSelected()}
              onClick={toggleDataSourcesFilesSelectAll}
              style={{ opacity: validatedDataSourcesFilesSelected()?.length === 0 ? 0.5 : 1 }}
            />
          ),
        isTooltip: false,
        Cell: ({ row }: $TSFixMe) => {
          const { id, type, path, checked } = row.original;

          if (type !== DataSourceFileTypes.File && !isFivetran) {
            return null;
          }

          const extension = leadingTextInPath({
            text: leadingTextInPath({ text: path }),
            delimiter: "."
          });

          return datasetCriterionStore?.value === Criteria.SingleFileUpload ? (
            <Radio
              id={`dataSourceFileSelection_${id}`}
              checked={checked}
              color="primary"
              disabled={
                isFivetran
                  ? false
                  : type !== DataSourceFileTypes.Directory
                    ? !validFileTypes.includes(extension?.toLowerCase())
                    : false
              }
              onChange={() => {
                toggleDataSourceFileSelect(id);
              }}
              value={path}
              name="dataSource"
            />
          ) : (
            <Checkbox
              id={`dataSourceFileSelection_${id}`}
              checked={checked}
              color="primary"
              disabled={type === DataSourceFileTypes.File ? isDisabledSelection(extension) : false}
              onClick={() => {
                toggleDataSourceFileSelect(id);
              }}
            />
          );
        }
      });
    }
    columns.push({
      id: "Name",
      accessor: "path",
      Header: "Name",
      width: datasetCriterionStore?.value === Criteria.SingleFileUpload ? "42%" : "38%",
      isSortable: true,
      Cell: ({ row }: $TSFixMe) => {
        const { id, path, type } = row.original;

        const extension = leadingTextInPath({
          text: leadingTextInPath({ text: path }),
          delimiter: "."
        });

        return type === DataSourceFileTypes.Directory ? (
          <Button
            id={`dataSourceFileName_${id}`}
            color="primary"
            onClick={async () => {
              await getDataSourceFiles(path);

              const newPaths = breadcrumbs?.length === 0 ? ["/", path] : [...breadcrumbs, path];
              setBreadcrumbs(newPaths);
              setDatasetSelectedDataSourceFilesStore([]);
            }}
            className={classes.breadcrumbTypeLink}>
            <FolderOpenIcon className={classes.breadcrumbIcon} />
            <span>{leadingTextInPath({ text: path })}</span>
          </Button>
        ) : (
          <div
            id={`dataSourceFileName_${id}`}
            className={classes.breadcrumbTypeText}
            style={{
              opacity: (
                datasetCriterionStore?.value === Criteria.SingleFileUpload
                  ? !validFileTypes.includes(extension?.toLowerCase()) && !isFivetran
                  : isDisabledSelection(extension) && !isFivetran
              )
                ? 0.5
                : 1
            }}>
            <DescriptionOutlinedIcon fontSize="small" />
            {leadingTextInPath({ text: path })}
          </div>
        );
      }
    });

    if (!isFivetran) {
      columns = [
        ...columns,
        {
          id: "Type",
          accessor: "type",
          Header: "Type",
          isSortable: true,
          width: "15%",
          Cell: ({ row }: $TSFixMe) => {
            const { id, path, type } = row.original;

            const extension = leadingTextInPath({
              text: leadingTextInPath({ text: path }),
              delimiter: "."
            });

            return type ? (
              type === DataSourceFileTypes.Directory ? (
                capitalize(type)
              ) : (
                <span
                  id={`dataSourceFileType_${id}`}
                  style={{
                    opacity: !validFileTypes.includes(extension?.toLowerCase()) ? 0.5 : 1
                  }}>
                  {capitalize(type)}
                </span>
              )
            ) : null;
          }
        },
        {
          id: "Size",
          accessor: "sizeInBytes",
          Header: "Size",
          isSortable: true,
          width: "15%",
          Cell: ({ row }: $TSFixMe) =>
            row?.original?.sizeInBytes !== DataSourceFileTypes.Directory &&
            row?.original?.sizeInBytes
              ? formatBytes(row?.original?.sizeInBytes)
              : "-"
        },
        {
          id: "Last Modified",
          accessor: "updatedAt",
          Header: "Last Modified",
          isSortable: true,
          width: "20%",
          Cell: ({ row }: $TSFixMe) =>
            row?.original?.updatedAt !== DataSourceFileTypes.Directory && row?.original?.updatedAt
              ? dateFormat(row?.original?.updatedAt, false) ?? ""
              : "-"
        }
      ];
    }

    return columns;
  };

  const onSearchChange = (event: $TSFixMe) => {
    setSearchTerm(event?.target?.value);
  };

  const updateBreadcrumbs = (index: $TSFixMe) => {
    breadcrumbs.splice(index + 1);

    setBreadcrumbs(breadcrumbs);
  };

  useEffect(
    () => setBreadcrumbs(() => []),
    [datasetSourceStore?.value, datasetCriterionStore?.value]
  );

  return (
    <Grid container style={{ height: "100%" }}>
      {areDataSourceFilesFetching ? (
        <Spinner size={24} />
      ) : (inputDataSourcesFiles || [])?.length > 0 && (dataSourcesFiles || [])?.length === 0 ? (
        <Spinner size={24} />
      ) : (
        <>
          {dataSourcesFiles?.length > 0 ? (
            <Grid container direction="column" className={classes.root}>
              {!!message && (
                <Grid item className={classes.messageContainer}>
                  {message}
                </Grid>
              )}

              <Grid item className={classes.headerContainer}>
                <Grid
                  container
                  justifyContent={breadcrumbs?.length > 1 ? "space-between" : "flex-end"}>
                  {breadcrumbs?.length > 1 && (
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbContainer}>
                      {(breadcrumbs || [])?.map(
                        (breadcrumb: $TSFixMe, breadcrumbIndex: $TSFixMe) => {
                          return breadcrumbIndex === breadcrumbs.length - 1 ? (
                            <Tooltip
                              id={`breadcrumbTextTooltip_${breadcrumbIndex}`}
                              key={`breadcrumb_${breadcrumbIndex}`}
                              title={
                                leadingTextInPath({ text: breadcrumb })?.length > 20
                                  ? leadingTextInPath({ text: breadcrumb })
                                  : ""
                              }>
                              <span
                                id={`breadcrumbText_${breadcrumbIndex}`}
                                className="breadcrumbText">
                                {ellipses({
                                  text: leadingTextInPath({ text: breadcrumb }),
                                  length: 15
                                })}
                              </span>
                            </Tooltip>
                          ) : (
                            <Button
                              id={`breadcrumbTextLink_${breadcrumbIndex}`}
                              key={`breadcrumb_${breadcrumbIndex}`}
                              color="primary"
                              onClick={() => {
                                updateBreadcrumbs(breadcrumbIndex);
                                getDataSourceFiles(breadcrumb);
                                setDatasetSelectedDataSourceFilesStore([]);
                              }}
                              startIcon={breadcrumbIndex === 0 ? <NavigateBeforeIcon /> : <></>}
                              className="breadcrumbLink">
                              <FolderOpenIcon
                                className={classes.breadcrumbIcon}
                                style={
                                  !leadingTextInPath({ text: breadcrumb })
                                    ? {
                                        marginRight: 0
                                      }
                                    : {}
                                }
                              />
                              <Tooltip
                                id={`breadcrumbTextTooltip_${breadcrumbIndex}`}
                                key={`breadcrumb_${breadcrumbIndex}`}
                                title={
                                  leadingTextInPath({ text: breadcrumb })?.length > 20
                                    ? leadingTextInPath({ text: breadcrumb })
                                    : ""
                                }>
                                <span id={`breadcrumbText_${breadcrumbIndex}`}>
                                  {ellipses({
                                    text: leadingTextInPath({ text: breadcrumb }),
                                    length: 15
                                  })}
                                </span>
                              </Tooltip>
                            </Button>
                          );
                        }
                      )}
                    </Breadcrumbs>
                  )}

                  <SearchField
                    id="ontologyContainerSearch"
                    placeholder="Search"
                    className={classes.searchField}
                    value={searchTerm}
                    onChange={onSearchChange}
                  />
                </Grid>
              </Grid>

              <Grid item className={classes.tableContainer}>
                <Table
                  size="small"
                  orderByDefault="Name"
                  columns={getColumns()}
                  data={filteredData}
                  maxHeight={`calc(100vh - ${!!message ? 343 : 293}px)`}
                  isTheadSticky
                  fixedLayout={false}
                  hideSettings
                />
              </Grid>
            </Grid>
          ) : isFivetran ? (
            <Box m={2}>
              <div>Data not available. This might be due to the following</div>
              <div>- Sync is still in progress. Recheck after some time</div>
              <div>- No data available at source. Update your data-connector</div>
            </Box>
          ) : (
            <span id="dataSourcesNoFileFound">{DatasetHelperText.NoFileFound}</span>
          )}
        </>
      )}
    </Grid>
  );
};

export default ConnectorFilesSelection;
