/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { BaseTransform } from './base-transform';
// May contain unused imports in some cases
// @ts-ignore
import { DFSInput } from './dfsinput';
// May contain unused imports in some cases
// @ts-ignore
import { PipelineEntityFeature } from './pipeline-entity-feature';

/**
 * 
 * @export
 * @interface CreateDFSTemplateDto
 */
export interface CreateDFSTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'type'?: CreateDFSTemplateDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'conditionExpression'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'markdown'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'source'?: CreateDFSTemplateDtoSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'category'?: CreateDFSTemplateDtoCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'status'?: CreateDFSTemplateDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'requirements'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'projectId'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateDFSTemplateDto
     */
    'variableExprMap'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: Array<PipelineEntityFeature>; }}
     * @memberof CreateDFSTemplateDto
     */
    'fieldsTemplate'?: { [key: string]: Array<PipelineEntityFeature>; };
    /**
     * 
     * @type {Array<BaseTransform>}
     * @memberof CreateDFSTemplateDto
     */
    'baseTransforms'?: Array<BaseTransform>;
    /**
     * 
     * @type {Array<DFSInput>}
     * @memberof CreateDFSTemplateDto
     */
    'inputs'?: Array<DFSInput>;
    /**
     * 
     * @type {Set<string>}
     * @memberof CreateDFSTemplateDto
     */
    'tags'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'descriptionHtml'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDFSTemplateDto
     */
    'systemTemplate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDFSTemplateDto
     */
    'localTemplate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDFSTemplateDto
     */
    'tenantTemplate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'display_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDFSTemplateDto
     */
    'version'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof CreateDFSTemplateDto
     */
    'secondary_tags'?: Set<string>;
}

export const CreateDFSTemplateDtoTypeEnum = {
    Notebook: 'NOTEBOOK',
    PythonFunc: 'PYTHON_FUNC',
    Code: 'CODE'
} as const;

export type CreateDFSTemplateDtoTypeEnum = typeof CreateDFSTemplateDtoTypeEnum[keyof typeof CreateDFSTemplateDtoTypeEnum];
export const CreateDFSTemplateDtoSourceEnum = {
    System: 'SYSTEM',
    Project: 'PROJECT',
    Tenant: 'TENANT',
    Custom: 'CUSTOM'
} as const;

export type CreateDFSTemplateDtoSourceEnum = typeof CreateDFSTemplateDtoSourceEnum[keyof typeof CreateDFSTemplateDtoSourceEnum];
export const CreateDFSTemplateDtoCategoryEnum = {
    Basic: 'BASIC',
    Advanced: 'ADVANCED'
} as const;

export type CreateDFSTemplateDtoCategoryEnum = typeof CreateDFSTemplateDtoCategoryEnum[keyof typeof CreateDFSTemplateDtoCategoryEnum];
export const CreateDFSTemplateDtoStatusEnum = {
    Active: 'ACTIVE',
    Disabled: 'DISABLED'
} as const;

export type CreateDFSTemplateDtoStatusEnum = typeof CreateDFSTemplateDtoStatusEnum[keyof typeof CreateDFSTemplateDtoStatusEnum];


