import React from "react";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  makeStyles
} from "@material-ui/core";

import Text from "src/components/Widget/Text";
import useStoreSelectors from "../../hooks/useStoreSelectors";
import { DatasetHelperText } from "../../utils/Dataset.constants";
import { OverflowTooltip } from "src/components";
import { useProjectsStore } from "src/store/store";
import { projectsGetter } from "src/store/store.selectors";
import { map } from "lodash";

const useStyles = makeStyles(() => ({
  root: {
    "& [class^='MuiInputLabel-root']": { maxWidth: "100%" }
  },
  titleText: {
    maxWidth: "90%"
  }
}));

const ProjectDropdown = () => {
  const classes: $TSFixMe = useStyles();

  // Stores - STARTS >>
  const projectsStore = useProjectsStore(projectsGetter);
  const { datasetProjectStore, setDatasetProjectStore } = useStoreSelectors();
  // << ENDS - Stores

  const onChange = (event: $TSFixMe) => {
    const thisProjectStore = datasetProjectStore;
    thisProjectStore.value = event?.target?.value;

    setDatasetProjectStore({ ...thisProjectStore });
  };

  return (
    <FormControl fullWidth variant="outlined" className={classes.root}>
      <InputLabel id="projectLabel">{DatasetHelperText.Project}</InputLabel>
      <Select
        labelId="projectLabel"
        id="project"
        value={datasetProjectStore?.value}
        disabled={datasetProjectStore?.isDisabled}
        onChange={onChange}
        label={DatasetHelperText.Project}>
        {map(projectsStore, (eachProject: $TSFixMe) => {
          return (
            <MenuItem
              id={`projectOption_${eachProject?.id}`}
              key={`projectOption_${eachProject?.id}`}
              value={eachProject?.id}>
              <Typography
                id={`projectName_${eachProject?.id}`}
                color="textPrimary"
                className={classes.titleText}>
                <OverflowTooltip
                  value={<Text value={eachProject?.name} style={{ whiteSpace: "pre" }} />}
                />
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ProjectDropdown;
