import { useState, useEffect } from "react";
import Papa from "papaparse";

import { SignedUrlObjectTypeEnum, useGetSignedUrlMutation } from "src/hooks/api";
import { getAPIWithRethrow } from "src/utils/apiService";

export const useFetchChartData = ({
  name,
  dataFilePath,
  chartType,
  shouldFetchData = true,
  onSuccess
}: {
  name: string;
  dataFilePath: string | undefined;
  chartType: string;
  shouldFetchData?: boolean;
  onSuccess?: () => void;
}) => {
  const [chartData, setChartData] = useState<any>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const getSignedUrlMutation = useGetSignedUrlMutation();

  useEffect(() => {
    if (!dataFilePath || !shouldFetchData) {
      return;
    }
    setIsFetching(true);
    getSignedUrlMutation.mutate(
      {
        fileName: name,
        filePath: dataFilePath,
        signedUrlObjectType: SignedUrlObjectTypeEnum.GENERAL
      },
      {
        onSuccess: async (response: any) => {
          const fileData = await getAPIWithRethrow(response.signedUrl, {
            headers: response.headers,
            signedUrl: true
          });
          if (chartType === "IMAGE") {
            const res = await fetch(response.signedUrl);
            const blob = await res.blob();

            setChartData(URL.createObjectURL(blob));
            setIsFetching(false);
            onSuccess?.();
            return;
          }
          if (chartType === "TABLE") {
            Papa.parse(fileData, {
              complete: function (results) {
                setChartData(results.data);
              },
              header: true,
              skipEmptyLines: true
            });

            setIsFetching(false);
            onSuccess?.();
            return;
          }
          if (chartType === "JSON") {
            //Remove title
            setChartData({ ...fileData, layout: { ...fileData.layout, title: null } });
            setIsFetching(false);
            onSuccess?.();
            return;
          }
          onSuccess?.();
          setChartData(fileData);
          setIsFetching(false);
        },
        onError: () => {
          setIsFetching(false);
        }
      }
    );
  }, [dataFilePath, shouldFetchData]);
  return { isFetching, chartData };
};
