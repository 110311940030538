import React, { useMemo, useState } from "react";
import _, { groupBy, isEmpty, isEqual, size } from "lodash";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import DataAppConfigDrawer from "../common/DataAppConfigDrawer";
import DataAppLogsDrawer from "../DataAppLogs/DataAppLogsDrawer";
import backgroundBottomImg from "src/assets/images/background-bottom.svg";
import backgroundTopImg from "src/assets/images/background-top.svg";
import { useProjectsStore } from "src/store/store";
import { DataAppsConfig } from "../utils/DataApps.constants";
import { DataAppsTable } from "./DataAppsTable/DataAppsTable";
import { DataAppsTiles } from "./DataAppsTiles/DataAppsTiles";
import { DataAppType } from "../DataApps.type";
import { EditDataAppDrawer } from "../EditDataAppDrawer/EditDataAppDrawer";
import { IProject } from "hooks/api";
import { defaultImages } from "../common/DataAppDefaultImage";
import { projectsGetter } from "src/store/store.selectors";
import { useGetRole } from "src/hooks/useGetRole";
import { ToggleView } from "src/components/custom";
import Search from "src/components/custom/Search/Search";
import { DataAppsFilter } from "./DataAppsFilter/DataAppsFilter";
import noDataAppImgSrc from "src/assets/images/no-dataApps-screen.png";
import { listViewPages, PagesViewConfiguration } from "src/constants";
import MuiLink from "@material-ui/core/Link";
import EventBus from "src/utils/EventBus";
import { EVENTBUS_EVENTS } from "src/constants/eventbus.constants";

interface IProps {
  dataApps: DataAppType[];
  allDataApps: DataAppType[];
  onDelete: (dataApp: DataAppType) => void;
  isProjectDataApps: boolean;
  onSearch: (event: $TSFixMe) => void;
  setIsTilesView: (tilesView: boolean) => void;
  isTilesView: boolean;
  searchedDataApps: DataAppType[];
  selectedProjects: string[];
  setSelectedProjects: (selectedProjects: string[]) => void;
}

type StyleProps = {
  isProjectPath?: boolean;
  isProjectDataApps?: boolean;
};

const useStyles = makeStyles({
  root: {
    backgroundColor: "#f5f5f5",
    display: "flex",
    flexDirection: "column",
    // For Project Sub-Navbar
    marginLeft: ({ isProjectDataApps }: StyleProps) => (!!isProjectDataApps ? 50 : 0),
    padding: ({ isProjectDataApps }: StyleProps) => (isProjectDataApps ? 16 : "64px 10% 32px 10%"),
    width: "auto",
    // New UX change
    // The value 96px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 46px).
    height: "calc(100vh - 96px)",
    overflowY: "auto",
    overflowX: "hidden"
  },
  title: {
    color: "#003656",
    fontSize: "32px",
    lineHeight: "37.5px",
    paddingBottom: "4px"
  },
  topAction: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    marginLeft: "auto"
  },
  noDataAppImgWrap: {
    flexWrap: "nowrap",
    alignItems: "center",
    paddingTop: "15%",
    gap: "8px"
  },
  noDataAppImg: {
    height: "90px",
    width: "200px"
  }
});

export const DataAppsView: React.FC<IProps> = (props) => {
  const {
    dataApps,
    onDelete,
    isProjectDataApps = false,
    onSearch,
    isTilesView,
    setIsTilesView,
    selectedProjects,
    setSelectedProjects,
    searchedDataApps,
    allDataApps
  } = props;

  const projects = useProjectsStore(projectsGetter);
  const { isRoleYieldsDataAppView } = useGetRole();

  const isFiltered = useMemo(
    () => !isEqual(size(groupBy(allDataApps, "projectId")), size(selectedProjects)),
    [dataApps, selectedProjects]
  );

  const classes = useStyles({
    isProjectDataApps
  });

  const [configDataApp, setConfigDataApp] = useState<DataAppType | null>(null);
  const [logsDataApp, setLogsDataApp] = useState<DataAppType | null>(null);
  const [editingDataApp, setEditingDataApp] = useState<DataAppType | null>(null);

  const pagesViewConfiguration = JSON.parse(localStorage.getItem(PagesViewConfiguration) || "{}");

  const toggleView = (bool: boolean) => {
    setIsTilesView(bool);
    localStorage.setItem(
      PagesViewConfiguration,
      JSON.stringify({ ...pagesViewConfiguration, [listViewPages.DATAAPPS]: bool })
    );
  };

  const projectIdNameMap = useMemo(
    () =>
      projects?.reduce(
        (acc: any, project: IProject) => ({ ...acc, [project.id]: project.name }),
        {}
      ),
    [projects]
  );

  const openFilterDropdown = () => {
    EventBus.publish(EVENTBUS_EVENTS.OpenFilterDropdownDataApp);
  };

  const updatedDataApps = useMemo(() => {
    let imageIndex = 0;
    return dataApps.map((dataApp) => {
      const projectName = projectIdNameMap?.[dataApp.projectId];
      const updatedImage = !dataApp.iconUrl ? defaultImages[imageIndex] : null;
      const isLastImage = imageIndex === defaultImages.length - 1;
      imageIndex = dataApp.iconUrl ? imageIndex : isLastImage ? 0 : imageIndex + 1;
      const stoppedBy =
        dataApp.stoppedBy === "CleanupJob"
          ? "System (Due to Inactivity)"
          : dataApp.stoppedBy || " - ";
      return { ...dataApp, imgComponent: updatedImage, projectName, stoppedBy };
    });
  }, [dataApps, projectIdNameMap]);

  const handleClose = () => {
    setConfigDataApp(null);
  };

  const handleConfigOpen = (appInfo: DataAppType) => {
    setConfigDataApp(appInfo);
  };

  const handleDataAppEdit = (appInfo: DataAppType) => {
    setEditingDataApp(appInfo);
  };

  const handleLogsOpen = (appInfo: DataAppType) => {
    setLogsDataApp(appInfo);
  };

  const availableWindowSize = window.innerWidth * (1 - DataAppsConfig.ExemptingWidth);
  const numberOfCards = Math.floor((availableWindowSize + 24) / (DataAppsConfig.CardWidth + 24));

  const startLoc = isTilesView
    ? (availableWindowSize + 24 - numberOfCards * (DataAppsConfig.CardWidth + 24)) / 2
    : 0;

  return (
    <>
      {configDataApp && (
        <DataAppConfigDrawer
          open={!_.isEmpty(configDataApp)}
          dataApp={configDataApp}
          onClose={handleClose}
        />
      )}
      {editingDataApp && (
        <EditDataAppDrawer dataApp={editingDataApp} onClose={() => setEditingDataApp(null)} />
      )}
      {logsDataApp && (
        <DataAppLogsDrawer
          open={!_.isEmpty(logsDataApp)}
          dataApp={logsDataApp}
          onClose={() => setLogsDataApp(null)}
        />
      )}

      <Grid
        item
        container
        direction="column"
        wrap="nowrap"
        xs={12}
        className={classes.root}
        {...(!isProjectDataApps
          ? {
              style: {
                backgroundImage: `url(${backgroundTopImg}), url(${backgroundBottomImg})`,
                backgroundSize: "100% auto, 100% auto",
                backgroundRepeat: "no-repeat, no-repeat",
                backgroundPosition: "right top, right bottom"
              }
            }
          : {})}>
        {!isProjectDataApps && (
          <Grid container style={{ padding: `0 ${startLoc}px`, width: availableWindowSize }}>
            <>
              {dataApps?.length !== 0 && (
                <Grid item>
                  <Typography variant="h5" className={classes.title}>
                    DataApps
                  </Typography>

                  <Typography variant="subtitle1">
                    Showing {updatedDataApps.length || 0} DataApps
                  </Typography>
                </Grid>
              )}

              {allDataApps?.length > 0 && (
                <Grid className={classes.topAction}>
                  <Search onSearch={onSearch} placeholder="Search DataApps" />
                  <DataAppsFilter
                    dataApps={allDataApps}
                    selectedProjects={selectedProjects}
                    filteredDataApps={searchedDataApps}
                    setSelectedProjects={setSelectedProjects}
                    projects={projects}
                  />
                  <ToggleView isPrimaryView={isTilesView} setIsPrimaryView={toggleView} />
                </Grid>
              )}
            </>
          </Grid>
        )}
        <Grid
          item
          {...(isProjectDataApps
            ? {}
            : {
                style: { padding: `16px ${startLoc}px`, width: availableWindowSize }
              })}>
          {isTilesView ? (
            <DataAppsTiles
              isProjectDataApps={isProjectDataApps}
              dataApps={updatedDataApps}
              isRoleYieldsDataAppView={isRoleYieldsDataAppView}
              onDelete={onDelete}
              onConfigOpen={handleConfigOpen}
              onEdit={handleDataAppEdit}
              onLogsOpen={handleLogsOpen}
            />
          ) : (
            !isEmpty(updatedDataApps) && (
              <DataAppsTable
                isProjectDataApps={isProjectDataApps}
                dataApps={updatedDataApps}
                isRoleYieldsDataAppView={isRoleYieldsDataAppView}
                onDelete={onDelete}
                onEdit={handleDataAppEdit}
                onConfigOpen={handleConfigOpen}
                onLogsOpen={handleLogsOpen}
              />
            )
          )}
        </Grid>
        {!isProjectDataApps && (!dataApps || dataApps?.length === 0) && (
          <Grid container direction="column" className={classes.noDataAppImgWrap}>
            <Grid component="img" src={noDataAppImgSrc} className={classes.noDataAppImg} />
            <Typography variant="h5">Oops!</Typography>
            <Typography>No DataApps found</Typography>
            {isFiltered && (
              <Typography>
                Please change{" "}
                <MuiLink
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={openFilterDropdown}>
                  filter criteria
                </MuiLink>{" "}
                to view DataApps
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};
