/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Button, Tooltip } from "@material-ui/core";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";

// Icons
import { RunIcon } from "src/icons/NewUX/RunIcon";
import { TimeoutIcon } from "src/icons/NewUX/TimeoutIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { AddRunIcon } from "src/icons/NewUX";

import { useCanvasStore } from "src/store/store";
import { handleResponse } from "src/utils/apiService";
import { validateNameField } from "src/utils/formFieldUtils";
import { useDrawerStore } from "src/store/store";
import { sideComponentSetter } from "src/store/store.selectors";
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import { RecipeConditions } from "../../common/RecipeConditions/RecipeConditions";
import { useCodeRecipeContext } from "../CodeRecipeContext/useCodeRecipeContext";
import { TimeoutTextField } from "../../common/TimeoutTextField/TimeoutTextField";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { DISABLED_RECIPE_LOGS } from "src/constants";
import { useJobsContext } from "src/pages/private/ProjectsModule/pages/Jobs/context/useJobsContext";
import { READONLY_ENTITY } from "src/constants";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { openLogsModal } from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import RecipeLogsDrawer from "../../common/ShowLogsModal/RecipeLogsDrawer";
import { useDeleteRecipe } from "src/hooks/api/transforms/useDeleteRecipe";
import { toastWrapper } from "src/utils/toastWrapper";
import { useQueryClient } from "@tanstack/react-query";
import { UseGetProjectCanvasQueryKeys } from "src/hooks/api";
import { Spinner } from "src/components";

export const CodeRecipeHeader = ({ isDefaultScenario }: { isDefaultScenario: boolean }) => {
  const navigate = useNavigate();
  const { projectId, scenarioId, jobId, jobRunId } = useParams<$TSFixMe>();
  const [isDeleteRecipeSuccess, setIsDeleteRecipeSuccess] = useState(false);

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  // Project context
  const { project } = useProjectContext() || {};

  // Jobs context
  const { jobPaths, jobPathsSetter } = useJobsContext() || {};
  const deleteRecipeMutation = useDeleteRecipe();

  const [setReloadTrigger] = useCanvasStore((state) => [state.setReloadTrigger]);

  const [isRecipeUpdateInProgress, setIsRecipeUpdateInProgress] = React.useState(false);
  const {
    jobData,
    jobRunData,
    isSaveDisabled,
    isAddRecipeToQueue,
    isAddingRecipeToQueue,
    isRunDisabled,
    saveToolTip,
    runTooltip,
    handleUpdateRecipe,
    isRunInProgress,
    isSaveInProgress,
    recipe,
    scenarioData,
    handleSave,
    handleRun,
    isDeletableRecipe
  } = useCodeRecipeContext();

  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);
  const { canAccessRecipeTimeout, canViewCodeRecipeConditions } = useAccessControlContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpenLogs = searchParams.get(openLogsModal);

  const setSideComponent = useDrawerStore(sideComponentSetter);
  const nodes = useCanvasStore((state) => state.nodes);

  const dfsGroupNodes = useMemo(() => {
    return nodes?.filter(
      (node: $TSFixMe) => (node as $TSFixMe)?.type?.toLowerCase() === "dfsgroup"
    );
  }, [nodes]);

  const handleLogsClick = () => {
    setOpenLogs(true);
  };

  useEffect(() => {
    if (isOpenLogs) {
      handleLogsClick();
    }
  }, [isOpenLogs]);

  const validateName = useCallback(
    (name: string) => {
      const nameExist = dfsGroupNodes.some(
        (item: $TSFixMe) => item.name === name || item.displayName === name
      );
      const { error } = validateNameField({
        fieldName: name,
        fieldNameLabel: `recipe name`
      });
      return { nameExist, error };
    },
    [dfsGroupNodes]
  );

  const updateTransformName = useCallback(
    async (name: string) => {
      const cleanedName = name?.trim();
      const { error, nameExist } = validateName(cleanedName);

      if (error) {
        handleResponse({ errorMessage: error });
        setShouldResetName(Date.now());
      } else if (nameExist) {
        handleResponse({ errorMessage: "Recipe name already exists" });
        setShouldResetName(Date.now());
      } else if (recipe?.id) {
        handleUpdateRecipe({
          payload: { displayName: cleanedName },
          onError: () => setShouldResetName(Date.now()),
          onSuccess: () =>
            toastWrapper({ type: "success", content: "Recipe Name updated Successfully" })
        });
      }
    },
    [handleUpdateRecipe, recipe?.id, validateName]
  );

  const handleSaveConditions = async (updatedConditions: $TSFixMe) => {
    await handleUpdateRecipe({
      payload: {
        condition: {
          expression: updatedConditions
        }
      }
    });
  };

  const openConditionsSideBar = () => {
    const onClose = () => {
      setSideComponent({
        sideComponent: null,
        sideComponentProps: null
      });
    };
    setSideComponent({
      sideComponent: RecipeConditions,
      sideComponentProps: {
        isJobPath,
        jobId,
        jobRunId,
        recipe,
        conditions: recipe?.condition?.expression,
        projectId,
        scenarioId,
        setConditions: handleSaveConditions,
        title: "Conditions",
        onClose,
        onSubmitSuccess: () => {
          handleResponse({
            successMessage: `Conditions updated successfully.`
          });
          onClose();
        },
        isDefaultScenario
      }
    });
  };

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [projectId, scenarioId]);

  const onUpdateRecipeRunTimeout = (timeout: number) => {
    setIsRecipeUpdateInProgress(true);
    handleUpdateRecipe({
      payload: { timeout },
      onSuccess: () => {
        setIsRecipeUpdateInProgress(false);
        handleResponse({ successMessage: "Recipe Run Timeout updated successfully." });
      },
      onError: () => setIsRecipeUpdateInProgress(false)
    });
  };

  const handleCloseLogs = () => {
    setOpenLogs(false);
    setSearchParams({});
  };

  const queryClient = useQueryClient();
  const handleRecipeDelete = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
    isDeletableRecipe &&
      projectId &&
      recipe?.id &&
      deleteRecipeMutation.mutate(
        { projectId, recipeId: recipe?.id },
        {
          onSuccess: () => {
            setReloadTrigger();
            queryClient.invalidateQueries({
              queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvas, projectId, scenarioId],
              refetchType: "all"
            });
            onSuccess?.();
          }
        }
      );
  };

  const onBack = () => {
    if (isDeletableRecipe && !isDeleteRecipeSuccess) {
      handleRecipeDelete();
    }
    window.onpopstate = null;
  };

  useEffect(() => {
    window.onpopstate = (e) => {
      onProjectClick(e);
    };
  });

  const onProjectClick = (event: any) => {
    if (isDeletableRecipe && !isDeleteRecipeSuccess) {
      event.preventDefault();
      handleRecipeDelete({
        onSuccess: () => {
          navigate(`/projects/${projectId}/canvas`);
        }
      });
    }
    window.onpopstate = null;
  };

  const onDeleteSuccess = () => {
    setIsDeleteRecipeSuccess(true);
    handleBack();
  };

  return (
    <>
      <DeleteNodeModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteSuccess={onDeleteSuccess}
        nodeId={recipe?.id}
        nodeName={recipe?.displayName}
        nodeType="group"
        deleteNote={
          <div>
            <span>Note: </span>
            {psMessage}
          </div>
        }
      />
      {openLogs && (
        <RecipeLogsDrawer
          name={recipe?.displayName ?? ""}
          open={openLogs}
          projectId={projectId}
          scenarioId={scenarioId}
          jobRunId={jobRunId}
          groupId={recipe?.id}
          isJobPath={isJobPath}
          onClose={handleCloseLogs}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          ...(isJobPath
            ? {
                backNavAction: () => {
                  jobPathsSetter(jobPaths.slice(0, -1));
                  onBack();
                }
              }
            : {
                backNavAction: () => {
                  onBack();
                }
              }),
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              isJobPath={isJobPath}
              onProjectClick={onProjectClick}
              jobData={jobData}
              jobRunId={jobRunId}
              jobRunName={jobRunData?.entryDto?.runId}
              isDefaultScenario={isDefaultScenario}
              scenarioName={scenarioData?.name}
              recipeName={recipe?.displayName || "Recipe"}
              updateRecipeName={updateTransformName}
              shouldResetRecipeName={shouldResetName}
            />
          )
        }}
        subTopNavBarRightSection={{
          ...(!isJobPath
            ? {
                moreOptions: [
                  {
                    label: "Delete",
                    icon: <TrashIcon viewBox="0 0 20 20" />,
                    action: () => (recipe?.id ? handleOpenDeleteModal() : handleBack()),
                    isDisabled: !recipe?.id || !isDefaultScenario,
                    ...(!isDefaultScenario ? { tooltip: READONLY_ENTITY } : {})
                  },
                  ...(canAccessRecipeTimeout
                    ? [
                        {
                          label: `Timeout ${recipe?.timeout || 0}hr`,
                          icon: <TimeoutIcon viewBox="0 0 20 22" />,
                          disabled: !recipe?.id,
                          nestedComponent: (
                            <TimeoutTextField
                              value={recipe?.timeout || ""}
                              minValue={1}
                              onChange={onUpdateRecipeRunTimeout}
                              disabled={isRecipeUpdateInProgress}
                            />
                          )
                        }
                      ]
                    : [])
                ]
              }
            : {}),
          component: (
            <>
              {canViewCodeRecipeConditions && (
                <Button size="small" color="primary" onClick={openConditionsSideBar}>
                  Conditions
                </Button>
              )}

              {!isJobPath && (
                <>
                  <Tooltip title={isDefaultScenario ? saveToolTip || "" : READONLY_ENTITY}>
                    <span>
                      <Button
                        size="small"
                        color="primary"
                        onClick={handleSave}
                        disabled={isSaveDisabled || !isDefaultScenario}>
                        Save
                        {isSaveInProgress && <Spinner size={16} padding="0px 8px" />}
                      </Button>
                    </span>
                  </Tooltip>

                  <Tooltip
                    title={
                      runTooltip ||
                      "Please remember to save your changes. Unsaved modifications won't be considered in the recipe run"
                    }>
                    <span>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={handleRun}
                        disabled={isRunDisabled}
                        style={{ textTransform: "none" }}
                        startIcon={isAddRecipeToQueue ? <AddRunIcon /> : <RunIcon />}>
                        {isAddRecipeToQueue ? "Add to Queue" : "Run"}
                        {(isAddingRecipeToQueue || isRunInProgress) && (
                          <Spinner size={16} padding="0px 8px" />
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                </>
              )}

              <Tooltip title={!recipe?.id ? DISABLED_RECIPE_LOGS : ""}>
                <span>
                  <Button
                    size="small"
                    color="primary"
                    onClick={handleLogsClick}
                    disabled={!recipe?.id}>
                    <LogsNewIconV2
                      width="22"
                      height="22"
                      // New UX change
                      // The static value will be replaced with new theme at platform-level.
                      color={!recipe?.id ? "#4646b550" : "#4646b5"}
                    />
                  </Button>
                </span>
              </Tooltip>
            </>
          )
        }}
      />
    </>
  );
};
