import React, { useMemo, useState } from "react";

// MUI
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

// Icons
import { ConfigIcon } from "src/icons/ConfigIcon";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { Clear } from "src/icons/Clear";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { StatusBar } from "./StatusBar/StatusBar";

// Constants
import { EnvironmentsHelperText } from "../utils/Environments.constants";

import { Environment } from "../Environments";
import EnvironmentHeaderBreadcrumb from "./EnvironmentHeaderBreadcrumb";
import useUpdateEnvironment from "src/hooks/api/environments/useUpdateEnvironment";
import { handleResponse } from "src/utils/apiService";
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { validateNameField } from "src/utils/formFieldUtils";
import {
  getEnvironmentConfigSession,
  setEnvironmentConfigSession
} from "../utils/environments.helpers";

type Props = {
  environment: Environment;
  isLoading: boolean;
  isLaunching: boolean;
  isClearingCache: boolean;
  isEnvironmentsTypesFetching: boolean;
  isStopping: boolean;
  isSaving: boolean;
  isSaveActionDisabled: boolean;
  isUsageDisabled: boolean;
  checkIsDuplicate: (name: string) => boolean;
  onRelaunch: $TSFixMeFunction;
  onStopEnvironmentAction: $TSFixMeFunction;
  onSaveAction: $TSFixMeFunction;
  onConfigAction: $TSFixMeFunction;
  onLogsAction: $TSFixMeFunction;
  onClearCache: $TSFixMeFunction;
  onDelete: $TSFixMeFunction;
  readOnly: boolean;
};

const EnvironmentHeader = (props: Props) => {
  const {
    environment,
    isLoading,
    isLaunching,
    isClearingCache,
    isEnvironmentsTypesFetching,
    isStopping,
    isSaving,
    isSaveActionDisabled,
    isUsageDisabled,
    checkIsDuplicate,
    onRelaunch,
    onStopEnvironmentAction,
    onSaveAction,
    onConfigAction,
    onLogsAction,
    onClearCache,
    onDelete,
    readOnly
  } = props || {};

  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);

  const updateEnv = useUpdateEnvironment();

  const validateDatasourcetName = (value: $TSFixMe) => {
    const { isValid, error: errorMessage } = validateNameField({
      fieldName: value,
      fieldNameLabel: `environment name`
    });

    if (!isValid) {
      return errorMessage;
    }

    if (checkIsDuplicate(value)) {
      return "The Environment name already exist.";
    }

    return "";
  };

  const updateEnvironmentName = (name: string) => {
    const errorMessage = validateDatasourcetName(name);

    if (errorMessage) {
      handleResponse({ errorMessage });
      setShouldResetName(Date.now());
      return;
    }
    updateEnv.mutate(
      { ...environment, name: name },
      {
        onSuccess: () => {
          toastWrapper({
            type: ToastTypes.Success,
            content: "Environment Name updated successfully."
          });
          const thisValue = getEnvironmentConfigSession();
          setEnvironmentConfigSession({ ...thisValue, name });
        },
        onError: () => {
          setShouldResetName(Date.now());
        }
      }
    );
  };

  const moreOptions = useMemo(
    () => [
      {
        label: "Configs",
        icon: <ConfigIcon />,
        action: onConfigAction,
        isCloseMoreAction: true
      },
      {
        label: "Logs",
        icon: <LogsNewIconV2 />,
        isDisabled: isEnvironmentsTypesFetching,
        action: onLogsAction,
        isCloseMoreAction: true
      },
      {
        label: "Clear Cache",
        icon: <Clear width={20} height={20} />,
        isDisabled: isClearingCache,
        isLoading: isClearingCache,
        action: onClearCache
      },
      {
        label: "Delete",
        icon: <TrashIcon width={18} height={18} viewBox="0 0 20 20" />,
        isDisabled: isLaunching,
        tooltip: isLaunching ? EnvironmentsHelperText.DeleteInfo : "",
        action: onDelete,
        isCloseMoreAction: true
      }
    ],
    [isUsageDisabled, isEnvironmentsTypesFetching, isClearingCache, isLaunching]
  );

  return (
    <SubTopNavBarWrapper
      subTopNavBarLeftSection={{
        backNavPath: "/environments",
        component: (
          <EnvironmentHeaderBreadcrumb
            environmentName={environment.name}
            onEditEnvironmentName={updateEnvironmentName}
            shouldResetName={shouldResetName}
            readonly={readOnly}
          />
        )
      }}
      subTopNavBarRightSection={{
        moreOptions,
        component: (
          <>
            {isLoading ? (
              <CircularProgress size={24} style={{ marginRight: 20 }} />
            ) : (
              environment && (
                <StatusBar
                  env={environment}
                  onRelaunchEnv={onRelaunch}
                  stoppingEnvironments={isStopping ? [environment?.id] : []}
                  onStopEnv={onStopEnvironmentAction}
                  subtopBarStyles
                />
              )
            )}

            <Button
              color="primary"
              startIcon={isSaving ? <CircularProgress size={16} /> : undefined}
              onClick={onSaveAction}
              disabled={isSaveActionDisabled}
              data-testid="saveEnvironment">
              {EnvironmentsHelperText.Save}
            </Button>
          </>
        )
      }}
    />
  );
};

export default EnvironmentHeader;
