import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";
import { AI_GUIDE_MESSAGES_KEY } from "./useGetAIGuideMessages";

interface IVariables {
  threadId: string;
}

export const useDeleteAIGuideThread = (): UseMutationResult<
  unknown,
  unknown,
  IVariables,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ threadId }) => {
      const response = await api.fetchResponse(() => api.AiControllerApi.deleteThread1(threadId));
      queryClient.setQueryData([AI_GUIDE_MESSAGES_KEY, threadId!], []);
      return response;
    }
  });
};
