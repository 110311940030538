import React from "react";
import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";

import { AiGuideIcon } from "src/icons/NewUX";
import { Spinner } from "src/components";

interface IProps {
  onClick: () => void;
  isLoading: boolean;
}

const useStyles = makeStyles({
  spinner: {
    border: "2px solid #ff653d",
    borderRadius: "50%"
  }
});
const AIGuideBtn: React.FC<IProps> = (props) => {
  const { isLoading, onClick } = props;
  const classes = useStyles();

  return (
    <Tooltip title="RapidCanvas AI Guide" placement="top">
      <IconButton
        aria-label="ai-guide"
        test-id="ai-guide-btn"
        size="small"
        style={{ padding: 0 }}
        onClick={() => onClick()}>
        {isLoading ? (
          <Spinner size={20} padding="10px" className={classes.spinner} />
        ) : (
          <Typography color="primary">
            <AiGuideIcon />
          </Typography>
        )}
      </IconButton>
    </Tooltip>
  );
};

export default AIGuideBtn;
