/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ArtifactListDto } from '../Models';
// @ts-ignore
import { ErrorMessage } from '../Models';
// @ts-ignore
import { SignedUrlResult } from '../Models';
/**
 * ArtifactsControllerApi - axios parameter creator
 * @export
 */
export const ArtifactsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmptyArtifact: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createEmptyArtifact', 'name', name)
            const localVarPath = `/v2/artifacts/empty-folder/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArtifact: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteArtifact', 'name', name)
            const localVarPath = `/v2/artifacts/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArtifactFile: async (name: string, path: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteArtifactFile', 'name', name)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('deleteArtifactFile', 'path', path)
            const localVarPath = `/v2/artifacts/contents/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtifacts: async (name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/artifacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {boolean} [getBinary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadSignedUrl1: async (path: string, getBinary?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getDownloadSignedUrl1', 'path', path)
            const localVarPath = `/v2/artifacts/download-signed-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (getBinary !== undefined) {
                localVarQueryParameter['getBinary'] = getBinary;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrl3: async (path: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getUploadSignedUrl3', 'path', path)
            const localVarPath = `/v2/artifacts/signed-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArtifactsControllerApi - functional programming interface
 * @export
 */
export const ArtifactsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArtifactsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmptyArtifact(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmptyArtifact(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArtifactsControllerApi.createEmptyArtifact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteArtifact(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArtifact(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArtifactsControllerApi.deleteArtifact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteArtifactFile(name: string, path: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArtifactFile(name, path, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArtifactsControllerApi.deleteArtifactFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArtifacts(name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArtifactListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArtifacts(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArtifactsControllerApi.getArtifacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} path 
         * @param {boolean} [getBinary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadSignedUrl1(path: string, getBinary?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadSignedUrl1(path, getBinary, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArtifactsControllerApi.getDownloadSignedUrl1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadSignedUrl3(path: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadSignedUrl3(path, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArtifactsControllerApi.getUploadSignedUrl3']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ArtifactsControllerApi - factory interface
 * @export
 */
export const ArtifactsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArtifactsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmptyArtifact(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.createEmptyArtifact(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArtifact(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteArtifact(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArtifactFile(name: string, path: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteArtifactFile(name, path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtifacts(name?: string, options?: any): AxiosPromise<ArtifactListDto> {
            return localVarFp.getArtifacts(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} path 
         * @param {boolean} [getBinary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadSignedUrl1(path: string, getBinary?: boolean, options?: any): AxiosPromise<SignedUrlResult> {
            return localVarFp.getDownloadSignedUrl1(path, getBinary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrl3(path: string, options?: any): AxiosPromise<SignedUrlResult> {
            return localVarFp.getUploadSignedUrl3(path, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArtifactsControllerApi - interface
 * @export
 * @interface ArtifactsControllerApi
 */
export interface ArtifactsControllerApiInterface {
    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsControllerApiInterface
     */
    createEmptyArtifact(name: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsControllerApiInterface
     */
    deleteArtifact(name: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} name 
     * @param {string} path 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsControllerApiInterface
     */
    deleteArtifactFile(name: string, path: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsControllerApiInterface
     */
    getArtifacts(name?: string, options?: RawAxiosRequestConfig): AxiosPromise<ArtifactListDto>;

    /**
     * 
     * @param {string} path 
     * @param {boolean} [getBinary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsControllerApiInterface
     */
    getDownloadSignedUrl1(path: string, getBinary?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<SignedUrlResult>;

    /**
     * 
     * @param {string} path 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsControllerApiInterface
     */
    getUploadSignedUrl3(path: string, options?: RawAxiosRequestConfig): AxiosPromise<SignedUrlResult>;

}

/**
 * ArtifactsControllerApi - object-oriented interface
 * @export
 * @class ArtifactsControllerApi
 * @extends {BaseAPI}
 */
export class ArtifactsControllerApi extends BaseAPI implements ArtifactsControllerApiInterface {
    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsControllerApi
     */
    public createEmptyArtifact(name: string, options?: RawAxiosRequestConfig) {
        return ArtifactsControllerApiFp(this.configuration).createEmptyArtifact(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsControllerApi
     */
    public deleteArtifact(name: string, options?: RawAxiosRequestConfig) {
        return ArtifactsControllerApiFp(this.configuration).deleteArtifact(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} path 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsControllerApi
     */
    public deleteArtifactFile(name: string, path: string, options?: RawAxiosRequestConfig) {
        return ArtifactsControllerApiFp(this.configuration).deleteArtifactFile(name, path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsControllerApi
     */
    public getArtifacts(name?: string, options?: RawAxiosRequestConfig) {
        return ArtifactsControllerApiFp(this.configuration).getArtifacts(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} path 
     * @param {boolean} [getBinary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsControllerApi
     */
    public getDownloadSignedUrl1(path: string, getBinary?: boolean, options?: RawAxiosRequestConfig) {
        return ArtifactsControllerApiFp(this.configuration).getDownloadSignedUrl1(path, getBinary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} path 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArtifactsControllerApi
     */
    public getUploadSignedUrl3(path: string, options?: RawAxiosRequestConfig) {
        return ArtifactsControllerApiFp(this.configuration).getUploadSignedUrl3(path, options).then((request) => request(this.axios, this.basePath));
    }
}

