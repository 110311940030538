import React, { useState, useMemo } from "react";
import { Box, Grid, IconButton, Tooltip, makeStyles } from "@material-ui/core";

import { useLocation } from "react-router";
import { Clear } from "@material-ui/icons";

import { TabPanel, Tabs, Tab } from "src/components";
import { PreviewIconNew } from "src/icons/PreviewIconNew";
import { useAutoMLRecipeContext } from "../../AutoMLRecipeContext/useAutoMLRecipeContext";
import ViewDataData from "src/pages/ViewData/ViewDataData/ViewDataData";
import { READONLY_ENTITY } from "src/constants";

const useStyles: any = makeStyles(() => ({
  recipeContainer: {
    flexWrap: "nowrap",
    width: "100%",
    paddingLeft: "16px"
  },
  tabRoot: {
    minHeight: "44px",
    flex: 1,
    boxShadow: "none",
    borderRadius: "4px 4px 0px 0px",
    borderBottom: "solid rgba(53,96,121,0.26)",
    borderWidth: "0px 0px 1px 0px"
  },
  inputTabWrap: {
    flexWrap: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  recipeBodyWrap: {
    height: "calc(100vh - 201px)",
    borderRadius: "6px"
  },
  viewDataContentContainer: {
    overflow: "auto",
    height: "100%",
    borderRadius: "0px 0px 4px 4px"
  },
  tabWrap: {
    padding: "0px 0px 8px 16px",
    borderBottom: "1px solid #35607942"
  }
}));

const AutoMLRecipeTabContainer = ({ isDefaultScenario }: { isDefaultScenario: boolean }) => {
  const classes = useStyles();
  const {
    inputDatasets,
    setInputDatasets,
    entityFeaturesMap,
    isGetEntityFeaturesLoading,
    isEntityLoading,
    currentEntityData,
    getDatasetCustomColumnsData
  } = useAutoMLRecipeContext();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const [value, setValue] = useState<string>("");

  React.useEffect(() => {
    if ((inputDatasets || [])?.length > 0) {
      setValue(inputDatasets[0].id);
    }
  }, [inputDatasets]);

  return (
    <Grid container direction="column" className={classes.recipeContainer}>
      <Grid container direction="column" wrap="nowrap" className={classes.tabWrap}>
        <Tabs value={value} onChange={setValue}>
          {inputDatasets?.map(({ displayName, name, id }) => (
            <Tab
              key={name}
              label={displayName}
              value={id}
              icon={<PreviewIconNew />}
              clearIcon={
                !isJobPath && (
                  <Tooltip title={!isDefaultScenario ? "" : READONLY_ENTITY}>
                    <div>
                      <IconButton
                        size="small"
                        disabled={!isDefaultScenario}
                        onClick={(e) => {
                          e.stopPropagation();
                          const updatedDatasets =
                            inputDatasets?.filter((dataset) => dataset.id !== id) || [];
                          setInputDatasets(updatedDatasets);
                        }}>
                        <Clear style={{ fontSize: 14 }} />
                      </IconButton>
                    </div>
                  </Tooltip>
                )
              }
            />
          ))}
        </Tabs>
      </Grid>
      <Box p="0px 12px">
        <Grid className={classes.recipeBodyWrap}>
          {inputDatasets?.map((dataset: $TSFixMe, index: $TSFixMe) => {
            const entityFeatures = entityFeaturesMap?.[dataset.name];
            return (
              <TabPanel key={index} value={value} index={dataset.id} style={{ height: "100%" }}>
                {dataset.id === value ? (
                  <Grid id="viewDataContainer" className={classes.viewDataContentContainer}>
                    <ViewDataData
                      isLoading={isEntityLoading || isGetEntityFeaturesLoading}
                      hideCount={false}
                      showSample
                      responseData={currentEntityData || { columns: [], rows: [] }}
                      entityFeatures={entityFeatures}
                      onColumnChange={(columnNames: string[]) =>
                        getDatasetCustomColumnsData?.({
                          columnNames
                        })
                      }
                      maxHeight="calc(100vh - 243px)"
                    />
                  </Grid>
                ) : null}
              </TabPanel>
            );
          })}
        </Grid>
      </Box>
    </Grid>
  );
};

export default React.memo(AutoMLRecipeTabContainer);
