import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress
} from "@material-ui/core";
import shallow from "zustand/shallow";

import styles from "./AddScenarioVariables.module.scss";
import { handleResponse, postAPIWithRethrow, putAPIWithRethrow } from "../../utils/apiService";
import { useScenariosStore } from "../../store/store";
import { Field, Spinner } from "../../components";
import { Delete } from "../../icons/Delete";
import { Add } from "@material-ui/icons";
import { generatePath, useNavigate } from "react-router-dom";
import { MAX_FIELD_VALUE_LENGTH } from "../Projects/common/RecipeConditions/Rule";
import { WebPaths } from "src/routing/routes";
import { useGetGlobalVariables } from "src/hooks/api";

type OwnProps = {
  projectId: string;
  scenarioName?: string;
  onAfterSubmit: $TSFixMeFunction;
  isEditing?: boolean;
  initialScenario?: $TSFixMe;
  scenarioVariables?: $TSFixMe;
  segmentIds: $TSFixMe;
  sharedVariables: $TSFixMe;
  setSharedVariables: $TSFixMe;
};

const AddScenarioVariables = ({
  projectId,
  scenarioName,
  onAfterSubmit,
  isEditing,
  initialScenario,
  scenarioVariables,
  segmentIds,
  sharedVariables,
  setSharedVariables
}: OwnProps) => {
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);

  const [setIsScenariosLoading, scenarios, setScenarios] = useScenariosStore(
    useCallback((state) => [state.setIsScenariosLoading, state.scenarios, state.setScenarios], []),
    shallow
  );

  const { isLoading, data: globalVariables } = useGetGlobalVariables({
    projectId
  });

  const onSubmit = useCallback(async () => {
    setIsSaving(() => true);

    const url = "/v2/scenarios";
    const parsedSharedVariables = sharedVariables.reduce((acc: $TSFixMe, variable: $TSFixMe) => {
      acc[variable.key] = variable.value;
      return acc;
    }, {});
    const saveInformation = async () =>
      isEditing
        ? await putAPIWithRethrow(url, {
            ...initialScenario,
            name: scenarioName,
            segmentIds,
            sharedVariables: parsedSharedVariables
          })
        : // // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
          await postAPIWithRethrow(url, {
            name: scenarioName,
            description: "",
            projectId,
            segmentIds,
            variables: scenarioVariables,
            sharedVariables: parsedSharedVariables
          });

    setIsScenariosLoading(true);
    try {
      const response = await saveInformation();
      isEditing
        ? setScenarios(scenarios.map((curr: any) => (curr.id === response?.id ? response : curr)))
        : setScenarios([...scenarios, response]);

      handleResponse({ successMessage: `Scenario variables saved successfully.` });

      const path = generatePath(WebPaths.EditScenarios, {
        projectId,
        scenarioId: response?.id
      });

      response && !isEditing && navigate(path);
    } catch (error) {
      console.error({ error });
    } finally {
      setIsSaving(() => false);
    }

    setIsScenariosLoading(false);
  }, [
    isEditing,
    scenarioName,
    projectId,
    setIsScenariosLoading,
    scenarios,
    setScenarios,
    sharedVariables
  ]);

  const handleChange = (e: $TSFixMe, index: number) => {
    const newSharedVariables = [...sharedVariables];
    newSharedVariables[index][e.target.name] = e.target.value;
    setSharedVariables(newSharedVariables);
  };

  const filteredGlobalVariables = React.useMemo(() => {
    if (!globalVariables) return [];
    const sharedVariableKeys = sharedVariables
      ?.slice(0, -1)
      .map((variable: $TSFixMe) => variable.key);
    return globalVariables.filter(
      (variable: $TSFixMe) => !sharedVariableKeys.includes(variable?.name)
    );
  }, [globalVariables, sharedVariables]);

  const onAddVariable = () => {
    const sharedVariableKeys = sharedVariables.map((variable: $TSFixMe) => variable.key);
    const availableGlobalVariables = globalVariables.filter(
      (variable: $TSFixMe) => !sharedVariableKeys.includes(variable?.name)
    );
    setSharedVariables([
      ...sharedVariables,
      { key: availableGlobalVariables?.[0]?.name || "", value: "" }
    ]);
  };

  const onDelete = (index: number) => {
    const newSharedVariables = [...sharedVariables];
    newSharedVariables.splice(index, 1);
    setSharedVariables(newSharedVariables);
  };

  const isDisabled = useMemo(() => {
    return sharedVariables.reduce(
      (acc: boolean, variable: $TSFixMe) =>
        acc ||
        (variable as $TSFixMe).value === "" ||
        variable.value.length > MAX_FIELD_VALUE_LENGTH,
      false
    );
  }, [sharedVariables]);

  useEffect(() => {
    if (!!initialScenario?.sharedVariables) {
      const newSharedVariables = Object.keys(initialScenario?.sharedVariables).map((item) => ({
        key: item,
        value: initialScenario?.sharedVariables[item],
        existing: true
      }));
      setSharedVariables(newSharedVariables);
    }
  }, [initialScenario]);

  return isLoading ? (
    <Spinner />
  ) : (
    <Grid container>
      <Grid item xs={4}>
        <div className={styles.maxWidthContainer}>
          <Box display="flex">
            <Typography variant="body2">
              Set values of Global Variables which will be used during scenario execution
            </Typography>
            {sharedVariables.length > 0 && (
              <Button
                style={{ minWidth: 138, fontSize: 12 }}
                onClick={onAddVariable}
                color="primary"
                variant="outlined"
                disabled={filteredGlobalVariables?.length === 1}>
                <Add fontSize="small" /> ADD VARIABLE
              </Button>
            )}
          </Box>
          {sharedVariables.length === 0 && (
            <div className={styles.addVariable}>
              {globalVariables.length > 0 ? (
                <Button
                  onClick={onAddVariable}
                  className={styles.actionButton}
                  color="primary"
                  variant="outlined">
                  ADD VARIABLE
                </Button>
              ) : (
                <Typography variant="body2">No Global Variables present in this project</Typography>
              )}
            </div>
          )}
          {sharedVariables.length > 0 && (
            <div className={styles.inputsContainer}>
              {sharedVariables.map((variable: $TSFixMe, index: number) => {
                const isLastVariable = index === sharedVariables.length - 1;
                const updatedGlobalVariables = isLastVariable
                  ? filteredGlobalVariables
                  : globalVariables;

                const isValueInvalid = !!variable.value && variable.value?.length > 255;
                return (
                  <div key={index} className={styles.inputRow}>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="solutionId">Key</InputLabel>
                      <Select
                        id="key"
                        name="key"
                        label="Key"
                        defaultValue={(variable as $TSFixMe).key}
                        value={(variable as $TSFixMe).key}
                        onChange={(e) => handleChange(e, index)}
                        disabled={!isLastVariable || variable?.existing}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}>
                        {updatedGlobalVariables.map((item: $TSFixMe) => (
                          <MenuItem key={item.name} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Field
                      id="value"
                      label="Value"
                      error={isValueInvalid}
                      value={(variable as $TSFixMe).value}
                      onChange={(e) => handleChange(e, index)}
                      // error={nameError.length > 1}
                      helperText={isValueInvalid ? "Maximum allowed characters: 255" : ""}
                      variant="outlined"
                    />
                    <Button
                      className={styles.deleteButton}
                      color="primary"
                      size="small"
                      onClick={() => onDelete(index)}>
                      <Delete />
                    </Button>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Grid>

      {globalVariables.length !== 0 && (
        <Grid item xs={8}>
          <Grid container justifyContent="flex-end">
            <Button disabled={isSaving} color="primary" onClick={onAfterSubmit}>
              Cancel
            </Button>
            <Button
              disabled={isSaving || isDisabled}
              onClick={onSubmit}
              style={{ marginLeft: 16 }}
              color="primary"
              variant="contained">
              {isSaving ? <CircularProgress size={20} /> : "Save"}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AddScenarioVariables;
