/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateEntityDto } from '../Models';
// @ts-ignore
import { Data } from '../Models';
// @ts-ignore
import { DownloadEntityDto } from '../Models';
// @ts-ignore
import { EntityAuditHistoryDto } from '../Models';
// @ts-ignore
import { EntityDataDto } from '../Models';
// @ts-ignore
import { EntityDataRequestDto } from '../Models';
// @ts-ignore
import { EntityDetailsDto } from '../Models';
// @ts-ignore
import { EntityDto } from '../Models';
// @ts-ignore
import { EntityEDAChartsDto } from '../Models';
// @ts-ignore
import { EntityEDAChartsResult } from '../Models';
// @ts-ignore
import { EntityEDADto } from '../Models';
// @ts-ignore
import { EntityEDAResult } from '../Models';
// @ts-ignore
import { ErrorMessage } from '../Models';
// @ts-ignore
import { FeatureStatsEntityDto } from '../Models';
// @ts-ignore
import { GenerateStatsConfigDto } from '../Models';
// @ts-ignore
import { SignedUrlResult } from '../Models';
// @ts-ignore
import { UpdateEntityDto } from '../Models';
/**
 * EntityControllerApi - axios parameter creator
 * @export
 */
export const EntityControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEntityDto} createEntityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEntityToProject: async (createEntityDto: CreateEntityDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEntityDto' is not null or undefined
            assertParamExists('addEntityToProject', 'createEntityDto', createEntityDto)
            const localVarPath = `/v2/entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEntityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('buildEntity', 'id', id)
            const localVarPath = `/v2/entities/{id}/rebuild-entity`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAuditHistory: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAuditHistory', 'id', id)
            const localVarPath = `/v2/entities/{id}/audit-history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [projectId] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEntities: async (id?: string, name?: string, projectId?: string, scenarioId?: string, projectRunEntryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }

            if (projectRunEntryId !== undefined) {
                localVarQueryParameter['projectRunEntryId'] = projectRunEntryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [parentType] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStats: async (id: string, parentType?: string, parentId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findStats', 'id', id)
            const localVarPath = `/v2/entities/{id}/stats`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentType !== undefined) {
                localVarQueryParameter['parentType'] = parentType;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStats1: async (id: string, scenarioId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findStats1', 'id', id)
            const localVarPath = `/v2/entities/{id}/stats-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {GenerateStatsConfigDto} [generateStatsConfigDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateStats: async (id: string, generateStatsConfigDto?: GenerateStatsConfigDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('generateStats', 'id', id)
            const localVarPath = `/v2/entities/{id}/stats`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateStatsConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getData1: async (id: string, limit?: number, scenarioId?: string, projectRunEntryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getData1', 'id', id)
            const localVarPath = `/v2/entities/{id}/data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }

            if (projectRunEntryId !== undefined) {
                localVarQueryParameter['projectRunEntryId'] = projectRunEntryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DownloadEntityDto} downloadEntityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrl: async (id: string, downloadEntityDto: DownloadEntityDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDownloadUrl', 'id', id)
            // verify required parameter 'downloadEntityDto' is not null or undefined
            assertParamExists('getDownloadUrl', 'downloadEntityDto', downloadEntityDto)
            const localVarPath = `/v2/entities/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadEntityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {string} [dataFileId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDownloadUrls: async (id: string, scenarioId?: string, dataFileId?: string, projectRunEntryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDownloadUrls', 'id', id)
            const localVarPath = `/v2/entities/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }

            if (dataFileId !== undefined) {
                localVarQueryParameter['dataFileId'] = dataFileId;
            }

            if (projectRunEntryId !== undefined) {
                localVarQueryParameter['projectRunEntryId'] = projectRunEntryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {EntityDataRequestDto} entityDataRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityData: async (id: string, entityDataRequestDto: EntityDataRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEntityData', 'id', id)
            // verify required parameter 'entityDataRequestDto' is not null or undefined
            assertParamExists('getEntityData', 'entityDataRequestDto', entityDataRequestDto)
            const localVarPath = `/v2/entities/{id}/data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityDataRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {string} [runEntityName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityDetails: async (id: string, scenarioId?: string, projectRunEntryId?: string, runEntityName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEntityDetails', 'id', id)
            const localVarPath = `/v2/entities/{id}/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }

            if (projectRunEntryId !== undefined) {
                localVarQueryParameter['projectRunEntryId'] = projectRunEntryId;
            }

            if (runEntityName !== undefined) {
                localVarQueryParameter['runEntityName'] = runEntityName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {EntityEDADto} entityEDADto 
         * @param {boolean} [async] 
         * @param {boolean} [triggerOnError] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityEDA: async (id: string, entityEDADto: EntityEDADto, async?: boolean, triggerOnError?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEntityEDA', 'id', id)
            // verify required parameter 'entityEDADto' is not null or undefined
            assertParamExists('getEntityEDA', 'entityEDADto', entityEDADto)
            const localVarPath = `/v2/entities/{id}/eda`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (async !== undefined) {
                localVarQueryParameter['async'] = async;
            }

            if (triggerOnError !== undefined) {
                localVarQueryParameter['triggerOnError'] = triggerOnError;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityEDADto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {EntityEDAChartsDto} entityEDAChartsDto 
         * @param {boolean} [async] 
         * @param {boolean} [triggerOnError] 
         * @param {boolean} [generateAdditionalCharts] 
         * @param {boolean} [readOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityEDACharts: async (id: string, entityEDAChartsDto: EntityEDAChartsDto, async?: boolean, triggerOnError?: boolean, generateAdditionalCharts?: boolean, readOnly?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEntityEDACharts', 'id', id)
            // verify required parameter 'entityEDAChartsDto' is not null or undefined
            assertParamExists('getEntityEDACharts', 'entityEDAChartsDto', entityEDAChartsDto)
            const localVarPath = `/v2/entities/{id}/eda-charts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (async !== undefined) {
                localVarQueryParameter['async'] = async;
            }

            if (triggerOnError !== undefined) {
                localVarQueryParameter['triggerOnError'] = triggerOnError;
            }

            if (generateAdditionalCharts !== undefined) {
                localVarQueryParameter['generateAdditionalCharts'] = generateAdditionalCharts;
            }

            if (readOnly !== undefined) {
                localVarQueryParameter['readOnly'] = readOnly;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityEDAChartsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchemaDownloadUrl: async (id: string, scenarioId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSchemaDownloadUrl', 'id', id)
            const localVarPath = `/v2/entities/{id}/download-schema`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [parentType] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasPendingStats: async (id: string, parentType?: string, parentId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('hasPendingStats', 'id', id)
            const localVarPath = `/v2/entities/{id}/pending-stats`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentType !== undefined) {
                localVarQueryParameter['parentType'] = parentType;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} fileName 
         * @param {string} [existingFilePath] 
         * @param {boolean} [isAppend] 
         * @param {boolean} [deleteOld] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processUploadedFile1: async (id: string, fileName: Array<string>, existingFilePath?: string, isAppend?: boolean, deleteOld?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('processUploadedFile1', 'id', id)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('processUploadedFile1', 'fileName', fileName)
            const localVarPath = `/v2/entities/{id}/process-uploaded-file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fileName) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (existingFilePath !== undefined) {
                localVarQueryParameter['existingFilePath'] = existingFilePath;
            }

            if (isAppend !== undefined) {
                localVarQueryParameter['isAppend'] = isAppend;
            }

            if (deleteOld !== undefined) {
                localVarQueryParameter['deleteOld'] = deleteOld;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reloadData: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reloadData', 'id', id)
            const localVarPath = `/v2/entities/{id}/reload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sync: async (id: string, scenarioId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sync', 'id', id)
            const localVarPath = `/v2/entities/{id}/sync`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateEntityDto} updateEntityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntity: async (updateEntityDto: UpdateEntityDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateEntityDto' is not null or undefined
            assertParamExists('updateEntity', 'updateEntityDto', updateEntityDto)
            const localVarPath = `/v2/entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEntityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileUrl: async (id: string, fileName: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadFileUrl', 'id', id)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('uploadFileUrl', 'fileName', fileName)
            const localVarPath = `/v2/entities/{id}/generate-file-upload-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fileName) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EntityControllerApi - functional programming interface
 * @export
 */
export const EntityControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EntityControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEntityDto} createEntityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEntityToProject(createEntityDto: CreateEntityDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEntityToProject(createEntityDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.addEntityToProject']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buildEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buildEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.buildEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAuditHistory(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityAuditHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAuditHistory(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.findAuditHistory']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [projectId] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEntities(id?: string, name?: string, projectId?: string, scenarioId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEntities(id, name, projectId, scenarioId, projectRunEntryId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.findEntities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [parentType] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findStats(id: string, parentType?: string, parentId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureStatsEntityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findStats(id, parentType, parentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.findStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findStats1(id: string, scenarioId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findStats1(id, scenarioId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.findStats1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {GenerateStatsConfigDto} [generateStatsConfigDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateStats(id: string, generateStatsConfigDto?: GenerateStatsConfigDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateStats(id, generateStatsConfigDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.generateStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getData1(id: string, limit?: number, scenarioId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Data>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getData1(id, limit, scenarioId, projectRunEntryId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.getData1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {DownloadEntityDto} downloadEntityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadUrl(id: string, downloadEntityDto: DownloadEntityDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadUrl(id, downloadEntityDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.getDownloadUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {string} [dataFileId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDownloadUrls(id: string, scenarioId?: string, dataFileId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadUrls(id, scenarioId, dataFileId, projectRunEntryId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.getDownloadUrls']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {EntityDataRequestDto} entityDataRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityData(id: string, entityDataRequestDto: EntityDataRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityData(id, entityDataRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.getEntityData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {string} [runEntityName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityDetails(id: string, scenarioId?: string, projectRunEntryId?: string, runEntityName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityDetails(id, scenarioId, projectRunEntryId, runEntityName, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.getEntityDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {EntityEDADto} entityEDADto 
         * @param {boolean} [async] 
         * @param {boolean} [triggerOnError] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityEDA(id: string, entityEDADto: EntityEDADto, async?: boolean, triggerOnError?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityEDAResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityEDA(id, entityEDADto, async, triggerOnError, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.getEntityEDA']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {EntityEDAChartsDto} entityEDAChartsDto 
         * @param {boolean} [async] 
         * @param {boolean} [triggerOnError] 
         * @param {boolean} [generateAdditionalCharts] 
         * @param {boolean} [readOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityEDACharts(id: string, entityEDAChartsDto: EntityEDAChartsDto, async?: boolean, triggerOnError?: boolean, generateAdditionalCharts?: boolean, readOnly?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityEDAChartsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityEDACharts(id, entityEDAChartsDto, async, triggerOnError, generateAdditionalCharts, readOnly, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.getEntityEDACharts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchemaDownloadUrl(id: string, scenarioId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchemaDownloadUrl(id, scenarioId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.getSchemaDownloadUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [parentType] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasPendingStats(id: string, parentType?: string, parentId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasPendingStats(id, parentType, parentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.hasPendingStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} fileName 
         * @param {string} [existingFilePath] 
         * @param {boolean} [isAppend] 
         * @param {boolean} [deleteOld] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processUploadedFile1(id: string, fileName: Array<string>, existingFilePath?: string, isAppend?: boolean, deleteOld?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processUploadedFile1(id, fileName, existingFilePath, isAppend, deleteOld, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.processUploadedFile1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reloadData(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reloadData(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.reloadData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sync(id: string, scenarioId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sync(id, scenarioId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.sync']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateEntityDto} updateEntityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEntity(updateEntityDto: UpdateEntityDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEntity(updateEntityDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.updateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFileUrl(id: string, fileName: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SignedUrlResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFileUrl(id, fileName, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EntityControllerApi.uploadFileUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EntityControllerApi - factory interface
 * @export
 */
export const EntityControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EntityControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEntityDto} createEntityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEntityToProject(createEntityDto: CreateEntityDto, options?: any): AxiosPromise<EntityDto> {
            return localVarFp.addEntityToProject(createEntityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildEntity(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.buildEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAuditHistory(id: string, options?: any): AxiosPromise<Array<EntityAuditHistoryDto>> {
            return localVarFp.findAuditHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [projectId] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEntities(id?: string, name?: string, projectId?: string, scenarioId?: string, projectRunEntryId?: string, options?: any): AxiosPromise<Array<EntityDto>> {
            return localVarFp.findEntities(id, name, projectId, scenarioId, projectRunEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [parentType] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStats(id: string, parentType?: string, parentId?: string, options?: any): AxiosPromise<Array<FeatureStatsEntityDto>> {
            return localVarFp.findStats(id, parentType, parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStats1(id: string, scenarioId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.findStats1(id, scenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {GenerateStatsConfigDto} [generateStatsConfigDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateStats(id: string, generateStatsConfigDto?: GenerateStatsConfigDto, options?: any): AxiosPromise<void> {
            return localVarFp.generateStats(id, generateStatsConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getData1(id: string, limit?: number, scenarioId?: string, projectRunEntryId?: string, options?: any): AxiosPromise<Data> {
            return localVarFp.getData1(id, limit, scenarioId, projectRunEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {DownloadEntityDto} downloadEntityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadUrl(id: string, downloadEntityDto: DownloadEntityDto, options?: any): AxiosPromise<string> {
            return localVarFp.getDownloadUrl(id, downloadEntityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {string} [dataFileId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDownloadUrls(id: string, scenarioId?: string, dataFileId?: string, projectRunEntryId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getDownloadUrls(id, scenarioId, dataFileId, projectRunEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {EntityDataRequestDto} entityDataRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityData(id: string, entityDataRequestDto: EntityDataRequestDto, options?: any): AxiosPromise<EntityDataDto> {
            return localVarFp.getEntityData(id, entityDataRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunEntryId] 
         * @param {string} [runEntityName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityDetails(id: string, scenarioId?: string, projectRunEntryId?: string, runEntityName?: string, options?: any): AxiosPromise<EntityDetailsDto> {
            return localVarFp.getEntityDetails(id, scenarioId, projectRunEntryId, runEntityName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {EntityEDADto} entityEDADto 
         * @param {boolean} [async] 
         * @param {boolean} [triggerOnError] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityEDA(id: string, entityEDADto: EntityEDADto, async?: boolean, triggerOnError?: boolean, options?: any): AxiosPromise<EntityEDAResult> {
            return localVarFp.getEntityEDA(id, entityEDADto, async, triggerOnError, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {EntityEDAChartsDto} entityEDAChartsDto 
         * @param {boolean} [async] 
         * @param {boolean} [triggerOnError] 
         * @param {boolean} [generateAdditionalCharts] 
         * @param {boolean} [readOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityEDACharts(id: string, entityEDAChartsDto: EntityEDAChartsDto, async?: boolean, triggerOnError?: boolean, generateAdditionalCharts?: boolean, readOnly?: boolean, options?: any): AxiosPromise<EntityEDAChartsResult> {
            return localVarFp.getEntityEDACharts(id, entityEDAChartsDto, async, triggerOnError, generateAdditionalCharts, readOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchemaDownloadUrl(id: string, scenarioId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getSchemaDownloadUrl(id, scenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [parentType] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasPendingStats(id: string, parentType?: string, parentId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.hasPendingStats(id, parentType, parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} fileName 
         * @param {string} [existingFilePath] 
         * @param {boolean} [isAppend] 
         * @param {boolean} [deleteOld] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processUploadedFile1(id: string, fileName: Array<string>, existingFilePath?: string, isAppend?: boolean, deleteOld?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.processUploadedFile1(id, fileName, existingFilePath, isAppend, deleteOld, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reloadData(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.reloadData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sync(id: string, scenarioId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.sync(id, scenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateEntityDto} updateEntityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntity(updateEntityDto: UpdateEntityDto, options?: any): AxiosPromise<EntityDto> {
            return localVarFp.updateEntity(updateEntityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileUrl(id: string, fileName: Array<string>, options?: any): AxiosPromise<Array<SignedUrlResult>> {
            return localVarFp.uploadFileUrl(id, fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EntityControllerApi - interface
 * @export
 * @interface EntityControllerApi
 */
export interface EntityControllerApiInterface {
    /**
     * 
     * @param {CreateEntityDto} createEntityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    addEntityToProject(createEntityDto: CreateEntityDto, options?: RawAxiosRequestConfig): AxiosPromise<EntityDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    buildEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    findAuditHistory(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityAuditHistoryDto>>;

    /**
     * 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [projectId] 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    findEntities(id?: string, name?: string, projectId?: string, scenarioId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityDto>>;

    /**
     * 
     * @param {string} id 
     * @param {string} [parentType] 
     * @param {string} [parentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    findStats(id: string, parentType?: string, parentId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<FeatureStatsEntityDto>>;

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    findStats1(id: string, scenarioId?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {GenerateStatsConfigDto} [generateStatsConfigDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    generateStats(id: string, generateStatsConfigDto?: GenerateStatsConfigDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {number} [limit] 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    getData1(id: string, limit?: number, scenarioId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Data>;

    /**
     * 
     * @param {string} id 
     * @param {DownloadEntityDto} downloadEntityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    getDownloadUrl(id: string, downloadEntityDto: DownloadEntityDto, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {string} [dataFileId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    getDownloadUrls(id: string, scenarioId?: string, dataFileId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {EntityDataRequestDto} entityDataRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    getEntityData(id: string, entityDataRequestDto: EntityDataRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<EntityDataDto>;

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunEntryId] 
     * @param {string} [runEntityName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    getEntityDetails(id: string, scenarioId?: string, projectRunEntryId?: string, runEntityName?: string, options?: RawAxiosRequestConfig): AxiosPromise<EntityDetailsDto>;

    /**
     * 
     * @param {string} id 
     * @param {EntityEDADto} entityEDADto 
     * @param {boolean} [async] 
     * @param {boolean} [triggerOnError] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    getEntityEDA(id: string, entityEDADto: EntityEDADto, async?: boolean, triggerOnError?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<EntityEDAResult>;

    /**
     * 
     * @param {string} id 
     * @param {EntityEDAChartsDto} entityEDAChartsDto 
     * @param {boolean} [async] 
     * @param {boolean} [triggerOnError] 
     * @param {boolean} [generateAdditionalCharts] 
     * @param {boolean} [readOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    getEntityEDACharts(id: string, entityEDAChartsDto: EntityEDAChartsDto, async?: boolean, triggerOnError?: boolean, generateAdditionalCharts?: boolean, readOnly?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<EntityEDAChartsResult>;

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    getSchemaDownloadUrl(id: string, scenarioId?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {string} [parentType] 
     * @param {string} [parentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    hasPendingStats(id: string, parentType?: string, parentId?: string, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} id 
     * @param {Array<string>} fileName 
     * @param {string} [existingFilePath] 
     * @param {boolean} [isAppend] 
     * @param {boolean} [deleteOld] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    processUploadedFile1(id: string, fileName: Array<string>, existingFilePath?: string, isAppend?: boolean, deleteOld?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    reloadData(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    sync(id: string, scenarioId?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {UpdateEntityDto} updateEntityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    updateEntity(updateEntityDto: UpdateEntityDto, options?: RawAxiosRequestConfig): AxiosPromise<EntityDto>;

    /**
     * 
     * @param {string} id 
     * @param {Array<string>} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApiInterface
     */
    uploadFileUrl(id: string, fileName: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<SignedUrlResult>>;

}

/**
 * EntityControllerApi - object-oriented interface
 * @export
 * @class EntityControllerApi
 * @extends {BaseAPI}
 */
export class EntityControllerApi extends BaseAPI implements EntityControllerApiInterface {
    /**
     * 
     * @param {CreateEntityDto} createEntityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public addEntityToProject(createEntityDto: CreateEntityDto, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).addEntityToProject(createEntityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public buildEntity(id: string, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).buildEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public findAuditHistory(id: string, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).findAuditHistory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [projectId] 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public findEntities(id?: string, name?: string, projectId?: string, scenarioId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).findEntities(id, name, projectId, scenarioId, projectRunEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [parentType] 
     * @param {string} [parentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public findStats(id: string, parentType?: string, parentId?: string, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).findStats(id, parentType, parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public findStats1(id: string, scenarioId?: string, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).findStats1(id, scenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {GenerateStatsConfigDto} [generateStatsConfigDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public generateStats(id: string, generateStatsConfigDto?: GenerateStatsConfigDto, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).generateStats(id, generateStatsConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [limit] 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public getData1(id: string, limit?: number, scenarioId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).getData1(id, limit, scenarioId, projectRunEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {DownloadEntityDto} downloadEntityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public getDownloadUrl(id: string, downloadEntityDto: DownloadEntityDto, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).getDownloadUrl(id, downloadEntityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {string} [dataFileId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public getDownloadUrls(id: string, scenarioId?: string, dataFileId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).getDownloadUrls(id, scenarioId, dataFileId, projectRunEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {EntityDataRequestDto} entityDataRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public getEntityData(id: string, entityDataRequestDto: EntityDataRequestDto, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).getEntityData(id, entityDataRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunEntryId] 
     * @param {string} [runEntityName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public getEntityDetails(id: string, scenarioId?: string, projectRunEntryId?: string, runEntityName?: string, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).getEntityDetails(id, scenarioId, projectRunEntryId, runEntityName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {EntityEDADto} entityEDADto 
     * @param {boolean} [async] 
     * @param {boolean} [triggerOnError] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public getEntityEDA(id: string, entityEDADto: EntityEDADto, async?: boolean, triggerOnError?: boolean, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).getEntityEDA(id, entityEDADto, async, triggerOnError, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {EntityEDAChartsDto} entityEDAChartsDto 
     * @param {boolean} [async] 
     * @param {boolean} [triggerOnError] 
     * @param {boolean} [generateAdditionalCharts] 
     * @param {boolean} [readOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public getEntityEDACharts(id: string, entityEDAChartsDto: EntityEDAChartsDto, async?: boolean, triggerOnError?: boolean, generateAdditionalCharts?: boolean, readOnly?: boolean, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).getEntityEDACharts(id, entityEDAChartsDto, async, triggerOnError, generateAdditionalCharts, readOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public getSchemaDownloadUrl(id: string, scenarioId?: string, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).getSchemaDownloadUrl(id, scenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [parentType] 
     * @param {string} [parentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public hasPendingStats(id: string, parentType?: string, parentId?: string, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).hasPendingStats(id, parentType, parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Array<string>} fileName 
     * @param {string} [existingFilePath] 
     * @param {boolean} [isAppend] 
     * @param {boolean} [deleteOld] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public processUploadedFile1(id: string, fileName: Array<string>, existingFilePath?: string, isAppend?: boolean, deleteOld?: boolean, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).processUploadedFile1(id, fileName, existingFilePath, isAppend, deleteOld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public reloadData(id: string, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).reloadData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public sync(id: string, scenarioId?: string, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).sync(id, scenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateEntityDto} updateEntityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public updateEntity(updateEntityDto: UpdateEntityDto, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).updateEntity(updateEntityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Array<string>} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityControllerApi
     */
    public uploadFileUrl(id: string, fileName: Array<string>, options?: RawAxiosRequestConfig) {
        return EntityControllerApiFp(this.configuration).uploadFileUrl(id, fileName, options).then((request) => request(this.axios, this.basePath));
    }
}

