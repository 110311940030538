import makeStyles from "@material-ui/core/styles/makeStyles";

import backgroundImage from "src/assets/images/public-screens-bg-image.svg";
import { MuiBreakpoints } from "src/constants";

// Most of the values being used are multiple of 8, as MUI prefers 8 unit spacing.
const useStyles = makeStyles((theme) => ({
  page: {
    minHeight: "100vh",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  root: {
    margin: "auto",
    borderRadius: theme.spacing(3),
    color: theme.palette.common.black,
    [theme.breakpoints.only(MuiBreakpoints.Xs)]: {
      width: "88%",
      borderRadius: theme.spacing(1.5)
    },
    [theme.breakpoints.only(MuiBreakpoints.Sm)]: {
      width: "56%",
      borderRadius: theme.spacing(2)
    },
    [theme.breakpoints.only(MuiBreakpoints.Md)]: {
      width: "44%",
      borderRadius: theme.spacing(2)
    },
    [theme.breakpoints.only(MuiBreakpoints.Lg)]: {
      width: "28%"
    },
    [theme.breakpoints.only(MuiBreakpoints.Xl)]: {
      width: "24%"
    }
  },
  container: {
    margin: "16%",
    marginBottom: "24%",
    width: "auto",
    [theme.breakpoints.down(MuiBreakpoints.Md)]: {
      margin: "12%",
      marginBottom: "20%"
    },
    [theme.breakpoints.only(MuiBreakpoints.Xs)]: {
      margin: "8%",
      marginBottom: "16%"
    }
  },
  rapidCanvasLogoContainer: {
    marginBottom: "24%",
    textAlign: "center",
    [theme.breakpoints.down(MuiBreakpoints.Sm)]: {
      "& img": {
        width: 200
      }
    }
  },
  titleContainer: {
    marginBottom: "8%",
    [theme.breakpoints.down(MuiBreakpoints.Sm)]: {
      "& [class^='MuiTypography-root']": {
        fontSize: "1.25rem"
      }
    }
  },
  messageContainer: {
    marginBottom: "6%",
    [theme.breakpoints.down(MuiBreakpoints.Sm)]: {
      "& [class^='MuiTypography-root']": {
        fontSize: "1rem"
      }
    }
  },
  formContainer: {
    rowGap: theme.spacing(2)
  },
  action: {
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(2)
  },
  // $FixMe: Scope to be refactored.
  // The alert is not displaying as expected. Hence, customized it.
  errorContainer: {
    width: "100%",
    paddingTop: 0,
    paddingBottom: 0,
    "& [class^='MuiAlert-icon']": {
      flexWrap: "wrap",
      alignContent: "center"
    }
  },
  link: {
    color: theme.palette.primary.main
  }
}));

export { useStyles };
