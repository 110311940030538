import React from "react";
import _ from "lodash";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import { AIGuideChat } from "./AIGuideChat";
import {
  ThreadResponseDto,
  ThreadResponseDtoDatasetContextEnum
} from "openapi/Models/thread-response-dto";

const useStyles = makeStyles({
  leftBar: {
    width: "20%",
    maxWidth: "20%",
    flexBasis: "20%",
    borderRight: "1px solid #0000001f",
    height: "auto",
    background: "#fffc"
  },
  chatLabelWrap: {
    padding: "24px 16px 12px 16px"
  },
  chatLabel: {
    color: "#7C7C7C",
    flex: 1
  },
  chatsWrap: {
    height: "100%"
  },
  chatName: {
    padding: "0px 16px 16px 16px"
  },
  chatText: {
    whiteSpace: "nowrap",
    background: "#E7E7FF",
    color: "#4646B5",
    boxShadow: "none",
    borderRadius: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    fontWeight: 400,
    minHeight: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center"
  },
  chatNames: {
    gap: "16px"
  },
  chatWrap: {
    overflow: "auto",
    paddingBottom: "8px"
  }
});

const AIGuideLeftbar = ({
  threads,
  onThreadChange,
  activeId,
  onDeleteSuccess
}: {
  activeId: string;
  threads: ThreadResponseDto[];
  onThreadChange: React.Dispatch<React.SetStateAction<ThreadResponseDto | undefined>>;
  onDeleteSuccess: (threadId: string) => void;
}) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.leftBar}>
      <Grid container direction="column" wrap="nowrap" className={classes.chatsWrap}>
        <Grid container direction="row" alignItems="center" className={classes.chatLabelWrap}>
          <Typography className={classes.chatLabel} variant="body2">
            Chats
          </Typography>
        </Grid>
        <Grid container direction="column" wrap="nowrap" className={classes.chatWrap}>
          {threads.map((thread) => {
            const { threadId, targetInputs, datasetContext } = thread;

            const entityName =
              targetInputs?.[0]?.entityDisplayName || targetInputs?.[0]?.entityName;

            const chartName = targetInputs?.[0]?.chartName;
            const currChatName = `${chartName ? `${chartName} - ` : ""}${entityName}${
              datasetContext === ThreadResponseDtoDatasetContextEnum.RapidModelRecipe
                ? " - Rapid Recipe"
                : ""
            }`;
            const isActive = activeId === threadId!;
            const setActiveChat = () => onThreadChange(thread);
            return (
              <AIGuideChat
                key={threadId}
                threadId={threadId!}
                isLoading={!currChatName}
                chatName={currChatName}
                isActive={isActive}
                onChatChange={setActiveChat}
                onDeleteSuccess={onDeleteSuccess}
              />
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(AIGuideLeftbar);
