import React from "react";
import _ from "lodash";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import {
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip
} from "@material-ui/core";

import { ConfigInputTypeEnum, ConfigLlmModelEnum } from "openapi/Models/config";
import { askAITypesInfoList } from "src/pages/DataApps/utils/DataApps.constants";
import { dataAppConfigFields } from "./CreateDataAppForm";

interface IProps {
  value: ConfigLlmModelEnum;
  disabledInputs: ConfigInputTypeEnum[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectAskAIInputType: React.FC<IProps> = (props) => {
  return (
    <Card style={{ padding: "16px" }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Inputs</FormLabel>
        <RadioGroup
          aria-label={dataAppConfigFields.inputType}
          name={dataAppConfigFields.inputType}
          value={props.value}
          onChange={props.onChange}>
          {_.map(askAITypesInfoList, ({ label, tooltip, disabledTooltip, value }, index) => {
            const disabled = _.includes(props.disabledInputs, value);
            return (
              <FormControlLabel
                key={index}
                disabled={disabled}
                value={value}
                control={<Radio />}
                label={
                  <>
                    <span>{label}</span>{" "}
                    <Tooltip title={disabled ? disabledTooltip : tooltip}>
                      <InfoOutlinedIcon
                        style={{ fontSize: "1rem", marginBottom: "2px", color: "#8a8989" }}
                      />
                    </Tooltip>
                  </>
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Card>
  );
};

export default SelectAskAIInputType;
