import React from "react";

// Packages
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";

// Components
import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";

const FONT_SIZE = 14;

type Props = {
  project: $TSFixMe;
  scenarioName: string;
  updateScenarioName: $TSFixMeFunction;
  shouldResetScenarioName: number | boolean;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, scenarioName, updateScenarioName, shouldResetScenarioName } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="projects" to={`/projects/${project?.id}/canvas`}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText key="project-name" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="scenarios" to={`/scenarios/${project?.id}`}>
        <RenderText>Scenarios</RenderText>
      </Link>
    ) : (
      <RenderText key="scenarios" color="textSecondary">
        Scenarios
      </RenderText>
    )
  );

  breadcrumbs.push(
    <EditName
      key="edit-name"
      inputValue={scenarioName}
      onSave={updateScenarioName}
      isResetName={shouldResetScenarioName}
      containerProps={{
        style: {
          width: ((scenarioName || "")?.length + 1) * (FONT_SIZE - 4) + 16
        }
      }}
    />
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
