import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QUERY_KEY_DATA_APPS } from "src/hooks/api/dataapps/useGetDataApps";
import { getAPIWithRethrow, putAPIWithRethrow } from "src/utils/apiService";

export const useUpdateDataAppMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      dataAppId,
      dataAppName,
      params
    }: {
      dataAppId: string;
      dataAppName: string;
      params: any;
    }) => {
      const currDataApp = await getAPIWithRethrow(`/dataapps/${dataAppName}`);
      return putAPIWithRethrow(`/dataapps/${dataAppId}`, {
        ...currDataApp,
        ...params
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY_DATA_APPS]);
    }
  });
};
