import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import { GenerateCodeInputsDropdown } from "./GenerateCodeInputsDropdown";
import { GenerateCodeQueryInput } from "./GenerateCodeQueryInput";
import { useCodeRecipeContext } from "../../../CodeRecipeContext/useCodeRecipeContext";

const useStyles = makeStyles({
  generateCodeInputs: {
    flexWrap: "nowrap",
    padding: "12px 16px 0px 16px",
    borderRadius: "0px 0px 4px 4px"
  },
  inputGroup: {
    flexWrap: "nowrap"
  },
  inputsWrap: {
    flexWrap: "nowrap",
    width: "300px",
    minWidth: "300px"
  },
  outputTypeWrap: {
    flexWrap: "nowrap",
    alignItems: "center",
    flex: 0
  },
  helpText: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "12px",
    lineHeight: 1.54,
    padding: "4px 16px"
  }
});

export const GenerateCodeBar = ({
  disabled,
  isFetchingSuggestions,
  onFetchPromptSuggestions,
  ...rest
}: {
  disabled: boolean;
  isFetchingSuggestions: boolean;
  onFetchPromptSuggestions: (response: { payload: any }) => void;
  onReset: () => void;
}) => {
  const classes = useStyles();
  const { pinnedNames } = useCodeRecipeContext();

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid container item direction="row" className={classes.generateCodeInputs}>
        <Grid container className={classes.inputsWrap}>
          <GenerateCodeInputsDropdown />
        </Grid>
        <GenerateCodeQueryInput
          disabled={disabled}
          isFetchingSuggestions={isFetchingSuggestions}
          onFetchPromptSuggestions={onFetchPromptSuggestions}
          {...rest}
        />
      </Grid>
      <Grid container item justifyContent="space-between" direction="row-reverse">
        <Grid item xs={6} container direction="row" justifyContent="flex-end">
          <Typography className={classes.helpText}>
            <b>Shift/Ctrl + Return</b> to add a new line
          </Typography>
          <Typography style={{ marginLeft: "10px" }} className={classes.helpText}>
            <b>Use @</b> in query box for column names.
          </Typography>
        </Grid>
        {pinnedNames.length > 0 && (
          <Typography style={{ marginLeft: "10px" }} className={classes.helpText}>
            <i>Maximum of 4 datasets can be pinned at once</i>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
