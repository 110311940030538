import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Grid, makeStyles, Tooltip } from "@material-ui/core";
import { camelCase } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { Tabs, Tab, TabPanel } from "src/components";

import Data from "./Data";
import TabPanelWrapper from "../TabPanelWrapper";
import ThirdPartyData from "./ThirdPartyData";

import {
  DataSourceTabKeys,
  dataSourceTabKeyNames,
  dataSourceTabs,
  DataSourcesHelperText,
  thirdPartyTypeName
} from "../../utils/DataSources.constants";
import { InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  tabPanelContainer: {
    // 94 (SubTopNavBar height)
    // + 24 (Page padding)
    // + 48 (Tabs height)
    // + 24 (Data section margin-top)
    // + 8 (Data section padding-top)
    // + n (buffer)
    height: "calc(100vh - 214px)",
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    overflow: "auto"
  },
  syncTime: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    color: "#003656"
  }
}));

const SqlBased = (props: $TSFixMe) => {
  const {
    status,
    id,
    isFetching,
    dataSourcesSchema,
    dataSourceTypeStore,
    dataSourceDetails,
    isSqlContentDisabled,
    isDataSourceUsageLoading,
    usageSourceDatasets,
    usageDestinationDatasets,
    usageSourceDatasetsInJobs,
    usageDestinationDatasetsInJobs,
    syncData
  } = props || {};

  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);

  const onTabChange = (newValue: $TSFixMe) => {
    setTabValue(newValue);
  };

  // SQL Based States - STARTS >>
  const [values, setValues] = useState<$TSFixMe>({});
  const [editorQueryFields, setEditorQueryFields] = useState<$TSFixMe>({});

  const [sampleData, setSampleData] = useState<$TSFixMe>({});

  const [isFetchingSampleData, setIsFetchingSampleData] = useState<$TSFixMe>(false);
  const [isRanQuery, setIsRanQuery] = useState<$TSFixMe>(false);

  const [isFormFieldsDirty, setIsFormFieldsDirty] = useState<$TSFixMe>(false);
  // << ENDS - SQL Based States

  // SQL configuration  - STARTS >>
  const formForm = async (thisDataSourceType: $TSFixMe) => {
    const foundDataSourceType = dataSourcesSchema?.find(
      (eachDataSourceType: $TSFixMe) =>
        eachDataSourceType?.type === (thisDataSourceType?.dataSourceType || dataSourceTypeStore)
    );

    if (foundDataSourceType) {
      const allFields = [...foundDataSourceType?.datasetFields];

      const thisEditorQueryField: $TSFixMe = {};
      const formFormFields: $TSFixMe = {};

      allFields?.forEach((field: $TSFixMe) => {
        let formField = field;
        if (/query/i.test(field?.name)) {
          thisEditorQueryField[field?.name] = {
            ...field,
            value: ""
          };
        } else {
          if (thisDataSourceType[field?.name]) {
            formField.value = thisDataSourceType[field?.name];
          }

          if (thisDataSourceType?.options?.[field?.name]) {
            formField.value = thisDataSourceType?.options?.[field?.name];
          }

          formFormFields[field?.name] = { ...formFormFields[field?.name], ...formField };
        }
      });

      setEditorQueryFields(() => thisEditorQueryField);

      if (Object.keys(formFormFields)?.length > 0) {
        setValues(() => ({ ...formFormFields }));
      }
    }
  };

  useEffect(() => {
    (dataSourcesSchema || [])?.length > 0 &&
      (dataSourceDetails?.dataSourceType || dataSourceTypeStore) &&
      formForm(dataSourceDetails);
  }, [dataSourcesSchema, dataSourceDetails, dataSourceTypeStore]);
  // << ENDS - SQL configuration

  return (
    <>
      <Grid container justifyContent="space-between" alignContent="center">
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable">
          <Tab
            value={0}
            data-testid="connectorData"
            label={dataSourceTabKeyNames[DataSourceTabKeys.Data]}
          />
          {dataSourceTabs.map((eachTab, index: number) => (
            <Tab
              key={uuidv4()}
              value={index + 1}
              data-testid={camelCase(`connector${eachTab.displayName}`)}
              label={eachTab.displayName}
            />
          ))}
        </Tabs>

        {syncData?.lastSyncTime && (
          <span className={classes.syncTime} data-testid="connectorLastSynced">
            Last Sync Triggered At: {dayjs(syncData?.lastSyncTime).format("YYYY-MM-DD HH:mm:ss")}
            <Tooltip
              arrow
              title="This timestamp updates upon completion of the latest sync action.">
              <InfoOutlined color="disabled" fontSize="small" />
            </Tooltip>
          </span>
        )}
      </Grid>
      <div className={classes.tabPanelContainer}>
        <TabPanel value={tabValue} index={0} data-testid="connectorTabData">
          {dataSourceDetails?.dataSourceType !== thirdPartyTypeName ? (
            <Data
              id={id}
              isFetching={isFetching}
              dataSourceTypeStore={dataSourceTypeStore}
              dataSourceDetails={dataSourceDetails}
              isSqlContentDisabled={isSqlContentDisabled}
              values={values}
              setValues={setValues}
              editorQueryFields={editorQueryFields}
              setEditorQueryFields={setEditorQueryFields}
              sampleData={sampleData}
              setSampleData={setSampleData}
              isFetchingSampleData={isFetchingSampleData}
              setIsFetchingSampleData={setIsFetchingSampleData}
              isRanQuery={isRanQuery}
              setIsRanQuery={setIsRanQuery}
              isFormFieldsDirty={isFormFieldsDirty}
              setIsFormFieldsDirty={setIsFormFieldsDirty}
            />
          ) : (
            <ThirdPartyData id={id} status={status} details={dataSourceDetails} />
          )}
        </TabPanel>
        <TabPanelWrapper
          data-testid="connectorTabDatasets"
          tab={DataSourceTabKeys.Datasets}
          value={tabValue}
          index={1}
          isDataSourceUsageLoading={isDataSourceUsageLoading}
          usageSourceDatasets={usageSourceDatasets}
          usageDestinationDatasets={usageDestinationDatasets}
          emptyDataMessage={DataSourcesHelperText.NoDatasetFound}
        />
        <TabPanelWrapper
          data-testid="connectorTabDatasets"
          tab={DataSourceTabKeys.Jobs}
          value={tabValue}
          index={2}
          isDataSourceUsageLoading={isDataSourceUsageLoading}
          usageSourceDatasets={usageSourceDatasetsInJobs}
          usageDestinationDatasets={usageDestinationDatasetsInJobs}
          emptyDataMessage={DataSourcesHelperText.NoJobFound}
        />
      </div>
    </>
  );
};

export default SqlBased;
