import React, { useEffect } from "react";
import { generatePath, useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  useSaveCodeRecipeMutation,
  useUpdateTransformGroupMutation,
  useTestTransformGroupV2Mutation,
  useRunTransformGroupMutation,
  useDeleteTransformMutation,
  useSaveCodeRecipeMutationV2
} from "src/hooks/api";
import { useGetJob, useGetJobRun } from "src/hooks/api";
import { handleResponse } from "src/utils/apiService";
import { Entities, Entity, EntityFeaturesResponse, Recipe } from "src/types";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import useCreateApiConnectorRecipe from "hooks/api/codeRecipe/useCreateApiConnectorRecipe";
import { ApiConnectorRecipeContext } from "./ApiConnectorRecipeContext";
import { useTransformErrorUtils } from "../../common/hooks/useTransformErrorUtils";
import { RecipeStatuses } from "src/constants";
import { usePollRecipe } from "src/hooks/api/transforms/usePollRecipe";
import { WebPaths } from "src/routing/routes";

export enum OUTPUT_TYPE {
  DATASET = "DATASET",
  CHART = "CHART",
  TEXT = "TEXT",
  AUTO_INFER = "AUTO_INFER",
  PROMPT_SUGGESTIONS = "PROMPT_SUGGESTIONS",
  MODEL = "MODEL"
}

export const CodeRecipeContextProvider = ({
  templates: initialTemplates,
  recipe: initialRecipe,
  children
}: $TSFixMe) => {
  const { projectId, scenarioId, jobId, jobRunId, groupId } = useParams();
  const [recipe, setRecipe] = React.useState<Recipe | null>(initialRecipe || null);
  const [recipeName, setRecipeName] = React.useState<string>(recipe?.name || "");
  const [templates, setTemplates] = React.useState<$TSFixMe[]>(initialTemplates || []);
  const [editorValue, setEditorValue] = React.useState("");
  const [previewTabs, setPreviewTabs] = React.useState<string[]>([]);
  const [inputDatasets, setInputDatasets] = React.useState<Array<Entity>>([]);
  const [isTestInProgress, setIsTestInProgress] = React.useState(false);
  const [codeErrorDetails, setCodeErrorDetails] = React.useState<{
    lineNo: number;
    line: string;
  } | null>(null);
  const [isSelectedEntitiesUpdateInProgess, setIsSelectedEntitiesUpdateInProgess] =
    React.useState<boolean>(false);
  const [entityFeaturesMap, setEntityFeaturesMap] = React.useState<{
    [id: string]: EntityFeaturesResponse;
  } | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    setTemplates(initialTemplates);
    setEditorValue(initialTemplates?.map((template: any) => template.code)?.join("\r\n\n"));
  }, [initialTemplates]);

  useEffect(() => {
    setRecipe(initialRecipe);
  }, [initialRecipe]);

  const onUpdateRecipe = (recipe: Recipe) => {
    setRecipeName(recipe?.name);
    setRecipe(recipe);
  };

  const createApiConnectorRecipe = useCreateApiConnectorRecipe();
  const updateTransformGroupMutation = useUpdateTransformGroupMutation();
  const saveCodeRecipeMutation = useSaveCodeRecipeMutation();
  const saveAndTestCodeRecipeMutation = useSaveCodeRecipeMutation();
  const testTransformGroupMutation = useTestTransformGroupV2Mutation();
  const runTransformGroupMutation = useRunTransformGroupMutation();
  const saveCodeRecipeMutationV2 = useSaveCodeRecipeMutationV2();
  const deleteTransformMutation = useDeleteTransformMutation();
  const { fetchAndRenderFullErrorLogs, renderTestTransformErrors } = useTransformErrorUtils();
  const { data: jobData } = useGetJob({ projectId, jobId });
  const { data: jobRunData } = useGetJobRun({ jobRunId, isApiWithRethrow: false });

  const getCodeStrings = () => {
    const pattern =
      /(def transform\(entities(?:, context)?\))([\s\S]*?)(?=\bdef transform\(entities(?:, context)?\)|$)/g;

    const codeStrings = [...editorValue.matchAll(pattern)]
      ?.map((match) => match[0])
      ?.filter((str) => str.trim() !== "");
    return codeStrings;
  };

  const handleInputDatasetsChange = (newEntities: Entities) => {
    if (!recipe?.id) {
      setInputDatasets(newEntities);
      return;
    }
    setIsSelectedEntitiesUpdateInProgess(true);
    const payload = {
      parents: newEntities?.map((entity) => ({ id: entity.id, type: "ENTITY" }))
    };
    const onSuccess = () => {
      setInputDatasets(newEntities);
      setIsSelectedEntitiesUpdateInProgess(false);
    };
    handleUpdateRecipe({ payload, onSuccess });
  };

  const handleSave = () => {
    const codeStrings = getCodeStrings();

    if (codeStrings?.length === 0) {
      handleResponse({
        errorMessage: "Unable to save recipe - The provided code format is incorrect"
      });
      setEditorValue("");
      return;
    }

    const callSave = (recipeId: string) => {
      saveCodeRecipeMutation.mutate(
        {
          codeStrings,
          projectId: projectId!,
          recipe,
          templates,
          groupId: recipeId
        },
        {
          onSuccess: ({ templatesResponse, transformGroupResponse }) => {
            handleResponse({
              successMessage: `Recipe is saved successfully.`
            });

            if (groupId) {
              setTemplates(templatesResponse);
              onUpdateRecipe(transformGroupResponse);
              setEditorValue(templatesResponse?.map((template) => template.code)?.join("\r\n\n"));
            } else {
              if (projectId && scenarioId) {
                navigate(
                  generatePath(`${WebPaths.APIConnectorRecipeContainer}/:groupId`, {
                    projectId,
                    scenarioId,
                    groupId: recipeId
                  }),
                  { replace: true }
                );
              }
            }
          }
        }
      );
    };

    if (!groupId) {
      createApiConnectorRecipe.mutate(
        { projectId, inputDatasets },
        {
          onSuccess: (response) => {
            callSave(response?.group?.id);
          }
        }
      );
    } else {
      callSave(groupId);
    }
  };

  const handleTest = React.useCallback(
    (sampleRows?: number) => {
      setIsTestInProgress(true);
      const codeStrings = getCodeStrings();

      if (codeStrings?.length === 0) {
        handleResponse({
          errorMessage: "Unable to save recipe - The provided code format is incorrect"
        });
        setEditorValue("");
        setIsTestInProgress(false);
        return;
      }
      saveAndTestCodeRecipeMutation.mutate(
        {
          codeStrings,
          projectId: projectId!,
          recipe,
          templates,
          groupId: groupId ?? (recipe?.id as string)
          // recipeTye: RecipeTypes.ApiConnector
        },
        {
          onSuccess: ({ templatesResponse, transformGroupResponse }) => {
            setTemplates(templatesResponse);
            onUpdateRecipe(transformGroupResponse);
            setEditorValue(templatesResponse?.map((template) => template.code)?.join("\r\n\n"));
            testTransformGroupMutation.mutate(
              {
                recipeId: recipe?.id,
                projectId,
                sampleRows,
                testTransformConfig: transformGroupResponse?.runConfigs?.map((config: any) => ({
                  ...config,
                  templateId: config.templateId,
                  projectId,
                  name: config.name
                }))
              },
              {
                onSuccess: (response: $TSFixMe) => {
                  const outputDatasets = response?.dataMap
                    ? Object.keys(response?.dataMap).map((item) => ({
                        name: item,
                        data: response?.dataMap?.[item],
                        type: OUTPUT_TYPE.DATASET,
                        id: uuidv4()
                      }))
                    : [];
                  const outputCharts =
                    response?.entityViewData.map((item: $TSFixMe) => ({
                      ...item,
                      type: OUTPUT_TYPE.CHART,
                      id: uuidv4()
                    })) || [];

                  setPreviewTabs([...outputDatasets, ...outputCharts]);
                  setCodeErrorDetails(null);
                  handleResponse({ successMessage: `Test successful` });
                },
                onError: (error: $TSFixMe) => {
                  renderTestTransformErrors(error, false, true);
                  const responseData = error?.response?.data;
                  responseData?.extraInfo?.runErrDetails &&
                    setCodeErrorDetails(responseData?.extraInfo?.runErrDetails);
                },
                onSettled: () => {
                  setIsTestInProgress(false);
                }
              }
            );
          },
          onError: () => {
            setIsTestInProgress(false);
          }
        }
      );
    },
    [
      editorValue,
      fetchAndRenderFullErrorLogs,
      projectId,
      recipe,
      saveAndTestCodeRecipeMutation,
      templates,
      testTransformGroupMutation
    ]
  );

  const onRunSuccess = (response: any) => {
    if (response && !response.msg) {
      setCodeErrorDetails(null);
      handleResponse({ successMessage: `Run successful` });
    }
  };

  const onRunError = (error: $TSFixMe) => {
    fetchAndRenderFullErrorLogs(error, { projectId, scenarioId, groupId: recipe?.id }, false, true);
    const responseData = error?.response?.data;
    responseData?.extraInfo && setCodeErrorDetails(responseData?.extraInfo?.runErrDetails);
  };

  const handleRun = React.useCallback(async () => {
    if (!recipe?.id) {
      return;
    }
    projectId && checkEnvRelaunch(projectId);

    let timeoutId: $TSFixMe;

    runTransformGroupMutation.mutate(
      { recipeId: recipe?.id ?? groupId, scenarioId: scenarioId! },
      {
        onSuccess: onRunSuccess,
        onError: onRunError
      }
    );

    return () => clearTimeout(timeoutId);
  }, [projectId, recipe?.id, runTransformGroupMutation, scenarioId, onRunSuccess, onRunError]);

  const handleUpdateRecipe = React.useCallback(
    async ({
      payload,
      onSuccess,
      onError
    }: {
      payload: $TSFixMe;
      onError?: () => void;
      onSuccess?: (recipe: Recipe) => void;
    }) => {
      const runConfigs = recipe?.runConfigs?.length
        ? recipe?.runConfigs?.map((item) => item.id)
        : [];
      const updatedPayload = {
        ...recipe,
        runConfigs,
        projectId,
        name: recipe?.name || recipeName,
        displayName: recipe?.displayName || recipe?.name || recipeName,
        parents: recipe?.parents,
        ...payload
      };
      updateTransformGroupMutation.mutate(
        {
          recipeId: recipe?.id,
          transformGroup: updatedPayload,
          projectId: projectId!
        },
        {
          onSuccess: (response: $TSFixMe) => {
            onUpdateRecipe(response);
            onSuccess?.(response);
          },
          onError: () => {
            onError?.();
          }
        }
      );
    },
    [projectId, recipe, recipeName, updateTransformGroupMutation]
  );

  const {
    data: runningRecipe,
    isFetched: isFetchedRunningRecipe,
    isError,
    isSuccess,
    error: runError
  } = usePollRecipe({
    enabled: !!(recipe?.id && recipe?.status === RecipeStatuses.Running),
    recipeId: recipe?.id,
    scenarioId
  });

  useEffect(() => {
    if (isSuccess && runningRecipe?.status === RecipeStatuses.Success) {
      onRunSuccess(runningRecipe);
    } else if (isSuccess && runningRecipe?.status === RecipeStatuses.Error) {
      onRunError(runError);
    }
  }, [runningRecipe, runError]);

  useEffect(() => {
    isError && onRunError(runError);
  }, [runError]);

  const isSaveInProgress = React.useMemo(
    () => saveCodeRecipeMutation.isLoading || createApiConnectorRecipe.isLoading,
    [saveCodeRecipeMutation.isLoading, createApiConnectorRecipe.isLoading]
  );

  const isRunInProgress = React.useMemo(
    () =>
      isFetchedRunningRecipe
        ? runningRecipe?.status === RecipeStatuses.Running
        : runTransformGroupMutation.isLoading || recipe?.status === RecipeStatuses.Running,
    [recipe, runTransformGroupMutation.isLoading, runningRecipe?.status, isFetchedRunningRecipe]
  );

  const isAskAiRecipeUpdateInProgress =
    saveCodeRecipeMutationV2.isLoading || deleteTransformMutation.isLoading;

  const isSaveDisabled =
    !editorValue || isSaveInProgress || isTestInProgress || isAskAiRecipeUpdateInProgress;

  const saveToolTip = !editorValue ? "Add code to enable save" : undefined;
  const runTooltip = !editorValue
    ? "Add code to enable run"
    : isRunInProgress
      ? "Recipe run is in progress"
      : !recipe?.id
        ? "Please save your changes to enable this"
        : undefined;
  const testTooltip = !editorValue
    ? "Add  code to enable test"
    : !recipe?.id
      ? "Please save your changes to enable this"
      : undefined;
  const isRunDisabled =
    !recipe?.id ||
    !editorValue ||
    isRunInProgress ||
    isAskAiRecipeUpdateInProgress ||
    templates?.length === 0;
  const isTestDisabled =
    !recipe?.id ||
    !editorValue ||
    isTestInProgress ||
    isSaveInProgress ||
    isRunInProgress ||
    isAskAiRecipeUpdateInProgress;

  const onAddCodeToRecipe = React.useCallback(
    ({
      code: newCode,
      onSuccess,
      codeHistoryId
    }: {
      code: string;
      onSuccess?: (transformId: string) => void;
      codeHistoryId: string;
    }) => {
      saveCodeRecipeMutationV2.mutate(
        {
          recipe,
          code: newCode,
          codeHistoryId,
          projectId: projectId!
        },
        {
          onSuccess: ({ templateResponse, saveTransformResponse, transformGroupResponse }) => {
            const updatedTemplates = [...templates, templateResponse];
            onUpdateRecipe(transformGroupResponse);
            setTemplates((templates: $TSFixMe) => [...templates, templateResponse]);
            setEditorValue(updatedTemplates?.map((template) => template.code)?.join("\r\n\n"));
            onSuccess?.(saveTransformResponse.id);
          }
        }
      );
    },
    [projectId, recipe, saveCodeRecipeMutationV2, templates]
  );

  const onRemoveCodeFromRecipe = React.useCallback(
    ({ transformId, onSuccess }: { transformId: string; onSuccess: () => void }) => {
      recipe &&
        deleteTransformMutation.mutate(
          { transformId, recipeId: recipe.id },
          {
            onSuccess: ({ transformGroupResponse }: { transformGroupResponse: Recipe }) => {
              onUpdateRecipe(transformGroupResponse);
              const recipeTemplateIds =
                transformGroupResponse?.runConfigs?.map((config) => config.templateId) || [];
              const updatedTemplates = templates?.filter((currTemplate) =>
                recipeTemplateIds.includes(currTemplate.id)
              );
              const updatedCode = updatedTemplates?.map((template) => template.code);
              setTemplates(updatedTemplates);
              setEditorValue(updatedCode?.join("\r\n\n"));
              onSuccess?.();
            }
          }
        );
    },
    [deleteTransformMutation, recipe, templates]
  );

  const isQueryAssociatedWithRecipe = React.useCallback(
    (identifier: string) => {
      return (
        (deleteTransformMutation.isLoading &&
          deleteTransformMutation.variables?.transformId === identifier) ||
        (saveCodeRecipeMutationV2.isLoading &&
          saveCodeRecipeMutationV2.variables?.codeHistoryId === identifier)
      );
    },
    [deleteTransformMutation.isLoading, saveCodeRecipeMutationV2.isLoading]
  );

  const contextValue = React.useMemo(() => {
    return {
      jobData,
      jobRunData,
      recipe,
      handleUpdateRecipe,
      recipeId: recipe?.id,
      isRunInProgress,
      isTestInProgress,
      isSaveInProgress,
      isSaveDisabled,
      inputDatasets,
      entityFeaturesMap,
      isRunDisabled,
      saveToolTip,
      runTooltip,
      isSelectedEntitiesUpdateInProgess,
      testTooltip,
      handleSave,
      handleRun,
      isTestDisabled,
      onUpdateRecipe,
      onAddCodeToRecipe,
      onRemoveCodeFromRecipe,
      isAskAiRecipeUpdateInProgress,
      previewTabs,
      setEntityFeaturesMap,
      handleInputDatasetsChange,
      handleTest,
      setInputDatasets,
      editorValue,
      setEditorValue,
      setPreviewTabs,
      codeErrorDetails,
      isQueryAssociatedWithRecipe
    };
  }, [
    jobData,
    jobRunData,
    inputDatasets,
    entityFeaturesMap,
    isSelectedEntitiesUpdateInProgess,
    handleRun,
    handleSave,
    handleUpdateRecipe,
    setEntityFeaturesMap,
    setInputDatasets,
    handleInputDatasetsChange,
    isAskAiRecipeUpdateInProgress,
    isRunDisabled,
    isRunInProgress,
    isSaveDisabled,
    isSaveInProgress,
    isTestDisabled,
    isTestInProgress,
    onAddCodeToRecipe,
    onRemoveCodeFromRecipe,
    recipe,
    saveToolTip,
    runTooltip,
    testTooltip,
    previewTabs,
    handleTest,
    editorValue,
    setEditorValue,
    setPreviewTabs,
    codeErrorDetails,
    isQueryAssociatedWithRecipe
  ]);
  return (
    <ApiConnectorRecipeContext.Provider value={contextValue}>
      {children}
    </ApiConnectorRecipeContext.Provider>
  );
};
