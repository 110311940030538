import React, { useMemo } from "react";
import { Box, Typography, CardContent, Card, Button, Grid } from "@material-ui/core";
import { useNavigate, useLocation, generatePath } from "react-router-dom";

import { TrashIcon } from "src/icons/NewUX/TrashIcon";

import { RenderText } from "src/utils";

import DeleteNodeModal from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import DrawerAbout from "./components/DrawerAbout";
import Spinner from "components/Spinner";
import useArtifactByName from "hooks/api/artifacts/useArtifactByName";
import useEntities from "hooks/api/entities/useEntities";
import { checkIfDefaultScenario } from "src/pages/Scenarios/helpers/scenarios.helpers";
import { dateFormat } from "src/utils/dateFormat";
import { getErrorMessage, handleResponse } from "src/utils/apiService";
import { sideComponentSetter } from "src/store/store.selectors";
import { useScenariosStore, useDrawerStore } from "src/store/store";
import { useStyles } from "./components/styling";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import ArtifactReadonly from "src/pages/private/ProjectsModule/pages/Dag/components/Nodes/Artifact/ArtifactReadonly";
import { WebPaths } from "src/routing/routes";
import { EyeIcon } from "src/icons/NewUX";
import MoreOptions from "src/layout/NavBars/components/SubTopNavBar/MoreOptions";

export const ArtifactModalV2 = ({ data }: $TSFixMe) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const setSideComponent = useDrawerStore(sideComponentSetter);

  const [scenarios] = useScenariosStore((state) => [state.scenarios]);

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(null, scenarios, data?.scenarioId),
    [data?.scenarioId, scenarios]
  );

  const { data: artifact, isLoading: isArtifactLoading } = useArtifactByName(
    data.name,
    data?.isJobCanvas ? data?.scenarioId : undefined,
    data?.isJobCanvas ? data?.jobProps?.jobRunId : undefined,
    { cacheTime: Infinity, refetchOnMount: true }
  );

  const {
    data: artifactEntity,
    isLoading,
    refetch
  } = useEntities({
    id: data.itemId,
    scenarioId: data?.isJobCanvas ? data?.scenarioId : undefined,
    jobRunId: data?.isJobCanvas ? data?.jobProps?.jobRunId : undefined,
    options: {
      cacheTime: Infinity,
      refetchOnMount: true,
      onError: (e) => {
        handleResponse({
          errorMessage: getErrorMessage(e) || `Error in fetching artifact - ${data.name}`
        });
      }
    }
  });

  const getNavigatePath = () => {
    if (!!data?.jobProps) {
      return generatePath(`${WebPaths.JobRoutes}${WebPaths.JobArtifacts}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        artifactName: data.name
      });
    } else {
      return generatePath(WebPaths.ArtifactsDetails, { artifactName: data.name });
    }
  };

  const handleViewArtifactClick = () => {
    const path = getNavigatePath();

    if (!!data?.jobProps) {
      data?.jobProps?.jobPathsSetter(
        `/projects/${data.projectId}/jobs/${data?.jobProps?.jobId}/job-runs/${data?.jobProps?.jobRunId}/job-canvas`
      );
    }

    navigate({ pathname: path }, { state: { prevPath: location.pathname } });
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const onClose = () => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  return (
    <NewThemeWrapper>
      <Box className={classes.drawerContent}>
        {artifactEntity && (
          <DeleteNodeModal
            open={isDeleteModalOpen}
            deleteNote="Deleting this might impact associated dataapps(if any)"
            onClose={() => {
              setIsDeleteModalOpen(false);
            }}
            nodeId={data.itemId}
            nodeName={data.label}
            nodeType="artifact"
            onAfterSubmit={() =>
              setSideComponent({ sideComponent: null, sideComponentProps: null })
            }
          />
        )}

        <SubTopNavBarWrapper
          variant="drawer"
          onDrawerClose={onClose}
          subTopNavBarLeftSection={{
            component: (
              <>
                <ArtifactReadonly data={data} />
                <RenderText
                  color="textSecondary"
                  isOverflowTooltip
                  linkTo={getNavigatePath()}
                  data-testid="sidebar-artifact-name">
                  {data?.label}
                </RenderText>
              </>
            )
          }}
        />

        <div className={classes.content} style={{ marginTop: 44 }}>
          {isLoading || isArtifactLoading ? (
            <Spinner />
          ) : (
            <>
              <Card className={classes.cardMargin}>
                <CardContent>
                  <Grid container direction="row" wrap="nowrap">
                    <Grid
                      container
                      item
                      md={11}
                      xs={10}
                      className={classes.datasetItemGap}
                      wrap="nowrap">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<EyeIcon viewBox="0 0 20 20" />}
                        onClick={handleViewArtifactClick}>
                        Preview
                      </Button>
                    </Grid>
                    {!data?.isJobCanvas && isDefaultScenario && (
                      <Grid container item md={1} xs={2} className="moreIcon">
                        <MoreOptions
                          options={[
                            {
                              label: "Delete",
                              icon: <TrashIcon viewBox="0 0 20 22" />,
                              action: onDelete
                            }
                          ]}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
              {artifactEntity?.markdown && (
                <Card className={classes.cardMargin}>
                  <DrawerAbout
                    markdownString={artifactEntity?.markdown}
                    projectId={data.projectId}
                    elementId={data.itemId}
                    hiddenUpdateContentAction={!!data?.isJobCanvas}
                    type="ARTIFACT"
                    onSuccess={refetch}
                  />
                </Card>
              )}
              <Card className={classes.cardMargin}>
                <CardContent>
                  <Box className={classes.detailsWrapper}>
                    <Box className={classes.detailItem}>
                      <Typography className={classes.detailName}>Created:</Typography>
                      <Typography>{dateFormat(artifactEntity?.created)}</Typography>
                    </Box>
                    <Box className={classes.detailItem}>
                      <Typography className={classes.detailName}>Updated:</Typography>
                      <Typography>{dateFormat(artifactEntity?.updated)}</Typography>
                    </Box>
                    <Box className={classes.detailItem}>
                      <Typography className={classes.detailName}>Build:</Typography>
                      <Typography>{dateFormat(artifactEntity?.built)}</Typography>
                    </Box>
                    {artifact?.producer && (
                      <Box className={classes.detailItem}>
                        <Typography className={classes.detailName}>Parent Transform:</Typography>
                        <Typography> {artifact?.producer.recipeName}</Typography>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </>
          )}
        </div>
      </Box>
    </NewThemeWrapper>
  );
};
