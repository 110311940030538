import { RecipeTypes } from "src/pages/private/ProjectsModule/utils/Projects.constants";

export enum NotificationTypes {
  OOM = "OOM",
  PROJECT_MARKDOWN = "PROJECT_MARKDOWN",
  LOW_DISK = "LOW_DISK",
  HIGH_CPU = "HIGH_CPU",
  DATAAPP_LAUNCH = "DATAAPP_LAUNCH",
  EDA_CHARTS = "EDA_CHARTS",
  EDA_ADDITIONAL_CHARTS = "EDA_ADDITIONAL_CHARTS",
  EDA_INFO = "EDA_INFO",
  RECIPE_RUN = "RECIPE_RUN",
  PREDICTION_JOB_RUN = "PREDICTION_JOB_RUN",
  JOB_RUN = "JOB_RUN",
  CUSTOM_ENV_LAUNCH = "CUSTOM_ENV_LAUNCH",
  PROJECT_SNIPPET = "PROJECT_SNIPPET",
  QUEUE_RECIPE = "QUEUE_RECIPE"
}

export enum NotificationParentTypes {
  CustomEnv = "CUSTOM_ENV",
  PROJECT = "PROJECT",
  DataApp = "DATAAPP",
  Eda = "EDA",
  BYOS = "BYOS",
  BYOR = "BYOR",
  Recipe = "RECIPE",
  PredictionJob = "PREDICTION_JOB",
  Job = "JOB"
}

export enum NotificationStatus {
  Triggered = "TRIGGERED",
  Success = "SUCCESS",
  Error = "ERROR",
  RecipeTimeout = "RECIPE_TIMED_OUT",
  JobTimeout = "TIMED_OUT",
  Handled = "HANDLED"
}

export interface NotificationResponse {
  id: string;
  created: number;
  updated: 0;
  creator: string;
  updater: string;
  parentId: string;
  parentName: string;
  parentType: NotificationParentTypes;
  type: NotificationTypes;
  status: NotificationStatus;
  extraInfo: {
    projectId: string;
    scenarioId: string;
    projectName: string;
    lastRunEntryId?: string;
    dataAppDisplayName?: string;
    envType?: string;
    envId: string;
    recipeName: string;
    recipeId?: string;
    recipeType: RecipeTypes;
  };
  read: boolean;
  matching: NotificationResponse[];
}

export interface Notification extends NotificationResponse {
  icon?: JSX.Element;
  tooltip: string;
  text: string;
  label: JSX.Element | string;
  extra?: JSX.Element;
  frequency: number;
  loader?: JSX.Element;
}
