import Button from "@material-ui/core/Button";
import CachedIcon from "@material-ui/icons/Cached";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams, useLocation, generatePath } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { UseGetDatasetDataInfiniteQueryKeys } from "src/hooks/api/projects/useEntityDataAndStats";

import AddMenu from "./AddMenu";
import DeleteNodeModal from "./DeleteNodeModal/DeleteNodeModal";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import ReloadDatasetModal from "./ViewDataData/ReloadDatasetModal";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { ExportIcon } from "src/icons/NewUX/ExportIcon";
import { ScenarioSettings } from "../../icons/ScenarioSettings";
import { Section } from "./ViewDataRoutes";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { checkIfDefaultScenario } from "../Scenarios/helpers/scenarios.helpers";
import { downloadEntity } from "src/api/projects";
import { toastWrapper } from "src/utils/toastWrapper";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { useJobsContext } from "src/pages/private/ProjectsModule/pages/Jobs/context/useJobsContext";
import { useProjectsStore, useScenariosStore } from "../../store/store";
// Components
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import _ from "lodash";
import { WebPaths } from "src/routing/routes";
import { thirdPartyTypeName } from "../DataSources/utils/DataSources.constants";
import { AIGuideMiniIcon } from "src/icons/AIGuideMiniIcon";
import AIGuideDialog from "../Projects/AIGuide/common/AIGuideDialog";
import {
  ThreadResponseDtoTargetTypeEnum,
  ThreadResponseDtoDatasetContextEnum
} from "openapi/Models/thread-response-dto";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { CACHE_QUERY_KEY_FETCH_DATASET_AUDIT_HISTORY } from "src/hooks/api/entities/useEntityAuditHistory";

const useStyles = makeStyles({
  aiGuideBtn: {
    height: "28px",
    width: "28px",
    scale: 1.4
  }
});
const ViewDataHeader = ({
  entityDetails,
  handleAddSegment,
  scenarioData,
  jobData,
  jobRunId,
  section,
  jobRunName,
  isEntityLoading,
  entity
}: $TSFixMe) => {
  const { projectId, entityId, scenarioId } = useParams<$TSFixMe>();
  const classes = useStyles();

  const queryClient = useQueryClient();

  const scenarios = useScenariosStore((state) => state.scenarios);

  // Project context
  const { project } = useProjectContext() || {};

  // Jobs context
  const { jobPaths, jobPathsSetter } = useJobsContext() || {};

  const setModifiedDatasetId = useProjectsStore((state) => state.setModifiedDatasetId);

  const [isDownloading, setIsDownloading] = React.useState<$TSFixMe>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [showAIGuideDialog, setShowAIGuideDialog] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);
  const { canViewSegments, canAddStandardRecipe } = useAccessControlContext();

  const isDatasetOutput = useMemo(() => {
    return Boolean(entityDetails?.parentTransform);
  }, [entityDetails]);

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(scenarioData, scenarios, scenarioId),
    [scenarioData, scenarios, scenarioId]
  );

  const handleOpenDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(true);
  }, []);
  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);

  const handleScenarioSettings = useCallback(() => {
    if (projectId && scenarioId) {
      navigate(generatePath(WebPaths.EditScenarios, { projectId, scenarioId }));
    }
  }, [projectId, scenarioId]);

  const handleOpenAddFileModal = () => {
    setOpenConfirmModal(true);
  };

  const handleAddTransformClick = useCallback(() => {
    navigate(`/projects/${projectId}/scenario/${scenarioId}/add-recipe?entity=${entityId}`);
  }, [projectId, scenarioId, entityId]);

  const handleAddCodeRecipeClick = useCallback(() => {
    navigate(`/projects/${projectId}/scenario/${scenarioId}/add-code-recipe?entity=${entityId}`);
  }, [projectId, scenarioId, entityId]);

  const handleAddAutoMLRecipeClick = () => {
    navigate(`/projects/${projectId}/scenario/${scenarioId}/add-auto-ml-recipe?entity=${entityId}`);
  };

  const handleAddApiConnectorRecipe = () => {
    const url = `${WebPaths.APIConnectorRecipeContainer}?entity=${entityId}`;
    navigate(
      generatePath(url, {
        projectId,
        scenarioId
      })
    );
  };

  const downloadCSV = useCallback(async () => {
    setIsDownloading(() => true);

    if (!entityId) {
      return;
    }

    projectId && checkEnvRelaunch(projectId);

    try {
      const downloadEntityResponse: $TSFixMe = await downloadEntity({
        entityId,
        scenarioId,
        ...(!!jobRunId ? { projectRunEntryId: jobRunId } : {})
      });

      !!downloadEntityResponse &&
        window.open(downloadEntityResponse.replace(": //", "://"), "_blank");
    } catch (error: $TSFixMe) {
      toastWrapper({
        type: "error",
        content: "No file connected to this dataset!"
      });
    }

    setIsDownloading(() => false);
  }, [entityId, scenarioId]);

  // const handleEntityNameSubmit = useCallback(
  //   async (newEntityName: $TSFixMe) => {
  //     try {
  //       setIsEntityLoading(true);
  //       const responseData = await putAPIWithRethrow(`/v2/entities`, {
  //         // @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type 'nev... Remove this comment to see the full error message
  //         displayName: newEntityName || entity?.displayName,
  //         // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
  //         id: entity.id,
  //         // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
  //         description: entity.description
  //       });
  //       setEntity(responseData);
  //       setEntities([]);
  //     } catch (e) {
  //       toastWrapper({
  //         type: "error",
  //         content: "There was a problem changing the name, please try again"
  //       });
  //     }
  //     setIsEntityLoading(false);
  //   },
  //   [
  //     entity?.description,
  //     entity?.displayName,
  //     entity?.id,
  //     setEntities
  //   ]
  // );

  const handleDeleteSuccess = () => {
    // Navigate back to canvas post data set delete success
    if (projectId && scenarioId) {
      navigate(-1);
      // navigate(generatePath(`${WebPaths.Dag}${WebPaths.Canvas}`, { projectId, scenarioId }));
    }
  };

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  const onAddDatasetAction = useCallback(() => {
    if (projectId && scenarioId && entityId) {
      navigate(
        generatePath(WebPaths.AppendDataset, { projectId, scenarioId, datasetId: entityId })
      );
    }
  }, [scenarioId, projectId]);

  const isBuiltEntity = useMemo(() => ["BUILT"].includes(entity?.status), [entity?.status]);

  const handleReload = () => {
    setOpen(true);
  };

  const handleSuccess = async () => {
    setOpen(false);

    if (section !== Section.analysis && entityId) {
      setModifiedDatasetId(entityId);
    }

    await queryClient.invalidateQueries([UseGetDatasetDataInfiniteQueryKeys.InfiniteDatasetData]);
    await queryClient.invalidateQueries([CACHE_QUERY_KEY_FETCH_DATASET_AUDIT_HISTORY]);
  };

  return (
    <>
      <DeleteNodeModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        nodeType="entity"
        nodeId={entityId}
        nodeName={entity?.displayName || ""}
        onDeleteSuccess={handleDeleteSuccess}
      />
      <ReloadDatasetModal
        id={entityId}
        name={entity?.name}
        open={open}
        isFiveTran={entity?.dataSourceType === thirdPartyTypeName}
        projectId={projectId}
        onCancel={() => setOpen(false)}
        onSuccess={handleSuccess}
      />
      {openConfirmModal && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title="Add File"
          content={[
            "If you make any changes to the dataset, it will render the current runs of the associated recipe(s) with this input dataset invalid, marking them as UNBUILT. To implement the changes, it is necessary to rerun the linked recipe(s). Also the associated segment(if any) will be deleted, and any custom scenarios using it will default to the entire dataset instead of the segment.",
            "Are you sure you want to proceed with this?"
          ]}
          submitLabel="Yes, Proceed"
          onClose={handleCancel}
          onSubmit={onAddDatasetAction}
        />
      )}
      <AIGuideDialog
        open={showAIGuideDialog}
        onClose={() => {
          setShowAIGuideDialog(false);
        }}
        projectId={projectId!}
        scenarioId={scenarioId!}
        datasetId={entityId!}
        targetType={ThreadResponseDtoTargetTypeEnum.Dataset}
        datasetContext={ThreadResponseDtoDatasetContextEnum.Dataset}
      />
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          ...(isJobPath
            ? {
                backNavAction: () => {
                  jobPathsSetter(jobPaths.slice(0, -1));
                }
              }
            : {
                backNavPath:
                  projectId && scenarioId
                    ? generatePath(`${WebPaths.Dag}${WebPaths.Canvas}`, {
                        projectId,
                        scenarioId
                      })
                    : WebPaths.Dashboard
              }),
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              isJobPath={isJobPath}
              jobData={jobData}
              section={section}
              jobRunId={jobRunId}
              jobRunName={jobRunName}
              entityName={entity?.displayName}
              isDefaultScenario={isDefaultScenario}
              scenarioName={scenarioData?.name}
            />
          )
        }}
        subTopNavBarRightSection={
          section === Section.advanceAnalysis
            ? {}
            : {
                moreOptions: [
                  ...(!!_.get(entityDetails, "rows")
                    ? [
                        {
                          label: "Export",
                          icon: <ExportIcon viewBox="0 0 22 24" />,
                          action: downloadCSV,
                          isDisabled: isDownloading,
                          isLoading: isDownloading
                        }
                      ]
                    : []),
                  ...(!isJobPath && isDefaultScenario
                    ? [
                        {
                          label: "Delete",
                          icon: <TrashIcon viewBox="0 0 20 22" />,
                          action: handleOpenDeleteModal
                        }
                      ]
                    : []),
                  ...(!!entity?.dataSourceId && !isJobPath && isDefaultScenario
                    ? [
                        {
                          label: "Reload",
                          icon: <CachedIcon style={{ width: "16px", height: "16px" }} />,
                          tooltip:
                            entity?.dataSourceType === thirdPartyTypeName
                              ? "Synchronizes the current dataset with latest data in corresponding data connector"
                              : "Synchronizes the current dataset with remote storage and retrieves the latest data",
                          action: handleReload
                        }
                      ]
                    : [])
                ],
                ...(!isJobPath && !isEntityLoading
                  ? {
                      component: isDefaultScenario ? (
                        <>
                          {!isJobPath && isBuiltEntity && (
                            <Tooltip title="AI Guide">
                              <IconButton
                                size="small"
                                onClick={() => setShowAIGuideDialog(true)}
                                className={classes.aiGuideBtn}>
                                <AIGuideMiniIcon viewBox="-4 -4 28 28" width={28} height={28} />
                              </IconButton>
                            </Tooltip>
                          )}
                          <AddMenu
                            // Datasets
                            isAddDatasetHidden={
                              !entityDetails?.rootEntity || !!entity?.dataSourceType
                            }
                            isAddDatasetDisabled={isDatasetOutput}
                            addDataset={handleOpenAddFileModal}
                            // Recipes
                            isUnbuilt={!isBuiltEntity}
                            addTemplateRecipe={handleAddTransformClick}
                            addAiAssistedRecipe={handleAddCodeRecipeClick}
                            addRapidModelRecipe={handleAddAutoMLRecipeClick}
                            onAddApiConnectorRecipe={handleAddApiConnectorRecipe}
                            canAddStandardRecipe={canAddStandardRecipe}
                            // Segments
                            isAddSegmentDisabled={isDatasetOutput}
                            addSegment={handleAddSegment}
                            canViewSegments={canViewSegments && entityDetails?.rootEntity}
                          />
                        </>
                      ) : (
                        <Button
                          color="primary"
                          size="small"
                          startIcon={
                            <ScenarioSettings width={18} height={18} viewBox="0 0 24 24" />
                          }
                          onClick={handleScenarioSettings}>
                          Scenario Settings
                        </Button>
                      )
                    }
                  : {})
              }
        }
      />
    </>
  );
};

export default ViewDataHeader;
