import { useQuery } from "@tanstack/react-query";

import { getEnvironments } from "src/api";

type EnvironmentsProps = {
  // useQuery options
  cacheTime?: number;
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
  onSuccess?: (data: any) => void;
  enabled?: boolean;
};

export const useGetEnvironments = (props: EnvironmentsProps = {}) => {
  const {
    // useQuery options
    cacheTime = 0,
    refetchOnMount = false,
    refetchOnWindowFocus = false,
    onSuccess,
    enabled
  } = props;

  return useQuery({
    queryKey: ["environments"],
    queryFn: () => {
      return getEnvironments();
    },
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    cacheTime,
    enabled,
    refetchOnMount,
    refetchOnWindowFocus,
    onSuccess
  });
};
