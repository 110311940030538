/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Condition } from './condition';
// May contain unused imports in some cases
// @ts-ignore
import { PipelineEntityDto } from './pipeline-entity-dto';
// May contain unused imports in some cases
// @ts-ignore
import { PipelineRunConfigDto } from './pipeline-run-config-dto';
// May contain unused imports in some cases
// @ts-ignore
import { PipelineTemplateDto } from './pipeline-template-dto';

/**
 * 
 * @export
 * @interface PipelineRecipeDto
 */
export interface PipelineRecipeDto {
    /**
     * 
     * @type {string}
     * @memberof PipelineRecipeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineRecipeDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineRecipeDto
     */
    'markdown'?: string;
    /**
     * 
     * @type {Condition}
     * @memberof PipelineRecipeDto
     */
    'condition'?: Condition;
    /**
     * 
     * @type {string}
     * @memberof PipelineRecipeDto
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineRecipeDto
     */
    'groupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineRecipeDto
     */
    'recipeType'?: PipelineRecipeDtoRecipeTypeEnum;
    /**
     * 
     * @type {Array<PipelineRunConfigDto>}
     * @memberof PipelineRecipeDto
     */
    'runConfigs'?: Array<PipelineRunConfigDto>;
    /**
     * 
     * @type {{ [key: string]: PipelineTemplateDto; }}
     * @memberof PipelineRecipeDto
     */
    'templateDtos'?: { [key: string]: PipelineTemplateDto; };
    /**
     * 
     * @type {Array<PipelineEntityDto>}
     * @memberof PipelineRecipeDto
     */
    'parentEntityDtos'?: Array<PipelineEntityDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PipelineRecipeDto
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof PipelineRecipeDto
     */
    'positionX'?: number;
    /**
     * 
     * @type {number}
     * @memberof PipelineRecipeDto
     */
    'positionY'?: number;
    /**
     * 
     * @type {number}
     * @memberof PipelineRecipeDto
     */
    'timeout'?: number;
    /**
     * 
     * @type {string}
     * @memberof PipelineRecipeDto
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineRecipeDto
     */
    'image'?: string;
}

export const PipelineRecipeDtoRecipeTypeEnum = {
    Standard: 'STANDARD',
    Code: 'CODE',
    AutoMl: 'AUTO_ML',
    ApiConnector: 'API_CONNECTOR'
} as const;

export type PipelineRecipeDtoRecipeTypeEnum = typeof PipelineRecipeDtoRecipeTypeEnum[keyof typeof PipelineRecipeDtoRecipeTypeEnum];


