import React from "react";

// Packages
import { ColumnDefResolved } from "@tanstack/react-table";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { find, findIndex, map, size } from "lodash";
import clsx from "clsx";

// MUI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";

// Icons
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

// Components
import OverflowTooltip from "src/components/OverflowTooltip";

// Constants
import { TableConfig } from "../../utils/Table.constants";

// Types
import { TData } from "../../Table.types";

// Styles
import useStyles from "./Columns.styles";

type Props = {
  maxColumnsCount?: number;
  columns: ColumnDefResolved<TData, any>[];
  setColumns: $TSFixMeFunction;
  filteredColumns: ColumnDefResolved<TData, any>[];
  setFilteredColumns: $TSFixMeFunction;
  selectedColumns: ColumnDefResolved<TData, any>[];
  onColumnsReorder: $TSFixMeFunction;
};

const Columns = (props: Props) => {
  const {
    maxColumnsCount,
    columns,
    setColumns,
    filteredColumns,
    setFilteredColumns,
    selectedColumns,
    onColumnsReorder
  } = props || {};

  const classes = useStyles();

  const onDragEnd = (result: DropResult) => {
    if (!result?.destination) return;

    const reorderedColumns = [...filteredColumns];
    const [removed] = reorderedColumns?.splice(result?.source?.index, 1);
    reorderedColumns?.splice(result?.destination?.index, 0, removed);

    setFilteredColumns(() => reorderedColumns);
    onColumnsReorder(reorderedColumns);
  };

  const selectColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = event?.target?.checked;
    const targetValue = event?.currentTarget?.value;
    const targetIndex = findIndex(columns, { accessorKey: targetValue });

    if (
      size(selectedColumns) > (maxColumnsCount ?? TableConfig.MaxColumnsCount) - 1 &&
      isSelected
    ) {
      // @ts-ignore
      const firstSelectedColumn: ColumnDefResolved<any, any> = find(
        columns,
        { isSelected: true },
        targetIndex > (maxColumnsCount ?? TableConfig.MaxColumnsCount) - 1 ? undefined : targetIndex
      );

      setColumns(
        map(columns, (column: ColumnDefResolved<TData, string>) => {
          if (column?.accessorKey === targetValue) {
            return { ...column, isSelected: true };
          }

          if (firstSelectedColumn?.accessorKey === column?.accessorKey) {
            return { ...column, isSelected: false };
          }

          return column;
        })
      );

      setFilteredColumns(
        map(filteredColumns, (column: ColumnDefResolved<TData, any>) => {
          if (column?.accessorKey === targetValue) {
            return { ...column, isSelected: true };
          }

          if (firstSelectedColumn?.accessorKey === column?.accessorKey) {
            return { ...column, isSelected: false };
          }

          return column;
        })
      );
    } else {
      setColumns(
        map(columns, (column: ColumnDefResolved<TData, any>) => {
          if (column?.accessorKey === targetValue) {
            return { ...column, isSelected };
          } else {
            return column;
          }
        })
      );

      setFilteredColumns(
        map(filteredColumns, (column: ColumnDefResolved<TData, any>) => {
          if (column?.accessorKey === targetValue) {
            return { ...column, isSelected };
          } else {
            return column;
          }
        })
      );
    }
  };

  return (
    <>
      {/* Check all */}
      {/* <List className={classes.columnsListContainer} disablePadding>
        <ListItem button dense>
          <Checkbox
            id="toggleColumns"
            size="small"
            color="primary"
            className="columnCheckbox"
            disableRipple
            checked={table?.getIsAllColumnsVisible()}
            onChange={table?.getToggleAllColumnsVisibilityHandler()}
          />
          <ListItemText className="columnListItemText" primary="Select all" />
        </ListItem>
      </List> */}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <>
              <List
                className={clsx(classes.columnsListContainer, classes.fixedHeight)}
                disablePadding
                {...provided?.droppableProps}
                ref={provided?.innerRef}>
                {map(filteredColumns, (column: ColumnDefResolved<TData, any>, index: number) => (
                  <Draggable
                    key={column?.accessorKey}
                    draggableId={column?.accessorKey || `draggableId-${index}`}
                    index={index}>
                    {(provided) => (
                      <ListItem
                        key={`headerSelection_${index}`}
                        button
                        dense
                        className="columnListItem"
                        ref={provided?.innerRef}
                        {...provided?.draggableProps}>
                        <Checkbox
                          id={`toggleColumn-${index}`}
                          size="small"
                          color="primary"
                          className="columnCheckbox"
                          disableRipple
                          value={column?.accessorKey}
                          // @ts-ignore
                          checked={!!column?.isSelected}
                          onChange={selectColumn}
                        />
                        <ListItemText
                          className="columnListItemText"
                          primary={
                            <OverflowTooltip
                              style={{ whiteSpace: "nowrap" }}
                              value={column?.header}
                            />
                          }
                        />
                        <ListItemSecondaryAction {...provided?.dragHandleProps}>
                          <DragIndicatorIcon fontSize="small" color="disabled" />
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided?.placeholder}
              </List>
            </>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default Columns;
