import React from "react";

type ContextTypes = {
  isFromDatasetPage: boolean;

  scenarioId: string;
  project: $TSFixMe;

  isFetchingProjectScenarios: boolean;
  jobsData: $TSFixMe;
};

// @ts-ignore
export const DagContext = React.createContext<ContextTypes>();
