import React from "react";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import { SystemAdminIcon } from "src/icons/NewUX";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
    opacity: "0.9 !important"
  },
  container: {
    width: "auto",
    maxWidth: 300,
    rowGap: theme.spacing(2)
  }
}));

interface AskAiA11yBackdropProps {
  size?: "small" | "medium";
  styleProps?: any;
}

const AskAiA11yBackdrop: React.FC<AskAiA11yBackdropProps> = (props) => {
  const { size = "medium", styleProps } = props || {};

  const classes = useStyles();

  return (
    <Backdrop open invisible className={classes.root} style={{ ...styleProps }}>
      <Grid container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
        <Grid container direction="column" alignItems="center" className={classes.container}>
          {size === "small" && <SystemAdminIcon width={80} height={80} viewBox="0 0 128 128" />}
          {size === "medium" && <SystemAdminIcon />}
          <Typography variant="caption" align="center">
            <Box>Please get in touch with the</Box>
            <Box>system administrator to enable this action.</Box>
          </Typography>
          <Typography variant="caption" align="center">
            It requires access to OpenAI API.
          </Typography>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default AskAiA11yBackdrop;
