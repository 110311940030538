import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { IProjectData } from "./useGetProject";
import { putAPIWithRethrow } from "src/utils/apiService";

const useUpdateProject = (): UseMutationResult<unknown, unknown, IProjectData, unknown> => {
  return useMutation({
    mutationFn: async (body) => {
      return await putAPIWithRethrow(`/v2/projects`, body);
    }
  });
};

export default useUpdateProject;
