import React, { useEffect, useMemo, useState } from "react";

// Packages
import { useNavigate } from "react-router-dom";
import { filter, includes, orderBy, size, truncate } from "lodash";
import clsx from "clsx";

// MUI
import {
  Grid,
  Box,
  Menu,
  MenuItem,
  Button,
  Chip,
  Typography,
  makeStyles,
  Tooltip
} from "@material-ui/core";

// Icons
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { FileImportIcon } from "src/icons/NewUX";

// Utils
import { getFivetranConnectorStatus, getStatusColor } from "src/utils/fivetran";

// Stores
import useStoreSelectors from "../../hooks/useStoreSelectors";
import { useDataSourcesStore } from "src/store/store";
import { isCreateDataSourceSetter } from "src/store/store.selectors";

// Components
import { OverflowTooltip, SearchField } from "src/components";

// Contexts
import { useSourceContext } from "../../contexts/Source/useSourceContext";

// Constants
import {
  SourceType,
  Criteria,
  DatasetHelperText,
  FivetranStatus
} from "../../utils/Dataset.constants";
import { WebPaths } from "src/routing/routes";

const useStyles = makeStyles((theme) => ({
  sourceAction: {
    justifyContent: "space-between",
    padding: `${theme.spacing(2)}px ${theme.spacing(1.5)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
    "&:hover": {
      borderColor: theme.palette.text.primary,
      backgroundColor: theme.palette.common.white
    },
    "&:focus": {
      borderColor: theme.palette.primary.main,
      "& .legend": {
        color: theme.palette.primary.main
      }
    },
    "& .legend": {
      position: "absolute",
      top: -theme.spacing(1.5),
      left: theme.spacing(1),
      padding: `0 ${theme.spacing(0.5)}px`,
      backgroundColor: theme.palette.common.white
    }
  },
  menuItem: {
    columnGap: theme.spacing(0.5),
    "&.fileImport": {
      backgroundColor: "#f2f2ff"
    },
    "&.newConnection": {
      borderTop: "1px solid #e9ecef"
    }
  },
  sourceImage: {
    display: "inline-block",
    width: 20,
    height: 20,
    marginRight: theme.spacing(1)
  },
  sources: {
    flexWrap: "nowrap"
  },
  sourceOptionName: {
    maxWidth: "65%"
  },
  dropdownContainer: {
    width: 450,
    borderRadius: theme.spacing(1.5)
  },
  menuHeader: {
    marginTop: theme.spacing(1)
  },
  menuContainer: { marginBottom: theme.spacing(2), maxHeight: 220, overflow: "auto" },

  // $FixMe: Scope to be refactored.
  // The below styles are added only to support New UX. It shall be removed soon.
  searchField: {
    "& [class^='MuiInputBase-root']": {
      background: "none"
    },
    "& input": {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: "small"
    }
  }
}));

const NEW_CONNECTION = "newConnection";

const SourceDropdown = () => {
  const { sources, onSourceOrCriterionChange } = useSourceContext();

  const navigate = useNavigate();

  const newConnectorToSync = useDataSourcesStore((state) => state.newConnectorToSync);

  const classes: $TSFixMe = useStyles();

  // Stores - STARTS >>
  const setIsCreateDataSourceStore = useDataSourcesStore(isCreateDataSourceSetter);
  const {
    datasetDefaultDatasetStore,
    datasetSourceStore,
    setDatasetSourceStore,
    setDatasetCriterionStore
  } = useStoreSelectors();
  // << ENDS - Stores

  // States - STARTS >>
  const [menuMap, setMenuMap] = useState<$TSFixMe>({});
  const [searchTerm, setSearchTerm] = useState<$TSFixMe>("");
  const [anchorEl, setAnchorEl] = useState(null);
  // << ENDS - States

  const onClose = () => {
    setAnchorEl(null);
    setSearchTerm(() => "");
  };

  console.log(sources);

  const fivetranFilteredSources = useMemo(
    () =>
      filter(orderBy(sources, "updated", "desc"), (source) => {
        if (!source.isFivetran) {
          return true;
        }

        const isSameConnector = source?.id === newConnectorToSync?.id;
        const status = getFivetranConnectorStatus(
          source.thirdPartyDataSourceStatus,
          isSameConnector,
          newConnectorToSync?.created
        );

        return status !== FivetranStatus.inActive;
      }),
    [sources]
  );

  const onSearchChange = (event: $TSFixMe) => {
    setSearchTerm(event?.target?.value);
  };

  const searchFilteredSources = useMemo(
    () =>
      (fivetranFilteredSources || [])?.filter((eachField: $TSFixMe) =>
        Object.values(eachField || {})
          ?.join(" ")
          ?.toLowerCase()
          ?.includes(searchTerm?.toLowerCase())
      ),
    [searchTerm, fivetranFilteredSources]
  );

  useEffect(() => {
    const thisMenuMap: $TSFixMe = {
      [SourceType.FileUpload]: (
        <Grid container style={{ columnGap: 4 }}>
          <Typography color="textPrimary">
            <FileImportIcon viewBox="0 0 18 18" />
          </Typography>
          <Typography variant="body1" color="textPrimary">
            File Import
          </Typography>
        </Grid>
      )
    };

    searchFilteredSources?.forEach((eachSource: $TSFixMe) => {
      const isSameConnector = eachSource?.id === newConnectorToSync?.id;
      const thirdPartyDataSourceStatus = eachSource?.thirdPartyDataSourceStatus;

      const status =
        eachSource?.id === SourceType.FileUpload
          ? null
          : getFivetranConnectorStatus(
              thirdPartyDataSourceStatus,
              isSameConnector,
              newConnectorToSync?.created
            );

      const statusColor = status ? getStatusColor(status) : null;

      thisMenuMap[eachSource?.id] = (
        <Grid container direction="row" className={classes.sources}>
          <span id={`sourceOptionImage_${eachSource?.id}`} className={classes.sourceImage}>
            {eachSource.iconUrl ? (
              <img
                src={eachSource.iconUrl}
                alt={eachSource.dataSourceType}
                width="20px"
                height="20px"
              />
            ) : (
              eachSource?.image
            )}
          </span>
          <Typography
            variant="body1"
            color="textPrimary"
            id={`sourceOptionName_${eachSource?.id}`}
            className={classes.sourceOptionName}>
            {Boolean(anchorEl) ? (
              <OverflowTooltip value={eachSource?.name} />
            ) : (
              <Tooltip title={size(eachSource?.name) > 16 ? eachSource?.name : ""}>
                <span>{truncate(eachSource?.name, { length: 16 })}</span>
              </Tooltip>
            )}
          </Typography>
          {status && statusColor && (
            <Chip
              label={status}
              style={{
                color: statusColor,
                border: `1px solid ${statusColor}`,
                marginLeft: "auto"
              }}
              size="small"
              variant="outlined"
            />
          )}
        </Grid>
      );
    });

    setMenuMap(() => thisMenuMap);
  }, [searchFilteredSources, anchorEl]);

  const onSourceClick = (sourceId: string) => {
    if (sourceId === NEW_CONNECTION) {
      return;
    }

    if (!datasetDefaultDatasetStore?.id) {
      const thisCriterionStore = {
        value: Criteria.SingleFileUpload,
        isDisabled: false
      };

      setDatasetCriterionStore({ ...thisCriterionStore });
    }

    const thisSourceStore = datasetSourceStore;
    thisSourceStore.value = sourceId;

    setDatasetSourceStore({ ...thisSourceStore });

    onSourceOrCriterionChange();
  };

  const onCreateConnection = () => {
    setIsCreateDataSourceStore(true);
    navigate(WebPaths.DataConnectors);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="large"
        fullWidth
        className={classes.sourceAction}
        disabled={datasetSourceStore?.isDisabled}
        onClick={(event: $TSFixMe) => {
          setAnchorEl(event.currentTarget);
        }}
        endIcon={
          <ArrowDropDownIcon
            style={{ color: "#0000008a", opacity: datasetSourceStore?.isDisabled ? 0.5 : 1 }}
          />
        }>
        <Typography variant="caption" className="legend">
          {DatasetHelperText.Source}
        </Typography>
        {menuMap[datasetSourceStore?.value]}
      </Button>

      <Menu
        id="source"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        PopoverClasses={{ paper: classes.dropdownContainer }}>
        <SearchField
          id="dataConnectorSearch"
          placeholder="Search"
          fullWidth
          className={classes.searchField}
          value={searchTerm}
          onChange={onSearchChange}
        />

        <MenuItem
          id={`sourceOption_${SourceType.FileUpload}`}
          color="primary"
          className={clsx(classes.menuItem, "fileImport")}
          onClick={() => {
            onSourceClick(SourceType.FileUpload);
            onClose();
          }}>
          <Typography color="primary">
            <FileImportIcon viewBox="0 0 18 18" />
          </Typography>
          <Typography color="primary">File Import</Typography>
        </MenuItem>

        <MenuItem className={classes.menuHeader} disabled>
          Latest Data Connectors
        </MenuItem>

        <Box className={classes.menuContainer}>
          {Object.keys(menuMap)?.map(
            (eachSourceId: string) =>
              !includes([SourceType.FileUpload], eachSourceId) && (
                <MenuItem
                  id={`sourceOption_${eachSourceId}`}
                  key={`sourceOption_${eachSourceId}`}
                  value={eachSourceId}
                  onClick={() => {
                    onSourceClick(eachSourceId);
                    onClose();
                  }}>
                  {menuMap[eachSourceId]}
                </MenuItem>
              )
          )}
        </Box>

        <MenuItem
          id="sourceOption_newConnection"
          color="primary"
          className={clsx(classes.menuItem, "newConnection")}
          onClick={() => {
            onCreateConnection();
            onClose();
          }}>
          <AddRoundedIcon fontSize="small" color="primary" />
          <Typography color="primary">{DatasetHelperText.NewConnection}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SourceDropdown;
