/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { PipelineEntityFeature } from './pipeline-entity-feature';

/**
 * 
 * @export
 * @interface PipelineEntity
 */
export interface PipelineEntity {
    /**
     * 
     * @type {number}
     * @memberof PipelineEntity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntity
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntity
     */
    'updated'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntity
     */
    'entityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntity
     */
    'entityType'?: PipelineEntityEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntity
     */
    'entitySubType'?: PipelineEntityEntitySubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntity
     */
    'entityOntology'?: PipelineEntityEntityOntologyEnum;
    /**
     * 
     * @type {{ [key: string]: PipelineEntityFeature; }}
     * @memberof PipelineEntity
     */
    'entityFeatureMap'?: { [key: string]: PipelineEntityFeature; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PipelineEntity
     */
    'metaData'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof PipelineEntity
     */
    'entityKeys'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PipelineEntity
     */
    'entityDimensions'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntity
     */
    'runConfigStatus'?: PipelineEntityRunConfigStatusEnum;
}

export const PipelineEntityEntityTypeEnum = {
    None: 'NONE',
    Base: 'BASE',
    Event: 'EVENT',
    Dimension: 'DIMENSION',
    Relation: 'RELATION',
    Signal: 'SIGNAL',
    Rollupevent: 'ROLLUPEVENT',
    Timetick: 'TIMETICK'
} as const;

export type PipelineEntityEntityTypeEnum = typeof PipelineEntityEntityTypeEnum[keyof typeof PipelineEntityEntityTypeEnum];
export const PipelineEntityEntitySubTypeEnum = {
    None: 'NONE',
    DimensionLow: 'DIMENSION_LOW',
    DimensionHigh: 'DIMENSION_HIGH'
} as const;

export type PipelineEntityEntitySubTypeEnum = typeof PipelineEntityEntitySubTypeEnum[keyof typeof PipelineEntityEntitySubTypeEnum];
export const PipelineEntityEntityOntologyEnum = {
    None: 'NONE',
    User: 'USER',
    Email: 'EMAIL',
    Ip: 'IP',
    Phone: 'PHONE',
    Device: 'DEVICE',
    Product: 'PRODUCT',
    DeviceEvents: 'DEVICE_EVENTS',
    UserSignup: 'USER_SIGNUP',
    UserLogin: 'USER_LOGIN',
    Transaction: 'TRANSACTION',
    Payment: 'PAYMENT',
    Order: 'ORDER',
    Cart: 'CART',
    BlsMsa: 'BLS_MSA',
    City: 'CITY',
    Department: 'DEPARTMENT'
} as const;

export type PipelineEntityEntityOntologyEnum = typeof PipelineEntityEntityOntologyEnum[keyof typeof PipelineEntityEntityOntologyEnum];
export const PipelineEntityRunConfigStatusEnum = {
    DoNotRun: 'DO_NOT_RUN',
    RunOnIngress: 'RUN_ON_INGRESS',
    RunOnCascade: 'RUN_ON_CASCADE',
    RunOnTimetick: 'RUN_ON_TIMETICK'
} as const;

export type PipelineEntityRunConfigStatusEnum = typeof PipelineEntityRunConfigStatusEnum[keyof typeof PipelineEntityRunConfigStatusEnum];


