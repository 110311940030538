import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Grid, IconButton, TextField, Tooltip, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import Drawer from "components/Drawer/CustomDrawer";
import PreviewImageSelector from "pages/Projects/ProjectSettings/PreviewImageSelector";
import { DataAppType } from "../DataApps.type";
import { Spinner } from "src/components";
import { dataAppThumbNailImages } from "src/pages/DataApps/common/DataAppImages";
import { handleResponse } from "src/utils/apiService";
import { imageToBase64 } from "pages/Projects/helpers/projects.helpers";
import { useUpdateDataAppMutation } from "src/hooks/api";
import { validateNameField } from "src/utils/formFieldUtils";

const useStyles = makeStyles({
  drawerPaper: {
    width: "40%",
    overflowX: "hidden"
  },
  btnList: {
    height: "70px",
    backgroundColor: "#F5F5F5",
    padding: "20px",
    alignItems: "center",
    gap: "20px"
  },

  outlinedBtn: {
    color: "#008FE4",
    borderColor: "#008FE4"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "capitalize",
    fontSize: "14px"
  },
  titleString: {
    fontSize: "18px"
  },
  previewImage: {
    width: "auto"
  },
  rightInputs: {
    flexWrap: "nowrap",
    gap: "30px",
    width: "100%"
  },
  inputsWrap: {
    flexWrap: "nowrap",
    gap: "16px",
    padding: "16px"
  }
});

export const EditDataAppDrawer = ({
  dataApp,
  onClose
}: {
  dataApp: DataAppType;
  onClose: () => void;
}) => {
  const classes = useStyles();
  const [image, setImage] = useState(dataApp.iconUrl);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      name: dataApp.displayName || dataApp.name,
      description: dataApp.description
    }
  });
  const dataAppName = watch("name");
  const dataAppDescription = watch("description");
  const updateDataAppMutation = useUpdateDataAppMutation();

  const onSubmit = (data: { name: string; description: string }) => {
    const { error } = validateNameField({
      fieldName: data.name.trim(),
      fieldNameLabel: `dataApp name`
    });
    if (error) {
      handleResponse({ errorMessage: error });
      return;
    }
    updateDataAppMutation.mutate(
      {
        dataAppId: dataApp.id,
        dataAppName: dataApp.name?.trim(),
        params: { displayName: data.name, description: data.description, iconUrl: image }
      },
      {
        onSuccess: () => {
          handleResponse({ successMessage: "DataApp updated successfully." });
          onClose();
        }
      }
    );
  };
  const footer = (
    <Grid container direction="row-reverse" className={classes.btnList}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={
          !dataAppName ||
          updateDataAppMutation.isLoading ||
          (dataAppName === (dataApp.displayName || dataApp.name) &&
            dataAppDescription === dataApp.description &&
            dataApp.iconUrl === image)
        }
        test-id="dataapp-edit-save-btn">
        {updateDataAppMutation.isLoading ? <Spinner size={24} noPadding /> : "Save"}
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={onClose}
        test-id="dataapp-edit-cancel-btn">
        Cancel
      </Button>
    </Grid>
  );

  const title = (
    <div className={classes.title}>
      <div className={classes.titleString}>Edit DataApp</div>
      <IconButton onClick={onClose} color="primary">
        <Close />
      </IconButton>
    </div>
  );

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      test-id="dataapp-config-drawer"
      hideCloseButton
      open
      width="40%"
      title={title}
      classes={{
        paper: classes.drawerPaper
      }}
      footer={footer}
      onClose={onClose}>
      <Grid container direction="row" className={classes.inputsWrap}>
        <Grid item className={classes.previewImage}>
          <PreviewImageSelector
            images={dataAppThumbNailImages}
            noText
            onChange={(img: $TSFixMe) => imageToBase64(img, setImage)}
            defaultImage={image}
          />
        </Grid>
        <Grid container item direction="column" className={classes.rightInputs}>
          <TextField
            required
            fullWidth
            size="small"
            {...register("name", {
              required: "The dataApp name cannot be blank.",
              setValueAs: (val: string) => val?.trim()
            })}
            error={!!errors.name}
            helperText={errors.name && <>{errors.name?.message}</>}
            label="DataApp Name"
            variant="outlined"
          />
          <Tooltip title={dataAppDescription ?? ""}>
            <TextField
              fullWidth
              multiline
              maxRows={6}
              minRows={6}
              size="small"
              {...register("description", {
                setValueAs: (val: string) => val?.trim()
              })}
              error={!!errors.description}
              helperText={errors.description && <>{errors.description?.message}</>}
              label="DataApp Description"
              variant="outlined"
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Drawer>
  );
};
