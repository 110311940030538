import React from "react";
import {
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";

import { ConfigLlmModelEnum } from "openapi/Models/config";
import { dataAppConfigFields } from "./CreateDataAppForm";

interface IProps {
  value: ConfigLlmModelEnum;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectAskAILLMModel: React.FC<IProps> = (props) => {
  return (
    <Card style={{ padding: "16px" }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Models</FormLabel>
        <RadioGroup
          aria-label={dataAppConfigFields.llmModel}
          name={dataAppConfigFields.llmModel}
          value={props.value}
          onChange={props.onChange}>
          <FormControlLabel
            value={ConfigLlmModelEnum.OpenaiGpt}
            control={<Radio />}
            label="OpenAI GPT"
          />
        </RadioGroup>
      </FormControl>
    </Card>
  );
};

export default SelectAskAILLMModel;
