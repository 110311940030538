import React, { useMemo } from "react";
import _ from "lodash";

import DataConnectorStatus from "./DataSource/DataConnectorStatus";
import { Delete } from "src/icons/Delete";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { PencilUnderlined } from "src/icons/PencilUnderlined";
import { Table } from "src/components";
import OverflowTooltip from "components/OverflowTooltip";
import { dateFormat } from "src/utils/dateFormat";
import { getFivetranConnectorStatus } from "src/utils/fivetran";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";
import { useDataSourcesStore } from "src/store/store";
import { UseTableRowProps } from "react-table";

const DataSourcesTable = (props: $TSFixMe) => {
  const { dataSources, editDataSource, deleteDataSource, onManualSync } = props || {};
  const newConnectorToSync = useDataSourcesStore((state) => state.newConnectorToSync);

  const dataSourceSortType = useMemo(
    () => (rowA: UseTableRowProps<any>, rowB: UseTableRowProps<any>) => {
      const valA =
        _.get(rowA.original, "dataSourceType") === thirdPartyTypeName
          ? _.get(rowA.original, "tpConnectorType")
          : _.get(rowA.original, "dataSourceType");
      const valB =
        _.get(rowB.original, "dataSourceType") === thirdPartyTypeName
          ? _.get(rowB.original, "tpConnectorType")
          : _.get(rowB.original, "dataSourceType");

      if (_.lowerCase(valA) > _.lowerCase(valB)) {
        return 1;
      }
      if (_.lowerCase(valA) < _.lowerCase(valB)) {
        return -1;
      }

      return 0;
    },
    []
  );

  const statusSortType = useMemo(
    () => (rowA: UseTableRowProps<any>, rowB: UseTableRowProps<any>) => {
      const valA = getFivetranConnectorStatus(
        _.get(rowA.original, "thirdPartyDataSourceStatus"),
        rowA?.original?.id === newConnectorToSync?.id,
        newConnectorToSync?.created
      );
      const valB = getFivetranConnectorStatus(
        _.get(rowB.original, "thirdPartyDataSourceStatus"),
        rowB?.original?.id === newConnectorToSync?.id,
        newConnectorToSync?.created
      );

      if (_.lowerCase(valA) > _.lowerCase(valB)) {
        return 1;
      }
      if (_.lowerCase(valA) < _.lowerCase(valB)) {
        return -1;
      }

      return 0;
    },
    [newConnectorToSync?.id, newConnectorToSync?.created]
  );

  const columns = [
    {
      id: "Connection Name",
      accessor: "name",
      Header: "Connection Name",
      width: 180,
      isSortable: true,
      isTooltip: false,
      Cell: ({ row, value }: $TSFixMe) => {
        const handleClick = () => {
          editDataSource({
            ...row?.original,
            connectCardUrl: row?.original?.options?.connectCardUrl
          });
        };

        return (
          <LinkCellWithMenu
            style={{ cursor: "pointer" }}
            url={
              row?.original?.dataSourceType === thirdPartyTypeName
                ? ""
                : `/data-connectors/${row?.original?.id}`
            }
            linkText={<OverflowTooltip style={{ whiteSpace: "nowrap" }} value={value} />}
            menuButtons={[
              {
                id: "editDataSource",
                label: "EDIT",
                icon: <PencilUnderlined />,
                action: handleClick
              },
              {
                id: "deleteDataSource",
                label: "DELETE",
                icon: <Delete />,
                action: () => deleteDataSource(row?.original?.id)
              }
            ]}
            onClick={handleClick}
          />
        );
      }
    },
    {
      id: "Type",
      accessor: "dataSourceType",
      Header: "Type",
      isSortable: true,
      sortType: dataSourceSortType,
      Cell: ({ row, value }: $TSFixMe) =>
        row?.original?.dataSourceType === thirdPartyTypeName
          ? row.original.tpConnectorType
          : value ?? null
    },
    {
      id: "Status",
      accessor: "thirdPartyDataSourceStatus",
      Header: "Status",
      isSortable: true,
      sortType: statusSortType,
      Cell: ({ row }: $TSFixMe) => {
        const tpStatus = row?.original?.thirdPartyDataSourceStatus;
        const isSameConnector = row?.original?.id === newConnectorToSync?.id;
        const status = getFivetranConnectorStatus(
          tpStatus,
          isSameConnector,
          newConnectorToSync?.created
        );

        return (
          <DataConnectorStatus id={row?.original?.id} status={status} onManualSync={onManualSync} />
        );
      }
    },
    {
      id: "projects",
      accessor: "projects",
      Header: "Projects",
      isSortable: true
    },
    {
      id: "Updated by",
      accessor: "updater",
      Header: "Updated by",
      isSortable: true
    },
    {
      id: "Updated on",
      accessor: "updated",
      Header: "Updated on",
      isSortable: true,
      Cell: (row: $TSFixMe) => <span>{dateFormat(row?.value)}</span>
    }
  ];

  return (
    <Table
      data={dataSources}
      size="small"
      fixedLayout={true}
      hideCount
      columns={columns}
      isCellSortEnabled
      orderByDefault="Updated on"
      maxHeight="calc(100vh - 339px)"
      isTheadSticky
    />
  );
};

export default DataSourcesTable;
