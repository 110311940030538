import React, { useMemo, useCallback, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import shallow from "zustand/shallow";
import SettingsOverscanIcon from "@material-ui/icons/SettingsOverscan";
import clsx from "clsx";
import { deleteAPIWithRethrow, postAPIWithRethrow } from "../../utils/apiService";

import styles from "./Scenarios.module.scss";

import { useScenariosStore } from "../../store/store";
import { dateFormat } from "src/utils/dateFormat";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { ScenarioDeletePromptDetails } from "./helpers/scenarios.helpers";
import { ConfirmScreen, Table } from "../../components";
import { LinkCellWithMenu } from "../../components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { Delete } from "../../icons/Delete";
import { Run } from "../../icons/Run";
import { PencilUnderlined } from "../../icons/PencilUnderlined";
import { toastWrapper } from "src/utils/toastWrapper";
import { WebPaths } from "src/routing/routes";
import { QUERY_KEY_SCENARIOS } from "src/hooks/api";
import { useQueryClient } from "@tanstack/react-query";

const StatusCell = ({ cell }: $TSFixMe) => {
  return (
    <td className={clsx({ [styles.statusCellSuccess]: cell.value === "success" })}>{cell.value}</td>
  );
};

const SegmentsCell = ({ row }: $TSFixMe) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowClick = (e: $TSFixMe) => {
    setShowMore(e);
  };

  return (
    <>
      {row?.original?.segments?.length < 4 ? (
        <span className={styles.headerCellContainer}>
          {row?.original?.segments?.map((value: $TSFixMe) => value.name).join(", ")}
        </span>
      ) : (
        <div className={styles.showMore}>
          {!showMore ? (
            <div>
              <span>
                {row?.original?.segments
                  ?.map((value: $TSFixMe) => value.name)
                  .slice(0, 4)
                  .join(", ") + "..."}
              </span>
              <span className={styles.showMoreButton} onClick={() => handleShowClick(true)}>
                {row?.original?.segments?.length - 4} more
              </span>
            </div>
          ) : (
            <div>
              <span>
                {row?.original?.segments?.map((value: $TSFixMe) => value.name).join(", ")}
              </span>
              <span className={styles.showMoreButton} onClick={() => handleShowClick(false)}>
                Show Less
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const ScenariosTable = ({
  data,
  searchValue,
  isLoading,
  enableRun = false,
  projectId
}: $TSFixMe) => {
  const navigate = useNavigate();
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [targetScenario, setTargetScenario] = useState("");

  const [setIsScenariosLoading] = useScenariosStore(
    useCallback((state) => [state.setIsScenariosLoading], []),
    shallow
  );

  const onDisplayOnCanvas = useCallback((projectId: string, scenarioId: string) => {
    const path = generatePath(`${WebPaths.Dag}${WebPaths.Canvas}`, { projectId, scenarioId });
    navigate(path, { replace: true });
  }, []);

  const promptConfirmDeleteScenario = (scenarioId: string) => {
    setTargetScenario(scenarioId);
    setShowConfirmScreen(true);
  };

  const cancelDeleteScenario = () => {
    setShowConfirmScreen(false);
  };

  const confirmDeleteScenario = () => {
    deleteScenario(targetScenario);
    setShowConfirmScreen(false);
  };

  const queryClient = useQueryClient();
  const deleteScenario = async (scenarioId: string) => {
    setIsScenariosLoading(true);
    try {
      // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
      await deleteAPIWithRethrow(`/v2/scenarios/${scenarioId}`);

      toastWrapper({
        type: "success",
        content: "Scenario deleted successfully!"
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_SCENARIOS, projectId],
        refetchType: "all"
      });
    } catch (e) {
      console.error(e);
    }
    setIsScenariosLoading(false);
  };

  const onRunScenario = useCallback(
    (projectId: string, scenarioId: string) => {
      projectId && checkEnvRelaunch(projectId);

      try {
        // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 1.
        postAPIWithRethrow(`/v2/projects/run/${projectId}?scenarioId=${scenarioId}`);
        onDisplayOnCanvas(projectId, scenarioId);
      } catch (e) {
        console.error(e);
        // toastWrapper({
        //   type: "error",
        //   content: "Scenario run failed"
        // });
      }
    },
    [onDisplayOnCanvas]
  );

  const onEditScenario = useCallback((projectId: string, scenarioId: string) => {
    const path = generatePath(WebPaths.EditScenarios, { projectId, scenarioId });
    navigate(path);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: "Scenario Name",
        id: "Scenario Name",
        accessor: "name",
        isTooltip: false,
        Cell: ({ row, value }: $TSFixMe) => (
          <LinkCellWithMenu
            url={
              !row.original.default
                ? `/scenarios/${row.original.projectId}/edit-scenario/${row.original.id}`
                : ""
            }
            linkText={value}
            menuButtons={[
              {
                disabled: row.original.default,
                icon: <PencilUnderlined />,
                label: "EDIT",
                action: () => onEditScenario(row?.original?.projectId, row?.original?.id)
              },
              {
                disabled: !enableRun,
                icon: <Run />,
                label: "RUN",
                action: () => onRunScenario(row?.original?.projectId, row?.original?.id)
              },
              {
                icon: <SettingsOverscanIcon />,
                label: "DISPLAY ON CANVAS",
                action: () => onDisplayOnCanvas(row?.original?.projectId, row?.original?.id)
              },
              {
                disabled: row.original.default,
                icon: <Delete />,
                label: "DELETE",
                action: () => promptConfirmDeleteScenario(row?.original?.id)
              }
            ]}
          />
        )
      },
      {
        Header: "Segments",
        id: "Segments",
        accessor: (props: $TSFixMe) =>
          props.segments
            ? props.segments?.reduce(
                (acc: string, segment: $TSFixMe) =>
                  acc.length ? acc.concat(`, ${segment.name}`) : segment.name,
                ""
              )
            : "",
        Cell: SegmentsCell
      },
      {
        Header: "Created",
        id: "Created",
        accessor: (props: $TSFixMe) => dateFormat(props.value),
        Cell: ({ row }: $TSFixMe) => <span>{dateFormat(row.original.created)}</span>
      },
      {
        Header: "Status",
        id: "Status",
        accessor: "status",
        Cell: StatusCell
      }
    ];
  }, [enableRun, onDisplayOnCanvas, onEditScenario, onRunScenario]);

  return (
    <>
      {showConfirmScreen && (
        <ConfirmScreen
          onCancel={cancelDeleteScenario}
          onConfirm={confirmDeleteScenario}
          confirmLabel={ScenarioDeletePromptDetails.confirmLabel}
          title={ScenarioDeletePromptDetails.title}
          subtitleLines={[ScenarioDeletePromptDetails.message]}
        />
      )}
      <Table
        rowsPerPage={50}
        isLoading={isLoading}
        globalFilter={searchValue}
        orderByDefault="Created"
        data={data}
        size="small"
        columns={columns}
        isCellSortEnabled
        emptyTableMessage="No records"
      />
    </>
  );
};

export default ScenariosTable;
