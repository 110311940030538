/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateProjectRunDto } from '../Models';
// @ts-ignore
import { CreateProjectRunEntryDto } from '../Models';
// @ts-ignore
import { ErrorMessage } from '../Models';
// @ts-ignore
import { PredictionFileProcessDto } from '../Models';
// @ts-ignore
import { ProjectRunDetailDto } from '../Models';
// @ts-ignore
import { ProjectRunDto } from '../Models';
// @ts-ignore
import { ProjectRunEntryDashboardDto } from '../Models';
// @ts-ignore
import { ProjectRunEntryDetailsDto } from '../Models';
// @ts-ignore
import { ProjectRunEntryDto } from '../Models';
// @ts-ignore
import { SignedUrlResult } from '../Models';
// @ts-ignore
import { UpdateProjectRunDto } from '../Models';
/**
 * ProjectRunControllerApi - axios parameter creator
 * @export
 */
export const ProjectRunControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProjectRunDto} createProjectRunDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectRun: async (createProjectRunDto: CreateProjectRunDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProjectRunDto' is not null or undefined
            assertParamExists('createProjectRun', 'createProjectRunDto', createProjectRunDto)
            const localVarPath = `/v2/project-runs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectRunDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectRun: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProjectRun', 'id', id)
            const localVarPath = `/v2/project-runs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectRunEntry: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProjectRunEntry', 'id', id)
            const localVarPath = `/v2/project-runs/{id}/entry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDashboardEntries: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/project-runs/entry-dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectRunById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findProjectRunById', 'id', id)
            const localVarPath = `/v2/project-runs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectRunByName: async (name: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('findProjectRunByName', 'name', name)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('findProjectRunByName', 'projectId', projectId)
            const localVarPath = `/v2/project-runs/byname/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectRunDetailsById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findProjectRunDetailsById', 'id', id)
            const localVarPath = `/v2/project-runs/{id}/run-details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectRunEntries: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findProjectRunEntries', 'id', id)
            const localVarPath = `/v2/project-runs/{id}/entries`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FindProjectRunsJobTypeEnum} [jobType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectRuns: async (projectId: string, jobType?: FindProjectRunsJobTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('findProjectRuns', 'projectId', projectId)
            const localVarPath = `/v2/project-runs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (jobType !== undefined) {
                localVarQueryParameter['jobType'] = jobType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRunDashboard: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/project-runs/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLogs', 'id', id)
            const localVarPath = `/v2/project-runs/{id}/logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRunEntryDetails: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectRunEntryDetails', 'id', id)
            const localVarPath = `/v2/project-runs/{id}/entry-details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectRunId 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrl: async (projectRunId: string, entityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectRunId' is not null or undefined
            assertParamExists('getUploadSignedUrl', 'projectRunId', projectRunId)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getUploadSignedUrl', 'entityId', entityId)
            const localVarPath = `/v2/project-runs/{projectRunId}/{entityId}/upload-signed-url`
                .replace(`{${"projectRunId"}}`, encodeURIComponent(String(projectRunId)))
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PredictionFileProcessDto} predictionFileProcessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processUploadedFile: async (id: string, predictionFileProcessDto: PredictionFileProcessDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('processUploadedFile', 'id', id)
            // verify required parameter 'predictionFileProcessDto' is not null or undefined
            assertParamExists('processUploadedFile', 'predictionFileProcessDto', predictionFileProcessDto)
            const localVarPath = `/v2/project-runs/{id}/process-uploaded-file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(predictionFileProcessDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishProjectRunMeta: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publishProjectRunMeta', 'id', id)
            const localVarPath = `/v2/project-runs/publish/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateProjectRunEntryDto} createProjectRunEntryDto 
         * @param {boolean} [async] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        run: async (id: string, createProjectRunEntryDto: CreateProjectRunEntryDto, async?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('run', 'id', id)
            // verify required parameter 'createProjectRunEntryDto' is not null or undefined
            assertParamExists('run', 'createProjectRunEntryDto', createProjectRunEntryDto)
            const localVarPath = `/v2/project-runs/{id}/manual-run`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (async !== undefined) {
                localVarQueryParameter['async'] = async;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectRunEntryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectRunDto} updateProjectRunDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectRun: async (updateProjectRunDto: UpdateProjectRunDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProjectRunDto' is not null or undefined
            assertParamExists('updateProjectRun', 'updateProjectRunDto', updateProjectRunDto)
            const localVarPath = `/v2/project-runs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectRunDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectRunControllerApi - functional programming interface
 * @export
 */
export const ProjectRunControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectRunControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProjectRunDto} createProjectRunDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectRun(createProjectRunDto: CreateProjectRunDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectRunDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectRun(createProjectRunDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.createProjectRun']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectRun(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectRun(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.deleteProjectRun']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectRunEntry(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectRunEntry(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.deleteProjectRunEntry']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findDashboardEntries(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectRunEntryDashboardDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findDashboardEntries(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.findDashboardEntries']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjectRunById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectRunDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjectRunById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.findProjectRunById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjectRunByName(name: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectRunDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjectRunByName(name, projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.findProjectRunByName']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjectRunDetailsById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectRunDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjectRunDetailsById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.findProjectRunDetailsById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjectRunEntries(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectRunEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjectRunEntries(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.findProjectRunEntries']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FindProjectRunsJobTypeEnum} [jobType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjectRuns(projectId: string, jobType?: FindProjectRunsJobTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectRunDetailDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjectRuns(projectId, jobType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.findProjectRuns']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRunDashboard(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectRunDetailDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRunDashboard(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.findRunDashboard']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogs(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogs(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.getLogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectRunEntryDetails(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectRunEntryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectRunEntryDetails(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.getProjectRunEntryDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectRunId 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadSignedUrl(projectRunId: string, entityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadSignedUrl(projectRunId, entityId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.getUploadSignedUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {PredictionFileProcessDto} predictionFileProcessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processUploadedFile(id: string, predictionFileProcessDto: PredictionFileProcessDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processUploadedFile(id, predictionFileProcessDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.processUploadedFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishProjectRunMeta(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishProjectRunMeta(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.publishProjectRunMeta']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateProjectRunEntryDto} createProjectRunEntryDto 
         * @param {boolean} [async] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async run(id: string, createProjectRunEntryDto: CreateProjectRunEntryDto, async?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectRunEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.run(id, createProjectRunEntryDto, async, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.run']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectRunDto} updateProjectRunDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectRun(updateProjectRunDto: UpdateProjectRunDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectRunDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectRun(updateProjectRunDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectRunControllerApi.updateProjectRun']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProjectRunControllerApi - factory interface
 * @export
 */
export const ProjectRunControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectRunControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProjectRunDto} createProjectRunDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectRun(createProjectRunDto: CreateProjectRunDto, options?: any): AxiosPromise<ProjectRunDto> {
            return localVarFp.createProjectRun(createProjectRunDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectRun(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectRun(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectRunEntry(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectRunEntry(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDashboardEntries(options?: any): AxiosPromise<Array<ProjectRunEntryDashboardDto>> {
            return localVarFp.findDashboardEntries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectRunById(id: string, options?: any): AxiosPromise<ProjectRunDto> {
            return localVarFp.findProjectRunById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectRunByName(name: string, projectId: string, options?: any): AxiosPromise<ProjectRunDto> {
            return localVarFp.findProjectRunByName(name, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectRunDetailsById(id: string, options?: any): AxiosPromise<ProjectRunDetailDto> {
            return localVarFp.findProjectRunDetailsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectRunEntries(id: string, options?: any): AxiosPromise<Array<ProjectRunEntryDto>> {
            return localVarFp.findProjectRunEntries(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {FindProjectRunsJobTypeEnum} [jobType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectRuns(projectId: string, jobType?: FindProjectRunsJobTypeEnum, options?: any): AxiosPromise<Array<ProjectRunDetailDto>> {
            return localVarFp.findProjectRuns(projectId, jobType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRunDashboard(options?: any): AxiosPromise<Array<ProjectRunDetailDto>> {
            return localVarFp.findRunDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs(id: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getLogs(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRunEntryDetails(id: string, options?: any): AxiosPromise<ProjectRunEntryDetailsDto> {
            return localVarFp.getProjectRunEntryDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectRunId 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrl(projectRunId: string, entityId: string, options?: any): AxiosPromise<SignedUrlResult> {
            return localVarFp.getUploadSignedUrl(projectRunId, entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PredictionFileProcessDto} predictionFileProcessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processUploadedFile(id: string, predictionFileProcessDto: PredictionFileProcessDto, options?: any): AxiosPromise<void> {
            return localVarFp.processUploadedFile(id, predictionFileProcessDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishProjectRunMeta(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.publishProjectRunMeta(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateProjectRunEntryDto} createProjectRunEntryDto 
         * @param {boolean} [async] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        run(id: string, createProjectRunEntryDto: CreateProjectRunEntryDto, async?: boolean, options?: any): AxiosPromise<ProjectRunEntryDto> {
            return localVarFp.run(id, createProjectRunEntryDto, async, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectRunDto} updateProjectRunDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectRun(updateProjectRunDto: UpdateProjectRunDto, options?: any): AxiosPromise<ProjectRunDto> {
            return localVarFp.updateProjectRun(updateProjectRunDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectRunControllerApi - interface
 * @export
 * @interface ProjectRunControllerApi
 */
export interface ProjectRunControllerApiInterface {
    /**
     * 
     * @param {CreateProjectRunDto} createProjectRunDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    createProjectRun(createProjectRunDto: CreateProjectRunDto, options?: RawAxiosRequestConfig): AxiosPromise<ProjectRunDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    deleteProjectRun(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    deleteProjectRunEntry(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    findDashboardEntries(options?: RawAxiosRequestConfig): AxiosPromise<Array<ProjectRunEntryDashboardDto>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    findProjectRunById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectRunDto>;

    /**
     * 
     * @param {string} name 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    findProjectRunByName(name: string, projectId: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectRunDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    findProjectRunDetailsById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectRunDetailDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    findProjectRunEntries(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProjectRunEntryDto>>;

    /**
     * 
     * @param {string} projectId 
     * @param {FindProjectRunsJobTypeEnum} [jobType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    findProjectRuns(projectId: string, jobType?: FindProjectRunsJobTypeEnum, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProjectRunDetailDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    findRunDashboard(options?: RawAxiosRequestConfig): AxiosPromise<Array<ProjectRunDetailDto>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    getLogs(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    getProjectRunEntryDetails(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectRunEntryDetailsDto>;

    /**
     * 
     * @param {string} projectRunId 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    getUploadSignedUrl(projectRunId: string, entityId: string, options?: RawAxiosRequestConfig): AxiosPromise<SignedUrlResult>;

    /**
     * 
     * @param {string} id 
     * @param {PredictionFileProcessDto} predictionFileProcessDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    processUploadedFile(id: string, predictionFileProcessDto: PredictionFileProcessDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    publishProjectRunMeta(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {CreateProjectRunEntryDto} createProjectRunEntryDto 
     * @param {boolean} [async] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    run(id: string, createProjectRunEntryDto: CreateProjectRunEntryDto, async?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ProjectRunEntryDto>;

    /**
     * 
     * @param {UpdateProjectRunDto} updateProjectRunDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApiInterface
     */
    updateProjectRun(updateProjectRunDto: UpdateProjectRunDto, options?: RawAxiosRequestConfig): AxiosPromise<ProjectRunDto>;

}

/**
 * ProjectRunControllerApi - object-oriented interface
 * @export
 * @class ProjectRunControllerApi
 * @extends {BaseAPI}
 */
export class ProjectRunControllerApi extends BaseAPI implements ProjectRunControllerApiInterface {
    /**
     * 
     * @param {CreateProjectRunDto} createProjectRunDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public createProjectRun(createProjectRunDto: CreateProjectRunDto, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).createProjectRun(createProjectRunDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public deleteProjectRun(id: string, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).deleteProjectRun(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public deleteProjectRunEntry(id: string, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).deleteProjectRunEntry(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public findDashboardEntries(options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).findDashboardEntries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public findProjectRunById(id: string, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).findProjectRunById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public findProjectRunByName(name: string, projectId: string, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).findProjectRunByName(name, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public findProjectRunDetailsById(id: string, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).findProjectRunDetailsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public findProjectRunEntries(id: string, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).findProjectRunEntries(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {FindProjectRunsJobTypeEnum} [jobType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public findProjectRuns(projectId: string, jobType?: FindProjectRunsJobTypeEnum, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).findProjectRuns(projectId, jobType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public findRunDashboard(options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).findRunDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public getLogs(id: string, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).getLogs(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public getProjectRunEntryDetails(id: string, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).getProjectRunEntryDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectRunId 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public getUploadSignedUrl(projectRunId: string, entityId: string, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).getUploadSignedUrl(projectRunId, entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PredictionFileProcessDto} predictionFileProcessDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public processUploadedFile(id: string, predictionFileProcessDto: PredictionFileProcessDto, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).processUploadedFile(id, predictionFileProcessDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public publishProjectRunMeta(id: string, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).publishProjectRunMeta(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreateProjectRunEntryDto} createProjectRunEntryDto 
     * @param {boolean} [async] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public run(id: string, createProjectRunEntryDto: CreateProjectRunEntryDto, async?: boolean, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).run(id, createProjectRunEntryDto, async, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectRunDto} updateProjectRunDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectRunControllerApi
     */
    public updateProjectRun(updateProjectRunDto: UpdateProjectRunDto, options?: RawAxiosRequestConfig) {
        return ProjectRunControllerApiFp(this.configuration).updateProjectRun(updateProjectRunDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FindProjectRunsJobTypeEnum = {
    ProjectJob: 'PROJECT_JOB',
    PredictionJob: 'PREDICTION_JOB'
} as const;
export type FindProjectRunsJobTypeEnum = typeof FindProjectRunsJobTypeEnum[keyof typeof FindProjectRunsJobTypeEnum];
