import React, { useMemo } from "react";

// Packages
import { get, has } from "lodash";

// Icons
import BlockIcon from "@material-ui/icons/Block";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import WarningIcon from "@material-ui/icons/Warning";
import { ChartIcon } from "src/icons/NewUX";

// Utils
import { ChartStatuses, NodeChartTheme } from "src/pages/private/ProjectsModule/utils";

// Types
import { NodeData } from "src/types";

type Props = {
  data: NodeData;
  isHovered?: boolean;
};

const ChartIconFormatted = () => <ChartIcon width={20} height={20} viewBox="2 -4 40 40" />;

const statusIconMap: $TSFixMe = {
  [ChartStatuses.UnBuilt]: <BlockIcon />,
  [ChartStatuses.Pending]: <HourglassEmptyIcon />,
  [ChartStatuses.Empty]: <WarningIcon />,
  [ChartStatuses.Skipped]: <SkipNextIcon />,
  [ChartStatuses.Built]: <ChartIconFormatted />
};

const statusNameMap: $TSFixMe = {
  [ChartStatuses.UnBuilt]: "Unbuilt",
  [ChartStatuses.Pending]: "Pending...",
  [ChartStatuses.Empty]: "Empty",
  [ChartStatuses.Skipped]: "Skipped",
  [ChartStatuses.Built]: "Built"
};

const useAttributes = (props: Props) => {
  const { data, isHovered } = props || {};

  const icon = useMemo(() => {
    const status = data?.status;

    let color: string = has(NodeChartTheme, status)
      ? get(NodeChartTheme, [status, "iconColor"])
      : NodeChartTheme.iconColor;

    if (!!isHovered) {
      color = has(NodeChartTheme, status)
        ? get(NodeChartTheme, [status, "onHover", "iconColor"])
        : NodeChartTheme.onHover.iconColor;
    }

    return <span style={{ color }}>{statusIconMap[status] || <ChartIconFormatted />}</span>;
  }, [data, isHovered]);

  const status = useMemo(() => {
    return statusNameMap[data?.status] || "";
  }, [data?.status]);

  return { icon, status };
};

export default useAttributes;
