import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { makeStyles, Checkbox } from "@material-ui/core";

import AccordianCard from "./AccordianCard";
import { Record } from "hooks/api/projects/useProjectDetails";
import { projectMetadata } from "../utils/CopyDownloadProject.constants";

interface IProps {
  data: Record[];
  onChange: (key: string, value: string[], predictionServices: string[]) => void;
}

const useStyles = makeStyles({
  flexBetween: { display: "flex", gap: "6px", alignItems: "center" }
});

interface IDataApp {
  id: string;
  name: string;
  checked: boolean;
  predictionService?: string;
}

const CopyDataApp: React.FC<IProps> = (props) => {
  const { data, onChange } = props;
  const [dataApps, setDataApps] = useState<IDataApp[]>([]);
  const { flexBetween } = useStyles();

  useEffect(() => {
    if (data) {
      setDataApps(
        _.map(data, (app) => ({
          id: app.id,
          name: app.displayName || app.name,
          checked: false,
          predictionService: app.predictionService
        }))
      );
    }
  }, [data]);

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const newDataApps: IDataApp[] = [];
    const selectedDataApps: string[] = [];
    const predictionServices: string[] = [];
    _.forEach(dataApps, (app) => {
      if (app.id === id) {
        newDataApps.push({ ...app, checked: e.target.checked });
        if (e.target.checked) {
          selectedDataApps.push(id);
          if (app.predictionService) {
            predictionServices.push(app.predictionService);
          }
        }
      } else {
        newDataApps.push(app);
        if (app.checked) {
          selectedDataApps.push(app.id);
          if (app.predictionService) {
            predictionServices.push(app.predictionService);
          }
        }
      }
    });

    setDataApps(newDataApps);
    onChange(projectMetadata.dataapps.id, selectedDataApps, predictionServices);
  };

  const checkedAll = useMemo(
    () => !_.isEmpty(dataApps) && !_.some(dataApps, { checked: false }),
    [dataApps]
  );

  const handleCheckAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDataApps: IDataApp[] = [];
    const selectedDataApps: string[] = [];
    const predictionServices: string[] = [];
    _.forEach(dataApps, (app) => {
      newDataApps.push({ ...app, checked: e.target.checked });
      if (e.target.checked) {
        selectedDataApps.push(app.id);
        if (app.predictionService) {
          predictionServices.push(app.predictionService);
        }
      }
    });

    setDataApps(newDataApps);
    onChange(projectMetadata.dataapps.id, selectedDataApps, predictionServices);
  };

  if (_.isEmpty(dataApps)) {
    return null;
  }

  return (
    <AccordianCard
      label={projectMetadata.dataapps.label}
      title={
        <div className={flexBetween}>
          <Checkbox
            checked={checkedAll}
            disabled={_.isEmpty(dataApps)}
            inputProps={{ "aria-label": "primary checkbox" }}
            onChange={handleCheckAllChange}
            onClick={(e) => e.stopPropagation()}
          />
          <span>{projectMetadata.dataapps.label}</span>
        </div>
      }>
      <div>
        {_.map(dataApps, (app) => (
          <div className={flexBetween} key={app.id}>
            <Checkbox
              checked={app.checked}
              onChange={(e) => handleCheckChange(e, app.id)}
              inputProps={{ "aria-label": "dataApps checkbox" }}
            />
            <span>{app.name}</span>
          </div>
        ))}
      </div>
    </AccordianCard>
  );
};

export default CopyDataApp;
