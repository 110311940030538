/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Config } from './config';
// May contain unused imports in some cases
// @ts-ignore
import { OutputEntity } from './output-entity';
// May contain unused imports in some cases
// @ts-ignore
import { OutputEntityDto } from './output-entity-dto';

/**
 * 
 * @export
 * @interface AskAIResponseDto
 */
export interface AskAIResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AskAIResponseDto
     */
    'messageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AskAIResponseDto
     */
    'query'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AskAIResponseDto
     */
    'inputIds'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AskAIResponseDto
     */
    'entitySizeUsed'?: number;
    /**
     * 
     * @type {string}
     * @memberof AskAIResponseDto
     */
    'outputType'?: AskAIResponseDtoOutputTypeEnum;
    /**
     * 
     * @type {Config}
     * @memberof AskAIResponseDto
     */
    'configUsed'?: Config;
    /**
     * 
     * @type {Array<OutputEntityDto>}
     * @memberof AskAIResponseDto
     */
    'outputEntitiesResponse'?: Array<OutputEntityDto>;
    /**
     * 
     * @type {Array<OutputEntity>}
     * @memberof AskAIResponseDto
     */
    'outputEntities'?: Array<OutputEntity>;
    /**
     * 
     * @type {string}
     * @memberof AskAIResponseDto
     */
    'useCase'?: AskAIResponseDtoUseCaseEnum;
    /**
     * 
     * @type {string}
     * @memberof AskAIResponseDto
     */
    'executedCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof AskAIResponseDto
     */
    'created'?: number;
    /**
     * 
     * @type {string}
     * @memberof AskAIResponseDto
     */
    'feedback'?: AskAIResponseDtoFeedbackEnum;
    /**
     * 
     * @type {string}
     * @memberof AskAIResponseDto
     */
    'comment'?: string;
    /**
     * 
     * @type {Error}
     * @memberof AskAIResponseDto
     */
    'error'?: Error;
}

export const AskAIResponseDtoOutputTypeEnum = {
    Text: 'TEXT',
    Chart: 'CHART',
    Image: 'IMAGE',
    Dataset: 'DATASET',
    TextNoCode: 'TEXT_NO_CODE',
    Function: 'FUNCTION',
    Unrecognized: 'UNRECOGNIZED',
    AutoInfer: 'AUTO_INFER',
    Model: 'MODEL'
} as const;

export type AskAIResponseDtoOutputTypeEnum = typeof AskAIResponseDtoOutputTypeEnum[keyof typeof AskAIResponseDtoOutputTypeEnum];
export const AskAIResponseDtoUseCaseEnum = {
    ConversationUseCase: 'CONVERSATION_USE_CASE',
    ProblemTypeUseCase: 'PROBLEM_TYPE_USE_CASE',
    TargetColumnUseCase: 'TARGET_COLUMN_USE_CASE',
    OneShotUseCase: 'ONE_SHOT_USE_CASE',
    PromptSuggestionsUseCase: 'PROMPT_SUGGESTIONS_USE_CASE'
} as const;

export type AskAIResponseDtoUseCaseEnum = typeof AskAIResponseDtoUseCaseEnum[keyof typeof AskAIResponseDtoUseCaseEnum];
export const AskAIResponseDtoFeedbackEnum = {
    Liked: 'LIKED',
    Disliked: 'DISLIKED',
    NotProvided: 'NOT_PROVIDED'
} as const;

export type AskAIResponseDtoFeedbackEnum = typeof AskAIResponseDtoFeedbackEnum[keyof typeof AskAIResponseDtoFeedbackEnum];


