import React, { useEffect, useMemo, useState } from "react";

// Packages
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MuiLink from "@material-ui/core/Link";

import { useLocation, useNavigate } from "react-router-dom";

// Icons
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";

// Components
import { SubTopNavBarVariants } from "./SubTopNavBar";
import { Tooltip } from "@material-ui/core";
import { WebPaths } from "src/routing/routes";

type Props = {
  variant: string;
  subTopNavBarLeftSection?: {
    backNavPath?: string;
    backNavAction?: $TSFixMeFunction;
    isBackNavActionDisabled?: boolean;
    styleProps: React.CSSProperties;
  };
  children: $TSFixMe;
};

type StyleProps = {
  variant: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: StyleProps) =>
      props?.variant === SubTopNavBarVariants.Drawer ? "100%" : "auto",
    columnGap: 10,
    paddingLeft: (props: StyleProps) =>
      props?.variant === SubTopNavBarVariants.Drawer ? theme.spacing(2) : theme.spacing(3)
  }
}));

export const SubTopNavBarLeftSection = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [canGoBack, setCanGoBack] = useState(true);
  const { variant, subTopNavBarLeftSection, children } = props || {};
  const { backNavPath, backNavAction, styleProps } = subTopNavBarLeftSection || {};

  const classes = useStyles({ variant });

  useEffect(() => {
    setCanGoBack(history.state && history.state.idx !== 0);
  }, [location]);

  const handleGoBack = () => {
    if (backNavPath === WebPaths.Dashboard) {
      navigate(WebPaths.Dashboard);
    } else {
      navigate(-1);
    }
  };

  const backNavComponent = useMemo(() => {
    return (
      <>
        {!!backNavPath && (
          <Tooltip
            title={
              !canGoBack
                ? "This action isn't supported in a new tab since there's no previous page in the browsing history."
                : ""
            }>
            <span>
              <MuiLink
                component="button"
                variant="body2"
                onClick={() => handleGoBack()}
                disabled={!canGoBack}>
                <span>
                  <Typography
                    gutterBottom
                    variant="body2"
                    color="textPrimary"
                    {...(!canGoBack
                      ? {
                          style: {
                            opacity: 0.5
                          }
                        }
                      : {})}>
                    <ArrowBackIosIcon fontSize="inherit" />
                  </Typography>
                </span>
              </MuiLink>
            </span>
          </Tooltip>
        )}

        {!!backNavAction && (
          <Tooltip
            title={
              !canGoBack
                ? "This action isn't supported in a new tab since there's no previous page in the browsing history."
                : ""
            }>
            <span>
              <MuiLink
                component="button"
                variant="body2"
                onClick={() => {
                  backNavAction();
                  navigate(-1);
                }}
                disabled={!canGoBack}>
                <span>
                  <Typography
                    gutterBottom
                    variant="body2"
                    color="textPrimary"
                    {...(!canGoBack
                      ? {
                          style: {
                            opacity: 0.5
                          }
                        }
                      : {})}>
                    <ArrowBackIosIcon fontSize="inherit" />
                  </Typography>
                </span>
              </MuiLink>
            </span>
          </Tooltip>
        )}
      </>
    );
  }, [backNavPath, backNavAction, canGoBack]);

  return (
    // @ts-ignore
    <Grid container alignItems="center" className={classes.root} {...styleProps}>
      {backNavComponent}
      {children}
    </Grid>
  );
};

export default SubTopNavBarLeftSection;
