import { makeStyles } from "@material-ui/core/styles";

type StyleProps = {
  height?: number;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative", // needed for absolute positioning of rows
    height: (props?: StyleProps) => props?.height // tells scrollbar how big the table is
  },
  tableRow: {
    position: "absolute",
    display: "flex"
  },
  tableCell: {
    border: `0.5px solid ${theme.palette.divider}`,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

export default useStyles;
