import React, { useMemo } from "react";

// Icons
import ApiConnectorIcon from "src/icons/NewUX/ApiConnectorIcon";
import { AiAssistedIcon } from "src/icons/AiAssistedIcon";
import { RecipeIcon } from "src/icons/RecipeIcon";
import { RapidModelIcon } from "src/icons/RapidModelIcon";

// Utils
import { RecipeStatuses, RecipeTypes } from "src/pages/private/ProjectsModule/utils";

// Types
import { NodeData } from "src/types";

type Props = {
  data: NodeData;
};

const statusIconMap: $TSFixMe = {
  [RecipeTypes.AiAssisted]: <AiAssistedIcon />,
  [RecipeTypes.RapidModel]: <RapidModelIcon />,
  [RecipeTypes.ApiConnector]: <ApiConnectorIcon />
};

const statusNameMap: $TSFixMe = {
  [RecipeStatuses.UnBuilt]: "Unbuilt",
  [RecipeStatuses.Pending]: "Pending...",
  [RecipeStatuses.Running]: "Running...",
  [RecipeStatuses.Success]: "Success",
  [RecipeStatuses.Error]: "Error",
  [RecipeStatuses.Skipped]: "Skipped"
};

const useAttributes = (props: Props) => {
  const { data } = props || {};

  const icon = useMemo(() => {
    if (data?.image) {
      return <img style={{ width: "70%", height: "70%" }} src={data?.image} alt="recipe-icon" />;
    }

    return statusIconMap[data?.recipeType] || <RecipeIcon />;
  }, [data]);

  const status = useMemo(() => {
    return statusNameMap[data?.status] || "";
  }, [data?.status]);

  return { icon, status };
};

export default useAttributes;
