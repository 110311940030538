import React, { useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { getEnsuredDataConnectorsData } from "src/hooks/api";

import { useDataSourcesStore } from "src/store/store";
import { connectorsGetter, connectorsSetter } from "src/store/store.selectors";

import { JobsContext } from "./JobsContext";

const JobsContextProvider = (props: $TSFixMe) => {
  const { children } = props || {};

  const queryClient = useQueryClient();

  // Stores - STARTS >>
  const connectorsStore = useDataSourcesStore(connectorsGetter);
  const setConnectorsStore = useDataSourcesStore(connectorsSetter);
  // << ENDS - Stores

  // States - STARTS >>
  const [jobPaths, setJobPaths] = useState<$TSFixMe>([]);
  // << ENDS - States

  useEffect(() => {
    const _ = async () => {
      const dataConnectorsData: $TSFixMe = await getEnsuredDataConnectorsData({ queryClient });

      if ((dataConnectorsData || [])?.length > 0) {
        setConnectorsStore([...dataConnectorsData]);
      }
    };

    _();
  }, []);

  const jobPathsSetter = (args: string | []) => {
    if (Array.isArray(args)) {
      setJobPaths(() => [...args]);
    } else {
      setJobPaths(() => [...(jobPaths || []), args]);
    }
  };

  // Resetting store-data
  useEffect(() => () => setConnectorsStore([]), []);

  const value = useMemo(
    () => ({ connectorsStore, jobPaths, jobPathsSetter }),
    [connectorsStore, jobPaths, jobPathsSetter]
  );

  return <JobsContext.Provider value={value}>{children}</JobsContext.Provider>;
};

export default JobsContextProvider;
