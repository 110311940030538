import React from "react";
import { Button, CircularProgress, Tooltip, ButtonProps } from "@material-ui/core";
import clsx from "clsx";

import { useStyles } from "./styling";

interface IProps extends ButtonProps {
  id?: string;
  icon?: React.ReactNode;
  label?: string;
  tooltip?: string;
  loading?: boolean;
  main?: boolean;
  testId?: string;
}

export const BarButton = ({
  id,
  icon,
  label,
  onClick,
  disabled,
  tooltip,
  loading,
  main,
  variant,
  testId,
  ...restProps
}: IProps) => {
  const { barButton, barMainButton, barButtonDisabled, iconButton, buttonLoader } = useStyles();
  const barButtonStyles = clsx(
    main ? barMainButton : barButton,
    disabled && barButtonDisabled,
    !label && iconButton
  );

  return (
    <Tooltip title={tooltip ?? ""}>
      {/* div has to enclose button to display tooltip when disabled */}
      <div>
        {loading ? (
          <Button test-id={testId} disabled className={buttonLoader}>
            <CircularProgress size={16} />
          </Button>
        ) : (
          <Button
            id={id}
            className={barButtonStyles}
            color="primary"
            size="small"
            variant={variant}
            onClick={onClick}
            test-id={testId}
            disabled={disabled}
            {...restProps}>
            {icon}
            {label}
          </Button>
        )}
      </div>
    </Tooltip>
  );
};

BarButton.defaultProps = {
  icon: <></>,
  label: "",
  onClick: () => {},
  disabled: false,
  tooltip: "",
  loading: false,
  main: false,
  variant: "false"
};
