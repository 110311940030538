export enum EnvironmentsConfig {
  ExemptingWidth = 0.15, // 15%
  CardWidth = 375,
  CardHeight = 370
}

export enum SplashSectionHelperText {
  Title = "Get Started with a New Environment",
  SubLine1 = "Welcome to the Environments page.",
  SubLine2 = "Start a new one by clicking on the button below."
}

export enum EnvironmentDeleteMessage {
  messageLine1 = "Do you really want to delete this environment?",
  messageLine2 = "If you confirm you will delete the environment completely"
}

export enum EnvironmentsHelperText {
  NewEnvironment = "New Environment",
  NameInfo = "The name of the tenant-level default environment is non-editable",
  DeleteInfo = "Cannot delete a launching environment",
  UsageInfo = "Resource usage data can be accessed when the environment is in a running state",

  Save = "Save"
}
