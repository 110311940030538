import React from "react";
import isEmpty from "lodash/isEmpty";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Modal from "src/components/custom/Modal/Modal";
import ViewDataDataTable from "../../ViewDataData/ViewDataDataTable/ViewDataDataTable";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    rowGap: theme.spacing(2)
  },
  info: {
    marginLeft: "auto",
    fontStyle: "italic",
    opacity: 0.5
  }
}));

export default function ModalTenantSwitch({ visible, segmentData, onClose }: $TSFixMe) {
  const classes = useStyles();

  const handleClose = () => {
    return onClose(false);
  };

  return (
    <Modal
      size="lg"
      title="Segment Preview"
      open={visible}
      onClose={handleClose}
      hideCancelAction
      hideSubmitAction>
      <Grid container className={classes.root}>
        {!isEmpty(segmentData?.rows) && (
          <Typography variant="body2" className={classes.info}>
            {`* showing first ${segmentData?.rows?.length | 0} record(s) from the segment data`}
          </Typography>
        )}
        <ViewDataDataTable
          responseData={segmentData}
          isLoadingData={!segmentData}
          hiddenColumns={[]}
          entityFeatures={[]}
          noGraphics
        />
      </Grid>
    </Modal>
  );
}
