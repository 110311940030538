import React, { useEffect, useState } from "react";
import { last } from "lodash";

import { useAIGuideContext } from "./useAIGuideContext";
import { useCreateNewThreadMutation } from "src/hooks/api";
import AIGuideThreadsContainer from "./AIGuideThreadsContainer";
import { Spinner } from "src/components";

import {
  ThreadResponseDto,
  ThreadResponseDtoFlowTypeEnum
} from "openapi/Models/thread-response-dto";
import ComponentNotFound from "src/components/Errors/ComponentNotFound";

export const AIGuideThreadsWrap = ({
  threads,
  setThreads
}: {
  threads: ThreadResponseDto[];
  setThreads: React.Dispatch<React.SetStateAction<ThreadResponseDto[] | undefined>>;
}) => {
  const {
    modelId,
    chartId,
    diamondId,
    datasetId,
    chartName,
    targetType,
    projectId,
    scenarioId,
    onClose,
    datasetContext
  } = useAIGuideContext();

  const targetId = modelId || diamondId || datasetId;

  const getActiveThread = (inputThreads: ThreadResponseDto[]) => {
    if (diamondId) {
      return inputThreads.find(
        (thread) =>
          thread.entityId === diamondId &&
          thread.targetInputs?.find((targetInput) => targetInput.chartId === chartId)
      );
    }

    if (modelId) {
      return inputThreads.find((thread) => thread.entityId === targetId);
    }

    return inputThreads.find(
      (thread) => thread.entityId === targetId && thread.datasetContext === datasetContext
    );
  };

  const [activeThread, setActiveThread] = useState<undefined | ThreadResponseDto>(
    getActiveThread(threads)
  );

  const createNewThreadMutation = useCreateNewThreadMutation();

  useEffect(() => {
    if (!activeThread) {
      createNewThreadMutation.mutate(
        {
          flowType: ThreadResponseDtoFlowTypeEnum.AiGuide,
          context: {
            projectId,
            scenarioId,
            datasetContext
          },
          target: {
            targetId,
            targetType,
            chartId,
            chartName
          }
        },
        {
          onSuccess: (response) => {
            setThreads((currThreads) => [response, ...(currThreads || [])]);
            setActiveThread(response);
          }
        }
      );
    }
  }, [activeThread]);

  const isCreatingNewThread = createNewThreadMutation.isLoading;
  const isError = createNewThreadMutation.isError;

  const onDeleteSuccess = (deletedThreadId: string) => {
    const updatedThreads = threads?.filter((thread) => thread.threadId !== deletedThreadId);
    if (updatedThreads?.length === 0) {
      onClose();
      setThreads([]);

      return;
    }
    const activeThread = getActiveThread(updatedThreads || []) || last(updatedThreads);
    setThreads(updatedThreads);
    activeThread && setActiveThread(activeThread);
  };

  if (isError) {
    return <ComponentNotFound />;
  }

  return isCreatingNewThread || !activeThread ? (
    <Spinner />
  ) : (
    <AIGuideThreadsContainer
      threads={threads}
      activeThread={activeThread}
      setActiveThread={setActiveThread}
      onDeleteSuccess={onDeleteSuccess}
    />
  );
};
