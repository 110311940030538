import React, { useMemo } from "react";
import _ from "lodash";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button, CircularProgress, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";

import CreateDataAppInputs from "./CreateDataAppInputs";
import SelectAskAIInputType from "./SelectAskAIInputType";
import SelectAskAILLMModel from "./SelectAskAILLMModel";
import {
  ConfigInputTypeEnum,
  ConfigLlmModelEnum,
  CreateDataAppRequestDtoDataAppTypeEnum
} from "openapi/Models";
import { IRecipes } from "./CreateDataApp";
import { useForm } from "src/utils/useForm";
import { validateNameField } from "src/utils/formFieldUtils";
import { useCreateDataAppMutation } from "src/hooks/api/dataapps/useCreateDataAppMutation";
import { useParams } from "react-router-dom";
import { handleResponse } from "src/utils/apiService";
import { AppTemplate } from "src/pages/DataApps/DataApps.type";

interface IProps {
  type: CreateDataAppRequestDtoDataAppTypeEnum;
  recipes: IRecipes[];
  appTemplates?: AppTemplate[];
  disabledInputs: ConfigInputTypeEnum[];
  askAIDataAppTemplateId?: string;
  onBack: () => void;
  onSuccess: () => void;
}

export const dataAppConfigFields = {
  llmModel: "llmModel",
  inputType: "inputType",
  dataAppName: "dataAppName",
  description: "description",
  imageBase64: "imageBase64",
  recipeId: "recipeId"
};

const initialValues = {
  [dataAppConfigFields.dataAppName]: "",
  [dataAppConfigFields.description]: ""
};

const CreateDataAppForm: React.FC<IProps> = (props) => {
  const { recipes, type, appTemplates, disabledInputs, askAIDataAppTemplateId, onBack, onSuccess } =
    props;

  const { projectId } = useParams();
  const createDataAppMutation = useCreateDataAppMutation();
  const allowedRecipes = useMemo(() => recipes?.filter((recipe) => recipe.allowed), [recipes]);

  const { values, handleInputChange } = useForm({
    ...initialValues,
    [dataAppConfigFields.recipeId]:
      type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel && allowedRecipes?.length === 1
        ? allowedRecipes?.[0]?.id
        : "",
    [dataAppConfigFields.llmModel]:
      type === CreateDataAppRequestDtoDataAppTypeEnum.Askai
        ? ConfigLlmModelEnum.OpenaiGpt
        : undefined,
    [dataAppConfigFields.inputType]:
      type === CreateDataAppRequestDtoDataAppTypeEnum.Askai
        ? ConfigInputTypeEnum.ProjectCanvas
        : undefined
  });

  const errorMsgs = useMemo(() => {
    const name = _.trim(_.get(values, dataAppConfigFields.dataAppName));

    const { error } = validateNameField({
      fieldName: name,
      fieldNameLabel: "dataApp name"
    });

    return {
      [dataAppConfigFields.dataAppName]: name ? error : ""
    };
  }, [values]);

  const disabledTooltipMsg = useMemo(() => {
    const name = _.trim(_.get(values, dataAppConfigFields.dataAppName));
    if (type === CreateDataAppRequestDtoDataAppTypeEnum.Askai) {
      if (!name) {
        return "Please input DataApp Name to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.dataAppName)) {
        return "Please use valid DataApp Name to enable";
      }

      return "";
    } else {
      const recipeId = _.get(values, dataAppConfigFields.recipeId);
      if (!name || !recipeId) {
        return "Please input DataApp Name and select Recipe to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.dataAppName)) {
        return "Please use valid DataApp Name to enable";
      }

      return "";
    }
  }, [values, type, errorMsgs]);

  const handleSubmit = () => {
    if (projectId) {
      if (type === CreateDataAppRequestDtoDataAppTypeEnum.Askai) {
        if (askAIDataAppTemplateId) {
          createDataAppMutation.mutate({
            dataAppName: _.get(values, dataAppConfigFields.dataAppName),
            description: _.get(values, dataAppConfigFields.description),
            appTemplateId: askAIDataAppTemplateId,
            projectId,
            iconUrl: _.get(values, dataAppConfigFields.imageBase64),
            dataAppType: type,
            dataappConfig: {
              llmModel: _.get(values, dataAppConfigFields.llmModel),
              inputType: _.get(values, dataAppConfigFields.inputType)
            },
            onSuccess
          });
        } else {
          handleResponse({
            errorMessage: "Please make sure to publish ask AI template before creating the app"
          });
        }
        return;
      }

      if (type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel) {
        const currRecipe = recipes.find(
          (recipe) => recipe.id === _.get(values, dataAppConfigFields.recipeId)
        );
        const currentAppTemplate = appTemplates?.find(
          (appTemplate: any) =>
            appTemplate.autoMlProblemType?.toLowerCase() === currRecipe?.metadata?.problemType &&
            appTemplate.buildStatus
        );
        if (!currentAppTemplate) {
          handleResponse({ errorMessage: `App Template not found for the specified recipe;` });
        }

        projectId &&
          currentAppTemplate &&
          currRecipe &&
          createDataAppMutation.mutate({
            dataAppName: _.get(values, dataAppConfigFields.dataAppName),
            description: _.get(values, dataAppConfigFields.description),
            projectId,
            iconUrl: _.get(values, dataAppConfigFields.imageBase64),
            appTemplateId: currentAppTemplate.id,
            recipeId: currRecipe.id,
            metadata: currRecipe?.runConfigs?.[0]?.variables,
            dataAppType: type,
            onSuccess
          });
        return;
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px"
        }}>
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <IconButton style={{ padding: 0 }} onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography component="h3" style={{ color: "#515151" }}>
            {type === CreateDataAppRequestDtoDataAppTypeEnum.Askai ? "Custom" : "Model"}{" "}
            Configuration
          </Typography>
        </div>
        <Tooltip title={disabledTooltipMsg}>
          <span>
            <Button
              size="small"
              style={{ marginRight: "16px" }}
              disabled={!!disabledTooltipMsg || createDataAppMutation.isLoading}
              variant="contained"
              startIcon={
                createDataAppMutation.isLoading ? <CircularProgress size={16} /> : undefined
              }
              color="primary"
              onClick={handleSubmit}>
              Create
            </Button>
          </span>
        </Tooltip>
      </div>
      <Grid container direction="row" spacing={2}>
        <Grid
          item
          xs={type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel ? 12 : 6}
          container
          spacing={2}
          direction="column">
          <Grid item>
            <CreateDataAppInputs
              type={type}
              values={values}
              errorMsgs={errorMsgs}
              recipes={recipes}
              onChange={handleInputChange}
            />
          </Grid>
          {type === CreateDataAppRequestDtoDataAppTypeEnum.Askai && (
            <Grid item>
              <SelectAskAILLMModel
                value={_.get(values, dataAppConfigFields.llmModel)}
                onChange={handleInputChange}
              />
            </Grid>
          )}
        </Grid>
        {type === CreateDataAppRequestDtoDataAppTypeEnum.Askai && (
          <Grid item xs={6}>
            <SelectAskAIInputType
              disabledInputs={disabledInputs}
              value={_.get(values, dataAppConfigFields.inputType)}
              onChange={handleInputChange}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CreateDataAppForm;
