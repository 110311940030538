import { Box, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { ChatBox } from "./ChatBox";
import { Spinner } from "src/components";

const useStyles = makeStyles({
  root: {
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "nowrap"
  },
  title: {
    color: "#4646B5",
    textWrap: "nowrap",
    fontWeight: 400,
    wordBreak: "break-word",
    whiteSpace: "normal"
  },
  titleBox: {
    padding: ({ isExpanded }: any) => (isExpanded ? "6px 12px" : "0px"),
    borderTopRightRadius: "4px",
    gap: "16px",
    cursor: ({ isExpandDisabled }: any) => (isExpandDisabled ? "default" : "pointer")
  },
  dataContainer: {
    background: "#fff",
    borderRadius: "4px",
    maxWidth: "100%"
  },
  dataGrid: {
    maxHeight: "260px",
    overflowY: "auto",
    margin: "0px 6px 0px 6px",
    borderRadius: "2px"
  },
  bottomGrid: {
    padding: "0px 12px 12px 12px",
    gap: "16px",
    alignItems: "center"
  },
  sizeText: {
    fontSize: "14px",
    color: "#515151"
  },
  boxWrap: {
    width: ({ isExpanded }) => (isExpanded ? "100%" : "auto"),
    boxShadow: ({ isExpanded }: any) => (isExpanded ? "0px 4px 4px 0px #82828240" : "none")
  },
  child: {
    display: ({ isExpanded }: any) => (isExpanded ? "block" : "none"),
    width: "100%",
    background: "#fff",
    borderRadius: "0px 0px 4px 4px"
  },
  rightContainer: {
    gap: "8px",
    width: "auto"
  }
});

interface IProps {
  isExpandDisabled: boolean;
  isGenerating?: boolean;
  title: string;
  children: React.ReactNode;
  icon: React.ReactNode;
  isCollapsed: boolean;
  isLoading?: boolean;
  onToggle?: ({ onSuccess }: { onSuccess: () => void }) => void;
  rightContainer?: React.ReactNode;
  isAddedToRecipe?: boolean;
  width?: string;
}

export const AIGuideChatBox = ({
  isExpandDisabled,
  icon,
  isGenerating = false,
  title,
  children,
  isCollapsed,
  isLoading,
  onToggle,
  rightContainer,
  isAddedToRecipe = false,
  width
}: IProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles({ isExpandDisabled, isExpanded });

  useEffect(() => {
    if (isCollapsed || isGenerating) {
      setIsExpanded(false);
    }
    if (!isCollapsed) {
      setIsExpanded(true);
    }
  }, [isCollapsed, isGenerating]);

  const toggleExpanded = () => {
    const onSuccess = () => {
      setIsExpanded((expanded) => !expanded);
    };
    onToggle ? onToggle({ onSuccess }) : onSuccess();
  };

  const chatBoxProps = isExpanded
    ? {
        noPadding: true,
        color: isAddedToRecipe ? "#E9FFDF" : "#F2F2FF",
        border: "#4646B5",
        width: width || "calc(100% - 134px)",
        childWidth: "100%"
      }
    : {
        border: isAddedToRecipe ? "#E9FFDF" : "#FFF",
        color: isAddedToRecipe ? "#E9FFDF" : "#FFF",
        width: "auto"
      };
  return (
    <Grid container className={classes.root}>
      <Box mr="24px">{icon}</Box>
      <ChatBox {...chatBoxProps}>
        <Grid container direction="column" className={classes.boxWrap}>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
            className={classes.titleBox}
            onClick={() => !isExpandDisabled && toggleExpanded()}>
            <Grid item>
              <Typography variant="subtitle2" className={classes.title}>
                {title}
              </Typography>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              direction="row"
              wrap="nowrap"
              justifyContent="flex-end"
              className={classes.rightContainer}>
              {rightContainer}
              <IconButton size="small" style={{ padding: "0px" }}>
                {isLoading ? (
                  <Spinner size={18} noPadding />
                ) : isExpanded ? (
                  <ExpandLess fontSize="small" />
                ) : (
                  <ExpandMore fontSize="small" />
                )}
              </IconButton>
            </Grid>
          </Grid>
          <Box className={classes.child}>{children}</Box>
        </Grid>
      </ChatBox>
    </Grid>
  );
};
