/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'errMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'originalErrMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'errorType'?: ModelErrorErrorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'explanation'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'lineNo'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'lineInCode'?: string;
}

export const ModelErrorErrorTypeEnum = {
    Success: 'SUCCESS',
    Unknown: 'UNKNOWN',
    LlmDown: 'LLM_DOWN',
    RequestInvalid: 'REQUEST_INVALID',
    InputInvalid: 'INPUT_INVALID',
    LlmNotSupported: 'LLM_NOT_SUPPORTED',
    PromptGeneratorNotImplemented: 'PROMPT_GENERATOR_NOT_IMPLEMENTED',
    LlmGeneratedInvalidCode: 'LLM_GENERATED_INVALID_CODE',
    LlmGeneratedInvalidTagsResponse: 'LLM_GENERATED_INVALID_TAGS_RESPONSE',
    CodeExecutionError: 'CODE_EXECUTION_ERROR'
} as const;

export type ModelErrorErrorTypeEnum = typeof ModelErrorErrorTypeEnum[keyof typeof ModelErrorErrorTypeEnum];


