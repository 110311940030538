// $FixMe: Scope to be refactored.
// The whole component is to be refactored.

import React, { useEffect, useState } from "react";
import { makeStyles, TextField, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

const FONT_SIZE = 14;

export const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: 12,
    // @ts-expect-error
    maxWidth: ({ inputWidth }) => inputWidth
  },
  inputClass: {
    flex: "1 0 auto",
    "& div:not(.Mui-focused):not(:hover) fieldset": {
      border: "none"
    },
    "& input": {
      fontSize: FONT_SIZE,
      padding: "8px"
    }
  },
  infoTooltipStyles: {
    margin: "0px 15px",
    cursor: "help",
    opacity: 0.5,
    verticalAlign: "bottom"
  }
}));

type OwnProps = {
  placeholder?: string;
  inputValue?: string;
  onSave: $TSFixMeFunction;
  isLoading?: boolean;
  infoTooltip?: string;
  isResetName?: number | boolean;
  containerProps?: $TSFixMe;
  inputProps?: any;
};

// @ts-expect-error TS(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof EditName.defaultProps;

export default function EditName({
  placeholder,
  inputValue,
  onSave,
  isLoading,
  infoTooltip,
  isResetName,
  containerProps,
  inputProps,
  ...restProps
}: Props) {
  const classes = useStyles({ inputWidth: (inputValue?.length + 1) * (FONT_SIZE - 4) + 16 });

  const [inputName, setInputName] = useState(inputValue);

  const handleChange = (e: $TSFixMe) => {
    const { value } = e.target;
    setInputName(value);
  };
  const handleSubmit = () => {
    if (inputValue.trim() === inputName.trim()) {
      return;
    }

    onSave(inputName);
  };

  const handleKeyPress = (e: $TSFixMe) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    setInputName(inputValue);
  }, [inputValue]);

  useEffect(() => {
    !!isResetName && setInputName(inputValue);
  }, [isResetName]);

  return (
    <div className={classes.container} {...containerProps}>
      <TextField
        size="small"
        className={classes.inputClass}
        onBlur={handleSubmit}
        onChange={handleChange}
        inputProps={inputProps}
        placeholder={placeholder}
        value={inputName}
        variant="outlined"
        onKeyPress={handleKeyPress}
        disabled={isLoading}
        {...restProps}
      />
      {infoTooltip && (
        <Tooltip title={infoTooltip}>
          <InfoOutlined className={classes.infoTooltipStyles} />
        </Tooltip>
      )}
    </div>
  );
}

EditName.defaultProps = {
  placeholder: "",
  inputValue: "",
  isLoading: false
};
