import React, { useCallback, useMemo, useState } from "react";

// Packages
import {
  Cell,
  ColumnDefResolved,
  ColumnDefTemplate,
  HeaderContext,
  VisibilityState
} from "@tanstack/react-table";
import {
  filter,
  findKey,
  fromPairs,
  includes,
  isEmpty,
  isString,
  map,
  reduce,
  size,
  toLower,
  trim,
  uniq
} from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

// Hooks
import useUserPreferences from "./useUserPreferences";

// Components
import { Table } from "src/components/custom";
import { TableCogIcon } from "src/icons/NewUX";
import { TableConfig } from "./TableData.constants";

// Types
import { ServerSideRenderingProps } from "src/components/custom/Table/Table.types";

type Row = {
  [key: string]: string | React.ReactNode;
};

type Schema = {
  [key: string]: string;
};

type Props = {
  userId?: string | null | undefined;
  datasetId?: string | null | undefined;
  datasetName?: string | null | undefined;
  isLoading: boolean;
  data: {
    rows: Row[];
    columns: string[];
  };
  showSettingIconOutside: boolean;
  schema: string[];
  allColumns: string[];
  toggleColumns: (columns: (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]) => void;
  serverSideRenderingProps?: ServerSideRenderingProps;
};

const TableData = (props: Props) => {
  const {
    userId,
    datasetId,
    datasetName,
    isLoading,
    data: inputData,
    showSettingIconOutside,
    schema,
    allColumns,
    toggleColumns,
    serverSideRenderingProps
  } = props || {};

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [togglingColumns, setTogglingColumns] = useState<
    (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]
  >(inputData?.columns);
  const [tableSettingsAnchorEl, setTableSettingsAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );

  const getColumnId = useCallback(
    // Replacing ".", "[", "]" with "_".
    (columnVal?: ColumnDefTemplate<HeaderContext<any, unknown>> | undefined) =>
      ("" + columnVal).replace(/[.[\]]/gi, "_"),
    []
  );

  const schemaMap = useMemo(
    () =>
      reduce(
        schema,
        (acc: Schema, { fieldSchema }: $TSFixMe) => {
          acc[getColumnId(fieldSchema?.fieldName)] =
            // <Box fontSize="small" fontStyle="italic" fontWeight="bold" color="#7c7c7c">
            fieldSchema?.rcDataType;
          // </Box>
          return acc;
        },
        {}
      ),
    [schema]
  );

  const columns = useMemo<ColumnDefResolved<Row, any>[]>(() => {
    const thisColumns = uniq([...(allColumns || []), ...(inputData?.columns || [])]);

    return map(thisColumns, (columnName: string) => ({
      // id is optional when for accessor column is created with an object key accessor.
      accessorKey: getColumnId(columnName),
      header: columnName,
      cell: ({ cell }: { cell: Cell<Row, string> }) => cell?.getValue(),
      meta: schemaMap?.[getColumnId(columnName)],
      minSize: 50
    }));
  }, [inputData?.columns, allColumns, schemaMap]);

  const data = useMemo<Row[]>(() => {
    if (isEmpty(inputData?.rows) || isEmpty(inputData?.columns)) {
      return [];
    }

    return reduce(
      inputData?.rows,
      (rowAcc: Row[], eachRow: Row) => {
        rowAcc?.push(
          reduce(
            // @ts-ignore
            eachRow?.cells,
            (cellAcc: Row, cellVal: string, cellIndex: number) => {
              const thisCellVal =
                !!cellVal && isString(cellVal) && includes(["nan"], toLower(trim(cellVal)))
                  ? ""
                  : cellVal;

              cellAcc[getColumnId(inputData?.columns[cellIndex])] = thisCellVal;

              return cellAcc;
            },
            {}
          )
        );

        return rowAcc;
      },
      []
    );
  }, [inputData]);

  const getPairsOfColumnVisibility = (
    columns: (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]
  ) =>
    fromPairs(
      map(allColumns, (columnName: string) => [
        getColumnId(columnName),
        size(columns) === 0
          ? false
          : includes(
              map(
                columns,
                (inputDataColumnName: ColumnDefTemplate<HeaderContext<any, unknown>> | undefined) =>
                  getColumnId(inputDataColumnName)
              ),
              getColumnId(columnName)
            )
      ])
    );

  const onToggleColumns = (
    columns: (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]
  ) => {
    setTogglingColumns(() => columns);

    if (size(columns) === 0) {
      setColumnVisibility(() => getPairsOfColumnVisibility([]));
    } else {
      if (size(filter(columns, (columnName) => !includes(inputData?.columns, columnName))) > 0) {
        toggleColumns(columns);
      } else {
        setColumnVisibility(() => getPairsOfColumnVisibility(columns));
      }
    }
  };

  const { columnOrder, storeUserPreferences } = useUserPreferences({
    userId,
    datasetId,
    inputData,
    columns,
    data,
    togglingColumns,
    setColumnVisibility,
    getColumnId,
    getPairsOfColumnVisibility
  });

  const handleTableSettingsClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setTableSettingsAnchorEl(() => event?.currentTarget);
  };

  return isLoading ||
    (isEmpty(columns) && isEmpty(data) && serverSideRenderingProps?.isFetching) ? (
    <Box p={4}>
      <Grid container>
        <CircularProgress color="secondary" style={{ margin: "auto" }} />
      </Grid>
    </Box>
  ) : isEmpty(columns) || isEmpty(data) ? (
    <Box p={4}>
      <Grid container alignItems="center" justifyContent="center">
        <Alert severity="info" style={{ width: "25%", justifyContent: "center" }}>
          No data found!
        </Alert>
      </Grid>
    </Box>
  ) : (
    <>
      {showSettingIconOutside && !isEmpty(inputData?.rows) && !isEmpty(inputData?.columns) && (
        <Box style={{ padding: 8 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle2">{datasetName || ""}</Typography>
            <IconButton
              size="small"
              onClick={handleTableSettingsClick}
              color="primary"
              style={{ padding: 0 }}>
              <TableCogIcon width={24} height={24} />
            </IconButton>
          </Grid>
        </Box>
      )}
      <Table
        columns={columns}
        data={data}
        size="small"
        maxHeight="calc(100vh - 275px)"
        isStickyHeader
        sortBy={findKey(columnVisibility, (column: boolean) => column === true)}
        columnVisibility={columnVisibility}
        columnOrder={columnOrder}
        tableSettingsProps={{
          maxColumnsCount: TableConfig.MaxColumnsCount,
          anchorEl: tableSettingsAnchorEl,
          setAnchorEl: setTableSettingsAnchorEl
        }}
        serverSideRenderingProps={serverSideRenderingProps}
        toggleColumns={onToggleColumns}
        storeUserPreferences={storeUserPreferences}
      />
    </>
  );
};

export default TableData;
