import { useCallback } from "react";

// Packages
import useTheme from "@material-ui/core/styles/useTheme";
import { getConnectedEdges, getIncomers, isNode } from "react-flow-renderer";

type Props = {
  setNodes: $TSFixMeFunction;
  setEdges: $TSFixMeFunction;
};

const useHighlightPath = (props: Props) => {
  const { setNodes, setEdges } = props || {};

  const theme = useTheme();

  // The nodes & edges here are passed by the event-handler.
  const getAllIncomers = (node: $TSFixMe, nodes: $TSFixMe, edges: $TSFixMe, prevIncomers = []) => {
    const incomerNodes = getIncomers(node, nodes, edges);
    const incomerEdges = getConnectedEdges(incomerNodes, edges).filter((item) => {
      const incomerIds = incomerNodes.map((i) => i.id);
      return incomerIds.includes(item.target) || item.target === node.id;
    });
    const result = [node, ...incomerNodes, ...incomerEdges].reduce((memo, incomer) => {
      memo.push(incomer);

      if (prevIncomers.findIndex((n) => (n as $TSFixMe).id == incomer.id) == -1) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        prevIncomers.push(incomer);

        getAllIncomers(incomer, nodes, edges, prevIncomers)?.forEach((foundNode: $TSFixMe) => {
          memo.push(foundNode);
          if (prevIncomers.findIndex((n) => (n as $TSFixMe).id == foundNode.id) == -1) {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
            prevIncomers.push(incomer);
          }
        });
      }
      return memo;
    }, []);
    return result;
  };

  const highlightPath = useCallback((node: $TSFixMe, nodes: $TSFixMe, edges: $TSFixMe) => {
    node.selected = true;

    if (node && [...nodes, ...edges]) {
      const allIncomers = getAllIncomers(node, nodes, edges);

      setNodes((prevElements: $TSFixMe) => {
        return prevElements?.map((elem: $TSFixMe) => {
          const incomerIds = allIncomers.map((i: $TSFixMe) => i.id);

          if (isNode(elem) && allIncomers.length > 0) {
            const highlight = elem.id === node.id || incomerIds.includes(elem.id);

            elem.style = {
              ...elem.style,
              opacity: highlight ? 1 : 0.25
            };
          }
          return elem;
        });
      });

      setEdges((prevElements: $TSFixMe) => {
        return prevElements?.map((elem: $TSFixMe) => {
          const highlight = allIncomers.find(
            (item: $TSFixMe) => item.target === elem.target && item.source === elem.source
          );

          if (highlight) {
            elem.animated = true;
            elem.selected = true;

            elem.markerEnd = {
              ...elem.markerEnd,
              color: theme.palette.info.main
            };
          }

          elem.style = {
            ...elem.style,
            stroke: highlight ? theme.palette.info.main : "#c7c7c7",
            opacity: highlight ? 1 : 0.25 // Applying opacity 1 to currently animating edge and 0.25 to rest of the edges.
          };

          return elem;
        });
      });
    }
  }, []);

  return { highlightPath };
};

export default useHighlightPath;
