import { useMutation } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import {
  createTemplateWithRethrow,
  saveTransformByIdWithRethrow,
  updateTemplateWithRethrow,
  deleteTransformByIdWithRethrow,
  getTransformGroupWithRethrow
} from "src/api";
import { Recipe } from "src/types";

export const useSaveCodeRecipeMutation = () => {
  return useMutation({
    mutationFn: async ({
      projectId,
      recipe,
      groupId,
      templates,
      codeStrings
    }: {
      projectId: string;
      recipe: Recipe | null;
      groupId?: string;
      templates: Array<$TSFixMe>;
      codeStrings: string[];
    }) => {
      const hasNewTemplates = codeStrings?.length !== recipe?.runConfigs?.length;
      if (!hasNewTemplates) {
        const templatesResponse = await Promise.all(
          templates?.map((template, index) => {
            const templatePayload = {
              ...template,
              tags: ["code-template"],
              code: codeStrings[index]
            };
            return updateTemplateWithRethrow(templatePayload);
          })
        );
        const transformGroupResponse = await getTransformGroupWithRethrow(recipe?.id);

        return {
          templatesResponse,
          transformGroupResponse: transformGroupResponse?.[0],
          hasNewTemplates
        };
      }
      const configIdsToDelete = recipe?.runConfigs?.map((config) => config.id) || [];
      await Promise.all(
        configIdsToDelete.map(async (configId) => await deleteTransformByIdWithRethrow(configId))
      );

      const newTemplateResponses = await Promise.all(
        codeStrings?.map((codeString) => {
          const templatePayload = {
            name: uuidv4(),
            code: codeString,
            projectId,
            baseTransforms: [{ condition: null, type: "python", params: {}, runConfigId: null }],
            type: "CODE",
            tags: ["code-template"]
          };
          return createTemplateWithRethrow(templatePayload);
        })
      );
      await Promise.all(
        newTemplateResponses.map((templateResponse) => {
          const payload = {
            name: uuidv4(),
            groupId: groupId ?? recipe?.id,
            templateId: templateResponse.id
          };
          return saveTransformByIdWithRethrow(payload);
        })
      );

      const transformGroupResponse = await getTransformGroupWithRethrow(groupId ?? recipe?.id);

      return {
        templatesResponse: newTemplateResponses,
        transformGroupResponse: transformGroupResponse?.[0],
        hasNewTemplates
      };
    },
    cacheTime: 0
  });
};
