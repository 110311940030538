import React from "react";
export const AIGuideIcon = ({
  width = 48,
  height = 48
}: { width?: number; height?: number } = {}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_17930_4826)">
        <path
          d="M24 45.9862C24 47.4309 25.4959 48.4043 26.8394 47.8349C39.2365 42.5806 47.7328 32.9759 47.8579 20.4041C47.8594 20.3111 47.8594 20.2169 47.8594 20.1227C47.8594 20.0285 47.8594 19.9342 47.8579 19.8412V20.4042C47.8594 20.3112 47.8594 20.217 47.8594 20.1228C47.8594 20.0286 47.8594 19.9342 47.8579 19.8413C47.6782 8.85778 37.0653 0 24 0C10.823 0 0.140625 9.00928 0.140625 20.1226C0.140625 31.2359 10.823 40.2452 24 40.2452V45.9862Z"
          fill="url(#paint0_linear_17930_4826)"
        />
        <path
          d="M46.3655 13.1011C41.0835 27.1433 27.5268 37.1348 11.6356 37.1348C11.5066 37.1348 11.3777 37.1337 11.249 37.1324C14.9376 39.1033 19.3105 40.2453 24.0001 40.2453V45.9862C24.0001 47.4309 25.4959 48.4043 26.8395 47.8349C39.2366 42.5806 47.7329 32.9758 47.8579 20.404C47.8594 20.311 47.8594 20.2168 47.8594 20.1226C47.8594 20.0284 47.8594 19.934 47.8579 19.8411C47.8191 17.473 47.2943 15.2041 46.3655 13.1011Z"
          fill="url(#paint1_linear_17930_4826)"
        />
        <path d="M24 27.75V12.75" stroke="white" strokeWidth="3.75" strokeLinecap="round" />
        <path d="M16.5 24V16.5" stroke="white" strokeWidth="3.75" strokeLinecap="round" />
        <path d="M31.5 24V16.5" stroke="white" strokeWidth="3.75" strokeLinecap="round" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_17930_4826"
          x1="-4.03481"
          y1="12"
          x2="-11.3227"
          y2="44.1822"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF653D" />
          <stop offset="1" stopColor="#8585FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17930_4826"
          x1="8.04558"
          y1="21.8258"
          x2="2.99908"
          y2="45.3411"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF653D" />
          <stop offset="1" stopColor="#8585FF" />
        </linearGradient>
        <clipPath id="clip0_17930_4826">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AIGuideIcon;
