import _ from "lodash";
import { Entities } from "src/types";
import { AskAIChatResponseType } from "../../CodeRecipeContext/CodeRecipeContextProvider";
import { InputIdDto, InputIdDtoTypeEnum } from "openapi/Models/input-id-dto";
import { ThreadResponseDto } from "openapi/Models/thread-response-dto";
import { AIChatResponseDto } from "openapi/Models";

export const extractAskAIDatasets = ({
  inputDatasets,
  inputNames,
  responses
}: {
  inputDatasets: Entities;
  inputNames: string[];
  responses: AskAIChatResponseType[];
}) => {
  const selectedInputEntities = _.filter(inputDatasets, (entity) =>
    _.includes(inputNames, entity.displayName || entity.name)
  );

  const selectedInputEntityNames = _.map(
    selectedInputEntities,
    (entity) => entity.displayName || entity.name
  );
  const filteredIntermediateDs = _.filter(responses, ["outputType", "DATASET"]);
  const intermediateDatasetHistoryIdMapping: any = filteredIntermediateDs?.reduce(
    (acc, response: any) => {
      const {
        historyId,
        askAIMessageId,
        outputEntityResponseList,
        outputEntityList,
        queryOutputs
      } = response;
      const responseList = _.fromPairs(
        _.map(outputEntityResponseList || outputEntityList || queryOutputs, (item) => [
          item.name,
          historyId || askAIMessageId
        ])
      );

      return Object.keys(responseList).length
        ? {
            ...acc,
            ...responseList
          }
        : acc;
    },
    {}
  );
  const intermediateDatasets = inputNames
    ?.filter((name) => !selectedInputEntityNames?.includes(name))
    ?.reduce(
      (acc: any, name) => ({ ...acc, [name]: intermediateDatasetHistoryIdMapping[name] }),
      {}
    );

  const entityIds = selectedInputEntities?.map((entity) => entity.id) || [];

  return { intermediateDatasets, selectedInputEntities, entityIds };
};

export const extractAskAINewFlowInputDatasetIds = ({
  recipeThread,
  entityIds,
  intermediateDatasets
}: {
  entityIds: string[];
  recipeThread: ThreadResponseDto | undefined;
  intermediateDatasets: any;
}): { inputIds: InputIdDto[] } => {
  const filteredTargetInputs = _.filter(recipeThread?.targetInputs || [], (targetInput) =>
    _.includes(entityIds, targetInput.entityId)
  );
  const inputDsIds = _.map(filteredTargetInputs, (threadInput) => ({
    id: threadInput.askAIInputId!,
    type: InputIdDtoTypeEnum.InputId,
    name: threadInput.entityName!
  }));

  const intermediateDsIds = _.map(_.entries(intermediateDatasets || []), ([name, dsId]) => ({
    id: dsId as string,
    type: InputIdDtoTypeEnum.AskaiMessageId,
    name
  }));

  return { inputIds: [...inputDsIds, ...intermediateDsIds] };
};

export const getAllDescendants = (
  messages: Array<AIChatResponseDto>,
  message: AIChatResponseDto
): Array<AIChatResponseDto> => {
  const { outputEntityResponseList, outputEntityList } = message;
  const outputNames =
    (outputEntityResponseList || outputEntityList)?.map((outputEntity) => outputEntity.name!) || [];

  let descendants: Array<AIChatResponseDto> = [];

  function findDescendants(
    currentMessages: Array<AIChatResponseDto>,
    parentOutputNames: Array<string>
  ) {
    const directDescendants = _.filter(currentMessages, (msg) => {
      return _.some(msg.targetInputs, (input) =>
        parentOutputNames.includes(input.entityName! || input.intermediateDataset?.entityName!)
      );
    });

    descendants = _.union(descendants, directDescendants);

    for (const descendant of directDescendants) {
      const descendantOutputNames =
        (descendant.outputEntityResponseList || descendant.outputEntityList)?.map(
          (outputEntity) => outputEntity.name!
        ) || [];
      findDescendants(currentMessages, descendantOutputNames);
    }
  }

  findDescendants(messages, outputNames);

  return descendants;
};

export const checkCanDeleteAIGuideMessage = (
  messages: Array<AIChatResponseDto>,
  message: AIChatResponseDto
): boolean => {
  const descendants = getAllDescendants(messages, message);
  return !_.some([...descendants, message], (message: AIChatResponseDto) => !!message.transformId);
};
