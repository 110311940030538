import React, { useState } from "react";

import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message
const PasswordInput = React.forwardRef(({ className, label = "Password", ...rest }, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      label={label}
      variant="outlined"
      // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
      ref={ref}
      className={className}
      type={showPassword ? "text" : "password"} // <-- This is where the magic happens
      InputProps={{
        // <-- This is where the toggle button is added.
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...rest}
    />
  );
});

PasswordInput.displayName = "PasswordInput";

export default PasswordInput;
