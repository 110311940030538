import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { IconButton, makeStyles } from "@material-ui/core";
import { map } from "lodash";

import OverflowTooltip from "components/OverflowTooltip";
import ViewDataData from "pages/ViewData/ViewDataData/ViewDataData";
import api from "utils/AxiosClient";
import {
  BorderLinearProgress,
  useStyles
} from "pages/Library/ArtifactsAndModels/Artifacts/UploadFile";
import { csvtoJson } from "../utils/PredictionJob.helpers";
import { handleResponse } from "utils/apiService";
import { getExtension } from "utils/formatText";
import { useCancelApis } from "utils/useCancelApis";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { formatBytes } from "src/utils/format-bytes";

interface IProps {
  file: File;
  entityId: string;
  projectRunId: string;
  onCancel: () => void;
  onFileUpload: (fileName: string) => void;
}

const useLocalStyles = makeStyles({
  wrapper: {
    background: "#fff",
    padding: "16px",
    borderRadius: "4px",
    boxShadow: "0px 4px 4px 0px #ADADAD40"
  },
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "16px"
  }
});

interface IData {
  columns: string[];
  rows: { cells: string[] }[];
}

const UploadedFileWithData: React.FC<IProps> = (props) => {
  const { file, entityId, projectRunId, onCancel, onFileUpload } = props;

  const [progress, setProgress] = useState<number | null>(0);
  const [data, setData] = useState<IData>({
    columns: [],
    rows: []
  });

  const { newCancelApiToken, cancelApi } = useCancelApis();
  const cancelApiTokenRef = useRef<any>(null);
  const classes = useStyles({
    uploadComplete: progress === 100,
    uploadFailed: false
  });
  const { wrapper, root } = useLocalStyles();

  useEffect(() => {
    let mounted = true;

    if (!cancelApiTokenRef?.current) {
      cancelApiTokenRef.current = newCancelApiToken();
    }

    const handleUploadProgress = (e: any) => {
      let progressValue = Math.floor((e.loaded * 100) / e.total);
      if (mounted) {
        setProgress(() => (progressValue === 100 ? 99 : progressValue));
      }
    };

    const upload = async () => {
      try {
        const signedUrlObj = await api.fetchResponse(
          async () => await api.ProjectRunControllerApi.getUploadSignedUrl(projectRunId, entityId)
        );

        try {
          const [columns, ...rest] = await csvtoJson(file);
          setData({ columns, rows: map(rest, (item) => ({ cells: item })) });
        } catch {
          handleResponse({ errorMessage: "Unable to display the csv data" });
        }

        await axios(signedUrlObj?.signedUrl as string, {
          method: "put",
          data: file,
          headers: { ...signedUrlObj?.headers },
          cancelToken: cancelApiTokenRef.current,
          onUploadProgress: handleUploadProgress
        });

        onFileUpload(file.name);
        setProgress(100);
      } catch (error: any) {
        onCancel();
      }
    };

    upload();

    return () => {
      mounted = false;
    };
  }, []);

  const handleCancel = () => {
    cancelApi();
    onCancel();
  };

  return (
    <NewThemeWrapper>
      <div className={root}>
        <div className={wrapper}>
          <div
            key={file.name}
            style={{ width: "40%", minWidth: "550px" }}
            className={classes.fileContainer}>
            <div className={classes.left}>
              <div className={classes.title}>
                <OverflowTooltip value={file?.name} />
              </div>
              <div className={classes.subtitle}>
                <span>Size: {file.size ? formatBytes(file.size) : `0 KB`}</span>
                <span>Type: {getExtension(file.name)}</span>
              </div>
            </div>
            <div className={classes.progress}>
              {progress === 100 ? (
                <div className={classes.success}>
                  <CheckCircleIcon />
                  <span>Uploaded Successfully!</span>
                </div>
              ) : progress === null ? (
                <div className={classes.failure}>
                  <CancelIcon />
                  <span>Upload Failed!</span>
                </div>
              ) : (
                <>
                  <span>Uploading {progress}%</span>
                  <div className={classes.close}>
                    <BorderLinearProgress
                      style={{ width: "100%" }}
                      variant="determinate"
                      value={progress}
                    />
                    <IconButton size="small" onClick={handleCancel}>
                      <CancelIcon />
                    </IconButton>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {progress === 100 && (
          <div className={wrapper}>
            <ViewDataData
              responseData={data}
              hideCount={false}
              entityFeatures={[]}
              maxHeight="calc(100vh - 340px)"
            />
          </div>
        )}
      </div>
    </NewThemeWrapper>
  );
};

export default UploadedFileWithData;
