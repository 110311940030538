export enum SYNTAX_TABS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  ShopifyOrders = "Shopify Orders"
}

export const SYNTAX_CODE = {
  [SYNTAX_TABS.ShopifyOrders]: `def transform(entities, context):
      import requests
      import pandas as pd
      from utils.notebookhelpers.helpers import Helpers
  
      # Fetch the access token using a helper function
      access_token = Helpers.get_secret(context, "Example_Secret_Key")
      if not access_token:
          print("Failed to retrieve access token.")
          return {}
  
      url_orders = "https://www.domain.shop/admin/api/2024-01/orders.json?created_at_max=2024-12-12T23:59:59-00:00&status=any"
      url = url_orders
      headers = {
          'X-Shopify-Access-Token': access_token
      }
      dataframes = []
      i = 0
      next_link = True
  
      while True:
          if not next_link:
              break
          if i == 0:
              current_url = url
          else:
              current_url = next_url
          response = requests.get(current_url, headers=headers)
          if response.status_code == 200:
              data = response.json()
              if not data.get('orders'):
                  break
              df = pd.json_normalize(data['orders'])
              # print(len(df))
              dataframes.append(df)
              i += 1
              print('Page ' + str(i))
              # print(response.links)
              if 'next' in response.links:
                  next_link = response.links['next']
                  next_url = next_link['url']
              else:
                  print('no more next links')
                  next_link = False
          else:
              print(f"Error: {response.status_code}")
              print(response.text)
              break
          # break
  
      orders_df = pd.concat(dataframes, ignore_index=True)
      orders_df.drop_duplicates(subset=['id'], inplace=True)
      return {'output_orders_df': orders_df}`,
  [SYNTAX_TABS.GET]: `def transform(entities, context):
      import requests
      import pandas as pd
  
      url = "https://jsonplaceholder.typicode.com/users"
      response = requests.get(url)
      if response.status_code == 200:
          data = response.json()
          users_df = pd.json_normalize(data)
          print("Successfully retrieved users.")
          return {'output_users_df': users_df}
      else:
          print(f"Error: {response.status_code}")
          print(response.text)
          return {}`,
  [SYNTAX_TABS.POST]: `def transform(entities, context):
      import pandas as pd
      import requests
  
      input_df_1 = entities['Dataset_Name']  # this is for reading input dataset
  
      # Extract the first row values
      first_row = input_df_1.iloc[0]
  
      url = "https://jsonplaceholder.typicode.com/posts"
      post_data = {
          'title': str(first_row['JobTitle']),
          'body': str(first_row['GivenName']),
          'userId': int(first_row['EmployeeNumber'])
      }
      
      response = requests.post(url, json=post_data)
      
      if response.status_code == 201:
          new_post = response.json()
          new_post_df = pd.json_normalize(new_post)
          print("Successfully created a new post.")
          return {'output_new_post_df': new_post_df}
      else:
          print(f"Error: {response.status_code}")
          print(response.text)
          return {}`,
  [SYNTAX_TABS.PUT]: `def transform(entities, context):
      import requests
      import pandas as pd
  
      input_df_1 = entities['Dataset_Name']  # this is for reading input dataset
  
      # Extract the first row values
      first_row = input_df_1.iloc[15]
  
      post_id = 1  # ID of the post to be updated
      url = f"https://jsonplaceholder.typicode.com/posts/{post_id}"
      updated_data = {
          'title': str(first_row['JobTitle']),
          'body': str(first_row['GivenName']),
          'userId': int(first_row['EmployeeNumber'])
      }
      
      response = requests.put(url, json=updated_data)
      if response.status_code == 200:
          updated_post = response.json()
          updated_post_df = pd.json_normalize(updated_post)
          print("Successfully updated the post.")
          return {'output_updated_post_df': updated_post_df}
      else:
          print(f"Error: {response.status_code}")
          print(response.text)
          return {}`
};
