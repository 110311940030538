import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";

const QUERY_KEY_ENVIRONMENTS = "query-key-environments";

const useGetEnvironments = (options?: UseQueryOptions): UseQueryResult<any> => {
  return useQuery({
    queryKey: [QUERY_KEY_ENVIRONMENTS],
    queryFn: async () => {
      return await getAPIWithRethrow(`/v2/envs`);
    },
    ...options
  });
};

export default useGetEnvironments;
export { QUERY_KEY_ENVIRONMENTS };
