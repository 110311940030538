/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";

// Icons
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import { DISABLED_RECIPE_LOGS } from "src/constants";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { READONLY_ENTITY } from "src/constants";
import { RunIcon } from "src/icons/NewUX/RunIcon";
import { TimeoutIcon } from "src/icons/NewUX/TimeoutIcon";
import { TimeoutTextField } from "../../common/TimeoutTextField/TimeoutTextField";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { handleResponse } from "src/utils/apiService";
import { openLogsModal } from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { useCanvasStore } from "src/store/store";
import { useJobsContext } from "src/pages/private/ProjectsModule/pages/Jobs/context/useJobsContext";
import { validateNameField } from "src/utils/formFieldUtils";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "../../AddCodeRecipe/CodeRecipeHeader/SubTopNavBarBreadcrumbs";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import RecipeLogsDrawer from "../../common/ShowLogsModal/RecipeLogsDrawer";
import { toastWrapper } from "src/utils/toastWrapper";
import { useApiConnectorRecipeContext } from "../ApiConnectorRecipeContext/useApiConnectorRecipeContext";
import { Scenario } from "src/types";

interface IProps {
  isDefaultScenario: boolean;
  scenarioData?: Scenario;
}

const ApiConnectorRecipeHeader: React.FC<IProps> = ({ isDefaultScenario, scenarioData }) => {
  const navigate = useNavigate();
  const { projectId, scenarioId, jobRunId } = useParams<$TSFixMe>();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  // Project context
  const { project } = useProjectContext() || {};

  // Jobs context
  const { jobPaths, jobPathsSetter } = useJobsContext() || {};

  const [isRecipeUpdateInProgress, setIsRecipeUpdateInProgress] = React.useState(false);
  const {
    jobData,
    jobRunData,
    isSaveDisabled,
    isRunDisabled,
    saveToolTip,
    runTooltip,
    handleUpdateRecipe,
    isRunInProgress,
    isSaveInProgress,
    recipe,
    handleSave,
    handleRun
  } = useApiConnectorRecipeContext();

  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);
  const { canAccessRecipeTimeout } = useAccessControlContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpenLogs = searchParams.get(openLogsModal);

  const nodes = useCanvasStore((state) => state.nodes);

  const dfsGroupNodes = useMemo(() => {
    return nodes?.filter(
      (node: $TSFixMe) => (node as $TSFixMe)?.type?.toLowerCase() === "dfsgroup"
    );
  }, [nodes]);

  const handleLogsClick = () => {
    setOpenLogs(true);
  };

  useEffect(() => {
    if (isOpenLogs) {
      handleLogsClick();
    }
  }, [isOpenLogs]);

  const validateName = useCallback(
    (name: string) => {
      const nameExist = dfsGroupNodes.some(
        (item: $TSFixMe) => item.name === name || item.displayName === name
      );
      const { error } = validateNameField({
        fieldName: name,
        fieldNameLabel: `recipe name`
      });
      return { nameExist, error };
    },
    [dfsGroupNodes]
  );

  const updateTransformName = useCallback(
    async (name: string) => {
      const cleanedName = name?.trim();
      const { error, nameExist } = validateName(cleanedName);

      if (error) {
        handleResponse({ errorMessage: error });
        setShouldResetName(Date.now());
      } else if (nameExist) {
        handleResponse({ errorMessage: "Recipe name already exists" });
        setShouldResetName(Date.now());
      } else if (recipe?.id) {
        handleUpdateRecipe({
          payload: { displayName: cleanedName },
          onError: () => setShouldResetName(Date.now()),
          onSuccess: () =>
            toastWrapper({ type: "success", content: "Recipe Name updated Successfully" })
        });
      }
    },
    [handleUpdateRecipe, recipe?.id, validateName]
  );

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleBack = useCallback(() => {
    navigate(`/projects/${projectId}/scenario/${scenarioId}/canvas`);
  }, [projectId, scenarioId]);

  const onUpdateRecipeRunTimeout = (timeout: number) => {
    setIsRecipeUpdateInProgress(true);
    handleUpdateRecipe({
      payload: { timeout },
      onSuccess: () => {
        setIsRecipeUpdateInProgress(false);
        handleResponse({ successMessage: "Recipe Run Timeout updated successfully." });
      },
      onError: () => setIsRecipeUpdateInProgress(false)
    });
  };

  const handleCloseLogs = () => {
    setOpenLogs(false);
    setSearchParams({});
  };

  const onProjectClick = () => {
    navigate(`/projects/${projectId}/canvas`);
  };

  return (
    <>
      <DeleteNodeModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteSuccess={handleBack}
        nodeId={recipe?.id}
        nodeName={recipe?.displayName}
        nodeType="group"
        deleteNote={
          <div>
            <span>Note: </span>
            {psMessage}
          </div>
        }
      />
      {openLogs && (
        <RecipeLogsDrawer
          name={recipe?.displayName ?? ""}
          open={openLogs}
          projectId={projectId}
          scenarioId={scenarioId}
          jobRunId={jobRunId}
          groupId={recipe?.id}
          isJobPath={isJobPath}
          onClose={handleCloseLogs}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          ...(isJobPath
            ? {
                backNavAction: () => {
                  jobPathsSetter(jobPaths.slice(0, -1));
                }
              }
            : {
                backNavAction: () => {}
              }),
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              isJobPath={isJobPath}
              onProjectClick={onProjectClick}
              jobData={jobData}
              jobRunId={jobRunId}
              jobRunName={jobRunData?.entryDto?.runId}
              isDefaultScenario={isDefaultScenario}
              scenarioName={scenarioData?.name}
              recipeName={recipe?.displayName || "Recipe"}
              updateRecipeName={updateTransformName}
              shouldResetRecipeName={shouldResetName}
            />
          )
        }}
        subTopNavBarRightSection={{
          ...(!isJobPath
            ? {
                moreOptions: [
                  {
                    label: "Delete",
                    icon: <TrashIcon viewBox="0 0 20 20" />,
                    action: () => (recipe?.id ? handleOpenDeleteModal() : handleBack()),
                    isDisabled: !recipe?.id || !isDefaultScenario,
                    ...(!isDefaultScenario ? { tooltip: READONLY_ENTITY } : {})
                  },
                  ...(canAccessRecipeTimeout
                    ? [
                        {
                          label: `Timeout ${recipe?.timeout || 0}hr`,
                          icon: <TimeoutIcon viewBox="0 0 20 22" />,
                          disabled: !recipe?.id,
                          nestedComponent: (
                            <TimeoutTextField
                              value={recipe?.timeout || ""}
                              minValue={1}
                              onChange={onUpdateRecipeRunTimeout}
                              disabled={!recipe?.id || isRecipeUpdateInProgress}
                            />
                          )
                        }
                      ]
                    : [])
                ]
              }
            : {}),
          component: (
            <>
              {!isJobPath && (
                <>
                  <Tooltip title={isDefaultScenario ? saveToolTip || "" : READONLY_ENTITY}>
                    <span>
                      <Button
                        size="small"
                        color="primary"
                        onClick={handleSave}
                        disabled={isSaveDisabled || !isDefaultScenario}>
                        {isSaveInProgress ? <CircularProgress size={22} /> : "Save"}
                      </Button>
                    </span>
                  </Tooltip>

                  <Tooltip
                    title={
                      runTooltip ||
                      "Please remember to save your changes. Unsaved modifications won't be considered in the recipe run"
                    }>
                    <span>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={handleRun}
                        disabled={isRunDisabled}
                        startIcon={isRunInProgress ? <CircularProgress size={18} /> : <RunIcon />}>
                        Run
                      </Button>
                    </span>
                  </Tooltip>
                </>
              )}

              <Tooltip title={!recipe?.id ? DISABLED_RECIPE_LOGS : ""}>
                <span>
                  <Button
                    size="small"
                    color="primary"
                    onClick={handleLogsClick}
                    disabled={!recipe?.id}>
                    <LogsNewIconV2
                      width="22"
                      height="22"
                      // New UX change
                      // The static value will be replaced with new theme at platform-level.
                      color={!recipe?.id ? "#4646b550" : "#4646b5"}
                    />
                  </Button>
                </span>
              </Tooltip>
            </>
          )
        }}
      />
    </>
  );
};

export default ApiConnectorRecipeHeader;
