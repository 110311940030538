import { useCallback } from "react";

// Utils
import { toastWrapper } from "src/utils/toastWrapper";

// APIs
import { putAPIWithRethrow } from "src/utils/apiService";

// Contexts
import { useDagFlowContext } from "../context/useDagFlowContext";

type Props = {
  nodes: $TSFixMe;
};

const useConnect = (props: Props) => {
  const { nodes } = props || {};

  const { setReloadTriggerStore } = useDagFlowContext();

  const onConnect = useCallback(
    async ({ source, target }: $TSFixMe) => {
      const sourceNode = nodes.find((node: $TSFixMe) => node.id === source);
      const targetNode = nodes.find((node: $TSFixMe) => node.id === target);

      (sourceNode as $TSFixMe).isSource = true;
      (sourceNode as $TSFixMe).isTarget = false;
      (targetNode as $TSFixMe).isSource = false;
      (targetNode as $TSFixMe).isTarget = true;

      const sourceNodeType = sourceNode.type;
      const targetNodeType = targetNode.type;

      if (sourceNodeType === "chart" || targetNodeType === "chart") {
        toastWrapper({
          type: "error",
          content: "Chart blocks cannot be connected with other types of blocks"
        });
        return;
      }

      if (sourceNodeType === "entity" && targetNodeType === "entity") {
        toastWrapper({
          type: "error",
          content: "Two datasets cannot be connected"
        });
        return;
      }

      if (sourceNodeType === "dfsgroup" && targetNodeType === "dfsgroup") {
        toastWrapper({
          type: "error",
          content: "Two recipes cannot be connected"
        });
        return;
      }

      const dfsGroup = [sourceNode, targetNode].find((node) => node.type === "dfsgroup");
      const dataset = [sourceNode, targetNode].find((node) => node.type === "entity");

      try {
        const [[dfsGroupFromBE], [datasetFromBE]] = await Promise.all([
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          getAPIWithRethrow(`/v2/dfs-run-config-groups?id=${dfsGroup.id}`),
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          getAPIWithRethrow(`/v2/entities?id=${dataset.id}`)
        ]);

        let parents = [...dfsGroupFromBE.parents];
        let children = [...dfsGroupFromBE.children];

        const objectToPush = {
          id: datasetFromBE.id,
          name: datasetFromBE.name,
          type: "ENTITY",
          displayName: datasetFromBE.displayName,
          viewData: (dataset as $TSFixMe).viewData
        };

        if ((dataset as $TSFixMe).isSource) {
          parents.push(objectToPush);
        }

        if ((dataset as $TSFixMe).isTarget) {
          children.push(objectToPush);
        }

        await putAPIWithRethrow(`/v2/dfs-run-config-groups/${dfsGroupFromBE.id}`, {
          id: dfsGroupFromBE.id,
          displayName: dfsGroupFromBE.displayName,
          runConfigs: dfsGroupFromBE.runConfigs.map((runConfig: $TSFixMe) => runConfig.id),
          parents,
          children
        });

        // const edgeType = "smart";
        // const markerEnd = { type: "arrowclosed" };
        // let edgesToSet = null;

        // setEdges((edges) => {
        //   edgesToSet = addEdge(
        //     { id: Date.now(), source, target, type: edgeType, markerEnd },
        //     edges
        //   );
        //   return edgesToSet;
        // });

        // setCanvasEdges(edgesToSet);
        setReloadTriggerStore();
      } catch (e) {
        toastWrapper({
          type: "error",
          content: "Edge creation failed"
        });
      }
    },
    [nodes, setReloadTriggerStore]
  );

  return { onConnect };
};

export default useConnect;
