/* eslint-disable no-unused-vars */
import { useQuery } from "@tanstack/react-query";

import { getRecipesByProjectId } from "src/api";
import { Recipe } from "src/types";

export const useGetTransformGroups = ({
  projectId,
  enabled,
  onSuccess
}: {
  projectId?: string;
  enabled?: boolean;
  onSuccess?: (groups: Array<Recipe>) => void;
}) => {
  return useQuery<Array<Recipe>>({
    queryKey: ["project", projectId, "recipes"],
    queryFn: () => {
      return getRecipesByProjectId(projectId);
    },
    onSuccess,
    enabled: !!projectId && enabled,
    cacheTime: 0
  });
};
