import { Breadcrumbs, Tooltip } from "@material-ui/core";
import React from "react";
import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";
import { RenderText } from "src/utils";
import { EnvironmentsHelperText } from "../utils/Environments.constants";

interface IProps {
  environmentName: string;
  onEditEnvironmentName: (name: string) => void;
  shouldResetName: number | boolean;
  readonly: boolean;
}

const FONT_SIZE = 14;

const EnvironmentHeaderBreadcrumb: React.FC<IProps> = (props) => {
  const { environmentName, onEditEnvironmentName, shouldResetName, readonly } = props;
  const breadcrumbs = readonly ? (
    <Tooltip title={EnvironmentsHelperText.NameInfo}>
      <span>
        <RenderText>{environmentName}</RenderText>
      </span>
    </Tooltip>
  ) : (
    <EditName
      key="edit-name"
      inputValue={environmentName}
      onSave={onEditEnvironmentName}
      isResetName={shouldResetName}
      containerProps={{
        style: {
          width: ((environmentName || "")?.length + 1) * (FONT_SIZE - 4) + 16
        }
      }}
    />
  );

  return <Breadcrumbs>{breadcrumbs}</Breadcrumbs>;
};

export default EnvironmentHeaderBreadcrumb;
