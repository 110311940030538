import React, { useMemo } from "react";
import _ from "lodash";
import { Card, FormControl, Grid, InputLabel, TextField } from "@material-ui/core";

import PreviewImageSelector from "src/pages/Projects/ProjectSettings/PreviewImageSelector";
import { CreateDataAppRequestDtoDataAppTypeEnum } from "openapi/Models/create-data-app-request-dto";
import { IRecipes } from "./CreateDataApp";
import { Select } from "src/components";
import { dataAppConfigFields } from "./CreateDataAppForm";
import { dataAppThumbNailImages } from "src/pages/DataApps/common/DataAppImages";
import { imageToBase64 } from "src/pages/Projects/helpers/projects.helpers";

interface IProps {
  values: any;
  type: CreateDataAppRequestDtoDataAppTypeEnum;
  recipes: IRecipes[];
  errorMsgs: {};
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CreateDataAppInputs: React.FC<IProps> = (props) => {
  const { recipes, values, type, errorMsgs, onChange } = props;

  const allRecipes = useMemo(
    () =>
      recipes.map((recipe) => ({
        label: recipe.displayName as string,
        value: recipe.id as string,
        disabled: !recipe.allowed,
        helpText: recipe.helpText
      })),
    [recipes]
  );

  const handleSetImageBase64 = (value: string) => {
    onChange({
      target: { value, name: dataAppConfigFields.imageBase64 }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleRecipeChange = (e: any) => {
    onChange({
      target: { value: e.target.value, name: dataAppConfigFields.recipeId }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <Card style={{ padding: "16px" }}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={"auto"}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "calc(100% - 200px)"
          }}>
          <TextField
            required
            data-testid="dataAppName"
            fullWidth
            size="small"
            name={dataAppConfigFields.dataAppName}
            value={_.get(values, dataAppConfigFields.dataAppName)}
            label="DataApp Name"
            variant="outlined"
            error={!!_.get(errorMsgs, dataAppConfigFields.dataAppName)}
            helperText={_.get(errorMsgs, dataAppConfigFields.dataAppName)}
            onChange={onChange}
          />
          <TextField
            fullWidth
            multiline
            maxRows={3}
            minRows={3}
            name={dataAppConfigFields.description}
            size="small"
            data-testid="dataAppDescription"
            value={_.get(values, dataAppConfigFields.description)}
            label="DataApp Description"
            variant="outlined"
            onChange={onChange}
          />
          {type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel && (
            <FormControl>
              <InputLabel shrink htmlFor={dataAppConfigFields.recipeId}>
                Recipe Name *
              </InputLabel>
              <Select
                required
                values={allRecipes}
                name={dataAppConfigFields.recipeId}
                variant="outlined"
                placeholder="Rapid Model Recipe not available"
                label="Recipe Name"
                value={_.get(values, dataAppConfigFields.recipeId, "")}
                onChange={handleRecipeChange}
              />
            </FormControl>
          )}
        </Grid>
        <Grid item xs={"auto"}>
          <PreviewImageSelector
            noText
            images={dataAppThumbNailImages}
            prefix="DataApp"
            onChange={(img: string) =>
              imageToBase64(img, (base64String: string) => handleSetImageBase64(base64String))
            }
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default CreateDataAppInputs;
