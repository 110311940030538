import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";
import useAddPredictionService from "pages/private/ProjectsModule/pages/PredictionService/hooks/useAddPredictionService";
import { DEFAULT_NAME, EnvironmentsTypes } from "src/constants/environments.constants";
import { DEFAULT_PRE_POST_PROCESS_CODE } from "pages/private/ProjectsModule/pages/PredictionService/utils/PredictionService.constants";
import { DataAppDto } from "openapi/Models/data-app-dto";
import { EnvDto } from "openapi/Models/env-dto";
import { QUERY_KEY_DATA_APPS } from "src/hooks/api/dataapps/useGetDataApps";
import { getFormattedPythonCode } from "pages/private/ProjectsModule/pages/PredictionService/utils/PredictionService.helpers";
import { CreateDataAppRequestDtoDataAppTypeEnum } from "openapi/Models/create-data-app-request-dto";
import { Config } from "openapi/Models";

type Props = {
  dataAppName: string;
  appTemplateId: string;
  projectId: string;
  recipeId?: string;
  description: string;
  metadata?: {
    [key: string]: string;
  };
  iconUrl?: string;
  dataAppType: CreateDataAppRequestDtoDataAppTypeEnum;
  dataappConfig?: Config;
  onSuccess: (response: DataAppDto) => void;
};

export const useCreateDataAppMutation = (): UseMutationResult<
  DataAppDto,
  unknown,
  Props,
  unknown
> => {
  const queryClient = useQueryClient();
  const addPredictionService = useAddPredictionService();

  return useMutation({
    mutationFn: async ({
      dataAppName,
      appTemplateId,
      projectId,
      recipeId,
      description,
      metadata,
      dataappConfig,
      dataAppType,
      onSuccess,
      iconUrl
    }) => {
      const response = await api.fetchResponse(
        async () =>
          await api.DataAppControllerApi.createDataApp({
            name: dataAppName,
            displayName: dataAppName,
            appTemplateId,
            envType: EnvironmentsTypes.Small,
            projectId,
            description,
            params: {},
            iconUrl,
            groupId: recipeId,
            metadata,
            dataappConfig,
            dataAppType
          })
      );

      await api.fetchResponse(
        async () => await api.DataAppControllerApi.launch(response.id as string),
        false
      );
      await queryClient.invalidateQueries([QUERY_KEY_DATA_APPS]);
      onSuccess(response);

      return response;
    },
    onSuccess: async (data: DataAppDto) => {
      // Default create Prediction Service
      const { modelName, predictionSvcName, shouldCreatePredictionSvc } =
        data?.predictionSvcMetadata ?? {};
      if (modelName && predictionSvcName && shouldCreatePredictionSvc) {
        const response = await api.fetchResponse(async () => await api.EnvControllerApi.findEnvs());
        const defaultEnv = response?.find(
          (env: EnvDto) => env?.name === DEFAULT_NAME && env?.defaultFlag
        );

        if (defaultEnv) {
          const formattedPythonCode = getFormattedPythonCode(
            DEFAULT_PRE_POST_PROCESS_CODE,
            predictionSvcName
          );
          const pythonFile = new File([formattedPythonCode], `${predictionSvcName}.py`, {
            type: "text/plain"
          });
          const payload = {
            modelName,
            name: predictionSvcName,
            displayName: predictionSvcName,
            envId: defaultEnv?.id,
            description: "",
            file: pythonFile,
            shouldDispatchEvent: false
          };
          addPredictionService.mutate(payload);
        }
      }
    }
  });
};
