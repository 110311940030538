import React from "react";

import { IProjectData } from "src/hooks/api";
import { IEntity } from "src/hooks/api/entities/useEntities";

type ContextTypes = {
  scenarioId?: string;

  isLoadingProject?: boolean;
  project?: IProjectData;

  isLoadingDataset?: boolean;
  dataset?: IEntity;

  reset: $TSFixMeFunction;
  navigateBack: $TSFixMeFunction;
  navigateBackWithoutRedirect: $TSFixMeFunction;
};

// @ts-ignore
export const DatasetContext = React.createContext<ContextTypes>();
