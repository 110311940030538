import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import api from "src/utils/AxiosClient";
import { AI_GUIDE_MESSAGES_KEY } from "./useGetAIGuideMessages";
import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";

interface IVariables {
  threadId: string;
  messageIds: string[];
}

export const useDeleteAIGuideMessages = (): UseMutationResult<
  unknown,
  unknown,
  IVariables,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ threadId, messageIds }) => {
      const promises = messageIds.map((messageId) =>
        api.fetchResponse(() => api.AiControllerApi.deleteMessage2(threadId, messageId))
      );

      const responses = await Promise.all(promises);

      queryClient.setQueryData(
        [AI_GUIDE_MESSAGES_KEY, threadId],
        (prevData: AIChatResponseDto[] | undefined) =>
          (prevData || []).filter((resp) => !messageIds.includes(resp.askAIMessageId!))
      );

      return responses;
    }
  });
};
