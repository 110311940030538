import { LimitsIcon } from "src/icons/LimitsIcon";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  MenuList,
  Radio,
  Typography,
  makeStyles
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import React, { useCallback, useState } from "react";
import { useCodeRecipeContext } from "../../../CodeRecipeContext/useCodeRecipeContext";

const useStyles = makeStyles({
  menuPaper: {
    border: "1px solid #C7C7C7",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    width: "320px"
  },
  menuList: {
    padding: "8px"
  },
  menuItemTitle: {
    color: "#008FE4",
    fontSize: "14px"
  },
  menuItemDesc: {
    color: "#000",
    opacity: 0.5,
    fontSize: "12px",
    fontStyle: "italic",
    whiteSpace: "normal"
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    gap: "2px",
    paddingLeft: "8px",
    paddingRight: "8px"
  },
  menuItemText: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    alignItems: "flex-start",
    "&:hover": {
      background: "#F2F9FF",
      borderRadius: "4px"
    }
  },
  btn: {
    border: "none !important",
    whiteSpace: "nowrap",
    color: "#4646B5 !important",
    background: "#fff",
    borderRadius: "4px"
  },
  btnEndIcon: {
    cursor: "pointer",
    border: "1px solid #008FE4",
    borderLeft: "0px",
    position: "absolute",
    right: "-16px",
    height: "105%",
    marginRight: "-5px",
    alignItems: "center",
    borderRadius: "0px 4px 4px 0px"
  },
  checkbox: {
    padding: "5px 10px",
    "&$checked": {
      color: "#008fe4"
    }
  }
});

export const GenerateQueryLimitsDropdown = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = useCallback((e: $TSFixMe) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { generateQueryLimit, setGenerateQueryLimit } = useCodeRecipeContext();
  const options = [
    {
      label: "Full Data",
      value: undefined,
      helpText: "By default ASK AI runs on full data. Response time can be higher"
    },
    {
      label: "100k",
      value: 100000,
      helpText: "This runs on sample data of 100k rows"
    },
    {
      label: "1 Million",
      value: 1000000,
      helpText: "This runs on sample data of 1 million rows of data"
    }
  ];

  const selectedLabel = options?.find((option) => option.value === generateQueryLimit)?.label;
  return (
    <>
      <Button
        startIcon={<LimitsIcon />}
        onClick={handleClick}
        className={classes.btn}
        size="small"
        variant="outlined">
        {selectedLabel}
      </Button>
      <Menu
        classes={{
          paper: classes.menuPaper,
          list: classes.menuList
        }}
        transitionDuration={{ exit: 0 }}
        onClose={handleClose}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}>
        <MenuList disablePadding>
          <Box px="8px" py="4px">
            <Typography className={classes.menuItemDesc}>Sample Selection</Typography>
          </Box>
          {options.map((option, index) => (
            <MenuItem
              key={index}
              className={classes.menuItem}
              test-id="generate-query-menu-item"
              onClick={() => {
                const isChecked = option.value === generateQueryLimit;
                setGenerateQueryLimit(isChecked ? undefined : option.value);
              }}>
              <Radio
                test-id="generate-query-check-btn"
                classes={{
                  root: classes.checkbox
                }}
                checked={option.value === generateQueryLimit}
                size="small"
              />
              <Grid container className={classes.menuItemText}>
                <Typography className={classes.menuItemTitle}>{option.label}</Typography>
                <Typography className={classes.menuItemDesc}>{option.helpText}</Typography>
              </Grid>
            </MenuItem>
          ))}
          <Box px="8px" pt="4px">
            <Grid container direction="row" spacing={2}>
              <Grid item xs={1}>
                <InfoIcon fontSize="small" style={{ color: "#9e9e9e" }} />
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.menuItemDesc}>
                  Change in the sample selection will only apply on new queries. This generate limit
                  is only applicable to main datasets
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </MenuList>
      </Menu>
    </>
  );
};
