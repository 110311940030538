import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import _, { capitalize } from "lodash";

// Icons
import IconButton from "@material-ui/core/IconButton";
import { PlusIcon } from "src/icons/NewUX/PlusIcon";

import { Spinner, Tabs, Tab, TabPanel } from "src/components";
import { deleteArtifact, deleteModal } from "src/api";
import ArtifactsTable from "./Artifacts/ArtifactsTable";
import styles from "./ArtifactsAndModels.module.scss";
import CreateArtifact from "./CreateArtifact";
import ModelsTable from "./Models/ModelsTable";
import { useUpdateEffect } from "src/hooks/useUpdateEffect";
import { handleResponse } from "src/utils/apiService";

// Components
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import Search from "src/components/custom/Search/Search";
import { useGetArtifacts, useGetModels } from "src/hooks/api";
import { Artifact, Model } from "src/types";
import { generatePath } from "react-router";
import { WebPaths } from "src/routing/routes";

// $FixMe: Scope to be refactored.
// The below stylings will be removed once the search-field is centralized.
const useStyles = makeStyles({
  wrapperContainer: {
    height: "100%"
  }
});

const ArtifactsAndModels = () => {
  const { entityName } = useParams<$TSFixMe>();
  const navigate = useNavigate();

  const classes = useStyles();

  const [value, setValue] = useState(entityName === "models" ? 2 : 1);
  const [models, setModels] = useState<Model[]>([]);
  const [artifacts, setArtifacts] = useState<Artifact[]>([]);
  const [selectedTab, setSelectedTab] = useState(entityName === "models" ? "models" : "artifacts");
  const [targetId, setTargetId] = useState("");
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [shouldArtifactsRefresh, setShouldArtifactsRefresh] = useState(false);
  const [shouldModalsRefresh, setShouldModalsRefresh] = useState(false);

  const {
    isSuccess,
    data: artifactsData,
    refetch: refetchArtifacts,
    isLoading: isArtifactsLoading
  } = useGetArtifacts(undefined, { keepPreviousData: true, refetchOnMount: true });

  const {
    data: modelsData,
    isSuccess: areModelsFetched,
    isLoading: isModelsLoading,
    refetch: refetchModels
  } = useGetModels(undefined, { keepPreviousData: true, refetchOnMount: true });

  useEffect(() => {
    if (isSuccess && artifactsData) {
      const sortedArtifacts =
        artifactsData?.map((artifact) => ({
          ...artifact,
          updated: getUpdateValue(artifact)
        })) || [];
      setArtifacts(sortedArtifacts);
    }
  }, [artifactsData, isSuccess]);

  useEffect(() => {
    if (areModelsFetched && modelsData) {
      setModels(modelsData);
    }
  }, [areModelsFetched, modelsData]);

  const handleTabChange = useCallback(
    (newValue: number) => {
      if (value === newValue) return;
      setValue(newValue);
      const tab = ["artifacts", "models"][newValue - 1];
      setSelectedTab(["artifacts", "models"][newValue - 1]);
      navigate(generatePath(WebPaths.ArtifactsAndModels, { entityName: tab }), { replace: true });
    },
    [value]
  );

  const toggleShouldArtifactsRefresh = () => {
    setShouldArtifactsRefresh((refresh) => !refresh);
  };

  const toggleShouldModalsRefresh = () => {
    setShouldModalsRefresh((refresh) => !refresh);
  };

  const handleCloseModal = () => {
    selectedTab === "artifacts" ? handleArtifactDelete() : handleModalDelete();
    setShowConfirmScreen(false);
  };

  const handleArtifactDelete = async () => {
    try {
      await deleteArtifact(targetId);
      toggleShouldArtifactsRefresh();
      handleResponse({ successMessage: `Artifact - ${targetId} deleted successfully` });
      // eslint-disable-next-line no-empty
    } catch (e: $TSFixMe) {}
  };

  const handleModalDelete = async () => {
    try {
      await deleteModal(targetId);
      toggleShouldModalsRefresh();
      handleResponse({ successMessage: `Model - ${targetId} deleted successfully` });
      // eslint-disable-next-line no-empty
    } catch (e: $TSFixMe) {}
  };

  const handleOpenCreationModal = () => {
    setShowCreateModal(true);
  };

  const handleClose = () => {
    setShowCreateModal(false);
  };

  const handleCancelClose = () => {
    setTargetId("");
    setShowConfirmScreen(false);
  };

  const handleDeleteProcessV2 = (id: string) => {
    setTargetId(id);
    setShowConfirmScreen(true);
  };

  const onSearch = ({ target: { value } }: $TSFixMe) => {
    setSearchValue(value);
  };

  const parsedList = useMemo(() => {
    if (selectedTab === "artifacts") {
      return _.filter(artifacts, ({ name }) =>
        _.includes(_.lowerCase(name), _.lowerCase(searchValue))
      );
    }

    return _.filter(
      models,
      ({ name, predictionServiceDetails }) =>
        _.includes(_.lowerCase(name), _.lowerCase(searchValue)) ||
        _.includes(_.lowerCase(predictionServiceDetails?.displayName), _.lowerCase(searchValue))
    );
  }, [searchValue, artifacts, selectedTab, models]);

  const getUpdateValue = (artifact: $TSFixMe) =>
    artifact?.fileObjects?.reduce(
      (acc: number, item: $TSFixMe) => (item?.updated > acc ? item?.updated : acc),
      artifact.created
    );

  useUpdateEffect(() => {
    refetchArtifacts();
  }, [shouldArtifactsRefresh]);

  useUpdateEffect(() => {
    refetchModels();
  }, [shouldModalsRefresh]);

  const tabName = useMemo(
    () => (selectedTab === "artifacts" ? "artifact" : "model"),
    [selectedTab]
  );

  return (
    <Grid container className={classes.wrapperContainer}>
      {showConfirmScreen && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title={`Delete ${capitalize(tabName)}`}
          content={[
            `If you confirm, you will delete the ${tabName} completely`,
            `Do you really want to delete this ${tabName}?`,
            <Box fontStyle="italic" key="content">
              Note: Deleting this might impact associated DataApps (if any).
            </Box>
          ]}
          onClose={handleCancelClose}
          onSubmit={handleCloseModal}
        />
      )}
      <SubTopNavBarWrapper
        subTopNavBarRightSection={{
          component: (
            <>
              <Search onSearch={onSearch} placeholder={`Search ${selectedTab}`} />
              {selectedTab === "artifacts" && (
                <IconButton color="primary" size="small" onClick={handleOpenCreationModal}>
                  <PlusIcon width={28} height={28} />
                </IconButton>
              )}
            </>
          )
        }}
      />

      <Grid item xs={12} className={styles.container}>
        {showCreateModal && (
          <CreateArtifact
            open={true}
            onClose={handleClose}
            artifactList={artifacts}
            toggleShouldArtifactsRefresh={toggleShouldArtifactsRefresh}
          />
        )}
        <div className={styles.tabsSection}>
          <div className={styles.tabsContainer}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              variant="scrollable">
              <Tab value={1} label="Artifacts" />
              <Tab value={2} label="Models" />
            </Tabs>
          </div>
          <div className={styles.tabPanelContainer}>
            <TabPanel value={value} index={1}>
              {isArtifactsLoading ? (
                <Spinner />
              ) : (
                <ArtifactsTable
                  data={parsedList}
                  onDelete={handleDeleteProcessV2}
                  openCreationModal={handleOpenCreationModal}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {isModelsLoading ? (
                <Spinner />
              ) : (
                <ModelsTable data={parsedList} onDelete={handleDeleteProcessV2} />
              )}
            </TabPanel>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ArtifactsAndModels;
