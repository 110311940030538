import React, { useMemo, useState } from "react";

// Packages
import clsx from "clsx";
import shallow from "zustand/shallow";
import { get, has, includes } from "lodash";

// MUI
import { Typography } from "@material-ui/core";

// Utils
import { addEllipsis, handleClickClosure } from "src/helpers/helpers";
import { RecipeStatuses, NodeRecipeTheme } from "src/pages/private/ProjectsModule/utils";

// Stores
import { useCanvasStore } from "src/store/store";

// Hooks
import useAttributes from "./useAttributes";
import useActions from "./useActions";
import useContextMenu from "../useContextMenu";

// Components
import NodesWrapper from "../NodesWrapper";
import ContextMenuWrapper from "../ContextMenuWrapper";
import ContextMenu from "./ContextMenu";
import NodeActionWrapper from "../NodeActionWrapper";
import ExpandCollapseIconButton from "../ExpandCollapseIconButton";

// Contexts
import { useDagFlowContext } from "../../DagFlow/context/useDagFlowContext";

// Types
import { NodeData } from "src/types";

// Styles
import useStyles from "./Recipe.styles";
import NodeCaptionOverflowTooltip from "../NodeCaptionOverflowTooltip";

type Props = {
  data: NodeData;
};

const Recipe = (props: Props) => {
  const { data } = props || {};

  const classes: $TSFixMe = useStyles({ status: data?.status });

  // Contexts
  const { scenario, isNodeHighlighted, nodesExpanded } = useDagFlowContext();

  // Stores - STARTS >>
  const [
    nodeToFocusStore,
    shouldBlockClickHandlerTriggerStore,
    setIsRunRequestPending,
    setReloadTriggerWithDelay
  ] = useCanvasStore(
    (state: $TSFixMe) => [
      state.nodeToFocus,
      state.shouldBlockClickHandlerTrigger,
      state.setIsRunRequestPending,
      state.setReloadTriggerWithDelay
    ],
    shallow
  );
  // << ENDS - Stores

  // States - STARTS >>
  const [isHovered, setIsHovered] = useState(false);
  // << ENDS - States

  const disabledMessage = useMemo(() => {
    if (
      !!data?.isJobCanvas &&
      includes([RecipeStatuses.UnBuilt, RecipeStatuses.Empty, RecipeStatuses.Skipped], data?.status)
    ) {
      return `Certain click actions are restricted as supplementary details for the specified recipe ${data?.label} are not accessible in the context of this job run entry.`;
    }

    return "";
  }, [data?.isJobCanvas, data?.status, data?.label]);

  // Keeping hooks at the bottom so as to pass the above defined props to it.
  // Hooks - STARTS >>
  const { onClick, onDoubleClick } = useActions({ data, disabledMessage });

  const { icon, status } = useAttributes({ data });

  const {
    contextMenuAnchorEl,
    // Mouse-leave action is not fully supported. Hence, deferring mouse-enter action.
    // actionsOnMouseEnter,
    closeContextMenu,
    contextMenuProp
  } = useContextMenu({
    data,
    disabledMessage
  });
  // << ENDS - Hooks

  return (
    <>
      <ContextMenuWrapper
        id={`recipeContextMenu-${data?.id}`}
        contextMenuAnchorEl={contextMenuAnchorEl}
        closeContextMenu={closeContextMenu}
        keepMounted>
        <ContextMenu
          open={Boolean(contextMenuAnchorEl)}
          closeContextMenu={closeContextMenu}
          isDefaultScenario={!!scenario?.default}
          setIsRunRequestPending={setIsRunRequestPending}
          setReloadTriggerWithDelay={setReloadTriggerWithDelay}
          data={data}>
          {!!data?.id && !!data?.isSourceNode && (
            <NodeActionWrapper title={nodesExpanded[data?.id] ?? true ? "Collapse" : "Expand"}>
              <ExpandCollapseIconButton nodeId={data?.id} />
            </NodeActionWrapper>
          )}
        </ContextMenu>
      </ContextMenuWrapper>

      <NodesWrapper
        nodeId={data?.id}
        disabledMessage={disabledMessage}
        isSourceNode={data?.isSourceNode}
        isTargetNode={data?.isTargetNode}
        backgroundColor={
          has(NodeRecipeTheme, data?.status)
            ? get(NodeRecipeTheme, [data?.status, "backgroundColor"])
            : NodeRecipeTheme.backgroundColor
        }
        isHovered={isHovered}
        setIsHovered={setIsHovered}>
        <div
          role="button"
          tabIndex={0}
          onMouseEnter={() => setIsHovered(() => true)}
          onMouseLeave={() => setIsHovered(() => false)}
          onClick={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTriggerStore || data?.shouldDisableBlockInteraction,
            handleDoubleClick: onDoubleClick,
            handleSingleClick: onClick
          })}
          onKeyPress={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTriggerStore || data?.shouldDisableBlockInteraction,
            handleDoubleClick: onDoubleClick,
            handleSingleClick: onClick
          })}
          {...contextMenuProp}
          className={clsx(classes.root, classes[data?.status], {
            highlighted:
              isNodeHighlighted(data?.label, nodeToFocusStore) || Boolean(contextMenuAnchorEl)
          })}>
          <div className={classes.container}>
            <Typography variant="caption" className={clsx(classes.status, "status")}>
              {status}
            </Typography>
            <div className={classes.icon}>{icon}</div>
            {data?.label && (
              <NodeCaptionOverflowTooltip label={data?.label}>
                <Typography variant="caption" className={classes.label}>
                  {addEllipsis(data?.label)}
                </Typography>
              </NodeCaptionOverflowTooltip>
            )}
          </div>
        </div>
      </NodesWrapper>
    </>
  );
};

export default Recipe;
