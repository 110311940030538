/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { GroupTransformLogDto } from './group-transform-log-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ScenarioRunStepResult } from './scenario-run-step-result';

/**
 * 
 * @export
 * @interface GroupLogDto
 */
export interface GroupLogDto {
    /**
     * 
     * @type {string}
     * @memberof GroupLogDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupLogDto
     */
    'status'?: GroupLogDtoStatusEnum;
    /**
     * 
     * @type {Array<GroupTransformLogDto>}
     * @memberof GroupLogDto
     */
    'transformLogs'?: Array<GroupTransformLogDto>;
    /**
     * 
     * @type {Array<ScenarioRunStepResult>}
     * @memberof GroupLogDto
     */
    'results'?: Array<ScenarioRunStepResult>;
    /**
     * 
     * @type {string}
     * @memberof GroupLogDto
     */
    'envId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupLogDto
     */
    'envStatus'?: GroupLogDtoEnvStatusEnum;
}

export const GroupLogDtoStatusEnum = {
    Pending: 'PENDING',
    Built: 'BUILT',
    Empty: 'EMPTY',
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Success: 'SUCCESS',
    Error: 'ERROR',
    Unbuilt: 'UNBUILT',
    Skipped: 'SKIPPED',
    Building: 'BUILDING'
} as const;

export type GroupLogDtoStatusEnum = typeof GroupLogDtoStatusEnum[keyof typeof GroupLogDtoStatusEnum];
export const GroupLogDtoEnvStatusEnum = {
    Success: 'SUCCESS',
    Launching: 'LAUNCHING',
    Shutdown: 'SHUTDOWN',
    Failed: 'FAILED',
    Unknown: 'UNKNOWN'
} as const;

export type GroupLogDtoEnvStatusEnum = typeof GroupLogDtoEnvStatusEnum[keyof typeof GroupLogDtoEnvStatusEnum];


