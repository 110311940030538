import React, { useState } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";

import { AIGuideChatBox } from "./AIGuideChatBox";
import Chart from "./Chart";
import { AskAICodeEditor } from "../../common/AskAICodeEditor";
import { AIGuideOutputWrap } from "./AIGuideOutputWrap";
import { ChartIconV3 } from "src/icons/NewUX/ChartIconV3";
import { SampleRows } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/SampleRows";

type ChartDto = {
  name: string;
  dataFilePath: string;
  chartType: string;
};

type ExtractedChartDto = {
  plotlyJson: string;
  name: string;
};

type IProps = {
  title: string;
  isCollapsed: boolean;
  isGenerating?: boolean;
  chartList: (ChartDto | ExtractedChartDto)[];
  onToggle?: () => void;
  rightContainer?: React.ReactNode;
  shouldShowCode?: boolean;
  code?: string;
  isAddedToRecipe?: boolean;
  errDetails?: any;
  icon?: React.ReactNode;
  sampleRows?: number;
};

const useStyles = makeStyles({
  chartsWrap: {
    overflow: "auto",
    display: "none"
  },
  selectedChart: {
    display: "block"
  },
  icon: {
    height: "32px",
    width: "24px !important"
  },
  chartWrap: {
    margin: "5px 12px 5px 12px",
    overflowY: "auto",
    borderRadius: "2px"
  }
});

export const GroupedCharts = ({
  title,
  isCollapsed,
  isGenerating,
  chartList,
  onToggle: onToggleProp,
  rightContainer,
  shouldShowCode,
  code,
  isAddedToRecipe,
  errDetails,
  icon,
  sampleRows
}: IProps) => {
  const classes = useStyles();
  const [shouldFetchData, setShouldFetchData] = useState(!isCollapsed);

  const onToggle = ({ onSuccess }: { onSuccess: () => void }) => {
    setShouldFetchData((fd) => !fd);
    onSuccess?.();
    onToggleProp?.();
  };
  return (
    <AIGuideChatBox
      isCollapsed={isCollapsed}
      isExpandDisabled={false}
      isGenerating={isGenerating}
      onToggle={onToggle}
      rightContainer={rightContainer}
      isAddedToRecipe={isAddedToRecipe}
      icon={
        icon || (
          <Grid
            container
            item
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
            wrap="nowrap"
            className={classes.icon}>
            <ChartIconV3 />
          </Grid>
        )
      }
      title={title}>
      {shouldShowCode && code ? (
        <AskAICodeEditor editorValue={code} errDetails={errDetails} />
      ) : (
        <Grid container direction="column" wrap="nowrap">
          <MemoizedCharts chartList={chartList} shouldFetchData={shouldFetchData} />
          {sampleRows && (
            <Box pt="8px" style={{ backgroundColor: "#fff" }}>
              <SampleRows sampleRows={sampleRows} />
            </Box>
          )}
        </Grid>
      )}
    </AIGuideChatBox>
  );
};

const Charts = ({
  chartList,
  shouldFetchData
}: {
  chartList: (ChartDto | ExtractedChartDto)[];
  shouldFetchData: boolean;
}) => {
  const classes = useStyles();
  const [value, handleChange] = useState<number>(0);
  const selectedChart = chartList?.[value];

  if (chartList?.length === 1) {
    const chart: any = chartList?.[0];
    return (
      <Box className={classes.chartWrap}>
        <Chart
          plotlyJson={chart.plotlyJson}
          name={chart.name}
          dataFilePath={chart.dataFilePath}
          chartType={chart.chartType}
          shouldFetchData={shouldFetchData}
        />
      </Box>
    );
  }
  return (
    <AIGuideOutputWrap tabNames={chartList} value={value} handleChange={handleChange}>
      {chartList.map((chart: any) => (
        <Box
          height="100%"
          key={chart.name}
          className={clsx(classes.chartsWrap, {
            [classes.selectedChart]: selectedChart.name === chart.name
          })}>
          <Chart
            plotlyJson={chart.plotlyJson}
            name={chart.name}
            dataFilePath={chart.dataFilePath}
            chartType={chart.chartType}
            shouldFetchData={shouldFetchData}
          />
        </Box>
      ))}
    </AIGuideOutputWrap>
  );
};

const MemoizedCharts = React.memo(Charts);
