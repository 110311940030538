import React from "react";
import { map } from "lodash";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";

import Spinner from "components/Spinner";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import useDataAppStatus from "hooks/api/dataapps/useDataAppStatus";
import { isInProgress } from "hooks/api/dataapps/useDataAppStatusById";
import { useGetDataAppLogs } from "hooks/api/dataapps/useGetDataAppLogs";
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";

export const useStyles = makeStyles({
  logsContainer: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    padding: 16
  }
});

const DataAppRunLogs: React.FC = () => {
  const { id, dataAppName } = useParams();
  const classes = useStyles();

  // Project context
  const { project } = useProjectContext() || {};

  const { status } = useDataAppStatus(id as string);

  const { isLoading, data: displayedLogs } = useGetDataAppLogs({
    dataAppId: id,
    mode: status && isInProgress(status) ? "live" : "offline",
    enabled: !!id
  });

  return (
    <>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          backNavPath: project?.id
            ? `projects/${project?.id}/project-dataapps/${dataAppName}`
            : `/dataapps-ui/${dataAppName}`,

          component: <SubTopNavBarBreadcrumbs project={project} dataAppName={dataAppName} />
        }}
      />

      <Grid container justifyContent="center">
        {isLoading ? (
          <Spinner size={24} />
        ) : (
          <Grid container className={classes.logsContainer}>
            <Grid container item>
              <Paper
                style={{
                  width: "100%",
                  padding: "20px 25px",
                  backgroundColor: "#1e1e1e",
                  fontFamily: `Menlo, Monaco, "Courier New", monospace`,
                  color: "#d4d4d4",
                  fontSize: 12,
                  overflow: "auto"
                }}>
                <ul style={{ listStyle: "none", wordBreak: "break-word" }}>
                  {map(displayedLogs, (log: $TSFixMe, index: $TSFixMe) => {
                    return <li key={`log_${index}`}>{log}</li>;
                  })}
                </ul>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DataAppRunLogs;
