import React from "react";
import {
  FilterNone as FilterNoneIcon,
  LibraryAddCheckOutlined as LibraryAddCheckOutlinedIcon
} from "@material-ui/icons";

import useCopyClipboard from "src/hooks/useCopyClipboard";
import { Button } from "@material-ui/core";

export const CopyTextButton = ({ text, ...restProps }: { text: string }) => {
  const { handleCopyClick, isCopied } = useCopyClipboard(text);
  return (
    <Button
      variant="contained"
      size="small"
      color="primary"
      onClick={handleCopyClick}
      startIcon={
        isCopied ? (
          <LibraryAddCheckOutlinedIcon fontSize="small" />
        ) : (
          <FilterNoneIcon fontSize="small" />
        )
      }
      {...restProps}>
      copy
    </Button>
  );
};
