import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useLocation, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";

import { Tabs, Tab } from "src/components";

import PredictionService from "pages/private/ProjectsModule/pages/PredictionService/components/PredictionService";
import styles from "../ArtifactsAndModels.module.scss";
import usePredictionServiceByModel from "pages/private/ProjectsModule/pages/PredictionService/hooks/usePredictionServiceByModel";
import { ModelTable } from "./ModelTable";
import { Spinner, TabPanel } from "src/components";
import { useGetJob, useGetJobRun } from "src/hooks/api";
import { useJobsContext } from "src/pages/private/ProjectsModule/pages/Jobs/context/useJobsContext";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

export const TABS = {
  files: {
    value: "files",
    label: "Files"
  },
  predictionService: {
    value: "prediction-service",
    label: "Prediction Service"
  }
};
const ModelDetails = () => {
  const { modelName, projectId, scenarioId, jobId, jobRunId } = useParams<$TSFixMe>();
  const { pathname, state } = useLocation();

  // Project context
  const { project } = useProjectContext() || {};

  // Jobs context
  const { jobPaths, jobPathsSetter } = useJobsContext() || {};

  const isJobPath = useMemo(() => /jobs/.test(pathname), [pathname]);

  // Query hooks - STARTS >>
  const { data: jobData } = useGetJob({ projectId, jobId });
  const { data: jobRunData } = useGetJobRun({ jobRunId, isApiWithRethrow: false });
  // << ENDS - Query hooks

  const [tab, setTab] = useState(TABS.files.value);
  const [open, setOpen] = useState(false);

  const predictionServiceResults = usePredictionServiceByModel(modelName, scenarioId, jobRunId);
  const { data, isLoading, isFetching } = predictionServiceResults;

  useEffect(() => {
    if (_.get(state, "tab") === TABS.predictionService.value) {
      setTab(TABS.predictionService.value);
    }
  }, []);

  const handleChange = (newValue: string) => {
    setTab(newValue);
  };

  const handleViewChange = (__: React.MouseEvent<HTMLElement>, nextView: boolean) => {
    setOpen(nextView);
  };

  return (
    <>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          ...(isJobPath
            ? {
                backNavAction: () => {
                  jobPathsSetter(jobPaths.slice(0, -1));
                }
              }
            : {
                backNavPath: state?.prevPath || `/artifacts-and-models/models`
              }),
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              isJobPath={isJobPath}
              jobData={jobData}
              jobRunId={jobRunId}
              jobRunName={jobRunData?.entryDto?.runId}
              modelName={modelName}
            />
          )
        }}
      />

      <NewThemeWrapper>
        <Grid item xs={12} className={`${styles.container} container-height`}>
          <div className={styles.tabsSection}>
            {isLoading || isFetching ? (
              <Spinner />
            ) : (
              <>
                <Grid container alignItems="center">
                  <Tabs
                    value={tab}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                    onChange={handleChange}>
                    <Tab value={TABS.files.value} label={TABS.files.label} />
                    {!isJobPath && (
                      <Tab
                        value={TABS.predictionService.value}
                        label={TABS.predictionService.label}
                      />
                    )}
                  </Tabs>
                  {tab === TABS.predictionService.value && data?.predictionServiceDetails?.id && (
                    <span style={{ marginLeft: "auto" }}>
                      <ToggleButtonGroup
                        size="small"
                        orientation="horizontal"
                        value={open}
                        exclusive
                        onChange={handleViewChange}>
                        <ToggleButton
                          data-testid="predictoinServiceTestButton"
                          disabled={!open}
                          style={{ padding: "5px", textTransform: "none" }}
                          value={false}>
                          <span> Test</span>
                        </ToggleButton>
                        <ToggleButton
                          data-testid="predictoinServiceLogButton"
                          disabled={open}
                          style={{ padding: "5px" }}
                          value={true}>
                          <LogsNewIconV2 width="22" height="22" color={"#4646b5"} />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </span>
                  )}
                </Grid>
                <div className={styles.tabPanelContainer}>
                  <TabPanel key={TABS.files.value} value={tab} index={TABS.files.value}>
                    <ModelTable modelName={modelName!} data={data} />
                  </TabPanel>
                  {!isJobPath && (
                    <TabPanel
                      key={TABS.predictionService.value}
                      value={tab}
                      index={TABS.predictionService.value}>
                      <PredictionService
                        modelName={modelName}
                        data={data}
                        open={open}
                        setOpen={setOpen}
                      />
                    </TabPanel>
                  )}
                </div>
              </>
            )}
          </div>
        </Grid>
      </NewThemeWrapper>
    </>
  );
};

export default ModelDetails;
