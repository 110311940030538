import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

// MUI
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

// Icons
import { CanvasIcon } from "src/icons/NewUX/CanvasIcon";
import { RunIcon } from "src/icons/NewUX/RunIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

import { deleteAPIWithRethrow, postAPIWithRethrow } from "../../../utils/apiService";
import { checkEnvRelaunch } from "../../../utils/envRelaunchNotification";
import { useScenariosStore, useCanvasStore } from "../../../store/store";
import shallow from "zustand/shallow";

import { ScenarioDeletePromptDetails } from "../helpers/scenarios.helpers";
import { toastWrapper } from "src/utils/toastWrapper";

// Components
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { WebPaths } from "src/routing/routes";

const ScenariosHeader = ({ scenarioData, isEditMode, handleUpdateName, shouldReset }: $TSFixMe) => {
  const { id, projectId } = useParams<$TSFixMe>();
  const navigate = useNavigate();

  // Project context
  const { project } = useProjectContext() || {};

  const [scenarioName, setScenarioName] = useState(scenarioData?.name);
  const [isScenarioRunning, setIsScenarioRunning] = useState(false);

  const [showConfirmScreen, setShowConfirmScreen] = useState(false);

  const [triggerScenariosReload, setIsScenariosLoading, scenarios, setScenarios] =
    useScenariosStore(
      useCallback(
        (state) => [
          state.triggerScenariosReload,
          state.setIsScenariosLoading,
          state.scenarios,
          state.setScenarios
        ],
        []
      ),
      shallow
    );

  const nodes = useCanvasStore((state: $TSFixMe) => state?.nodes);
  const dfsGroupNodes =
    useMemo(() => {
      return nodes?.filter((node: $TSFixMe) => node?.type === "DFSGROUP");
    }, [nodes]) || [];

  const deleteScenario = useCallback(async () => {
    try {
      // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
      await deleteAPIWithRethrow(`/v2/scenarios/${scenarioData.id}`);
      setScenarios(scenarios.filter((curr: any) => curr.id !== scenarioData.id));
      toastWrapper({
        type: "success",
        content: "Scenario deleted successfully!"
      });

      if (projectId) {
        navigate(-1);
      }
    } catch (e) {
      console.error(e);
    }
  }, [scenarioData, setScenarios, scenarios]);

  const promptConfirmDeleteScenario = () => {
    setShowConfirmScreen(true);
  };

  const cancelDeleteScenario = () => {
    setShowConfirmScreen(false);
  };

  const confirmDeleteScenario = () => {
    deleteScenario();
    setShowConfirmScreen(false);
  };

  const viewOnCanvas = useCallback(async () => {
    if (projectId) {
      const path = generatePath(`${WebPaths.Dag}${WebPaths.Canvas}`, {
        projectId,
        scenarioId: scenarioData.id
      });
      navigate(path);
    }
  }, [history, projectId, scenarioData]);

  const onRunScenario = useCallback(async () => {
    projectId && checkEnvRelaunch(projectId);

    setIsScenariosLoading(true);
    setIsScenarioRunning(true);
    try {
      // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 1.
      await postAPIWithRethrow(`/v2/projects/run/${projectId}?scenarioId=${scenarioData.id}`);
      triggerScenariosReload();
    } catch (e) {
      console.error(e);
      // toastWrapper({
      //   type: "error",
      //   content: "Scenario run failed"
      // });
    }
    setIsScenarioRunning(false);
    setIsScenariosLoading(false);
  }, [projectId, scenarioData, setIsScenariosLoading, triggerScenariosReload]);

  useEffect(() => {
    setScenarioName(scenarioData?.name);
  }, [scenarioData]);

  return (
    <>
      {showConfirmScreen && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title="Delete Scenario"
          content={[ScenarioDeletePromptDetails.title, ScenarioDeletePromptDetails.message]}
          onClose={cancelDeleteScenario}
          onSubmit={confirmDeleteScenario}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          backNavPath: `/scenarios/${id || projectId}`,
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              scenarioName={scenarioName}
              updateScenarioName={handleUpdateName}
              shouldResetScenarioName={shouldReset}
            />
          )
        }}
        {...(isEditMode
          ? {
              subTopNavBarRightSection: {
                moreOptions: [
                  {
                    label: "Delete",
                    icon: <TrashIcon viewBox="0 0 20 20" />,
                    action: promptConfirmDeleteScenario
                  }
                ],
                component: (
                  <>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={onRunScenario}
                      disabled={dfsGroupNodes?.length === 0 || !!isScenarioRunning}
                      startIcon={isScenarioRunning ? <CircularProgress size={18} /> : <RunIcon />}>
                      Run
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={viewOnCanvas}
                      startIcon={<CanvasIcon width={14} height={14} viewBox="0 0 16 12" />}>
                      Canvas View
                    </Button>
                  </>
                )
              }
            }
          : {})}
      />
    </>
  );
};

export default ScenariosHeader;
