/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from "react";
import clsx from "clsx";
import {
  MenuItem,
  Box,
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
  Divider,
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  withStyles,
  Button
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { OverflowTooltip } from "src/components";
import _ from "lodash";
import useNotificationStore from "src/stores/notification.store";
import shallow from "zustand/shallow";
import { useProjectsStore } from "src/store/store";
import { shouldRefreshProjectsSetter } from "src/store/store.selectors";
import { useAuthSetAuthResponse } from "src/hooks/useAuthSetAuthResponse";
import useJoinNewTenant from "src/hooks/api/useJoinTenant";
import { logNotebooksOut } from "src/utils/operateNotebookService";
import useTenantsStore from "src/stores/tenant-management.store";
import { WebPaths } from "src/routing/routes";

const useStyles = makeStyles({
  subTitle: {
    fontSize: "12px"
  },
  selectedMenuItem: {
    backgroundColor: "#E6F5FF"
  },
  menuItem: {
    borderBottom: "1px solid #cccccc",

    "&:hover": {
      background: "rgba(230, 245, 255, .6)"
    },

    "&:last-of-type": {
      borderBottom: "none !important"
    },
    width: "inherit"
  },
  invitedTenant: {
    opacity: "1 !important",
    "&:hover": {
      background: "#ffff !important"
    }
  },
  invitedTenantAccordationDetails: {
    padding: "0px  !important"
  },

  invalidLicenseTenant: {
    paddingLeft: "6px"
  },
  tenantNameWrap: {
    flexWrap: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    gap: 16
  },
  tenantListItems: {
    maxHeight: "290px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#003656",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },
  menuHeader: {
    paddingLeft: "10px",
    paddingRight: "8px"
  },
  invalidLicenseHeader: {
    color: "#A3A3A3",
    fontWeight: 400,
    fontSize: "12px"
  },
  expanded: {
    margin: "0px !important"
  },
  acceptButton: {
    fontSize: 12,
    lineHeight: "17px",
    pointerEvents: "auto",
    position: "relative",
    zIndex: 100,
    opacity: 1
  }
});

const Accordion = withStyles({
  root: {
    width: "inherit",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 48,
    "&$expanded": {
      minHeight: 48
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const TenantSettingsListItem = ({
  tenant,
  tenantId,
  onTenantSwitch,
  isLoading,
  tenantSwitchId,
  disabled,
  onJoinNewTenant,
  acceptInviteTenantId
}: {
  tenant: $TSFixMe;
  tenantId: string | null;
  onTenantSwitch: (tenantId: string) => void;
  isLoading?: boolean;
  tenantSwitchId: string | undefined;
  disabled?: boolean;
  onJoinNewTenant: (tenantId: string) => void;
  acceptInviteTenantId?: string;
}) => {
  const classes = useStyles();
  const isCurrentTenant = tenant?.id === tenantId;
  const testId = `tenant-menu-${tenant?.hasValidLicense ? "valid" : "invalid"}-license-list-item`;
  const isInvited = tenant?.invitationStatus === "PENDING";

  return (
    <MenuItem
      className={clsx([
        classes.menuItem,
        {
          [classes.selectedMenuItem]: isCurrentTenant,
          [classes.invalidLicenseTenant]: !tenant?.hasValidLicense,
          [classes.invitedTenant]: isInvited
        }
      ])}
      disabled={isLoading || disabled || isCurrentTenant}
      test-id={testId}
      onClick={(event) => {
        if (isInvited) {
          event.preventDefault();
        } else {
          onTenantSwitch(tenant?.id);
        }
      }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid xs={isInvited ? 9 : 10} item>
          <OverflowTooltip value={tenant?.name} title={`${tenant?.name}`} />
        </Grid>
        {isInvited && (
          <Grid xs={3} item>
            <Button
              size="small"
              color="primary"
              className={classes.acceptButton}
              variant="contained"
              disabled={isLoading}
              onClick={(event) => {
                event.stopPropagation();
                onJoinNewTenant(tenant?.id);
              }}>
              {isLoading &&
              (tenantSwitchId === tenant?.id || acceptInviteTenantId === tenant.id) ? (
                <CircularProgress size={20} />
              ) : (
                "Accept"
              )}
            </Button>
          </Grid>
        )}
        {!isInvited &&
          isLoading &&
          (tenantSwitchId === tenant?.id || acceptInviteTenantId === tenant.id) && (
            <Grid xs={2} item>
              <Box pl="6px">
                <CircularProgress size={20} />
              </Box>
            </Grid>
          )}
      </Grid>
    </MenuItem>
  );
};

export const TenantSettingsListItems = ({
  onClose,
  onTenantSwitch,
  userTenants,
  tenantId,
  isLoading,
  tenantSwitchId
}: {
  onClose: () => void;
  onTenantSwitch: (tenantId: string) => void;
  tenantId: string | null;
  userTenants: Array<$TSFixMe>;
  isLoading?: boolean;
  tenantSwitchId: string | undefined;
}) => {
  const classes = useStyles();
  const [setNotification] = useNotificationStore(
    (state: any) => [state.setNotification, state.notifications],
    shallow
  );
  const [acceptInviteTenantId, setAcceptInviteTenantId] = useState<string>();
  const joinTenant = useJoinNewTenant();
  const setShouldRefreshProjects = useProjectsStore(shouldRefreshProjectsSetter);
  const [toggleUserTenantsRefresh] = useTenantsStore(
    (state) => [state.toggleUserTenantsRefresh],
    shallow
  );
  const { setAuthResponse } = useAuthSetAuthResponse();

  const joinNewTenant = (tenantId: string) => {
    setAcceptInviteTenantId(tenantId);
    joinTenant.mutate(
      { tenantId: tenantId },
      {
        onSuccess: (data) => {
          if (data) {
            toggleUserTenantsRefresh();
            setNotification({
              type: "Dashboard",
              message: `You are added to "${data.tenantName}" tenant successfully`
            });
            setAuthResponse({
              data,
              rememberMe: true
            });
            setShouldRefreshProjects(true);
            logNotebooksOut();
            window.location.href = WebPaths.Dashboard;
            window.history.pushState(null, "", WebPaths.Dashboard);
            setAcceptInviteTenantId(undefined);
            onTenantSwitch(tenantId);
          }
        }
      }
    );
  };

  const { validLicenseTenants, validLicenseInvitedTenants, invalidLicenseTenants } = useMemo(() => {
    const valid: any[] = [];
    const invalid: any[] = [];
    const sorted = _.sortBy(userTenants, (tenant) => _.toUpper(tenant.name));

    _.forEach(sorted, (tenant) => {
      if (_.includes(["ACTIVE", "PENDING"], tenant?.invitationStatus)) {
        if (tenant?.hasValidLicense) {
          valid.push(tenant);
        } else {
          invalid.push(tenant);
        }
      }
    });

    return {
      validLicenseTenants: _.filter(valid, (item) => item?.invitationStatus === "ACTIVE"),
      validLicenseInvitedTenants: _.filter(valid, (item) => item?.invitationStatus === "PENDING"),
      invalidLicenseTenants: invalid
    };
  }, [userTenants]);

  return (
    <Box m="0px 10px">
      <MenuItem
        className={classes.menuHeader}
        onClick={onClose}
        test-id="tenant-menu-tenant-list-back-btn">
        <Grid container alignItems="center">
          <Box pr="6px" display="flex">
            <ChevronLeftIcon fontSize="small" />
          </Box>
          <Typography className={classes.subTitle}>TENANT SWITCH</Typography>
        </Grid>
      </MenuItem>
      <Grid className={classes.tenantListItems} test-id="tenant-menu-tenant-list-items">
        {validLicenseTenants?.map((tenant: $TSFixMe) => {
          return (
            <TenantSettingsListItem
              key={tenant?.id}
              tenant={tenant}
              tenantId={tenantId}
              tenantSwitchId={tenantSwitchId}
              onTenantSwitch={onTenantSwitch}
              onJoinNewTenant={joinNewTenant}
              acceptInviteTenantId={acceptInviteTenantId}
              isLoading={isLoading || joinTenant.isLoading}
            />
          );
        })}
        {validLicenseInvitedTenants?.length > 0 && (
          <div>
            <Divider light />
            <Accordion>
              <AccordionSummary
                style={{
                  background: "#f9f9f9"
                }}
                classes={{ expanded: classes.expanded }}
                expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.invalidLicenseHeader}>INVITED TENANTS</Typography>
              </AccordionSummary>
              <AccordionDetails
                className={`${classes.tenantListItems} ${classes.invitedTenantAccordationDetails}`}>
                <Grid container direction="column">
                  {validLicenseInvitedTenants?.map((tenant: $TSFixMe) => {
                    return (
                      <TenantSettingsListItem
                        key={tenant?.id}
                        tenant={tenant}
                        tenantId={tenantId}
                        tenantSwitchId={tenantSwitchId}
                        onTenantSwitch={onTenantSwitch}
                        isLoading={isLoading || joinTenant.isLoading}
                        onJoinNewTenant={joinNewTenant}
                        acceptInviteTenantId={acceptInviteTenantId}
                      />
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
        {invalidLicenseTenants?.length > 0 && (
          <div>
            <Divider light />
            <Accordion>
              <AccordionSummary
                classes={{ expanded: classes.expanded }}
                expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.invalidLicenseHeader}>
                  TENANTS WITH INVALID LICENSE
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="column">
                  {invalidLicenseTenants?.map((tenant: $TSFixMe) => {
                    return (
                      <TenantSettingsListItem
                        key={tenant?.id}
                        tenant={tenant}
                        tenantId={tenantId}
                        tenantSwitchId={tenantSwitchId}
                        onTenantSwitch={onTenantSwitch}
                        isLoading={isLoading || joinTenant.isLoading}
                        onJoinNewTenant={joinNewTenant}
                        acceptInviteTenantId={acceptInviteTenantId}
                      />
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </Grid>
    </Box>
  );
};
