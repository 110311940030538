import React from "react";

import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";

type Props = {
  id?: string;
  contextMenuAnchorEl: Element | null;
  closeContextMenu: $TSFixMeFunction;
  keepMounted?: boolean;
  children: React.ReactNode;
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -theme.spacing(2.5),
    boxShadow: "none",
    borderRadius: 0,
    backgroundColor: "transparent"
  },
  downArrow: {
    position: "relative",
    marginTop: theme.spacing(1),
    "&::after": {
      position: "absolute",
      display: "block",
      top: -theme.spacing(1.5),
      left: `calc(50% - ${theme.spacing(0.5)}px)`,
      width: theme.spacing(1),
      height: theme.spacing(1),
      content: '""',
      transform: "rotate(45deg)",
      backgroundColor: theme.palette.common.black
    }
  },
  container: {
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(0.75)
  }
}));

const ContextMenuWrapper = (props: Props) => {
  const {
    id = "nodeContextMenu",
    contextMenuAnchorEl,
    closeContextMenu,
    keepMounted = false,
    children
  } = props || {};

  const classes = useStyles();

  // onContextMenu of the popover background.
  const onContextMenu = (event: $TSFixMe) => {
    event.preventDefault(event);

    if (Boolean(contextMenuAnchorEl)) {
      closeContextMenu();
    }
  };

  return (
    <Popover
      id={id}
      open={Boolean(contextMenuAnchorEl)}
      anchorEl={contextMenuAnchorEl}
      onClose={closeContextMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      PaperProps={{
        className: classes.root
      }}
      onContextMenu={onContextMenu}
      keepMounted={keepMounted}>
      <div className={classes.container}>{children}</div>
      <Box className={classes.downArrow} />
    </Popover>
  );
};

export default ContextMenuWrapper;
