import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";

import ViewWeekRoundedIcon from "@material-ui/icons/ViewWeekRounded";

type Props = {
  onValueChange: (newValue: string) => void;
};

const useStyles = makeStyles((theme) => ({
  search: {
    alignItems: "center",
    columnGap: theme.spacing(1),
    fontSize: "small",
    "&:before, &:hover:before": {
      borderBottom: "none !important"
    }
  }
}));

const SearchColumns = (props: Props) => {
  const [value, setValue] = useState("");
  const { onValueChange } = props;

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onValueChange(event.target.value);
  };

  return (
    <TextField
      data-testid="searchColumns"
      size="small"
      fullWidth
      placeholder="Search columns..."
      InputProps={{
        className: classes.search,
        startAdornment: <ViewWeekRoundedIcon fontSize="small" color="action" />
      }}
      value={value}
      onChange={handleChange}
    />
  );
};

export default SearchColumns;
