import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { includes, map } from "lodash";

import OverflowTooltip from "src/components/OverflowTooltip";
import { DragHandle } from "src/icons/DragHandle";
import { IColumn } from "../TableSettingsMenu";

const useStyles = makeStyles({
  formControl: {
    width: "100%",
    minWidth: "300px",
    "& span[class*='MuiCheckbox-root']": {
      padding: "7px"
    }
  },
  header: {
    width: "100%",
    backgroundColor: "#fff"
  },
  legend: {
    padding: "8px 14px",
    color: "#7c7c7c"
  },
  list: {
    maxHeight: "50vh",
    maxWidth: "300px",
    overflowY: "auto",
    overflowX: "hidden"
  },
  listItem: {
    padding: "0 14px",
    "&:not(:first-child)": {
      borderTop: "1px solid #e1e1e1"
    },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& label[class*='MuiFormControlLabel-root']": {
      marginBottom: "0",
      width: "100%",
      overflow: "hidden",
      "& > span": {
        overflow: "hidden",
        whiteSpace: "nowrap"
      }
    }
  },
  search: {
    color: "#7c7c7c",
    minHeight: "34px",
    height: "100%",
    margin: "0px",
    width: "100%",
    borderTop: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "& fieldset": {
      border: "none"
    },
    "& div[class*='MuiInputBase-root']": {
      height: "34px",
      width: "100%",
      color: "#7c7c7c"
    },
    "& input[class*='MuiInputBase-input']": {
      height: "34px",
      fontSize: "14px",
      width: "100%"
    }
  },
  selectAllContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 14px",
    zIndex: 1,
    background: "#FFF",
    borderBottom: "1px solid #e1e1e1",
    "& label": {
      marginBottom: "0"
    }
  }
});

interface ColumnFilterMenuProps {
  columns: IColumn[];
  setColumns: Dispatch<SetStateAction<IColumn[]>>;
  onColumnsChange: (columns: IColumn[]) => void;
  onFormControlChange: (e: React.ChangeEvent<{ checked: boolean; value: string }>) => void;
}

const ColumnFilterMenu = ({
  columns,
  setColumns,
  onFormControlChange,
  onColumnsChange
}: ColumnFilterMenuProps) => {
  const classes = useStyles();
  const [filterValue, setFilterValue] = useState("");

  const handleSearch = (value: string) => {
    setFilterValue(value);
  };

  const reorder = (list: IColumn[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result?.splice(startIndex, 1);
    result?.splice(endIndex, 0, removed);
    return result;
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result?.destination) {
      return;
    }
    const items = reorder(columns, result?.source?.index, result?.destination?.index);
    onColumnsChange(items);
  };

  const filteredColumnList = useMemo(() => {
    if (!filterValue) {
      return columns || [];
    }
    return (
      columns?.filter(({ name }) => name?.toLowerCase().includes(filterValue.toLowerCase())) || []
    );
  }, [filterValue, columns]);

  const deselectAll = () => {
    const filteredColumnsNames = map(filteredColumnList, "name");

    const updatedColumns = map(columns, (column) => {
      if (includes(filteredColumnsNames, column?.name)) {
        return {
          ...column,
          checked: false
        };
      }
      return column;
    });

    setColumns(() => updatedColumns);
  };

  return (
    <div>
      <FormControl className={classes.formControl} component="fieldset">
        <div className={classes.header}>
          <Typography variant="body2" className={classes.legend} component="legend">
            Columns
          </Typography>
          <TextField
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              onKeyDown: (e) => e.stopPropagation(),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            data-testid="tableSettingsSearch"
            placeholder="Search column..."
            className={classes.search}
            id="column-search-input"
            name="column-search-input"
            variant="outlined"
          />
          <Box m={1}>
            <Button
              variant="outlined"
              data-testid="tableSettingsDeSelectAllButton"
              color="primary"
              size="small"
              onClick={deselectAll}>
              Deselect All
            </Button>
          </Box>
        </div>
        <FormGroup>
          <div className={classes.selectAllContainer}></div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided?.droppableProps}
                  ref={provided?.innerRef}
                  className={classes.list}>
                  {filteredColumnList?.map((column, index) => (
                    <Draggable key={column.name} draggableId={column.name} index={index}>
                      {(provided) => (
                        <div
                          ref={provided?.innerRef}
                          {...provided?.draggableProps}
                          className={classes.listItem}>
                          <FormControlLabel
                            value={column.name}
                            control={
                              <Checkbox size={"small"} checked={column.checked} color="primary" />
                            }
                            label={
                              <OverflowTooltip
                                value={<Typography variant="body2">{column.name}</Typography>}
                                title={column.name}
                                tooltipProps={{ placement: "left-start" }}
                              />
                            }
                            labelPlacement="end"
                            onChange={(e) =>
                              onFormControlChange(
                                e as unknown as React.ChangeEvent<{
                                  checked: boolean;
                                  value: string;
                                }>
                              )
                            }
                          />
                          <div {...provided?.dragHandleProps}>
                            <DragHandle />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default ColumnFilterMenu;
