/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CodeHistoryLikes } from './code-history-likes';
// May contain unused imports in some cases
// @ts-ignore
import { ErrDetails } from './err-details';
// May contain unused imports in some cases
// @ts-ignore
import { QueryInputDto } from './query-input-dto';
// May contain unused imports in some cases
// @ts-ignore
import { QueryOutputDto } from './query-output-dto';

/**
 * 
 * @export
 * @interface DataConversationDto
 */
export interface DataConversationDto {
    /**
     * 
     * @type {string}
     * @memberof DataConversationDto
     */
    'historyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataConversationDto
     */
    'conversationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataConversationDto
     */
    'conversationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataConversationDto
     */
    'groupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataConversationDto
     */
    'userInput'?: string;
    /**
     * 
     * @type {Array<QueryInputDto>}
     * @memberof DataConversationDto
     */
    'queryInputs'?: Array<QueryInputDto>;
    /**
     * 
     * @type {string}
     * @memberof DataConversationDto
     */
    'transformId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataConversationDto
     */
    'dataappId'?: string;
    /**
     * 
     * @type {Array<QueryOutputDto>}
     * @memberof DataConversationDto
     */
    'queryOutputs'?: Array<QueryOutputDto>;
    /**
     * 
     * @type {string}
     * @memberof DataConversationDto
     */
    'outputType'?: DataConversationDtoOutputTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataConversationDto
     */
    'outputStatus'?: DataConversationDtoOutputStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataConversationDto
     */
    'promptSuggestions'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DataConversationDto
     */
    'execErr'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataConversationDto
     */
    'errorType'?: DataConversationDtoErrorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataConversationDto
     */
    'erroneousCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof DataConversationDto
     */
    'sampleRows'?: number;
    /**
     * 
     * @type {ErrDetails}
     * @memberof DataConversationDto
     */
    'errDetails'?: ErrDetails;
    /**
     * 
     * @type {number}
     * @memberof DataConversationDto
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {CodeHistoryLikes}
     * @memberof DataConversationDto
     */
    'likes'?: CodeHistoryLikes;
}

export const DataConversationDtoOutputTypeEnum = {
    Dataset: 'DATASET',
    Chart: 'CHART',
    Text: 'TEXT',
    Model: 'MODEL',
    AutoInfer: 'AUTO_INFER',
    PromptSuggestions: 'PROMPT_SUGGESTIONS'
} as const;

export type DataConversationDtoOutputTypeEnum = typeof DataConversationDtoOutputTypeEnum[keyof typeof DataConversationDtoOutputTypeEnum];
export const DataConversationDtoOutputStatusEnum = {
    Successful: 'SUCCESSFUL',
    Failed: 'FAILED'
} as const;

export type DataConversationDtoOutputStatusEnum = typeof DataConversationDtoOutputStatusEnum[keyof typeof DataConversationDtoOutputStatusEnum];
export const DataConversationDtoErrorTypeEnum = {
    AiDown: 'AI_DOWN',
    AiCodeNotGenerated: 'AI_CODE_NOT_GENERATED',
    UnknownError: 'UNKNOWN_ERROR'
} as const;

export type DataConversationDtoErrorTypeEnum = typeof DataConversationDtoErrorTypeEnum[keyof typeof DataConversationDtoErrorTypeEnum];


