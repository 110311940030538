import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import React from "react";
import _ from "lodash";
import {
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";

interface IProps {
  title: string;
  disabledMsg?: string;
  description: string;
  configList: (React.JSX.Element | string)[];
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  card: {
    width: "390px",
    minHeight: "calc(100vh - 215px)",
    height: "100%",
    padding: "20px 8px",
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "12px",

    "& h3": {
      color: "#191D23"
    },

    "&:hover": {
      outline: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: "#EDEDFF"
    }
  },
  desc: {
    color: "#64748B",
    fontSize: 16
  },
  btn: {
    width: "100%",
    "&.Mui-disabled": {
      cursor: "not-allowed"
    }
  },
  content: {
    display: "flex",
    gap: "12px",
    flexDirection: "column"
  },
  listItem: {
    display: "flex",
    gap: "16px",
    alignItems: "flex-start",
    padding: "8px 0px",

    "& li": {
      listStyleType: "disc",
      marginLeft: "30px",
      marginTop: "6px"
    }
  },
  icon: {
    padding: "4px",
    background: "#E8EDFB",
    minWidth: "unset",
    borderRadius: "50%"
  }
}));

const SelectDataAppCard: React.FC<IProps> = (props) => {
  const { title, description, configList, disabledMsg, onClick } = props;

  const { card, desc, btn, content, listItem, icon } = useStyles();

  return (
    <Card className={card}>
      <CardContent className={content}>
        <Typography variant="h6" component="h3">
          {title}
        </Typography>
        <Typography className={desc}>{description}</Typography>
        <Tooltip title={disabledMsg ?? ""}>
          <span>
            <Button
              className={btn}
              disabled={!!disabledMsg}
              variant="outlined"
              color="primary"
              onClick={onClick}>
              Get Started Now
            </Button>
          </span>
        </Tooltip>
        <List>
          {_.map(configList, (item, index) => (
            <ListItem className={listItem} key={index}>
              <ListItemIcon className={icon}>
                <DoneOutlinedIcon color="primary" />
              </ListItemIcon>
              <ListItemText>{item}</ListItemText>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default SelectDataAppCard;
