import React from "react";

// Packages
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";
import { Section } from "./ViewDataRoutes";

type Props = {
  project: $TSFixMe;
  isJobPath: boolean;
  jobData: $TSFixMe;
  jobRunId: string;
  jobRunName?: string;
  entityName: string;
  section: Section;
  isDefaultScenario: boolean;
  scenarioName: string;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const {
    project,
    isJobPath,
    jobData,
    jobRunId,
    section,
    jobRunName,
    entityName,
    isDefaultScenario,
    scenarioName
  } = props || {};

  const location = useLocation();

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link
        key="projects"
        to={`/projects/${project?.id}/canvas`}
        {...(!!location?.state ? { state: location?.state } : {})}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText key="project-name" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  if (isJobPath && !!project?.id) {
    breadcrumbs.push(
      <Link key="jobs" to={`/projects/${project?.id}/jobs`}>
        <RenderText>Jobs</RenderText>
      </Link>
    );

    breadcrumbs.push(
      !!project?.id && !!jobData?.id ? (
        <Link key="jobs-name" to={`/projects/${project?.id}/jobs/${jobData?.id}`}>
          <RenderText>{jobData?.name || "Job"}</RenderText>
        </Link>
      ) : (
        <RenderText key="jobs-name" color="textSecondary">
          {jobData?.name || "Job"}
        </RenderText>
      )
    );

    breadcrumbs.push(
      project?.id && !!jobData?.id && !!jobRunId ? (
        <Link
          key="job-run-name"
          to={`/projects/${project?.id}/jobs/${jobData?.id}/job-runs/${jobRunId}/job-canvas`}>
          <RenderText>{`Run Name ${jobRunName || jobRunId || "Unknown"}`}</RenderText>
        </Link>
      ) : (
        <RenderText key="job-run-name" color="textSecondary">{`Run Name ${
          jobRunName || jobRunId || "Unknown"
        }`}</RenderText>
      )
    );

    if (section === Section.advanceAnalysis) {
      breadcrumbs.push(
        <Link
          key="job-run-name"
          to={_.replace(window.location.pathname, Section.advanceAnalysis, Section.analysis)}>
          <RenderText key="entity" color="textSecondary">
            {entityName}
          </RenderText>
        </Link>
      );
    } else {
      breadcrumbs.push(
        <RenderText key="entity-name" color="textSecondary">
          {entityName}
        </RenderText>
      );
    }
  } else {
    if (section === Section.advanceAnalysis) {
      breadcrumbs.push(
        <Link
          key="job-run-name"
          to={_.replace(window.location.pathname, Section.advanceAnalysis, Section.analysis)}>
          <RenderText key="entity" color="textSecondary">
            {entityName}
          </RenderText>
        </Link>
      );
    } else {
      breadcrumbs.push(
        <RenderText key="scenario" color="textSecondary">
          {entityName}
          {!isDefaultScenario && !!scenarioName && ` (${scenarioName})`}
        </RenderText>
      );
    }
  }

  if (section === Section.advanceAnalysis && !!project?.id) {
    breadcrumbs.push(
      <RenderText key="advance-analysis" color="textSecondary">
        Advanced Analysis
      </RenderText>
    );
  }
  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
