import React from "react";

import { IConnector } from "src/pages/DataSources/utils/DataSources.constants";

type ContextTypes = {
  connectorsStore: IConnector[];
  jobPaths: string[];
  jobPathsSetter: $TSFixMeFunction;
};

// @ts-ignore
export const JobsContext = React.createContext<ContextTypes>();
