/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { PipelineTestResultPerTransformMsgsInnerValueInner } from './pipeline-test-result-per-transform-msgs-inner-value-inner';

/**
 * 
 * @export
 * @interface AIGuideConversationResult
 */
export interface AIGuideConversationResult {
    /**
     * 
     * @type {string}
     * @memberof AIGuideConversationResult
     */
    'status'?: AIGuideConversationResultStatusEnum;
    /**
     * 
     * @type {Array<PipelineTestResultPerTransformMsgsInnerValueInner>}
     * @memberof AIGuideConversationResult
     */
    'messages'?: Array<PipelineTestResultPerTransformMsgsInnerValueInner>;
    /**
     * 
     * @type {string}
     * @memberof AIGuideConversationResult
     */
    'responseEntity'?: string;
    /**
     * 
     * @type {string}
     * @memberof AIGuideConversationResult
     */
    'vertical'?: string;
    /**
     * 
     * @type {string}
     * @memberof AIGuideConversationResult
     */
    'useCase'?: string;
    /**
     * 
     * @type {string}
     * @memberof AIGuideConversationResult
     */
    'question'?: string;
    /**
     * 
     * @type {string}
     * @memberof AIGuideConversationResult
     */
    'answer'?: string;
}

export const AIGuideConversationResultStatusEnum = {
    Skipped: 'SKIPPED',
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type AIGuideConversationResultStatusEnum = typeof AIGuideConversationResultStatusEnum[keyof typeof AIGuideConversationResultStatusEnum];


