import React from "react";

import isEmpty from "lodash/isEmpty";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";

import SubTopNavBarLeftSection from "./SubTopNavBarLeftSection";
import SubTopNavBarRightSection from "./SubTopNavBarRightSection";

export enum SubTopNavBarVariants {
  Page = "page",
  Drawer = "drawer"
}

type Props = {
  variant?: "page" | "drawer";
  subTopNavBarLeftSection?: {
    backNavPath?: string;
    backNavAction?: $TSFixMeFunction;
    isBackNavActionDisabled?: boolean;
    styleProps: $TSFixMe;
    component?: React.JSX.Element;
  };
  subTopNavBarRightSection?: {
    moreOptions?: $TSFixMe[];
    component?: React.JSX.Element;
  };
  onDrawerClose?: $TSFixMeFunction;
};

type StyleProps = {
  variant: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: (props: StyleProps) =>
      props?.variant === SubTopNavBarVariants.Drawer ? "absolute" : "fixed",
    marginTop: (props: StyleProps) => (props?.variant === SubTopNavBarVariants.Drawer ? 0 : 48),
    backgroundColor: (props: StyleProps) =>
      props?.variant === SubTopNavBarVariants.Drawer
        ? theme?.palette?.common?.white
        : theme?.palette?.background?.default,
    color: theme.palette.common.black
  }
}));

const SubTopNavBar = (props: Props) => {
  const {
    variant = SubTopNavBarVariants.Page,
    subTopNavBarLeftSection,
    subTopNavBarRightSection,
    onDrawerClose
  } = props || {};

  const classes = useStyles({ variant });

  return (
    <AppBar className={classes.root} elevation={1}>
      <Toolbar variant="dense" disableGutters>
        {!isEmpty(subTopNavBarLeftSection) && (
          <SubTopNavBarLeftSection
            variant={variant}
            subTopNavBarLeftSection={subTopNavBarLeftSection}>
            {subTopNavBarLeftSection?.component}
          </SubTopNavBarLeftSection>
        )}
        {(!isEmpty(subTopNavBarRightSection) || variant === SubTopNavBarVariants.Drawer) && (
          <SubTopNavBarRightSection
            variant={variant}
            moreOptions={subTopNavBarRightSection?.moreOptions}
            onDrawerClose={onDrawerClose}>
            {subTopNavBarRightSection?.component}
          </SubTopNavBarRightSection>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default SubTopNavBar;
