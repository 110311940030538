import React from "react";

// Packages
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";

type Props = {
  project: $TSFixMe;
  openCreateDataApp: boolean;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, openCreateDataApp } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  if (!!project?.id) {
    breadcrumbs.push(
      <Link key="projects" to={`/projects/${project?.id}/canvas`}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    );

    breadcrumbs.push(
      <RenderText key="dataapps" color="textSecondary">
        {openCreateDataApp ? "Create DataApp" : "DataApps"}
      </RenderText>
    );
  }

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
