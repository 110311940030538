/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CreateEnvDto } from '../Models';
// @ts-ignore
import { EnvDto } from '../Models';
// @ts-ignore
import { EnvLogDto } from '../Models';
// @ts-ignore
import { EnvUsageDto } from '../Models';
// @ts-ignore
import { ErrorMessage } from '../Models';
// @ts-ignore
import { ResourceUsageDto } from '../Models';
// @ts-ignore
import { UpdateEnvDto } from '../Models';
/**
 * EnvControllerApi - axios parameter creator
 * @export
 */
export const EnvControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {boolean} [deleteRecent] 
         * @param {boolean} [cleanUpCustomLibs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanupCache: async (id: string, deleteRecent?: boolean, cleanUpCustomLibs?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cleanupCache', 'id', id)
            const localVarPath = `/v2/envs/clean-cache/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deleteRecent !== undefined) {
                localVarQueryParameter['deleteRecent'] = deleteRecent;
            }

            if (cleanUpCustomLibs !== undefined) {
                localVarQueryParameter['cleanUpCustomLibs'] = cleanUpCustomLibs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateEnvDto} createEnvDto 
         * @param {boolean} [async] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnv: async (createEnvDto: CreateEnvDto, async?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEnvDto' is not null or undefined
            assertParamExists('createEnv', 'createEnvDto', createEnvDto)
            const localVarPath = `/v2/envs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (async !== undefined) {
                localVarQueryParameter['async'] = async;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEnvDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [deleteDefault] 
         * @param {boolean} [skipCheck] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvs: async (id: string, deleteDefault?: boolean, skipCheck?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEnvs', 'id', id)
            const localVarPath = `/v2/envs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deleteDefault !== undefined) {
                localVarQueryParameter['deleteDefault'] = deleteDefault;
            }

            if (skipCheck !== undefined) {
                localVarQueryParameter['skipCheck'] = skipCheck;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEnvById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findEnvById', 'id', id)
            const localVarPath = `/v2/envs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEnvs: async (name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/envs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEnvTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/envs/types/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs2: async (id: string, mode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLogs2', 'id', id)
            const localVarPath = `/v2/envs/{id}/logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceUsage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getResourceUsage', 'id', id)
            const localVarPath = `/v2/envs/{id}/resource-usage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUsage', 'id', id)
            const localVarPath = `/v2/envs/{id}/usage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [cores] 
         * @param {number} [gpus] 
         * @param {number} [memInGb] 
         * @param {number} [diskInGB] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        launchCustomConfig: async (id: string, cores?: number, gpus?: number, memInGb?: number, diskInGB?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('launchCustomConfig', 'id', id)
            const localVarPath = `/v2/envs/launch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cores !== undefined) {
                localVarQueryParameter['cores'] = cores;
            }

            if (gpus !== undefined) {
                localVarQueryParameter['gpus'] = gpus;
            }

            if (memInGb !== undefined) {
                localVarQueryParameter['memInGb'] = memInGb;
            }

            if (diskInGB !== undefined) {
                localVarQueryParameter['diskInGB'] = diskInGB;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [skipCheck] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopEnv: async (id: string, skipCheck?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stopEnv', 'id', id)
            const localVarPath = `/v2/envs/stop/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skipCheck !== undefined) {
                localVarQueryParameter['skipCheck'] = skipCheck;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEnvDto} updateEnvDto 
         * @param {boolean} [async] 
         * @param {boolean} [relaunch] 
         * @param {boolean} [notify] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnv: async (id: string, updateEnvDto: UpdateEnvDto, async?: boolean, relaunch?: boolean, notify?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEnv', 'id', id)
            // verify required parameter 'updateEnvDto' is not null or undefined
            assertParamExists('updateEnv', 'updateEnvDto', updateEnvDto)
            const localVarPath = `/v2/envs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (async !== undefined) {
                localVarQueryParameter['async'] = async;
            }

            if (relaunch !== undefined) {
                localVarQueryParameter['relaunch'] = relaunch;
            }

            if (notify !== undefined) {
                localVarQueryParameter['notify'] = notify;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEnvDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnvControllerApi - functional programming interface
 * @export
 */
export const EnvControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnvControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {boolean} [deleteRecent] 
         * @param {boolean} [cleanUpCustomLibs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanupCache(id: string, deleteRecent?: boolean, cleanUpCustomLibs?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cleanupCache(id, deleteRecent, cleanUpCustomLibs, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvControllerApi.cleanupCache']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CreateEnvDto} createEnvDto 
         * @param {boolean} [async] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEnv(createEnvDto: CreateEnvDto, async?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEnv(createEnvDto, async, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvControllerApi.createEnv']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [deleteDefault] 
         * @param {boolean} [skipCheck] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEnvs(id: string, deleteDefault?: boolean, skipCheck?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEnvs(id, deleteDefault, skipCheck, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvControllerApi.deleteEnvs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEnvById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnvDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEnvById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvControllerApi.findEnvById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEnvs(name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnvDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEnvs(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvControllerApi.findEnvs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEnvTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: object; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEnvTypes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvControllerApi.getAllEnvTypes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogs2(id: string, mode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogs2(id, mode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvControllerApi.getLogs2']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourceUsage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceUsageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourceUsage(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvControllerApi.getResourceUsage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnvUsageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsage(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvControllerApi.getUsage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [cores] 
         * @param {number} [gpus] 
         * @param {number} [memInGb] 
         * @param {number} [diskInGB] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async launchCustomConfig(id: string, cores?: number, gpus?: number, memInGb?: number, diskInGB?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.launchCustomConfig(id, cores, gpus, memInGb, diskInGB, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvControllerApi.launchCustomConfig']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [skipCheck] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopEnv(id: string, skipCheck?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopEnv(id, skipCheck, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvControllerApi.stopEnv']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEnvDto} updateEnvDto 
         * @param {boolean} [async] 
         * @param {boolean} [relaunch] 
         * @param {boolean} [notify] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEnv(id: string, updateEnvDto: UpdateEnvDto, async?: boolean, relaunch?: boolean, notify?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEnv(id, updateEnvDto, async, relaunch, notify, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EnvControllerApi.updateEnv']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EnvControllerApi - factory interface
 * @export
 */
export const EnvControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnvControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {boolean} [deleteRecent] 
         * @param {boolean} [cleanUpCustomLibs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanupCache(id: string, deleteRecent?: boolean, cleanUpCustomLibs?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.cleanupCache(id, deleteRecent, cleanUpCustomLibs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateEnvDto} createEnvDto 
         * @param {boolean} [async] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnv(createEnvDto: CreateEnvDto, async?: boolean, options?: any): AxiosPromise<EnvDto> {
            return localVarFp.createEnv(createEnvDto, async, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [deleteDefault] 
         * @param {boolean} [skipCheck] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvs(id: string, deleteDefault?: boolean, skipCheck?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEnvs(id, deleteDefault, skipCheck, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEnvById(id: string, options?: any): AxiosPromise<Array<EnvDto>> {
            return localVarFp.findEnvById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEnvs(name?: string, options?: any): AxiosPromise<Array<EnvDto>> {
            return localVarFp.findEnvs(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEnvTypes(options?: any): AxiosPromise<Array<{ [key: string]: object; }>> {
            return localVarFp.getAllEnvTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs2(id: string, mode?: string, options?: any): AxiosPromise<EnvLogDto> {
            return localVarFp.getLogs2(id, mode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceUsage(id: string, options?: any): AxiosPromise<ResourceUsageDto> {
            return localVarFp.getResourceUsage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsage(id: string, options?: any): AxiosPromise<Array<EnvUsageDto>> {
            return localVarFp.getUsage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [cores] 
         * @param {number} [gpus] 
         * @param {number} [memInGb] 
         * @param {number} [diskInGB] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        launchCustomConfig(id: string, cores?: number, gpus?: number, memInGb?: number, diskInGB?: number, options?: any): AxiosPromise<EnvDto> {
            return localVarFp.launchCustomConfig(id, cores, gpus, memInGb, diskInGB, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [skipCheck] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopEnv(id: string, skipCheck?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.stopEnv(id, skipCheck, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateEnvDto} updateEnvDto 
         * @param {boolean} [async] 
         * @param {boolean} [relaunch] 
         * @param {boolean} [notify] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnv(id: string, updateEnvDto: UpdateEnvDto, async?: boolean, relaunch?: boolean, notify?: boolean, options?: any): AxiosPromise<EnvDto> {
            return localVarFp.updateEnv(id, updateEnvDto, async, relaunch, notify, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnvControllerApi - interface
 * @export
 * @interface EnvControllerApi
 */
export interface EnvControllerApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {boolean} [deleteRecent] 
     * @param {boolean} [cleanUpCustomLibs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApiInterface
     */
    cleanupCache(id: string, deleteRecent?: boolean, cleanUpCustomLibs?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {CreateEnvDto} createEnvDto 
     * @param {boolean} [async] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApiInterface
     */
    createEnv(createEnvDto: CreateEnvDto, async?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<EnvDto>;

    /**
     * 
     * @param {string} id 
     * @param {boolean} [deleteDefault] 
     * @param {boolean} [skipCheck] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApiInterface
     */
    deleteEnvs(id: string, deleteDefault?: boolean, skipCheck?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApiInterface
     */
    findEnvById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EnvDto>>;

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApiInterface
     */
    findEnvs(name?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EnvDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApiInterface
     */
    getAllEnvTypes(options?: RawAxiosRequestConfig): AxiosPromise<Array<{ [key: string]: object; }>>;

    /**
     * 
     * @param {string} id 
     * @param {string} [mode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApiInterface
     */
    getLogs2(id: string, mode?: string, options?: RawAxiosRequestConfig): AxiosPromise<EnvLogDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApiInterface
     */
    getResourceUsage(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ResourceUsageDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApiInterface
     */
    getUsage(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EnvUsageDto>>;

    /**
     * 
     * @param {string} id 
     * @param {number} [cores] 
     * @param {number} [gpus] 
     * @param {number} [memInGb] 
     * @param {number} [diskInGB] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EnvControllerApiInterface
     */
    launchCustomConfig(id: string, cores?: number, gpus?: number, memInGb?: number, diskInGB?: number, options?: RawAxiosRequestConfig): AxiosPromise<EnvDto>;

    /**
     * 
     * @param {string} id 
     * @param {boolean} [skipCheck] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApiInterface
     */
    stopEnv(id: string, skipCheck?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {UpdateEnvDto} updateEnvDto 
     * @param {boolean} [async] 
     * @param {boolean} [relaunch] 
     * @param {boolean} [notify] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApiInterface
     */
    updateEnv(id: string, updateEnvDto: UpdateEnvDto, async?: boolean, relaunch?: boolean, notify?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<EnvDto>;

}

/**
 * EnvControllerApi - object-oriented interface
 * @export
 * @class EnvControllerApi
 * @extends {BaseAPI}
 */
export class EnvControllerApi extends BaseAPI implements EnvControllerApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {boolean} [deleteRecent] 
     * @param {boolean} [cleanUpCustomLibs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApi
     */
    public cleanupCache(id: string, deleteRecent?: boolean, cleanUpCustomLibs?: boolean, options?: RawAxiosRequestConfig) {
        return EnvControllerApiFp(this.configuration).cleanupCache(id, deleteRecent, cleanUpCustomLibs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateEnvDto} createEnvDto 
     * @param {boolean} [async] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApi
     */
    public createEnv(createEnvDto: CreateEnvDto, async?: boolean, options?: RawAxiosRequestConfig) {
        return EnvControllerApiFp(this.configuration).createEnv(createEnvDto, async, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {boolean} [deleteDefault] 
     * @param {boolean} [skipCheck] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApi
     */
    public deleteEnvs(id: string, deleteDefault?: boolean, skipCheck?: boolean, options?: RawAxiosRequestConfig) {
        return EnvControllerApiFp(this.configuration).deleteEnvs(id, deleteDefault, skipCheck, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApi
     */
    public findEnvById(id: string, options?: RawAxiosRequestConfig) {
        return EnvControllerApiFp(this.configuration).findEnvById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApi
     */
    public findEnvs(name?: string, options?: RawAxiosRequestConfig) {
        return EnvControllerApiFp(this.configuration).findEnvs(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApi
     */
    public getAllEnvTypes(options?: RawAxiosRequestConfig) {
        return EnvControllerApiFp(this.configuration).getAllEnvTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [mode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApi
     */
    public getLogs2(id: string, mode?: string, options?: RawAxiosRequestConfig) {
        return EnvControllerApiFp(this.configuration).getLogs2(id, mode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApi
     */
    public getResourceUsage(id: string, options?: RawAxiosRequestConfig) {
        return EnvControllerApiFp(this.configuration).getResourceUsage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApi
     */
    public getUsage(id: string, options?: RawAxiosRequestConfig) {
        return EnvControllerApiFp(this.configuration).getUsage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [cores] 
     * @param {number} [gpus] 
     * @param {number} [memInGb] 
     * @param {number} [diskInGB] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EnvControllerApi
     */
    public launchCustomConfig(id: string, cores?: number, gpus?: number, memInGb?: number, diskInGB?: number, options?: RawAxiosRequestConfig) {
        return EnvControllerApiFp(this.configuration).launchCustomConfig(id, cores, gpus, memInGb, diskInGB, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {boolean} [skipCheck] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApi
     */
    public stopEnv(id: string, skipCheck?: boolean, options?: RawAxiosRequestConfig) {
        return EnvControllerApiFp(this.configuration).stopEnv(id, skipCheck, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateEnvDto} updateEnvDto 
     * @param {boolean} [async] 
     * @param {boolean} [relaunch] 
     * @param {boolean} [notify] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvControllerApi
     */
    public updateEnv(id: string, updateEnvDto: UpdateEnvDto, async?: boolean, relaunch?: boolean, notify?: boolean, options?: RawAxiosRequestConfig) {
        return EnvControllerApiFp(this.configuration).updateEnv(id, updateEnvDto, async, relaunch, notify, options).then((request) => request(this.axios, this.basePath));
    }
}

