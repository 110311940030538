import React, { useCallback, useMemo } from "react";
import _, { toLower } from "lodash";
import {
  IconButton,
  makeStyles,
  Tooltip,
  CircularProgress,
  Box,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { UseTableRowProps } from "react-table";

// Icons
import { Edit, PlayArrow, Stop, Refresh } from "@material-ui/icons";
import { ConfigIcon } from "src/icons/ConfigIcon";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { Delete } from "src/icons/Delete";

import Text from "src/components/Widget/Text";
import { Table } from "src/components";
import {
  EnvironmentStatuses,
  environmentsTypes as environmentsTypesConstant
} from "src/constants/environments.constants";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import styles from "./EnvironmentTable.module.scss";
import { dateFormat } from "src/utils/dateFormat";
import { environmentDetails } from "../../utils/environments.helpers";
import { EnvironmentsHelperText } from "../../utils/Environments.constants";

const useStyles = makeStyles({
  link: {
    display: "inline-flex",
    color: "#003656",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  }
});

export const EnvironmentTable = ({
  environmentsTypes,
  dataEnvironments,
  onDeleteEnv,
  onEditEnv,
  onRelaunch,
  stoppingEnvironments,
  onStopEnv,
  logs,
  usage
}: $TSFixMe) => {
  const { link } = useStyles();

  const statusEnvironmentClassName = {
    shutdown: styles.environmentStatusFailed,
    launching: styles.environmentStatusLaunching,
    success: styles.environmentStatusActive,
    failed: styles.environmentStatusFailed
  };

  const statusValues: $TSFixMe = {
    shutdown: { text: "Shutdown", buttonText: "START", icon: <PlayArrow fontSize="small" /> },
    launching: { text: "Launching...", buttonText: "", icon: <></> },
    success: { text: "Running", buttonText: "STOP", icon: <Stop fontSize="small" /> },
    failed: { text: "Failed", buttonText: "RELAUNCH", icon: <Refresh fontSize="small" /> }
  };

  const environmentAction = (env: $TSFixMe) => {
    switch (env?.launchStatus?.trim()?.toLowerCase()) {
      case EnvironmentStatuses.Active.toLowerCase():
        return () => onStopEnv(env);
      default:
        return () => onRelaunch(env);
    }
  };

  const isLaunching = useCallback(
    (env: $TSFixMe) => env?.launchStatus === EnvironmentStatuses.Launching,
    []
  );

  const statusSortType = useMemo(
    () => (rowA: UseTableRowProps<any>, rowB: UseTableRowProps<any>) => {
      const statusA = _.lowerCase(_.trim(_.get(rowA.original, "launchStatus")));
      const statusB = _.lowerCase(_.trim(_.get(rowB.original, "launchStatus")));

      const valA = statusValues[statusA]?.text || "Unknown";
      const valB = statusValues[statusB]?.text || "Unknown";
      if (_.lowerCase(valA) > _.lowerCase(valB)) {
        return 1;
      }
      if (_.lowerCase(valA) < _.lowerCase(valB)) {
        return -1;
      }

      return 0;
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        id: "Name",
        accessor: "name",
        Header: "Name",
        isSortable: true,
        Cell: ({ row }: $TSFixMe) => (
          <LinkCellWithMenu
            url={`/environments/${row.original.id}`}
            linkText={<Text value={row.original.name} style={{ whiteSpace: "pre" }} />}
            menuButtons={[
              {
                label: "EDIT",
                icon: (
                  <Typography color="textSecondary">
                    <Edit />
                  </Typography>
                ),
                action: () => onEditEnv(row.original.id)
              },
              {
                label: "CONFIGS",
                icon: <ConfigIcon />,
                action: () => usage(row?.original)
              },
              {
                label: "LOGS",
                icon: (
                  <Typography color="textSecondary">
                    <LogsNewIconV2 width="24" />
                  </Typography>
                ),
                action: () => logs(row?.original)
              },
              {
                label: "DELETE",
                icon: (
                  <Typography color="textSecondary">
                    <Delete />
                  </Typography>
                ),
                action: () => onDeleteEnv(row?.original),
                disabled: isLaunching(row?.original),
                tooltip: isLaunching(row?.original) ? EnvironmentsHelperText.DeleteInfo : ""
              }
            ]}
          />
        )
      },
      {
        id: "Env Type",
        accessor: "envType",
        Header: "Env Type",
        isSortable: true,
        Cell: ({ row }: $TSFixMe) =>
          environmentsTypesConstant[
            environmentDetails({ environmentsTypes, env: row?.original })?.name || ""
          ]
      },
      {
        id: "Description",
        accessor: "description",
        Header: "Description",
        isSortable: true,
        Cell: (row: $TSFixMe) => row.value
      },
      {
        id: "Updated by",
        accessor: "updater",
        Header: "Updated by",
        isSortable: true,
        Cell: (row: $TSFixMe) => row.value
      },
      {
        id: "Updated on",
        accessor: "updated",
        Header: "Updated on",
        isSortable: true,
        Cell: ({ value }: $TSFixMe) => <span>{dateFormat(value)}</span>
      },
      {
        id: "Used In",
        accessor: (props: $TSFixMe) =>
          props.projects
            ? props.projects?.reduce(
                (acc: string, project: $TSFixMe) =>
                  acc.length ? acc.concat(`, ${project}`) : project,
                ""
              )
            : "",
        Header: "Used In",
        isSortable: true,
        Cell: ({ row }: $TSFixMe) => {
          const projects = _.map(row?.original?.projectDtos, (project, i: number) => (
            <span key={project.id}>
              {i > 0 && <span>{", "}</span>}
              <Link className={link} to={`/projects/${project.id}`}>
                <Text value={project.name} />
              </Link>
            </span>
          ));

          return row?.original?.projects.length > 0 ? <>{projects}</> : "";
        }
      },
      {
        id: "Status",
        accessor: "launchStatus",
        Header: "Status",
        sortType: statusSortType,
        isSortable: true,
        Cell: ({ row, value }: $TSFixMe) => {
          const statusName = value?.trim()?.toLowerCase();

          // @ts-ignore
          const classNameStatus = statusEnvironmentClassName[statusName.toLowerCase()];

          return (
            <div className={`${styles.statusNameContainer} ${classNameStatus}`}>
              <span>{statusValues[statusName]?.text || value || "Unknown"}</span>
              {statusName !== EnvironmentStatuses.Launching.toLowerCase() &&
                (stoppingEnvironments?.includes(row?.original?.id) ? (
                  <Box paddingLeft="6px">
                    <CircularProgress size="20px" />
                  </Box>
                ) : (
                  !!statusValues[statusName]?.icon && (
                    <Tooltip title={statusValues[statusName]?.buttonText}>
                      <IconButton aria-label="edit" onClick={environmentAction(row?.original)}>
                        {statusValues[statusName]?.icon}
                      </IconButton>
                    </Tooltip>
                  )
                ))}
              {statusName === EnvironmentStatuses.Launching.toLowerCase() && (
                <Box paddingLeft="6px">
                  {/* @ts-expect-error TS(2322) FIXME: Type '"#c77700"' is not assignable to type '"inher... Remove this comment to see the full error message */}
                  <CircularProgress size={20} color="#c77700" />
                </Box>
              )}
            </div>
          );
        }
      }
    ],
    [environmentsTypes, stoppingEnvironments]
  );

  const sortByDate = (a: $TSFixMe, b: $TSFixMe) => {
    return b.createdAt - a.createdAt;
  };
  const statusOrder = ["launching", "success", "shutdown", "failed"];

  const sortBystatus = (a: any, b: any) => {
    return (
      statusOrder.indexOf(toLower(a.launchStatus)) - statusOrder.indexOf(toLower(b.launchStatus))
    );
  };

  return (
    <Table
      data={dataEnvironments.sort(sortByDate).sort(sortBystatus)}
      size="small"
      hideCount
      columns={columns}
      isCellSortEnabled
      orderByDefault="Updated on"
      maxHeight="calc(100vh - 339px)"
      isTheadSticky
    />
  );
};
