import { useScenariosStore } from "src/store/store";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getScenarios } from "src/api/projects";
import shallow from "zustand/shallow";

type PrefetchingScenariosProps = { queryClient: $TSFixMe; projectId?: string; jobId?: string };

interface ScenariosProps extends UseQueryOptions<$TSFixMe> {
  projectId: string;
  jobId?: string;

  // useQuery options
  cacheTime?: number;
  shouldSetScenariosLoading?: boolean;
}

export const prefetchScenarios = (props: PrefetchingScenariosProps) => {
  const { queryClient, projectId, jobId } = props;

  queryClient.prefetchQuery({
    queryKey: ["scenarios", projectId],
    queryFn: () => getScenarios({ projectId, jobId })
  });
};

export const QUERY_KEY_SCENARIOS = "scenarios";
export const useGetScenarios = (props: ScenariosProps): UseQueryResult<$TSFixMe> => {
  const {
    projectId,
    jobId,
    shouldSetScenariosLoading = false,

    // useQuery options
    cacheTime = 0,
    ...options
  } = props;
  const [setIsScenariosLoading] = useScenariosStore(
    (state) => [state.setIsScenariosLoading],
    shallow
  );
  return useQuery({
    queryKey: [QUERY_KEY_SCENARIOS, projectId],
    queryFn: async () => {
      shouldSetScenariosLoading && setIsScenariosLoading(true);
      const response = await getScenarios({ projectId, jobId });
      shouldSetScenariosLoading && setIsScenariosLoading(false);
      return response;
    },
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    cacheTime,
    enabled: !!projectId,
    ...options
  });
};
