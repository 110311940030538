import React, { useState } from "react";
import _ from "lodash";
import { Box } from "@material-ui/core";

import NewModal from "components/Modal/NewModal";
import SampleSyntaxIcon from "src/icons/NewUX/SampleSyntaxIcon";
import { Tabs, Tab } from "src/components";
import { CodeRecipeCodeEditor } from "../../AddCodeRecipe/CodeRecipeTabContainer/CodeRecipeCodeEditor/CodeRecipeCodeEditor";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { SYNTAX_CODE, SYNTAX_TABS } from "../utils/ApiConnectorRecipe.constants";

interface IProps {
  open: boolean;
  onClose: () => void;
}

const SyntaxModal: React.FC<IProps> = (props) => {
  const { open, onClose } = props;
  const [tab, setTab] = useState<SYNTAX_TABS>(SYNTAX_TABS.GET);
  const [value, setValue] = useState(_.get(SYNTAX_CODE, SYNTAX_TABS.GET));
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);

    setTimeout(() => setIsCopied(false), 5000);
  };

  const handleChange = (newTab: SYNTAX_TABS) => {
    setTab(newTab);
    setValue(_.get(SYNTAX_CODE, newTab));
    setIsCopied(false);
  };

  return (
    <NewThemeWrapper>
      <NewModal
        header={
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <SampleSyntaxIcon color="black" width={17} height={17} /> Sample Syntax
          </div>
        }
        open={open}
        data-testid="api-connectorsample-syntax"
        width={980}
        background="#fff"
        keepMounted={false}
        cancelButtonLabel="Close"
        submitDisabled={isCopied}
        submitButtonLabel={isCopied ? "Copied" : "Copy"}
        onClose={onClose}
        onFinish={handleCopy}>
        <>
          <div>
            <Tabs value={tab} onChange={handleChange}>
              {_.map(SYNTAX_TABS, (tab) => (
                <Tab key={tab} label={tab} value={tab} />
              ))}
            </Tabs>
          </div>
          <Box height="calc(100vh - 200px)" mt="1px" mb="4px">
            <CodeRecipeCodeEditor editorValue={value} setEditorValue={setValue} />
          </Box>
        </>
      </NewModal>
    </NewThemeWrapper>
  );
};

export default SyntaxModal;
