import React from "react";

import { Button, makeStyles } from "@material-ui/core";

import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";

import { DatasetHelperText } from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 150
  },
  buttonMarginLeft: {
    extend: "button",
    marginLeft: theme.spacing(1)
  }
}));

const SqlInterfaceActions = (props: $TSFixMe) => {
  const { ontologyClose } = props || {};

  const classes = useStyles();

  // Stores - STARTS >>
  const { setDatasetIsOntologyStore, datasetFooterActionsStore } = useStoreSelectors();
  // << ENDS - Stores

  const onNext = () => {
    setDatasetIsOntologyStore(true);
  };

  return (
    <>
      {!datasetFooterActionsStore.next.isHidden && (
        <Button
          id="datasetActionNext"
          variant="contained"
          color="primary"
          className={classes.buttonMarginLeft}
          disabled={datasetFooterActionsStore.next.isDisabled}
          onClick={() => onNext()}
          endIcon={<ChevronRightRoundedIcon />}>
          {DatasetHelperText.Next}
        </Button>
      )}
      {ontologyClose}
    </>
  );
};

export default SqlInterfaceActions;
