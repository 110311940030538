import React from "react";
import { useParams } from "react-router";

import { useGetRecipe } from "src/hooks/api";
import { Spinner } from "src/components";
import { AutoMLRecipeContainer } from "./AutoMLRecipeContainer/AutoMLRecipeContainer";
import { AutoMLRecipeContextProvider } from "./AutoMLRecipeContext/AutoMLRecipeContextProvider";

const AutoMLRecipeDataContainer = () => {
  const { groupId, scenarioId, jobRunId } = useParams<$TSFixMe>();
  const { isFetching, data } = useGetRecipe({ recipeId: groupId, scenarioId, jobRunId });
  if (!groupId) {
    return (
      <AutoMLRecipeContextProvider>
        <AutoMLRecipeContainer />
      </AutoMLRecipeContextProvider>
    );
  }
  return isFetching ? (
    <Spinner />
  ) : (
    <AutoMLRecipeContextProvider recipe={data?.recipe}>
      <AutoMLRecipeContainer />
    </AutoMLRecipeContextProvider>
  );
};

export default AutoMLRecipeDataContainer;
