import React, { Dispatch, SetStateAction } from "react";

export type NodeActions = {
  autoLayout: boolean;
  nodeDrag: boolean;
};

type ContextTypes = {
  isFromDatasetPage: boolean;

  project: $TSFixMe;
  scenario: $TSFixMe;

  nodesExpanded: { [key: string]: boolean };
  setNodesExpanded: Dispatch<SetStateAction<{ [key: string]: boolean }>>;

  updateNodesExpanded: (nodeId?: string) => void;

  nodeActions: NodeActions;
  setNodeActions: Dispatch<SetStateAction<NodeActions>>;

  isJobPath: boolean;
  isJobCanvasComparePath: boolean;

  isFetchingProjectCanvas: boolean;

  nodesStore: $TSFixMe;
  setNodesStore: $TSFixMeFunction;
  edgesStore: $TSFixMe;
  setEdgesStore: $TSFixMeFunction;
  setNodeToFocusStore: $TSFixMeFunction;
  setReloadTriggerStore: $TSFixMeFunction;

  initialNodes: $TSFixMe;
  setInitialNodes: $TSFixMeFunction;

  initialEdges: $TSFixMe;

  edgeType: string;
  changeEdgeType: $TSFixMeFunction;

  isRecipesRunning: boolean;
  isNodeHighlighted: $TSFixMe;

  contextMenuNodeId: string | null;
  setContextMenuNodeId: Dispatch<SetStateAction<string | null>>;

  resetNodesAndEdgesTimer: number | null;
  setResetNodesAndEdgesTimer: Dispatch<SetStateAction<number | null>>;
  isDefaultScenario: boolean;
};

// @ts-ignore
export const DagFlowContext = React.createContext<ContextTypes>();
