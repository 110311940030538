import React, { useEffect, useMemo } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useLocation, useParams } from "react-router";

import { Spinner } from "src/components";
import { useGetInputEntities, useGetTransformGroups } from "src/hooks/api";
import { useQuery } from "src/hooks";
import { AutoMLRecipeHeader } from "../AutoMLRecipeHeader/AutoMLRecipeHeader";
import { useAutoMLRecipeContext } from "../AutoMLRecipeContext/useAutoMLRecipeContext";
import { AutoMLRecipeInputContainer } from "./AutoMLRecipeInputContainer/AutoMLRecipeInputContainer";
import AutoMLRecipeTabContainer from "./AutoMLRecipeTabContainer/AutoMLRecipeTabContainer";
import { NoInputAutoMLContainer } from "./NoInputAutoMLContainer";
import { AutoMLRecipeRunningView } from "./AutoMLRecipeRunningView/AutoMLRecipeRunningView";
import { EntityTypeEnum, Recipe } from "src/types";
import { AutoMLRecipeRunSuccessView } from "./AutoMLRecipeRunSuccessView";
import { AutoMLRecipeRunFailureView } from "./AutoMLRecipeRunFailureView";
import { useScenariosStore } from "src/store/store";
import { checkIfDefaultScenario } from "src/pages/Scenarios/helpers/scenarios.helpers";

const useStyles = makeStyles({
  recipeWrapContainer: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)"
  },
  recipeContainer: {
    display: "inherit",
    height: "100%"
  },
  recipeInnerContainer: {
    flex: 1,
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    flexWrap: "nowrap",
    padding: "16px 0px 16px 16px"
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    flexGrow: 0,
    minWidth: "300px",
    maxWidth: "25%",
    flexBasis: "25%",
    position: "relative",
    "&::after": {
      content: "''",
      position: "absolute",
      top: "-17px",
      right: "-17px",
      width: "1px",
      height: "calc(100vh - 94px)",
      backgroundColor: "#CECECE"
    }
  },
  rightContainer: {
    flexShrink: 0,
    flexGrow: 0,
    maxWidth: "75%",
    flexBasis: "75%",
    overflow: "hidden"
  },
  recipeRunStatusContainer: {
    width: "100%",
    height: "100%",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    background: "#F8FAFB",
    marginLeft: "32px",
    marginRight: "16px",
    borderRadius: "4px",
    border: "1px solid #C8C8C8"
  }
});

export const AutoMLRecipeContainer = () => {
  const { projectId, scenarioId, jobRunId } = useParams<$TSFixMe>();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const classes = useStyles();

  const queryParameters = useQuery();
  const {
    recipe,
    setRecipeDisplayName,
    inputDatasets,
    setInputDatasets,
    setSelectedInputDatasets,
    isRunInProgress,
    isRecipeRunSuccessful,
    setIsRecipeRunSuccessful,
    scenarioData
  } = useAutoMLRecipeContext();

  const { isLoading, data: allEntities } = useGetInputEntities({
    id: projectId,
    ...(!!isJobPath ? { scenarioId, jobRunId } : {})
  });

  useEffect(() => {
    if (!isLoading && !!allEntities) {
      const selectedEntitiesStr = sessionStorage.getItem("selectedEntities");
      const selectedEntities = selectedEntitiesStr ? JSON.parse(selectedEntitiesStr) : null;
      const entityIds = recipe?.parents
        ?.filter((parent) => parent.type === EntityTypeEnum.ENTITY)
        ?.map((parent) => parent.id);
      const filteredInputDatasets = allEntities.filter(
        (entity: $TSFixMe) =>
          selectedEntities?.includes(entity.id) ||
          entityIds?.includes(entity.id) ||
          queryParameters?.get("entity") === entity.id
      );

      sessionStorage.setItem("selectedEntities", "");
      setSelectedInputDatasets(filteredInputDatasets);
      setInputDatasets(filteredInputDatasets);
    }
  }, [isLoading]);

  const { isFetching: isFetchingRecipes } = useGetTransformGroups({
    projectId,
    enabled: isLoading ? false : !recipe?.name,
    onSuccess: (groups: Array<Recipe>) => {
      const entityName = allEntities?.find(
        (item: $TSFixMe) => item.id === queryParameters?.get("entity")
      )?.name;
      const allRecipeNames = groups.reduce((acc: Array<string>, item) => {
        return [...acc, item.name?.toLowerCase(), item.displayName?.toLowerCase()];
      }, []);
      let updatedRecipeName = entityName ? `${entityName} Recipe` : `Recipe`;
      if (allRecipeNames?.length) {
        let i = 2;
        while (allRecipeNames?.includes((updatedRecipeName || "")?.toLowerCase())) {
          updatedRecipeName = entityName ? `${entityName} Recipe ${i}` : `Recipe ${i}`;
          i++;
        }
      }
      setRecipeDisplayName(updatedRecipeName);
    }
  });

  const scenarios = useScenariosStore((state) => state.scenarios);

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(scenarioData, scenarios, scenarioId),
    [scenarioData, scenarios, scenarioId]
  );

  return (
    <Grid className={classes.recipeWrapContainer}>
      {isLoading || isFetchingRecipes ? (
        <Spinner />
      ) : (
        <Grid container direction="column" className={classes.recipeContainer}>
          <AutoMLRecipeHeader isDefaultScenario={isDefaultScenario} />
          <Grid container direction="row" className={classes.recipeInnerContainer}>
            <Grid container className={classes.leftContainer}>
              <AutoMLRecipeInputContainer
                allEntities={allEntities}
                isDefaultScenario={isDefaultScenario}
              />
            </Grid>
            {isJobPath ? (
              <Grid container className={classes.rightContainer}>
                <AutoMLRecipeTabContainer isDefaultScenario={isDefaultScenario} />
              </Grid>
            ) : isRunInProgress ? (
              <Grid container className={classes.rightContainer}>
                <Box ml="32px" mr="16px" width="100%" height="100%">
                  <AutoMLRecipeRunningView />
                </Box>
              </Grid>
            ) : isRecipeRunSuccessful === true ? (
              <Grid container direction="column" className={classes.recipeRunStatusContainer}>
                <AutoMLRecipeRunSuccessView onBack={() => setIsRecipeRunSuccessful(null)} />
              </Grid>
            ) : isRecipeRunSuccessful === false ? (
              <Grid container direction="column" className={classes.recipeRunStatusContainer}>
                <AutoMLRecipeRunFailureView onBack={() => setIsRecipeRunSuccessful(null)} />
              </Grid>
            ) : inputDatasets?.length === 0 ? (
              <NoInputAutoMLContainer />
            ) : (
              <Grid container className={classes.rightContainer}>
                <AutoMLRecipeTabContainer isDefaultScenario={isDefaultScenario} />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
