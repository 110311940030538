/* eslint-disable no-unused-vars */
import React from "react";
import MonacoEditor from "react-monaco-editor";
import { v4 as uuidv4 } from "uuid";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import { CopyTextButton } from "../../CopyButton/CopyTextButton";

const useStyles = makeStyles({
  codeSnippetsNestedContainer: {
    backgroundColor: "white",
    border: "1px solid #008FE4",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px 0px 0px 8px",
    padding: "12px 0px 16px 0px",
    width: "600px",
    maxHeight: "600px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#7C7C7C",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },
  title: {
    fontWeight: 400
  },
  paragraph: {
    fontWeight: 400,
    opacity: 0.5
  },
  paragraphs: {
    flexWrap: "nowrap"
  },
  textWrap: {
    padding: "0px 12px",
    gap: "12px",
    flexWrap: "nowrap"
  },
  codeSnippet: {
    marginTop: "6px",
    backgroundColor: "#EFF3F5",
    padding: "6px 12px",
    flexWrap: "nowrap",
    gap: "6px"
  },
  codeSnippetHeader: {
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center"
  },
  codeContainer: {
    border: "1px solid #D1D1D1"
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: "16px"
  }
});

export type CodeSnippet = {
  id?: string;
  title: string;
  code: string;
  paragraphs?: string[];
};

export type Snippet = {
  id?: string;
  title: string;
  description: string;
  codeSnippets: Array<CodeSnippet>;
};

export type Snippets = Array<Snippet>;

export const CodeSnippetsNestedContainer = ({
  currentSnippet
}: {
  currentSnippet: Snippet | null;
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.codeSnippetsNestedContainer}
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch">
      {currentSnippet ? (
        <Grid container direction="column">
          <Grid container direction="column" className={classes.textWrap}>
            <Typography variant="subtitle2" test-id="current-snippet-title">
              {currentSnippet.title}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.title}
              test-id="current-snippet-description">
              {currentSnippet.description}
            </Typography>
          </Grid>
          <Grid item container direction="column">
            {currentSnippet?.codeSnippets.map(({ title, code, id, paragraphs }) => {
              return (
                <Grid container direction="column" key={id} className={classes.codeSnippet}>
                  <Grid item container direction="row" className={classes.codeSnippetHeader}>
                    <Typography
                      className={classes.title}
                      variant="subtitle2"
                      test-id="current-snippet-code-title">
                      {title}
                    </Typography>
                    <Grid className={classes.buttons}>
                      <CopyTextButton test-id="current-snippet-copy-code-btn" text={code} />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    className={classes.codeContainer}
                    test-id="current-snippet-code-container">
                    <MonacoEditor
                      language="python"
                      height="80px"
                      width="100%"
                      theme="vs"
                      value={code}
                      options={{
                        theme: "vs",
                        minimap: { enabled: false },
                        readOnly: true,
                        renderLineHighlight: "none",
                        lineNumbers: "off",
                        scrollbar: {
                          vertical: "hidden",
                          horizontal: "auto"
                        },
                        overviewRulerLanes: 0,
                        scrollBeyondLastLine: false,
                        padding: {
                          top: 12
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    className={classes.paragraphs}
                    test-id="current-snippet-code-paragraphs">
                    {paragraphs?.map((paragraph) => {
                      const key = uuidv4();
                      return (
                        <Typography
                          key={key}
                          className={classes.paragraph}
                          variant="subtitle2"
                          test-id="current-snippet-code-paragraph">
                          {paragraph}
                        </Typography>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      ) : (
        "Snippet Details Not Found"
      )}
    </Grid>
  );
};
