import { DropResult } from "react-beautiful-dnd";

// Packages
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { findIndex, isNil, split } from "lodash";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";

// Open API
import { RecipeRunData, RecipeRunDataStatusEnum } from "openapi/Models/recipe-run-data";

// Hooks
import { UseGetRecipeRunsQueueQueryKeys, useReorderRecipeInQueue } from "src/hooks/api/recipes";
import { IReorderRecipeInQueuePayload } from "src/hooks/api/recipes/useReorderRecipeInQueue";

// Constants
import { statusNameMap } from "./RecipeRunsPopover.constants";

type Props = {
  slicedRecipeRunsQueue: RecipeRunData[];
};

const useReorderRecipe = (props: Props) => {
  const { slicedRecipeRunsQueue } = props || {};

  const { projectId } = useParams();

  const queryClient = useQueryClient();

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isReorderingRecipeInQueue,
    mutateAsync: reorderRecipeInQueueMutation,
    reset: resetReorderRecipeInQueueMutation
  } = useReorderRecipeInQueue();
  // << ENDS - Query hooks

  // Reorder code - STARTS >>
  const reorderRecipeInQueue = async ({
    recipeId,
    scenarioId,
    newIndex
  }: {
    recipeId: string;
    scenarioId: string;
    newIndex: number;
  }) => {
    if (!projectId || !scenarioId || !recipeId) {
      return;
    }

    const payload: IReorderRecipeInQueuePayload = {
      projectId,
      payload: {
        groupId: recipeId,
        scenarioId,
        index: newIndex
      }
    };

    await resetReorderRecipeInQueueMutation();
    await reorderRecipeInQueueMutation(payload, {
      onSuccess: async () =>
        await queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue])
    });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result?.destination) return;

    const recipeInQueueMinIndex = findIndex(slicedRecipeRunsQueue, {
      status: RecipeRunDataStatusEnum.InQueue
    });

    if (result?.destination?.index < recipeInQueueMinIndex) {
      toastWrapper({
        type: ToastTypes.Warn,
        content: `This recipe cannot be moved higher in the queue than the current minimum index of an ${statusNameMap[RecipeRunDataStatusEnum.InQueue]} recipe.`
      });

      return;
    }

    const draggableIdFragments = split(result?.draggableId, ":");

    const recipeId = draggableIdFragments[1];
    const scenarioId = draggableIdFragments[3];

    if (
      !recipeId ||
      !scenarioId ||
      isNil(slicedRecipeRunsQueue?.[result?.destination?.index]?.index)
    ) {
      return;
    }

    // Set reordered list
    // const reorderedColumns = [...slicedRecipeRunsQueue];
    // const [removed] = reorderedColumns?.splice(result?.source?.index, 1);
    // reorderedColumns?.splice(result?.destination?.index, 0, removed);

    // setSlicedRecipeRunsQueue(() => reorderedColumns);

    reorderRecipeInQueue({
      recipeId,
      scenarioId,
      // newIndex: result?.destination?.index
      // @ts-ignore
      newIndex: slicedRecipeRunsQueue?.[result?.destination?.index]?.index
    });
  };
  // << ENDS - Reorder code

  return { onDragEnd, isReorderingRecipeInQueue };
};

export default useReorderRecipe;
