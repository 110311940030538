/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { TargetInputDto } from './target-input-dto';

/**
 * 
 * @export
 * @interface AIGuideThreadResponseDto
 */
export interface AIGuideThreadResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AIGuideThreadResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AIGuideThreadResponseDto
     */
    'targetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AIGuideThreadResponseDto
     */
    'inputType'?: AIGuideThreadResponseDtoInputTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AIGuideThreadResponseDto
     */
    'targetType'?: AIGuideThreadResponseDtoTargetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AIGuideThreadResponseDto
     */
    'projectId'?: string;
    /**
     * 
     * @type {Array<TargetInputDto>}
     * @memberof AIGuideThreadResponseDto
     */
    'targetInputs'?: Array<TargetInputDto>;
    /**
     * 
     * @type {number}
     * @memberof AIGuideThreadResponseDto
     */
    'updated'?: number;
}

export const AIGuideThreadResponseDtoInputTypeEnum = {
    Dataset: 'DATASET',
    Chart: 'CHART',
    Model: 'MODEL'
} as const;

export type AIGuideThreadResponseDtoInputTypeEnum = typeof AIGuideThreadResponseDtoInputTypeEnum[keyof typeof AIGuideThreadResponseDtoInputTypeEnum];
export const AIGuideThreadResponseDtoTargetTypeEnum = {
    CanvasNode: 'CANVAS_NODE',
    RapidModelRecipe: 'RAPID_MODEL_RECIPE'
} as const;

export type AIGuideThreadResponseDtoTargetTypeEnum = typeof AIGuideThreadResponseDtoTargetTypeEnum[keyof typeof AIGuideThreadResponseDtoTargetTypeEnum];


