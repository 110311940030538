import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  drawerContent: {
    minWidth: "600px",
    maxWidth: "600px",
    background: "#F0F0F0"
  },
  datasetItemGap: {
    gap: "10px",
    alignItems: "center"
  },
  aiGuideIcon: {
    height: "28px",
    scale: 1.4
  },
  aiGuideBtn: {
    height: "28px",
    width: "28px"
  },
  detailsWrapper: {
    fontSize: "14px",
    "& p": {
      marginBottom: "12px",
      fontSize: "14px"
    }
  },
  detailItem: {
    display: "flex",
    "&:last-child p": {
      marginBottom: 0
    }
  },
  detailName: {
    color: "#515151",
    fontWeight: 500,
    paddingRight: "6px"
  },
  cardMargin: {
    margin: "16px",
    "& .MuiCardHeader-root": {
      paddingBottom: 0
    }
  },
  accordionContainer: {
    maxHeight: "206px",
    overflow: "auto"
  },
  accordionTitle: {
    textTransform: "uppercase",
    fontWeight: 500
  },
  chartIcon: {
    marginRight: 10
  },
  link: {
    color: "black"
  },
  endpoint: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "395px",
    whiteSpace: "nowrap"
  },
  destinationCard: {
    margin: "16px",
    "& .MuiCardHeader-root": {
      borderBottom: "1px solid #e5e5e5",
      padding: "5px 16px"
    }
  },
  flexSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  bluebtn: {
    textTransform: "capitalize"
  },
  flexContainer: {
    display: "flex",
    gap: "4px",
    alignItems: "center"
  },
  flexWidth: {
    display: "flex",
    gap: "4px",
    width: "110%",

    "& svg": {
      marginTop: "6px"
    }
  },
  flex: {
    display: "flex",
    justifyContent: "space-between"
  },
  content: {
    height: "calc(100vh - 44px)",
    overflowX: "hidden",
    overflowY: "auto"
  },
  charts: {
    flexWrap: "nowrap",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#7C7C7C",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  }
});
