import React, { useState } from "react";

// Packages
import { v4 as uuidv4 } from "uuid";

// MUI
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Tooltip,
  makeStyles,
  IconButton
} from "@material-ui/core";

// Icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";

type Props = {
  options: $TSFixMe[];
};

const useStyles = makeStyles({
  menuItemIcon: {
    minWidth: "auto",
    marginRight: 8
  },
  loader: { color: "#515151" }
});

export const MoreOptions = (props: Props) => {
  const { options } = props || {};

  const classes = useStyles();

  // States - STARTS >>
  const [nestedComponent, setNestedComponent] = useState<React.JSX.Element | null>(null);
  // ENDS - States

  // Drop-down code - STARTS >>
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event: $TSFixMe) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
    setNestedComponent(null);
  };

  return (
    <>
      <IconButton color="primary" onClick={onOpen} size="small">
        <MoreVertRoundedIcon opacity={0.5} />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={onClose}
        PaperProps={{
          style: {
            width: 225,
            borderRadius: 12
          }
        }}>
        {Boolean(nestedComponent) && (
          <MenuItem key={uuidv4()} onClick={() => setNestedComponent(null)}>
            <ListItemIcon className={classes.menuItemIcon}>
              <ChevronLeftIcon />
            </ListItemIcon>
          </MenuItem>
        )}
        {Boolean(nestedComponent) && nestedComponent}

        {!Boolean(nestedComponent) &&
          options?.map((moreOption: $TSFixMe) => (
            <Tooltip key={uuidv4()} title={!!moreOption?.tooltip ? moreOption?.tooltip : ""}>
              <span>
                <MenuItem
                  onClick={() => {
                    if (!!moreOption?.nestedComponent) {
                      setNestedComponent(moreOption?.nestedComponent);
                    } else {
                      moreOption?.action();
                      !!moreOption?.isCloseMoreAction && onClose();
                    }
                  }}
                  disabled={!!moreOption?.isDisabled || !!moreOption?.isLoading}>
                  <ListItemIcon className={classes.menuItemIcon}>{moreOption?.icon}</ListItemIcon>
                  <ListItemText
                    primary={moreOption?.label}
                    primaryTypographyProps={{
                      variant: "body2"
                    }}
                  />
                  {!!moreOption?.isLoading && (
                    <ListItemSecondaryAction>
                      <CircularProgress size={18} color="inherit" className={classes.loader} />
                    </ListItemSecondaryAction>
                  )}
                  {!!moreOption?.nestedComponent && (
                    <ListItemSecondaryAction>
                      <ChevronRightIcon fontSize="small" />
                    </ListItemSecondaryAction>
                  )}
                </MenuItem>
              </span>
            </Tooltip>
          ))}
      </Menu>
    </>
  );
};

export default MoreOptions;
