import React from "react";

// Packages
import { Routes, Route } from "react-router-dom";

// Components
import ComponentNotFound from "components/Errors/ComponentNotFound";
import {
  ViewDataRoutes,
  StandardRecipeDataContainer,
  CodeRecipeDataContainer,
  AutoMLRecipeDataContainer,
  Charts,
  ArtifactDetails,
  ModelDetails
} from "src/pages";
import ApiConnectorRecipeDataContainer from "src/pages/Projects/AddApiConnectorRecipe/ApiConnectorRecipeDataContainer";

import Jobs from "./Jobs";
import { JobWrapper, JobRunCanvas, JobCanvasCompare, JobRunLogsPage, JobRuns } from "./components";

// Styles
import NewThemeWrapper from "src/styles/NewThemeWrapper";

// Contexts
import JobsContextProvider from "./context/JobsContextProvider";
import { WebPaths } from "src/routing/routes";

const JobRoutes = () => {
  return (
    <JobsContextProvider>
      <Routes>
        <Route
          index
          element={
            <NewThemeWrapper>
              <Jobs />
            </NewThemeWrapper>
          }
        />
        <Route
          path={WebPaths.CreateJob}
          element={
            <NewThemeWrapper>
              <JobWrapper />
            </NewThemeWrapper>
          }
        />
        <Route
          path={WebPaths.JobId}
          element={
            <NewThemeWrapper>
              <JobWrapper />
            </NewThemeWrapper>
          }
        />
        <Route
          path={WebPaths.JobCompare}
          element={
            <NewThemeWrapper>
              <JobCanvasCompare />
            </NewThemeWrapper>
          }
        />
        <Route
          path={WebPaths.JobRuns}
          element={
            <NewThemeWrapper>
              <JobRuns />
            </NewThemeWrapper>
          }
        />
        <Route
          path={WebPaths.JobCanvas}
          element={
            <NewThemeWrapper>
              <JobRunCanvas />
            </NewThemeWrapper>
          }
        />
        <Route
          path={WebPaths.RunIdLogs}
          element={
            <NewThemeWrapper>
              <JobRunLogsPage />
            </NewThemeWrapper>
          }
        />
        <Route
          path={WebPaths.JobDataRoutes}
          element={
            <ViewDataRoutes
              jobProps={{
                path: "projects/:projectId/jobs/:jobId/scenario/:scenarioId/job-runs/:jobRunId/entity/:entityId"
              }}
            />
          }
        />
        <Route path={WebPaths.JobArtifacts} element={<ArtifactDetails />} />
        <Route path={WebPaths.JobModels} element={<ModelDetails />} />
        <Route path={WebPaths.JobChart} element={<Charts />} />
        <Route path={WebPaths.JobStandardRecipe} element={<StandardRecipeDataContainer />} />
        <Route path={WebPaths.JobCodeRecipe} element={<CodeRecipeDataContainer />} />
        <Route path={WebPaths.JobAutoMLRecipe} element={<AutoMLRecipeDataContainer />} />
        <Route
          path={WebPaths.JobAPIConnectorRecipe}
          element={<ApiConnectorRecipeDataContainer />}
        />

        <Route path="*" element={<ComponentNotFound />} />
      </Routes>
    </JobsContextProvider>
  );
};

export default JobRoutes;
