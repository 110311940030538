import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { putAPIWithRethrow } from "src/utils/apiService";
import { IProjectReturn } from "./useRunDetails";

const useUpdateProjectRun = (): UseMutationResult<unknown, unknown, IProjectReturn, unknown> => {
  return useMutation({
    mutationFn: async (body) => {
      return await putAPIWithRethrow("/v2/project-runs", body);
    }
  });
};

export default useUpdateProjectRun;
