import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "utils/AxiosClient";
import { AIChatRequestDto } from "openapi/Models/aichat-request-dto";
import { useAIGuideStore } from "src/store/store";
import { AI_GUIDE_MESSAGES_KEY } from "./useGetAIGuideMessages";
import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";

interface IRequestDto extends AIChatRequestDto {
  controller?: any;
}

type IProps = { request: IRequestDto; threadId: string; inputNames?: string[] };

export const useSaveAIGuideChatMutation = () => {
  const [setGeneratingState, setIsGenerated] = useAIGuideStore((state) => [
    state.setGeneratingState,
    state.setIsGenerated
  ]);
  const queryClient = useQueryClient();
  return useMutation<any, unknown, IProps, IRequestDto>({
    mutationFn: async ({ threadId, request }: { request: IRequestDto; threadId: string }) => {
      const { controller, ...restProps } = request;
      const { query } = restProps;
      const currTime = Date.now();
      const currTimestamp = new Date(currTime).toISOString();
      setGeneratingState(threadId, query!, currTimestamp);
      const response = await api.fetchResponse(() =>
        api.AiControllerApi.chat2(
          threadId,
          restProps,
          controller
            ? {
                signal: controller.signal
              }
            : {}
        )
      );
      if (controller?.signal?.aborted) {
        setIsGenerated(threadId);
        return;
      }
      queryClient.setQueryData(
        [AI_GUIDE_MESSAGES_KEY, threadId],
        (prevData: AIChatResponseDto[] | undefined) => [
          ...(prevData || []),
          { ...response, isExpanded: true }
        ]
      );
      return response;
    },
    onSuccess: (_data, variables) => {
      const {
        threadId,
        request: { controller }
      } = variables;
      setIsGenerated(threadId);
      if (controller?.signal?.aborted) {
        return;
      }
    },
    onError: (_data, variables) => {
      const {
        threadId,
        request: { controller }
      } = variables;
      setIsGenerated(threadId);
      if (controller?.signal?.aborted) {
        return;
      }
    }
  });
};
