import React, { useEffect, useRef } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment, ListSubheader, TextField } from "@material-ui/core";

interface IProps {
  value: string;
  onChange: (value: string) => void;
}

const SelectSearch: React.FC<IProps> = (props) => {
  const { value, onChange } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <ListSubheader
      style={{ position: "sticky", top: "0px", paddingTop: "8px", background: "#fff" }}>
      <TextField
        size="small"
        placeholder="Type to search..."
        fullWidth
        value={value}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          inputRef
        }}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key !== "Escape") {
            e.stopPropagation();
          }
        }}
      />
    </ListSubheader>
  );
};

export default SelectSearch;
