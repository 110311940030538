import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  FormHelperText,
  Tooltip,
  MenuItemProps
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    margin: 0,
    background: "#fff"
  },
  formControlSmall: {
    // TODO: below code makes label overlap if label is not hidden
    "& .MuiSelect-root": {
      padding: 0,
      paddingBottom: 7,
      fontSize: 14
    }
  },
  select: {
    "& .MuiSelect-root": {
      display: "flex",
      alignItems: "center"
    },
    "& .MuiSelect-outlined": {
      backgroundColor: "#ffffff"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D8D8D8",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D8D8D8"
    }
  },
  selectMenu: {
    "& .Mui-selected": {
      color: theme.palette.primary.main,
      backgroundColor: "#F2F9FF"
    }
  },
  fixedMenuHeight: {
    maxHeight: 250
  },
  menuItem: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
      backgroundColor: "none"
    }
  }
}));

interface SelectProps extends MuiSelectProps {
  values: {
    label: string | number;
    value: string;
    disabled?: boolean;
    helpText?: string;
    rightComponent?: React.ReactNode;
  }[];
  hideInputLabel?: boolean;
  size?: string;
  fixedMenuHeight?: boolean;
  helperText?: string;
  menuProps?: any;
}

const Select = ({
  label,
  value,
  variant = "filled",
  size = "medium",
  values,
  onChange,
  fullWidth,
  margin = "dense",
  inputProps,
  placeholder,
  hideInputLabel = false,
  fixedMenuHeight = false,
  required,
  helperText,
  menuProps = {},
  ...restProps
}: SelectProps) => {
  const classes = useStyles();

  return (
    <FormControl
      fullWidth={fullWidth}
      variant={variant}
      className={clsx(classes.formControl, {
        [classes.formControlSmall]: size === "small"
      })}
      required={required}
      margin={margin}>
      {!hideInputLabel && (
        <InputLabel htmlFor="mutiple-select" id="select-label">
          {label}
        </InputLabel>
      )}
      <MuiSelect
        labelId="select-label"
        variant={variant}
        value={value}
        required={required}
        label={!hideInputLabel ? label : ""}
        placeholder={placeholder}
        onChange={(event: any, child: any) => {
          if (child.props.disabled) {
            return;
          }
          onChange?.(event, child);
        }}
        className={classes.select}
        inputProps={{
          name: "mutiple-select",
          id: "mutiple-select",
          ...inputProps
        }}
        MenuProps={{
          classes: {
            paper: clsx(classes.selectMenu, {
              [classes.fixedMenuHeight]: fixedMenuHeight
            })
          },
          MenuListProps: { disablePadding: true },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null,
          ...menuProps
        }}
        {...restProps}>
        {values.map(({ label, helpText, value: listVal, disabled, rightComponent }) => (
          <MenuItemWithTooltip
            key={listVal}
            id={listVal}
            helpText={helpText || ""}
            value={listVal}
            disabled={disabled}
            className={classes.menuItem}>
            {label} {rightComponent}
          </MenuItemWithTooltip>
        ))}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

interface MenuItemWithTooltipProps extends MenuItemProps {
  helpText: string;
}
const MenuItemWithTooltip = React.forwardRef((props: MenuItemWithTooltipProps, ref) => {
  const { children, helpText, ...restProps } = props;
  return (
    <Tooltip title={helpText || ""} placement="bottom-start">
      <MenuItem {...restProps} innerRef={ref} button>
        {children}
      </MenuItem>
    </Tooltip>
  );
});
MenuItemWithTooltip.displayName = "MenuItemWithTooltip";

export default Select;
