import React, { useCallback, useMemo } from "react";
import { Grid, IconButton, Typography, makeStyles, Tooltip } from "@material-ui/core";
import { useLocation } from "react-router";
import { Add } from "@material-ui/icons";

import { CollapseIcon } from "src/icons/CollapseIcon";
import { Entities, EntityFeaturesResponse } from "src/types";
import InputDatasetsView from "../../common/InputDatasetsView";
import { READONLY_ENTITY } from "src/constants";

const useStyles = makeStyles({
  codeRecipeHeader: {
    padding: "0px 16px",
    height: "44px",
    flexDirection: "row",
    flexWrap: "nowrap",
    background: "#d9d9d933",
    borderRadius: "11px 11px 0px 0px"
  },
  inputContainerWrap: {
    height: "100%",
    maxHeight: "100%",
    flexWrap: "nowrap"
  },
  inputWrap: {
    height: "auto",
    flexWrap: "nowrap",
    overflow: "auto",
    borderRadius: "0px 0px 11px 11px",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#003656",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },
  btnContainer: {
    flexWrap: "nowrap",
    gap: "8px",
    flex: 0
  },
  iconBtn: {
    padding: "4px"
  }
});

const CodeRecipeInputContainer = ({
  openAddEntitiesDialog,
  onCollapse,
  handleInputDatasetsChange,
  entityFeaturesMap,
  inputDatasets,
  isDefaultScenario
}: {
  openAddEntitiesDialog: () => void;
  onCollapse: () => void;
  inputDatasets: Entities;
  entityFeaturesMap: {
    [id: string]: EntityFeaturesResponse;
  } | null;
  isDefaultScenario: boolean;
  handleInputDatasetsChange: (entities: Entities) => void;
}) => {
  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const classes = useStyles();

  const onDeleteDataset = useCallback(
    (datasetId: string) => {
      handleInputDatasetsChange(inputDatasets.filter((dataset) => dataset.id !== datasetId));
    },
    [handleInputDatasetsChange, inputDatasets]
  );

  return (
    <Grid container direction="column" className={classes.inputContainerWrap}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.codeRecipeHeader}>
        <Typography variant="subtitle1">Inputs</Typography>
        <Grid container direction="row" className={classes.btnContainer}>
          {!isJobPath && (
            <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
              <div>
                <IconButton
                  className={classes.iconBtn}
                  test-id="codeRecipeHeaderAddEntitiesBtn"
                  disabled={!isDefaultScenario}
                  onClick={openAddEntitiesDialog}>
                  <Add />
                </IconButton>
              </div>
            </Tooltip>
          )}
          <IconButton onClick={onCollapse} size="small">
            <CollapseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.inputWrap}>
        <InputDatasetsView
          isDefaultScenario={isDefaultScenario}
          inputDatasets={inputDatasets}
          onDeleteDataset={onDeleteDataset}
          entityFeaturesMap={entityFeaturesMap}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(CodeRecipeInputContainer);
