/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AuthenticationResponseDto
 */
export interface AuthenticationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseDto
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseDto
     */
    'userEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseDto
     */
    'ghFullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseDto
     */
    'ghEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseDto
     */
    'notebookId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseDto
     */
    'tenantName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseDto
     */
    'notebookType'?: AuthenticationResponseDtoNotebookTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationResponseDto
     */
    'cpuGuarantee'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationResponseDto
     */
    'memGuarantee'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationResponseDto
     */
    'dataappsTtlInHrs'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationResponseDto
     */
    'expiresAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationResponseDto
     */
    'eulaAccepted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseDto
     */
    'roleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseDto
     */
    'roleName'?: string;
}

export const AuthenticationResponseDtoNotebookTypeEnum = {
    Tenant: 'TENANT',
    User: 'USER',
    Both: 'BOTH'
} as const;

export type AuthenticationResponseDtoNotebookTypeEnum = typeof AuthenticationResponseDtoNotebookTypeEnum[keyof typeof AuthenticationResponseDtoNotebookTypeEnum];


