import React, { useEffect } from "react";
import MonacoEditor from "react-monaco-editor";
import { editor } from "monaco-editor";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import "./CodeRecipeCodeEditor.css";

export const CodeRecipeCodeEditor = ({
  editorValue,
  setEditorValue,
  insertedCode,
  resetInsertedCode,
  disabled,
  errDetails
}: $TSFixMe) => {
  const editorRef: $TSFixMe = React.useRef(null);
  const [decorationIds, setDecorationIds] = React.useState([]);

  const onInsertCode = (code: string) => {
    const editor = editorRef.current;
    const model = editor.getModel();
    if (model && !model.isDisposed()) {
      const currentPosition = editor.getPosition();
      const lineNumber = currentPosition.lineNumber;
      const column = currentPosition.column;

      const shouldIndentFirstLine = column <= 5;
      const columnPosition = column < 5 ? 4 : column;
      const position = {
        lineNumber,
        column
      };

      const indentSpaces = "    "; // Add Four spaces for indentation
      const indentCodeSkipFirst = code.replace(/\n/g, "\n" + indentSpaces);
      const indentedCode = `${
        shouldIndentFirstLine ? " ".repeat(5 - column) : ""
      }${indentCodeSkipFirst}`;

      const op = {
        range: new monaco.Range(lineNumber, columnPosition, lineNumber, columnPosition),
        text: indentedCode + "\n",
        forceMoveMarkers: true
      };

      model.pushEditOperations([], [op], () => []);
      editor.setPosition(position);
      resetInsertedCode?.();
    }
  };

  useEffect(() => {
    insertedCode && onInsertCode?.(insertedCode);
  }, [insertedCode]);

  const handleEditorDidMount = (editor: editor.IStandaloneCodeEditor) => {
    editorRef.current = editor;
  };

  React.useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      //clear existing decorations
      decorationIds?.length && editor.deltaDecorations(decorationIds, []);
    }
    if (editor && errDetails && !(errDetails.lineNo === 0 && !errDetails.line)) {
      const lineNumber = errDetails.lineNo;
      const hoverMessage = errDetails.line;
      const currDecorationIds = editor.deltaDecorations(
        [],
        [
          {
            range: new monaco.Range(lineNumber, 1, lineNumber, 1),
            options: {
              isWholeLine: true,
              className: "my-line-highlight",
              hoverMessage: { value: hoverMessage }
            }
          }
        ]
      );
      setDecorationIds(currDecorationIds);
    }
  }, [errDetails, errDetails?.line, errDetails?.lineNo]);

  return (
    <MonacoEditor
      key="code-recipe-code-editor"
      editorDidMount={handleEditorDidMount}
      height="100%"
      value={editorValue}
      width="100%"
      language="python"
      onChange={(value) => setEditorValue(value)}
      theme="vs"
      options={{
        readOnly: disabled,
        theme: "vs",
        minimap: { enabled: false },
        renderLineHighlight: "none",
        scrollbar: {
          vertical: "hidden",
          horizontal: "hidden"
        },
        overviewRulerLanes: 0,
        padding: {
          top: 16
        }
      }}
    />
  );
};
