import React, { useMemo, useState } from "react";
import _ from "lodash";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  makeStyles
} from "@material-ui/core";

import Text from "components/Widget/Text";
import { CONNECTOR_KEYS } from "../utils/OutputDataset.constants";
import { IConnectorType } from "./DestinationDetails";
import SelectSearch from "src/components/Inputs/SelectSearch";

interface IProps extends SelectProps {
  connectors: IConnectorType[];
}

const useStyles = makeStyles(() => ({
  sourceImage: {
    display: "inline-block",
    width: 20,
    height: 20,
    marginRight: 10
  },
  paper: {
    maxHeight: "400px",
    overflowY: "auto",
    overflowX: "hidden"
  }
}));

const SelectConnectorType: React.FC<IProps> = (props) => {
  const { connectors, ...restProps } = props;

  const [value, setValue] = useState("");
  const { sourceImage, paper } = useStyles();

  const options = useMemo(() => {
    const filtered = _.filter(connectors, ({ name }) =>
      _.includes(_.lowerCase(name), _.lowerCase(value))
    );

    return _.map(filtered, ({ id, name, image }) => (
      <MenuItem id={id} key={id} value={id}>
        <span className={sourceImage}>{image}</span>
        <Text value={name} />
      </MenuItem>
    ));
  }, [connectors, value]);

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel test-id="connector-label">Data Connector *</InputLabel>
      <Select
        id="data-connector-select"
        label="Data Connector *"
        test-id="data-connector-select"
        name={CONNECTOR_KEYS.connectorType}
        required
        MenuProps={{ classes: { paper } }}
        onClose={() => setValue("")}
        {...restProps}>
        <SelectSearch value={value} onChange={setValue} />
        {options}
      </Select>
    </FormControl>
  );
};

export default SelectConnectorType;
