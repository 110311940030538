/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Context } from './context';
// May contain unused imports in some cases
// @ts-ignore
import { InputEntityDto } from './input-entity-dto';
// May contain unused imports in some cases
// @ts-ignore
import { TargetDto } from './target-dto';

/**
 * 
 * @export
 * @interface CreateAIThreadRequestDto
 */
export interface CreateAIThreadRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAIThreadRequestDto
     */
    'flowType': CreateAIThreadRequestDtoFlowTypeEnum;
    /**
     * 
     * @type {Context}
     * @memberof CreateAIThreadRequestDto
     */
    'context'?: Context;
    /**
     * 
     * @type {TargetDto}
     * @memberof CreateAIThreadRequestDto
     */
    'target'?: TargetDto;
    /**
     * 
     * @type {Array<InputEntityDto>}
     * @memberof CreateAIThreadRequestDto
     */
    'inputEntities'?: Array<InputEntityDto>;
}

export const CreateAIThreadRequestDtoFlowTypeEnum = {
    AiGuide: 'AI_GUIDE',
    Recipe: 'RECIPE',
    Dataapp: 'DATAAPP'
} as const;

export type CreateAIThreadRequestDtoFlowTypeEnum = typeof CreateAIThreadRequestDtoFlowTypeEnum[keyof typeof CreateAIThreadRequestDtoFlowTypeEnum];


