// FullScreenDialog.js
import React from "react";
import { Dialog, Slide, makeStyles } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import AIGuideThreads from "./AIGuideThreads";
import { AIGuideContextProvider, AIGuideProps } from "./useAIGuideContext";

const useStyles = makeStyles((theme) => ({
  dialog: {
    position: "fixed",
    top: 48,
    left: 0,
    width: "100%",
    height: "100%",
    margin: 0
  },
  dialogContent: {
    height: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction={"up"} ref={ref} {...props} timeout={300} />;
});

interface AIGuideDialogProps extends AIGuideProps {
  open: boolean;
  onClose: () => void;
}

const AIGuideDialog = ({ open, onClose, ...restProps }: AIGuideDialogProps) => {
  const classes = useStyles();

  const handleClose = (_event: any, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      onClose();
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      TransitionComponent={Transition}>
      <div className={classes.dialogContent}>
        <AIGuideContextProvider {...restProps} onClose={onClose}>
          <AIGuideThreads />
        </AIGuideContextProvider>
      </div>
    </Dialog>
  );
};

export default AIGuideDialog;
