import {
  getAPI,
  getAPIWithRethrow,
  postAPIWithRethrow,
  putAPIWithRethrow,
  deleteAPIWithRethrow
} from "../utils/apiService";

export const getEnvironmentsTypes = async () => await getAPI(`/v2/envs/types/all`);
export const getEnvironments = async () => await getAPIWithRethrow(`/v2/envs`);
export const getEnvironmentById = async (id: $TSFixMe) => await getAPIWithRethrow(`/v2/envs/${id}`);
export const createEnvironment = async (body: $TSFixMe) =>
  // // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
  await postAPIWithRethrow(`/v2/envs?async=true`, body);

export const updateEnvironment = async (body: $TSFixMe, id: $TSFixMe) =>
  await putAPIWithRethrow(`/v2/envs/${id}?async=true`, body);
export const deleteEnvironment = async (id?: string) =>
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  await deleteAPIWithRethrow(`/v2/envs/${id}?skipCheck=true`);
export const relaunchEnvironment = async (
  id: string,
  notify: boolean,
  body: $TSFixMe,
  shouldDispatchEvent?: boolean
) =>
  await putAPIWithRethrow(
    `/v2/envs/${id}?relaunch=true&async=true&notify=${notify}`,
    body,
    undefined,
    shouldDispatchEvent
  );
export const stopEnvironment = async (id: string) =>
  // @ts-expect-error
  await putAPIWithRethrow(`/v2/envs/stop/${id}?skipCheck=true`);
export const clearCache = async ({ id, isDeleteRecent = false }: $TSFixMe) =>
  await postAPIWithRethrow(`/v2/envs/clean-cache/${id}?deleteRecent=${isDeleteRecent}`, {});
export const getEnvironmentLogs = async ({ id, mode = "offline" }: $TSFixMe = {}) =>
  await getAPIWithRethrow(`/v2/envs/${id}/logs?mode=${mode}`, {}, false);
export const getEnvironmentUsage = async (id: $TSFixMe) => await getAPI(`/v2/envs/${id}/usage`);
export const getEnvironmentResourceUsage = async (id: $TSFixMe) =>
  await getAPI(`/v2/envs/${id}/resource-usage`);

export const createDefaultEnvironment = async () =>
  await postAPIWithRethrow(`/tenants/create-default-env`, {});
