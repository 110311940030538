import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import _ from "lodash";

import { getAPIWithRethrow } from "src/utils/apiService";

const QUERY_KEY_RECIPE_BY_ID = "query-key-recipe-by-id";

interface IReturn {
  name: string;
  id: string;
  templateId: string;
  inputEntityId: string;
  projectId: string;
  variables: {
    inputDataset1: string;
    targetCol: string;
    predictPeriod: string | number;
    modelName: string;
    dateCol: string;
    predictFrequency: string;
  };
}

const useRecipeById = (
  recipeId?: string,
  options?: UseQueryOptions<IReturn>
): UseQueryResult<IReturn> => {
  return useQuery<any>({
    queryKey: [QUERY_KEY_RECIPE_BY_ID, recipeId],
    queryFn: async () => {
      if (!recipeId) {
        throw "recipe Id is absent";
      }
      const recipeDetails = await getAPIWithRethrow(`/v2/dfs-run-configs?groupId=${recipeId}`);

      return _.head(recipeDetails);
    },
    enabled: !!recipeId,
    ...options
  });
};

export default useRecipeById;
export { QUERY_KEY_RECIPE_BY_ID };
