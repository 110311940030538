/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface TargetDto
 */
export interface TargetDto {
    /**
     * 
     * @type {string}
     * @memberof TargetDto
     */
    'targetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TargetDto
     */
    'targetType'?: TargetDtoTargetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TargetDto
     */
    'chartId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TargetDto
     */
    'chartName'?: string;
}

export const TargetDtoTargetTypeEnum = {
    Dataset: 'DATASET',
    Chart: 'CHART',
    Model: 'MODEL',
    Recipe: 'RECIPE',
    Dataapp: 'DATAAPP'
} as const;

export type TargetDtoTargetTypeEnum = typeof TargetDtoTargetTypeEnum[keyof typeof TargetDtoTargetTypeEnum];


