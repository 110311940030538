import React, { useRef, useEffect } from "react";

import { Spinner } from "src/components";
import AiGuideConvoGrid from "./AIGuideConvoGrid";
import { useSaveAIGuideChatMutation } from "src/hooks/api/aiguide/useSaveAIGuideChatMutation";

import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";
import {
  ThreadResponseDto,
  ThreadResponseDtoDatasetContextEnum,
  ThreadResponseDtoTargetTypeEnum
} from "openapi/Models/thread-response-dto";
import {
  AIChatRequestDtoOutputTypeEnum,
  AIChatRequestDtoUseCaseEnum
} from "openapi/Models/aichat-request-dto";
import { InputIdDtoTypeEnum } from "openapi/Models/input-id-dto";
import { useQueryClient } from "@tanstack/react-query";
import { AI_GUIDE_MESSAGES_KEY } from "src/hooks/api";
import { GeneratingState } from "src/store/store";
import _ from "lodash";
import { STOPPED_QUERY_ID } from "./AIGuideResponse";

export const ERRORED_QUERY_ID = "errored_query";

type IProps = {
  isLoading: boolean;
  query: string;
  setQuery: (query: string) => void;
  renderFn: (props: any) => React.ReactNode;
  suggestions?: string[];
  targetType?: keyof typeof ThreadResponseDtoTargetTypeEnum | null;
  threadId: string;
  messages: AIChatResponseDto[];
  thread: ThreadResponseDto;
  generateState: GeneratingState;
  columns?: string[];
};

const AIGuideContainer = ({
  isLoading,
  query,
  setQuery,
  renderFn,
  suggestions,
  thread,
  messages,
  generateState,
  columns
}: IProps) => {
  const { threadId, targetInputs, datasetContext, targetType } = thread;
  const saveAIGuideChatMutation = useSaveAIGuideChatMutation();
  const isStopped = useRef<boolean>(false);
  const controllerRef = useRef<AbortController | null>(null);
  const useCase =
    datasetContext === ThreadResponseDtoDatasetContextEnum.Dataset
      ? AIChatRequestDtoUseCaseEnum.ConversationUseCase
      : AIChatRequestDtoUseCaseEnum.TargetColumnUseCase;

  const isGenerating = generateState?.isGenerating || false;
  const generatingQuery = generateState?.generatingQuery || "";
  const isDisabled = generateState?.isDisabled || false;

  const inputIds =
    targetInputs?.map((target) => ({
      id: target.askAIInputId,
      type: InputIdDtoTypeEnum.InputId
    })) || [];

  const handleGenerateCode = (suggestion?: string) => {
    const newController = new AbortController();
    controllerRef.current = newController;
    saveAIGuideChatMutation.mutate(
      {
        threadId: threadId!,
        request: {
          query: suggestion || query?.trim(),
          useCase,
          inputIds,
          controller: newController,
          outputType:
            targetType === ThreadResponseDtoTargetTypeEnum.Dataset
              ? AIChatRequestDtoOutputTypeEnum.AutoInfer
              : AIChatRequestDtoOutputTypeEnum.Text
        }
      },
      {
        onSuccess: (data) => {
          if (isStopped.current || !data) {
            isStopped.current = false;
            return;
          }
        },
        onError: (error) => {
          if (isStopped.current || !error) {
            isStopped.current = false;
            return;
          }
        }
      }
    );
  };

  const queryClient = useQueryClient();

  const handleGenerateStop = () => {
    isStopped.current = true;
    controllerRef?.current?.abort();
    queryClient.setQueryData(
      [AI_GUIDE_MESSAGES_KEY, threadId!],
      (prevData: AIChatResponseDto[] | undefined) => [
        ...(prevData || []),
        {
          threadId: threadId!,
          query: generatingQuery,
          askAIMessageId: STOPPED_QUERY_ID,
          outputType: AIChatRequestDtoOutputTypeEnum.Text,
          useCase,
          error: {
            errMessage: "Query run stopped",
            errorType: "errorType"
          }
        } as any
      ]
    );
  };

  useEffect(() => {
    if (
      thread.datasetContext === ThreadResponseDtoDatasetContextEnum.RapidModelRecipe &&
      messages?.length === 0
    ) {
      handleGenerateCode("Recommend a target column");
    }
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <AiGuideConvoGrid
      isGenerating={isGenerating}
      isDisabled={isDisabled}
      query={query}
      setQuery={setQuery}
      generatingQuery={generatingQuery}
      messages={messages}
      inputIds={inputIds}
      handleGenerateCode={handleGenerateCode}
      handleGenerateStop={handleGenerateStop}
      columns={columns}
      suggestions={suggestions}>
      {renderFn({ hasMessages: messages?.length !== 0 })}
    </AiGuideConvoGrid>
  );
};

export default React.memo(AIGuideContainer);
