/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { IntermediateDataset } from './intermediate-dataset';
// May contain unused imports in some cases
// @ts-ignore
import { ThreadTargetInput } from './thread-target-input';

/**
 * 
 * @export
 * @interface ThreadResponseDto
 */
export interface ThreadResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ThreadResponseDto
     */
    'flowType'?: ThreadResponseDtoFlowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ThreadResponseDto
     */
    'targetType'?: ThreadResponseDtoTargetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ThreadResponseDto
     */
    'datasetContext'?: ThreadResponseDtoDatasetContextEnum;
    /**
     * 
     * @type {string}
     * @memberof ThreadResponseDto
     */
    'threadId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadResponseDto
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadResponseDto
     */
    'scenarioId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadResponseDto
     */
    'stepId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadResponseDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadResponseDto
     */
    'askAIThreadId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ThreadResponseDto
     */
    'created'?: number;
    /**
     * 
     * @type {number}
     * @memberof ThreadResponseDto
     */
    'updated'?: number;
    /**
     * 
     * @type {Array<ThreadTargetInput>}
     * @memberof ThreadResponseDto
     */
    'targetInputs'?: Array<ThreadTargetInput>;
    /**
     * 
     * @type {Set<IntermediateDataset>}
     * @memberof ThreadResponseDto
     */
    'intermediateDatasets'?: Set<IntermediateDataset>;
}

export const ThreadResponseDtoFlowTypeEnum = {
    AiGuide: 'AI_GUIDE',
    Recipe: 'RECIPE',
    Dataapp: 'DATAAPP'
} as const;

export type ThreadResponseDtoFlowTypeEnum = typeof ThreadResponseDtoFlowTypeEnum[keyof typeof ThreadResponseDtoFlowTypeEnum];
export const ThreadResponseDtoTargetTypeEnum = {
    Dataset: 'DATASET',
    Chart: 'CHART',
    Model: 'MODEL',
    Recipe: 'RECIPE',
    Dataapp: 'DATAAPP'
} as const;

export type ThreadResponseDtoTargetTypeEnum = typeof ThreadResponseDtoTargetTypeEnum[keyof typeof ThreadResponseDtoTargetTypeEnum];
export const ThreadResponseDtoDatasetContextEnum = {
    Dataset: 'DATASET',
    RapidModelRecipe: 'RAPID_MODEL_RECIPE'
} as const;

export type ThreadResponseDtoDatasetContextEnum = typeof ThreadResponseDtoDatasetContextEnum[keyof typeof ThreadResponseDtoDatasetContextEnum];


