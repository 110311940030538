import React, { useMemo } from "react";
import _ from "lodash";
import shallow from "zustand/shallow";
import { Grid, Box, Typography, makeStyles } from "@material-ui/core";

import ModalTenantSwitch from "../MyTenantsSection/ModalTenantSwitch";
import SearchField from "src/components/Inputs/SearchField";
import useAuthStore from "src/stores/auth.store";
import useNotificationStore from "src/stores/notification.store";
import useTenantManagementStore from "src/stores/tenant-management.store";
import { Table, Spinner } from "src/components";
import { formTableCells } from "src/components/Table/TableUtils";
import { getTenantColumns } from "./TenantsColumns";
import { useFetchTenantActions } from "./useFetchTenantActions";
import { postAPI } from "src/utils/apiService";
import { useProjectsStore } from "src/store/store";
import { shouldRefreshProjectsSetter } from "src/store/store.selectors";
import { useAuthSetAuthResponse } from "src/hooks/useAuthSetAuthResponse";
import { logNotebooksOut } from "src/utils/operateNotebookService";
import { WebPaths } from "src/routing/routes";

const TABLE_SIZE = "small";

const useStyles = makeStyles({
  // $FixMe: Scope to be refactored.
  // The below styles are added only to support New UX. It shall be removed soon.
  searchField: {
    "& [class^='MuiInputBase-root']": {
      background: "none",
      border: "1px solid #ccc",
      borderRadius: 4
    },
    "& input": {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: "small"
    }
  }
});

const TenantsData = () => {
  const classes = useStyles();

  const [openSwitchTenantModal, setOpenSwitchTenantModal] = React.useState<Boolean>(false);
  const [value, setValue] = React.useState("");
  const [currentTenantId] = useAuthStore((state) => [state.tenantId], shallow);
  const tenants = useTenantManagementStore((state) => state.userTenants, shallow);
  const { switchingTenantId, switchTenant, leaveTenantAndSwitch } = useFetchTenantActions();
  const [setNotification] = useNotificationStore(
    (state: any) => [state.setNotification, state.notifications],
    shallow
  );
  const setShouldRefreshProjects = useProjectsStore(shouldRefreshProjectsSetter);

  const { setAuthResponse } = useAuthSetAuthResponse();

  const handleTenantsSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const joinNewTenant = (tenantId: string) => {
    postAPI("/invitation/accept", { invitationDetails: "", tenantId }).then((data) => {
      if (data) {
        setNotification({
          type: "Dashboard",
          message: `You are added to "${data.tenantName}" tenant successfully`
        });
        setAuthResponse({
          data,
          rememberMe: true
        });
        setShouldRefreshProjects(true);
        logNotebooksOut();
        window.location.href = WebPaths.Dashboard;
        window.history.pushState(null, "", WebPaths.Dashboard);
      }
    });
  };

  const tenantColumns = getTenantColumns({
    currentTenantId,
    switchingTenantId,
    switchTenant,
    showLeaveAndSwitchTenantModal: () => setOpenSwitchTenantModal(true),
    joinNewTenant,
    // No other tenant to switch to
    canLeaveAndSwitchTenant:
      tenants?.filter((tenant: $TSFixMe) => tenant?.invitationStatus === "ACTIVE")?.length !== 1
  });
  const columns = formTableCells({ inputColumns: tenantColumns, size: TABLE_SIZE });

  const validTenants = useMemo(() => _.filter(tenants, { hasValidLicense: true }), [tenants]);

  const data = useMemo(() => {
    const sorted = tenants?.sort(
      (tenant1: $TSFixMe, tenant2: $TSFixMe) => tenant1?.hasValidLicense - tenant2?.hasValidLicense
    );

    return _.filter(sorted, ({ name }) => _.includes(_.toLower(name), _.toLower(value)));
  }, [value, tenants]);

  if (tenants.length === 0) {
    return <Spinner />;
  }

  return (
    <Grid container direction="column">
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography color="textPrimary">
          <Box fontWeight="500">Tenants</Box>
        </Typography>
        <SearchField
          placeholder="Search Tenants"
          value={value}
          onChange={handleTenantsSearch}
          className={classes.searchField}
        />
      </Grid>
      <Box pt="20px" width="inherit">
        <Table
          data={data}
          columns={columns}
          isCellSortEnabled
          size={TABLE_SIZE}
          maxHeight="calc(100vh - 218px)"
        />
      </Box>
      <ModalTenantSwitch
        visible={openSwitchTenantModal}
        tenants={validTenants}
        handleSwitchAndDelete={leaveTenantAndSwitch}
        onClose={setOpenSwitchTenantModal}
      />
    </Grid>
  );
};

export default TenantsData;
