/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AIChatRequestDto } from '../Models';
// @ts-ignore
import { AIChatResponseDto } from '../Models';
// @ts-ignore
import { AddInputsToThreadRequestDto } from '../Models';
// @ts-ignore
import { AddOutputRequestDto } from '../Models';
// @ts-ignore
import { CreateAIThreadRequestDto } from '../Models';
// @ts-ignore
import { ErrorMessage } from '../Models';
// @ts-ignore
import { MessageFeedbackRequestDto } from '../Models';
// @ts-ignore
import { RemoveInputsFromThreadsRequestDto } from '../Models';
// @ts-ignore
import { RemoveOutputRequestDto } from '../Models';
// @ts-ignore
import { ThreadResponseDto } from '../Models';
/**
 * AiControllerApi - axios parameter creator
 * @export
 */
export const AiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {MessageFeedbackRequestDto} messageFeedbackRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeedback: async (threadId: string, messageId: string, messageFeedbackRequestDto: MessageFeedbackRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('addFeedback', 'threadId', threadId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('addFeedback', 'messageId', messageId)
            // verify required parameter 'messageFeedbackRequestDto' is not null or undefined
            assertParamExists('addFeedback', 'messageFeedbackRequestDto', messageFeedbackRequestDto)
            const localVarPath = `/ai/threads/{threadId}/messages/{messageId}/feedback`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageFeedbackRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {AddInputsToThreadRequestDto} addInputsToThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInputsToThread: async (threadId: string, addInputsToThreadRequestDto: AddInputsToThreadRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('addInputsToThread', 'threadId', threadId)
            // verify required parameter 'addInputsToThreadRequestDto' is not null or undefined
            assertParamExists('addInputsToThread', 'addInputsToThreadRequestDto', addInputsToThreadRequestDto)
            const localVarPath = `/ai/threads/{threadId}/inputs`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addInputsToThreadRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {AddOutputRequestDto} addOutputRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOutput: async (threadId: string, messageId: string, addOutputRequestDto: AddOutputRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('addOutput', 'threadId', threadId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('addOutput', 'messageId', messageId)
            // verify required parameter 'addOutputRequestDto' is not null or undefined
            assertParamExists('addOutput', 'addOutputRequestDto', addOutputRequestDto)
            const localVarPath = `/ai/threads/{threadId}/messages/{messageId}/add`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOutputRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {AIChatRequestDto} aIChatRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chat2: async (threadId: string, aIChatRequestDto: AIChatRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('chat2', 'threadId', threadId)
            // verify required parameter 'aIChatRequestDto' is not null or undefined
            assertParamExists('chat2', 'aIChatRequestDto', aIChatRequestDto)
            const localVarPath = `/ai/threads/{threadId}/chat`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aIChatRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAIThreadRequestDto} createAIThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThread1: async (createAIThreadRequestDto: CreateAIThreadRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAIThreadRequestDto' is not null or undefined
            assertParamExists('createThread1', 'createAIThreadRequestDto', createAIThreadRequestDto)
            const localVarPath = `/ai/threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAIThreadRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage2: async (threadId: string, messageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('deleteMessage2', 'threadId', threadId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('deleteMessage2', 'messageId', messageId)
            const localVarPath = `/ai/threads/{threadId}/messages/{messageId}`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessages: async (threadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('deleteMessages', 'threadId', threadId)
            const localVarPath = `/ai/threads/{threadId}/messages`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThread1: async (threadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('deleteThread1', 'threadId', threadId)
            const localVarPath = `/ai/threads/{threadId}`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages3: async (threadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('getMessages3', 'threadId', threadId)
            const localVarPath = `/ai/threads/{threadId}/messages`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreads2: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getThreads2', 'projectId', projectId)
            const localVarPath = `/ai/threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {RemoveInputsFromThreadsRequestDto} removeInputsFromThreadsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeInputsFromThread: async (threadId: string, removeInputsFromThreadsRequestDto: RemoveInputsFromThreadsRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('removeInputsFromThread', 'threadId', threadId)
            // verify required parameter 'removeInputsFromThreadsRequestDto' is not null or undefined
            assertParamExists('removeInputsFromThread', 'removeInputsFromThreadsRequestDto', removeInputsFromThreadsRequestDto)
            const localVarPath = `/ai/threads/{threadId}/inputs`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeInputsFromThreadsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {RemoveOutputRequestDto} removeOutputRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOutput: async (threadId: string, messageId: string, removeOutputRequestDto: RemoveOutputRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('removeOutput', 'threadId', threadId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('removeOutput', 'messageId', messageId)
            // verify required parameter 'removeOutputRequestDto' is not null or undefined
            assertParamExists('removeOutput', 'removeOutputRequestDto', removeOutputRequestDto)
            const localVarPath = `/ai/threads/{threadId}/messages/{messageId}/remove`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeOutputRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiControllerApi - functional programming interface
 * @export
 */
export const AiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {MessageFeedbackRequestDto} messageFeedbackRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFeedback(threadId: string, messageId: string, messageFeedbackRequestDto: MessageFeedbackRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIChatResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFeedback(threadId, messageId, messageFeedbackRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiControllerApi.addFeedback']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {AddInputsToThreadRequestDto} addInputsToThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInputsToThread(threadId: string, addInputsToThreadRequestDto: AddInputsToThreadRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInputsToThread(threadId, addInputsToThreadRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiControllerApi.addInputsToThread']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {AddOutputRequestDto} addOutputRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOutput(threadId: string, messageId: string, addOutputRequestDto: AddOutputRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIChatResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOutput(threadId, messageId, addOutputRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiControllerApi.addOutput']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {AIChatRequestDto} aIChatRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chat2(threadId: string, aIChatRequestDto: AIChatRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIChatResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chat2(threadId, aIChatRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiControllerApi.chat2']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CreateAIThreadRequestDto} createAIThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createThread1(createAIThreadRequestDto: CreateAIThreadRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createThread1(createAIThreadRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiControllerApi.createThread1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMessage2(threadId: string, messageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMessage2(threadId, messageId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiControllerApi.deleteMessage2']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMessages(threadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMessages(threadId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiControllerApi.deleteMessages']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteThread1(threadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteThread1(threadId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiControllerApi.deleteThread1']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages3(threadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AIChatResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessages3(threadId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiControllerApi.getMessages3']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThreads2(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThreadResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThreads2(projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiControllerApi.getThreads2']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {RemoveInputsFromThreadsRequestDto} removeInputsFromThreadsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeInputsFromThread(threadId: string, removeInputsFromThreadsRequestDto: RemoveInputsFromThreadsRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeInputsFromThread(threadId, removeInputsFromThreadsRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiControllerApi.removeInputsFromThread']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {RemoveOutputRequestDto} removeOutputRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOutput(threadId: string, messageId: string, removeOutputRequestDto: RemoveOutputRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIChatResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeOutput(threadId, messageId, removeOutputRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AiControllerApi.removeOutput']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AiControllerApi - factory interface
 * @export
 */
export const AiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {MessageFeedbackRequestDto} messageFeedbackRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeedback(threadId: string, messageId: string, messageFeedbackRequestDto: MessageFeedbackRequestDto, options?: any): AxiosPromise<AIChatResponseDto> {
            return localVarFp.addFeedback(threadId, messageId, messageFeedbackRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {AddInputsToThreadRequestDto} addInputsToThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInputsToThread(threadId: string, addInputsToThreadRequestDto: AddInputsToThreadRequestDto, options?: any): AxiosPromise<ThreadResponseDto> {
            return localVarFp.addInputsToThread(threadId, addInputsToThreadRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {AddOutputRequestDto} addOutputRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOutput(threadId: string, messageId: string, addOutputRequestDto: AddOutputRequestDto, options?: any): AxiosPromise<AIChatResponseDto> {
            return localVarFp.addOutput(threadId, messageId, addOutputRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {AIChatRequestDto} aIChatRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chat2(threadId: string, aIChatRequestDto: AIChatRequestDto, options?: any): AxiosPromise<AIChatResponseDto> {
            return localVarFp.chat2(threadId, aIChatRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAIThreadRequestDto} createAIThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThread1(createAIThreadRequestDto: CreateAIThreadRequestDto, options?: any): AxiosPromise<ThreadResponseDto> {
            return localVarFp.createThread1(createAIThreadRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage2(threadId: string, messageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMessage2(threadId, messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessages(threadId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMessages(threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThread1(threadId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteThread1(threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages3(threadId: string, options?: any): AxiosPromise<Array<AIChatResponseDto>> {
            return localVarFp.getMessages3(threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreads2(projectId: string, options?: any): AxiosPromise<Array<ThreadResponseDto>> {
            return localVarFp.getThreads2(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {RemoveInputsFromThreadsRequestDto} removeInputsFromThreadsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeInputsFromThread(threadId: string, removeInputsFromThreadsRequestDto: RemoveInputsFromThreadsRequestDto, options?: any): AxiosPromise<ThreadResponseDto> {
            return localVarFp.removeInputsFromThread(threadId, removeInputsFromThreadsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {RemoveOutputRequestDto} removeOutputRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOutput(threadId: string, messageId: string, removeOutputRequestDto: RemoveOutputRequestDto, options?: any): AxiosPromise<AIChatResponseDto> {
            return localVarFp.removeOutput(threadId, messageId, removeOutputRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiControllerApi - interface
 * @export
 * @interface AiControllerApi
 */
export interface AiControllerApiInterface {
    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {MessageFeedbackRequestDto} messageFeedbackRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    addFeedback(threadId: string, messageId: string, messageFeedbackRequestDto: MessageFeedbackRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<AIChatResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {AddInputsToThreadRequestDto} addInputsToThreadRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    addInputsToThread(threadId: string, addInputsToThreadRequestDto: AddInputsToThreadRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ThreadResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {AddOutputRequestDto} addOutputRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    addOutput(threadId: string, messageId: string, addOutputRequestDto: AddOutputRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<AIChatResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {AIChatRequestDto} aIChatRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    chat2(threadId: string, aIChatRequestDto: AIChatRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<AIChatResponseDto>;

    /**
     * 
     * @param {CreateAIThreadRequestDto} createAIThreadRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    createThread1(createAIThreadRequestDto: CreateAIThreadRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ThreadResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    deleteMessage2(threadId: string, messageId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    deleteMessages(threadId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    deleteThread1(threadId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    getMessages3(threadId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AIChatResponseDto>>;

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    getThreads2(projectId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ThreadResponseDto>>;

    /**
     * 
     * @param {string} threadId 
     * @param {RemoveInputsFromThreadsRequestDto} removeInputsFromThreadsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    removeInputsFromThread(threadId: string, removeInputsFromThreadsRequestDto: RemoveInputsFromThreadsRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ThreadResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {RemoveOutputRequestDto} removeOutputRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    removeOutput(threadId: string, messageId: string, removeOutputRequestDto: RemoveOutputRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<AIChatResponseDto>;

}

/**
 * AiControllerApi - object-oriented interface
 * @export
 * @class AiControllerApi
 * @extends {BaseAPI}
 */
export class AiControllerApi extends BaseAPI implements AiControllerApiInterface {
    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {MessageFeedbackRequestDto} messageFeedbackRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public addFeedback(threadId: string, messageId: string, messageFeedbackRequestDto: MessageFeedbackRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).addFeedback(threadId, messageId, messageFeedbackRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {AddInputsToThreadRequestDto} addInputsToThreadRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public addInputsToThread(threadId: string, addInputsToThreadRequestDto: AddInputsToThreadRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).addInputsToThread(threadId, addInputsToThreadRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {AddOutputRequestDto} addOutputRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public addOutput(threadId: string, messageId: string, addOutputRequestDto: AddOutputRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).addOutput(threadId, messageId, addOutputRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {AIChatRequestDto} aIChatRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public chat2(threadId: string, aIChatRequestDto: AIChatRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).chat2(threadId, aIChatRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAIThreadRequestDto} createAIThreadRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public createThread1(createAIThreadRequestDto: CreateAIThreadRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).createThread1(createAIThreadRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public deleteMessage2(threadId: string, messageId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).deleteMessage2(threadId, messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public deleteMessages(threadId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).deleteMessages(threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public deleteThread1(threadId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).deleteThread1(threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public getMessages3(threadId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).getMessages3(threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public getThreads2(projectId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).getThreads2(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {RemoveInputsFromThreadsRequestDto} removeInputsFromThreadsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public removeInputsFromThread(threadId: string, removeInputsFromThreadsRequestDto: RemoveInputsFromThreadsRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).removeInputsFromThread(threadId, removeInputsFromThreadsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {RemoveOutputRequestDto} removeOutputRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public removeOutput(threadId: string, messageId: string, removeOutputRequestDto: RemoveOutputRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).removeOutput(threadId, messageId, removeOutputRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}

