import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { deleteAPIWithRethrow } from "src/utils/apiService";

interface IVariables {
  deletingSegmentId: string;
}

const useDeleteSegment = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  return useMutation({
    mutationFn: async ({ deletingSegmentId }) => {
      return await deleteAPIWithRethrow(`/v2/segments/${deletingSegmentId}`, {});
    }
  });
};

export default useDeleteSegment;
