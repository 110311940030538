import React, { MutableRefObject } from "react";

type ContextTypes = {
  historyStack: MutableRefObject<string[]>;

  isNavBars: boolean;
  isFetchingProjects: boolean;
  isFetchingJobs: boolean;

  jobs: $TSFixMe;
};

// @ts-ignore
export const PrivateRouteContext = React.createContext<ContextTypes>();
