import { every, has, isEmpty, isNil } from "lodash";

import { removeLocalStorageItem } from "src/services/LocalStorage/LocalStorage.service";
import { removeSessionStorageItem } from "src/services/SessionStorage/SessionStorage.service";

import { TableSessionConfig } from "src/constants/table.constants";

export const onLogout = () => {
  removeLocalStorageItem(TableSessionConfig.TablePreferencesSessionKey);
  removeSessionStorageItem(TableSessionConfig.TablePreferencesSessionKey);
};

export const areAllKeysPresentAndNotNil = (dataMap?: { [key: string]: any }, keys?: string[]) => {
  if (isNil(dataMap) || isNil(keys)) {
    return false;
  }

  if (isEmpty(dataMap) || isEmpty(keys)) {
    return false;
  }

  return every(keys, (key) => has(dataMap, key) && !isNil(dataMap[key]));
};
