import React from "react";
import { Grid } from "@material-ui/core";

import GenerateLoader from "src/components/Loader/GenerateLoader";
import { AIGuideTextRow } from "src/pages/Projects/AIGuide/common/AIGuideTextRow";

interface IProps {
  testId?: string;
  style?: React.CSSProperties;
  inputNames: string[];
  userInput: string;
  isFetchingSuggestions: boolean;
  tags?: string[];
}

const AskAILoaderItem: React.FC<IProps> = (props) => {
  const { style, testId, inputNames, userInput, isFetchingSuggestions, tags } = props;

  return (
    <Grid container direction="column">
      <AIGuideTextRow
        userInput={userInput}
        queryInputs={inputNames.map((name) => ({ name }))}
        maxHeight="132px"
        tags={tags}
        color="#fff"
        width="750px"
        hoverBgColor="#fff"
      />
      <Grid style={style} container direction="column">
        <GenerateLoader
          test-id={testId}
          title={isFetchingSuggestions ? "Generating Suggestions..." : "Generating Code..."}
          transformX={isFetchingSuggestions ? "translateX(64px)" : "translateX(40px)"}
        />
      </Grid>
    </Grid>
  );
};

export { AskAILoaderItem };
export default AskAILoaderItem;
