import React, { useEffect } from "react";
import clsx from "clsx";
import marked from "marked";
import { Box, Chip, Grid, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { Delete } from "src/icons/Delete";
import { CopyTextIcon } from "../../AddCodeRecipe/CopyButton/CopyTextIcon";
import ChatBox from "./ChatBox";
import { PersonIconV3 } from "src/icons/NewUX/PersonIconV3";
import { EntityIcon } from "src/icons/EntityIcon";
import { SampleRows } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/SampleRows";
import markdownToTxt from "markdown-to-txt";

marked.setOptions({
  gfm: true,
  breaks: false,
  pedantic: false
});

type Props = {
  userInput: string;
  canDelete?: boolean;
  canCopy?: boolean;
  setShowDeleteModal?: (showDeleteModal: boolean) => void;
  isError?: boolean;
  width?: string;
  isMarkDown?: boolean;
  icon?: React.ReactNode;
  maxHeight?: string;
  onCollapse?: () => void;
  onExpand?: () => void;
  copyTitle?: string;
  className?: any;
  noPadding?: boolean;
  color?: string;
  textColor?: string;
  hoverBgColor?: string;
  disableMarkdownPadding?: boolean;
  queryInputs?: {
    name: string;
  }[];
  sampleRows?: number;
  tags?: string[];
  children?: React.ReactNode;
  disableDelete?: boolean;
  deleteTooltip?: string;
};

type StyleProps = {
  isError: boolean;
  width?: string;
  maxHeight?: string;
  isHovered: boolean;
  canCopy: boolean;
  canDelete: boolean;
  canClick: boolean;
  isMarkDown: boolean;
  noPadding?: boolean;
  textColor?: string;
  disableMarkdownPadding?: boolean;
};

const useStyles = makeStyles<undefined, StyleProps>({
  userInputColumn: {
    padding: ({ noPadding }) => (noPadding ? "0px 32px 0px 16px" : "12px 32px 16px 16px"),
    width: "calc(100% - 28px)",
    gap: "24px",
    flexWrap: "nowrap"
  },
  profileBtnLarge: {
    display: "flex",
    justifyContent: "center",
    width: "24px",
    height: "32px",
    flexWrap: "nowrap"
  },
  askAIUserInput: {
    position: "relative",
    maxWidth: ({ width }) => (width ? "none" : "750px")
  },
  queryInputGroup: {
    flexWrap: "nowrap",
    overflow: "auto",
    height: "100%",
    justifyContent: "center"
  },
  queryContainer: {
    flexWrap: "nowrap",
    padding: "4px 12px",
    justifyContent: "space-between",
    alignItems: "flex-start",
    borderRadius: "0px 4px 4px 4px",
    gap: "16px"
  },
  userInputText: {
    borderRadius: "2px",
    color: "#515151",
    whiteSpace: ({ isMarkDown }) => (isMarkDown ? "normal" : "pre"),
    textWrap: "wrap !important",
    wordBreak: "break-word",
    fontWeight: 400,
    "& *": {
      marginBottom: ({ disableMarkdownPadding }) =>
        disableMarkdownPadding ? "0px !important" : "default"
    }
  },
  queryContainerWrap: {
    maxHeight: ({ maxHeight }) => maxHeight || "inherit",
    overflow: ({ maxHeight }) => (maxHeight ? "auto" : "none"),
    width: "inherit",
    cursor: ({ canClick }) => (canClick ? "pointer" : "default")
  },
  copyIcon: {
    opacity: ({ isHovered }) => (isHovered ? 1 : 0),
    display: ({ canCopy }) => (canCopy ? "block" : "none")
  },
  deleteIcon: {
    visibility: ({ isHovered }) => (isHovered ? "visible" : "hidden"),
    display: ({ canDelete }) => (canDelete ? "block" : "none")
  },
  rightBar: {
    flexGrow: 0,
    padding: "0px 6px 0px 6px"
  },
  collapsedText: {
    color: ({ textColor }) => textColor || "#000",
    fontWeight: 400
  },
  btn: {
    padding: 0
  },
  queryInputsWrap: {
    flexWrap: "nowrap",
    width: "auto",
    alignItems: "center",
    gap: "12px"
  },
  queryInputName: {
    fontSize: "14px",
    color: "#FF653D",
    paddingLeft: "4px"
  },
  queryInputs: {
    display: "inline-flex",
    width: "auto",
    flexWrap: "nowrap"
  }
});

export const AIGuideTextRow = ({
  userInput,
  canDelete = false,
  setShowDeleteModal,
  canCopy = true,
  isError = false,
  isMarkDown = false,
  width,
  icon = <PersonIconV3 />,
  maxHeight,
  onCollapse,
  onExpand,
  copyTitle,
  className,
  noPadding = false,
  disableMarkdownPadding = false,
  color,
  textColor,
  hoverBgColor,
  queryInputs,
  sampleRows,
  tags,
  children,
  disableDelete,
  deleteTooltip
}: Props) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [htmlContent, setHtmlContent] = React.useState<string>("");

  const classes = useStyles({
    isError,
    width,
    maxHeight,
    isHovered,
    noPadding,
    canClick: !!onExpand,
    canCopy,
    canDelete,
    isMarkDown,
    textColor,
    disableMarkdownPadding
  });

  const openDeleteModal = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setShowDeleteModal?.(true);
  };

  useEffect(() => {
    const fetchHtmlContent = async () => {
      const htmlString = await marked.parse(userInput);
      setHtmlContent(htmlString);
    };

    fetchHtmlContent();
  }, [userInput]);

  const boxProps = onExpand ? { onClick: onExpand } : {};
  const isMarkDownLoading = isMarkDown && !htmlContent;

  return (
    <Grid container direction="row" alignItems="flex-start" className={classes.userInputColumn}>
      <Grid
        container
        item
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
        className={classes.profileBtnLarge}>
        {icon}
      </Grid>
      <ChatBox
        border={isError ? "#FFDBDD" : isHovered ? hoverBgColor || "rgba(255, 92, 0, 1)" : "#FFF"}
        color={color || (isError ? "#FFDBDD" : "#FFF8F3")}
        width={width || "auto"}
        childWidth="100%"
        noPadding>
        <Grid
          container
          className={classes.askAIUserInput}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <Grid container direction="row" className={classes.queryContainerWrap} {...boxProps}>
            <Grid container direction="row" className={classes.queryContainer}>
              {children ? (
                <Grid item xs container direction="column" className={classes.queryInputGroup}>
                  {children}
                </Grid>
              ) : (
                <Grid item xs container direction="column" className={classes.queryInputGroup}>
                  <Grid container direction="row" wrap="nowrap" className={classes.queryInputsWrap}>
                    {queryInputs?.map((queryInput: any) => {
                      const { name } = queryInput;
                      return (
                        <Grid
                          container
                          alignItems="center"
                          key={name}
                          className={classes.queryInputs}>
                          <EntityIcon width="12px" height="12px" fill="#FF653D" />
                          <Typography className={classes.queryInputName}>{name}</Typography>
                        </Grid>
                      );
                    })}
                    {(tags || [])?.length > 0 && (
                      <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        style={{ gap: "4px", flex: 0 }}>
                        {tags?.map((tag) => (
                          <Chip variant="outlined" size="small" key={tag} label={tag} />
                        ))}
                      </Grid>
                    )}
                  </Grid>
                  <Typography
                    className={clsx(classes.userInputText, className, {
                      [classes.collapsedText]: true
                    })}
                    variant="subtitle2">
                    {isMarkDown
                      ? htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                      : userInput}
                  </Typography>

                  {sampleRows && (
                    <Box pt="8px">
                      <SampleRows sampleRows={sampleRows} />
                    </Box>
                  )}
                </Grid>
              )}
              <Grid item xs container direction="row" wrap="nowrap" className={classes.rightBar}>
                <Box className={classes.copyIcon}>
                  <CopyTextIcon text={markdownToTxt(userInput)} title={canCopy ? copyTitle : ""} />
                </Box>
                <Box className={classes.deleteIcon}>
                  <Tooltip title={deleteTooltip || "Delete Query"} placement="top" arrow>
                    <div>
                      <IconButton
                        size="small"
                        color="primary"
                        className={classes.btn}
                        disabled={disableDelete}
                        onClick={openDeleteModal}>
                        <Delete width={16} height={16} />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Box>
                {!!onCollapse && (
                  <IconButton size="small" className={classes.btn} onClick={onCollapse}>
                    <ExpandLess fontSize="small" />
                  </IconButton>
                )}
                {!!onExpand && (
                  <Tooltip title={isMarkDownLoading ? "Please wait until text is fetched.." : ""}>
                    <div>
                      <IconButton size="small" className={classes.btn} disabled={isMarkDownLoading}>
                        <ExpandMore fontSize="small" />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ChatBox>
    </Grid>
  );
};
