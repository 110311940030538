import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { get } from "lodash";

import NewModal from "components/Modal/NewModal";
import SampleSyntaxIcon from "src/icons/NewUX/SampleSyntaxIcon";
import { Tabs, Tab } from "src/components";
import { CodeRecipeCodeEditor } from "../CodeRecipeCodeEditor/CodeRecipeCodeEditor";

enum TABS {
  DatasetAndChart = "Dataset & Chart",
  Artifacts = "Artifacts",
  Model = "Model"
}

interface IProps {
  open: boolean;
  onClose: () => void;
}

const SYNTAX_CODE = {
  [TABS.DatasetAndChart]: `# Dataset
  def transform(entities, context):
    input_df_1 = entities['titanic'] # this is for reading input dataset
  
    import pandas as pd
    import numpy as np
    output_df_1 = input_df_1.drop(['Age'], axis=1)
  
    return {
        "output_dataset": output_df_1
    }
  
# Chart
  def transform(entities, context):
    input_df_1 = entities['titanic'] # this is for reading input dataset
        
    import plotly.express as px
    import plotly.graph_objects as go
    import plotly.io as pio
    import pandas as pd
    import numpy as np
    pio.templates.default = 'simple_white'
    gender_counts = input_df_1['Sex'].value_counts()
    fig_1 = go.Figure(data=[go.Pie(labels=gender_counts.index, values=
        gender_counts.values, hole=0.3)])
    fig_1.update_layout(title_text='Gender Distribution', font=dict(family=
        'Roboto, monospace', size=16, color='black'), autosize=True)
    fig_1.show()
        
    return {
        'auto_generated_9EYi': fig_1, # auto_generated_9EYi is the name of the chart to be generated. Change the name as per your requirements.
    }`,
  [TABS.Artifacts]: `def transform(entities, context):
  from utils.notebookhelpers.helpers import Helpers
  from utils.dtos.templateOutput import ArtifactOutput

  input_df_1 = entities['titanic'] # this is for reading input dataset

  import pandas as pd
  import numpy as np
  output_df_1 = input_df_1.drop(['Age'], axis=1)

  artifactsDir = Helpers.getOrCreateArtifactsDir(context, artifactsId = "test-artifact")
  output_df_1.head(10).to_csv(artifactsDir + '/test.csv')

  return {
      "test-artifact": ArtifactOutput()
  }`,
  [TABS.Model]: `def transform(entities, context):
  from utils.notebookhelpers.helpers import Helpers
  from utils.dtos.templateOutput import ModelOutput
  from utils.dtos.rc_ml_model import RCMLModel

  input_df_1 = entities['titanic'] # this is for reading input dataset

  value_file = Helpers.getChildDir(context) + "/value.txt"
  with open(value_file, "w") as f:
      f.write("3")

  class TestModel(RCMLModel):
      def load(self, artifacts):
          value_file = artifacts['value']
          with open(value_file, "r") as f:
              self.value = int(f.read())
      
      def predict(self, model_input):
          model_input['Age_added'] = model_input['Age'] + self.value
          return output

  return {
      'test-model-code': ModelOutput(TestModel, {"value": value_file})
  }
`
};

const SampleSyntaxModal: React.FC<IProps> = (props) => {
  const { open, onClose } = props;
  const [tab, setTab] = useState<TABS>(TABS.DatasetAndChart);
  const [value, setValue] = useState(get(SYNTAX_CODE, TABS.DatasetAndChart));
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);

    setTimeout(() => setIsCopied(false), 5000);
  };

  const handleChange = (newTab: TABS) => {
    setTab(newTab);
    setValue(get(SYNTAX_CODE, newTab));
    setIsCopied(false);
  };

  return (
    <NewModal
      header={
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <SampleSyntaxIcon color="black" width={17} height={17} /> Sample Syntax
        </div>
      }
      open={open}
      data-testid="ask-ai-sample-syntax"
      width={980}
      background="#fff"
      keepMounted={false}
      cancelButtonLabel="Close"
      submitDisabled={isCopied}
      submitButtonLabel={isCopied ? "Copied" : "Copy"}
      onClose={onClose}
      onFinish={handleCopy}>
      <>
        <div>
          <Tabs value={tab} onChange={handleChange}>
            <Tab label={TABS.DatasetAndChart} value={TABS.DatasetAndChart} />
            <Tab label={TABS.Artifacts} value={TABS.Artifacts} />
            <Tab label={TABS.Model} value={TABS.Model} />
          </Tabs>
        </div>
        <Box height="calc(100vh - 200px)" mt="1px" mb="4px">
          <CodeRecipeCodeEditor editorValue={value} setEditorValue={setValue} />
        </Box>
      </>
    </NewModal>
  );
};

export default SampleSyntaxModal;
