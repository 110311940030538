import React, { useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import { filter, find, forEach, get, includes, map, size } from "lodash";

import CreateDataAppForm from "./CreateDataAppForm";
import SelectDataAppType from "./SelectDataAppType";
import { AppTemplate, DataAppType } from "src/pages/DataApps/DataApps.type";
import { ConfigInputTypeEnum } from "openapi/Models/config";
import { CreateDataAppRequestDtoDataAppTypeEnum } from "openapi/Models/create-data-app-request-dto";
import { DFSRunConfigGroupDto } from "openapi/Models/dfsrun-config-group-dto";
import { Spinner } from "src/components";
import { askAITypesInfoList } from "src/pages/DataApps/utils/DataApps.constants";

export interface IRecipes extends DFSRunConfigGroupDto {
  allowed: boolean;
  helpText: string;
}

interface IProps {
  isLoading: boolean;
  binaryClassRecipes?: DFSRunConfigGroupDto[];
  appTemplates?: AppTemplate[];
  dataApps: DataAppType[];
  onCancel: () => void;
}

const CreateDataApp: React.FC<IProps> = (props) => {
  const { dataApps, binaryClassRecipes, appTemplates, isLoading, onCancel } = props;

  const [type, setType] = useState<CreateDataAppRequestDtoDataAppTypeEnum | null>(null);

  const dataAppRecipeIds = useMemo(
    () => dataApps?.map((dataApp) => dataApp.groupId).filter((id) => !!id),
    [dataApps]
  );

  const askAIDataAppTemplateId = useMemo(
    () => find(appTemplates, { name: "askai" })?.id,
    [appTemplates]
  );

  const allowedRecipes = useMemo(
    () =>
      map(binaryClassRecipes, (recipe) => {
        const isAllowed = !dataAppRecipeIds?.includes(recipe.id);
        return {
          ...recipe,
          allowed: isAllowed,
          helpText: !isAllowed ? "Recipe already has dataApp associated with it" : ""
        };
      }),
    [binaryClassRecipes, dataAppRecipeIds]
  );

  const { askAIDataApps, disabledInputs } = useMemo(() => {
    const filtered = filter(dataApps, { appTemplateId: askAIDataAppTemplateId });
    const disabledInputArray: ConfigInputTypeEnum[] = [];

    forEach(filtered, ({ dataappConfig }) => {
      const inputType = get(dataappConfig, "inputType");
      if (inputType && includes(map(ConfigInputTypeEnum), inputType)) {
        disabledInputArray.push(inputType);
      }
    });

    return {
      askAIDataApps: filtered,
      disabledInputs: disabledInputArray
    };
  }, [dataApps, askAIDataAppTemplateId]);

  const disabledAskAICreateActionMessage = useMemo(() => {
    if (!askAIDataAppTemplateId) {
      return "It seems that the associated appTemplate for this data app does not exist. Please contact the administrator for assistance";
    }

    if (size(askAIDataApps) === size(askAITypesInfoList)) {
      return "Each project can only be associated with one project and one dataset AskAI DataApp respectively. If necessary, please delete the existing AskAI DataApp associated with this project and create a new one";
    }

    return "";
  }, [askAIDataAppTemplateId, askAIDataApps]);

  const disabledAddDataAppActionMessage = useMemo(() => {
    if (size(binaryClassRecipes) === 0) {
      return "This is applicable only for successfully built Binary Classification/Regression/Binary Experimental/Multiclass Classification types of Rapid Model Recipes.";
    }

    return "";
  }, [binaryClassRecipes]);

  const handleSuccess = () => {
    setType(null);
    onCancel();
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box
      style={{
        padding: "30px 50px",
        maxWidth: type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel ? 700 : "100%",
        margin:
          type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel ? "auto" : "0px 0px 0px 50px"
      }}>
      {type ? (
        <CreateDataAppForm
          type={type}
          appTemplates={appTemplates}
          askAIDataAppTemplateId={askAIDataAppTemplateId}
          recipes={allowedRecipes}
          disabledInputs={disabledInputs}
          onBack={() => setType(null)}
          onSuccess={handleSuccess}
        />
      ) : (
        <SelectDataAppType
          disabledAddDataAppActionMessage={disabledAddDataAppActionMessage}
          disabledAskAICreateActionMessage={disabledAskAICreateActionMessage}
          onCancel={onCancel}
          onTypeChange={setType}
        />
      )}
    </Box>
  );
};

export default CreateDataApp;
