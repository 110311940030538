import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";

const QUERY_KEY_PROJECT_DETAILS = "query-key-project-details";

export interface Record {
  id: string;
  name: string;
  displayName: string;
  predictionService: string;
}

interface IProjectDetails {
  projectId: string;
  projectJobs: Record[];
  predictionServices: Record[];
  dataApps: Record[];
}
const useProjectDetails = (
  id: string,
  options?: UseQueryOptions<IProjectDetails>
): UseQueryResult<IProjectDetails> => {
  return useQuery({
    queryKey: [QUERY_KEY_PROJECT_DETAILS, id],
    queryFn: async () => {
      return await getAPIWithRethrow(`/v2/project/${id}/associates`);
    },
    ...options,
    refetchOnMount: true
  });
};

export default useProjectDetails;
export { QUERY_KEY_PROJECT_DETAILS };
