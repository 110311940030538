import React from "react";
import { Grid } from "@material-ui/core";

import { AskAIOutputWrapContainer } from "./AskAIOutputWrapContainer";
import { AskAICodeEditor } from "src/pages/Projects/common/AskAICodeEditor";

type Props = {
  editorValue: string;
  toggleExpanded?: () => void;
  buttons?: $TSFixMe;
  background?: string;
  title?: string;
  errDetails?: {
    lineNo: number;
    lineOfCode: string;
    errorExplanation?: string;
  } | null;
  errorDescription?: string | null;
};

export const AskAICodeContainer = ({
  editorValue,
  toggleExpanded,
  buttons,
  background,
  title = "Python",
  errDetails,
  errorDescription
}: Props) => {
  return (
    <Grid container test-id="ask-ai-modal-code-container">
      <AskAIOutputWrapContainer
        name={title}
        buttons={buttons}
        onClick={() => toggleExpanded?.()}
        background={background}>
        <AskAICodeEditor
          editorValue={editorValue}
          errDetails={errDetails}
          errorDescription={errorDescription}
        />
      </AskAIOutputWrapContainer>
    </Grid>
  );
};
