import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import _ from "lodash";
import { Handle, Position } from "react-flow-renderer";
import { useNavigate, useParams, useLocation, generatePath } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

import ApiConnectorIcon from "src/icons/NewUX/ApiConnectorIcon";
import styles from "./TransformBlock.module.scss";
import { AiAssistedIcon } from "src/icons/AiAssistedIcon";
import { RapidModelIcon } from "src/icons/RapidModelIcon";
import { RecipeIcon } from "src/icons/RecipeIcon";
import { RecipeTypesPathMapping, RecipeTypes } from "src/pages/private/ProjectsModule/utils";
import { TransformGroupModal } from "../CanvasDrawerItems";
import { WebPaths } from "src/routing/routes";
import { addEllipsis, handleClickClosure } from "../../../helpers/helpers";
import { getNodes, sideComponentSetter } from "../../../store/store.selectors";
import { shouldHighlight } from "../helpers/CanvasFlow.helpers";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { useCanvasStore, useDrawerStore } from "../../../store/store";

const TransformBlockV2 = ({ data }: $TSFixMe) => {
  const [isElementHovered, setIsElementHovered] = useState(false);

  const navigate = useNavigate();
  const { scenarioId } = useParams<$TSFixMe>();

  const location = useLocation();
  const isJobCanvasPath = useMemo(() => /job-canvas/.test(location.pathname), [location.pathname]);
  const { canAccessRecipeTimeout } = useAccessControlContext();

  const setSideComponent = useDrawerStore(sideComponentSetter);
  const shouldBlockClickHandlerTrigger = useCanvasStore(
    (state) => state.shouldBlockClickHandlerTrigger
  );
  const nodes = useCanvasStore(getNodes);
  const nodeToFocus = useCanvasStore((state) => state.nodeToFocus);

  const currentNode = useMemo(() => {
    return nodes?.find((node: $TSFixMe) => (node as $TSFixMe).id === data.itemId);
  }, [data.itemId, nodes]);

  const disabledBlockMessage = useMemo(() => {
    if (!!data?.isJobCanvas && ["UNBUILT", "EMPTY", "SKIPPED"].includes(data?.status)) {
      return `Certain click actions are restricted as supplementary details for the specified recipe ${data?.label} are not accessible in the context of this job run entry.`;
    }

    return "";
  }, [data?.isJobCanvas, data?.status]);

  const handleSingleClick = async () => {
    if (!!disabledBlockMessage) {
      return;
    }

    if (!!data?.jobProps) {
      if (!isJobCanvasPath) {
        return;
      }
    }

    setSideComponent({
      sideComponent: TransformGroupModal,
      sideComponentProps: {
        noHeader: true,
        data: {
          ...data,
          ...{
            scenarioId: data?.scenarioId,
            status: data.status,
            isJobCanvas: !!data?.isJobCanvas,
            jobProps: data?.jobProps
          }
        },
        canAccessRecipeTimeout,
        handleCloseAction: () => sessionStorage.removeItem("configGroupId")
      }
    });
  };

  const handleDoubleClick = async () => {
    if (!!disabledBlockMessage) {
      return;
    }

    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });

    const url = _.replace(
      WebPaths.StandardRecipeDataContainer,
      RecipeTypesPathMapping.STANDARD,
      _.get(RecipeTypesPathMapping, currentNode?.recipeType || RecipeTypesPathMapping.STANDARD)
    );

    let path = generatePath(url, {
      projectId: data.projectId,
      scenarioId: scenarioId || data?.scenarioId,
      groupId: data.itemId
    });

    if (!!data?.jobProps) {
      if (!isJobCanvasPath) {
        return;
      }

      const jobUrl = _.replace(
        WebPaths.JobStandardRecipe,
        RecipeTypesPathMapping.STANDARD,
        _.get(RecipeTypesPathMapping, currentNode?.recipeType || RecipeTypesPathMapping.STANDARD)
      ) as typeof WebPaths.JobStandardRecipe;

      path = generatePath(`${WebPaths.JobRoutes}${jobUrl}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: scenarioId || data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        groupId: data.itemId
      });

      data?.jobProps?.jobPathsSetter(
        `/projects/${data.projectId}/jobs/${data?.jobProps?.jobId}/job-runs/${data?.jobProps?.jobRunId}/job-canvas`
      );
    }

    navigate(path);
  };

  const handleMouseEnter = useCallback(() => {
    setIsElementHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsElementHovered(false);
  }, []);

  const blockIcon = useMemo(() => {
    // Commenting the lines below rather removing them, as the requirements may change.

    let defaultIcon = <RecipeIcon />;

    if (data.image) {
      defaultIcon = (
        <img style={{ width: "70%", height: "70%" }} src={data.image} alt="recipe-icon" />
      );
    } else if (currentNode?.recipeType !== RecipeTypes.Template) {
      if (currentNode?.recipeType === RecipeTypes.AiAssisted) {
        defaultIcon = <AiAssistedIcon />;
      }

      if (currentNode?.recipeType === RecipeTypes.RapidModel) {
        defaultIcon = <RapidModelIcon />;
      }

      if (currentNode?.recipeType === RecipeTypes.ApiConnector) {
        defaultIcon = <ApiConnectorIcon />;
      }

      // if (data.status === "RUNNING") {
      //   return <TimerIcon />;
      // }
      // if (data.status === "ERROR") {
      //   return <WarningIcon />;
      // }
    }

    // if (isElementHovered) {
    //   return <ReceiptIcon />;
    // }

    // if (data.status === "UNBUILT") {
    //   return <BlockIcon />;
    // }
    // if (data.status === "RUNNING") {
    //   return <TimerIcon />;
    // }
    // if (data.status === "SUCCESS") {
    //   return <DoneAllIcon />;
    // }
    // if (data.status === "ERROR") {
    //   return <WarningIcon />;
    // }
    // if (data.status === "PENDING") {
    //   return <HourglassEmptyIcon />;
    // }
    // if (data.status === "SKIPPED") {
    //   return <SkipNextIcon />;
    // }

    // return <ReceiptIcon />;

    return defaultIcon;
  }, [currentNode?.recipeType, data.status, isElementHovered, data.image]);

  const blockStatus = useMemo(() => {
    if (data.status === "UNBUILT") {
      return "Unbuilt";
    }
    if (data.status === "RUNNING") {
      return "Running...";
    }
    if (data.status === "SUCCESS") {
      return "Success";
    }
    if (data.status === "SKIPPED") {
      return "Skipped";
    }
    if (data.status === "PENDING") {
      return "Pending...";
    }
    if (data.status === "ERROR") {
      return "Error";
    }
    return "";
  }, [data.status]);

  return (
    <>
      <Handle type="target" position={Position.Left} />
      <Tooltip title={disabledBlockMessage}>
        <div
          role="button"
          tabIndex={0}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTrigger || data.shouldDisableBlockInteraction,
            handleDoubleClick,
            handleSingleClick
          })}
          onKeyPress={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTrigger || data.shouldDisableBlockInteraction,
            handleDoubleClick,
            handleSingleClick
          })}
          className={clsx(styles.transformBlockContainer, {
            [styles.highlighted]: shouldHighlight(data?.label, nodeToFocus),
            [styles.transformBlockContainerUnbuilt]: data.status === "UNBUILT",
            [styles.transformBlockContainerRunning]: data.status === "RUNNING",
            [styles.transformBlockContainerSuccess]: data.status === "SUCCESS",
            [styles.transformBlockContainerError]: data.status === "ERROR",
            [styles.transformBlockContainerPending]: data.status === "PENDING",
            [styles.transformBlockContainerSkipped]: data.status === "SKIPPED"
            // Commenting the lines below rather removing them, as the requirements may change.
            // [styles.orangeColorBlock]:
            //   currentNode?.recipeType === "CODE" || currentNode?.recipeType === "AUTO_ML"
          })}>
          <div className={styles.relativeContainer}>
            <div className={styles.blockStatus}>{blockStatus}</div>
            <div className={styles.iconContainer}>{blockIcon}</div>
            <div className={styles.label}>{addEllipsis(data.label)}</div>
          </div>
        </div>
      </Tooltip>
      <Handle type="source" position={Position.Right} />
    </>
  );
};

export default TransformBlockV2;
