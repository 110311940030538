import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import { DataAppTableLinkCell } from "./DataAppTableLinkCell";
import { Table } from "src/components";
import { dateFormat } from "src/utils/dateFormat";
import { type DataAppType } from "../../DataApps.type";
import DataAppTableStatus from "./DataAppTableStatus";

const useStyles = makeStyles({
  link: {
    color: "#003656",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  }
});

type Props = {
  isProjectDataApps: boolean;
  dataApps: DataAppType[];
  isRoleYieldsDataAppView: boolean;
  onDelete: (data: DataAppType) => void;
  onConfigOpen: (dataApp: DataAppType) => void;
  onEdit: (dataApp: DataAppType) => void;
  onLogsOpen: (dataApp: DataAppType) => void;
};

export const DataAppsTable = ({
  isProjectDataApps,
  dataApps,
  isRoleYieldsDataAppView,
  onDelete,
  onEdit,
  onConfigOpen,
  onLogsOpen
}: Props) => {
  const { link } = useStyles();

  const columns = React.useMemo(
    () => [
      {
        id: "DataApp Name",
        accessor: (row: $TSFixMe) => row?.displayName || row?.name,
        Header: "DataApp Name",
        Cell: ({ row }: $TSFixMe) => {
          return (
            <DataAppTableLinkCell
              row={row}
              onEdit={onEdit}
              onDelete={onDelete}
              onConfigOpen={onConfigOpen}
              onLogsOpen={onLogsOpen}
            />
          );
        }
      },
      {
        id: "Description",
        accessor: "description",
        Header: "Description"
      },
      {
        id: "Project",
        accessor: "projectName",
        Header: "Project",
        Cell: ({ row }: $TSFixMe) =>
          isRoleYieldsDataAppView ? (
            row.original.projectName || row.original.projectId
          ) : (
            <Link className={link} to={`/projects/${row.original.projectId}`}>
              {row.original.projectName || row.original.projectId}
            </Link>
          )
      },
      {
        id: "Recipe",
        accessor: (row: $TSFixMe) => row.groupDisplayName || "",
        Header: "Recipe",
        Cell: ({ row }: $TSFixMe) => {
          if (!row.original.groupDisplayName) {
            return "-";
          }

          return (
            <Link
              className={link}
              to={`/projects/${row.original.projectId}/scenario/${row.original.scenarioId}/add-auto-ml-recipe/${row.original.groupId}`}>
              {row.original.groupDisplayName || row.original.groupId}
            </Link>
          );
        }
      },
      {
        id: "stoppedBy",
        accessor: "stoppedBy",
        Header: "Last Shutdown By"
      },
      {
        id: "Updated by",
        accessor: "updater",
        Header: "Updated by"
      },
      {
        id: "Updated on",
        accessor: "updatedOn",
        Header: "Updated on",
        Cell: ({ value }: $TSFixMe) => <span>{dateFormat(value)}</span>
      },
      {
        id: "Status",
        accessor: "launchStatus",
        Header: "Status",
        isSortable: true,
        Cell: ({ row }: any) => (
          <DataAppTableStatus id={row?.original?.id} launchStatus={row?.original?.launchStatus} />
        )
      }
    ],
    [onDelete]
  );

  return (
    <Table
      data={dataApps}
      size="small"
      columns={columns}
      hideCount
      isCellSortEnabled
      orderByDefault="Updated on"
      skipPageReset
      maxHeight={isProjectDataApps ? "calc(100vh - 136px)" : "calc(100vh - 339px)"}
      isTheadSticky
    />
  );
};
