import React, { useCallback, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { handleResponse } from "../../utils/apiService";

import AddScenariosSegments from "./AddScenariosSegments";
import ScenariosHeader from "./ScenariosHeader/ScenariosHeader";
import { Grid, makeStyles } from "@material-ui/core";
import { Tabs, Tab, TabPanel, Spinner } from "src/components";
import AddScenarioVariables from "./AddScenarioVariables";
import { validateNameField } from "src/utils/formFieldUtils";
import { WebPaths } from "src/routing/routes";
import { useGetScenario } from "src/hooks/api";

const useStyles = makeStyles((theme) => ({
  root: {
    rowGap: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

const EditScenario = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { projectId, scenarioId } = useParams<$TSFixMe>();

  const [value, setValue] = useState(1);
  const [initialScenario, setInitialScenario] = useState({});
  const [currentScenario, setCurrentScenario] = useState({});
  const [scenarioVariables, setScenarioVariables] = useState([]);
  const [sharedVariables, setSharedVariables] = useState([]);
  const [entityGroupFormStates, setEntityGroupFormStates] = useState([]);
  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);

  const { isLoading: isLoadingScenarios } = useGetScenario({
    scenarioId,
    onSuccess: (data: $TSFixMe) => {
      setInitialScenario(data);
      setCurrentScenario(data);
      setScenarioVariables(data?.variables);
    }
  });

  const updateScenarioName = async (name: $TSFixMe) => {
    const { error } = validateNameField({
      fieldName: name,
      fieldNameLabel: `scenario name`
    });
    if (error) {
      setShouldResetName(Date.now());
      handleResponse({ errorMessage: error });
      return;
    }
    setCurrentScenario((previousCurrentScenario) => ({
      ...previousCurrentScenario,
      name
    }));
  };

  const onAfterSubmit = useCallback(() => {
    if (projectId) {
      const path = generatePath(WebPaths.Scenarios, {
        id: projectId
      });
      navigate(path);
    }
  }, [projectId]);

  const handleTabChange = useCallback(
    (newValue: $TSFixMe) => {
      if (value === newValue) return;
      setValue(newValue);
    },
    [value]
  );

  const segmentIds = useMemo(() => {
    return entityGroupFormStates
      .map((formState) => {
        if ((formState as $TSFixMe).segmentVariant === "custom") {
          return (formState as $TSFixMe).segments
            .filter((segment: $TSFixMe) => segment.checked)
            .map((segment: $TSFixMe) => segment.id);
        } else {
          return [];
        }
      })
      .flat();
  }, [entityGroupFormStates]);

  return isLoadingScenarios ? (
    <Spinner />
  ) : (
    <>
      <ScenariosHeader
        handleUpdateName={updateScenarioName}
        isEditMode
        scenarioData={currentScenario}
        shouldReset={shouldResetName}
      />

      <Grid container direction="column" className={classes.root}>
        <Tabs value={value} onChange={handleTabChange}>
          <Tab value={1} label="Segments" />
          <Tab value={2} label="Variables" />
        </Tabs>
        <TabPanel value={value} index={1}>
          <AddScenariosSegments
            scenarioId={scenarioId}
            projectId={projectId}
            scenarioName={(currentScenario as $TSFixMe)?.name}
            scenarioVariables={scenarioVariables}
            scenarioSegments={(currentScenario as $TSFixMe)?.segments || []}
            onAfterSubmit={onAfterSubmit}
            submitButtonLabel="SAVE"
            isEditing
            sharedVariables={sharedVariables}
            entityGroupFormStates={entityGroupFormStates}
            setEntityGroupFormStates={setEntityGroupFormStates}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AddScenarioVariables
            initialScenario={initialScenario}
            onAfterSubmit={onAfterSubmit}
            projectId={projectId!}
            scenarioName={(currentScenario as $TSFixMe)?.name}
            isEditing
            segmentIds={segmentIds}
            sharedVariables={sharedVariables}
            setSharedVariables={setSharedVariables}
          />
        </TabPanel>
      </Grid>
    </>
  );
};

export default EditScenario;
