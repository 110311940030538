import React from "react";

// Packages
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";

// Components
import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";

const FONT_SIZE = 14;

type Props = {
  project: $TSFixMe;
  jobName: string;
  updateJobName: $TSFixMeFunction;
  shouldResetJobName: number | boolean;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, jobName, updateJobName, shouldResetJobName } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="projects" to={`/projects/${project?.id}/canvas`}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText key="projects" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="jobs" to={`/projects/${project?.id}/jobs`}>
        <RenderText>Jobs</RenderText>
      </Link>
    ) : (
      <RenderText key="jobs" color="textSecondary">
        Jobs
      </RenderText>
    )
  );

  breadcrumbs.push(
    <EditName
      key="edit-name"
      inputValue={jobName}
      onSave={updateJobName}
      isResetName={shouldResetJobName}
      containerProps={{
        style: {
          width: ((jobName || "")?.length + 1) * (FONT_SIZE - 4) + 16
        }
      }}
    />
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
