import { Breadcrumbs } from "@material-ui/core";
import React from "react";
import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";

interface IProps {
  connectorName: string;
  onEditDataSourceName: (name?: string | undefined) => void;
  shouldResetName: number | boolean;
}

const FONT_SIZE = 14;

const DataSourceHeaderBreadcrumb: React.FC<IProps> = (props) => {
  const { connectorName, onEditDataSourceName, shouldResetName } = props;
  const breadcrumbs = (
    <EditName
      key="edit-name"
      inputValue={connectorName}
      onSave={onEditDataSourceName}
      isResetName={shouldResetName}
      inputProps={{ "data-testid": "connectorName" }}
      containerProps={{
        style: {
          width: ((connectorName || "")?.length + 1) * (FONT_SIZE - 4) + 16
        }
      }}
    />
  );

  return <Breadcrumbs>{breadcrumbs}</Breadcrumbs>;
};

export default DataSourceHeaderBreadcrumb;
