import Modal, { ModalProps } from "@material-ui/core/Modal";
import React from "react";
import { Button, CircularProgress, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import DefaultButton from "../Buttons/DefaultButton";

interface IModalProps extends ModalProps {
  header?: React.JSX.Element | string;
  cancelButtonLabel?: string;
  submitButtonLabel?: string;
  submitDisabled?: boolean;
  loading?: boolean;
  hideCancel?: boolean;
  hideSubmit?: boolean;
  hideFooter?: boolean;
  footerLeftContent?: React.JSX.Element;
  maxWidth?: string | number;
  width?: string | number;
  height?: string | number;
  maxHeight?: string | number;
  submitTooltip?: string | React.JSX.Element;
  modelBg?: string;
  hideCloseButton?: boolean;
  onFinish?: React.MouseEventHandler<HTMLButtonElement>;
  background?: string;
  isDefaultButtonCustomTheming?: boolean;
  contentWidth?: string;
}

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
};

const useStyles = makeStyles<
  undefined,
  Pick<
    IModalProps,
    "width" | "maxHeight" | "maxWidth" | "height" | "background" | "contentWidth" | "modelBg"
  >
>(() => ({
  paper: ({ width, maxWidth, modelBg }) => ({
    position: "absolute",
    width: width ?? 400,
    maxWidth,
    background: modelBg ?? "white",
    borderRadius: "6px",
    border: "1px solid transparent",
    overflow: "hidden"
  }),
  content: ({ maxHeight, background, contentWidth, modelBg }) => ({
    background: modelBg ? undefined : background || "rgba(235, 233, 233, 1)",
    padding: "12px",
    maxHeight,
    overflow: maxHeight ? "auto" : undefined,
    width: contentWidth ?? undefined
  }),
  header: {
    background: ({ modelBg }) => (modelBg ? undefined : "white"),
    padding: "12px"
  },
  title: {
    fontWeight: 500,
    lineHeight: 1,
    fontSize: 10
  },
  footer: {
    width: "100%",
    background: ({ modelBg }) => (modelBg ? undefined : "white"),
    padding: "12px 16px",
    borderTop: "1px solid rgb(224, 224, 224)",
    display: "flex",
    justifyContent: "space-between"
  },
  close: {
    position: "absolute",
    right: 0,
    top: 0
  },
  btn: {
    textTransform: "none"
  },
  flexEnd: {
    gap: "10px",
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const NewModal: React.FC<IModalProps> = (props) => {
  const {
    maxWidth,
    width,
    height,
    maxHeight,
    hideCloseButton,
    header,
    children,
    submitDisabled,
    submitTooltip,
    loading,
    cancelButtonLabel,
    hideFooter,
    submitButtonLabel,
    hideCancel,
    modelBg,
    hideSubmit,
    footerLeftContent,
    onFinish,
    background,
    isDefaultButtonCustomTheming,
    contentWidth,
    ...restProps
  } = props;
  const classes = useStyles({
    width,
    contentWidth,
    maxWidth,
    height,
    maxHeight,
    background,
    modelBg
  });

  const handleClose = (e: any) => {
    props.onClose?.(e, "escapeKeyDown");
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      {...restProps}>
      <div style={getModalStyle()} className={classes.paper}>
        {!hideCloseButton && (
          <IconButton
            aria-label="close"
            color="primary"
            className={classes.close}
            onClick={handleClose}>
            <Close style={{ height: 21 }} />
          </IconButton>
        )}
        {header && <div className={classes.header}>{header}</div>}
        <div className={classes.content}>{children}</div>
        {!hideFooter && (
          <div className={classes.footer}>
            {footerLeftContent ? footerLeftContent : <span />}
            <div className={classes.flexEnd}>
              {!hideCancel && (
                <DefaultButton
                  size="small"
                  variant="outlined"
                  color="primary"
                  disabled={loading}
                  onClick={handleClose}
                  // New UX change
                  // Can be removed soon.
                  isCustomTheming={isDefaultButtonCustomTheming}>
                  {cancelButtonLabel ?? "Cancel"}
                </DefaultButton>
              )}
              {!hideSubmit && (
                <Tooltip title={submitTooltip ?? ""}>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.btn}
                      disabled={loading || submitDisabled}
                      startIcon={
                        loading ? (
                          <CircularProgress size={16} style={{ color: "#fff" }} />
                        ) : undefined
                      }
                      onClick={onFinish}>
                      {submitButtonLabel ?? "Submit"}
                    </Button>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default NewModal;
