/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import isEqual from "lodash/isEqual";
import {
  Typography,
  Tooltip,
  Grid,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  makeStyles,
  withStyles,
  IconButton,
  Button,
  TextField,
  CircularProgress
} from "@material-ui/core";
import { ArrowForwardIos, Check, Close, Edit } from "@material-ui/icons";

import { useForm } from "src/utils/useForm";
import {
  InputDatasets,
  OutputDatasets,
  OutputCharts,
  Parameters
} from "src/pages/Projects/common/TransformInputs/TransformInputs";
import { validateNameField } from "src/utils/formFieldUtils";
import { DeleteNew } from "src/icons/DeleteNew";
import { TransformsIcon } from "src/icons/TransformsIcon";
import { handleResponse } from "src/utils/apiService";
import { useFetchTransformInputs } from "./useFetchTransformInputs";
import { READONLY_ENTITY } from "src/constants";

const getRunConfig = (configGroup: $TSFixMe, id: $TSFixMe) => {
  return configGroup?.runConfigs?.find((item: $TSFixMe) => item.id === id);
};

const useStyles = makeStyles({
  transformInputsWrap: {
    flexWrap: "nowrap",
    padding: "8px 0px"
  },
  parametersWrap: {
    width: "100%",
    "& label": {
      whiteSpace: "nowrap"
    }
  },
  transformInput: {
    width: "100%"
  },
  docLink: {
    "& svg": {
      width: "20px",
      height: "20px",
      color: "#323232",
      opacity: 0.5
    }
  },
  transformNameWrap: {
    flexWrap: "nowrap",
    gap: "16px",
    alignItems: "center",
    padding: "8px 0px",
    justifyContent: "space-between"
  },
  transformNameLeftContent: {
    display: "flex",
    flexWrap: "nowrap",
    gap: "16px",
    alignItems: "center"
  },
  btnsWrap: {
    flexWrap: "nowrap",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  expandIcon: {
    "&.Mui-expanded": {
      transform: "rotate(90deg)"
    }
  },
  editNameButtons: {
    flexDirection: "row",
    flexWrap: "nowrap",
    flex: 0
  },
  errorContainer: {
    flexWrap: "nowrap"
  }
});

const Accordion = withStyles({
  root: {
    borderRadius: "4px",
    border: "1.2px solid #4646b5 !important",
    background: "#FFF",
    boxShadow: "0px 4px 4px 0px rgba(0, 143, 228, 0.06)",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: 0
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#E7E7FF",
    borderBottom: "1px solid #4646b5",
    marginBottom: -1,
    borderRadius: "4px",
    height: "auto",
    minHeight: 44,
    "&$expanded": {
      borderRadius: "4px 4px 0px 0px",
      height: "auto",
      minHeight: 44
    }
  },
  content: {
    margin: "0px",
    "&$expanded": {
      margin: "8px 0"
    }
  },
  expanded: {},
  expandIcon: {
    "&.Mui-expanded": {
      transform: "rotate(90deg)"
    }
  }
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails);

type TransformInputsContainerProps = {
  isJobPath?: boolean;
  selectedEntities: $TSFixMe[];
  selectedTransform?: $TSFixMe;
  groupId: string | undefined;
  appliedTransforms: Array<$TSFixMe>;
  configGroup: $TSFixMe;
  projectId: string | number;
  isEditing: boolean;
  onAddTransform: $TSFixMeFunction;
  onDeleteTransform: $TSFixMeFunction;
  allTransforms: $TSFixMe;
  onTransformNameChange: $TSFixMeFunction;
  setIsRecipeUpdating: (val: boolean) => void;
  isRecipeUpdating: boolean;
  isDefaultScenario: boolean;
};
const TransformItems = ({
  isJobPath,
  selectedTransform,
  selectedEntities,
  groupId,
  projectId,
  configGroup,
  appliedTransforms,
  onAddTransform,
  isEditing,
  onDeleteTransform,
  allTransforms,
  onTransformNameChange,
  setIsRecipeUpdating,
  isRecipeUpdating,
  isDefaultScenario
}: TransformInputsContainerProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(selectedTransform.expanded || false);

  const [transformName, setTransformName] = React.useState<string>(
    selectedTransform.displayName || selectedTransform.name
  );
  const [isEditingName, setIsEditingName] = React.useState(false);
  const [nameError, setNameError] = React.useState<string | undefined>();
  const { inputs } = useFetchTransformInputs({ selectedTransform });
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = React.useMemo(
    () =>
      selectedTransform.inputs?.reduce((acc: $TSFixMe, input: $TSFixMe) => {
        acc[input.name] =
          selectedTransform?.inputValues?.[input.name] ||
          getRunConfig(configGroup, selectedTransform.id)?.variables?.[input.name] ||
          input?.display?.default_value ||
          input?.metadata?.default_value;
        return acc;
      }, {}),
    [configGroup, selectedTransform.id, selectedTransform?.inputValues, selectedTransform.inputs]
  );
  const { values, handleInputChange, errors, setErrors } = useForm(initialValues);
  const isFormUnchanged = isEqual(values, initialValues);

  useEffect(() => setErrors({}), [setErrors, values]);

  const validateTransformName = (transformName: string) => {
    const { error } = validateNameField({
      fieldName: transformName,
      fieldNameLabel: `transform name`
    });

    const restTransforms = allTransforms?.filter(
      (currTransform: $TSFixMe) => currTransform.id !== selectedTransform.id
    );
    const currentTransformNames =
      restTransforms?.map((currTransform: $TSFixMe) => currTransform?.name?.toLowerCase()) || [];
    if (currentTransformNames.includes(transformName?.toLowerCase())) {
      return "There is a transform with the same name.";
    }
    return error;
  };
  const handleNameChange = (event: $TSFixMe) => {
    setNameError(undefined);
    event.preventDefault();
    event.stopPropagation();
    const error = validateTransformName(transformName);
    if (error) {
      setNameError(error);
      return;
    }

    const basePayload = getUpdatedTransformPayload();
    const updatedTransform = {
      ...basePayload,
      name: transformName,
      displayName: transformName
    };

    const hasErrors = isEditing ? checkForErrors(false) : false;
    if (hasErrors) {
      return;
    }
    setIsRecipeUpdating(true);

    onTransformNameChange(updatedTransform, () => {
      setIsEditingName(false);
      setIsRecipeUpdating(false);
    });
  };

  const checkForErrors = (shouldSetError = true) => {
    const { inputs = [] } = selectedTransform;
    const newErrors = {};
    const hasErrors =
      selectedTransform?.inputs &&
      inputs.reduce((acc: $TSFixMe, input: $TSFixMe) => {
        const isRequired = (input.metadata || input.display).is_required;
        if (isRequired) {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          newErrors[input.name] = !values[input.name];
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          return values[input.name] ? acc : true;
        } else {
          return acc;
        }
      }, false);
    shouldSetError && setErrors(newErrors);
    return hasErrors;
  };

  const getUpdatedTransformPayload = () => {
    return {
      ...selectedTransform,
      expanded: false,
      inputValues: Object.keys(values)?.reduce((acc, item) => {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        acc[item] = values[item]?.replace(", ", ",");
        return acc;
      }, {}),
      id: selectedTransform.id,
      templateId: selectedTransform.templateId || selectedTransform.id,
      projectId,
      displayName: transformName,
      name: transformName,
      groupId
    };
  };

  const handleSubmit = () => {
    setIsLoading(true);
    setIsRecipeUpdating(true);
    const onSuccess = () => {
      handleChange(null, false);
    };
    const onSettled = () => {
      setIsLoading(false);
      setIsRecipeUpdating(false);
    };
    const error = validateTransformName(transformName);
    if (error) {
      handleResponse({ errorMessage: error });
      onSettled();
      return;
    }

    const hasErrors = checkForErrors();
    if (hasErrors) {
      onSettled();
      return;
    }
    const payload = getUpdatedTransformPayload();
    onAddTransform({ payload, onSettled, onSuccess });
  };

  const handleChange = (_: any, newExpanded: boolean) => {
    setExpanded(newExpanded);
    !newExpanded && setIsEditingName(false);
  };

  const onEditCancel = (event: any) => {
    setNameError(undefined);
    event.stopPropagation();
    setIsEditingName(false);
    setTransformName(selectedTransform.displayName || selectedTransform.name);
  };
  return (
    <Accordion square expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ArrowForwardIos style={{ fontSize: 16 }} />}>
        <Grid container direction="row" className={classes.transformNameWrap}>
          <div className={classes.transformNameLeftContent}>
            <TransformsIcon test-id="transformIcon" />
            {isEditingName ? (
              <TextField
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                onClick={(event: any) => {
                  event.stopPropagation();
                }}
                value={transformName}
                error={!!nameError}
                helperText={nameError}
                onChange={(event: any) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setTransformName(event.target.value);
                }}
                inputProps={{
                  "test-id": "transformNameEditField"
                }}
                InputProps={{
                  endAdornment: (
                    <Grid container spacing={2} className={classes.editNameButtons}>
                      <Grid item>
                        <IconButton
                          size="small"
                          onClick={handleNameChange}
                          test-id="editTransformNameSubmit">
                          <Check style={{ fontSize: "16px" }} />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          size="small"
                          onClick={onEditCancel}
                          test-id="editTransformNameClose">
                          <Close style={{ fontSize: "16px" }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )
                }}
                disabled={!!isJobPath}
              />
            ) : (
              <Typography variant="body1" color="primary" test-id="transformName">
                {selectedTransform.displayName || selectedTransform.name}
              </Typography>
            )}
            {!isJobPath && expanded && !isEditingName && (
              <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
                <div>
                  <IconButton
                    size="small"
                    test-id="showEditTransformField"
                    disabled={isRecipeUpdating || !isDefaultScenario}
                    onClick={(event: any) => {
                      setTransformName(selectedTransform.displayName || selectedTransform.name);
                      event.preventDefault();
                      event.stopPropagation();
                      setIsEditingName(true);
                    }}>
                    <Edit style={{ fontSize: "16px" }} />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </div>
          {!isJobPath && (
            <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
              <div>
                <IconButton
                  onClick={() => {
                    onDeleteTransform(selectedTransform.id, transformName);
                  }}
                  disabled={isRecipeUpdating || !isDefaultScenario}
                  test-id="deleteTransformBtn">
                  <DeleteNew size="tiny" />
                </IconButton>
              </div>
            </Tooltip>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <Grid container direction="column" className={classes.transformInputsWrap}>
            {!!(inputs as $TSFixMe).input_datasets?.length && (
              <InputDatasets
                isJobPath={isJobPath}
                displayInfo={true}
                errors={errors}
                inputs={(inputs as $TSFixMe).input_datasets}
                inputOptions={selectedEntities}
                outputOptions={[]}
                values={values}
                handleInputChange={handleInputChange}
              />
            )}
            {!!(inputs as $TSFixMe).parameters?.length && (
              <Grid container direction="column" className={classes.parametersWrap}>
                {(inputs as $TSFixMe).parameters.map((input: $TSFixMe, index: $TSFixMe) => (
                  <Parameters
                    isJobPath={isJobPath}
                    displayInfo={true}
                    errors={errors}
                    key={index}
                    input={input}
                    values={values}
                    handleInputChange={handleInputChange}
                    selectedEntities={selectedEntities}
                    appliedTransforms={appliedTransforms}
                    outputDatasets={[]}
                    groupId={groupId}
                  />
                ))}
              </Grid>
            )}
            {!!(inputs as $TSFixMe).output_datasets?.length && (
              <OutputDatasets
                isJobPath={isJobPath}
                displayInfo={true}
                errors={errors}
                outputs={(inputs as $TSFixMe).output_datasets}
                values={values}
                handleInputChange={handleInputChange}
              />
            )}
            {!!(inputs as $TSFixMe).output_charts?.length && (
              <OutputCharts
                isJobPath={isJobPath}
                displayInfo={true}
                errors={errors}
                inputs={(inputs as $TSFixMe).output_charts}
                values={values}
                handleInputChange={handleInputChange}
              />
            )}
          </Grid>
          {!isJobPath && (
            <Grid container direction="row" className={classes.btnsWrap}>
              <Tooltip title={!transformName ? "Transformation name is required" : ""}>
                <div>
                  {isLoading ? (
                    <Button disabled>
                      <CircularProgress size={24} />
                    </Button>
                  ) : (
                    <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
                      <div>
                        <Button
                          onClick={handleSubmit}
                          color="primary"
                          variant="contained"
                          test-id={isEditing ? "editTransformBtn" : "addTransformBtn"}
                          disabled={
                            !isDefaultScenario ||
                            !!nameError ||
                            isLoading ||
                            isRecipeUpdating ||
                            (isEditing && isFormUnchanged)
                          }>
                          {isEditing ? "Update" : "Add"}
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default TransformItems;
