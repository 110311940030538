import { useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { deleteJobWithRethrow } from "src/api";
import { toastWrapper } from "src/utils/toastWrapper";
import { useProjectsStore, useJobsStore } from "src/store/store";
import { projectsGetter, shouldRefreshJobsToggler } from "src/store/store.selectors";
import {
  prefetchDataSources,
  prefetchScenarios,
  prefetchVariables,
  prefetchJob,
  prefetchJobDestinations
} from "src/hooks/api";
import { WebPaths } from "src/routing/routes";

export const useJobsListHelper = ({ data }: { data: $TSFixMe }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [showConfirmScreen, setShowConfirmScreen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const currentProjects = useProjectsStore(projectsGetter) || [];
  const refreshRecentJobs = useJobsStore(shouldRefreshJobsToggler);

  const {
    dto: { id: jobId, projectId }
  } = data;

  const prefetchJobQueries = ({ jobId }: $TSFixMe = {}) => {
    prefetchDataSources({ queryClient });
    prefetchVariables({ queryClient, projectId });

    if (!!jobId) {
      prefetchScenarios({ queryClient, projectId, jobId });
      prefetchJob({ queryClient, jobId });
      prefetchJobDestinations({ queryClient, jobId });
    } else {
      prefetchScenarios({ queryClient, projectId });
    }
  };

  // Delete job - STARTS >>
  const deleteJob = async () => {
    setIsDeleting(true);

    try {
      await deleteJobWithRethrow(jobId);

      toastWrapper({
        type: "success",
        content: "Job deleted successfully!"
      });
      refreshRecentJobs();
    } catch (e) {
      console.error(e);
    } finally {
      setIsDeleting(false);
      setShowConfirmScreen(false);
    }
  };

  const promptConfirmDeleteJob = () => {
    setShowConfirmScreen(true);
  };

  const cancelDeleteJob = () => {
    setShowConfirmScreen(false);
  };

  const confirmDeleteJob = () => {
    deleteJob();
  };
  // << ENDS - Delete job

  const cardOptions = useMemo(
    () => [
      {
        label: "Job History",
        click: () =>
          navigate(generatePath(`${WebPaths.JobRoutes}${WebPaths.JobRuns}`, { projectId, jobId })),
        disabled: isDeleting
      },
      {
        label: "Edit Job",
        click: () => {
          prefetchJobQueries({ jobId });
          navigate(generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, { projectId, jobId }));
        },
        disabled: isDeleting
      },
      {
        label: "Delete Job",
        click: promptConfirmDeleteJob,
        disabled: isDeleting
      }
    ],
    [isDeleting]
  );

  const projectName = useMemo(
    () => currentProjects?.find((project: $TSFixMe) => project?.id === projectId)?.name || "",
    []
  );

  return {
    projectName,
    cardOptions,
    cancelDeleteJob,
    confirmDeleteJob,
    showConfirmScreen,
    isDeleting
  };
};
