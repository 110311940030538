import React from "react";

import { filter, isEmpty, size, toUpper } from "lodash";

import { NodeTypes, RecipeStatuses } from "./Projects.constants";

import { RecipeRunData } from "openapi/Models/recipe-run-data";

import { NodeData } from "src/types";

export const deleteProjectMessageLines = (projectName: string = "") => [
  <>
    Deleting the project {!!projectName && <b>&quot;{projectName}&quot;</b>} removes the project
    permanently from the Projects list and will also delete the associated prediction service(s)
    linked to the model(s) within this project(if any).
  </>,
  "Are you sure you want to continue?"
];

export const isRecipesRunning = (nodesStore: NodeData[]) => {
  if (isEmpty(nodesStore)) {
    return false;
  }

  // Filtering nodes out for recipe-types with status running.
  return !isEmpty(
    filter(
      nodesStore,
      (eachNode: NodeData) =>
        toUpper(eachNode?.type || "") === toUpper(NodeTypes.Recipe) &&
        toUpper(eachNode?.status || "") === toUpper(RecipeStatuses.Running)
    )
  );
};

export const isRecipeRunInQueue = ({
  pendingRecipeRunsInQueue,
  recipeId = "",
  scenarioId = ""
}: {
  pendingRecipeRunsInQueue: RecipeRunData[];
  recipeId?: string;
  scenarioId?: string;
}) => {
  if (size(pendingRecipeRunsInQueue) === 0) {
    return false;
  }

  return (
    size(
      filter(
        pendingRecipeRunsInQueue,
        (pendingRecipe: RecipeRunData) =>
          pendingRecipe?.groupId === recipeId && pendingRecipe?.scenarioId === scenarioId
      )
    ) > 0
  );
};
