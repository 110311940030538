import React, { useState } from "react";
import { Button, Grid, List, Typography } from "@material-ui/core";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Add from "@material-ui/icons/Add";
import { ToggleButton, ToggleButtonGroup, Alert } from "@material-ui/lab";
import cloneDeep from "lodash/cloneDeep";
import { FieldName } from "../../../../components";
import styles from "./NewSegment.module.scss";
import CriteriaRule from "./CriteriaRule";

const nonStringOperators = [
  "Greater Than",
  "Less Than",
  "Equal To",
  "Not Equal To",
  "Greater Or Equal",
  "Lesser Or Equal",
  "Is Null",
  "Is Not Null"
];
const operators = [...nonStringOperators, "Contains"];
export const operatorsByDatatype: $TSFixMe = {
  STRING: ["Equal To", "Not Equal To", "Contains", "Is Null", "Is Not Null"],
  DOUBLE: nonStringOperators,
  LONG: nonStringOperators,
  FLOAT: nonStringOperators,
  TIMESTAMP: nonStringOperators,
  BOOLEAN: ["Equal To", "Not Equal To", "Is Null", "Is Not Null"],
  SET: ["Equal To", "Not Equal To", "Is Null", "Is Not Null"],
  LIST: ["Equal To", "Not Equal To", "Is Null", "Is Not Null"],
  MAP: ["Equal To", "Not Equal To", "Is Null", "Is Not Null"],
  BLOB: ["Equal To", "Not Equal To", "Is Null", "Is Not Null"],
  EMPTY: [],
  ANY: operators
};

type Props = {
  isReadOnly?: boolean;
  onChangeRules?: $TSFixMeFunction;
};

const CriteriaBuilder = ({
  isReadOnly,
  // @ts-expect-error TS(2339) FIXME: Property 'entityFeatures' does not exist on type '... Remove this comment to see the full error message
  entityFeatures = [],
  onChangeRules = () => null,
  // @ts-expect-error TS(2339) FIXME: Property 'initialRules' does not exist on type 'Pr... Remove this comment to see the full error message
  initialRules,
  // @ts-expect-error TS(2339) FIXME: Property 'error' does not exist on type 'Props'.
  error
}: Props) => {
  const [rules, setRules] = useState(
    initialRules.length
      ? initialRules
      : [
          {
            type: "group",
            groupType: "AND",
            groupElements: [
              {
                type: "item",
                operator: operators[0].replace(/\s/g, ""),
                field: {
                  type: "field",
                  name: "Field",
                  dataType: "ANY",
                  isEmpty: true
                },
                value: {
                  type: "lit",
                  value: "",
                  dataType: "ANY"
                }
              }
            ]
          }
        ]
  );

  const handleAddRule = () => {
    const newRules = cloneDeep(rules);
    newRules.push({
      type: "group",
      groupType: "AND",
      groupElements: [
        {
          type: "item",
          field: {
            type: "field",
            name: "Field",
            dataType: "ANY",
            isEmpty: true
          },
          operator: operators[0].replace(/\s/g, ""),
          value: {
            type: "lit",
            value: "",
            dataType: "ANY"
          }
        }
      ]
    });
    setRules(newRules);
    onChangeRules(newRules);
  };

  const handleRuleOpChange = (ruleIndex: $TSFixMe, operator: $TSFixMe) => {
    const newRules = cloneDeep(rules);
    newRules[ruleIndex] = {
      ...newRules[ruleIndex],
      groupType: operator
    };
    setRules(newRules);
    onChangeRules(newRules);
  };

  const handleDeleteRule = (ruleIndex: $TSFixMe) => {
    const newRules = cloneDeep(rules);
    newRules.splice(ruleIndex, 1);
    setRules(newRules);
    onChangeRules(newRules);
  };

  const handleAddCondition = (e: $TSFixMe, ruleIndex: $TSFixMe) => {
    e.preventDefault();
    const newRules = cloneDeep(rules);
    newRules[ruleIndex].groupElements.push({
      type: "item",
      field: {
        type: "field",
        name: "Field",
        dataType: "ANY",
        isEmpty: true
      },
      operator: operators[0].replace(/\s/g, ""),
      value: {
        type: "lit",
        value: "",
        dataType: "ANY"
      }
    });
    setRules(newRules);
    onChangeRules(newRules);
  };

  const handleConditionField = (
    ruleIndex: $TSFixMe,
    conditionIndex: $TSFixMe,
    field: $TSFixMe,
    newValue: $TSFixMe
  ) => {
    const newRules = cloneDeep(rules);
    if (field === "operator") {
      newRules[ruleIndex].groupElements[conditionIndex][field] = newValue.replace(/\s/g, "");
      if (["IsNull", "IsNotNull"].includes(newValue)) {
        delete newRules[ruleIndex].groupElements[conditionIndex].value.value;
      }
    } else if (field === "field") {
      newRules[ruleIndex].groupElements[conditionIndex][field].name = newValue;
      delete newRules[ruleIndex].groupElements[conditionIndex][field].isEmpty;

      const datatype = entityFeatures?.find((item: $TSFixMe) => item.name === newValue)?.fieldSchema
        ?.rcDataType;
      const conditionValue = newRules[ruleIndex].groupElements[conditionIndex].value;
      newRules[ruleIndex].groupElements[conditionIndex][field].dataType = datatype;
      if (conditionValue) {
        newRules[ruleIndex].groupElements[conditionIndex].value.dataType = datatype;
      }
      newRules[ruleIndex].groupElements[conditionIndex].operator = operatorsByDatatype[
        datatype
      ]?.[0]?.replace(/\s/g, "");
    } else {
      const datatype = entityFeatures?.find(
        (item: $TSFixMe) =>
          item.name === newRules[ruleIndex].groupElements[conditionIndex]?.field.name
      )?.fieldSchema?.rcDataType;
      newRules[ruleIndex].groupElements[conditionIndex][field] = { type: "lit" };
      newRules[ruleIndex].groupElements[conditionIndex][field].dataType = datatype;
      newRules[ruleIndex].groupElements[conditionIndex][field].value = newValue;
    }
    setRules(newRules);
    onChangeRules(newRules);
  };

  const handleDeleteCondition = (ruleIndex: $TSFixMe, conIndex: $TSFixMe) => {
    const newRules = cloneDeep(rules);
    newRules[ruleIndex].groupElements.splice(conIndex, 1);
    setRules(newRules);
    onChangeRules(newRules);
  };

  return (
    <Grid container item xs={12} className={styles.container}>
      <Grid item xs={12}>
        <FieldName name="Condition Builder" description="Add or modify rules to define split" />
      </Grid>
      <Grid item xs={12}>
        <div className={styles.conditionsContainer}>
          <div className={`${styles.header}  ${error ? styles.headerError : ""}`}>
            <Typography variant="subtitle1" className={`${styles.subheaderTitle}`}>
              Conditions *
            </Typography>
          </div>
          <div className={styles.rulesContainer}>
            {entityFeatures?.length === 0 ? (
              <Alert severity="info" style={{ margin: 16 }}>
                No fields found for the dataset. Please update your dataset.
              </Alert>
            ) : (
              <List disablePadding>
                {rules.map((rule: $TSFixMe, ruleIndex: $TSFixMe) => (
                  <li key={`rule-${ruleIndex}`}>
                    <Grid
                      container
                      className={ruleIndex !== 0 ? styles.nestedRule : ""}
                      style={{ paddingLeft: ruleIndex * 25 }}>
                      <Grid
                        item
                        container
                        xs={12}
                        alignItems="center"
                        className={styles.ruleGroupHeader}>
                        {ruleIndex !== 0 && (
                          <span
                            className={styles.line}
                            style={{
                              left: ruleIndex * 24
                            }}
                          />
                        )}
                        <ToggleButtonGroup
                          value={rule.groupType}
                          exclusive
                          onChange={(__, newOperator) => handleRuleOpChange(ruleIndex, newOperator)}
                          aria-label={`rule-${ruleIndex}-operators`}>
                          <ToggleButton
                            disableRipple
                            disabled={isReadOnly}
                            className={styles.toggleButton}
                            value="AND"
                            aria-label={`${ruleIndex}-and`}>
                            <Typography variant="caption">AND</Typography>
                          </ToggleButton>
                          <ToggleButton
                            disabled={isReadOnly}
                            className={styles.toggleButton}
                            value="OR"
                            aria-label={`${ruleIndex}-or`}>
                            <Typography variant="caption">OR</Typography>
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <Button
                          onClick={handleAddRule}
                          disabled={isReadOnly || ruleIndex !== rules.length - 1}>
                          <Typography variant="overline">
                            <Add /> ADD GROUP
                          </Typography>
                        </Button>
                        {ruleIndex !== 0 && (
                          <Button disabled={isReadOnly} onClick={() => handleDeleteRule(ruleIndex)}>
                            <DeleteOutline className={styles.subheaderButtonIcon} />
                            <Typography variant="overline">DELETE</Typography>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <ul>
                      {rule.groupElements.map((condition: $TSFixMe, conditionIndex: $TSFixMe) => (
                        <CriteriaRule
                          key={`condition-${ruleIndex}-${condition?.id}`}
                          isReadOnly={isReadOnly}
                          conditionIndex={conditionIndex}
                          ruleIndex={ruleIndex}
                          condition={condition}
                          entityFeatures={entityFeatures}
                          error={error}
                          rule={rule}
                          onConditionField={handleConditionField}
                          onDeleteCondition={handleDeleteCondition}
                          onAddCondition={handleAddCondition}
                        />
                      ))}
                    </ul>
                  </li>
                ))}
              </List>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default CriteriaBuilder;
