import {
  ColumnDef,
  ColumnDefResolved,
  VisibilityState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";

import { TData } from "../Table.types";

type Props = {
  data: TData[];
  columns: ColumnDefResolved<TData, any>[];
  sortBy?: string;
  columnVisibility?: VisibilityState;
};

const useHook = (props: Props) => {
  const { columns, data, sortBy, columnVisibility } = props || {};

  return useReactTable({
    columns: columns as ColumnDef<TData, any>[],
    data,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    getFilteredRowModel: getFilteredRowModel(), //client-side filtering
    getFacetedRowModel: getFacetedRowModel(), // client-side faceting
    getFacetedUniqueValues: getFacetedUniqueValues(),
    state: {
      columnVisibility
    },
    initialState: {
      ...(!!sortBy
        ? {
            sorting: [
              {
                id: sortBy,
                desc: false
              }
            ]
          }
        : {})
    }
  });
};

export default useHook;
