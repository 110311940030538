import React from "react";
import _ from "lodash";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@material-ui/core";

import Text from "components/Widget/Text";
import { Environment } from "src/pages/private/Environments/Environments";
import { projectMetadata } from "../utils/CopyDownloadProject.constants";

interface IProps {
  value: string;
  data: Environment[];
  onChange: (key: string, value: string) => void;
}

const CopyEnvironment: React.FC<IProps> = (props) => {
  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    props.onChange(projectMetadata.environment.id, e.target.value as string);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel shrink htmlFor={projectMetadata.environment.id}>
        Environment *
      </InputLabel>
      <Select
        label={projectMetadata.environment.label}
        required
        fullWidth
        disabled
        id={projectMetadata.environment.id}
        name={projectMetadata.environment.id}
        error={_.isEmpty(props.value)}
        value={props.value}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        input={<OutlinedInput notched label="Environment *" />}
        displayEmpty
        onChange={handleChange}>
        {_.map(props.data, (env: Environment) => (
          <MenuItem key={env.id} value={env.id}>
            <Text value={env.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CopyEnvironment;
