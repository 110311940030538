import React from "react";
import { Grid, IconButton, Typography, makeStyles } from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";
import ChatBox from "src/pages/Projects/AIGuide/common/ChatBox";

const useStyles = makeStyles({
  arrow: {
    content: "''",
    position: "absolute",
    width: 0,
    height: 0,
    left: "0px",
    top: "12px",
    transform: "translateY(-50%)",
    border: "12px solid",
    borderColor: "#fff transparent transparent"
  },
  collapsedResponseWrap: {
    flexWrap: "nowrap",
    position: "relative",
    padding: "0px 0px 0px 12px",
    cursor: "pointer"
  },
  collapsedResponse: {
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px 8px",
    width: "100%",
    background: "#fff",
    borderRadius: "4px",
    gap: "32px",
    height: "Opx"
  },
  noOutputText: {
    fontWeight: 400,
    padding: "6px 0px",
    fontSize: "14px",
    whiteSpace: "pre-wrap",
    color: "#515151"
  },
  expandBtn: {
    marginTop: "4px"
  }
});

interface IProps {
  text: string;
  toggleExpanded: () => void;
  hasError: boolean;
}

const TextOutputContainer: React.FC<IProps> = (props) => {
  const { text, toggleExpanded, hasError } = props;
  const classes = useStyles();
  const textRows = text.split(/\n|\\n/);
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <ChatBox
      noPadding
      color={hasError ? "#FFDBDD" : "#fff"}
      border={isHovered ? "#4646b5" : "#a7a7a7"}
      width="100%"
      childWidth="100%">
      <Grid
        container
        direction="row"
        className={classes.collapsedResponse}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => toggleExpanded()}>
        <Grid item>
          {textRows?.map((text, index) => (
            <Typography key={index} variant="subtitle2" className={classes.noOutputText}>
              {text}
            </Typography>
          ))}
        </Grid>
        <Grid item className={classes.expandBtn}>
          <IconButton size="small">
            <ExpandLess fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </ChatBox>
  );
};

export default TextOutputContainer;
