export type Model = {
  allPredictionServiceDetails: any;
  consumers: any[];
  created: number;
  files: null;
  name: string;
  predictionServiceDetails: { id: string; displayName: string } | null;
  producer: {
    projectId: string;
    projectName: string;
    recipeId: string;
    recipeName: string;
  };
  version: number;
  versions: number;
};
export type ModelsResponse = {
  status: string;
  models: Model[];
};

export type ModelMini = {
  type: "MODEL";
  name: string;
};

export enum RapidProblemType {
  binary_classification = "binary_classification",
  binary_classification_gpt = "binary_classification_gpt",
  regression = "regression",
  multiclass_classification = "multiclass_classification",
  timeseries_forecasting = "Timeseries Forecasting",
  anomaly_detection = "Anomaly Detection",
  clustering = "Clustering"
}
