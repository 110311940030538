import React from "react";

// Packages
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

// Icons
import { CardsViewIcon } from "src/icons/NewUX/CardsViewIcon";
import { TableViewIcon } from "src/icons/NewUX/TableViewIcon";

type Props = {
  buttonGroupProps?: $TSFixMe;
  buttonProps?: $TSFixMe;
  primaryIcon?: React.ReactNode;
  secondaryIcon?: React.ReactNode;
  isPrimaryView: boolean;
  setIsPrimaryView: $TSFixMeFunction;
};

const useStyles = makeStyles({
  button: {
    minWidth: "auto",
    borderRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    pointerEvents: "auto",
    cursor: "pointer",
    opacity: 0.5,
    "&.inactive": { pointerEvents: "none", opacity: 1 }
  }
});

const ToggleView = (props: Props) => {
  const {
    buttonGroupProps,
    buttonProps,
    primaryIcon,
    secondaryIcon,
    isPrimaryView,
    setIsPrimaryView
  } = props;

  const classes = useStyles();

  const toggleView = () => setIsPrimaryView(!isPrimaryView);

  return (
    <ButtonGroup color="primary" {...buttonGroupProps}>
      <Button
        data-testid="cardView"
        className={clsx(classes.button, { ["inactive"]: isPrimaryView })}
        onClick={toggleView}
        {...buttonProps}>
        {primaryIcon ?? <CardsViewIcon viewBox="0 0 18 18" />}
      </Button>
      <Button
        data-testid="tableView"
        className={clsx(classes.button, { ["inactive"]: !isPrimaryView })}
        onClick={toggleView}
        {...buttonProps}>
        {secondaryIcon ?? <TableViewIcon viewBox="0 0 20 20" />}
      </Button>
    </ButtonGroup>
  );
};

export default ToggleView;
