import React, { useMemo } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import ApiConnectorRecipeHeader from "./ApiConnectorRecipeHeader/ApiConnectorRecipeHeader";
import ApiConnectorRecipeTabContainer from "./ApiConnectorRecipeTabContainer/ApiConnectorRecipeTabContainer";
import CodeRecipeInputContainer from "../AddCodeRecipe/CodeRecipeInputContainer/CodeRecipeInputContainer";
import { AddEntitiesDialog } from "../AddStandardRecipe/AddEntitiesDialog/AddEntitiesDialog";
import { Box, Grid, IconButton, makeStyles } from "@material-ui/core";
import { Entities, EntityFeaturesResponse } from "src/types";
import { ExpandIcon } from "src/icons/NewUX";
import { Spinner } from "src/components";
import { checkIfDefaultScenario } from "src/pages/Scenarios/helpers/scenarios.helpers";
import { useApiConnectorRecipeContext } from "./ApiConnectorRecipeContext/useApiConnectorRecipeContext";
import { useGetEntityFeatures } from "src/hooks/api/transforms/useGetEntityFeatures";
import { useGetInputEntities } from "src/hooks/api/transforms/useGetInputEntities";
import { useGetScenario } from "src/hooks/api/scenario/useGetScenario";
import { useScenariosStore } from "src/store/store";

const useStyles = makeStyles({
  codeRecipeContainer: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    backgroundColor: "#f5f5f5"
  },
  leftContainer: {
    border: "1px solid #BDBDBD",
    borderRadius: "12px",
    background: "#FFFFFF",
    height: "fit-content",
    maxHeight: "100%"
  },
  gridContainer: {
    height: "100%",
    gap: "18px",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    flexGrow: 0,
    minWidth: "300px",
    maxWidth: "20%",
    flexBasis: "20%"
  },
  codeRecipeBodyContainer: {
    flex: 1,
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    flexWrap: "nowrap",
    background: "#f5f5f5"
  },
  codeContainer: {
    borderLeft: "1px solid #CECECE",
    width: ({ isJobPath, isExpanded }: { isJobPath?: boolean; isExpanded: boolean }) =>
      !!isJobPath ? "100%" : isExpanded ? "80%" : "calc(100vw - 80px)",
    flexBasis: ({ isJobPath, isExpanded }: { isJobPath?: boolean; isExpanded: boolean }) =>
      !!isJobPath ? "100%" : isExpanded ? "80%" : "calc(100vw - 80px)",
    minWidth: 0
  }
});

interface IProps {
  entityIds?: string[];
}

const ApiConnectorRecipeContainer: React.FC<IProps> = (props) => {
  const { entityIds } = props;
  const { projectId, scenarioId, jobRunId } = useParams();
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [showAddEntitiesDialog, setShowAddEntitiesDialog] = React.useState<boolean>(false);
  const scenarios = useScenariosStore((state) => state.scenarios);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const entity = searchParams.get("entity");
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);
  const classes = useStyles({ isJobPath, isExpanded });

  const {
    inputDatasets,
    recipe,
    entityFeaturesMap,
    isSelectedEntitiesUpdateInProgess,
    setEntityFeaturesMap,
    setInputDatasets,
    handleInputDatasetsChange
  } = useApiConnectorRecipeContext();

  const { isLoading, data: allEntities } = useGetInputEntities({
    id: projectId,
    ...(!!isJobPath ? { scenarioId, jobRunId } : {}),
    onSuccess: (data: Entities) => {
      const filteredInputDatasets = data.filter(
        (dataset) => entityIds?.includes(dataset.id) || entity === dataset.id
      );
      setSearchParams({});
      setInputDatasets(filteredInputDatasets);
    }
  });

  const { isLoading: isScenarioLoading, data } = useGetScenario({
    scenarioId,
    options: { cacheTime: undefined, refetchOnMount: true }
  });

  const { isFetching: isEntitiesLoading } = useGetEntityFeatures({
    datasetsToFetch: inputDatasets,
    ...(!!isJobPath ? { scenarioId, jobRunId } : {}),
    onSuccess: (entityFeatures: EntityFeaturesResponse) => {
      const newFeatures = inputDatasets?.reduce(
        (acc, dataset, index) => ({
          ...acc,
          [dataset?.name]: entityFeatures[index]
        }),
        {}
      );
      setEntityFeaturesMap(newFeatures);
    }
  });

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(data ?? null, scenarios, scenarioId),
    [data, scenarios, scenarioId]
  );

  if (isLoading || isScenarioLoading) {
    return <Spinner />;
  }

  return (
    <Grid className={classes.codeRecipeContainer}>
      <ApiConnectorRecipeHeader scenarioData={data} isDefaultScenario={isDefaultScenario} />
      <Grid container direction="row" className={classes.codeRecipeBodyContainer}>
        {isExpanded ? (
          <Grid item className={classes.gridContainer}>
            <Grid className={classes.leftContainer}>
              {isEntitiesLoading || isLoading ? (
                <Spinner />
              ) : (
                <CodeRecipeInputContainer
                  inputDatasets={inputDatasets}
                  entityFeaturesMap={entityFeaturesMap}
                  handleInputDatasetsChange={handleInputDatasetsChange}
                  isDefaultScenario={isDefaultScenario}
                  onCollapse={() => setIsExpanded(false)}
                  openAddEntitiesDialog={() => setShowAddEntitiesDialog(true)}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <Box p="16px">
            <Grid item className={classes.leftContainer}>
              <Box height="100%" p="16px 8px" bgcolor="#D9D9D9" borderRadius="11px">
                <IconButton onClick={() => setIsExpanded(true)} size="small">
                  <ExpandIcon />
                </IconButton>
              </Box>
            </Grid>
          </Box>
        )}
        <Grid item className={classes.codeContainer}>
          {isEntitiesLoading ? (
            <Spinner />
          ) : (
            <ApiConnectorRecipeTabContainer isDefaultScenario={isDefaultScenario} />
          )}
        </Grid>
      </Grid>
      {showAddEntitiesDialog && (
        <AddEntitiesDialog
          recipeId={recipe?.id}
          selectedInputDatasets={inputDatasets}
          allEntities={allEntities || []}
          onClose={() => {
            setShowAddEntitiesDialog(false);
          }}
          onSubmit={({ updatedInputDatasets }) => handleInputDatasetsChange(updatedInputDatasets)}
          isSubmitInProgress={isSelectedEntitiesUpdateInProgess}
          noEntitiesText="If you cannot see datasets here, please add it to the canvas first"
        />
      )}
    </Grid>
  );
};

export default ApiConnectorRecipeContainer;
