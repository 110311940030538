import React, { useMemo, useState } from "react";
import _ from "lodash";
import {
  FormControl,
  makeStyles,
  Select as MuiSelect,
  MenuItem,
  Checkbox,
  ListItemText,
  Tooltip,
  Grid,
  Typography,
  Divider
} from "@material-ui/core";

import SelectSearch from "components/Inputs/SelectSearch";
import { EntityIcon } from "src/icons/EntityIcon";
import { useCodeRecipeContext } from "../../../CodeRecipeContext/useCodeRecipeContext";
import { InfoOutlined } from "@material-ui/icons";
import PinnedIcon from "src/icons/NewUX/PinnedIcon";

export const MAX_INPUT_COUNT = 4;

const useStyles = makeStyles({
  inputs: {
    width: "100%",
    height: "100%",
    background: "#fff",
    borderRadius: "24px 0px 0px 24px",
    "&$disabled": {
      opacity: 0.4
    },
    "&:disabled": {
      opacity: 0.4
    },
    "& .Mui-disabled": {
      opacity: 0.4
    }
  },
  selectRoot: {
    height: "100%",
    borderRadius: "24px 0px 0px 24px"
  },
  customInput: {
    padding: "10px",
    textWrap: "wrap",
    maxHeight: "50px",
    textOverflow: "ellipsis",
    overflowY: "auto",
    borderRadius: "24px 0px 0px 24px"
  },
  selectMenu: {
    maxHeight: "250px",
    overflow: "auto"
  },
  inputLabel: {
    transform: "translate(24px, 16px) scale(1)"
  },
  selectLabel: {
    flexWrap: "nowrap",
    gap: "8px",
    paddingLeft: "4px",
    display: "inline-flex",
    alignItems: "center"
  },
  placeholder: {
    color: "#a2a2a2",
    fontSize: "14px",
    lineHeight: "16px"
  },
  entityWrap: {
    width: "16px",
    display: "inline"
  },
  selectValues: {
    color: "#4646B5",
    fontSize: "14px",
    lineHeight: "16px",
    wordBreak: "break-word"
  },
  pinnedSelectValues: {
    maxWidth: "186px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  countText: {
    color: "#0000008a",
    fontSize: "12px"
  },
  countDiv: {
    padding: "3px 8px 3px 8px",
    gap: "6px"
  },
  itemsWrap: {
    overflow: "auto",
    maxHeight: "200px"
  },
  menuItem: {
    minHeight: "40px"
  }
});

const menuProps: any = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  PaperProps: {
    style: {
      marginTop: "-10px",
      overflowY: "hidden",
      paddingTop: "10px",
      overflowX: "hidden"
    }
  },
  MenuListProps: {
    disablePadding: true
  },
  autoFocus: false
};

export const GenerateCodeInputsDropdown = () => {
  const {
    askAiEnabled,
    inputNames,
    setInputNames,
    pinnedNames,
    isAutoGenerateInProgress,
    allDatasets
  } = useCodeRecipeContext();
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const filteredItems = useMemo(() => {
    const items: any[] = [];

    _.forEach(allDatasets, (entity) => {
      const name = entity.displayName || entity.name;
      if (_.includes(_.toLower(name), _.toLower(value))) {
        items.push(
          <MenuItem
            dense
            key={entity.name || entity.displayName}
            value={entity.name}
            className={classes.menuItem}>
            <Checkbox checked={inputNames.includes(entity.name)} size="small" color="primary" />
            <ListItemText primary={entity.displayName || entity.name} />
          </MenuItem>
        );
      }
    });

    return items;
  }, [value, allDatasets, inputNames]);

  const handleInputNamesChange = (event: any) => {
    const updatedNames = event.target.value;
    setInputNames(
      updatedNames?.length <= MAX_INPUT_COUNT
        ? updatedNames
        : updatedNames.slice(1, MAX_INPUT_COUNT + 1)
    );
  };

  React.useEffect(() => {
    const inputDatasetsNames = allDatasets?.map((dataset) => dataset.displayName || dataset.name);
    if (inputNames.length === 0) {
      const lastDatasetName = inputDatasetsNames?.[inputDatasetsNames.length - 1];
      lastDatasetName && setInputNames([lastDatasetName]);
    } else if (allDatasets.length === 1) {
      // Autoselect single dataset
      setInputNames(inputDatasetsNames);
    } else {
      // Remove deleted datasets from query selection
      setInputNames(inputNames.filter((inputName) => inputDatasetsNames.includes(inputName)));
    }
  }, [allDatasets]);

  const handleClose = () => {
    setOpen(false);
    setValue("");
  };

  const handleOpen = () => {
    if (_.isEmpty(pinnedNames)) {
      setOpen(true);
    }
  };

  return (
    <Tooltip
      arrow
      title={isAutoGenerateInProgress ? "Wait until existing generate query is successful" : ""}>
      <FormControl
        required
        variant="outlined"
        className={classes.inputs}
        disabled={!askAiEnabled || allDatasets?.length === 0 || isAutoGenerateInProgress}>
        <MuiSelect
          labelId="mutiple-select-label"
          inputProps={{
            name: "mutiple-select",
            id: "mutiple-select"
          }}
          open={open}
          className={classes.selectRoot}
          MenuProps={{
            ...menuProps,
            classes: {
              list: classes.selectMenu
            }
          }}
          classes={{ select: classes.customInput }}
          variant="outlined"
          multiple
          value={_.isEmpty(pinnedNames) ? inputNames : pinnedNames}
          onChange={handleInputNamesChange}
          displayEmpty
          onClose={handleClose}
          onOpen={handleOpen}
          renderValue={(selected: any) => {
            return (
              <Tooltip
                title={(!isAutoGenerateInProgress && selected?.join(", ")) || ""}
                arrow
                placement="top">
                <Grid className={classes.selectLabel}>
                  <Grid item className={classes.entityWrap}>
                    <EntityIcon width="14px" height="14px" />
                  </Grid>
                  <Grid item style={{ maxHeight: "56px" }}>
                    {!selected || !selected.length ? (
                      <Typography className={classes.placeholder}>Select Input Datasets</Typography>
                    ) : _.isEmpty(pinnedNames) ? (
                      <span className={classes.selectValues}>{selected?.join(", ")}</span>
                    ) : (
                      _.map(selected, (item: string, index: number) => (
                        <span
                          key={index}
                          style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          <span className={`${classes.selectValues} ${classes.pinnedSelectValues}`}>
                            {item}
                          </span>
                          <span>
                            <PinnedIcon width={12} height={12} />
                            {index < selected.length - 1 && <span>{", "}</span>}
                          </span>
                        </span>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Tooltip>
            );
          }}>
          <SelectSearch value={value} onChange={setValue} />
          <Divider />
          <Grid container direction="row-reverse" alignItems="center" className={classes.countDiv}>
            <Tooltip title={`Maximum of ${MAX_INPUT_COUNT} datasets can be selected at once`}>
              <InfoOutlined style={{ fontSize: "16px", color: "#0000008a" }} />
            </Tooltip>
            <Typography variant="body2" className={classes.countText}>
              ({inputNames?.length || 0} of {filteredItems?.length || 0})
            </Typography>
          </Grid>
          <Divider />
          {filteredItems}
        </MuiSelect>
      </FormControl>
    </Tooltip>
  );
};
