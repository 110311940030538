import { EnvironmentsTypes } from "src/constants/environments.constants";
import { MachineLearningTask } from "../Projects/AddAutoMLRecipe/hooks/useGetAutoMLProblemTypes";
import { DataAppDto, DataAppDtoLaunchStatusEnum } from "openapi/Models/data-app-dto";

export enum DataAppAppTypeEnum {
  STREAMLIT = "STREAMLIT",
  REACTJS = "REACTJS"
}

export type DataAppType = {
  id: string;
  name: string;
  description: string;
  displayName: string;
  projectId: string;
  creator: string;
  createdOn: number;
  updater: string;
  updatedOn: number;
  iconUrl?: string;
  appTemplateId: string;
  accessUrl: string;
  tenantId: string;
  scenarioId: string;
  launchStatus: DataAppDtoLaunchStatusEnum;
  status?: keyof typeof DataAppDtoLaunchStatusEnum;
  stoppedBy?: string;
  memInMbs: number;
  inactivityTtlInHrs: number;
  ttlInHrs: number | null;
  envType?: keyof typeof EnvironmentsTypes;
  groupId?: string;
  groupDisplayName?: string;
  appTemplateType: keyof typeof DataAppAppTypeEnum;
  dataappConfig: DataAppDto["dataappConfig"];
};

export type AppTemplate = {
  id: string;
  name: string;
  autoMlProblemType: MachineLearningTask;
};
