/* eslint-disable no-unused-vars */
import React from "react";
import Fuse from "fuse.js";

import { v4 as uuidv4 } from "uuid";
import {
  Grid,
  makeStyles,
  Popper,
  ListItem,
  Paper,
  List,
  ClickAwayListener,
  Button,
  Typography,
  InputAdornment
} from "@material-ui/core";

import { SearchField } from "src/components";
import { Search } from "src/icons/Search";
//@ts-ignore
import snippets from "./snippets.json";
import { CodeSnippetsNestedContainer, Snippet, Snippets } from "./CodeSnippetsNestedContainer";

const useStyles = makeStyles({
  title: {
    fontWeight: 400
  },
  searchField: {
    backgroundColor: "#F2F9FF",
    border: "1px solid #008FE4",
    borderRadius: "4px 4px 0px 0px",
    width: "100%"
  },
  input: {
    color: "#000000"
  },
  listItem: {
    "&:hover": {
      backgroundColor: "#008fe433"
    }
  },
  codeSnippets: {
    maxHeight: "228px",
    overflowY: "auto",
    flexWrap: "nowrap",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#7C7C7C",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },
  popper: {
    // To Show Above Entities Table
    zIndex: 10
  }
});

export const CodeSnippetsMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [nestedMenuAnchorEl, setNestedMenuAnchorEl] = React.useState(null);
  const [currentSnippet, setCurrentSnippet] = React.useState<Snippet | null>(null);
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSearchTerm("");
    setNestedMenuAnchorEl(null);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const nestedMenuOpen = Boolean(nestedMenuAnchorEl);

  const handleNestedMenuOpen = (event: $TSFixMe, snippet: Snippet) => {
    setCurrentSnippet(snippet);
    setNestedMenuAnchorEl(event.currentTarget.parentNode.parentNode || event.target);
  };

  const handlePopoverMouseDown = (event: $TSFixMe) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const filteredSnippets = React.useMemo(() => {
    const snippetsWithIds: Snippets = (snippets as Snippets)?.map((snippet) => ({
      ...snippet,
      id: uuidv4(),
      codeSnippets: snippet?.codeSnippets?.map((codeSnippet) => ({ ...codeSnippet, id: uuidv4() }))
    }));
    const options = {
      keys: [
        "title",
        "description",
        "codeSnippets.title",
        "codeSnippets.code",
        "codeSnippets.paragraphs"
      ],
      threshold: 0.0,
      ignoreLocation: true
    };
    const fuse = new Fuse(snippetsWithIds, options);
    const result = fuse.search(searchTerm);

    return searchTerm ? result.map((item) => item.item) : snippetsWithIds;
  }, [searchTerm]);

  const handleSearch = (event: $TSFixMe) => {
    setNestedMenuAnchorEl(null);
    setSearchTerm(event.target.value.toLowerCase());
  };
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Grid>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={handleClick}
          test-id="code-snippets-btn">
          Snippets
        </Button>
        <Popper
          id="codesnippets"
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          className={classes.popper}>
          <List disablePadding>
            <Paper elevation={3}>
              <SearchField
                className={classes.searchField}
                placeholder="Search snippets"
                size="small"
                test-id="code-snippets-search-field"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  className: classes.input,
                  "test-id": "code-snippets-search-field-input"
                }}
                onChange={handleSearch}
              />
              <Grid container direction="column" className={classes.codeSnippets}>
                {filteredSnippets?.length ? (
                  filteredSnippets?.map((snippet) => {
                    const { title, id } = snippet;
                    return (
                      <ListItem
                        key={id}
                        className={classes.listItem}
                        button
                        onClick={(event: $TSFixMe) => handleNestedMenuOpen(event, snippet)}>
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          test-id="code-snippet-title">
                          {title}
                        </Typography>
                      </ListItem>
                    );
                  })
                ) : (
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    test-id="code-snippet-no-snippets-text">
                    <ListItem>No Snippets found!</ListItem>
                  </Typography>
                )}
              </Grid>
            </Paper>
          </List>
        </Popper>
        <Popper
          id="dropdown-id"
          open={nestedMenuOpen}
          anchorEl={nestedMenuAnchorEl}
          placement="left-start"
          onMouseDown={handlePopoverMouseDown}
          className={classes.popper}>
          <CodeSnippetsNestedContainer currentSnippet={currentSnippet} />
        </Popper>
      </Grid>
    </ClickAwayListener>
  );
};
