import { useMutation } from "@tanstack/react-query";

import { deleteProjectWithRethrow } from "src/api/projects";

type DeleteProjectPayloadTypes = { projectId: string };

export const useDeleteProject = () => {
  return useMutation({
    mutationFn: ({ projectId }: DeleteProjectPayloadTypes) =>
      deleteProjectWithRethrow(projectId, true)
  });
};
