import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { getEntityDataByPost, getEntityFeatures } from "src/api";
import { Entity } from "src/types";

interface IVariables {
  projectId?: string;
  scenarioId?: string;
  datasetsIds: string[];
}

export interface EntityFeaturesResponse {
  id: string;
  data: Entity;
  schema: any;
  numRows: number;
  numColumns: number;
}

export const useGetEntityFeaturesV2 = ({
  datasetsIds,
  projectId,
  scenarioId
}: IVariables): UseQueryResult<EntityFeaturesResponse[]> => {
  return useQuery({
    queryKey: ["entities", datasetsIds, projectId, scenarioId],
    queryFn: async () => {
      if (!projectId) {
        throw "projectId is absent";
      }
      if (!scenarioId) {
        throw "scenarioId is absent";
      }

      if (datasetsIds?.length === 0) {
        throw "datasetId is absent";
      }
      return await Promise.all(
        datasetsIds?.map(async (datasetId) => {
          const entityDataPromise = await getEntityDataByPost(datasetId, scenarioId);
          const featureDataPromise = await getEntityFeatures(datasetId, scenarioId);
          const [entityData, featureData] = await Promise.all([
            entityDataPromise,
            featureDataPromise
          ]);
          return {
            id: datasetId,
            data: entityData.data,
            schema: featureData,
            numRows: entityData.entityDetails?.rows || entityData?.data?.rows?.length || 0,
            numColumns: entityData.entityDetails?.columns || entityData?.data?.columns?.length || 0
          };
        })
      );
    },
    enabled: !!projectId,
    cacheTime: Infinity
  });
};
