import CancelIcon from "@material-ui/icons/Cancel";
import Papa from "papaparse";
import React, { useState } from "react";
import _ from "lodash";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  makeStyles
} from "@material-ui/core";

import DefaultButton from "components/Buttons/DefaultButton";
import EditJson from "./EditJson";
import PredictionResults from "./PredictionResults";
import { checkEnvRelaunchByEnvId } from "src/utils/envRelaunchNotification";
import usePredictionResults from "../hooks/usePredictionResults";
import { Environment } from "src/pages/private/Environments/Environments";
import { getExtension } from "utils/formatText";
import { handleResponse } from "src/utils/apiService";
import PredictPeriod from "../../PredictionJob/components/PredictPeriod";
import { InfoOutlined } from "@material-ui/icons";

interface IFile {
  name: string;
  fileSize: number;
  fileType: string;
  file: File;
  id: string;
}
interface IProps {
  file: IFile;
  isTimeSeriesRecipe: boolean;
  environment: Environment | null;
  name: string;
  onInputChange: (e: any) => void;
}

const useStyles = makeStyles({
  wrapper: {
    padding: "16px",
    minHeight: "calc(100vh - 165px)"
  },
  input: {
    display: "none"
  },
  details: {
    fontSize: "16px",
    fontWeight: 500
  },
  uploadText: {
    "& label": {
      marginBottom: 0,
      "&.MuiInputLabel-root.MuiInputLabel-outlined": {
        width: "calc(100% - 32px) !important"
      }
    },

    " & .MuiFormHelperText-root": {
      color: "rgba(76, 175, 80, 1)"
    }
  },
  flex: {
    display: "flex",
    gap: "10px",

    "& button": {
      height: "40px"
    }
  }
});

const uploadType = {
  json: {
    name: "json",
    label: "JSON"
  },
  csv: {
    name: "csv",
    label: "CSV File"
  }
};

const FILE_SIZE_IN_MB = 50;
const MAX_FILE_SIZE = FILE_SIZE_IN_MB * 1024 * 1024;

const TestPredictionService: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState<string>(uploadType.csv.name);
  const [jsonString, setJsonString] = useState("");
  const [prediction, setPrediction] = useState<string>();
  const [predictPeriod, setPredictPeriod] = useState(0);
  const fileName = props.file?.name ?? "";
  const predictionResults = usePredictionResults();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    handleClick();
    setJsonString("");
  };

  const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Object.values(e.target.files as FileList).map((file: File) => ({
      name: file.name,
      fileSize: file.size,
      fileType: getExtension(file.name),
      file,
      id: `${file.name}.${file.size}`
    }));
    if (_.some(newFiles, (file) => file.fileSize > MAX_FILE_SIZE)) {
      handleResponse({ errorMessage: `File size exceeds the limit of ${FILE_SIZE_IN_MB} MB.` });
      return;
    }
    props.onInputChange({
      target: { value: newFiles, name: "files" }
    });
    e.target.value = "";
    setJsonString("");
  };

  const handleClick = () => {
    props.onInputChange({
      target: { value: [], name: "files" }
    });
  };

  const handleJsonChange = (newJsonString: string) => {
    setJsonString(newJsonString);
    if (fileName) {
      handleClick();
    }
  };

  const csvtoJson = (file: File): Promise<Record<string, any>> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete(results: any) {
          resolve(results.data);
        },
        error(err) {
          reject(err);
        }
      });
    });
  };

  const getPrediction = (json: Record<string, any>) => {
    checkEnvRelaunchByEnvId(props.environment?.id);

    predictionResults.mutate(
      {
        name: props.name,
        json
      },
      {
        onSuccess: (results) => {
          try {
            const resultObject = typeof results === "string" ? JSON.parse(results) : results;
            const predictionString = JSON.stringify(resultObject, null, 2);
            if (predictionString) {
              setPrediction(predictionString);
            }
          } catch {
            handleResponse({ errorMessage: "Unable to parse JSON/CSV input" });
          }
        }
      }
    );
  };

  const handlePredict = async () => {
    try {
      if (props.isTimeSeriesRecipe && predictPeriod) {
        getPrediction({ key: { "0": predictPeriod } });
      } else if (jsonString) {
        getPrediction(JSON.parse(jsonString));
      } else if (props.file && value === uploadType.csv.name) {
        getPrediction(await csvtoJson(props.file.file));
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          getPrediction(JSON.parse(e.target?.result as string));
        };
        reader.readAsText(props.file.file);
      }
    } catch {
      handleResponse({ errorMessage: "Unable to parse JSON/CSV input" });
    }
  };

  const testBtn = (
    <Button
      variant="contained"
      disabled={
        (!props.isTimeSeriesRecipe && _.isEmpty(fileName) && _.isEmpty(jsonString)) ||
        predictionResults.isLoading ||
        (props.isTimeSeriesRecipe && !predictPeriod)
      }
      color="primary"
      size="small"
      onClick={handlePredict}>
      {predictionResults.isLoading ? (
        <CircularProgress style={{ color: "white" }} size={24} />
      ) : (
        "Test"
      )}
    </Button>
  );
  return (
    <Paper className={classes.wrapper}>
      <div className={classes.details}>Test Prediction Service</div>
      {props.isTimeSeriesRecipe ? (
        <div className={classes.flex} style={{ alignItems: "center" }}>
          <PredictPeriod
            disabled={predictionResults.isLoading}
            value={predictPeriod}
            onChange={setPredictPeriod}
          />
          <Tooltip title="The prediction frequency for the selected period will be automatically determined based on the corresponding time series recipe">
            <InfoOutlined fontSize="small" />
          </Tooltip>
          {testBtn}
        </div>
      ) : (
        <>
          <FormControl>
            <RadioGroup
              value={value}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleChange}>
              <FormControlLabel
                value={uploadType.csv.name}
                control={<Radio />}
                label={uploadType.csv.label}
              />
              <FormControlLabel
                value={uploadType.json.name}
                control={<Radio />}
                label={uploadType.json.label}
              />
            </RadioGroup>
          </FormControl>
          <div className={classes.flex}>
            <input
              accept={value === uploadType.csv.name ? "text/csv" : "application/json"}
              className={classes.input}
              disabled={!_.isEmpty(jsonString)}
              id="prediction-service-file-upload"
              type="file"
              onChange={handleFilesChange}
            />
            <TextField
              fullWidth
              id="upload"
              name="upload"
              label={`Upload File (Maximum ${FILE_SIZE_IN_MB} MB)`}
              value={fileName}
              className={classes.uploadText}
              disabled
              size="small"
              helperText={fileName ? "Upload Successful" : ""}
              test-id="prediction-service-upload-file-input"
              variant="outlined"
              InputLabelProps={{
                shrink: !!fileName
              }}
              InputProps={{
                endAdornment: (
                  <>
                    {fileName && (
                      <IconButton size="small" onClick={handleClick}>
                        <CancelIcon />
                      </IconButton>
                    )}
                    <label htmlFor="prediction-service-file-upload">
                      <DefaultButton
                        disabled={!_.isEmpty(jsonString)}
                        variant="outlined"
                        color="primary"
                        component="span"
                        size="small"
                        // New UX change
                        // Can be removed soon.
                        isCustomTheming={false}>
                        Browse
                      </DefaultButton>
                    </label>
                  </>
                )
              }}
            />
            {testBtn}
          </div>
          {value === uploadType.json.name && (
            <EditJson jsonString={jsonString} onChange={handleJsonChange} />
          )}
        </>
      )}
      {prediction && <PredictionResults jsonString={prediction} />}
    </Paper>
  );
};

export default TestPredictionService;
