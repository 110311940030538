import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";

// Icons
import { RunIcon } from "src/icons/NewUX/RunIcon";
import { TimeoutIcon } from "src/icons/NewUX/TimeoutIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { AddRunIcon } from "src/icons/NewUX";

import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import RecipeLogsDrawer from "../../common/ShowLogsModal/RecipeLogsDrawer";
import { TimeoutTextField } from "../../common/TimeoutTextField/TimeoutTextField";
import { useAutoMLRecipeContext } from "../AutoMLRecipeContext/useAutoMLRecipeContext";
import { useGetScenario } from "src/hooks/api";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { handleResponse } from "src/utils/apiService";
import { useValidateRecipeName } from "../../common/useValidateRecipeName";
import { Scenario } from "src/types";
import { useJobsContext } from "src/pages/private/ProjectsModule/pages/Jobs/context/useJobsContext";
import { DISABLED_RECIPE_LOGS, READONLY_ENTITY } from "src/constants";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { openLogsModal } from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";

export const AutoMLRecipeHeader = ({ isDefaultScenario }: { isDefaultScenario: boolean }) => {
  const { projectId, scenarioId, jobRunId } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  // Project context
  const { project } = useProjectContext() || {};

  // Jobs context
  const { jobPaths, jobPathsSetter } = useJobsContext() || {};

  const {
    jobData,
    jobRunData,
    recipe,
    recipeDisplayName,
    handleSave,
    handleRun,
    handleUpdateRecipeRunTimeout,
    isSaveDisabled,
    isSaveInProgress,
    isAddRecipeToQueue,
    isAddingRecipeToQueue,
    isRunDisabled,
    isRunInProgress,
    isRecipeTimeoutUpdating,
    handleUpdateRecipeName,
    saveDisabledMessage,
    runDisabledMessage,
    scenarioData,
    setScenarioData
  } = useAutoMLRecipeContext();
  const { canAccessRecipeTimeout } = useAccessControlContext();
  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const isOpenLogs = searchParams.get(openLogsModal);

  const { isLoading: isScenarioLoading } = useGetScenario({
    scenarioId,
    onSuccess: (data: Scenario) => {
      setScenarioData(data);
    }
  });
  const { validateName } = useValidateRecipeName();

  const handleLogsClick = () => {
    setOpenLogs(true);
  };

  useEffect(() => {
    if (isOpenLogs) {
      handleLogsClick();
    }
  }, [isOpenLogs]);

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);
  const handleBack = useCallback(() => {
    if (projectId && scenarioId) {
      navigate(-1);
    }
  }, [history, projectId, scenarioId]);

  const updateTransformName = React.useCallback(
    async (name: string) => {
      const cleanedName = name?.trim();
      const { error, nameExist } = validateName(cleanedName);

      if (error) {
        handleResponse({ errorMessage: error });
        setShouldResetName(Date.now());
      } else if (nameExist) {
        handleResponse({ errorMessage: "Recipe name already exists" });
        setShouldResetName(Date.now());
      } else {
        handleUpdateRecipeName({
          recipeName: cleanedName,
          onError: () => setShouldResetName(Date.now())
        });
      }
    },
    [handleUpdateRecipeName, validateName]
  );

  const handleCloseLogs = () => {
    setOpenLogs(false);
    setSearchParams({});
  };

  return (
    <>
      <DeleteNodeModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteSuccess={handleBack}
        nodeId={recipe?.id}
        nodeName={recipe?.displayName}
        nodeType="group"
        deleteNote={
          <div>
            <span>Note: Deleting this might impact associated dataapps(if any).</span>
            {psMessage}
          </div>
        }
      />
      {openLogs && (
        <RecipeLogsDrawer
          name={recipe?.displayName || ""}
          open={openLogs}
          projectId={projectId}
          scenarioId={scenarioId}
          jobRunId={jobRunId}
          groupId={recipe?.id}
          isJobPath={isJobPath}
          onClose={handleCloseLogs}
        />
      )}
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          ...(isJobPath
            ? {
                backNavAction: () => {
                  jobPathsSetter(jobPaths.slice(0, -1));
                }
              }
            : {
                backNavPath: `/projects/${projectId}/scenario/${scenarioId}/canvas`
              }),
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              isJobPath={isJobPath}
              jobData={jobData}
              jobRunId={jobRunId}
              jobRunName={jobRunData?.entryDto?.runId}
              isDefaultScenario={isDefaultScenario}
              scenarioName={scenarioData?.name}
              isScenarioLoading={isScenarioLoading}
              recipeName={recipeDisplayName || recipe?.name}
              updateRecipeName={updateTransformName}
              shouldResetRecipeName={shouldResetName}
            />
          )
        }}
        subTopNavBarRightSection={{
          ...(!isJobPath && !!recipe?.id
            ? {
                moreOptions: [
                  {
                    label: "Delete",
                    icon: <TrashIcon viewBox="0 0 20 20" />,
                    action: () => (recipe?.id ? handleOpenDeleteModal() : handleBack()),
                    isDisabled: !isDefaultScenario,
                    ...(!isDefaultScenario ? { tooltip: READONLY_ENTITY } : {})
                  },
                  ...(canAccessRecipeTimeout
                    ? [
                        {
                          label: `Timeout ${recipe?.timeout || 0}hr`,
                          icon: <TimeoutIcon viewBox="0 0 20 22" />,
                          nestedComponent: (
                            <TimeoutTextField
                              value={recipe?.timeout || ""}
                              minValue={1}
                              onChange={(timeout) => handleUpdateRecipeRunTimeout({ timeout })}
                              disabled={isRecipeTimeoutUpdating}
                            />
                          )
                        }
                      ]
                    : [])
                ]
              }
            : {}),
          component: (
            <>
              {!isJobPath && (
                <>
                  <Tooltip title={isDefaultScenario ? saveDisabledMessage : READONLY_ENTITY}>
                    <span>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => handleSave()}
                        disabled={isSaveDisabled || !isDefaultScenario}>
                        {isSaveInProgress ? <CircularProgress size={22} /> : "Save"}
                      </Button>
                    </span>
                  </Tooltip>

                  <Tooltip title={runDisabledMessage}>
                    <span>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => handleRun()}
                        disabled={isRunDisabled}
                        style={{ textTransform: "none" }}
                        startIcon={
                          isAddingRecipeToQueue || isRunInProgress ? (
                            <CircularProgress size={18} />
                          ) : isAddRecipeToQueue ? (
                            <AddRunIcon />
                          ) : (
                            <RunIcon />
                          )
                        }>
                        {isAddRecipeToQueue ? "Add to Queue" : "Run"}
                      </Button>
                    </span>
                  </Tooltip>
                </>
              )}

              <Tooltip title={!recipe?.id ? DISABLED_RECIPE_LOGS : ""}>
                <span>
                  <Button
                    size="small"
                    color="primary"
                    onClick={handleLogsClick}
                    disabled={!recipe?.id}>
                    <LogsNewIconV2
                      width="22"
                      height="22"
                      // New UX change
                      // The static value will be replaced with new theme at platform-level.
                      color={!recipe?.id ? "#4646b550" : "#4646b5"}
                    />
                  </Button>
                </span>
              </Tooltip>
            </>
          )
        }}
      />
    </>
  );
};
