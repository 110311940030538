import React, { useMemo, useState } from "react";
import CachedIcon from "@material-ui/icons/Cached";

// Packages
import { generatePath, useNavigate } from "react-router-dom";
import { includes, isEmpty } from "lodash";
import clsx from "clsx";

// MUI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Icons
import { EyeIcon } from "src/icons/NewUX";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { AiAssistedIcon } from "src/icons/NewUX/AiAssistedRecipeIcon";
import { RapidModelRecipeIcon } from "src/icons/NewUX/RapidModelRecipeIcon";
import { DatasetIcon } from "src/icons/NewUX/DatasetIcon";
import { AIGuideMiniIcon } from "src/icons/AIGuideMiniIcon";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { WebPaths } from "src/routing/routes";

// Components
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import NodeActionWrapper from "../NodeActionWrapper";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import ReloadDatasetModal from "src/pages/ViewData/ViewDataData/ReloadDatasetModal";

// Constants
import { newTheme as theme } from "src/styles";
import { RecipeTypeNames, DatasetStatuses } from "src/pages/private/ProjectsModule/utils";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";

// Types
import { NodeData } from "src/types";
import ApiConnectorIcon from "src/icons/NewUX/ApiConnectorIcon";

type Props = {
  closeContextMenu: $TSFixMeFunction;
  isDefaultScenario: boolean;
  data: NodeData;
  children?: React.ReactNode;
  openAIGuideDialog: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& button": {
      minWidth: theme.spacing(4),
      "& svg": {
        color: theme.palette.common.white
      },
      "&.disabled": {
        opacity: 0.5,
        cursor: "not-allowed"
      }
    },
    "& .borderLeft": {
      borderLeft: "1px solid #ffffff4d",
      borderRadius: "initial"
    }
  }
}));

const ContextMenu = (props: Props) => {
  const { closeContextMenu, isDefaultScenario, data, children, openAIGuideDialog } = props || {};
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const navigate = useNavigate();

  const classes = useStyles();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const isBuilt = useMemo(() => ["BUILT"].includes(data?.status), [data?.status]);

  const onView = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    let path = generatePath(WebPaths.ViewData, {
      projectId: data?.projectId,
      scenarioId: data?.scenarioId,
      entityId: data?.id,
      section: "data"
    });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobDataRoutes}`, {
        projectId: data?.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        entityId: data?.id,
        section: "data"
      });

      data?.jobProps?.jobPathsSetter(
        `/projects/${data?.projectId}/jobs/${data?.jobProps?.jobId}/job-runs/${data?.jobProps?.jobRunId}/job-canvas`
      );
    }

    !!path && navigate(path);
  };

  const isAddRecipeDisabled = useMemo(() => {
    return !data?.status || !includes([DatasetStatuses.Built], data?.status);
  }, [data?.status]);

  const isNavigableToRecipePage = useMemo(
    () => areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"]),
    [data?.projectId, data?.scenarioId, data?.id]
  );

  const addAiAssistedRecipe = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(
      `/projects/${data?.projectId}/scenario/${data?.scenarioId}/add-code-recipe?entity=${data?.id}`
    );
  };

  const onAddDatasetAction = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(`/projects/${data?.projectId}/scenario/${data?.scenarioId}/dataset/${data?.id}`);
  };

  const addRapidModelRecipe = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(
      `/projects/${data?.projectId}/scenario/${data?.scenarioId}/add-auto-ml-recipe?entity=${data?.id}`
    );
  };

  const handleAddApiConnectorRecipe = () => {
    const url = `${WebPaths.APIConnectorRecipeContainer}?entity=${data?.id}`;
    navigate(
      generatePath(url, {
        projectId: data?.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  const navigateToAIGuide = () => {
    openAIGuideDialog();
    closeContextMenu();
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleAddFile = () => {
    setOpenConfirmModal(true);
  };

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  const handleReload = () => {
    setOpen(true);
  };

  const buttonComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath) {
        return [];
      }
    }

    items.push(
      <NodeActionWrapper key="view" title="View">
        <IconButton size="small" onClick={onView}>
          <EyeIcon viewBox="0 0 20 20" />
        </IconButton>
      </NodeActionWrapper>
    );

    if (!!isDefaultScenario && isBuilt) {
      items.push(
        <ButtonGroup key="buttonGroup" variant="text" size="small" className="borderLeft">
          <NodeActionWrapper title={`${RecipeTypeNames.AiAssisted} Recipe`}>
            <IconButton
              size="small"
              className={clsx({ disabled: isAddRecipeDisabled })}
              disabled={isAddRecipeDisabled}
              {...(!!isNavigableToRecipePage
                ? {
                    onClick: addAiAssistedRecipe
                  }
                : {})}>
              <AiAssistedIcon viewBox="0 1 20 20" />
            </IconButton>
          </NodeActionWrapper>

          <NodeActionWrapper title={`${RecipeTypeNames.RapidModel} Recipe`}>
            <IconButton
              size="small"
              className={clsx({ disabled: isAddRecipeDisabled })}
              style={{ minWidth: theme.spacing(3) }}
              disabled={isAddRecipeDisabled}
              {...(!!isNavigableToRecipePage
                ? {
                    onClick: addRapidModelRecipe
                  }
                : {})}>
              <RapidModelRecipeIcon viewBox="0.5 0.5 22 22" />
            </IconButton>
          </NodeActionWrapper>

          <NodeActionWrapper title={`${RecipeTypeNames.ApiConnector} Recipe`}>
            <IconButton
              size="small"
              {...(!!isNavigableToRecipePage
                ? {
                    onClick: handleAddApiConnectorRecipe
                  }
                : {})}>
              <ApiConnectorIcon viewBox="0 0 16 16" />
            </IconButton>
          </NodeActionWrapper>
        </ButtonGroup>
      );
      if (!data?.isJobCanvas) {
        items.push(
          <ButtonGroup key="aiGuide" variant="text" size="small" className="borderLeft">
            <NodeActionWrapper title="AI Guide">
              <IconButton size="small" onClick={() => navigateToAIGuide()}>
                <AIGuideMiniIcon />
              </IconButton>
            </NodeActionWrapper>
          </ButtonGroup>
        );
      }
    }

    if (!isBuilt && !data?.isOutputDataset && !data?.entityDSDetails?.type) {
      items.push(
        <NodeActionWrapper key="addFile" title="Add File">
          <IconButton className="borderLeft" size="small" onClick={handleAddFile}>
            <DatasetIcon viewBox="0 0 20 20" />
          </IconButton>
        </NodeActionWrapper>
      );
    }

    if (!isBuilt && !!data?.entityDSDetails?.type) {
      items.push(
        <NodeActionWrapper key="reload" title="Reload">
          <IconButton className="borderLeft" size="small" onClick={handleReload}>
            <CachedIcon style={{ width: "16px", height: "16px" }} />
          </IconButton>
        </NodeActionWrapper>
      );
    }

    if (!!isDefaultScenario) {
      items.push(
        <NodeActionWrapper key="delete" title="Delete">
          <IconButton size="small" className="borderLeft" onClick={onDelete}>
            <TrashIcon viewBox="0 0 20 22" />
          </IconButton>
        </NodeActionWrapper>
      );
    }

    return items;
  }, [
    data?.isJobCanvas,
    data?.isJobCanvasPath,
    isDefaultScenario,
    isBuilt,
    isAddRecipeDisabled,
    isNavigableToRecipePage,
    data?.isOutputDataset,
    data?.entityDSDetails?.type
  ]);

  return (
    <>
      {!!isDeleteModalOpen && (
        <DeleteNodeModal
          open
          nodeId={data?.id}
          nodeName={data?.label}
          nodeType="entity"
          deleteNote={
            <div>
              <span>Note: </span>
              {psMessage}
            </div>
          }
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onAfterSubmit={closeContextMenu}
        />
      )}
      {open && (
        <ReloadDatasetModal
          id={data?.itemId}
          name={data?.label}
          projectId={data?.projectId}
          open={open}
          isFiveTran={data?.entityDSDetails?.type === thirdPartyTypeName}
          onCancel={() => setOpen(false)}
          onSuccess={() => setOpen(false)}
        />
      )}

      {(React.isValidElement(children) || !isEmpty(buttonComponents)) && (
        <ButtonGroup variant="text" size="small" className={classes.root}>
          {!isEmpty(buttonComponents) && buttonComponents}
          {children}
        </ButtonGroup>
      )}

      {openConfirmModal && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title="Add File"
          content={[
            "If you make any changes to the dataset, it will render the current runs of the associated recipe(s) with this input dataset invalid, marking them as UNBUILT. To implement the changes, it is necessary to rerun the linked recipe(s). Also the associated segment(if any) will be deleted, and any custom scenarios using it will default to the entire dataset instead of the segment.",
            "Are you sure you want to proceed with this?"
          ]}
          submitLabel="Yes, Proceed"
          onClose={handleCancel}
          onSubmit={onAddDatasetAction}
        />
      )}
    </>
  );
};

export default ContextMenu;
